import { DropdownOption } from '../../../../core/models/DropdownOption';
import { CreateMatchPlayerCommandOfMatchPlayer, EventVm } from '../../../../utils/api';
import MatchLineupRow from './MatchLineupRow';

export interface Props {
    isHomeTeam: boolean;
    cap: number;
    capNumber: number;
    lineupRequest: CreateMatchPlayerCommandOfMatchPlayer | undefined;
    players: DropdownOption[];
    lineup: CreateMatchPlayerCommandOfMatchPlayer[];
    events: EventVm[];
    handleSelectLineupPlayer: (isHomeTeam: boolean, playerId: number, cap: number, capNumber: number) => void;
    handleClearLineupPlayer: (isHomeTeam: boolean, cap: number) => void;
}

export default MatchLineupRow;
