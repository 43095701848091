import React, { Component } from 'react';
import { PageHeader, Typography } from 'antd';
import authService from './api-authorization/AuthorizeService';

const { Link } = Typography;

interface Props {}

export class Home extends Component<Props> {
    static displayName = Home.name;

    renewToken = (): void => authService.renewToken();

    render() {
        let isDev = process.env.NODE_ENV.toUpperCase() === 'DEVELOPMENT';

        return (
            <div>
                <PageHeader title="Total Waterpolo Arena" style={{ padding: '16px 0px' }} />

                {isDev && (
                    <>
                        <Link href="https://localhost:44347/swagger" target="_blank">
                            OpenAPI Specification
                        </Link>
                        <br></br>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <Link onClick={() => this.renewToken()}>Refresh token</Link>
                    </>
                )}
            </div>
        );
    }
}
