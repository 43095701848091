import { RouteComponentProps, withRouter } from 'react-router-dom';
import { PlayerVm } from '../../../utils/api';
import { RouteParams } from '../../../core/models/RouteHelper';
import PlayerTable from './PlayerTable';
import { Profile } from 'oidc-client';

interface OwnProps {
    userProfile: Profile;
    playersFromProps?: PlayerVm[];
    wrappedComponentRef?: any;
    onDelete?: (id?: number) => Promise<void>;
    clubId?: number;
    teamId?: number;
}

export type Props = OwnProps & RouteComponentProps<RouteParams>;

export default withRouter(PlayerTable);
