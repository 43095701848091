import moment from 'moment';
import React from 'react';
import DatePickerFilter from '../components/filters/DatePickerFilter';
import RadioFilter from '../components/filters/RadioFilter';
import SearchFilter from '../components/filters/SearchFilter';
import SelectFilter from '../components/filters/SelectFilter';
import { DropdownOption } from '../core/models/DropdownOption';
import { FilterItemOperationEnum } from '../core/models/enum';
import { Filter, FilterRequest } from '../core/models/Filter';
import { FilterItemOfDateTime } from '../utils/api';

export const getRadioFilter = (options: DropdownOption[]): any => ({
    filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
    }: {
        setSelectedKeys: Function;
        selectedKeys: string[];
        confirm: Function;
        clearFilters: Function;
    }): React.ReactElement => (
        <RadioFilter
            setSelectedKeys={setSelectedKeys}
            selectedKeys={selectedKeys}
            confirm={confirm}
            clearFilters={clearFilters}
            options={options}
        />
    ),
});

export const getSelectFilter = (options: DropdownOption[]): any => ({
    filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
    }: {
        setSelectedKeys: Function;
        selectedKeys: string[];
        confirm: Function;
        clearFilters: Function;
    }): React.ReactElement => (
        <SelectFilter
            setSelectedKeys={setSelectedKeys}
            selectedKeys={selectedKeys}
            confirm={confirm}
            clearFilters={clearFilters}
            options={options}
            placeholder={<div />}
        />
    ),
});

export const getSearchFilter = (): any => ({
    filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
    }: {
        setSelectedKeys: Function;
        selectedKeys: string[];
        confirm: Function;
        clearFilters: Function;
    }): React.ReactElement => (
        <SearchFilter
            setSelectedKeys={setSelectedKeys}
            selectedKeys={selectedKeys}
            confirm={confirm}
            clearFilters={clearFilters}
        />
    ),
});

export const getDatePickerFilter = (): any => ({
    filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
    }: {
        setSelectedKeys: Function;
        selectedKeys: Date[];
        confirm: Function;
        clearFilters: Function;
    }): React.ReactElement => (
        <DatePickerFilter
            setSelectedKeys={setSelectedKeys}
            selectedKeys={selectedKeys}
            confirm={confirm}
            clearFilters={clearFilters}
        />
    ),
});

export const defaultPageIndex = 1;
export const defaultPageSize = 10;

export const DefaultFilter: Filter = {
    pageIndex: defaultPageIndex,
    pageSize: defaultPageSize,
};

export const getDateRangeFromOption = (option: string): FilterItemOfDateTime[] => {
    switch(option) {
        case '1':
            return [
                new FilterItemOfDateTime({
                    value: moment.utc(new Date()).startOf('week').toDate(),
                    operation: FilterItemOperationEnum.GreaterThanEqual
                }),
                new FilterItemOfDateTime({
                    value: moment.utc(new Date()).endOf('week').toDate(),
                    operation: FilterItemOperationEnum.LessThanEqual
                }),
            ];
        case '2':
            return [
                new FilterItemOfDateTime({
                    value: moment.utc(new Date()).startOf('month').toDate(),
                    operation: FilterItemOperationEnum.GreaterThanEqual
                }),
                new FilterItemOfDateTime({
                    value: moment.utc(new Date()).endOf('month').toDate(),
                    operation: FilterItemOperationEnum.LessThanEqual
                }),
            ];
        case '3':
            return [
                new FilterItemOfDateTime({
                    value: moment.utc(new Date()).add(-7, 'day').startOf('week').toDate(),
                    operation: FilterItemOperationEnum.GreaterThanEqual
                }),
                new FilterItemOfDateTime({
                    value: moment.utc(new Date()).add(-7, 'day').endOf('week').toDate(),
                    operation: FilterItemOperationEnum.LessThanEqual
                }),
            ];
        default: return [];
    }
};

export const buildFilterRequest = (filters: any): Partial<FilterRequest> => {
    const filterRequest = { ...filters };
    Object.keys(filters)
        .filter((key: string): boolean => filters[key] === null || filters[key].length < 1)
        .forEach((key: string): boolean => delete filterRequest[key]);

    Object.keys(filterRequest).forEach((key: string): void => {
        if (singleValueProps.includes(key)) {
            [filterRequest[key]] = [...filterRequest[key]];
        } else if (dateRangeProps.includes(key)) {
            const [[start, end]] = filterRequest[key];
            filterRequest[key] = { start, end };
        } else {
            return;
        }
    });

    return filterRequest as FilterRequest;
};

export const singleValueProps: string[] = [
    'activeFrom',
    'activeTo',
    'awayTeam',
    'awayTeamDisplayName',
    'category',
    'city',
    'cityOfBirth',
    'club',
    'clubDisplayName',
    'competition',
    'competitionDisplayName',
    'competitionType',
    'country',
    'countryOfBirth',
    'email',
    'emailConfirmed',
    'endDate',
    'federation',
    'isPro',
    'homeTeam',
    'homeTeamDisplayName',
    'name',
    'nameUniversal',
    'person',
    'round',
    'shortName',
    'startDate',
    'status',
    'surname',
    'type',
    'venue',
];

export const MatchStartDateOptions: DropdownOption[] = [
    {
        id: '1',
        name: 'This week'
    },
    {
        id: '2',
        name: 'This month',
    },
    {
        id: '3',
        name: 'Last week'
    },
];

export const dateRangeProps: string[] = ['dateRange'];
