import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import React from 'react';
import './styles.css';

interface Props {
    selectedKeys: string[];
    confirm: Function;
    setSelectedKeys: Function;
    clearFilters: Function;
}

class SearchFilter extends React.Component<Props> {
    private handleSearch = (confirm: Function): void => {
        confirm();
    };

    private handleReset = (clearFilters: Function): void => {
        clearFilters();
    };

    public render = (): React.ReactElement => {
        const { selectedKeys, setSelectedKeys, clearFilters, confirm } = this.props;

        return (
            <div className="search-filter">
                <Input
                    ref={(input: any): void => input && input.focus()}
                    placeholder="Search"
                    value={selectedKeys[0]}
                    onChange={(e): void => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={(): void => this.handleSearch(confirm)}
                    style={{ width: 188 }}
                />
                <Button
                    type="primary"
                    onClick={(): void => this.handleSearch(confirm)}
                    icon={
                        <SearchOutlined
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        />
                    }
                    size="small"
                    style={{ marginLeft: 5 }}
                />
                <Button
                    onClick={(): void => this.handleReset(clearFilters)}
                    size="small"
                    icon={
                        <CloseOutlined
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        />
                    }
                    style={{ marginLeft: 5 }}
                />
            </div>
        );
    };
}

export default SearchFilter;
