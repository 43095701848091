import { RouteComponentProps, withRouter } from 'react-router-dom';
import { DrawerState } from '../../../core/models/enum';
import { UserFormInitialValues } from '../../../core/models/InitialValues';
import { UserVm } from '../../../utils/api';
import { RouteParams } from '../../../core/models/RouteHelper';
import UserForm from './UserForm';

interface OwnProps {
    user?: UserVm;
    initialValues?: UserFormInitialValues;
    handleClose: (drawerMode: DrawerState, id?: string) => void;
    refreshAfterSave?: () => void;
}

export type Props = OwnProps & RouteComponentProps<RouteParams>;

export default withRouter(UserForm);
