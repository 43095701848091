import React from 'react';
import { Props } from '.';
import {
    formatTime,
    getEventIcon,
    getEventSubtitle,
    getEventTitle,
} from '../../../../helpers/EventHelper';
import {
    CoachRoleEnum,
    EventTypeEnum,
    MatchCoachVm,
    TurnoverTypeEnum,
} from '../../../../utils/api';

class MatchLogItem extends React.PureComponent<Props> {
    public render = (): React.ReactElement => {
        const { event, isHomeTeam, lineups, lineup, coaches, editEvent } = this.props;

        // the first class has white background and the second one has blue background
        const className = isHomeTeam ? 'c-event--goal-white-event' : 'c-event--goal-blue-event';
        const cardedCoach = coaches.find(
            (c: MatchCoachVm): boolean => event.card?.cardedCoachId === c.id
        );
        const isClockExpiredTurnover =
            event.typeId === EventTypeEnum.Turnover &&
            event.turnover?.typeId === TurnoverTypeEnum.Clock_Expired;
        const lineupNumber = lineup && !isClockExpiredTurnover ? lineup.capNumber || lineup.number : '';

        return (
            <div
                className={`c-event ${className}`}
                onClick={(): void => editEvent(event)}
                role="button"
            >
                <span className="c-event__player-nbr">
                    {lineupNumber}
                    {cardedCoach ? CoachRoleEnum[cardedCoach.roleId].replace('_', ' ') : ''}
                </span>
                <span className="c-event__time">{formatTime(event.minute, event.seconds)}</span>
                <span className="c-event__icon">{getEventIcon(event)}</span>
                <span className="c-event__event">
                    <span className="c-event__event-name">{getEventTitle(event)}</span>
                    <span className="c-event__event-note">{getEventSubtitle(event, lineups)}</span>
                </span>
            </div>
        );
    };
}

export default MatchLogItem;
