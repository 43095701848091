import { RouteComponentProps, withRouter } from 'react-router-dom';
import { DrawerState } from '../../../core/models/enum';
import { TeamFormInitialValues } from '../../../core/models/InitialValues';
import { TeamDetailVm } from '../../../utils/api';
import { RouteParams } from '../../../core/models/RouteHelper';
import TeamForm from './TeamForm';

interface OwnProps {
    team?: TeamDetailVm;
    initialValues?: TeamFormInitialValues;
    handleClose: (drawerMode: DrawerState, id?: string) => void;
    refreshAfterSave: () => void;
}

export type Props = OwnProps & RouteComponentProps<RouteParams>;

export default withRouter(TeamForm);
