import { CompetitionVm, MatchDetailVm, TeamVm } from '../../../utils/api';
import MatchScoreboard from './MatchScoreboard';

export interface Props {
    match?: MatchDetailVm;
    competition?: CompetitionVm;
    homeTeam?: TeamVm;
    awayTeam?: TeamVm;
}

export default MatchScoreboard;
