import { RouteComponentProps, withRouter } from 'react-router-dom';
import { PlayerDetailVm } from '../../../../utils/api';
import { RouteParams } from '../../../../core/models/RouteHelper';
import PlayerDetails from './PlayerDetails';
import { Profile } from 'oidc-client';

interface OwnProps {
    userProfile: Profile;
    isInitialEdit: boolean;
    player: PlayerDetailVm;
    refreshAfterSave: () => Promise<void>;
}

export type Props = OwnProps & RouteComponentProps<RouteParams>;

export default withRouter(PlayerDetails);
