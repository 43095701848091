import React from 'react';
import { Props } from '.';
import CardIcon from '../../../../components/card-icon/CardIcon';
import Icon from '../../../../components/icon/Icon';
import { countExclusions } from '../../../../helpers/EventHelper';
import {
    CardTypeEnum,
    CoachRoleEnum,
    EventTypeEnum,
    EventVm,
    ExclusionTypeEnum,
} from '../../../../utils/api';

class MatchCoachLineupRow extends React.PureComponent<Props> {
    public render = (): React.ReactElement => {
        const { match, events, isHomeTeam, coach, initEvent } = this.props;

        const teamId = match ? (isHomeTeam ? match.homeTeamId : match.awayTeamId) : null;
        const commitedExclusions = countExclusions(events);

        const yellowCard = (
            <div
                key={1}
                onClick={
                    match
                        ? (): void =>
                              initEvent(EventTypeEnum.Card, {
                                  teamId: teamId,
                                  cardedCoachId: coach.id,
                                  typeId: CardTypeEnum.Yellow,
                              })
                        : undefined
                }
                className="c-cards__btn c-cards__btn--yellow-card"
                role="button"
            >
                <div className="c-cards__btn-inner">
                    <span className="fake-svg">
                        <CardIcon type={CardTypeEnum.Yellow} />
                    </span>
                </div>
            </div>
        );

        const redCard = (
            <div
                key={2}
                onClick={
                    match
                        ? (): void =>
                              initEvent(EventTypeEnum.Card, {
                                  teamId: teamId,
                                  cardedCoachId: coach.id,
                                  typeId: CardTypeEnum.Red,
                              })
                        : undefined
                }
                className="c-cards__btn c-cards__btn--red-card"
                role="button"
            >
                <span className="fake-svg">
                    <CardIcon type={CardTypeEnum.Red} />
                </span>
            </div>
        );

        const exclusions = (
            <div
                key={3}
                onClick={
                    match
                        ? (): void =>
                              initEvent(EventTypeEnum.Exclusion, {
                                  teamId: teamId,
                                  excludedCoachId: coach.id,
                                  typeId: ExclusionTypeEnum.Penalty,
                              })
                        : undefined
                }
                className="c-cards__btn"
                role="button"
            >
                <div className="c-cards__btn-inner">
                    <Icon name="icon-times" />
                    <span>
                        &nbsp;
                        {commitedExclusions > 0 ? commitedExclusions : ''}
                    </span>
                </div>
            </div>
        );

        const coachCard = (
            <div
                key={4}
                onClick={
                    match
                        ? (): void =>
                              initEvent(EventTypeEnum.Exclusion, {
                                  teamId: teamId,
                                  excludedCoachId: coach.id,
                                  typeId: ExclusionTypeEnum.Penalty,
                              })
                        : undefined
                }
                className="c-cards__btn "
                role="button"
            >
                <div className="c-cards__btn-inner">
                    <span className="fake-svg">
                        {coach.roleId === CoachRoleEnum.Head_Coach ? 'HC' : 'AC'}
                    </span>
                </div>
            </div>
        );

        const render = [yellowCard, redCard, exclusions, coachCard];
        const yellowCardEvents = events.filter(
            (e: EventVm): boolean => e.card?.typeId === CardTypeEnum.Yellow
        );
        const redCardEvents = events.filter(
            (e: EventVm): boolean => e.card?.typeId === CardTypeEnum.Red
        );

        let classname = '';
        if (yellowCardEvents.length > 0) {
            classname += 'is-yellow-card-active c-cards__row--';
        }
        if (redCardEvents.length > 0) {
            classname += 'is-red-card-active';
        }

        return (
            <div
                className={`c-cards__row c-cards__row--${
                    isHomeTeam ? 'white' : 'blue'
                }-team c-cards__row--${classname}`}
            >
                {render}
            </div>
        );
    };
}

export default MatchCoachLineupRow;
