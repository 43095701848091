import TableActionButtons from './TableActionButtons';

interface OwnProps {
    id?: number;
    confirmMessage?: string;
    onUpdateAction?: (id?: number) => Promise<void>;
    onDeleteAction?: (id?: number) => Promise<any>;
    isUserAllowedToDelete: boolean;
    isUserAllowedToUpdate: boolean;
}

export type Props = OwnProps;

export default TableActionButtons;
