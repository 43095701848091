import { Layout } from 'antd';
import React from 'react';
import Icon from '../icon/Icon';
import GoalScored from '../../assets/images/goal-scored-1500x583.png';
import GoalMissed from '../../assets/images/goal-missed-1500x583.png';

interface Props {
    isGoal: boolean;
    x?: number;
    y?: number;
    onSelect: (x: number, y: number) => void;
}

class ShotTarget extends React.PureComponent<Props> {
    private onTargetSelect = (event: React.MouseEvent<HTMLImageElement, MouseEvent>): void => {
        const { onSelect } = this.props;
        const img = event.target as HTMLImageElement;
        const x = Math.round((event.nativeEvent.offsetX / img.width) * 1000) / 1000;
        const y = Math.round((event.nativeEvent.offsetY / img.height) * 1000) / 1000;

        onSelect(x, y);
    };

    public render = (): React.ReactElement => {
        const { x, y, isGoal } = this.props;
        const image = isGoal ? GoalScored : GoalMissed;
        return (
            <Layout className="shot-target noselect">
                <div
                    onClick={(event: React.MouseEvent<HTMLImageElement, MouseEvent>): void =>
                        this.onTargetSelect(event)
                    }
                >
                    <img src={image} alt="shot target" />
                    {x && y && (
                        <Icon
                            name="icon-crosshair"
                            className="marker"
                            style={{ top: `${y * 100}%`, left: `${x * 100}%` }}
                        />
                    )}
                </div>
            </Layout>
        );
    };
}

export default ShotTarget;
