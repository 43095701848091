import React from 'react';
import { CardTypeEnum } from '../../utils/api';

interface Props {
    type: CardTypeEnum;
}

class CardIcon extends React.PureComponent<Props> {
    public render = (): React.ReactElement => {
        const { type } = this.props;

        let color = '';
        switch (type) {
            case CardTypeEnum.Yellow:
                color = '#ff0';
                break;
            case CardTypeEnum.Red:
                color = '#f00';
                break;
            default:
                break;
        }

        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1 1">
                <path d="M0,0h1v1H0" fill={color} />
            </svg>
        );
    };
}

export default CardIcon;
