import { message, Modal } from 'antd';
import * as log from 'loglevel';
import { ReactNode } from 'react';
import './styles.css';

const { confirm, info, error } = Modal;

export const success = (msg: string): void => {
    message.success(msg);
};

export const errorWithUserMessage = (msg: any): void => {
    if (!msg) {
        log.error('Bug with passing arguments, no message to be logged!');
    }
    log.error(msg);
    if (typeof msg === 'string') {
        message.error(msg);
    } else if (msg.message) {
        message.error(msg.message);
    } else {
        message.error('Unknown error occured. Please contact developers.');
    }
};

export const popupConfirmWithCallback = (
    callbackFunction: Function,
    callbackArguments?: any,
    message?: string,
    callbackOnCancel?: any,
    content?: ReactNode
): void => {
    confirm({
        title: message || 'Are you sure?',
        onOk(): void {
            callbackFunction(callbackArguments);
        },
        onCancel(): void {
            if (callbackOnCancel) {
                callbackOnCancel();
            }
        },
        content,
    });
};

export const popupConfirmDeleteWithCallback = (
    callbackFunction: Function,
    callbackArguments?: any,
    message?: string,
    confirmButtonText?: string,
    callbackOnCancel?: any,
    content?: ReactNode
): void => {
    confirm({
        title: message || 'Are you sure?',
        onOk(): void {
            callbackFunction(callbackArguments);
        },
        onCancel(): void {
            if (callbackOnCancel) {
                callbackOnCancel();
            }
        },
        okButtonProps: { danger: true, className: 'modal-delete-button' },
        okText: confirmButtonText || 'Delete',
        content,
    });
};

export const popupConfirm = (title: string): void => {
    info({
        title,
    });
};

export const popupError = (title: string): void => {
    error({
        title,
    });
};
