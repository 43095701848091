import { Table } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import React from 'react';
import { Link } from 'react-router-dom';
import Routes from '../../../config/routes';
import ReplaceStrings from '../../../config/replaceStrings';
import { ActionOption, ModuleName, SortDirectionEnum } from '../../../core/models/enum';
import { Filter } from '../../../core/models/Filter';
import { isUserAllowed } from '../../../helpers/CheckPermissionHelper';
import { buildFilterRequest, DefaultFilter, getSearchFilter } from '../../../helpers/FilterHelper';
import { PaginationOptions } from '../../../helpers/PaginationHelper';
import { feTableFilterHelper } from '../../../helpers/TableFilterHelper';
import { capitalizeFirstLetter } from '../../../helpers/TransformHelper';
import { CoachesClient, CoachVm, PaginatedListOfCoachVm } from '../../../utils/api';
import { Props } from './index';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { popupConfirmDeleteWithCallback } from '../../../helpers/NotificationHelper';

interface State {
    coaches: CoachVm[];
    paginationOptions: TablePaginationConfig;
    currentFilter: Filter;
    loading: boolean;
    isUserAllowedToUpdate: boolean;
    isUserAllowedToDelete: boolean;
}

class CoachTable extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            coaches: [],
            paginationOptions: PaginationOptions,
            currentFilter: DefaultFilter,
            loading: true,
            isUserAllowedToUpdate: false,
            isUserAllowedToDelete: false,
        };
    }

    public componentDidMount = (): void => {
        this.getPermissions();
        this.getCoaches();
    };

    private getPermissions = (): void => {
        const { userProfile } = this.props;

        this.setState({
            isUserAllowedToUpdate: isUserAllowed(
                userProfile,
                ModuleName.Coaches,
                ActionOption.Update
            ),
            isUserAllowedToDelete: isUserAllowed(
                userProfile,
                ModuleName.Coaches,
                ActionOption.Delete
            ),
        });
    };

    private getCoaches = async (currentFilterParam?: Filter): Promise<void> => {
        const { currentFilter } = this.state;
        const { coachesFromProps } = this.props;

        this.setState({
            loading: true,
        });

        let coachesPagedList;
        const filter = currentFilterParam || currentFilter;

        if (coachesFromProps) {
            coachesPagedList = this.getCoachesFromProps(filter);
        } else {
            coachesPagedList = await this.getAllCoaches(filter);
        }

        this.setState({
            paginationOptions: {
                ...PaginationOptions,
                current: coachesPagedList.currentPage,
                pageSize: coachesPagedList.pageSize,
                total: coachesPagedList.totalItems,
            },
            coaches: coachesPagedList?.pageItems || [],
            loading: false,
        });
    };

    private getAllCoaches = async (filter: Filter): Promise<PaginatedListOfCoachVm> => {
        const coachClient = new CoachesClient();

        return await coachClient.getPaginated(
            filter.filterRequest?.name,
            filter.filterRequest?.surname,
            filter.filterRequest?.countryOfBirth,
            filter.filterRequest?.cityOfBirth,
            filter.pageIndex,
            filter.pageSize,
            capitalizeFirstLetter(filter.orderByKey),
            filter.orderByValue
        );
    };

    private getCoachesFromProps = (filter: Filter): PaginatedListOfCoachVm => {
        const { coachesFromProps } = this.props;

        const filteredResult = feTableFilterHelper(coachesFromProps || [], filter);
        return new PaginatedListOfCoachVm({
            ...filteredResult,
            pageItems: filteredResult.pageItems as CoachVm[],
        });
    };

    private handleTableChange = (pagination: any, filters: any, sorter: any): void => {
        const filterRequest = buildFilterRequest(filters);

        const filter: Filter = {
            pageIndex: pagination.current,
            pageSize: pagination.pageSize,
            orderByKey: sorter?.columnKey && sorter?.order ? sorter.columnKey : undefined,
            orderByValue: sorter?.order ? sorter.order : undefined,
            filterRequest,
        };

        this.getCoaches(filter);
        this.setState({
            currentFilter: filter,
        });
    };

    private handleDeleteHelper = async (id?: number): Promise<any> => {
        const { onDelete } = this.props;

        if (onDelete) {
            onDelete(id);
        }
    };

    public render(): React.ReactElement {
        const {
            coaches,
            loading,
            paginationOptions,
            isUserAllowedToDelete,
            isUserAllowedToUpdate,
        } = this.state;
        const { coachesFromProps, history, clubId, teamId } = this.props;

        const columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                ...getSearchFilter(),
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: (): void => {},
            },
            {
                title: 'Surname',
                dataIndex: 'surname',
                key: 'surname',
                ...getSearchFilter(),
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: (): void => {},
            },
            {
                title: 'Country',
                dataIndex: 'countryOfBirth',
                key: 'countryOfBirth',
                ...getSearchFilter(),
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: (): void => {},
            },
            {
                title: 'City',
                dataIndex: 'cityOfBirth',
                key: 'cityOfBirth',
                ...getSearchFilter(),
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: (): void => {},
            },
        ];

        if (!coachesFromProps) {
            columns.push({
                dataIndex: '',
                render: (value: CoachVm): React.ReactElement =>
                    value && value.id ? (
                        <Link
                            to={Routes.ROUTE_COACH_PERSONAL_INFO.replace(
                                ReplaceStrings.COACH_ID,
                                value.id.toString()
                            )}
                        >
                            Details
                        </Link>
                    ) : (
                        <span />
                    ),
                width: 80,
            });
        } else {
            columns.push({
                title: '',
                dataIndex: '',
                key: '',
                render: (value: CoachVm): React.ReactElement =>
                    value && value.id ? (
                        <>
                            {isUserAllowedToUpdate && clubId && (
                                <EditOutlined
                                    onClick={(): void =>
                                        history.push(
                                            Routes.ROUTE_CLUB_COACHES_EDIT.replace(
                                                ReplaceStrings.COACH_ID,
                                                value.id.toString()
                                            ).replace(ReplaceStrings.CLUB_ID, clubId.toString())
                                        )
                                    }
                                    style={{ color: '#1890ff' }}
                                />
                            )}
                            {isUserAllowedToUpdate && teamId && (
                                <EditOutlined
                                    onClick={(): void =>
                                        history.push(
                                            Routes.ROUTE_TEAM_COACHES_EDIT.replace(
                                                ReplaceStrings.COACH_ID,
                                                value.id.toString()
                                            ).replace(ReplaceStrings.TEAM_ID, teamId.toString())
                                        )
                                    }
                                    style={{ color: '#1890ff' }}
                                />
                            )}
                            {isUserAllowedToDelete && (
                                <DeleteOutlined
                                    onClick={(): void =>
                                        popupConfirmDeleteWithCallback(
                                            (): Promise<void> => this.handleDeleteHelper(value.id),
                                            undefined,
                                            'Are you sure you want to remove this coach?',
                                            'Delete'
                                        )
                                    }
                                    className="text-danger-custom"
                                    style={{ marginLeft: 10 }}
                                />
                            )}
                        </>
                    ) : (
                        <span />
                    ),
                width: 80,
            });
        }

        return (
            <Table
                columns={columns}
                loading={loading}
                dataSource={coaches}
                rowKey={(record): string => (record && record.id ? record.id.toString() : '-1')}
                pagination={paginationOptions}
                onChange={this.handleTableChange}
                bordered
                size="small"
            />
        );
    }
}

export default CoachTable;
