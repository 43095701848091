import React from 'react';
import { LINEUP_LIMIT } from '../../../config/settings';
import { getPlayerNumber, getTeamId } from '../../../helpers/EventHelper';
import { CreateMatchPlayerCommandOfMatchPlayer, EventVm } from '../../../utils/api';
import { Props } from './index';
import MatchLineupRow from './match-lineup-row';

class MatchLineup extends React.PureComponent<Props> {
    private createLineup = (): React.ReactNode => {
        const {
            events,
            lineup,
            players,
            isHomeTeam,
            handleSelectLineupPlayer,
            handleClearLineupPlayer,
        } = this.props;

        const lineupArray = [];

        for (let i = 1; i <= LINEUP_LIMIT; i += 1) {
            const lineupRequest = lineup.find(
                (l: CreateMatchPlayerCommandOfMatchPlayer): boolean => l.number === i
            );
            const lineupPlayerEvents =
                lineupRequest && events
                    ? events.filter(
                        (e: EventVm): boolean =>
                            getPlayerNumber(e) === lineupRequest.number &&
                            lineupRequest.teamId === getTeamId(e)
                    )
                    : [];
            lineupArray.push(
                <MatchLineupRow
                    key={i}
                    cap={i}
                    capNumber={lineupRequest?.capNumber || i}
                    isHomeTeam={isHomeTeam}
                    lineupRequest={lineupRequest}
                    lineup={lineup}
                    players={players}
                    events={lineupPlayerEvents}
                    handleSelectLineupPlayer={handleSelectLineupPlayer}
                    handleClearLineupPlayer={handleClearLineupPlayer}
                />
            );
        }
        return lineupArray;
    };

    public render = (): React.ReactElement => {
        const { isHomeTeam } = this.props;

        const goalsScoredHeader = (
            <div key={1} className="c-player-list__goal-scored">
                <p>Goals scored</p>
            </div>
        );
        const majorFoulsHeader = (
            <div key={2} className="c-player-list__major-fouls">
                <p>Major Fouls</p>
            </div>
        );
        const playerNameHeader = (
            <div key={3} className="c-player-list__name">
                &nbsp;
            </div>
        );
        const playerCapHeader = (
            <div key={4} className="c-player-list__nbr">
                &nbsp;
            </div>
        );

        const goalsScoredSubheader = (
            <div
                key={1}
                className="c-player-list__goal-scored u-flex u-flex-row u-justify-content-between u-align-items-start"
            >
                <div className="c-player-list__goal-scored-item">P1</div>
                <div className="c-player-list__goal-scored-item">P2</div>
                <div className="c-player-list__goal-scored-item">P3</div>
                <div className="c-player-list__goal-scored-item">P4</div>
                <div className="c-player-list__goal-scored-item">OT</div>
            </div>
        );
        const majorFoulsSubheader = (
            <div
                key={2}
                className="c-player-list__major-fouls u-flex u-flex-row u-justify-content-between u-align-items-start"
            >
                <div className="c-player-list__major-fouls-item">1.</div>
                <div className="c-player-list__major-fouls-item">2.</div>
                <div className="c-player-list__major-fouls-item">3.</div>
            </div>
        );
        const playerNameSubheader = (
            <div
                key={3}
                className="c-player-list__name u-flex u-flex-row u-justify-content-between u-align-items-start"
            >
                <div className="c-player-list__name-item">Name</div>
            </div>
        );
        const playerCapSubheader = (
            <div
                key={4}
                className="c-player-list__nbr u-flex u-flex-row u-justify-content-between u-align-items-start"
            >
                <div className="c-player-list__nbr-item" />
            </div>
        );

        // setting order of column headers
        const columnHeaders = isHomeTeam
            ? [goalsScoredHeader, majorFoulsHeader, playerNameHeader, playerCapHeader]
            : [playerCapHeader, playerNameHeader, majorFoulsHeader, goalsScoredHeader];
        const columnSubheaders = isHomeTeam
            ? [goalsScoredSubheader, majorFoulsSubheader, playerNameSubheader, playerCapSubheader]
            : [playerCapSubheader, playerNameSubheader, majorFoulsSubheader, goalsScoredSubheader];

        return (
            <div className={`c-player-list--${isHomeTeam ? 'white' : 'blue'}`}>
                <div className="c-player-list__top u-flex u-flex-row u-justify-content-between">
                    {columnHeaders}
                </div>

                <div className="c-player-list__header u-flex u-flex-row u-justify-content-between">
                    {columnSubheaders}
                </div>

                <div className="c-player-list__body u-flex u-flex-column">
                    {this.createLineup()}
                </div>
            </div>
        );
    };
}

export default MatchLineup;
