import { CheckOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import React from 'react';
import { DropdownOption } from '../../../core/models/DropdownOption';
import {
    CoachRoleEnum,
    CreateMatchCoachCommandOfMatchCoach,
    CreateMatchOfficialCommandOfMatchOfficial,
    EventTypeEnum,
    EventVm,
    MatchOfficialVm,
    OfficialRoleEnum,
} from '../../../utils/api';
import { Props } from './index';

const { Option } = Select;

class MatchOfficials extends React.PureComponent<Props> {
    private filterOption = (input: string, option: any): boolean => {
        const value = Array.isArray(option.props.children)
            ? option.props.children[0]
            : option.props.children;

        return value.toUpperCase().indexOf(input.toUpperCase()) !== -1;
    };

    public render = (): React.ReactElement => {
        const {
            homeHeadCoach,
            homeAssistantCoaches,
            awayHeadCoach,
            awayAssistantCoaches,
            referee1,
            referee2,
            director,
            delegates,
            goalReferees,
            secretaries,
            timekeepers,
            vars,
            coaches,
            officials,
            handleSelectCoach,
            handleClearCoach,
            handleSelectOfficial,
            handleClearOfficial,
            events,
            match,
        } = this.props;

        const coachOptions = coaches.map(
            (c: DropdownOption): React.ReactElement => (
                <Option key={parseInt(c.id, 10)} value={parseInt(c.id, 10)} disabled={c.disabled}>
                    {c.name}
                </Option>
            )
        );
        const officialOptions = officials.map(
            (o: DropdownOption): React.ReactElement => {
                const matchOfficial = match?.officials?.find(
                    (mo: MatchOfficialVm): boolean => mo.officialId === parseInt(o.id, 10)
                );
                return (
                    <Option key={o.id} value={o.id} disabled={o.disabled}>
                        {o.name}
                        {matchOfficial?.confirmed && (
                            <CheckOutlined
                                style={{
                                    marginLeft: 5,
                                    display: 'inline-flex',
                                    color: '#66cc33',
                                }}
                            />
                        )}
                    </Option>
                );
            }
        );

        const timeoutsHome =
            events?.filter(
                (e: EventVm): boolean =>
                    e.typeId === EventTypeEnum.Timeout && e.timeout?.teamId === match?.homeTeamId
            ).length || 0;
        const timeoutsAway =
            events?.filter(
                (e: EventVm): boolean =>
                    e.typeId === EventTypeEnum.Timeout && e.timeout?.teamId === match?.awayTeamId
            ).length || 0;
        
        const challengesHome =
            events?.filter(
                (e: EventVm): boolean =>
                    e.typeId === EventTypeEnum.Challenge && e.challenge?.teamId === match?.homeTeamId
            ).length || 0;
        const challengesAway =
            events?.filter(
                (e: EventVm): boolean =>
                    e.typeId === EventTypeEnum.Challenge && e.challenge?.teamId === match?.awayTeamId
            ).length || 0;


        return (
            <div className="c-gl-main u-flex u-justify-content-between">
                <div className="c-gl-main__white u-flex u-flex-column">
                    <div className="c-gl-timeout u-flex u-flex-row u-align-items-start">
                        <div className="c-to c-to--white">
                            TO:
                            {timeoutsHome}
                        </div>
                    </div>
                    <div className="c-gl-timeout u-flex u-flex-row u-align-items-start">
                        <div className="c-to c-to--white">
                            CH:
                            {challengesHome}
                        </div>
                    </div>
                    <div className="c-gl-coaches">
                        <div className="c-choach c-choach--white u-flex u-flex-row u-align-items-start">
                            <div className="c-choach__name">
                                <Select
                                    showSearch
                                    allowClear
                                    style={{ width: '100%', minHeight: 30 }}
                                    placeholder="Select Home Head Coach"
                                    optionFilterProp="children"
                                    onSelect={(value: number): void =>
                                        handleSelectCoach(true, CoachRoleEnum.Head_Coach, value)
                                    }
                                    onChange={(value: number): void =>
                                        !value
                                            ? handleClearCoach(true, CoachRoleEnum.Head_Coach)
                                            : undefined
                                    }
                                    value={homeHeadCoach?.coachId}
                                    filterOption={(input: string, option: any): boolean =>
                                        this.filterOption(input, option)
                                    }
                                >
                                    {coachOptions}
                                </Select>
                            </div>
                            <div className="c-choach__mark">
                                <input
                                    type="text"
                                    placeholder="HC"
                                    title="Home Head Coach"
                                    value=""
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="c-choach c-choach--white u-flex u-flex-row">
                            <div className="c-choach__name">
                                <Select
                                    showSearch
                                    allowClear
                                    mode="multiple"
                                    style={{ width: '100%', minHeight: 30 }}
                                    placeholder="Select Home Assistant Coaches"
                                    optionFilterProp="children"
                                    onSelect={(value: number): void =>
                                        handleSelectCoach(
                                            true,
                                            CoachRoleEnum.Assistant_Coach,
                                            value
                                        )
                                    }
                                    onChange={(value: number[]): void =>
                                        handleClearCoach(true, CoachRoleEnum.Assistant_Coach, value)
                                    }
                                    value={homeAssistantCoaches.map(
                                        (c: CreateMatchCoachCommandOfMatchCoach): number =>
                                            c.coachId
                                    )}
                                    filterOption={(input: string, option: any): boolean =>
                                        this.filterOption(input, option)
                                    }
                                >
                                    {coachOptions}
                                </Select>
                            </div>
                            <div className="c-choach__mark">
                                <input
                                    type="text"
                                    placeholder="AC"
                                    title="Home Assistant Coaches"
                                    value=""
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="c-gl-main__middle u-flex u-flex-row u-flex-wrap u-justify-content-between u-align-items-start">
                    <div className="c-gl-main__ref">
                        <div className="u-flex u-flex-row u-flex-row-reverse u-align-items-start">
                            <div className="c-input">
                                <Select
                                    showSearch
                                    allowClear
                                    style={{ width: '100%', minHeight: 30 }}
                                    placeholder="Select Referee 1"
                                    optionFilterProp="children"
                                    onSelect={(value: string): void =>
                                        handleSelectOfficial(
                                            OfficialRoleEnum.Referee,
                                            parseInt(value, 10),
                                            1
                                        )
                                    }
                                    onChange={(value: string): void =>
                                        !value
                                            ? handleClearOfficial(
                                                  OfficialRoleEnum.Referee,
                                                  undefined,
                                                  1
                                              )
                                            : undefined
                                    }
                                    value={referee1?.officialId?.toString()}
                                    filterOption={(input: string, option: any): boolean =>
                                        this.filterOption(input, option)
                                    }
                                >
                                    {officialOptions}
                                </Select>
                            </div>
                            <div className="c-label">
                                <input
                                    type="text"
                                    placeholder="REF"
                                    title="Referee 1"
                                    value=""
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>
                    <div className="c-gl-main__ref">
                        <div className="u-flex u-flex-row u-flex-row-reverse u-align-items-start">
                            <div className="c-input">
                                <Select
                                    showSearch
                                    allowClear
                                    style={{ width: '100%', minHeight: 30 }}
                                    placeholder="Select Referee 2"
                                    optionFilterProp="children"
                                    onSelect={(value: string): void =>
                                        handleSelectOfficial(
                                            OfficialRoleEnum.Referee,
                                            parseInt(value, 10),
                                            2
                                        )
                                    }
                                    onChange={(value: string): void =>
                                        !value
                                            ? handleClearOfficial(
                                                  OfficialRoleEnum.Referee,
                                                  undefined,
                                                  2
                                              )
                                            : undefined
                                    }
                                    value={referee2?.officialId?.toString()}
                                    filterOption={(input: string, option: any): boolean =>
                                        this.filterOption(input, option)
                                    }
                                >
                                    {officialOptions}
                                </Select>
                            </div>
                            <div className="c-label">
                                <input
                                    type="text"
                                    placeholder="REF"
                                    title="Referee 2"
                                    value=""
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>
                    <div className="c-gl-main__director">
                        <div className="u-flex u-flex-row u-flex-row-reverse u-align-items-start">
                            <div className="c-input">
                                <Select
                                    showSearch
                                    allowClear
                                    style={{ width: '100%', minHeight: 30 }}
                                    placeholder="Select Director"
                                    optionFilterProp="children"
                                    onSelect={(value: string): void =>
                                        handleSelectOfficial(
                                            OfficialRoleEnum.Director,
                                            parseInt(value, 10)
                                        )
                                    }
                                    onChange={(value: string): void =>
                                        !value
                                            ? handleClearOfficial(
                                                  OfficialRoleEnum.Director,
                                                  undefined,
                                                  undefined
                                              )
                                            : undefined
                                    }
                                    value={director?.officialId?.toString()}
                                    filterOption={(input: string, option: any): boolean =>
                                        this.filterOption(input, option)
                                    }
                                >
                                    {officialOptions}
                                </Select>
                            </div>
                            <div className="c-label">
                                <input
                                    type="text"
                                    placeholder="DIR"
                                    title="Director"
                                    value=""
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>
                    <div className="c-gl-main__goal-referees">
                        <div className="u-flex u-flex-row u-flex-row-reverse">
                            <div className="c-input">
                                <Select
                                    showSearch
                                    allowClear
                                    mode="multiple"
                                    style={{ width: '100%', minHeight: 30 }}
                                    placeholder="Select Delegates"
                                    optionFilterProp="children"
                                    onSelect={(value: string): void =>
                                        handleSelectOfficial(
                                            OfficialRoleEnum.Delegate,
                                            parseInt(value, 10)
                                        )
                                    }
                                    onChange={(value: string[]): void =>
                                        handleClearOfficial(
                                            OfficialRoleEnum.Delegate,
                                            value.map((v: string): number => parseInt(v, 10))
                                        )
                                    }
                                    value={delegates.map(
                                        (o: CreateMatchOfficialCommandOfMatchOfficial): string =>
                                            o.officialId.toString()
                                    )}
                                    filterOption={(input: string, option: any): boolean =>
                                        this.filterOption(input, option)
                                    }
                                >
                                    {officialOptions}
                                </Select>
                            </div>
                            <div className="c-label">
                                <input
                                    type="text"
                                    placeholder="DEL"
                                    title="Delegates"
                                    value=""
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>
                    <div className="c-gl-main__goal-referees">
                        <div className="u-flex u-flex-row u-flex-row-reverse">
                            <div className="c-input">
                                <Select
                                    showSearch
                                    allowClear
                                    mode="multiple"
                                    style={{ width: '100%', minHeight: 30 }}
                                    placeholder="Select Goal Referees"
                                    optionFilterProp="children"
                                    onSelect={(value: string): void =>
                                        handleSelectOfficial(
                                            OfficialRoleEnum.Goal_Referee,
                                            parseInt(value, 10)
                                        )
                                    }
                                    onChange={(value: string[]): void =>
                                        handleClearOfficial(
                                            OfficialRoleEnum.Goal_Referee,
                                            value.map((v: string): number => parseInt(v, 10))
                                        )
                                    }
                                    value={goalReferees.map(
                                        (o: CreateMatchOfficialCommandOfMatchOfficial): string =>
                                            o.officialId.toString()
                                    )}
                                    filterOption={(input: string, option: any): boolean =>
                                        this.filterOption(input, option)
                                    }
                                >
                                    {officialOptions}
                                </Select>
                            </div>
                            <div className="c-label">
                                <input
                                    type="text"
                                    placeholder="GR"
                                    title="Goal Referees"
                                    value=""
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>
                    <div className="c-gl-main__secretaries">
                        <div className="u-flex u-flex-row u-flex-row-reverse">
                            <div className="c-input">
                                <Select
                                    showSearch
                                    allowClear
                                    mode="multiple"
                                    style={{ width: '100%', minHeight: 30 }}
                                    placeholder="Select Secretaries"
                                    optionFilterProp="children"
                                    onSelect={(value: string): void =>
                                        handleSelectOfficial(
                                            OfficialRoleEnum.Secretary,
                                            parseInt(value, 10)
                                        )
                                    }
                                    onChange={(value: string[]): void =>
                                        handleClearOfficial(
                                            OfficialRoleEnum.Secretary,
                                            value.map((v: string): number => parseInt(v, 10))
                                        )
                                    }
                                    value={secretaries.map(
                                        (o: CreateMatchOfficialCommandOfMatchOfficial): string =>
                                            o.officialId.toString()
                                    )}
                                    filterOption={(input: string, option: any): boolean =>
                                        this.filterOption(input, option)
                                    }
                                >
                                    {officialOptions}
                                </Select>
                            </div>
                            <div className="c-label">
                                <input
                                    type="text"
                                    placeholder="SEC"
                                    title="Secretaries"
                                    value=""
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>
                    <div className="c-gl-main__timekeepers">
                        <div className="u-flex u-flex-row u-flex-row-reverse">
                            <div className="c-input">
                                <Select
                                    showSearch
                                    allowClear
                                    mode="multiple"
                                    style={{ width: '100%', minHeight: 30 }}
                                    placeholder="Select Timekeepers"
                                    optionFilterProp="children"
                                    onSelect={(value: string): void =>
                                        handleSelectOfficial(
                                            OfficialRoleEnum.Timekeeper,
                                            parseInt(value, 10)
                                        )
                                    }
                                    onChange={(value: string[]): void =>
                                        handleClearOfficial(
                                            OfficialRoleEnum.Timekeeper,
                                            value.map((v: string): number => parseInt(v, 10))
                                        )
                                    }
                                    value={timekeepers.map(
                                        (o: CreateMatchOfficialCommandOfMatchOfficial): string =>
                                            o.officialId.toString()
                                    )}
                                    filterOption={(input: string, option: any): boolean =>
                                        this.filterOption(input, option)
                                    }
                                >
                                    {officialOptions}
                                </Select>
                            </div>
                            <div className="c-label">
                                <input
                                    type="text"
                                    placeholder="TK"
                                    title="Timekeepers"
                                    value=""
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>
                    <div className="c-gl-main__timekeepers">
                        <div className="u-flex u-flex-row u-flex-row-reverse">
                            <div className="c-input">
                                <Select
                                    showSearch
                                    allowClear
                                    mode="multiple"
                                    style={{ width: '100%', minHeight: 30 }}
                                    placeholder="Select VAR Referees"
                                    optionFilterProp="children"
                                    onSelect={(value: string): void =>
                                        handleSelectOfficial(
                                            OfficialRoleEnum.VAR,
                                            parseInt(value, 10)
                                        )
                                    }
                                    onChange={(value: string[]): void =>
                                        handleClearOfficial(
                                            OfficialRoleEnum.VAR,
                                            value.map((v: string): number => parseInt(v, 10))
                                        )
                                    }
                                    value={vars.map(
                                        (o: CreateMatchOfficialCommandOfMatchOfficial): string =>
                                            o.officialId.toString()
                                    )}
                                    filterOption={(input: string, option: any): boolean =>
                                        this.filterOption(input, option)
                                    }
                                >
                                    {officialOptions}
                                </Select>
                            </div>
                            <div className="c-label">
                                <input
                                    type="text"
                                    placeholder="VAR"
                                    title="VAR Referees"
                                    value=""
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="c-gl-main__blue u-flex u-flex-column">
                    <div className="c-gl-timeout u-flex u-flex-row u-flex-row-reverse u-align-items-start">
                        <div className="c-to c-to--white">
                            TO:
                            {timeoutsAway}
                        </div>
                    </div>
                    <div className="c-gl-timeout u-flex u-flex-row u-flex-row-reverse u-align-items-start">
                        <div className="c-to c-to--white">
                            CH:
                            {challengesAway}
                        </div>
                    </div>
                    <div className="c-gl-coaches">
                        <div className="c-choach c-choach--blue u-flex u-flex-row u-flex-row-reverse u-align-items-start">
                            <div className="c-choach__name">
                                <Select
                                    showSearch
                                    allowClear
                                    style={{ width: '100%', height: 30 }}
                                    placeholder="Select Away Head Coach"
                                    optionFilterProp="children"
                                    onSelect={(value: number): void =>
                                        handleSelectCoach(false, CoachRoleEnum.Head_Coach, value)
                                    }
                                    onChange={(value: number): void =>
                                        !value
                                            ? handleClearCoach(false, CoachRoleEnum.Head_Coach)
                                            : undefined
                                    }
                                    value={awayHeadCoach?.coachId}
                                    filterOption={(input: string, option: any): boolean =>
                                        this.filterOption(input, option)
                                    }
                                >
                                    {coachOptions}
                                </Select>
                            </div>
                            <div className="c-choach__mark">
                                <input
                                    type="text"
                                    placeholder="HC"
                                    title="Away Head Coach"
                                    value=""
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="c-choach c-choach--blue u-flex u-flex-row u-flex-row-reverse">
                            <div className="c-choach__name">
                                <Select
                                    showSearch
                                    allowClear
                                    mode="multiple"
                                    style={{ width: '100%', minHeight: 30 }}
                                    placeholder="Select Away Assistant Coaches"
                                    optionFilterProp="children"
                                    onSelect={(value: number): void =>
                                        handleSelectCoach(
                                            false,
                                            CoachRoleEnum.Assistant_Coach,
                                            value
                                        )
                                    }
                                    onChange={(value: number[]): void =>
                                        handleClearCoach(
                                            false,
                                            CoachRoleEnum.Assistant_Coach,
                                            value
                                        )
                                    }
                                    value={awayAssistantCoaches.map(
                                        (c: CreateMatchCoachCommandOfMatchCoach): number =>
                                            c.coachId
                                    )}
                                    filterOption={(input: string, option: any): boolean =>
                                        this.filterOption(input, option)
                                    }
                                >
                                    {coachOptions}
                                </Select>
                            </div>
                            <div className="c-choach__mark">
                                <input
                                    type="text"
                                    placeholder="AC"
                                    title="Away Assistant Coaches"
                                    value=""
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

export default MatchOfficials;
