import { CreateFederationOfficialCommandOfFederationOfficial } from '../../utils/api';
import FederationRoles from './FederationRoles';

interface OwnProps {
    initialFederationRoleId?: number;
    selectedFederationRoles?: CreateFederationOfficialCommandOfFederationOfficial[];
    saveChanges: (federationRole: CreateFederationOfficialCommandOfFederationOfficial) => void;
    removeFederationRoleFromList: (
        federationRole: CreateFederationOfficialCommandOfFederationOfficial
    ) => void;
}

export type Props = OwnProps;

export default FederationRoles;
