import { DropdownOption } from '../core/models/DropdownOption';
import { CitiesClient, CityVm, CountriesClient, CountryVm } from '../utils/api';

export interface EntitiesSelectedEntityHelperModel {
    entities: DropdownOption[];
    selectedEntity: DropdownOption[];
}

export const filterDropdownOptions = (
    value: string,
    entitiesToSearch: DropdownOption[]
): DropdownOption[] => {
    const options: DropdownOption[] = [];

    entitiesToSearch.forEach((entity: DropdownOption): void => {
        if (entity.name.toLowerCase().includes(value.toLowerCase())) {
            options.push(entity);
        }
    });

    return options;
};

export const getCountriesHelper = async (
    countryId?: number
): Promise<EntitiesSelectedEntityHelperModel> => {
    const countryClient = new CountriesClient();
    const countriesVMs = await countryClient.getAll();

    const countries = countriesVMs.map(
        (c: CountryVm): DropdownOption => ({
            id: c && c.id ? c.id.toString() : '-1',
            name: c.nameUniversal || c.name || '',
        })
    );
    const selectedCountry = countryId
        ? countries.find((c: DropdownOption): boolean => c.id === countryId.toString())
        : undefined;

    return {
        entities: countries,
        selectedEntity: selectedCountry ? [selectedCountry] : [],
    };
};

export const getCitiesHelper = async (
    countryId?: number,
    cityId?: number
): Promise<EntitiesSelectedEntityHelperModel> => {
    if (countryId) {
        const countryClient = new CountriesClient();
        const countryDetailVMs = await countryClient.getById(countryId);

        if (countryDetailVMs && countryDetailVMs.cities) {
            const cities = countryDetailVMs.cities.map(
                (c: CityVm): DropdownOption => ({
                    id: c && c.id ? c.id.toString() : '-1',
                    name: c.nameUniversal || c.name || '',
                })
            );
            const selectedCity = cityId
                ? cities.find((c: DropdownOption): boolean => c.id === cityId.toString())
                : undefined;

            return {
                entities: cities,
                selectedEntity: selectedCity ? [selectedCity] : [],
            };
        }
        return {
            entities: [],
            selectedEntity: [],
        };
    } else {
        const cityClient = new CitiesClient();

        const citiesVMs = await cityClient.getAll();

        const cities = citiesVMs.map(
            (c: CityVm): DropdownOption => ({
                id: c && c.id ? c.id.toString() : '-1',
                name: c.nameUniversal || c.name || '',
            })
        );

        const selectedCity = cityId
            ? cities.find((c: DropdownOption): boolean => c.id === cityId.toString())
            : undefined;

        return {
            entities: cities,
            selectedEntity: selectedCity ? [selectedCity] : [],
        };
    }
};

export const confirmDropdownHelper = (option: DropdownOption[]): DropdownOption[] | undefined => {
    return option && option.length > 0 ? option : undefined;
};
