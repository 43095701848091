import { DropdownOption } from '../../../core/models/DropdownOption';
import { CreateMatchPlayerCommandOfMatchPlayer, EventVm } from '../../../utils/api';
import MatchLineup from './MatchLineup';

export interface Props {
    isHomeTeam: boolean;
    events?: EventVm[];
    lineup: CreateMatchPlayerCommandOfMatchPlayer[];
    players: DropdownOption[];
    handleSelectLineupPlayer: (isHomeTeam: boolean, playerId: number, cap: number, capNumber: number) => void;
    handleClearLineupPlayer: (isHomeTeam: boolean, cap: number) => void;
}

export default MatchLineup;
