import moment from 'moment';
import { PDFDocument } from 'pdf-lib';
import { DropdownOption } from '../core/models/DropdownOption';
import {
    CoachRoleEnum,
    CreateMatchCoachCommandOfMatchCoach,
    CreateMatchOfficialCommandOfMatchOfficial,
    CreateMatchPlayerCommandOfMatchPlayer,
    EventPeriodEnum,
    EventTypeEnum,
    EventVm,
    MatchDetailVm,
    MatchPlayerVm,
} from '../utils/api';
import {
    calculateGoals,
    compareEventsByTime,
    formatTime,
    getEventComment,
    getFouls,
    getPlayerNumber,
    getTeamId,
} from './EventHelper';

export const exportToPdf = async (
    events: EventVm[],
    coaches: DropdownOption[],
    players: MatchPlayerVm[],
    selectedHomeAssistCoaches: CreateMatchCoachCommandOfMatchCoach[],
    selectedAwayAssistCoaches: CreateMatchCoachCommandOfMatchCoach[],
    selectedHomeLineup: CreateMatchPlayerCommandOfMatchPlayer[],
    selectedAwayLineup: CreateMatchPlayerCommandOfMatchPlayer[],
    delegates: CreateMatchOfficialCommandOfMatchOfficial[],
    goalReferees: CreateMatchOfficialCommandOfMatchOfficial[],
    secretaries: CreateMatchOfficialCommandOfMatchOfficial[],
    timekeepers: CreateMatchOfficialCommandOfMatchOfficial[],
    vars: CreateMatchOfficialCommandOfMatchOfficial[],
    officials: DropdownOption[],
    referee1?: CreateMatchOfficialCommandOfMatchOfficial,
    referee2?: CreateMatchOfficialCommandOfMatchOfficial,
    director?: CreateMatchOfficialCommandOfMatchOfficial,
    selectedHomeHeadCoach?: CreateMatchCoachCommandOfMatchCoach,
    selectedAwayHeadCoach?: CreateMatchCoachCommandOfMatchCoach,
    match?: MatchDetailVm
): Promise<void> => {
    const { pdfform, URL } = window as any;

    const matchReferees1 = referee1
        ? officials.filter(
            (o: DropdownOption): boolean => parseInt(o.id, 10) === referee1.officialId
        )
        : [];
    const matchReferees2 = referee2
        ? officials.filter(
            (o: DropdownOption): boolean => parseInt(o.id, 10) === referee2.officialId
        )
        : [];
    const matchDirectors = director
        ? officials.filter(
            (o: DropdownOption): boolean => parseInt(o.id, 10) === director.officialId
        )
        : [];
    const matchGoalReferees = goalReferees
        ? officials.filter(
            (o: DropdownOption): boolean =>
                !!goalReferees.find(
                    (gr: CreateMatchOfficialCommandOfMatchOfficial): boolean =>
                        gr.officialId === parseInt(o.id, 10)
                )
        )
        : [];
    const matchSecretaries = secretaries
        ? officials.filter(
            (o: DropdownOption): boolean =>
                !!secretaries.find(
                    (s: CreateMatchOfficialCommandOfMatchOfficial): boolean =>
                        s.officialId === parseInt(o.id, 10)
                )
        )
        : [];
    const matchTimekeepers = timekeepers
        ? officials.filter(
            (o: DropdownOption): boolean =>
                !!timekeepers.find(
                    (tk: CreateMatchOfficialCommandOfMatchOfficial): boolean =>
                        tk.officialId === parseInt(o.id, 10)
                )
        )
        : [];
    const matchVarReferees = vars
        ? officials.filter(
            (o: DropdownOption): boolean =>
                !!vars.find(
                    (v: CreateMatchOfficialCommandOfMatchOfficial): boolean =>
                        v.officialId === parseInt(o.id, 10)
                )
        )
        : [];
    const matchDelegates = delegates
        ? officials.filter(
            (o: DropdownOption): boolean =>
                !!delegates.find(
                    (d: CreateMatchOfficialCommandOfMatchOfficial): boolean =>
                        d.officialId === parseInt(o.id, 10)
                )
        )
        : [];

    const matchReferee1Names = matchReferees1.map(
        (mOfficial: DropdownOption): string => mOfficial.name
    );
    const matchReferee2Names = matchReferees2.map(
        (mOfficial: DropdownOption): string => mOfficial.name
    );
    const matchRefereeNames = [...matchReferee1Names, ...matchReferee2Names];

    const matchGoalRefereeNames = matchGoalReferees.map(
        (mOfficial: DropdownOption): string => mOfficial.name
    );
    const matchSecretaryNames = matchSecretaries.map(
        (mOfficial: DropdownOption): string => mOfficial.name
    );
    const matchTimekeeperNames = matchTimekeepers.map(
        (mOfficial: DropdownOption): string => mOfficial.name
    );
    const matchVarRefereeNames = matchVarReferees.map(
        (mOfficial: DropdownOption): string => mOfficial.name
    );
    const matchDelegateNames = matchDelegates.map(
        (mOfficial: DropdownOption): string => mOfficial.name
    );
    const matchDirectorNames = matchDirectors.map(
        (mOfficial: DropdownOption): string => mOfficial.name
    );

    const homeHeadCoach = coaches.find(
        (c: DropdownOption): boolean => c.id === selectedHomeHeadCoach?.coachId?.toString()
    );
    const homeAssistantCoach1 =
        selectedHomeAssistCoaches?.length > 0
            ? coaches.find(
                (c: DropdownOption): boolean =>
                    c.id === selectedHomeAssistCoaches[0].coachId.toString()
            )
            : undefined;
    const homeAssistantCoach2 =
        selectedHomeAssistCoaches?.length > 1
            ? coaches.find(
                (c: DropdownOption): boolean =>
                    c.id === selectedHomeAssistCoaches[1].coachId.toString()
            )
            : undefined;

    const awayHeadCoach = coaches.find(
        (c: DropdownOption): boolean => c.id === selectedAwayHeadCoach?.coachId?.toString()
    );
    const awayAssistantCoach1 =
        selectedAwayAssistCoaches?.length > 0
            ? coaches.find(
                (c: DropdownOption): boolean =>
                    c.id === selectedAwayAssistCoaches[0].coachId.toString()
            )
            : undefined;
    const awayAssistantCoach2 =
        selectedAwayAssistCoaches?.length > 1
            ? coaches.find(
                (c: DropdownOption): boolean =>
                    c.id === selectedAwayAssistCoaches[1].coachId.toString()
            )
            : undefined;

    const homePlayerNames: string[] = [];
    const homePlayerNumbers: string[] = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16",];
    const homePlayerExclusions1: string[] = [];
    const homePlayerExclusions2: string[] = [];
    const homePlayerExclusions3: string[] = [];
    const homePlayerGoals1: string[] = [];
    const homePlayerGoals2: string[] = [];
    const homePlayerGoals3: string[] = [];
    const homePlayerGoals4: string[] = [];
    const homePlayerGoalsPSO: string[] = [];

    const homeLineup = selectedHomeLineup.sort(
        (
            l1: CreateMatchPlayerCommandOfMatchPlayer,
            l2: CreateMatchPlayerCommandOfMatchPlayer
        ): number => l1.number - l2.number
    );
    homeLineup.map((l: CreateMatchPlayerCommandOfMatchPlayer, arrayIndex: number): null => {
        const index = l.number - 1;
        const matchPlayer = players.find((p: MatchPlayerVm): boolean => p.playerId === l.playerId);
        const lineupEvents = events.filter(
            (e: EventVm): boolean =>
                getPlayerNumber(e) === l.number && getTeamId(e) === match?.homeTeamId
        );
        const exclusions = lineupEvents
            .filter((e: EventVm): boolean => e.typeId === EventTypeEnum.Exclusion)
            .sort(compareEventsByTime);

        const exclusions1 = getFouls(exclusions, 0);
        const exclusions2 = getFouls(exclusions, 1);
        const exclusions3 = getFouls(exclusions, 2);
        const goalsQ1 = calculateGoals(lineupEvents, EventPeriodEnum.First_Period);
        const goalsQ2 = calculateGoals(lineupEvents, EventPeriodEnum.Second_Period);
        const goalsQ3 = calculateGoals(lineupEvents, EventPeriodEnum.Third_Period);
        const goalsQ4 = calculateGoals(lineupEvents, EventPeriodEnum.Fourth_Period);
        const goalsQPSO = calculateGoals(lineupEvents, EventPeriodEnum.Overtime);

        homePlayerNames[index] = matchPlayer
            ? `${matchPlayer.player.name} ${matchPlayer.player.surname}`
            : '';
        homePlayerNumbers[index] = matchPlayer ? `${matchPlayer.capNumber || matchPlayer.number}` : (arrayIndex + 1).toString();
        homePlayerExclusions1[index] = exclusions1;
        homePlayerExclusions2[index] = exclusions2;
        homePlayerExclusions3[index] = exclusions3;
        homePlayerGoals1[index] = goalsQ1 ? goalsQ1.toString() : '';
        homePlayerGoals2[index] = goalsQ2 ? goalsQ2.toString() : '';
        homePlayerGoals3[index] = goalsQ3 ? goalsQ3.toString() : '';
        homePlayerGoals4[index] = goalsQ4 ? goalsQ4.toString() : '';
        homePlayerGoalsPSO[index] = goalsQPSO ? goalsQPSO.toString() : '';

        return null;
    });

    const homeTeamGoalsTotal1 = homePlayerGoals1.reduce(
        (acc: number, val: string): number => (val ? acc + Number(val) : acc),
        0
    );
    const homeTeamGoalsTotal2 = homePlayerGoals2.reduce(
        (acc: number, val: string): number => (val ? acc + Number(val) : acc),
        0
    );
    const homeTeamGoalsTotal3 = homePlayerGoals3.reduce(
        (acc: number, val: string): number => (val ? acc + Number(val) : acc),
        0
    );
    const homeTeamGoalsTotal4 = homePlayerGoals4.reduce(
        (acc: number, val: string): number => (val ? acc + Number(val) : acc),
        0
    );
    const homeTeamGoalsTotalPSO = homePlayerGoalsPSO.reduce(
        (acc: number, val: string): number => (val ? acc + Number(val) : acc),
        0
    );

    const homeTimeouts = events.filter(
        (e: EventVm): boolean =>
            e.typeId === EventTypeEnum.Timeout && getTeamId(e) === match?.homeTeamId
    );
    const homeChallenges = events.filter(
        (e: EventVm): boolean =>
            e.typeId === EventTypeEnum.Challenge && getTeamId(e) === match?.homeTeamId
    );

    const awayPlayerNames: string[] = [];
    const awayPlayerNumbers: string[] = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16",];
    const awayPlayerExclusions1: string[] = [];
    const awayPlayerExclusions2: string[] = [];
    const awayPlayerExclusions3: string[] = [];
    const awayPlayerGoals1: string[] = [];
    const awayPlayerGoals2: string[] = [];
    const awayPlayerGoals3: string[] = [];
    const awayPlayerGoals4: string[] = [];
    const awayPlayerGoalsPSO: string[] = [];

    const awayLineup = selectedAwayLineup.sort(
        (
            l1: CreateMatchPlayerCommandOfMatchPlayer,
            l2: CreateMatchPlayerCommandOfMatchPlayer
        ): number => l1.number - l2.number
    );
    awayLineup.map((l: CreateMatchPlayerCommandOfMatchPlayer, arrayIndex: number): null => {
        const index = l.number - 1;
        const matchPlayer = players.find((p: MatchPlayerVm): boolean => p.playerId === l.playerId);
        const lineupEvents = events.filter(
            (e: EventVm): boolean =>
                getPlayerNumber(e) === l.number && getTeamId(e) === match?.awayTeamId
        );
        const exclusions = lineupEvents
            .filter((e: EventVm): boolean => e.typeId === EventTypeEnum.Exclusion)
            .sort(compareEventsByTime);

        const exclusions1 = getFouls(exclusions, 0);
        const exclusions2 = getFouls(exclusions, 1);
        const exclusions3 = getFouls(exclusions, 2);
        const goalsQ1 = calculateGoals(lineupEvents, EventPeriodEnum.First_Period);
        const goalsQ2 = calculateGoals(lineupEvents, EventPeriodEnum.Second_Period);
        const goalsQ3 = calculateGoals(lineupEvents, EventPeriodEnum.Third_Period);
        const goalsQ4 = calculateGoals(lineupEvents, EventPeriodEnum.Fourth_Period);
        const goalsQPSO = calculateGoals(lineupEvents, EventPeriodEnum.Overtime);

        awayPlayerNames[index] = matchPlayer
            ? `${matchPlayer.player.name} ${matchPlayer.player.surname}`
            : '';
        awayPlayerNumbers[index] = matchPlayer ? `${matchPlayer.capNumber || matchPlayer.number}` : (arrayIndex + 1).toString();
        awayPlayerExclusions1[index] = exclusions1;
        awayPlayerExclusions2[index] = exclusions2;
        awayPlayerExclusions3[index] = exclusions3;
        awayPlayerGoals1[index] = goalsQ1 ? goalsQ1.toString() : '';
        awayPlayerGoals2[index] = goalsQ2 ? goalsQ2.toString() : '';
        awayPlayerGoals3[index] = goalsQ3 ? goalsQ3.toString() : '';
        awayPlayerGoals4[index] = goalsQ4 ? goalsQ4.toString() : '';
        awayPlayerGoalsPSO[index] = goalsQPSO ? goalsQPSO.toString() : '';

        return null;
    });

    const awayTeamGoalsTotal1 = awayPlayerGoals1.reduce(
        (acc: number, val: string): number => (val ? acc + Number(val) : acc),
        0
    );
    const awayTeamGoalsTotal2 = awayPlayerGoals2.reduce(
        (acc: number, val: string): number => (val ? acc + Number(val) : acc),
        0
    );
    const awayTeamGoalsTotal3 = awayPlayerGoals3.reduce(
        (acc: number, val: string): number => (val ? acc + Number(val) : acc),
        0
    );
    const awayTeamGoalsTotal4 = awayPlayerGoals4.reduce(
        (acc: number, val: string): number => (val ? acc + Number(val) : acc),
        0
    );
    const awayTeamGoalsTotalPSO = awayPlayerGoalsPSO.reduce(
        (acc: number, val: string): number => (val ? acc + Number(val) : acc),
        0
    );

    const awayTimeouts = events.filter(
        (e: EventVm): boolean =>
            e.typeId === EventTypeEnum.Timeout && getTeamId(e) === match?.awayTeamId
    );
    const awayChallenges = events.filter(
        (e: EventVm): boolean =>
            e.typeId === EventTypeEnum.Challenge && getTeamId(e) === match?.awayTeamId
    );

    const eventTime: string[] = [];
    const eventPlayer: string[] = [];
    const eventColour: string[] = [];
    const eventComment: string[] = [];
    const eventScore: string[] = [];

    let currentHomeTeamScore = 0;
    let currentAwayTeamScore = 0;
    const lineups: CreateMatchPlayerCommandOfMatchPlayer[] = [
        ...selectedHomeLineup,
        ...selectedAwayLineup,
    ];

    events.map((e: EventVm) => {
        // export only timeouts, exclusions and goals and cards
        if (
            e.typeId !== EventTypeEnum.Timeout &&
            e.typeId !== EventTypeEnum.Challenge &&
            e.typeId !== EventTypeEnum.Exclusion &&
            e.typeId !== EventTypeEnum.Card &&
            !e.shot?.isGoal
        ) {
            return null;
        }

        const lineup = lineups.find(
            (l: CreateMatchPlayerCommandOfMatchPlayer): boolean =>
                getPlayerNumber(e) === l.number && getTeamId(e) === l.teamId
        );
        const cardedCoach = e.card?.cardedCoach;
        const excludedCoach = e.exclusion?.excludedCoach;

        eventTime.push(formatTime(e.minute, e.seconds));

        if (e.typeId === EventTypeEnum.Swim_Off) {
            if (e.swimoff?.winnerSwimmerId) {
                const swimOffLineup = lineups.find(
                    (l: CreateMatchPlayerCommandOfMatchPlayer): boolean =>
                        l.playerId === e?.swimoff?.winnerSwimmerId
                );
                eventPlayer.push(swimOffLineup ? swimOffLineup.capNumber?.toString() || swimOffLineup.number.toString() : '');
                eventColour.push(
                    swimOffLineup && swimOffLineup.teamId === match?.homeTeamId ? 'W' : 'B'
                );
            } else {
                eventPlayer.push('');
                eventColour.push('');
            }
        } else if (e.typeId === EventTypeEnum.Exclusion) {
            if (lineup) {
                eventPlayer.push(lineup.capNumber?.toString() || lineup.number.toString());
            } else if (excludedCoach) {
                eventPlayer.push(excludedCoach.roleId === CoachRoleEnum.Head_Coach ? 'HC' : 'AC');
            } else {
                eventPlayer.push('');
            }
            eventColour.push(getTeamId(e) === match?.homeTeamId ? 'W' : 'B');
        } else {
            if (lineup) {
                eventPlayer.push(lineup.capNumber?.toString() || lineup.number.toString());
            } else if (cardedCoach) {
                eventPlayer.push(cardedCoach.roleId === CoachRoleEnum.Head_Coach ? 'HC' : 'AC');
            } else {
                eventPlayer.push('');
            }
            eventColour.push(getTeamId(e) === match?.homeTeamId ? 'W' : 'B');
        }

        eventComment.push(getEventComment(e));

        if (e.typeId === EventTypeEnum.Shot) {
            currentHomeTeamScore += getTeamId(e) === match?.homeTeamId ? 1 : 0;
            currentAwayTeamScore += getTeamId(e) === match?.awayTeamId ? 1 : 0;
            eventScore.push(`${currentHomeTeamScore} - ${currentAwayTeamScore}`);
        } else {
            eventScore.push('');
        }

        return null;
    });

    const model = {
        matchName: [`${match?.homeTeamDisplayName || ''} - ${match?.awayTeamDisplayName || ''}`],
        matchNumber: [match?.number || ''],
        matchVenue: [match?.venue || ''],
        matchDate: [match?.startDate ? moment(match?.startDate).format('DD.MM.YYYY.') : ''],
        matchTime: [match?.startDate ? moment(match?.startDate).format('HH:mm') : ''],
        matchEndTime: [match?.endDate ? moment(match?.endDate).format('HH:mm') : ''],
        matchReferees: [matchRefereeNames.join(', ')],
        matchGoalReferees: [matchGoalRefereeNames.join(', ')],
        matchSecretaries: [matchSecretaryNames.join(', ')],
        matchTimekeepers: [matchTimekeeperNames.join(', ')],
        matchVarReferees: [matchVarRefereeNames.join(', ')],
        matchDelegates: [matchDelegateNames.join(', ')],
        matchDirectors: [matchDirectorNames.join(', ')],
        whiteTeamName: [match?.homeTeamDisplayName || ''],
        whiteTeamHeadCoach: [homeHeadCoach ? homeHeadCoach.name : ''],
        whiteTeamAssistantCoach1: [homeAssistantCoach1 ? homeAssistantCoach1.name : ''],
        whiteTeamAssistantCoach2: [homeAssistantCoach2 ? homeAssistantCoach2.name : ''],
        whitePlayerNames: homePlayerNames,
        whitePlayerNumbers: homePlayerNumbers,
        whitePlayerExclusions1: homePlayerExclusions1,
        whitePlayerExclusions2: homePlayerExclusions2,
        whitePlayerExclusions3: homePlayerExclusions3,
        whitePlayerGoals1: homePlayerGoals1,
        whitePlayerGoals2: homePlayerGoals2,
        whitePlayerGoals3: homePlayerGoals3,
        whitePlayerGoals4: homePlayerGoals4,
        whitePlayerGoalsPSO: homePlayerGoalsPSO,
        whiteTeamGoals: [
            homeTeamGoalsTotal1,
            homeTeamGoalsTotal2,
            homeTeamGoalsTotal3,
            homeTeamGoalsTotal4,
            homeTeamGoalsTotalPSO,
        ],
        whiteTeamResult: [match?.homeTeamDisplayName || ''],
        whiteTeamGoalResults: [
            homeTeamGoalsTotal1,
            homeTeamGoalsTotal2,
            homeTeamGoalsTotal3,
            homeTeamGoalsTotal4,
            homeTeamGoalsTotalPSO,
            match?.homeTeamGoalsTotal || 0,
        ],
        whiteTeamTimeouts: [homeTimeouts[0] ? 'a' : '', homeTimeouts[1] ? 'a' : ''],
        whiteTeamChallenges: [homeChallenges[0] ? 'a' : '', homeChallenges[1] ? 'a' : ''],
        blueTeamName: [match?.awayTeamDisplayName || ''],
        blueTeamHeadCoach: [awayHeadCoach ? awayHeadCoach.name : ''],
        blueTeamAssistantCoach1: [awayAssistantCoach1 ? awayAssistantCoach1.name : ''],
        blueTeamAssistantCoach2: [awayAssistantCoach2 ? awayAssistantCoach2.name : ''],
        bluePlayerNames: awayPlayerNames,
        bluePlayerNumbers: awayPlayerNumbers,
        bluePlayerExclusions1: awayPlayerExclusions1,
        bluePlayerExclusions2: awayPlayerExclusions2,
        bluePlayerExclusions3: awayPlayerExclusions3,
        bluePlayerGoals1: awayPlayerGoals1,
        bluePlayerGoals2: awayPlayerGoals2,
        bluePlayerGoals3: awayPlayerGoals3,
        bluePlayerGoals4: awayPlayerGoals4,
        bluePlayerGoalsPSO: awayPlayerGoalsPSO,
        blueTeamGoals: [
            awayTeamGoalsTotal1,
            awayTeamGoalsTotal2,
            awayTeamGoalsTotal3,
            awayTeamGoalsTotal4,
            awayTeamGoalsTotalPSO,
        ],
        blueTeamResult: [match?.awayTeamDisplayName || ''],
        blueTeamGoalResults: [
            awayTeamGoalsTotal1,
            awayTeamGoalsTotal2,
            awayTeamGoalsTotal3,
            awayTeamGoalsTotal4,
            awayTeamGoalsTotalPSO,
            match?.awayTeamGoalsTotal || 0,
        ],
        blueTeamTimeouts: [awayTimeouts[0] ? 'a' : '', awayTimeouts[1] ? 'a' : ''],
        blueTeamChallenges: [awayChallenges[0] ? 'a' : '', awayChallenges[1] ? 'a' : ''],
        eventTime,
        eventPlayer,
        eventColour,
        eventComment,
        eventScore,
    };

    // fetch template and fill with model data
    const response = await fetch('/match-report-template.pdf');
    const buffer = await response.arrayBuffer();
    const filledPdf = pdfform().transform(buffer, model);
    const pdfDoc = await PDFDocument.load(filledPdf);
    const pages = pdfDoc.getPages();

    // add Logo either from competition, federation or the default FINA logo
    let logo;
    if (match?.competitionLogo) {
        logo = await pdfDoc.embedPng(match?.competitionLogo);
    } else if (match?.federationLogo) {
        logo = await pdfDoc.embedPng(match?.federationLogo);
    } else {
        const imageResponse = await fetch('./fina-logo.png');
        const imageBuffer = await imageResponse.arrayBuffer();
        logo = await pdfDoc.embedPng(imageBuffer);
    }
    const firstPage = pages[0];
    const { width, height } = firstPage.getSize();
    firstPage.drawImage(logo, {
        x: width / 2 - 90,
        y: height / 2 + 230,
        width: 50,
        height: 50,
    });

    // finalize PDF and trigger download
    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    const link = URL.createObjectURL(blob);

    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = link;
    a.download = `Scoresheet_${match?.homeTeamDisplayName || ''}_${match?.awayTeamDisplayName || ''
        }_${match?.startDate ? moment(match?.startDate).format('DD.MM.YYYY.') : ''}.pdf`;
    a.click();
    window.URL.revokeObjectURL(link);
};
