import { DropdownOption } from '../core/models/DropdownOption';
import {
    CategoriesClient,
    CategoryVm,
    ClubsClient,
    ClubVm,
    CoachesClient,
    CoachRoleEnum,
    CoachVm,
    CompetitionsClient,
    CompetitionTypesClient,
    CompetitionTypeVm,
    CompetitionVm,
    FederationsClient,
    FederationVm,
    MatchSegmentEnum,
    OfficialsClient,
    OfficialVm,
    PersonsClient,
    PersonVm,
    PlayerPositionEnum,
    RoleEnum,
    TeamPlayerVm,
    TeamsClient,
    TeamVm,
} from '../utils/api';
import { reverseCompare } from './CompareHelper';
import {
    transformEnumToDropdownOptions,
    transformPersonToDropdownOption,
    transformTeamToDropdownOption,
} from './TransformHelper';

export interface DropdownEntitiesAndSelected {
    entities: DropdownOption[];
    selectedEntity?: DropdownOption[];
    selectedSecondEntity?: DropdownOption[];
}

export const getClubsToDropdownOptions = async (
    clubId?: number
): Promise<DropdownEntitiesAndSelected> => {
    const clubClient = new ClubsClient();
    const clubs = await clubClient.getAll();

    const mappedClubs = clubs.map(
        (c: ClubVm): DropdownOption => ({
            id: c.id.toString(),
            name: c.nameUniversal || c.name || '',
            searchableName: c.nameUniversal || c.name || '',
        })
    );
    const selectedClub = mappedClubs.find(
        (mC: DropdownOption): boolean => parseInt(mC.id, 10) === clubId
    );

    return {
        entities: mappedClubs,
        selectedEntity: selectedClub ? [selectedClub] : undefined,
    };
};

export const getCoachesToDropdownOptions = async (
    coachId?: number
): Promise<DropdownEntitiesAndSelected> => {
    const coachClient = new CoachesClient();
    const coaches = await coachClient.getAll();

    const mappedCoaches = coaches.map(
        (c: CoachVm): DropdownOption => ({
            id: c.id.toString(),
            name: `${c.name} ${c.surname}`,
        })
    );
    const selectedCoach = mappedCoaches.find(
        (mC: DropdownOption): boolean => parseInt(mC.id, 10) === coachId
    );

    return {
        entities: mappedCoaches,
        selectedEntity: selectedCoach ? [selectedCoach] : undefined,
    };
};

export const getPersonsToDropdownOptions = async (
    selectedPersonId?: number
): Promise<DropdownEntitiesAndSelected> => {
    const personClient = new PersonsClient();
    const persons = await personClient.getAll();

    const mappedPersons = persons.map(
        (c: PersonVm): DropdownOption => ({
            id: c.id.toString(),
            name: `${c.name} ${c.surname}`,
        })
    );
    const selectedPerson = mappedPersons.find(
        (mC: DropdownOption): boolean => parseInt(mC.id, 10) === selectedPersonId
    );

    return {
        entities: mappedPersons,
        selectedEntity: selectedPerson ? [selectedPerson] : undefined,
    };
};

export const getPersonsWithAdditionalInfoInNameToDropdownOptions = async (
    selectedPersonId?: number
): Promise<DropdownEntitiesAndSelected> => {
    const personClient = new PersonsClient();
    const persons = await personClient.getAll();

    const mappedPersons = persons.map(
        (c: PersonVm): DropdownOption => transformPersonToDropdownOption(c)
    );
    const selectedPerson = mappedPersons.find(
        (mC: DropdownOption): boolean => parseInt(mC.id, 10) === selectedPersonId
    );

    return {
        entities: mappedPersons,
        selectedEntity: selectedPerson ? [selectedPerson] : undefined,
    };
};

export const getOfficialsToDropdownOptions = async (
    officialId?: number
): Promise<DropdownEntitiesAndSelected> => {
    const officialClient = new OfficialsClient();
    const officials = await officialClient.getAll();

    const mappedOfficials = officials.map(
        (o: OfficialVm): DropdownOption => ({
            id: o.id.toString(),
            name: `${o.name} ${o.surname}`,
        })
    );
    const selectedOfficial = mappedOfficials.find(
        (mO: DropdownOption): boolean => parseInt(mO.id, 10) === officialId
    );

    return {
        entities: mappedOfficials,
        selectedEntity: selectedOfficial ? [selectedOfficial] : undefined,
    };
};

export const getTeamsToDropdownOptions = async (
    teamId?: number,
    excludedTeamIds?: number[],
    federationId?: number,
    isInternational?: boolean
): Promise<DropdownEntitiesAndSelected> => {
    const teamClient = new TeamsClient();
    const teamsVMs = await teamClient.getAll(!isInternational ? federationId : undefined);

    let teams = teamsVMs.map((t: TeamVm): DropdownOption => transformTeamToDropdownOption(t));
    const selectedTeam = teams.find((t: DropdownOption): boolean => parseInt(t.id, 10) === teamId);

    if (excludedTeamIds) {
        teams = teams.filter(
            (t: DropdownOption): boolean =>
                !excludedTeamIds.find((uTI: number): boolean => uTI.toString() === t.id) ||
                t.id === teamId?.toString()
        );
    }

    return {
        entities: teams,
        selectedEntity: selectedTeam ? [selectedTeam] : undefined,
    };
};

export const getMatchTeamsToDropdownOptions = async (
    homeId?: number,
    awayId?: number,
    teamsFromProps?: TeamVm[]
): Promise<DropdownEntitiesAndSelected> => {
    let teams: DropdownOption[] = [];

    if (teamsFromProps) {
        teams = teamsFromProps.map((t: TeamVm): DropdownOption => transformTeamToDropdownOption(t));
    } else {
        const teamClient = new TeamsClient();
        // @ts-ignore
        const teamsVMs = await teamClient.getAll();

        teams = teamsVMs.map((t: TeamVm): DropdownOption => transformTeamToDropdownOption(t));
    }

    const selectedHomeTeam = homeId
        ? teams.find((t: DropdownOption): boolean => t.id === homeId.toString())
        : undefined;
    const selectedAwayTeam = awayId
        ? teams.find((t: DropdownOption): boolean => t.id === awayId.toString())
        : undefined;

    return {
        entities: teams,
        selectedEntity: selectedHomeTeam ? [selectedHomeTeam] : undefined,
        selectedSecondEntity: selectedAwayTeam ? [selectedAwayTeam] : undefined,
    };
};

export const getFederationsToDropdownOptions = async (
    federationId?: number
): Promise<DropdownEntitiesAndSelected> => {
    const federationClient = new FederationsClient();
    const federationsVMs = await federationClient.getAll();

    const federations = federationsVMs.map(
        (f: FederationVm): DropdownOption => ({
            id: f && f.id ? f.id.toString() : '-1',
            name: `${f.nameUniversal || f.name || ''} (${f.country})`,
        })
    );
    const selectedFederation = federationId
        ? federations.find((c: DropdownOption): boolean => c.id === federationId.toString())
        : undefined;

    return {
        entities: federations,
        selectedEntity: selectedFederation ? [selectedFederation] : undefined,
    };
};

export const getCompetitionTypesToDropdownOptions = async (
    competitionTypeId?: number
): Promise<DropdownEntitiesAndSelected> => {
    const competitionTypeClient = new CompetitionTypesClient();
    const competitionTypesVMs = await competitionTypeClient.getAll();

    const competitionTypes = competitionTypesVMs.map(
        (c: CompetitionTypeVm): DropdownOption => ({
            id: c && c.id ? c.id.toString() : '-1',
            name: c.nameUniversal || c.name || '',
        })
    );
    const selectedCompetitionType = competitionTypeId
        ? competitionTypes.find(
            (c: DropdownOption): boolean => c.id === competitionTypeId.toString()
        )
        : undefined;

    return {
        entities: competitionTypes,
        selectedEntity: selectedCompetitionType ? [selectedCompetitionType] : undefined,
    };
};

export const getCompetitionsToDropdownOptions = async (
    competitionId?: number
): Promise<DropdownEntitiesAndSelected> => {
    const competitionClient = new CompetitionsClient();
    const competitionVMs = await competitionClient.getAll();

    const competitions = competitionVMs.map(
        (c: CompetitionVm): DropdownOption => ({
            id: c && c.id ? c.id.toString() : '-1',
            name: `${c.nameUniversal || c.name || ''}${c.country ? ` (${c.country})` : ''}`,
        })
    );
    const selectedCompetition = competitionId
        ? competitions.find((c: DropdownOption): boolean => c.id === competitionId.toString())
        : undefined;

    return {
        entities: competitions,
        selectedEntity: selectedCompetition ? [selectedCompetition] : undefined,
    };
};

export const getMatchSegmentsToDropdownOptions = async (
    matchSegmentId?: number
): Promise<DropdownEntitiesAndSelected> => {
    const matchSegments = transformEnumToDropdownOptions(MatchSegmentEnum);
    matchSegments.sort((a: DropdownOption, b: DropdownOption): number =>
        reverseCompare(a.id, b.id)
    );

    const selectedMatchSegment = matchSegmentId
        ? matchSegments.find((c: DropdownOption): boolean => c.id === matchSegmentId.toString())
        : matchSegments.length > 0
            ? matchSegments[0]
            : undefined;

    return {
        entities: matchSegments,
        selectedEntity: selectedMatchSegment ? [selectedMatchSegment] : undefined,
    };
};

export const getCategoriesToDropdownOptions = async (
    categoryId?: number
): Promise<DropdownEntitiesAndSelected> => {
    const categoryClient = new CategoriesClient();
    const categoriesVMs = await categoryClient.getAll();

    const categories = categoriesVMs.map(
        (c: CategoryVm): DropdownOption => ({
            id: c && c.id ? c.id.toString() : '-1',
            name: `${c.nameUniversal || c.name || ''} (${c.gender})`,
        })
    );
    const selectedCategory = categoryId
        ? categories.find((c: DropdownOption): boolean => c.id === categoryId.toString())
        : undefined;

    return {
        entities: categories,
        selectedEntity: selectedCategory ? [selectedCategory] : undefined,
    };
};

export const getCategoriesToDropdownOptionsMultipleInitials = async (
    categoryIds?: number[]
): Promise<DropdownEntitiesAndSelected> => {
    const categoryClient = new CategoriesClient();
    const categoriesVMs = await categoryClient.getAll();

    const categories = categoriesVMs.map(
        (c: CategoryVm): DropdownOption => ({
            id: c && c.id ? c.id.toString() : '-1',
            name: `${c.nameUniversal || c.name || ''} (${c.gender})`,
        })
    );

    const selectedCategories: DropdownOption[] = [];
    if (categoryIds && categoryIds.length > 0) {
        categoryIds.forEach((cI: number): void => {
            const category = categories.find(
                (c: DropdownOption): boolean => c.id === cI.toString()
            );
            if (category) {
                selectedCategories.push(category);
            }
        });
    }

    return {
        entities: categories,
        selectedEntity:
            selectedCategories && selectedCategories.length > 0 ? selectedCategories : undefined,
    };
};

export const getPlayerPositions = async (
    positionId?: number,
    teamPositionId?: number
): Promise<DropdownEntitiesAndSelected> => {
    const positions = transformEnumToDropdownOptions(PlayerPositionEnum);
    const selectedPosition = positionId
        ? positions.find((pP: DropdownOption): boolean => pP.id === positionId.toString())
        : undefined;
    const selectedTeamPosition = teamPositionId
        ? positions.find((pP: DropdownOption): boolean => pP.id === teamPositionId.toString())
        : undefined;

    return {
        entities: positions,
        selectedEntity: selectedPosition ? [selectedPosition] : undefined,
        selectedSecondEntity: selectedTeamPosition ? [selectedTeamPosition] : undefined,
    };
};

export const getCoachRoles = async (
    roleId?: CoachRoleEnum
): Promise<DropdownEntitiesAndSelected> => {
    const roles = transformEnumToDropdownOptions(CoachRoleEnum);
    const selectedRole = roleId
        ? roles.find((r: DropdownOption): boolean => r.id === roleId.toString())
        : undefined;

    return {
        entities: roles,
        selectedEntity: selectedRole ? [selectedRole] : undefined,
    };
};

export const getTeamPlayersToDropdownOptions = async (
    teamId: number
): Promise<DropdownEntitiesAndSelected> => {
    const teamClient = new TeamsClient();
    const team = await teamClient.getById(teamId);

    const players = team.players.map(
        (c: TeamPlayerVm): DropdownOption => ({
            id: c && c.playerId ? c.playerId.toString() : '-1',
            name: `${c.player.name} ${c.player.surname}`,
            capNumber: c.capNumber,
        })
    );

    return {
        entities: players,
    };
};

export const getUserTypesToDropdownOptions = async (
    typeId?: RoleEnum[]
): Promise<DropdownEntitiesAndSelected> => {
    const userTypes = transformEnumToDropdownOptions(RoleEnum);
    const selectedType = typeId
        ? userTypes.find((c: DropdownOption): boolean =>
            typeId.find((t: RoleEnum): boolean => t === parseInt(c.id, 10)) !== undefined
                ? true
                : false
        )
        : userTypes.length > 0
            ? userTypes[0]
            : undefined;

    return {
        entities: userTypes,
        selectedEntity: selectedType ? [selectedType] : undefined,
    };
};
