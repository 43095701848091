import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Row, Tooltip } from 'antd';
import React from 'react';
import { ActionOption } from '../../core/models/enum';
import { isUserAllowed } from '../../helpers/CheckPermissionHelper';
import { popupConfirmDeleteWithCallback } from '../../helpers/NotificationHelper';
import { Props } from './index';

const ConditionalTooltip = (child: React.ReactNode, title?: string) => { return title ? <Tooltip title={title}>{child}</Tooltip> : child; }

interface State {
    isUserAllowedToUpdate: boolean;
    isUserAllowedToDelete: boolean;
}

class DetailsButtons extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            isUserAllowedToUpdate: false,
            isUserAllowedToDelete: false,
        };
    }

    public componentDidMount = (): void => {
        this.getPermissions();
    };

    private getPermissions = (): void => {
        const { userProfile, entityId, moduleName } = this.props;

        this.setState({
            isUserAllowedToUpdate: isUserAllowed(
                userProfile,
                moduleName,
                ActionOption.Update,
                entityId
            ),
            isUserAllowedToDelete: isUserAllowed(
                userProfile,
                moduleName,
                ActionOption.Delete,
                entityId
            ),
        });
    };

    public render(): React.ReactElement {
        const {
            onDeleteAction,
            onEditAction,
            onUpdatePINAction,
            disabled,
            customMessage,
            styles,
            editTooltip
        } = this.props;
        const { isUserAllowedToDelete, isUserAllowedToUpdate } = this.state;

        return (
            <Row
                style={
                    styles || {
                        height: 50,
                        display: 'flow-root',
                        paddingRight: 20,
                    }
                }
            >
                <div style={{ float: 'right' }}>
                    {isUserAllowedToUpdate && (
                        ConditionalTooltip(
                        <Button
                            type="primary"
                            style={{ width: 100 }}
                            disabled={disabled}
                            onClick={(): void => onEditAction()}
                        >
                            <EditOutlined className="button-icon-display" />
                            Edit
                        </Button>, editTooltip)
                    )}
                    {onUpdatePINAction && isUserAllowedToUpdate && (
                        <Button
                            type="primary"
                            style={{
                                marginLeft: 10,
                                width: 130,
                            }}
                            disabled={disabled}
                            onClick={(): void => onUpdatePINAction()}
                        >
                            <EditOutlined className="button-icon-display" />
                            Change PIN
                        </Button>
                    )}
                    {onDeleteAction && isUserAllowedToDelete && (
                        <Button
                            type="primary"
                            danger
                            style={{
                                marginLeft: 10,
                                width: 100,
                            }}
                            disabled={disabled}
                            onClick={(): void =>
                                popupConfirmDeleteWithCallback(
                                    (): Promise<void> => onDeleteAction(),
                                    undefined,
                                    customMessage || undefined
                                )
                            }
                        >
                            <DeleteOutlined className="button-icon-display" />
                            Delete
                        </Button>
                    )}
                </div>
            </Row>
        );
    }
}

export default DetailsButtons;
