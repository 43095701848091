import React from 'react';
import { formatTime, getEventTitle } from '../../../../helpers/EventHelper';
import {
    CoachRoleEnum,
    EventTypeEnum,
    MatchCoachVm,
    TurnoverTypeEnum,
} from '../../../../utils/api';
import { Props } from './index';

class LogItem extends React.PureComponent<Props> {
    private getClass = (): string => {
        const { teamId, match } = this.props;

        switch (teamId) {
            case match.homeTeamId:
                return 'c-event--turn-over-event';
            case match.awayTeamId:
                return 'c-event--exclusion-event';
            default:
                return 'c-event--neutral-event';
        }
    };

    public render = (): React.ReactElement => {
        const { event, coaches, lineup } = this.props;

        const className = this.getClass();
        const cardedCoach = coaches.find(
            (c: MatchCoachVm): boolean =>
                event.card !== undefined && event.card.cardedCoachId === c.coachId
        );

        const isClockExpiredTurnover =
            event.typeId === EventTypeEnum.Turnover &&
            event.turnover?.typeId === TurnoverTypeEnum.Clock_Expired;
        const lineupNumber = lineup && !isClockExpiredTurnover ? lineup.capNumber || lineup.number : '';

        return (
            <div className={`c-event ${className}`}>
                <span className="c-event__player-nbr">
                    {lineupNumber}
                    {cardedCoach ? CoachRoleEnum[cardedCoach.roleId] : ''}
                </span>
                <span className="c-event__time">{formatTime(event.minute, event.seconds)}</span>
                <span className="c-event__event">
                    <span className="c-event__event-name">{getEventTitle(event)}</span>
                </span>
            </div>
        );
    };
}

export default LogItem;
