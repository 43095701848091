import React from 'react';
import { Props } from '.';
import Icon from '../../../../components/icon/Icon';
import { calculateGoals, countExclusions } from '../../../../helpers/EventHelper';
import {
    EventTypeEnum,
    EventVm,
    ExclusionTypeEnum,
    ShotTypeEnum,
    TurnoverTypeEnum,
} from '../../../../utils/api';

class MatchLineupRow extends React.PureComponent<Props> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    public render = (): React.ReactElement => {
        const {
            match,
            cap,
            capNumber,
            events,
            isHomeTeam,
            initEvent,
            lineupPlayer,
            isSelectedKeeper,
            selectPlayer,
        } = this.props;

        const scoredGoals = calculateGoals(events);
        const commitedExclusions = countExclusions(events);

        const teamId = match ? (isHomeTeam ? match.homeTeamId : match.awayTeamId) : null;

        let isExcluded = false;
        const playerExclusions = events.filter(
            (x: EventVm): boolean => x.typeId === EventTypeEnum.Exclusion
        );
        if (playerExclusions.length > 2) {
            isExcluded = true;
        }
        if (
            playerExclusions.filter(
                (x: EventVm): boolean =>
                    x.exclusion?.typeId === ExclusionTypeEnum.Suspension ||
                    x.exclusion?.typeId === ExclusionTypeEnum.Brutality
            ).length > 0
        ) {
            isExcluded = true;
        }
        const isKeeper = cap === 1 || cap === 13;
        const className = `c-team__btn ${isKeeper ? 'is-keeper' : ''} ${isSelectedKeeper ? 'active' : ''
            } ${isExcluded ? 'disabled-button' : ''}`;

        const playerCap = (
            <div key={1} className={className}>
                <div
                    role="button"
                    className="c-team__btn-inner"
                    onClick={
                        teamId && lineupPlayer && !isExcluded
                            ? (): void => selectPlayer(cap, teamId)
                            : undefined
                    }
                >
                    <span className="fake-svg">{capNumber}</span>
                </div>
            </div>
        );
        const goals = (
            <div
                key={2}
                onClick={
                    lineupPlayer && !isExcluded
                        ? (): void =>
                            initEvent(EventTypeEnum.Shot, {
                                teamId: teamId,
                                takenById: lineupPlayer.id,
                                typeId: ShotTypeEnum.Regular_Attack,
                            })
                        : undefined
                }
                className={`c-team__btn ${isExcluded ? 'disabled-button' : ''}`}
                role="button"
            >
                <div className="c-team__btn-inner">
                    <Icon name="icon-volleyball-ball" />
                    <span>
                        &nbsp;
                        {scoredGoals > 0 ? scoredGoals : ''}
                    </span>
                </div>
            </div>
        );
        const exclusions = (
            <div
                key={3}
                onClick={
                    lineupPlayer
                        ? (): void =>
                            initEvent(EventTypeEnum.Exclusion, {
                                teamId: teamId,
                                excludedPlayerId: lineupPlayer.id,
                                typeId: ExclusionTypeEnum.Exclusion,
                            })
                        : undefined
                }
                className="c-team__btn"
                role="button"
            >
                <div className="c-team__btn-inner">
                    <Icon name="icon-times" />
                    <span>
                        &nbsp;
                        {commitedExclusions > 0 ? commitedExclusions : ''}
                    </span>
                </div>
            </div>
        );
        const turnovers = (
            <div
                key={4}
                onClick={
                    lineupPlayer && !isExcluded
                        ? (): void =>
                            initEvent(EventTypeEnum.Turnover, {
                                teamId: teamId,
                                lostPossesionPlayerId: lineupPlayer.id,
                                typeId: TurnoverTypeEnum.Lost_Ball,
                            })
                        : undefined
                }
                className={`c-team__btn ${isExcluded ? 'disabled-button' : ''}`}
                role="button"
            >
                <div className="c-team__btn-inner">
                    <Icon name="icon-share" />
                </div>
            </div>
        );

        const render = isHomeTeam
            ? [turnovers, exclusions, goals, playerCap]
            : [exclusions, turnovers, goals, playerCap];

        return (
            <div
                className={`c-team__row c-team__row--${isHomeTeam ? 'white' : 'blue'
                    }-team c-team__row--single c-team__row-- ${lineupPlayer ? '' : 'disabled'}`}
            >
                {render}
            </div>
        );
    };
}

export default MatchLineupRow;
