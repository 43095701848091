import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, DatePicker, Drawer, Form, Table } from 'antd';
import moment from 'moment';
import React from 'react';
import { DrawerWidth } from '../../config/dimensions';
import { DropdownOption } from '../../core/models/DropdownOption';
import { DrawerState, ModuleName } from '../../core/models/enum';
import {
    confirmDropdownHelper,
    filterDropdownOptions,
} from '../../helpers/AutocompleteDropdownHelper';
import { convertUTCTimeToLocal } from '../../helpers/DateHelper';
import { getClubsToDropdownOptions } from '../../helpers/FetchAndTransformHelpers';
import { formItemLayout724 } from '../../helpers/FormLayoutHelper';
import { customArrayValidationHelper } from '../../helpers/ValidationHelper';
import {
    CreateClubCoachCommandOfClubCoach,
    CreateClubPlayerCommandOfClubPlayer,
} from '../../utils/api';
import AutocompleteDropdown from '../autocomplete-dropdown';
import DrawerButtons from '../drawer-buttons/index';
import { Props } from './index';

interface State {
    drawerState: DrawerState;
    clubs: DropdownOption[];
    selectedClub?: DropdownOption[];
}

class PersonClubs extends React.PureComponent<Props, State> {
    private formRef: any;

    public constructor(props: Props) {
        super(props);

        this.formRef = React.createRef();

        this.state = {
            drawerState: DrawerState.Closed,
            clubs: [],
        };
    }

    public componentDidMount = (): void => {
        this.getClubs();
    };

    private getClubs = async (): Promise<void> => {
        const { initialClubId } = this.props;
        const results = await getClubsToDropdownOptions(initialClubId);

        this.setState({
            clubs: results.entities,
            selectedClub: results.selectedEntity,
        });
    };

    private onSave = (values: any): void => {
        const { selectedClub, clubs } = this.state;
        const { saveCoachChanges, savePlayerChanges, initialClubId } = this.props;

        if (selectedClub && selectedClub.length > 0 && saveCoachChanges) {
            const club = new CreateClubCoachCommandOfClubCoach({
                clubId: parseInt(selectedClub[0].id, 10),
                activeFrom: values.activeFromClub,
                activeTo: values.activeToClub,
                coachId: 0,
            });

            let newClubs = [...clubs];
            const selectedClubIndex = clubs.findIndex((x) => x.id === selectedClub[0].id);
            newClubs[selectedClubIndex].disabled = true;

            saveCoachChanges(club);
            this.handleDrawerMode(DrawerState.Closed);
            if (!initialClubId) {
                this.setState({
                    selectedClub: undefined,
                    clubs: newClubs,
                });
            }
        } else if (selectedClub && selectedClub.length > 0 && savePlayerChanges) {
            const club = new CreateClubPlayerCommandOfClubPlayer({
                clubId: parseInt(selectedClub[0].id, 10),
                activeFrom: values.activeFromClub,
                activeTo: values.activeToClub,
                playerId: 0,
            });

            let newClubs = [...clubs];
            const selectedClubIndex = clubs.findIndex((x) => x.id === selectedClub[0].id);
            newClubs[selectedClubIndex].disabled = true;

            savePlayerChanges(club);
            this.handleDrawerMode(DrawerState.Closed);
            if (!initialClubId) {
                this.setState({
                    selectedClub: undefined,
                    clubs: newClubs,
                });
            }
        }
    };

    private handleDrawerMode = (drawerState: DrawerState): void => {
        this.setState({
            drawerState,
        });
    };

    private enableClub = (id: number): void => {
        const { clubs } = this.state;
        var newClubs = [...clubs];

        const clubIndex = clubs.findIndex((x) => x.id === id.toString());
        newClubs[clubIndex].disabled = false;

        this.setState({
            clubs: newClubs,
        });
    };

    public render(): React.ReactElement {
        const { drawerState, clubs, selectedClub } = this.state;
        const {
            entityModule,
            initialClubId,
            selectedCoachClubs,
            selectedPlayerClubs,
            removeCoachClubFromList,
            removePlayerClubFromList,
        } = this.props;

        const columnsCoach = [
            {
                title: 'Club',
                dataIndex: 'clubId',
                key: 'clubId',
                render: (value: number): string => {
                    const club = clubs.find(
                        (t: DropdownOption): boolean => t.id === value.toString()
                    );
                    return club ? club.name : '';
                },
            },
            {
                title: 'Active from',
                dataIndex: 'activeFrom',
                key: 'activeFrom',
                render: (value: Date): string =>
                    value ? moment(convertUTCTimeToLocal(value)).format('DD.MM.YYYY.') : '',
                width: 110,
            },
            {
                title: 'Active to',
                dataIndex: 'activeTo',
                key: 'activeTo',
                render: (value?: Date): string =>
                    value ? moment(convertUTCTimeToLocal(value)).format('DD.MM.YYYY.') : '',
                width: 110,
            },
            {
                title: '',
                dataIndex: '',
                key: '',
                render: (value: CreateClubCoachCommandOfClubCoach): React.ReactElement => (
                    <DeleteOutlined
                        onClick={(): void => {
                            if (removeCoachClubFromList) {
                                removeCoachClubFromList(value);
                                this.enableClub(value.clubId);
                            }
                        }}
                        className="text-danger-custom"
                    />
                ),
                width: 31,
            },
        ];

        const columnsPlayer = [
            {
                title: 'Club',
                dataIndex: 'clubId',
                key: 'clubId',
                render: (value: number): string => {
                    const club = clubs.find(
                        (t: DropdownOption): boolean => t.id === value.toString()
                    );
                    return club ? club.name : '';
                },
            },
            {
                title: 'Active from',
                dataIndex: 'activeFrom',
                key: 'activeFrom',
                render: (value: Date): string =>
                    value ? moment(convertUTCTimeToLocal(value)).format('DD.MM.YYYY.') : '',
                width: 110,
            },
            {
                title: 'Active to',
                dataIndex: 'activeTo',
                key: 'activeTo',
                render: (value?: Date): string =>
                    value ? moment(convertUTCTimeToLocal(value)).format('DD.MM.YYYY.') : '',
                width: 110,
            },
            {
                title: '',
                dataIndex: '',
                key: '',
                render: (value: CreateClubPlayerCommandOfClubPlayer): React.ReactElement => (
                    <DeleteOutlined
                        onClick={(): void => {
                            if (removePlayerClubFromList) {
                                removePlayerClubFromList(value);
                                this.enableClub(value.clubId);
                            }
                        }}
                        className="text-danger-custom"
                    />
                ),
                width: 31,
            },
        ];

        return (
            <div>
                <Button
                    type="primary"
                    style={{
                        zIndex: 10,
                        float: 'right',
                        marginBottom: 15,
                    }}
                    onClick={(): void => this.handleDrawerMode(DrawerState.Create)}
                >
                    <PlusOutlined className="button-icon-display" />
                    Add club
                </Button>
                {entityModule === ModuleName.Coaches &&
                    selectedCoachClubs &&
                    selectedCoachClubs.length > 0 && (
                        <Table
                            columns={columnsCoach}
                            dataSource={selectedCoachClubs}
                            bordered
                            pagination={false}
                            size="small"
                        />
                    )}
                {entityModule === ModuleName.Players &&
                    selectedPlayerClubs &&
                    selectedPlayerClubs.length > 0 && (
                        <Table
                            columns={columnsPlayer}
                            dataSource={selectedPlayerClubs}
                            bordered
                            pagination={false}
                            size="small"
                        />
                    )}
                <Drawer
                    title="Add club"
                    visible={!!drawerState}
                    width={DrawerWidth}
                    onClose={(): void => this.handleDrawerMode(DrawerState.Closed)}
                    destroyOnClose
                >
                    <Form onFinish={this.onSave} {...formItemLayout724} ref={this.formRef}>
                        <DrawerButtons
                            onCancelAction={(): void => this.handleDrawerMode(DrawerState.Closed)}
                        />
                        <div className="drawer-content">
                            <Form.Item
                                name="clubId"
                                label="Club"
                                rules={[
                                    {
                                        required: true,
                                        validator: (_rule: any, value: any, callback: any): void =>
                                            customArrayValidationHelper(
                                                _rule,
                                                value,
                                                callback,
                                                'Club field is required!',
                                                selectedClub
                                            ),
                                    },
                                ]}
                            >
                                <AutocompleteDropdown
                                    placeholder="Choose club"
                                    getOptionsFrontend={(value: string): DropdownOption[] =>
                                        filterDropdownOptions(value, clubs)
                                    }
                                    initialValues={selectedClub}
                                    confirmDirty={(options: DropdownOption[]): void =>
                                        this.setState({
                                            selectedClub: confirmDropdownHelper(options),
                                        })
                                    }
                                    style={{ width: '100%' }}
                                    isAllowedToClear
                                    disabled={!!initialClubId}
                                />
                            </Form.Item>
                            <Form.Item
                                name="activeFromClub"
                                label="Active from"
                                initialValue={moment(convertUTCTimeToLocal(new Date()))}
                            >
                                <DatePicker
                                    format="DD.MM.YYYY."
                                    style={{ width: '100%' }}
                                    allowClear={false}
                                />
                            </Form.Item>
                            <Form.Item
                                name="activeToClub"
                                label="Active to"
                                initialValue={undefined}
                            >
                                <DatePicker format="DD.MM.YYYY." style={{ width: '100%' }} />
                            </Form.Item>
                        </div>
                    </Form>
                </Drawer>
            </div>
        );
    }
}

export default PersonClubs;
