import { CheckOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import React from 'react';
import Routes from '../../../config/routes';
import ReplaceStrings from '../../../config/replaceStrings';
import { ActionOption, ModuleName, SortDirectionEnum } from '../../../core/models/enum';
import { Filter } from '../../../core/models/Filter';
import { isUserAllowed } from '../../../helpers/CheckPermissionHelper';
import {
    buildFilterRequest,
    DefaultFilter,
    getRadioFilter,
    getSearchFilter,
} from '../../../helpers/FilterHelper';
import {
    errorWithUserMessage,
    popupConfirmDeleteWithCallback,
    success,
} from '../../../helpers/NotificationHelper';
import { PaginationOptions } from '../../../helpers/PaginationHelper';
import { feTableFilterHelper } from '../../../helpers/TableFilterHelper';
import { UserVm, UsersClient, ApiException } from '../../../utils/api';
import { Props } from './index';

interface State {
    allUsers: UserVm[];
    users: UserVm[];
    paginationOptions: TablePaginationConfig;
    currentFilter: Filter;
    loading: boolean;
    isUserAllowedToUpdate: boolean;
    isUserAllowedToDelete: boolean;
}

class UserTable extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            allUsers: [],
            users: [],
            paginationOptions: PaginationOptions,
            currentFilter: DefaultFilter,
            loading: true,
            isUserAllowedToUpdate: false,
            isUserAllowedToDelete: false,
        };
    }

    public componentDidMount = (): void => {
        this.getPermissions();
        this.getInitialAllUsers();
    };

    private getPermissions = (): void => {
        const { userProfile } = this.props;

        this.setState({
            isUserAllowedToUpdate: isUserAllowed(
                userProfile,
                ModuleName.Users,
                ActionOption.Update
            ),
            isUserAllowedToDelete: isUserAllowed(
                userProfile,
                ModuleName.Users,
                ActionOption.Delete
            ),
        });
    };

    private getInitialAllUsers = async (): Promise<void> => {
        const { usersFromProps } = this.props;

        this.setState({
            loading: true,
        });

        let allUsers;

        if (usersFromProps) {
            allUsers = usersFromProps;
        } else {
            allUsers = await this.getUsersFromBe();
        }

        this.setState(
            {
                allUsers,
                loading: false,
            },
            (): void => this.getFilteredUsers()
        );
    };

    private getUsersFromBe = async (): Promise<UserVm[]> => {
        const userClient = new UsersClient();
        return await userClient.getAll();
    };

    private getFilteredUsers = (currentFilterParam?: Filter): void => {
        const { currentFilter, allUsers } = this.state;

        const filter = currentFilterParam || currentFilter;
        const userPagedList = feTableFilterHelper(allUsers || [], filter);

        this.setState({
            paginationOptions: {
                ...PaginationOptions,
                current: userPagedList.currentPage,
                pageSize: userPagedList.pageSize,
                total: userPagedList.totalItems,
            },
            users: userPagedList?.pageItems || [],
            loading: false,
        });
    };

    private handleDeleteHelper = async (id?: string): Promise<void> => {
        if (id) {
            try {
                const client = new UsersClient();
                const result = await client.delete(id);
                if (result) {
                    success('User successfully deleted.');
                    this.getInitialAllUsers();
                }
            } catch (error) {
                if (error instanceof ApiException) {
                    errorWithUserMessage(error.response);
                } else {
                    errorWithUserMessage('Error deleting data.');
                }    
            }
        }
    };

    private handleTableChange = (pagination: any, filters: any, sorter: any): void => {
        const filterRequest = buildFilterRequest(filters);

        const filter: Filter = {
            pageIndex: pagination.current,
            pageSize: pagination.pageSize,
            orderByKey: sorter?.columnKey && sorter?.order ? sorter.columnKey : undefined,
            orderByValue: sorter?.order ? sorter.order : undefined,
            filterRequest,
        };

        this.getFilteredUsers(filter);
        this.setState({
            currentFilter: filter,
        });
    };

    public render(): React.ReactElement {
        const {
            users,
            loading,
            paginationOptions,
            isUserAllowedToDelete,
            isUserAllowedToUpdate,
        } = this.state;
        const { history } = this.props;

        const columns = [
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
                ...getSearchFilter(),
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: (): void => {},
            },
            {
                title: 'Type',
                dataIndex: 'type',
                key: 'type',
                ...getSearchFilter(),
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: (): void => {},
            },
            {
                title: 'Federation',
                dataIndex: 'federation',
                key: 'federation',
                ...getSearchFilter(),
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: (): void => {},
            },
            {
                title: 'Club',
                dataIndex: 'club',
                key: 'club',
                ...getSearchFilter(),
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: (): void => {},
            },
            {
                title: 'Person',
                dataIndex: 'person',
                key: 'person',
                ...getSearchFilter(),
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: (): void => {},
            },
            {
                title: 'Email confirmed',
                dataIndex: 'emailConfirmed',
                key: 'emailConfirmed',
                ...getRadioFilter([
                    { id: 'false', name: 'False' },
                    { id: 'true', name: 'True' },
                ]),
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: (): void => {},
                render: (value: boolean): React.ReactElement =>
                    value ? <CheckOutlined style={{ width: '100%' }} /> : <span />,
                width: 100,
            },
            {
                dataIndex: '',
                render: (value: UserVm): React.ReactElement =>
                    value && value.id ? (
                        <>
                            {isUserAllowedToUpdate && (
                                <EditOutlined
                                    onClick={(): void =>
                                        history.push(
                                            Routes.ROUTE_USERS_EDIT.replace(
                                                ReplaceStrings.USER_ID,
                                                value.id.toString()
                                            )
                                        )
                                    }
                                    style={{ color: '#1890ff' }}
                                />
                            )}
                            {isUserAllowedToDelete && (
                                <DeleteOutlined
                                    onClick={(): void =>
                                        popupConfirmDeleteWithCallback(
                                            (): Promise<void> => this.handleDeleteHelper(value.id),
                                            undefined,
                                            'Are you sure you want to delete this user?',
                                            'Delete'
                                        )
                                    }
                                    className="text-danger-custom"
                                    style={{ marginLeft: 10 }}
                                />
                            )}
                        </>
                    ) : (
                        <span />
                    ),
                width: 80,
            },
        ];

        return (
            <Table
                className="user-table"
                columns={columns}
                loading={loading}
                dataSource={users}
                rowKey={(record): string => (record && record.id ? record.id.toString() : '-1')}
                pagination={paginationOptions}
                onChange={this.handleTableChange}
                bordered
                size="small"
            />
        );
    }
}

export default UserTable;
