import { Table } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import React from 'react';
import { Link } from 'react-router-dom';
import Routes from '../../../config/routes';
import ReplaceStrings from '../../../config/replaceStrings';
import { ActionOption, ModuleName, SortDirectionEnum } from '../../../core/models/enum';
import { Filter } from '../../../core/models/Filter';
import { isUserAllowed } from '../../../helpers/CheckPermissionHelper';
import { buildFilterRequest, DefaultFilter, getSearchFilter } from '../../../helpers/FilterHelper';
import { PaginationOptions } from '../../../helpers/PaginationHelper';
import { feTableFilterHelper } from '../../../helpers/TableFilterHelper';
import { capitalizeFirstLetter } from '../../../helpers/TransformHelper';
import { PaginatedListOfPlayerVm, PlayersClient, PlayerVm } from '../../../utils/api';
import { Props } from './index';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { popupConfirmDeleteWithCallback } from '../../../helpers/NotificationHelper';

interface State {
    players: PlayerVm[];
    paginationOptions: TablePaginationConfig;
    currentFilter: Filter;
    loading: boolean;
    isUserAllowedToUpdate: boolean;
    isUserAllowedToDelete: boolean;
}

class PlayerTable extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            players: [],
            paginationOptions: PaginationOptions,
            currentFilter: DefaultFilter,
            loading: true,
            isUserAllowedToUpdate: false,
            isUserAllowedToDelete: false,
        };
    }

    public componentDidMount = (): void => {
        this.getPermissions();
        this.getPlayers();
    };

    private getPermissions = (): void => {
        const { userProfile } = this.props;

        this.setState({
            isUserAllowedToUpdate: isUserAllowed(
                userProfile,
                ModuleName.Players,
                ActionOption.Update
            ),
            isUserAllowedToDelete: isUserAllowed(
                userProfile,
                ModuleName.Players,
                ActionOption.Delete
            ),
        });
    };

    private getPlayers = async (currentFilterParam?: Filter): Promise<void> => {
        const { currentFilter } = this.state;
        const { playersFromProps } = this.props;

        this.setState({
            loading: true,
        });

        let playersPagedList;
        const filter = currentFilterParam || currentFilter;

        if (playersFromProps) {
            playersPagedList = this.getPlayersFromProps(filter);
        } else {
            playersPagedList = await this.getAllPlayers(filter);
        }

        this.setState({
            paginationOptions: {
                ...PaginationOptions,
                current: playersPagedList.currentPage,
                pageSize: playersPagedList.pageSize,
                total: playersPagedList.totalItems,
            },
            players: playersPagedList?.pageItems || [],
            loading: false,
        });
    };

    private getAllPlayers = async (filter: Filter): Promise<PaginatedListOfPlayerVm> => {
        const playerClient = new PlayersClient();

        return await playerClient.getPaginated(
            filter.filterRequest?.name,
            filter.filterRequest?.surname,
            filter.filterRequest?.countryOfBirth,
            filter.filterRequest?.cityOfBirth,
            filter.pageIndex,
            filter.pageSize,
            capitalizeFirstLetter(filter.orderByKey),
            filter.orderByValue
        );
    };

    private getPlayersFromProps = (filter: Filter): PaginatedListOfPlayerVm => {
        const { playersFromProps } = this.props;

        const filteredResult = feTableFilterHelper(playersFromProps || [], filter);
        return new PaginatedListOfPlayerVm({
            ...filteredResult,
            pageItems: filteredResult.pageItems as PlayerVm[],
        });
    };

    private handleTableChange = (pagination: any, filters: any, sorter: any): void => {
        const filterRequest = buildFilterRequest(filters);

        const filter: Filter = {
            pageIndex: pagination.current,
            pageSize: pagination.pageSize,
            orderByKey: sorter?.columnKey && sorter?.order ? sorter.columnKey : undefined,
            orderByValue: sorter?.order ? sorter.order : undefined,
            filterRequest,
        };

        this.getPlayers(filter);
        this.setState({
            currentFilter: filter,
        });
    };

    private handleDeleteHelper = async (id?: number): Promise<any> => {
        const { onDelete } = this.props;

        if (onDelete) {
            onDelete(id);
        }
    };

    public render(): React.ReactElement {
        const {
            players,
            loading,
            paginationOptions,
            isUserAllowedToDelete,
            isUserAllowedToUpdate,
        } = this.state;
        const { playersFromProps, history, clubId, teamId } = this.props;

        const columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                ...getSearchFilter(),
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: (): void => {},
            },
            {
                title: 'Surname',
                dataIndex: 'surname',
                key: 'surname',
                ...getSearchFilter(),
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: (): void => {},
            },
            {
                title: 'Country',
                dataIndex: 'countryOfBirth',
                key: 'countryOfBirth',
                ...getSearchFilter(),
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: (): void => {},
            },
            {
                title: 'City',
                dataIndex: 'cityOfBirth',
                key: 'cityOfBirth',
                ...getSearchFilter(),
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: (): void => {},
            },
        ];

        if (!playersFromProps) {
            columns.push({
                dataIndex: '',
                render: (value: PlayerVm): React.ReactElement =>
                    value && value.id ? (
                        <Link
                            to={Routes.ROUTE_PLAYERS_READ.replace(
                                ReplaceStrings.PLAYER_ID,
                                value.id.toString()
                            )}
                        >
                            Details
                        </Link>
                    ) : (
                        <span />
                    ),
                width: 80,
            });
        } else {
            columns.push({
                title: '',
                dataIndex: '',
                key: '',
                render: (value: PlayerVm): React.ReactElement =>
                    value && value.id ? (
                        <>
                            {isUserAllowedToUpdate && clubId && (
                                <EditOutlined
                                    onClick={(): void =>
                                        history.push(
                                            Routes.ROUTE_CLUB_PLAYERS_EDIT.replace(
                                                ReplaceStrings.PLAYER_ID,
                                                value.id.toString()
                                            ).replace(ReplaceStrings.CLUB_ID, clubId.toString())
                                        )
                                    }
                                    style={{ color: '#1890ff' }}
                                />
                            )}
                            {isUserAllowedToUpdate && teamId && (
                                <EditOutlined
                                    onClick={(): void =>
                                        history.push(
                                            Routes.ROUTE_TEAM_PLAYERS_EDIT.replace(
                                                ReplaceStrings.PLAYER_ID,
                                                value.id.toString()
                                            ).replace(ReplaceStrings.TEAM_ID, teamId.toString())
                                        )
                                    }
                                    style={{ color: '#1890ff' }}
                                />
                            )}
                            {isUserAllowedToDelete && (
                                <DeleteOutlined
                                    onClick={(): void =>
                                        popupConfirmDeleteWithCallback(
                                            (): Promise<void> => this.handleDeleteHelper(value.id),
                                            undefined,
                                            'Are you sure you want to remove this player?',
                                            'Delete'
                                        )
                                    }
                                    className="text-danger-custom"
                                    style={{ marginLeft: 10 }}
                                />
                            )}
                        </>
                    ) : (
                        <span />
                    ),
                width: 80,
            });
        }

        return (
            <Table
                columns={columns}
                loading={loading}
                dataSource={players}
                rowKey={(record): string => (record && record.id ? record.id.toString() : '-1')}
                pagination={paginationOptions}
                onChange={this.handleTableChange}
                bordered
                size="small"
            />
        );
    }
}

export default PlayerTable;
