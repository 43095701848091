import { RouteComponentProps, withRouter } from 'react-router-dom';
import { DrawerState } from '../../../core/models/enum';
import { PersonVm } from '../../../utils/api';
import { RouteParams } from '../../../core/models/RouteHelper';
import PersonForm from './PersonForm';
import { Profile } from 'oidc-client';

interface OwnProps {
    userProfile: Profile;
    person?: PersonVm;
    handleClose: (drawerMode: DrawerState, id?: string) => void;
    refreshAfterSave: () => void;
}

export type Props = OwnProps & RouteComponentProps<RouteParams>;

export default withRouter(PersonForm);
