export enum DrawerState {
    Closed = 0,
    Read = 1,
    Edit = 2,
    Create = 3,
}

export enum ProfileOption {
    Existing = 'existing',
    New = 'new',
}

export enum ModuleName {
    Dashboard = '/',
    Federations = 'federations',
    FederationRoles = 'federation-roles',
    Clubs = 'clubs',
    Teams = 'teams',
    Competitions = 'competitions',
    CompetitionTeams = 'competition-teams',
    Matches = 'matches',
    MatchLog = 'match-log',
    Officials = 'officials',
    Coaches = 'coaches',
    Players = 'players',
    Persons = 'persons',
    Users = 'users',
    Cities = 'cities',
    Administration = 'administration',
}

export enum TeamColor {
    Home = 1,
    Away = 2,
    Neutral = 3,
}

export enum ClockState {
    Stopped,
    Paused,
    Running,
}

export enum SortDirectionEnum {
    ASC = 'ascend',
    DESC = 'descend',
}

export enum ActionOption {
    Read,
    Create,
    Update,
    Delete,
}

export enum ExtendedRoleEnum {
    Admin = 0,
    Federation = 1,
    Club = 2,
    Person = 3,
    Coach = 4,
    Official = 5,
}

export enum FilterItemOperationEnum {
    Equal = 'EQ',
    GreaterThan = 'GT',
    LessThan = 'LT',
    GreaterThanEqual = 'GTE',
    LessThanEqual = 'LTE',
    Contains = 'CONTAINS'
}
