import { ProfileOption } from './enum';

export interface ProfileOptionsModel {
    value: ProfileOption;
    title: string;
}

export const profileOptionButtons: ProfileOptionsModel[] = [
    {
        value: ProfileOption.New,
        title: 'Create new',
    },
    {
        value: ProfileOption.Existing,
        title: 'Choose existing',
    },
];
