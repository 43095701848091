import { ModuleName } from '../../core/models/enum';
import {
    CreateTeamCoachCommandOfTeamCoach,
    CreateTeamPlayerCommandOfTeamPlayer,
} from '../../utils/api';
import PersonTeams from './PersonTeams';

interface OwnProps {
    entityModule: ModuleName;
    initialTeamId?: number;
    selectedCoachTeams?: CreateTeamCoachCommandOfTeamCoach[];
    selectedPlayerTeams?: CreateTeamPlayerCommandOfTeamPlayer[];
    saveCoachChanges?: (team: CreateTeamCoachCommandOfTeamCoach) => void;
    savePlayerChanges?: (team: CreateTeamPlayerCommandOfTeamPlayer) => void;
    removeCoachTeamFromList?: (team: CreateTeamCoachCommandOfTeamCoach) => void;
    removePlayerTeamFromList?: (team: CreateTeamPlayerCommandOfTeamPlayer) => void;
}

export type Props = OwnProps;

export default PersonTeams;
