import { DropdownOption } from '../core/models/DropdownOption';
import { ProfileOption } from '../core/models/enum';
import { OfficialFormInitialValues } from '../core/models/InitialValues';
import {
    CreateFederationOfficialCommandOfFederationOfficial,
    CreateOfficialCommand,
    CreatePersonCommandOfPerson,
    FederationOfficialsClient,
    FederationOfficialVm,
    OfficialDetailVm,
    OfficialRoleEnum,
    OfficialsClient,
    PersonsClient,
    PersonVm,
    UpdateFederationOfficialCommand,
    UpdateOfficialCommandOfOfficial,
} from '../utils/api';
import { createHelper, updateHelper } from './SubmitHelper';

export const officialSubmitHelper = (
    callbackFunction: () => void,
    values: any,
    selectedCountry?: DropdownOption[],
    selectedCity?: DropdownOption[],
    selectedPerson?: DropdownOption[],
    selectedProfileOption?: string,
    fileBase64?: string,
    selectedPersonVm?: PersonVm,
    selectedRoles?: DropdownOption[],
    selectedFederation?: DropdownOption[],
    selectedFederationRoles?: CreateFederationOfficialCommandOfFederationOfficial[],
    official?: OfficialDetailVm,
    initialValues?: OfficialFormInitialValues,
    initialFederationOfficial?: FederationOfficialVm
): void => {
    if (initialValues && initialValues.federationId) {
        if (initialFederationOfficial) {
            if (selectedRoles && selectedRoles.length > 0) {
                const request: UpdateFederationOfficialCommand = new UpdateFederationOfficialCommand(
                    {
                        activeFrom: values.activeFrom,
                        activeTo: values.activeTo,
                        roles: selectedRoles.map(
                            (sR: DropdownOption): OfficialRoleEnum => parseInt(sR.id, 10)
                        ),
                        id: initialFederationOfficial.id || 0,
                    }
                );

                request.id = initialFederationOfficial.id || 0;

                if (request) {
                    const client = new FederationOfficialsClient();
                    updateHelper(client, request, callbackFunction);
                }
            }
        } else if (
            selectedProfileOption === ProfileOption.Existing &&
            selectedPersonVm &&
            selectedPersonVm.officialId
        ) {
            if (selectedRoles && selectedRoles.length > 0) {
                const request: CreateFederationOfficialCommandOfFederationOfficial = new CreateFederationOfficialCommandOfFederationOfficial(
                    {
                        federationId: initialValues.federationId,
                        officialId: selectedPersonVm.officialId,
                        activeFrom: values.activeFrom,
                        activeTo: values.activeTo,
                        roles: selectedRoles.map(
                            (sR: DropdownOption): OfficialRoleEnum => parseInt(sR.id, 10)
                        ),
                    }
                );

                if (request) {
                    const client = new FederationOfficialsClient();
                    createHelper(client, request, callbackFunction);
                }
            }
        } else {
            if (selectedProfileOption === ProfileOption.Existing) {
                const officialClient = new OfficialsClient();

                const existingPerson: CreateOfficialCommand = new CreateOfficialCommand({
                    title: values.title,
                    federations:
                        selectedRoles && selectedRoles.length > 0
                            ? [
                                  new CreateFederationOfficialCommandOfFederationOfficial({
                                      roles: selectedRoles.map(
                                          (sR: DropdownOption): OfficialRoleEnum =>
                                              parseInt(sR.id, 10)
                                      ),
                                      federationId:
                                          selectedFederation && selectedFederation.length > 0
                                              ? parseInt(selectedFederation[0].id, 10)
                                              : 0,
                                      activeFrom: values.activeFrom,
                                      activeTo: values.activeTo,
                                      officialId: 0,
                                  }),
                              ]
                            : [],
                    personId:
                        selectedPerson && selectedPerson.length > 0
                            ? parseInt(selectedPerson[0].id, 10)
                            : 0,
                });

                createHelper(officialClient, existingPerson, callbackFunction);
            } else {
                const personClient = new PersonsClient();

                const newPerson: CreatePersonCommandOfPerson = new CreatePersonCommandOfPerson({
                    official: new CreateOfficialCommand({
                        title: values.title,
                        federations:
                            selectedRoles && selectedRoles.length > 0
                                ? [
                                      new CreateFederationOfficialCommandOfFederationOfficial({
                                          roles: selectedRoles.map(
                                              (sR: DropdownOption): OfficialRoleEnum =>
                                                  parseInt(sR.id, 10)
                                          ),
                                          federationId:
                                              selectedFederation && selectedFederation.length > 0
                                                  ? parseInt(selectedFederation[0].id, 10)
                                                  : 0,
                                          activeFrom: values.activeFrom,
                                          activeTo: values.activeTo,
                                          officialId: 0,
                                      }),
                                  ]
                                : [],
                        personId:
                            selectedPerson && selectedPerson.length > 0
                                ? parseInt(selectedPerson[0].id, 10)
                                : 0,
                    }),
                    name: values.name,
                    surname: values.surname,
                    birthDate:
                        values && values.birthDate
                            ? new Date(values.birthDate.toLocaleString())
                            : new Date(),
                    nationality: values.nationality,
                    address: values.address,
                    email: values.email,
                    phone: values.phone,
                    facebook: values.facebook,
                    youtube: values.youtube,
                    twitter: values.twitter,
                    instagram: values.instagram,
                    photo: fileBase64 || undefined,
                    countryOfBirthId:
                        selectedCountry && selectedCountry.length > 0
                            ? parseInt(selectedCountry[0].id, 10)
                            : undefined,
                    cityOfBirthId:
                        selectedCity && selectedCity.length > 0
                            ? parseInt(selectedCity[0].id, 10)
                            : undefined,
                    playerId: undefined,
                    player: undefined,
                    coachId: undefined,
                    coach: undefined,
                    officialId: undefined,
                });

                createHelper(personClient, newPerson, callbackFunction);
            }
        }
    } else {
        if (selectedProfileOption === ProfileOption.Existing) {
            const officialClient = new OfficialsClient();

            const existingPerson: CreateOfficialCommand = new CreateOfficialCommand({
                title: values.title,
                federations:
                    selectedFederationRoles && selectedFederationRoles.length > 0
                        ? selectedFederationRoles
                        : [],
                personId:
                    selectedPerson && selectedPerson.length > 0
                        ? parseInt(selectedPerson[0].id, 10)
                        : 0,
            });

            createHelper(officialClient, existingPerson, callbackFunction);
        } else if (official && official.id) {
            const officialClient = new OfficialsClient();

            const updatedOfficial: UpdateOfficialCommandOfOfficial = new UpdateOfficialCommandOfOfficial(
                {
                    id: official.id,
                    title: values.title,
                }
            );

            updateHelper(officialClient, updatedOfficial, callbackFunction);
        } else {
            const personClient = new PersonsClient();

            const newPerson: CreatePersonCommandOfPerson = new CreatePersonCommandOfPerson({
                official: new CreateOfficialCommand({
                    title: values.title,
                    federations:
                        selectedFederationRoles && selectedFederationRoles.length > 0
                            ? selectedFederationRoles
                            : [],
                    personId:
                        selectedPerson && selectedPerson.length > 0
                            ? parseInt(selectedPerson[0].id, 10)
                            : 0,
                }),
                name: values.name,
                surname: values.surname,
                birthDate:
                    values && values.birthDate
                        ? new Date(values.birthDate.toLocaleString())
                        : new Date(),
                nationality: values.nationality,
                address: values.address,
                email: values.email,
                phone: values.phone,
                facebook: values.facebook,
                youtube: values.youtube,
                twitter: values.twitter,
                instagram: values.instagram,
                photo: fileBase64 || undefined,
                countryOfBirthId:
                    selectedCountry && selectedCountry.length > 0
                        ? parseInt(selectedCountry[0].id, 10)
                        : undefined,
                cityOfBirthId:
                    selectedCity && selectedCity.length > 0
                        ? parseInt(selectedCity[0].id, 10)
                        : undefined,
                playerId: undefined,
                player: undefined,
                coachId: undefined,
                coach: undefined,
                officialId: undefined,
            });

            createHelper(personClient, newPerson, callbackFunction);
        }
    }
};
