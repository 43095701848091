import {
    CreateClubCoachCommandOfClubCoach,
    CreateClubPlayerCommandOfClubPlayer,
    CreateTeamCoachCommandOfTeamCoach,
    CreateTeamPlayerCommandOfTeamPlayer,
} from '../utils/api';

// #region Teams
export const updateCoachTeamsHelper = (
    team: CreateTeamCoachCommandOfTeamCoach,
    teams?: CreateTeamCoachCommandOfTeamCoach[]
): CreateTeamCoachCommandOfTeamCoach[] => (teams ? [...teams, team] : [team]);

export const updatePlayerTeamsHelper = (
    team: CreateTeamPlayerCommandOfTeamPlayer,
    teams?: CreateTeamPlayerCommandOfTeamPlayer[]
): CreateTeamPlayerCommandOfTeamPlayer[] => (teams ? [...teams, team] : [team]);

export const removeCoachTeamFromListHelper = (
    team: CreateTeamCoachCommandOfTeamCoach,
    teams?: CreateTeamCoachCommandOfTeamCoach[]
): CreateTeamCoachCommandOfTeamCoach[] | undefined => {
    const newSelectedTeams = teams ? [...teams] : undefined;
    const teamToDeleteIndex = newSelectedTeams
        ? newSelectedTeams.findIndex(
              (sT: CreateTeamCoachCommandOfTeamCoach): boolean =>
                  sT.roleId === team.roleId &&
                  sT.teamId === team.teamId &&
                  sT.coachId === team.coachId &&
                  sT.activeFrom === team.activeFrom &&
                  sT.activeTo === team.activeTo
          )
        : -1;
    if (teamToDeleteIndex > -1 && newSelectedTeams) {
        newSelectedTeams.splice(teamToDeleteIndex, 1);
        return newSelectedTeams;
    }
    return undefined;
};

export const removePlayerTeamFromListHelper = (
    team: CreateTeamPlayerCommandOfTeamPlayer,
    teams?: CreateTeamPlayerCommandOfTeamPlayer[]
): CreateTeamPlayerCommandOfTeamPlayer[] | undefined => {
    const newSelectedTeams = teams ? [...teams] : undefined;
    const teamToDeleteIndex = newSelectedTeams
        ? newSelectedTeams.findIndex(
              (sT: CreateTeamPlayerCommandOfTeamPlayer): boolean =>
                  sT.positionId === team.positionId &&
                  sT.teamId === team.teamId &&
                  sT.playerId === team.playerId &&
                  sT.activeFrom === team.activeFrom &&
                  sT.activeTo === team.activeTo
          )
        : -1;
    if (teamToDeleteIndex > -1 && newSelectedTeams) {
        newSelectedTeams.splice(teamToDeleteIndex, 1);
        return newSelectedTeams;
    }
};
// #endregion Teams

// #region Clubs
export const updateCoachClubsHelper = (
    club: CreateClubCoachCommandOfClubCoach,
    clubs?: CreateClubCoachCommandOfClubCoach[]
): CreateClubCoachCommandOfClubCoach[] => (clubs ? [...clubs, club] : [club]);

export const updatePlayerClubsHelper = (
    club: CreateClubPlayerCommandOfClubPlayer,
    clubs?: CreateClubPlayerCommandOfClubPlayer[]
): CreateClubPlayerCommandOfClubPlayer[] => (clubs ? [...clubs, club] : [club]);

export const removeCoachClubFromListHelper = (
    club: CreateClubCoachCommandOfClubCoach,
    clubs?: CreateClubCoachCommandOfClubCoach[]
): CreateClubCoachCommandOfClubCoach[] | undefined => {
    const newSelectedClubs = clubs ? [...clubs] : undefined;
    const clubToDeleteIndex = newSelectedClubs
        ? newSelectedClubs.findIndex(
              (sT: CreateClubCoachCommandOfClubCoach): boolean =>
                  sT.clubId === club.clubId &&
                  sT.coachId === club.coachId &&
                  sT.activeFrom === club.activeFrom &&
                  sT.activeTo === club.activeTo
          )
        : -1;
    if (clubToDeleteIndex > -1 && newSelectedClubs) {
        newSelectedClubs.splice(clubToDeleteIndex, 1);
        return newSelectedClubs;
    }
    return undefined;
};

export const removePlayerClubFromListHelper = (
    club: CreateClubPlayerCommandOfClubPlayer,
    clubs?: CreateClubPlayerCommandOfClubPlayer[]
): CreateClubPlayerCommandOfClubPlayer[] | undefined => {
    const newSelectedClubs = clubs ? [...clubs] : undefined;
    const clubToDeleteIndex = newSelectedClubs
        ? newSelectedClubs.findIndex(
              (sT: CreateClubPlayerCommandOfClubPlayer): boolean =>
                  sT.clubId === club.clubId &&
                  sT.playerId === club.playerId &&
                  sT.activeFrom === club.activeFrom &&
                  sT.activeTo === club.activeTo
          )
        : -1;
    if (clubToDeleteIndex > -1 && newSelectedClubs) {
        newSelectedClubs.splice(clubToDeleteIndex, 1);
        return newSelectedClubs;
    }
};
// #endregion Clubs
