import { ClockCircleFilled, UsergroupAddOutlined } from '@ant-design/icons';
import { Button, Col, Radio, Row, Switch } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import React from 'react';
import { Props } from '.';
import CapSelector from '../../../../components/cap-selector/CapSelector';
import ShotLocation from '../../../../components/shot-location/ShotLocation';
import ShotTarget from '../../../../components/shot-target/ShotTarget';
import { CapNumber } from '../../../../core/models/CapNumber';
import { getTeamId, isShotOnTarget } from '../../../../helpers/EventHelper';
import {
    CreateMatchPlayerCommandOfMatchPlayer,
    EventPeriodEnum,
    EventVm,
    ShotRequest,
    ShotTypeEnum,
    UpdateEventCommand,
} from '../../../../utils/api';

class Shot extends React.PureComponent<Props> {
    public handleBlockedByPlayerSelect = (cap: number): void => {
        const { event, updateEvent, lineups } = this.props;

        const eventVm = event as EventVm;

        const otherTeamLineups = lineups.filter(
            (l: CreateMatchPlayerCommandOfMatchPlayer): boolean => l.teamId !== getTeamId(event)
        );
        const lineup = otherTeamLineups.find(
            (l: CreateMatchPlayerCommandOfMatchPlayer): boolean => l.number === cap
        );
        const playerId = lineup?.id;

        const updatedEvent = new UpdateEventCommand({
            ...event,
            shot: new ShotRequest({
                ...event.shot!,
                blockedById: playerId,
                assistedById: undefined,
                savedById: undefined,
                id: eventVm.shotId || 0,
            }),
        });

        updatedEvent.id = event.id;

        updateEvent(updatedEvent);
    };

    public handleBlockedByPlayerClear = (): void => {
        const { event, updateEvent } = this.props;

        const eventVm = event as EventVm;

        const newShot = new ShotRequest({
            ...event.shot!,
            blockedById: undefined,
            assistedById: undefined,
            id: eventVm.shotId || 0,
        });

        const updatedEvent = new UpdateEventCommand({
            ...event,
            shot: new ShotRequest({
                ...newShot,
                savedById: this.calcSavedById(newShot),
            }),
        });

        updatedEvent.id = event.id;

        updateEvent(updatedEvent);
    };

    public handleAssistedByPlayerSelect = (cap: number): void => {
        const { event, updateEvent, lineups } = this.props;

        const eventVm = event as EventVm;

        const teamLineups = lineups.filter(
            (l: CreateMatchPlayerCommandOfMatchPlayer): boolean => l.teamId === getTeamId(event)
        );
        const lineup = teamLineups.find(
            (l: CreateMatchPlayerCommandOfMatchPlayer): boolean => l.number === cap
        );
        const playerId = lineup?.id;

        const updatedEvent = new UpdateEventCommand({
            ...event,
            shot: new ShotRequest({
                ...event.shot!,
                assistedById: playerId,
                blockedById: undefined,
                id: eventVm.shotId || 0,
            }),
        });

        updatedEvent.id = event.id;

        updateEvent(updatedEvent);
    };

    public handleAssistedByPlayerClear = (): void => {
        const { event, updateEvent } = this.props;

        const eventVm = event as EventVm;

        const updatedEvent = new UpdateEventCommand({
            ...event,
            shot: new ShotRequest({
                ...event.shot!,
                assistedById: undefined,
                blockedById: undefined,
                id: eventVm.shotId || 0,
            }),
        });

        updatedEvent.id = event.id;

        updateEvent(updatedEvent);
    };

    public handleAttackTypeChange = (attackType: ShotTypeEnum): void => {
        const { event, updateEvent } = this.props;

        const eventVm = event as EventVm;

        const updatedEvent = new UpdateEventCommand({
            ...event,
            shot: new ShotRequest({
                ...event.shot!,
                typeId: attackType,
                isFastBreak: attackType !== ShotTypeEnum.Penalty && attackType !== ShotTypeEnum.Penalty_Shootout ? event.shot!.isFastBreak : false,
                isDirectFromFoul:
                    attackType !== ShotTypeEnum.Penalty && attackType !== ShotTypeEnum.Penalty_Shootout ? event.shot!.isDirectFromFoul : false,
                id: eventVm.shotId || 0,
            }),
        });

        updatedEvent.id = event.id;

        updateEvent(updatedEvent);
    };

    public handleGoalToggle = (isGoal: boolean): void => {
        const { event, updateEvent } = this.props;

        const eventVm = event as EventVm;

        const updatedEvent = new UpdateEventCommand({
            ...event,
            shot: new ShotRequest({
                ...event.shot!,
                isGoal,
                blockedById: undefined,
                assistedById: undefined,
                savedById: this.calcSavedById(event.shot!),
                id: eventVm.shotId || 0,
            }),
        });

        updatedEvent.id = event.id;

        updateEvent(updatedEvent);
    };

    public handleFastBreakToggle = (checked: boolean): void => {
        const { event, updateEvent } = this.props;

        const eventVm = event as EventVm;

        const updatedEvent = new UpdateEventCommand({
            ...event,
            shot: new ShotRequest({
                ...event.shot!,
                isFastBreak: checked,
                id: eventVm.shotId || 0,
            }),
        });

        updatedEvent.id = event.id;

        updateEvent(updatedEvent);
    };

    public handleDirectFromFoulToggle = (checked: boolean): void => {
        const { event, updateEvent } = this.props;

        const eventVm = event as EventVm;

        const updatedEvent = new UpdateEventCommand({
            ...event,
            shot: new ShotRequest({
                ...event.shot!,
                isDirectFromFoul: checked,
                id: eventVm.shotId || 0,
            }),
        });

        updatedEvent.id = event.id;

        updateEvent(updatedEvent);
    };

    public handleShotLocationSelect = (x: number, y: number): void => {
        const { event, updateEvent } = this.props;

        const eventVm = event as EventVm;

        const updatedEvent = new UpdateEventCommand({
            ...event,
            shot: new ShotRequest({
                ...event.shot!,
                locationX: x,
                locationY: y,
                id: eventVm.shotId || 0,
            }),
        });

        updatedEvent.id = event.id;

        updateEvent(updatedEvent);
    };

    public handleShotTargetSelect = (x: number, y: number): void => {
        const { event, updateEvent } = this.props;

        const eventVm = event as EventVm;

        const updatedEvent = new UpdateEventCommand({
            ...event,
            shot: new ShotRequest({
                ...event.shot!,
                targetX: x,
                targetY: y,
                savedById: this.calcSavedById({
                    ...event.shot,
                    targetX: x,
                    targetY: y,
                } as ShotRequest),
                id: eventVm.shotId || 0,
            }),
        });

        updatedEvent.id = event.id;

        updateEvent(updatedEvent);
    };

    public calcSavedById = (shot: ShotRequest): number | undefined => {
        const { lineups, selectedHomeKeeper, selectedAwayKeeper, isHomeTeam } = this.props;

        if (shot.blockedById || shot.isGoal || !isShotOnTarget(shot)) {
            return;
        }

        // saved by keeper is in the team opposing the shot taker
        const savedByKeeperNumber = isHomeTeam ? selectedAwayKeeper : selectedHomeKeeper;

        return lineups.find((l) => l.number === savedByKeeperNumber && l.teamId !== shot.teamId)
            ?.id;
    };

    public render = (): React.ReactElement => {
        const { event, lineups, isHomeTeam } = this.props;

        const teamLineups = lineups.filter(
            (l: CreateMatchPlayerCommandOfMatchPlayer): boolean => l.teamId === getTeamId(event)
        );
        const otherTeamLineups = lineups.filter(
            (l: CreateMatchPlayerCommandOfMatchPlayer): boolean => l.teamId !== getTeamId(event)
        );
        const shotType = event.shot?.typeId;

        const assistedById = event.shot?.assistedById;
        const assistedByPlayerLineup = lineups.find(
            (l: CreateMatchPlayerCommandOfMatchPlayer): boolean => l.id === assistedById
        );

        const blockedById = event.shot?.blockedById;
        const blockedByPlayerLineup = lineups.find(
            (l: CreateMatchPlayerCommandOfMatchPlayer): boolean => l.id === blockedById
        );

        const isFastBreak = event.shot?.isFastBreak;
        const isDirectFromFoul = event.shot?.isDirectFromFoul;

        const shotLocationX = event.shot?.locationX;
        const shotLocationY = event.shot?.locationY;

        const shotTargetX = event.shot?.targetX;
        const shotTargetY = event.shot?.targetY;

        const isGoal = event.shot?.isGoal as boolean;

        const isBlockedByDisabled = isGoal || shotType === ShotTypeEnum.Penalty || shotType === ShotTypeEnum.Penalty_Shootout;
        const isAssistedByDisabled = !isGoal || shotType === ShotTypeEnum.Penalty || shotType === ShotTypeEnum.Penalty_Shootout;
        const isPenalty = event.shot?.typeId === ShotTypeEnum.Penalty || event.shot?.typeId === ShotTypeEnum.Penalty_Shootout;
        const capClassBlock = isHomeTeam ? 'bg-blue' : 'bg-white';
        const capClassAssist = isHomeTeam ? 'bg-white' : 'bg-blue';

        return (
            <div className="event-create">
                <Row>
                    <Col span={4} className="is-goal-wrapper">
                        <Switch
                            checkedChildren="Goal"
                            unCheckedChildren="No goal"
                            className="goal-switch"
                            checked={isGoal}
                            onChange={(checked: boolean): void => this.handleGoalToggle(checked)}
                        />
                    </Col>
                    <Col span={19} offset={1}>
                        <Radio.Group
                            className="shot-type-wrapper"
                            value={event.shot?.typeId || ShotTypeEnum.Regular_Attack}
                            buttonStyle="solid"
                            size="large"
                            onChange={(e: RadioChangeEvent): void =>
                                this.handleAttackTypeChange(e.target.value)
                            }
                        >
                            <div className="shot-type-btn-wrapper">
                                <Radio.Button
                                    value={ShotTypeEnum.Regular_Attack}
                                    className={`btn-icon ${capClassAssist}`}
                                >
                                    <ClockCircleFilled style={{ color: '#000' }} />
                                </Radio.Button>
                                <span className="shot-type-title">Regular Attack</span>
                            </div>
                            <div className="shot-type-btn-wrapper">
                                <Radio.Button
                                    value={ShotTypeEnum.Penalty}
                                    className={`btn-icon ${capClassAssist}`}
                                >
                                    <span
                                        style={{
                                            color: '#000',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        5m
                                    </span>
                                </Radio.Button>
                                <span className="shot-type-title">Penalty</span>
                            </div>
                            <div className="shot-type-btn-wrapper">
                                <Radio.Button
                                    value={ShotTypeEnum.Penalty_Shootout}
                                    className={`btn-icon ${capClassAssist}`}
                                >
                                    <span
                                        style={{
                                            color: '#000',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        5m
                                    </span>
                                </Radio.Button>
                                <span className="shot-type-title">Penalty Shootout</span>
                            </div>
                            <div className="shot-type-btn-wrapper">
                                <Radio.Button
                                    value={ShotTypeEnum.Power_Play}
                                    className={`btn-icon ${capClassAssist}`}
                                >
                                    <UsergroupAddOutlined style={{ color: '#000' }} />
                                </Radio.Button>
                                <span className="shot-type-title">Power Play</span>
                            </div>
                            <div className="shot-type-btn-wrapper">
                                <div className="shot-type-switch">
                                    <Switch
                                        className={capClassAssist}
                                        checked={isFastBreak}
                                        onChange={(checked: boolean): void =>
                                            this.handleFastBreakToggle(checked)
                                        }
                                        disabled={isPenalty}
                                    />
                                </div>
                                <span className="shot-type-title">Fast break</span>
                            </div>
                            <div className="shot-type-btn-wrapper">
                                <div className="shot-type-switch">
                                    <Switch
                                        className={capClassAssist}
                                        checked={isDirectFromFoul}
                                        onChange={(checked: boolean): void =>
                                            this.handleDirectFromFoulToggle(checked)
                                        }
                                        disabled={isPenalty}
                                    />
                                </div>
                                <span className="shot-type-title">6m</span>
                            </div>
                        </Radio.Group>
                    </Col>
                </Row>
                <br />
                <Row style={{ marginTop: 50 }}>
                    {!isBlockedByDisabled && (
                        <div className="match-form-lineup">
                            <div className="title">Blocked by:</div>
                            <CapSelector
                                capClass={capClassBlock}
                                onSelect={this.handleBlockedByPlayerSelect}
                                caps={otherTeamLineups.map(
                                    (l: CreateMatchPlayerCommandOfMatchPlayer): CapNumber => ({ cap: l.number, capNumber: l.capNumber || l.number }),
                                )}
                                activeCap={
                                    blockedByPlayerLineup ? blockedByPlayerLineup.number : null
                                }
                                activeCapNumber={blockedByPlayerLineup ? blockedByPlayerLineup.capNumber || blockedByPlayerLineup.number : null}
                            />
                            {blockedByPlayerLineup && (
                                <Button
                                    size="large"
                                    onClick={(): void => this.handleBlockedByPlayerClear()}
                                >
                                    Clear
                                </Button>
                            )}
                        </div>
                    )}
                </Row>
                <br />
                <Row>
                    {!isAssistedByDisabled && (
                        <div className="match-form-lineup">
                            <div className="title">Assisted by:</div>
                            <CapSelector
                                capClass={capClassAssist}
                                onSelect={this.handleAssistedByPlayerSelect}
                                caps={teamLineups.map(
                                    (l: CreateMatchPlayerCommandOfMatchPlayer): CapNumber => ({ cap: l.number, capNumber: l.capNumber || l.number }),
                                )}
                                activeCap={
                                    assistedByPlayerLineup ? assistedByPlayerLineup.number : null
                                }
                                activeCapNumber={assistedByPlayerLineup ? assistedByPlayerLineup.capNumber || assistedByPlayerLineup.number : null}
                                disabled={isAssistedByDisabled}
                            />
                            {assistedByPlayerLineup && (
                                <Button
                                    size="large"
                                    onClick={(): void => this.handleAssistedByPlayerClear()}
                                >
                                    Clear
                                </Button>
                            )}
                        </div>
                    )}
                </Row>
                <br />
                <Row
                    style={{
                        display: 'inline-flex',
                        marginBottom: 20,
                        marginTop: isAssistedByDisabled && isBlockedByDisabled ? 20 : 0,
                    }}
                >
                    <ShotLocation
                        x={shotLocationX}
                        y={shotLocationY}
                        onSelect={this.handleShotLocationSelect}
                    />
                </Row>
                <br />
                <Row>
                    <ShotTarget
                        isGoal={isGoal}
                        x={shotTargetX}
                        y={shotTargetY}
                        onSelect={this.handleShotTargetSelect}
                    />
                </Row>
            </div>
        );
    };
}

export default Shot;
