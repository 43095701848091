import authService from '../components/api-authorization/AuthorizeService';
import {
    ApiException,
    ClubCoachesClient,
    ClubPlayersClient,
    ClubsClient,
    CoachesClient,
    CompetitionsClient,
    CompetitionTeamsClient,
    EventsClient,
    FederationOfficialsClient,
    FederationsClient,
    MatchesClient,
    MatchOfficialsClient,
    OfficialsClient,
    PersonsClient,
    PlayersClient,
    TeamCoachesClient,
    TeamPlayersClient,
    TeamsClient,
} from '../utils/api';
import { errorWithUserMessage, success } from './NotificationHelper';

type PossibleClientTypes =
    | ClubCoachesClient
    | ClubPlayersClient
    | ClubsClient
    | CoachesClient
    | CompetitionsClient
    | CompetitionTeamsClient
    | EventsClient
    | FederationsClient
    | FederationOfficialsClient
    | MatchesClient
    | MatchOfficialsClient
    | OfficialsClient
    | PersonsClient
    | PlayersClient
    | TeamCoachesClient
    | TeamsClient
    | TeamPlayersClient;
type PossibleUpdateClientTypes =
    | ClubCoachesClient
    | ClubPlayersClient
    | ClubsClient
    | CompetitionsClient
    | CompetitionTeamsClient
    | EventsClient
    | FederationsClient
    | FederationOfficialsClient
    | MatchesClient
    | MatchOfficialsClient
    | OfficialsClient
    | PersonsClient
    | PlayersClient
    | TeamCoachesClient
    | TeamsClient
    | TeamPlayersClient;

export const createHelper = async (
    client: PossibleClientTypes,
    request: any,
    callbackFunction?: () => void
): Promise<void> => {
    try {
        const result = await client.create(request);

        // renew token so that the profile.access object refreshes
        authService.renewToken();

        if (result && callbackFunction) {
            callbackFunction();
        }
    } catch (error) {
        if (error instanceof ApiException) {
            errorWithUserMessage(error.response);
        } else {
            errorWithUserMessage('Error saving data.');
        }
    }
};

export const updateHelper = async (
    client: PossibleUpdateClientTypes,
    request: any,
    callbackFunction: () => void
): Promise<void> => {
    try {
        await client.update(request.id, request);
        callbackFunction();
    } catch (error) {
        if (error instanceof ApiException) {
            errorWithUserMessage(error.response);
        } else {
            errorWithUserMessage('Error saving data.');
        }
    }
};

export const handleDeleteHelper = async (
    client: PossibleClientTypes,
    message: string,
    callbackFunction?: () => void,
    id?: number
): Promise<void> => {
    if (id) {
        try {
            await client.delete(id);

            success(message);

            // renew token so that the profile.access object refreshes
            authService.renewToken();

            if (callbackFunction) {
                callbackFunction();
            }
        } catch (error) {
            if (error instanceof ApiException) {
                errorWithUserMessage(error.response);
            } else {
                errorWithUserMessage('Error deleting data.');
            }
        }
    }
};
