import { CreateMatchPlayerCommandOfMatchPlayer, UpdateEventCommand } from '../../../../utils/api';
import Turnover from './Turnover';

interface OwnProps {
    isHomeTeam: boolean;
    event: UpdateEventCommand;
    lineups: CreateMatchPlayerCommandOfMatchPlayer[];
    updateEvent: (event: UpdateEventCommand) => void;
}

export type Props = OwnProps;

export default Turnover;
