import { PageHeader, Tabs } from 'antd';
import React from 'react';
import PageHeaderTitle from '../../../components/page-header';
import Routes from '../../../config/routes';
import ReplaceStrings from '../../../config/replaceStrings';
import { getSubPageInitialProperties } from '../../../helpers/RoutingHelper';
import { OfficialDetailVm, OfficialsClient } from '../../../utils/api';
import PersonDetails from '../../persons/person-page/person-details';
import OfficialMatchTable from '../official-matches-table';
import OfficialRoles from '../official-roles';
import { Props } from './index';
import OfficialDetails from './official-details';

const { TabPane } = Tabs;

interface State {
    official?: OfficialDetailVm;
    activeTabKey: string;
    isEditMode: boolean;
    id?: string;
    loading: boolean;
}

class OfficialPage extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        const {
            location: { pathname },
        } = window;

        const initialProperties = getSubPageInitialProperties(
            pathname,
            'officials',
            props.match,
            Routes.ROUTE_OFFICIALS_READ,
            Routes.ROUTE_OFFICIALS_EDIT
        );

        this.state = {
            activeTabKey: initialProperties.activeTabKey || Routes.ROUTE_OFFICIALS_READ,
            id: initialProperties.initialEntityId,
            isEditMode: initialProperties.isInitialEditMode,
            loading: true,
        };
    }

    public componentDidMount = (): void => {
        this.getOfficialDetails();
    };

    public componentDidUpdate = (prevProps: Props): void => {
        const {
            match: { path },
        } = this.props;

        if (path !== prevProps.match.path) {
            this.updateStateAfterRouteChanges();
        }
    };

    private updateStateAfterRouteChanges = (): void => {
        const { match } = this.props;
        const {
            location: { pathname },
        } = window;

        const initialProperties = getSubPageInitialProperties(
            pathname,
            'officials',
            match,
            Routes.ROUTE_OFFICIALS_READ,
            Routes.ROUTE_OFFICIALS_EDIT
        );

        this.setState({
            activeTabKey: initialProperties.activeTabKey || Routes.ROUTE_OFFICIALS_READ,
            id: initialProperties.initialEntityId,
            isEditMode: initialProperties.isInitialEditMode,
        });
    };

    private getOfficialDetails = async (): Promise<void> => {
        const {
            match: {
                params: { officialId },
            },
        } = this.props;

        if (officialId) {
            const officialClient = new OfficialsClient();
            const officialDetails = await officialClient.getById(parseInt(officialId, 10));

            this.setState({
                official: officialDetails,
            });
        }

        this.setState({
            loading: false,
        });
    };

    private handleTabClick = (route: string): void => {
        const {
            history,
            match: {
                params: { officialId },
            },
        } = this.props;

        this.setState({
            activeTabKey: route,
            isEditMode: false,
        });

        const newRoute = route.replace(ReplaceStrings.OFFICIAL_ID, officialId);

        history.push(newRoute);
    };

    public render(): React.ReactElement {
        const { userProfile } = this.props;
        const { activeTabKey, official, isEditMode } = this.state;

        return (
            <div>
                <PageHeader
                    title={
                        <PageHeaderTitle
                            headerTitle={
                                official
                                    ? `${official.title ? `${official.title} ` : ''}${
                                          official.person.name
                                      } ${official.person.surname}`
                                    : ''
                            }
                            headerPhoto={
                                official && official.person!.photo
                                    ? official.person.photo
                                    : undefined
                            }
                            tableName="official"
                            imgHeight={120}
                        />
                    }
                    style={{ padding: '16px 0px' }}
                />
                <div className="tab-padding">
                    <div
                        style={{
                            overflow: 'hidden',
                            width: '100%',
                            transition: 'width .3s cubic-bezier',
                        }}
                    >
                        <Tabs
                            style={{ width: '100%' }}
                            activeKey={activeTabKey}
                            onTabClick={(key: string): void => this.handleTabClick(key)}
                        >
                            <TabPane tab="Official information" key={Routes.ROUTE_OFFICIALS_READ}>
                                <div>
                                    {official && (
                                        <OfficialDetails
                                            userProfile={userProfile}
                                            isInitialEdit={isEditMode}
                                            official={official}
                                            refreshAfterSave={this.getOfficialDetails}
                                        />
                                    )}
                                </div>
                            </TabPane>
                            <TabPane
                                tab="Personal information"
                                key={Routes.ROUTE_OFFICIAL_PERSONAL_INFO}
                            >
                                {official && (
                                    <PersonDetails
                                        userProfile={userProfile}
                                        isInitialEdit={isEditMode}
                                        person={official.person}
                                        refreshAfterSave={this.getOfficialDetails}
                                        routeHelper={{
                                            mainRoute: Routes.ROUTE_OFFICIALS,
                                            readRoute: Routes.ROUTE_OFFICIAL_PERSONAL_INFO,
                                            editRoute: Routes.ROUTE_OFFICIAL_PERSONAL_INFO_EDIT,
                                            replaceString: ReplaceStrings.OFFICIAL_ID,
                                            id: official.id.toString(),
                                        }}
                                    />
                                )}
                            </TabPane>
                            <TabPane tab="Federations" key={Routes.ROUTE_OFFICIAL_ROLES}>
                                {official && (
                                    <OfficialRoles
                                        userProfile={userProfile}
                                        isInitialEdit={isEditMode}
                                        officialRoles={official.federations}
                                        refreshAfterSave={this.getOfficialDetails}
                                        officialId={official.id}
                                    />
                                )}
                            </TabPane>
                            <TabPane tab="Matches" key={Routes.ROUTE_OFFICIAL_MATCHES}>
                                {official && official.matches && official.matches.length > 0 ? (
                                    <OfficialMatchTable
                                        userProfile={userProfile}
                                        matchesFromProps={official.matches}
                                    />
                                ) : (
                                    <div>The official does not participate in any match.</div>
                                )}
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        );
    }
}

export default OfficialPage;
