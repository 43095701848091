import { CreateMatchPlayerCommandOfMatchPlayer, UpdateEventCommand } from '../../../../utils/api';
import Shot from './Shot';

interface OwnProps {
    isHomeTeam: boolean;
    event: UpdateEventCommand;
    lineups: CreateMatchPlayerCommandOfMatchPlayer[];
    selectedHomeKeeper: number;
    selectedAwayKeeper: number;
    updateEvent: (event: UpdateEventCommand) => void;
}

export type Props = OwnProps;

export default Shot;
