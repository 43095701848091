import moment from 'moment';
import React from 'react';
import Icon from '../../../components/icon/Icon';
import { Props } from './index';

class MatchScoreboard extends React.PureComponent<Props> {
    public render = (): React.ReactElement => {
        const { match, competition, homeTeam, awayTeam } = this.props;

        return (
            <header className="c-game-log__header u-flex u-justify-content-between u-align-items-start">
                <div className="o-team-gl o-team-gl--white">
                    <p className="c-gl-team__note u-flex u-justify-content-start u-align-items-center">
                        <Icon name="icon-trophy" />
                        <span>
                            {competition
                                ? `Competition: ${competition.nameUniversal || competition.name}`
                                : ''}
                        </span>
                    </p>
                    <div className="c-gl-team__team u-flex u-justify-content-start u-align-items-center">
                        <div className="c-gl-team__name">
                            <span>{homeTeam ? homeTeam.nameUniversal || homeTeam.name : ''}</span>
                        </div>
                    </div>
                </div>
                <div className="c-scoreboard">
                    <div className="c-scoreboard__top u-flex u-align-items-start">
                        <div className="c-scoreboard__date">
                            <span>
                                {match ? moment(match.startDate).format('DD.MM.YYYY. HH:mm') : ''}
                            </span>
                        </div>
                        <div className="c-scoreboard__name u-flex u-align-items-center">
                            <span>{match && match.round ? `Round: ${match.round}` : ''}</span>
                            {/* <Icon name="icon-edit-solid" /> */}
                        </div>
                    </div>

                    <div className="c-scoreboard__result u-flex u-align-items-center u-justify-content-center">
                        <span>
                            {match && match.homeTeamGoalsTotal !== null
                                ? match.homeTeamGoalsTotal
                                : ''}
                        </span>
                        <span>-</span>
                        <span>
                            {match && match.awayTeamGoalsTotal !== null
                                ? match.awayTeamGoalsTotal
                                : ''}
                        </span>
                    </div>

                    {match && (
                        <div className="c-scoreboard__quarter-results u-flex u-align-items-start u-justify-content-between">
                            <div>
                                &nbsp;
                                {match &&
                                match.homeTeamGoalsQ1 !== null &&
                                match.awayTeamGoalsQ1 !== null
                                    ? `${match.homeTeamGoalsQ1} - ${match.awayTeamGoalsQ1}`
                                    : ''}
                                &nbsp;
                            </div>
                            <div>
                                &nbsp;
                                {match &&
                                match.homeTeamGoalsQ2 !== null &&
                                match.awayTeamGoalsQ2 !== null
                                    ? `${match.homeTeamGoalsQ2} - ${match.awayTeamGoalsQ2}`
                                    : ''}
                                &nbsp;
                            </div>
                            <div>
                                &nbsp;
                                {match &&
                                match.homeTeamGoalsQ3 !== null &&
                                match.awayTeamGoalsQ3 !== null
                                    ? `${match.homeTeamGoalsQ3} - ${match.awayTeamGoalsQ3}`
                                    : ''}
                                &nbsp;
                            </div>
                            <div>
                                &nbsp;
                                {match &&
                                match.homeTeamGoalsQ4 !== null &&
                                match.awayTeamGoalsQ4 !== null
                                    ? `${match.homeTeamGoalsQ4} - ${match.awayTeamGoalsQ4}`
                                    : ''}
                                &nbsp;
                            </div>
                            <div>
                                &nbsp;
                                {match &&
                                match.homeTeamGoalsOT !== null &&
                                match.awayTeamGoalsOT !== null
                                    ? `${match.homeTeamGoalsOT} - ${match.awayTeamGoalsOT}`
                                    : ''}
                                &nbsp;
                            </div>
                        </div>
                    )}
                </div>
                <div className="o-team-gl o-team-gl--blue">
                    <p className="c-gl-team__note u-flex u-justify-content-end u-align-items-center">
                        <Icon name="icon-map-marker-alt" />
                        <span>
                            {match
                                ? `Venue: ${match.venue || ''}${
                                      match.venue && match.city ? ', ' : ''
                                  }${match.city || ''}`
                                : ''}
                        </span>
                        &nbsp;&nbsp;
                        <Icon name="icon-stopwatch" />
                        <span>
                            {match && match.status
                                ? `Status: ${match.status.replace('_', ' ')}`
                                : ''}
                        </span>
                    </p>
                    <div className="c-gl-team__team u-flex u-justify-content-end u-align-items-center">
                        <div className="c-gl-team__name">
                            <span>{awayTeam ? awayTeam.nameUniversal || awayTeam.name : ''}</span>
                        </div>
                    </div>
                </div>
            </header>
        );
    };
}

export default MatchScoreboard;
