import { RouteComponentProps, withRouter } from 'react-router-dom';
import { TeamDetailVm } from '../../../../utils/api';
import { RouteParams } from '../../../../core/models/RouteHelper';
import TeamDetails from './TeamDetails';
import { Profile } from 'oidc-client';

interface OwnProps {
    userProfile: Profile;
    isInitialEdit: boolean;
    team: TeamDetailVm;
    refreshAfterSave: () => Promise<void>;
}

export type Props = OwnProps & RouteComponentProps<RouteParams>;

export default withRouter(TeamDetails);
