import { UploadOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, Radio, Row, Upload } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import moment from 'moment';
import React from 'react';
import AutocompleteDropdown from '../../../components/autocomplete-dropdown';
import DrawerButtons from '../../../components/drawer-buttons/index';
import FederationRoles from '../../../components/federation-roles';
import { DropdownOption } from '../../../core/models/DropdownOption';
import { DrawerState, ProfileOption } from '../../../core/models/enum';
import { profileOptionButtons, ProfileOptionsModel } from '../../../core/models/FormProfileOptions';
import {
    confirmDropdownHelper,
    filterDropdownOptions,
    getCitiesHelper,
    getCountriesHelper,
} from '../../../helpers/AutocompleteDropdownHelper';
import { checkIfStringIsInArray } from '../../../helpers/CompareHelper';
import { convertUTCTimeToLocal } from '../../../helpers/DateHelper';
import {
    removeFederationRoleFromListHelper,
    updateFederationRolesHelper,
} from '../../../helpers/FederationRolesListHelper';
import { getFederationsToDropdownOptions } from '../../../helpers/FetchAndTransformHelpers';
import { onUploadFileChangeHelper } from '../../../helpers/FileHelper';
import { formItemLayout024, formItemLayout724 } from '../../../helpers/FormLayoutHelper';
import { success } from '../../../helpers/NotificationHelper';
import { officialSubmitHelper } from '../../../helpers/OfficialSubmitHelper';
import {
    transformEnumToDropdownOptions,
    transformPersonSuggestions,
    transformPersonToDropdownOption,
} from '../../../helpers/TransformHelper';
import {
    customArrayValidationHelper,
    customStringValidationHelper,
} from '../../../helpers/ValidationHelper';
import {
    CreateFederationOfficialCommandOfFederationOfficial,
    FederationOfficialRoleVm,
    FederationOfficialVm,
    OfficialRoleEnum,
    PersonsClient,
    PersonVm,
} from '../../../utils/api';
import { Props } from './index';
import './styles.css';

interface State {
    isSaving: boolean;
    countries: DropdownOption[];
    cities: DropdownOption[];
    federations: DropdownOption[];
    roles: DropdownOption[];
    persons: DropdownOption[];
    personVMs: PersonVm[];
    selectedCountry?: DropdownOption[];
    selectedCity?: DropdownOption[];
    selectedFederation?: DropdownOption[];
    selectedRoles?: DropdownOption[];
    selectedPerson?: DropdownOption[];
    selectedPersonVm?: PersonVm;
    selectedProfileOption?: string;
    selectedFederationRoles?: CreateFederationOfficialCommandOfFederationOfficial[];
    file?: any;
    fileBase64?: string;
    name?: string;
    surname?: string;
    personSuggestions?: DropdownOption[];
}

class OfficialForm extends React.PureComponent<Props, State> {
    private formRef: any;

    public constructor(props: Props) {
        super(props);

        const { official } = props;

        this.formRef = React.createRef();

        this.state = {
            isSaving: false,
            countries: [],
            cities: [],
            federations: [],
            roles: [],
            persons: [],
            personVMs: [],
            fileBase64: official ? official.person.photo : undefined,
        };
    }

    public componentDidMount = (): void => {
        const { official } = this.props;

        this.getCountries();
        if (!(official && official.person && official.person.countryOfBirthId)) {
            this.getCities();
        }
        this.getFederations();
        this.getRoles();
        this.getPersons();
    };

    private getCountries = async (): Promise<void> => {
        const { official } = this.props;
        const countryId = official && official.person.countryOfBirthId;

        const countryHelper = await getCountriesHelper(countryId);

        if (countryId) {
            this.getCities(countryId);
        }

        this.setState({
            countries: countryHelper.entities,
            selectedCountry: countryHelper.selectedEntity,
        });
    };

    private getCities = async (countryId?: number): Promise<void> => {
        const { official } = this.props;
        const cityId = official && official.person.cityOfBirthId;

        const cityHelper = await getCitiesHelper(countryId, cityId);

        this.setState({
            cities: cityHelper.entities,
            selectedCity: cityHelper.selectedEntity,
        });
    };

    private getFederations = async (): Promise<void> => {
        const { initialValues } = this.props;
        const results = await getFederationsToDropdownOptions(
            initialValues && initialValues.federationId
        );

        this.setState({
            federations: results.entities,
            selectedFederation: results.selectedEntity,
        });
    };

    private getRoles = async (): Promise<void> => {
        const { federationOfficial } = this.props;
        const roles = transformEnumToDropdownOptions(OfficialRoleEnum);

        const selectedRoles = roles.filter(
            (r: DropdownOption): boolean =>
                !!federationOfficial?.roles?.find(
                    (rol: FederationOfficialRoleVm): boolean => rol.roleId.toString() === r.id
                )
        );

        this.setState({
            roles,
            selectedRoles,
        });
    };

    private getPersons = async (): Promise<void> => {
        const { initialValues } = this.props;
        const personClient = new PersonsClient();
        const personsVMs = await personClient.getAll();

        const persons: DropdownOption[] = [];
        const personVMs: PersonVm[] = [];

        personsVMs.forEach((p: PersonVm): void => {
            if (!(initialValues && initialValues.federationId) && !p.officialId) {
                personVMs.push(p);
                persons.push(transformPersonToDropdownOption(p));
            } else if (initialValues && initialValues.federationId && !p.officialId) {
                personVMs.push(p);
                persons.push(transformPersonToDropdownOption(p));
            } else if (initialValues && initialValues.federationId && !initialValues.officials) {
                personVMs.push(p);
                persons.push(transformPersonToDropdownOption(p));
            } else if (
                initialValues &&
                initialValues.federationId &&
                initialValues.officials &&
                p.officialId &&
                !initialValues.officials.find(
                    (o: FederationOfficialVm): boolean => o.officialId === p.officialId
                )
            ) {
                personVMs.push(p);
                persons.push(transformPersonToDropdownOption(p));
            } else {
                personVMs.push(p);
                persons.push(
                    transformPersonToDropdownOption(p, 'This person is already a official!')
                );
            }
        });

        this.setState({
            persons,
            personVMs,
        });
    };

    private onSubmit = (values: any): void => {
        const {
            selectedCountry,
            selectedCity,
            selectedPerson,
            selectedProfileOption,
            fileBase64,
            selectedPersonVm,
            selectedFederation,
            selectedRoles,
            selectedFederationRoles,
        } = this.state;
        const { official, initialValues, federationOfficial } = this.props;

        this.setState({
            isSaving: true,
        });

        officialSubmitHelper(
            this.handleSuccessfullySaving,
            values,
            selectedCountry,
            selectedCity,
            selectedPerson,
            selectedProfileOption,
            fileBase64,
            selectedPersonVm,
            selectedRoles,
            selectedFederation,
            selectedFederationRoles,
            official,
            initialValues,
            federationOfficial
        );

        this.setState({
            isSaving: false,
        });
    };

    private confirmPerson = (options: DropdownOption[]): void => {
        const { personVMs } = this.state;
        const selectedPersonVm =
            options && options.length > 0
                ? personVMs.find((p: PersonVm): boolean => p.id.toString() === options[0].id)
                : undefined;

        this.setState({
            selectedPerson: confirmDropdownHelper(options),
            selectedPersonVm,
        });

        if (selectedPersonVm && selectedPersonVm.official) {
            this.formRef.current.setFieldsValue({
                title: selectedPersonVm.official.title,
            });
        }
    };

    private confirmExistingPerson = (option: DropdownOption): void => {
        const { personVMs } = this.state;
        const selectedPersonVm = personVMs.find(
            (p: PersonVm): boolean => p.id.toString() === option.id
        );

        this.setState({
            selectedPerson: [option],
            selectedPersonVm,
            selectedProfileOption: ProfileOption.Existing,
        });

        if (selectedPersonVm && selectedPersonVm.official) {
            this.formRef.current.setFieldsValue({
                title: selectedPersonVm.official.title,
            });
        }
    };

    private handleSuccessfullySaving = (): void => {
        const { official, handleClose, refreshAfterSave } = this.props;

        success('Official successfully saved.');
        refreshAfterSave();
        handleClose(
            official ? DrawerState.Read : DrawerState.Closed,
            official ? official.id.toString() : undefined
        );
    };

    private confirmCountry = (option: DropdownOption[]): void => {
        const selectedCountry = confirmDropdownHelper(option);
        this.setState({
            selectedCountry,
            selectedCity: undefined,
        });
        if (selectedCountry) {
            this.getCities(parseInt(selectedCountry[0].id, 10));
        }
    };

    private onUploadFileChange = async (file: File): Promise<void> => {
        const uploadHelper = await onUploadFileChangeHelper(file);

        if (uploadHelper) {
            this.setState({
                file: uploadHelper.file,
                fileBase64: uploadHelper.fileBase64,
            });
        }
    };

    private checkIfPersonExists = (
        event: React.FocusEvent<HTMLInputElement>,
        field: string
    ): void => {
        const { name, surname, persons } = this.state;
        const targetValue = event && event.target && event.target.value;

        if (field === 'name') {
            this.setState({
                personSuggestions: checkIfStringIsInArray(
                    `${targetValue || ''} ${surname || ''}`,
                    persons
                ),
                name: event.target.value,
            });
        } else {
            this.setState({
                personSuggestions: checkIfStringIsInArray(
                    `${name || ''} ${targetValue || ''}`,
                    persons
                ),
                surname: event.target.value,
            });
        }
    };

    private updateFederationRoles = (
        federationRole: CreateFederationOfficialCommandOfFederationOfficial
    ): void => {
        const { selectedFederationRoles } = this.state;
        this.setState({
            selectedFederationRoles: updateFederationRolesHelper(
                federationRole,
                selectedFederationRoles
            ),
        });
    };

    private removeFederationRoleFromList = (
        federationRole: CreateFederationOfficialCommandOfFederationOfficial
    ): void => {
        const { selectedFederationRoles } = this.state;
        const newFederationRoles = removeFederationRoleFromListHelper(
            federationRole,
            selectedFederationRoles
        );
        if (newFederationRoles) {
            this.setState({ selectedFederationRoles: newFederationRoles });
        }
    };

    private federationRolesArrayValidation = (
        _rule: any,
        value: any,
        callback: any,
        message: string,
        entityToValidate?: CreateFederationOfficialCommandOfFederationOfficial[]
    ): void => {
        if (!entityToValidate || entityToValidate.length === 0) {
            callback(message);
        } else {
            callback();
        }
    };

    public render(): React.ReactElement {
        const {
            isSaving,
            selectedCountry,
            selectedCity,
            selectedFederation,
            selectedRoles,
            selectedProfileOption,
            selectedPerson,
            selectedPersonVm,
            selectedFederationRoles,
            file,
            fileBase64,
            countries,
            cities,
            federations,
            roles,
            persons,
            personSuggestions,
        } = this.state;
        const { handleClose, official, initialValues, federationOfficial } = this.props;

        return (
            <Form onFinish={this.onSubmit} {...formItemLayout724} ref={this.formRef}>
                <DrawerButtons
                    isSaving={isSaving}
                    onCancelAction={(): void =>
                        handleClose(
                            official ? DrawerState.Read : DrawerState.Closed,
                            official ? official.id.toString() : undefined
                        )
                    }
                />
                <div className="drawer-content">
                    {!official && (
                        <div>
                            {!federationOfficial && (
                                <div>
                                    <div className="drawer-section-title">
                                        <h3>Profile information</h3>
                                    </div>
                                    <Form.Item
                                        name="profileInformationOption"
                                        rules={[
                                            {
                                                required: true,
                                                validator: (
                                                    _rule: any,
                                                    value: any,
                                                    callback: any
                                                ): void =>
                                                    customStringValidationHelper(
                                                        _rule,
                                                        value,
                                                        callback,
                                                        'You have to choose one of these options!',
                                                        selectedProfileOption
                                                    ),
                                            },
                                        ]}
                                        {...formItemLayout024}
                                    >
                                        {profileOptionButtons.map(
                                            (pOB: ProfileOptionsModel): React.ReactElement => (
                                                <Radio.Button
                                                    value={pOB.value}
                                                    checked={selectedProfileOption === pOB.value}
                                                    onChange={(e: RadioChangeEvent): void =>
                                                        this.setState({
                                                            selectedProfileOption: e.target.value,
                                                        })
                                                    }
                                                    style={{
                                                        width: 130,
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    {pOB.title}
                                                </Radio.Button>
                                            )
                                        )}
                                    </Form.Item>
                                    {selectedProfileOption === ProfileOption.Existing && (
                                        <div
                                            style={{
                                                width: '100%',
                                                float: 'left',
                                            }}
                                        >
                                            <Form.Item
                                                name="personId"
                                                label="Profile"
                                                rules={[
                                                    {
                                                        required: true,
                                                        validator: (
                                                            _rule: any,
                                                            value: any,
                                                            callback: any
                                                        ): void =>
                                                            customArrayValidationHelper(
                                                                _rule,
                                                                value,
                                                                callback,
                                                                'Profile field is required!',
                                                                selectedPerson
                                                            ),
                                                    },
                                                ]}
                                            >
                                                <AutocompleteDropdown
                                                    placeholder="Choose profile"
                                                    getOptionsFrontend={(
                                                        value: string
                                                    ): DropdownOption[] =>
                                                        filterDropdownOptions(value, persons)
                                                    }
                                                    initialValues={selectedPerson}
                                                    confirmDirty={(
                                                        options: DropdownOption[]
                                                    ): void => this.confirmPerson(options)}
                                                    style={{ width: '100%' }}
                                                />
                                            </Form.Item>
                                        </div>
                                    )}
                                    {selectedProfileOption === ProfileOption.New && (
                                        <div
                                            style={{
                                                width: '100%',
                                                float: 'left',
                                            }}
                                        >
                                            <Form.Item
                                                name="name"
                                                label="Name"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Name field is required!',
                                                    },
                                                    {
                                                        max: 200,
                                                        message: 'Too long input!',
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    onBlur={(
                                                        e: React.FocusEvent<HTMLInputElement>
                                                    ): void => this.checkIfPersonExists(e, 'name')}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                name="surname"
                                                label="Surname"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Surname field is required!',
                                                    },
                                                    {
                                                        max: 200,
                                                        message: 'Too long input!',
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    onBlur={(
                                                        e: React.FocusEvent<HTMLInputElement>
                                                    ): void =>
                                                        this.checkIfPersonExists(e, 'surname')
                                                    }
                                                />
                                            </Form.Item>
                                            {personSuggestions && personSuggestions.length > 0 && (
                                                <Form.Item className="person-suggestion" label=" ">
                                                    <span>
                                                        Profiles with this name and surname already
                                                        exists. Click on the profile to bind the
                                                        official role to it.
                                                    </span>
                                                    {transformPersonSuggestions(
                                                        personSuggestions,
                                                        this.confirmExistingPerson
                                                    )}
                                                </Form.Item>
                                            )}
                                            <Form.Item
                                                name="address"
                                                label="Address"
                                                rules={[
                                                    {
                                                        max: 200,
                                                        message: 'Too long input!',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                name="nationality"
                                                label="Nationality"
                                                rules={[
                                                    {
                                                        max: 200,
                                                        message: 'Too long input!',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                name="countryOfBirthId"
                                                label="Country of birth"
                                            >
                                                <AutocompleteDropdown
                                                    placeholder="Choose country"
                                                    getOptionsFrontend={(
                                                        value: string
                                                    ): DropdownOption[] =>
                                                        filterDropdownOptions(value, countries)
                                                    }
                                                    initialValues={selectedCountry}
                                                    confirmDirty={this.confirmCountry}
                                                    style={{ width: '100%' }}
                                                    isAllowedToClear
                                                />
                                            </Form.Item>
                                            <Form.Item name="cityOfBirthId" label="City of birth">
                                                <AutocompleteDropdown
                                                    placeholder="Choose city"
                                                    getOptionsFrontend={(
                                                        value: string
                                                    ): DropdownOption[] =>
                                                        filterDropdownOptions(value, cities)
                                                    }
                                                    initialValues={selectedCity}
                                                    confirmDirty={(
                                                        options: DropdownOption[]
                                                    ): void =>
                                                        this.setState({
                                                            selectedCity:
                                                                confirmDropdownHelper(options),
                                                        })
                                                    }
                                                    style={{ width: '100%' }}
                                                    isAllowedToClear
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                name="birthDate"
                                                label="Birth date"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Birth date field is required!',
                                                    },
                                                ]}
                                            >
                                                <DatePicker
                                                    format="DD.MM.YYYY."
                                                    style={{ width: '100%' }}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                name="phone"
                                                label="Phone"
                                                rules={[
                                                    {
                                                        // eslint-disable-next-line
                                                        pattern: /^[0-9(\)\+]+$/,
                                                        message:
                                                            'Only numbers and plus sign are allowed!',
                                                    },
                                                    {
                                                        max: 200,
                                                        message: 'Too long input!',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                name="email"
                                                label="Email"
                                                rules={[
                                                    {
                                                        type: 'email',
                                                        message: 'Wrong e-mail format!',
                                                    },
                                                    {
                                                        max: 200,
                                                        message: 'Too long input!',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                name="facebook"
                                                label="Facebook"
                                                rules={[
                                                    {
                                                        max: 200,
                                                        message: 'Too long input!',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                name="twitter"
                                                label="Twitter"
                                                rules={[
                                                    {
                                                        max: 200,
                                                        message: 'Too long input!',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                name="youtube"
                                                label="Youtube"
                                                rules={[
                                                    {
                                                        max: 200,
                                                        message: 'Too long input!',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                name="instagram"
                                                label="Instagram"
                                                rules={[
                                                    {
                                                        max: 200,
                                                        message: 'Too long input!',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item name="photo" label="Photo">
                                                {fileBase64 && (
                                                    <Row>
                                                        <Col span="24">
                                                            <img
                                                                height={55}
                                                                src={`data:image/jpeg;base64,${fileBase64}`}
                                                                alt="logo"
                                                            />
                                                        </Col>
                                                        <Col span="24">
                                                            <Button
                                                                type="primary"
                                                                danger
                                                                style={{
                                                                    marginTop: 10,
                                                                }}
                                                                onClick={(): void =>
                                                                    this.setState({
                                                                        file: undefined,
                                                                        fileBase64: undefined,
                                                                    })
                                                                }
                                                            >
                                                                Remove logo
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                )}
                                                {!fileBase64 && (
                                                    <Upload
                                                        accept="image/png"
                                                        style={{ zIndex: 10 }}
                                                        beforeUpload={(file) => {
                                                            this.onUploadFileChange(file);
                                                            return false;
                                                        }}
                                                        onRemove={(): void =>
                                                            this.setState({
                                                                file: undefined,
                                                                fileBase64: undefined,
                                                            })
                                                        }
                                                        fileList={file ? [{ ...file }] : []}
                                                    >
                                                        <Button type="primary" disabled={!!file}>
                                                            <UploadOutlined className="button-icon-display" />
                                                            Upload image
                                                        </Button>
                                                    </Upload>
                                                )}
                                            </Form.Item>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                    {!federationOfficial && (
                        <div>
                            {!official && (
                                <div className="drawer-section-title">
                                    <h3>Official information</h3>
                                </div>
                            )}
                            <Form.Item
                                name="title"
                                label="Title"
                                initialValue={official ? official.title : undefined}
                                rules={[{ max: 200, message: 'Too long input!' }]}
                            >
                                <Input
                                    disabled={
                                        selectedPersonVm &&
                                        selectedPersonVm.official &&
                                        selectedPersonVm.official.title !== undefined &&
                                        selectedProfileOption === ProfileOption.Existing
                                    }
                                />
                            </Form.Item>
                            {!(initialValues && initialValues.federationId) && !official && (
                                <div>
                                    <div className="drawer-section-title">
                                        <h3>Federations</h3>
                                    </div>
                                    <Form.Item
                                        name="federationRoles"
                                        {...formItemLayout024}
                                        rules={[
                                            {
                                                required: true,
                                                validator: (
                                                    _rule: any,
                                                    value: any,
                                                    callback: any
                                                ): void =>
                                                    this.federationRolesArrayValidation(
                                                        _rule,
                                                        value,
                                                        callback,
                                                        'Federation field is required!',
                                                        selectedFederationRoles
                                                    ),
                                            },
                                        ]}
                                    >
                                        <FederationRoles
                                            initialFederationRoleId={
                                                initialValues && initialValues.federationId
                                            }
                                            saveChanges={this.updateFederationRoles}
                                            selectedFederationRoles={selectedFederationRoles}
                                            removeFederationRoleFromList={
                                                this.removeFederationRoleFromList
                                            }
                                        />
                                    </Form.Item>
                                </div>
                            )}
                        </div>
                    )}
                    {initialValues && initialValues.federationId && !official && (
                        <div>
                            {!federationOfficial && (
                                <div className="drawer-section-title">
                                    <h3>Federation</h3>
                                </div>
                            )}
                            <Form.Item
                                name="federationId"
                                label="Federation"
                                rules={[
                                    {
                                        required: true,
                                        validator: (_rule: any, value: any, callback: any): void =>
                                            customArrayValidationHelper(
                                                _rule,
                                                value,
                                                callback,
                                                'Federation field is required!',
                                                selectedFederation
                                            ),
                                    },
                                ]}
                            >
                                <AutocompleteDropdown
                                    placeholder="Choose federation"
                                    getOptionsFrontend={(value: string): DropdownOption[] =>
                                        filterDropdownOptions(value, federations)
                                    }
                                    initialValues={selectedFederation}
                                    confirmDirty={(options: DropdownOption[]): void =>
                                        this.setState({
                                            selectedFederation: confirmDropdownHelper(options),
                                        })
                                    }
                                    style={{ width: '100%' }}
                                    disabled={!!(initialValues && initialValues.federationId)}
                                    isAllowedToClear
                                />
                            </Form.Item>
                            <Form.Item
                                name="roleIds"
                                label="Roles"
                                rules={[
                                    {
                                        required: true,
                                        validator: (_rule: any, value: any, callback: any): void =>
                                            customArrayValidationHelper(
                                                _rule,
                                                value,
                                                callback,
                                                'Roles field is required!',
                                                selectedRoles
                                            ),
                                    },
                                ]}
                            >
                                <AutocompleteDropdown
                                    placeholder="Choose roles"
                                    getOptionsFrontend={(value: string): DropdownOption[] =>
                                        filterDropdownOptions(value, roles)
                                    }
                                    initialValues={selectedRoles}
                                    mode="multiple"
                                    confirmDirty={(options: DropdownOption[]): void =>
                                        this.setState({
                                            selectedRoles: confirmDropdownHelper(options),
                                        })
                                    }
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                            <Form.Item
                                name="activeFrom"
                                label="Active from"
                                initialValue={
                                    federationOfficial?.activeFrom
                                        ? moment(
                                              convertUTCTimeToLocal(federationOfficial.activeFrom)
                                          )
                                        : moment(convertUTCTimeToLocal(new Date()))
                                }
                            >
                                <DatePicker
                                    format="DD.MM.YYYY."
                                    style={{ width: '100%' }}
                                    allowClear={false}
                                />
                            </Form.Item>
                            <Form.Item
                                name="activeTo"
                                label="Active to"
                                initialValue={
                                    federationOfficial?.activeTo
                                        ? moment(convertUTCTimeToLocal(federationOfficial.activeTo))
                                        : undefined
                                }
                            >
                                <DatePicker format="DD.MM.YYYY." style={{ width: '100%' }} />
                            </Form.Item>
                        </div>
                    )}
                </div>
            </Form>
        );
    }
}

export default OfficialForm;
