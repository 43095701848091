import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RouteParams } from '../../../core/models/RouteHelper';
import { DrawerState } from '../../../core/models/enum';
import { CompetitionTeamFormInitialValues } from '../../../core/models/InitialValues';
import { CompetitionTeamVm } from '../../../utils/api';
import CompetitionTeamForm from './CompetitionTeamForm';

interface OwnProps {
    competitionTeam?: CompetitionTeamVm;
    initialValues?: CompetitionTeamFormInitialValues;
    handleClose: (drawerMode: DrawerState, id?: string) => void;
    refreshAfterSave: () => Promise<void>;
}

export type Props = OwnProps & RouteComponentProps<RouteParams>;

export default withRouter(CompetitionTeamForm);
