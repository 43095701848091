import React from 'react';
import icons from '../../assets/icons/icons.svg';

interface Props {
    name: string;
    className?: string;
    style?: React.CSSProperties;
}

class Icon extends React.PureComponent<Props> {
    public render = (): React.ReactElement => {
        const { name, className, style } = this.props;

        return (
            <svg className={`icon ${name} ${className}`} style={style}>
                <use xlinkHref={`${icons}#${name}`} />
            </svg>
        );
    };
}

export default Icon;
