import { Table } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import React from 'react';
import { Filter } from '../../../core/models/Filter';
import { buildFilterRequest } from '../../../helpers/FilterHelper';
import { PaginationOptions } from '../../../helpers/PaginationHelper';
import { feTableFilterHelper } from '../../../helpers/TableFilterHelper';
import { capitalizeFirstLetter } from '../../../helpers/TransformHelper';
import {
    CompetitionsClient,
    CompetitionVm,
    PaginatedListOfCompetitionVm,
} from '../../../utils/api';
import { columns, DefaultCompetitionFilter } from '../columnHelper/CompetitionColumnHelper';
import { Props } from './index';

interface State {
    competitions: CompetitionVm[];
    paginationOptions: TablePaginationConfig;
    currentFilter: Filter;
    loading: boolean;
}

class CompetitionTable extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            competitions: [],
            paginationOptions: PaginationOptions,
            currentFilter: DefaultCompetitionFilter,
            loading: true,
        };
    }

    public componentDidMount = (): void => {
        this.getCompetitions();
    };

    private getCompetitions = async (currentFilterParam?: Filter): Promise<void> => {
        const { currentFilter } = this.state;
        const { competitionsFromProps } = this.props;

        this.setState({
            loading: true,
        });

        let competitionsPagedList;
        const filter = currentFilterParam || currentFilter;

        if (competitionsFromProps) {
            competitionsPagedList = this.getCompetitionsFromProps(filter);
        } else {
            competitionsPagedList = await this.getAllCompetitions(filter);
        }

        this.setState({
            paginationOptions: {
                ...PaginationOptions,
                current: competitionsPagedList.currentPage,
                pageSize: competitionsPagedList.pageSize,
                total: competitionsPagedList.totalItems,
            },
            competitions: competitionsPagedList?.pageItems || [],
            loading: false,
        });
    };

    private getAllCompetitions = async (filter: Filter): Promise<PaginatedListOfCompetitionVm> => {
        const competitionsClient = new CompetitionsClient();

        return await competitionsClient.getPaginated(
            filter.filterRequest?.nameUniversal,
            filter.filterRequest?.startDate as Date,
            filter.filterRequest?.endDate,
            filter.filterRequest?.isPro,
            filter.filterRequest?.federation,
            filter.filterRequest?.category,
            filter.filterRequest?.competitionType,
            filter.filterRequest?.country,
            filter.filterRequest?.city,
            filter.pageIndex,
            filter.pageSize,
            capitalizeFirstLetter(filter.orderByKey),
            filter.orderByValue
        );
    };

    private getCompetitionsFromProps = (filter: Filter): PaginatedListOfCompetitionVm => {
        const { competitionsFromProps } = this.props;

        const filteredResult = feTableFilterHelper(competitionsFromProps || [], filter);
        return new PaginatedListOfCompetitionVm({
            ...filteredResult,
            pageItems: filteredResult.pageItems as CompetitionVm[],
        });
    };

    private handleTableChange = (pagination: any, filters: any, sorter: any): void => {
        const filterRequest = buildFilterRequest(filters);

        const filter: Filter = {
            pageIndex: pagination.current,
            pageSize: pagination.pageSize,
            orderByKey:
                sorter?.columnKey && sorter?.order
                    ? sorter.columnKey
                    : DefaultCompetitionFilter.orderByKey,
            orderByValue: sorter?.order ? sorter.order : DefaultCompetitionFilter.orderByValue,
            filterRequest,
        };

        this.getCompetitions(filter);
        this.setState({
            currentFilter: filter,
        });
    };

    public render(): React.ReactElement {
        const { competitions, loading, paginationOptions } = this.state;

        return (
            <Table
                columns={columns}
                loading={loading}
                dataSource={competitions}
                rowKey={(record): string => (record && record.id ? record.id.toString() : '-1')}
                pagination={paginationOptions}
                onChange={this.handleTableChange}
                bordered
                size="small"
            />
        );
    }
}

export default CompetitionTable;
