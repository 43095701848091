import { SortDirectionEnum } from '../core/models/enum';
import { compare, compareDates, reverseCompare, reverseCompareDates } from './CompareHelper';

export const sortFeTableHelper = (
    orderByKey: string,
    orderByValue: SortDirectionEnum,
    unsortedList: any[]
): any[] => {
    const sortedList = [...unsortedList];

    if (regularCompareKeys.find((rck: string): boolean => rck === orderByKey)) {
        sortedList.sort((a: any, b: any): number =>
            orderByValue === SortDirectionEnum.ASC
                ? compare(a[orderByKey]?.toLowerCase(), b[orderByKey]?.toLowerCase())
                : reverseCompare(a[orderByKey]?.toLowerCase(), b[orderByKey]?.toLowerCase())
        );
    } else if (dateCompareKeys.find((dck: string): boolean => dck === orderByKey)) {
        sortedList.sort((a: any, b: any): number =>
            orderByValue === SortDirectionEnum.ASC
                ? compareDates(a[orderByKey], b[orderByKey])
                : reverseCompareDates(a[orderByKey], b[orderByKey])
        );
    } else if (booleanCompareKeys.find((bck: string): boolean => bck === orderByKey)) {
        sortedList.sort((a: any, b: any): number =>
            orderByValue === SortDirectionEnum.ASC
                ? compare(a[orderByKey]?.toString(), b[orderByKey]?.toString())
                : reverseCompare(a[orderByKey]?.toString(), b[orderByKey]?.toString())
        );
    } else if (possibleFakeArrayKeys.find((bck: string): boolean => bck === orderByKey)) {
        sortedList.sort((a: any, b: any): number =>
            orderByValue === SortDirectionEnum.ASC
                ? compare(Array.isArray(a[orderByKey])
                    ? a[orderByKey][0]?.toLowerCase()
                    : a[orderByKey]?.toLowerCase(), Array.isArray(b[orderByKey])
                    ? b[orderByKey][0]?.toLowerCase()
                    : b[orderByKey]?.toLowerCase())
                : reverseCompare(Array.isArray(a[orderByKey])
                    ? a[orderByKey][0]?.toLowerCase()
                    : a[orderByKey]?.toLowerCase(), Array.isArray(b[orderByKey])
                    ? b[orderByKey][0]?.toLowerCase()
                    : b[orderByKey]?.toLowerCase())
        );
    }

    return sortedList;
};

export const regularCompareKeys = [
    'awayTeam',
    'awayTeamDisplayName',
    'category',
    'city',
    'cityOfBirth',
    'club',
    'clubDisplayName',
    'competition',
    'competitionDisplayName',
    'competitionType',
    'country',
    'countryOfBirth',
    'email',
    'federation',
    'homeTeam',
    'homeTeamDisplayName',
    'name',
    'nameUniversal',
    'person',
    'round',
    'shortName',
    'status',
    'surname',
    'venue',
];

export const dateCompareKeys = ['activeFrom', 'activeTo', 'endDate', 'startDate'];

export const booleanCompareKeys = ['isPro', 'emailConfirmed'];

export const possibleFakeArrayKeys = ['type'];
