import { Profile } from 'oidc-client';
import { ModuleName } from '../../core/models/enum';
import DetailsButtons from './DetailsButtons';

interface OwnProps {
    userProfile: Profile;
    disabled?: boolean;
    editTooltip?: string;
    onEditAction: () => void;
    onUpdatePINAction?: () => void;
    onDeleteAction?: () => Promise<void>;
    customMessage?: string;
    styles?: React.CSSProperties;
    entityId?: number;
    moduleName: ModuleName;
}

export type Props = OwnProps;

export default DetailsButtons;
