const routes = {
    ROUTE_DASHBOARD: '/',

    ROUTE_FEDERATIONS: '/federations',
    ROUTE_FEDERATIONS_CREATE: '/federations/create',
    ROUTE_FEDERATIONS_READ: '/federations/:federationId',
    ROUTE_FEDERATIONS_EDIT: '/federations/:federationId/edit',

    ROUTE_FEDERATION_CLUBS: '/federations/:federationId/clubs',
    ROUTE_FEDERATION_CLUBS_CREATE: '/federations/:federationId/clubs/create',
    ROUTE_FEDERATION_COMPETITIONS: '/federations/:federationId/competitions',
    ROUTE_FEDERATION_COMPETITIONS_CREATE: '/federations/:federationId/competitions/create',
    ROUTE_FEDERATION_OFFICIALS: '/federations/:federationId/officials',
    ROUTE_FEDERATION_OFFICIALS_CREATE: '/federations/:federationId/officials/create',
    ROUTE_FEDERATION_OFFICIALS_EDIT: '/federations/:federationId/officials/:officialId/edit',

    ROUTE_CLUBS: '/clubs',
    ROUTE_CLUBS_CREATE: '/clubs/create',
    ROUTE_CLUBS_READ: '/clubs/:clubId',
    ROUTE_CLUBS_EDIT: '/clubs/:clubId/edit',

    ROUTE_CLUB_TEAMS: '/clubs/:clubId/teams',
    ROUTE_CLUB_TEAMS_CREATE: '/clubs/:clubId/teams/create',
    ROUTE_CLUB_COACHES: '/clubs/:clubId/coaches',
    ROUTE_CLUB_COACHES_CREATE: '/clubs/:clubId/coaches/create',
    ROUTE_CLUB_COACHES_EDIT: '/clubs/:clubId/coaches/:coachId/edit',
    ROUTE_CLUB_PLAYERS: '/clubs/:clubId/players',
    ROUTE_CLUB_PLAYERS_CREATE: '/clubs/:clubId/players/create',
    ROUTE_CLUB_PLAYERS_EDIT: '/clubs/:clubId/players/:playerId/edit',

    ROUTE_TEAMS: '/teams',
    ROUTE_TEAMS_CREATE: '/teams/create',
    ROUTE_TEAMS_READ: '/teams/:teamId',
    ROUTE_TEAMS_EDIT: '/teams/:teamId/edit',

    ROUTE_TEAM_CLUB: '/teams/:teamId/club',
    ROUTE_TEAM_COMPETITIONS: '/teams/:teamId/competitions',
    ROUTE_TEAM_MATCHES: '/teams/:teamId/matches',
    ROUTE_TEAM_COACHES: '/teams/:teamId/coaches',
    ROUTE_TEAM_COACHES_CREATE: '/teams/:teamId/coaches/create',
    ROUTE_TEAM_COACHES_EDIT: '/teams/:teamId/coaches/:coachId/edit',
    ROUTE_TEAM_PLAYERS: '/teams/:teamId/players',
    ROUTE_TEAM_PLAYERS_CREATE: '/teams/:teamId/players/create',
    ROUTE_TEAM_PLAYERS_EDIT: '/teams/:teamId/players/:playerId/edit',

    ROUTE_COMPETITIONS: '/competitions',
    ROUTE_COMPETITIONS_CREATE: '/competitions/create',
    ROUTE_COMPETITIONS_READ: '/competitions/:competitionId',
    ROUTE_COMPETITIONS_EDIT: '/competitions/:competitionId/edit',

    ROUTE_COMPETITION_TEAMS: '/competitions/:competitionId/teams',
    ROUTE_COMPETITION_TEAMS_CREATE: '/competitions/:competitionId/teams/create',
    ROUTE_COMPETITION_TEAMS_EDIT: '/competitions/:competitionId/teams/:teamId/edit',
    ROUTE_COMPETITION_MATCHES: '/competitions/:competitionId/matches',
    ROUTE_COMPETITION_MATCHES_CREATE: '/competitions/:competitionId/matches/create',
    ROUTE_COMPETITION_STANDINGS: '/competitions/:competitionId/standings',

    ROUTE_MATCHES: '/matches',
    ROUTE_MATCHES_CREATE: '/matches/create',
    ROUTE_MATCHES_READ: '/matches/:matchId',
    ROUTE_MATCHES_EDIT: '/matches/:matchId/edit',

    ROUTE_MATCH_LINEUPS: '/matches/:matchId/lineups',
    ROUTE_MATCH_LINEUPS_EDIT: '/matches/:matchId/lineups/edit',

    ROUTE_MATCH_OFFICIALS: '/matches/:matchId/officials',
    ROUTE_MATCH_OFFICIALS_CREATE: '/matches/:matchId/officials/create',
    ROUTE_MATCH_OFFICIALS_EDIT: '/matches/:matchId/officials/:officialId/edit',

    ROUTE_OFFICIALS: '/officials',
    ROUTE_OFFICIALS_CREATE: '/officials/create',
    ROUTE_OFFICIALS_READ: '/officials/:officialId',
    ROUTE_OFFICIALS_EDIT: '/officials/:officialId/edit',

    ROUTE_OFFICIAL_PERSONAL_INFO: '/officials/:officialId/personal-info',
    ROUTE_OFFICIAL_PERSONAL_INFO_EDIT: '/officials/:officialId/personal-info/edit',
    ROUTE_OFFICIAL_ROLES: '/officials/:officialId/roles',
    ROUTE_OFFICIAL_ROLES_CREATE: '/officials/:officialId/roles/create',
    ROUTE_OFFICIAL_ROLES_EDIT: '/officials/:officialId/roles/:officialRoleId/edit',
    ROUTE_OFFICIAL_MATCHES: '/officials/:officialId/matches',

    ROUTE_COACHES: '/coaches',
    ROUTE_COACHES_CREATE: '/coaches/create',
    ROUTE_COACHES_READ: '/coaches/:coachId',
    ROUTE_COACHES_EDIT: '/coaches/:coachId/edit',

    ROUTE_COACH_PERSONAL_INFO: '/coaches/:coachId/personal-info',
    ROUTE_COACH_PERSONAL_INFO_EDIT: '/coaches/:coachId/personal-info/edit',
    ROUTE_COACH_CLUBS: '/coaches/:coachId/clubs',
    ROUTE_COACH_CLUBS_CREATE: '/coaches/:coachId/clubs/create',
    ROUTE_COACH_TEAMS: '/coaches/:coachId/teams',
    ROUTE_COACH_TEAMS_CREATE: '/coaches/:coachId/teams/create',
    ROUTE_COACH_MATCHES: '/coaches/:coachId/matches',
    ROUTE_COACH_MATCHES_CREATE: '/coaches/:coachId/matches/create',

    ROUTE_PLAYERS: '/players',
    ROUTE_PLAYERS_CREATE: '/players/create',
    ROUTE_PLAYERS_READ: '/players/:playerId',
    ROUTE_PLAYERS_EDIT: '/players/:playerId/edit',

    ROUTE_PLAYER_PERSONAL_INFO: '/players/:playerId/personal-info',
    ROUTE_PLAYER_PERSONAL_INFO_EDIT: '/players/:playerId/personal-info/edit',
    ROUTE_PLAYER_CLUBS: '/players/:playerId/clubs',
    ROUTE_PLAYER_CLUBS_CREATE: '/players/:playerId/clubs/create',
    ROUTE_PLAYER_TEAMS: '/players/:playerId/teams',
    ROUTE_PLAYER_TEAMS_CREATE: '/players/:playerId/teams/create',
    ROUTE_PLAYER_MATCHES: '/players/:playerId/matches',
    ROUTE_PLAYER_MATCHES_CREATE: '/players/:playerId/matches/create',

    ROUTE_MATCH_LOG: '/match-log/:matchId',
    ROUTE_MATCH_REPORT: '/match-report/:matchId',

    ROUTE_USERS: '/users',
    ROUTE_USERS_CREATE: '/users/create',
    ROUTE_USERS_READ: '/users/:userId',
    ROUTE_USERS_EDIT: '/users/:userId/edit',

    ROUTE_CITIES: '/cities',
    ROUTE_CITIES_READ: '/cities/:cityId',
    ROUTE_CITIES_CREATE: '/cities/create',
    ROUTE_CITIES_EDIT: '/cities/:cityId/edit',

    ROUTE_NOT_FOUND: '/not-found',

    ROUTE_SOCKET: '/socket',
};

export default routes;
