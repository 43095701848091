import { UploadOutlined } from '@ant-design/icons';
import {
    Button,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Radio,
    Row,
    Select,
    Upload,
} from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import moment from 'moment';
import React from 'react';
import AutocompleteDropdown from '../../../components/autocomplete-dropdown';
import DebounceSelect from '../../../components/debounce-select/DebounceSelect';
import DrawerButtons from '../../../components/drawer-buttons/index';
import PersonClubs from '../../../components/person-clubs';
import PersonTeams from '../../../components/person-teams';
import { DropdownOption } from '../../../core/models/DropdownOption';
import { DrawerState, ModuleName, ProfileOption } from '../../../core/models/enum';
import { profileOptionButtons, ProfileOptionsModel } from '../../../core/models/FormProfileOptions';
import {
    confirmDropdownHelper,
    filterDropdownOptions,
    getCitiesHelper,
    getCountriesHelper,
} from '../../../helpers/AutocompleteDropdownHelper';
import {
    removePlayerClubFromListHelper,
    removePlayerTeamFromListHelper,
    updatePlayerClubsHelper,
    updatePlayerTeamsHelper,
} from '../../../helpers/CoachPlayerListHelper';
import { convertUTCTimeToLocal } from '../../../helpers/DateHelper';
import {
    getClubsToDropdownOptions,
    getPlayerPositions,
    getTeamsToDropdownOptions,
} from '../../../helpers/FetchAndTransformHelpers';
import { onUploadFileChangeHelper } from '../../../helpers/FileHelper';
import { formItemLayout024, formItemLayout724 } from '../../../helpers/FormLayoutHelper';
import { success } from '../../../helpers/NotificationHelper';
import { playerSubmitHelper } from '../../../helpers/PlayerSubmitHelper';
import { personToPersonOption, renderPersonSuggestions } from '../../../helpers/TransformHelper';
import {
    customStringValidationHelper,
} from '../../../helpers/ValidationHelper';
import {
    CreateClubPlayerCommandOfClubPlayer,
    CreateTeamPlayerCommandOfTeamPlayer,
    PersonsClient,
    PersonVm,
    PlayerVm,
} from '../../../utils/api';
import { Props } from './index';
import './styles.css';

const { Option } = Select;

interface State {
    isSaving: boolean;
    countries: DropdownOption[];
    cities: DropdownOption[];
    primaryPositions: DropdownOption[];
    clubs: DropdownOption[];
    teams: DropdownOption[];
    selectedCountry?: DropdownOption[];
    selectedCity?: DropdownOption[];
    selectedPrimaryPosition?: DropdownOption[];
    selectedTeamPosition?: DropdownOption[];
    selectedPlayerTeams?: CreateTeamPlayerCommandOfTeamPlayer[];
    selectedPlayerClubs?: CreateClubPlayerCommandOfClubPlayer[];
    selectedPersonOption?: PersonOption;
    selectedProfileOption?: string;
    selectedClub?: DropdownOption[];
    selectedTeam?: DropdownOption[];
    file?: any;
    fileBase64?: string;
    personSuggestions?: PersonOption[];
}

export interface PersonOption {
    label: string;
    value: number;
    person: PersonVm;
}

class PlayerForm extends React.PureComponent<Props, State> {
    private formRef: any;

    public constructor(props: Props) {
        super(props);

        const { player } = props;

        this.formRef = React.createRef();

        this.state = {
            isSaving: false,
            countries: [],
            cities: [],
            primaryPositions: [],
            clubs: [],
            teams: [],
            fileBase64: player?.person.photo ?? undefined,
        };
    }

    public componentDidMount = (): void => {
        const { player } = this.props;

        this.getCountries();

        if (!player?.person?.countryOfBirthId) {
            this.getCities();
        }

        this.getPrimaryPositions();
        this.getClubs();
        this.getTeams();
    };

    private getCountries = async (): Promise<void> => {
        const { player } = this.props;
        const countryId = player && player.person.countryOfBirthId;

        const countryHelper = await getCountriesHelper(countryId);

        if (countryId) {
            this.getCities(countryId);
        }

        this.setState({
            countries: countryHelper.entities,
            selectedCountry: countryHelper.selectedEntity,
        });
    };

    private getCities = async (countryId?: number): Promise<void> => {
        const { player } = this.props;
        const cityId = player && player.person.cityOfBirthId;

        const cityHelper = await getCitiesHelper(countryId, cityId);

        this.setState({
            cities: cityHelper.entities,
            selectedCity: cityHelper.selectedEntity,
        });
    };

    private getPrimaryPositions = async (): Promise<void> => {
        const { player, teamPlayer } = this.props;

        const results = await getPlayerPositions(player?.primaryPositionId, teamPlayer?.positionId);

        this.setState({
            primaryPositions: results.entities,
            selectedPrimaryPosition: results.selectedEntity,
            selectedTeamPosition: results.selectedSecondEntity,
        });
    };

    private getClubs = async (): Promise<void> => {
        const { initialValues } = this.props;

        const results = await getClubsToDropdownOptions(initialValues?.clubId);

        this.setState({
            clubs: results.entities,
            selectedClub: results.selectedEntity,
        });
    };

    private getTeams = async (): Promise<void> => {
        const { initialValues } = this.props;

        const results = await getTeamsToDropdownOptions(initialValues?.teamId);

        this.setState({
            teams: results.entities,
            selectedTeam: results.selectedEntity,
        });
    };

    private getPersonOptions = async (input: string): Promise<PersonOption[]> => {
        const { initialValues } = this.props;

        const persons = await new PersonsClient().searchAll(input);

        const personOptions = persons
            .filter(
                (person: PersonVm) =>
                    // filtering out players that are already playing for the team/club in context
                    !initialValues?.players?.some(
                        (player: PlayerVm): boolean => player.id === person.playerId
                    )
            )
            .map(personToPersonOption);

        return personOptions;
    };

    private getPersonSuggestionOptions = async (): Promise<void> => {
        const { name, surname } = this.formRef.current.getFieldsValue(['name', 'surname']);

        if (!name || !surname) {
            return;
        }

        const personSuggestions = await this.getPersonOptions(`${name} ${surname}`);

        this.setState({
            personSuggestions,
        });
    };

    private handlePersonSelect = async (option: PersonOption): Promise<void> => {
        const { primaryPositions } = this.state;
        const person = option.person;

        const selectedPrimaryPosition = primaryPositions.find(
            (position: DropdownOption) => parseInt(position.id) === person.player?.primaryPositionId
        );

        this.setState({
            selectedPersonOption: option,
            selectedPrimaryPosition: selectedPrimaryPosition ? [selectedPrimaryPosition] : [],
        });

        if (person.player) {
            // for existing players we manually set his player information in form values
            this.formRef.current.setFieldsValue({
                dominantHand: person.player.dominantHand,
                height: person.player.height,
                weight: person.player.weight,
            });
        }
    };

    private confirmCountry = (option: DropdownOption[]): void => {
        const selectedCountry = confirmDropdownHelper(option);
        this.setState({
            selectedCountry,
            selectedCity: undefined,
        });
        if (selectedCountry) {
            this.getCities(parseInt(selectedCountry[0].id, 10));
        }
    };

    private onUploadFileChange = async (file: File): Promise<void> => {
        const uploadHelper = await onUploadFileChangeHelper(file);

        if (uploadHelper) {
            this.setState({
                file: uploadHelper.file,
                fileBase64: uploadHelper.fileBase64,
            });
        }
    };

    private updatePlayerTeams = (team: CreateTeamPlayerCommandOfTeamPlayer): void => {
        const { selectedPlayerTeams } = this.state;

        this.setState({
            selectedPlayerTeams: updatePlayerTeamsHelper(team, selectedPlayerTeams),
        });
    };

    private removePlayerTeamFromList = (team: CreateTeamPlayerCommandOfTeamPlayer): void => {
        const { selectedPlayerTeams } = this.state;

        const newSelectedTeams = removePlayerTeamFromListHelper(team, selectedPlayerTeams);

        if (newSelectedTeams) {
            this.setState({ selectedPlayerTeams: newSelectedTeams });
        }
    };

    private updatePlayerClubs = (club: CreateClubPlayerCommandOfClubPlayer): void => {
        const { selectedPlayerClubs } = this.state;

        this.setState({
            selectedPlayerClubs: updatePlayerClubsHelper(club, selectedPlayerClubs),
        });
    };

    private removePlayerClubFromList = (club: CreateClubPlayerCommandOfClubPlayer): void => {
        const { selectedPlayerClubs } = this.state;

        const newSelectedClubs = removePlayerClubFromListHelper(club, selectedPlayerClubs);

        if (newSelectedClubs) {
            this.setState({ selectedPlayerClubs: newSelectedClubs });
        }
    };

    private onSubmit = (values: any): void => {
        const {
            selectedCountry,
            selectedCity,
            selectedPrimaryPosition,
            selectedProfileOption,
            selectedClub,
            selectedTeam,
            selectedPlayerTeams,
            selectedPlayerClubs,
            selectedTeamPosition,
            selectedPersonOption,
            fileBase64,
        } = this.state;
        const { player, clubPlayer, teamPlayer, initialValues } = this.props;

        this.setState({
            isSaving: true,
        });

        playerSubmitHelper(
            this.handleSubmitSuccess,
            values,
            selectedCountry,
            selectedCity,
            selectedProfileOption,
            selectedPersonOption?.person,
            fileBase64,
            selectedPrimaryPosition,
            selectedPlayerTeams,
            selectedPlayerClubs,
            selectedClub,
            selectedTeam,
            selectedTeamPosition,
            player,
            initialValues,
            clubPlayer,
            teamPlayer
        );

        this.setState({
            isSaving: false,
        });
    };

    private handleSubmitSuccess = (): void => {
        const { player, handleClose, refreshAfterSave } = this.props;

        success('Player successfully saved.');

        refreshAfterSave();

        handleClose(
            player ? DrawerState.Read : DrawerState.Closed,
            player ? player.id.toString() : undefined
        );
    };

    public render(): React.ReactElement {
        const {
            isSaving,
            selectedCountry,
            selectedCity,
            selectedPrimaryPosition,
            selectedProfileOption,
            selectedPersonOption,
            selectedTeam,
            selectedTeamPosition,
            selectedPlayerClubs,
            selectedPlayerTeams,
            file,
            fileBase64,
            countries,
            cities,
            primaryPositions,
            personSuggestions,
            clubs,
            selectedClub,
            teams,
        } = this.state;
        const { handleClose, player, clubPlayer, teamPlayer, initialValues } = this.props;

        return (
            <Form onFinish={this.onSubmit} {...formItemLayout724} ref={this.formRef}>
                <DrawerButtons
                    isSaving={isSaving}
                    onCancelAction={(): void =>
                        handleClose(
                            player ? DrawerState.Read : DrawerState.Closed,
                            player ? player.id.toString() : undefined
                        )
                    }
                />

                <div className="drawer-content">
                    {/* PROFILE */}
                    {!player && !clubPlayer && !teamPlayer && (
                        <>
                            <div className="drawer-section-title">
                                <h3>Profile</h3>
                            </div>
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        validator: (_rule: any, value: any, callback: any): void =>
                                            customStringValidationHelper(
                                                _rule,
                                                value,
                                                callback,
                                                'Profile type is required',
                                                selectedProfileOption
                                            ),
                                    },
                                ]}
                                {...formItemLayout024}
                            >
                                {profileOptionButtons.map(
                                    (opt: ProfileOptionsModel): React.ReactElement => (
                                        <Radio.Button
                                            key={opt.value}
                                            value={opt.value}
                                            checked={selectedProfileOption === opt.value}
                                            onChange={(e: RadioChangeEvent): void =>
                                                this.setState({
                                                    selectedProfileOption: e.target.value,
                                                })
                                            }
                                            style={{
                                                width: 130,
                                                textAlign: 'center',
                                            }}
                                        >
                                            {opt.title}
                                        </Radio.Button>
                                    )
                                )}
                            </Form.Item>

                            {/* EXISTING PROFILE FIELDS */}
                            {selectedProfileOption === ProfileOption.Existing && (
                                <div style={{ width: '100%', float: 'left' }}>
                                    <Form.Item
                                        name="person"
                                        label="Profile"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Profile field is required',
                                            },
                                        ]}
                                    >
                                        <DebounceSelect
                                            value={selectedPersonOption}
                                            placeholder="Search profiles"
                                            fetchOptions={this.getPersonOptions}
                                            onChange={(_, option) =>
                                                this.handlePersonSelect(option as PersonOption)
                                            }
                                            style={{ width: '100%' }}
                                            minInputLength={3}
                                        />
                                    </Form.Item>
                                </div>
                            )}

                            {/* NEW PROFILE FIELDS*/}
                            {selectedProfileOption === ProfileOption.New && (
                                <div style={{ width: '100%', float: 'left' }}>
                                    <Form.Item
                                        name="name"
                                        label="Name"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Name field is required!',
                                            },
                                            {
                                                max: 200,
                                                message: 'Too long input!',
                                            },
                                        ]}
                                    >
                                        <Input onBlur={this.getPersonSuggestionOptions} />
                                    </Form.Item>
                                    <Form.Item
                                        name="surname"
                                        label="Surname"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Surname field is required!',
                                            },
                                            {
                                                max: 200,
                                                message: 'Too long input!',
                                            },
                                        ]}
                                    >
                                        <Input onBlur={this.getPersonSuggestionOptions} />
                                    </Form.Item>

                                    {!!personSuggestions?.length && (
                                        <Form.Item className="person-suggestion" label=" ">
                                            <span>
                                                Profiles with this name already exist. Click on the
                                                profile below to assign it a player identity.
                                            </span>
                                            {renderPersonSuggestions(
                                                personSuggestions,
                                                (option: PersonOption) => {
                                                    this.setState({
                                                        selectedProfileOption:
                                                            ProfileOption.Existing,
                                                    });
                                                    this.formRef.current.setFieldsValue({
                                                        person: option,
                                                    });

                                                    this.handlePersonSelect(option);
                                                }
                                            )}
                                        </Form.Item>
                                    )}

                                    <Form.Item
                                        name="address"
                                        label="Address"
                                        rules={[
                                            {
                                                max: 200,
                                                message: 'Too long input!',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        name="nationality"
                                        label="Nationality"
                                        rules={[
                                            {
                                                max: 200,
                                                message: 'Too long input!',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item name="countryOfBirthId" label="Country of birth">
                                        <AutocompleteDropdown
                                            placeholder="Choose country"
                                            getOptionsFrontend={(value: string): DropdownOption[] =>
                                                filterDropdownOptions(value, countries)
                                            }
                                            initialValues={selectedCountry}
                                            confirmDirty={this.confirmCountry}
                                            style={{ width: '100%' }}
                                            isAllowedToClear
                                        />
                                    </Form.Item>
                                    <Form.Item name="cityOfBirthId" label="City of birth">
                                        <AutocompleteDropdown
                                            placeholder="Choose city"
                                            getOptionsFrontend={(value: string): DropdownOption[] =>
                                                filterDropdownOptions(value, cities)
                                            }
                                            initialValues={selectedCity}
                                            confirmDirty={(options: DropdownOption[]): void =>
                                                this.setState({
                                                    selectedCity: confirmDropdownHelper(options),
                                                })
                                            }
                                            style={{ width: '100%' }}
                                            isAllowedToClear
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="birthDate"
                                        label="Birth date"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Birth date field is required!',
                                            },
                                        ]}
                                    >
                                        <DatePicker
                                            format="DD.MM.YYYY."
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="phone"
                                        label="Phone"
                                        rules={[
                                            {
                                                // eslint-disable-next-line
                                                pattern: /^[0-9(\)\+]+$/,
                                                message: 'Only numbers and plus sign are allowed!',
                                            },
                                            {
                                                max: 200,
                                                message: 'Too long input!',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        name="email"
                                        label="Email"
                                        rules={[
                                            {
                                                type: 'email',
                                                message: 'Wrong e-mail format!',
                                            },
                                            {
                                                max: 200,
                                                message: 'Too long input!',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        name="facebook"
                                        label="Facebook"
                                        rules={[
                                            {
                                                max: 200,
                                                message: 'Too long input!',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        name="twitter"
                                        label="Twitter"
                                        rules={[
                                            {
                                                max: 200,
                                                message: 'Too long input!',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        name="youtube"
                                        label="Youtube"
                                        rules={[
                                            {
                                                max: 200,
                                                message: 'Too long input!',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        name="instagram"
                                        label="Instagram"
                                        rules={[
                                            {
                                                max: 200,
                                                message: 'Too long input!',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item label="Photo">
                                        {fileBase64 && (
                                            <Row>
                                                <Col span="24">
                                                    <img
                                                        height={55}
                                                        src={`data:image/jpeg;base64,${fileBase64}`}
                                                        alt="logo"
                                                    />
                                                </Col>
                                                <Col span="24">
                                                    <Button
                                                        type="primary"
                                                        danger
                                                        style={{
                                                            marginTop: 10,
                                                        }}
                                                        onClick={(): void =>
                                                            this.setState({
                                                                file: undefined,
                                                                fileBase64: undefined,
                                                            })
                                                        }
                                                    >
                                                        Remove logo
                                                    </Button>
                                                </Col>
                                            </Row>
                                        )}
                                        {!fileBase64 && (
                                            <Upload
                                                accept="image/png"
                                                style={{ zIndex: 10 }}
                                                beforeUpload={(file) => {
                                                    this.onUploadFileChange(file);
                                                    return false;
                                                }}
                                                onRemove={(): void =>
                                                    this.setState({
                                                        file: undefined,
                                                        fileBase64: undefined,
                                                    })
                                                }
                                                fileList={file ? [{ ...file }] : []}
                                            >
                                                <Button type="primary" disabled={!!file}>
                                                    <UploadOutlined className="button-icon-display" />
                                                    Upload image
                                                </Button>
                                            </Upload>
                                        )}
                                    </Form.Item>
                                </div>
                            )}
                        </>
                    )}

                    {/* PLAYER INFORMATION */}
                    {!clubPlayer && !teamPlayer && (
                        <>
                            {!player && (
                                <div className="drawer-section-title">
                                    <h3>Player information</h3>
                                </div>
                            )}
                            <Form.Item
                                name="dominantHand"
                                label="Dominant hand"
                                initialValue={player?.dominantHand}
                            >
                                <Select
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Choose dominant hand"
                                    disabled={
                                        selectedProfileOption === ProfileOption.Existing &&
                                        !!selectedPersonOption?.person.player
                                    }
                                >
                                    <Option key="L" value="L">
                                        L
                                    </Option>
                                    <Option key="R" value="R">
                                        R
                                    </Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="weight"
                                label="Weight (kg)"
                                initialValue={player ? player.weight : undefined}
                                rules={[{ max: 200, message: 'Too long input!' }]}
                            >
                                <Input
                                    disabled={
                                        selectedProfileOption === ProfileOption.Existing &&
                                        !!selectedPersonOption?.person.player
                                    }
                                />
                            </Form.Item>
                            <Form.Item
                                name="height"
                                label="Height (cm)"
                                initialValue={player ? player.height : undefined}
                                rules={[{ max: 200, message: 'Too long input!' }]}
                            >
                                <Input
                                    disabled={
                                        selectedProfileOption === ProfileOption.Existing &&
                                        !!selectedPersonOption?.person.player
                                    }
                                />
                            </Form.Item>
                            <Form.Item label="Primary position">
                                <AutocompleteDropdown
                                    placeholder="Choose primary position"
                                    getOptionsFrontend={(value: string): DropdownOption[] =>
                                        filterDropdownOptions(value, primaryPositions)
                                    }
                                    initialValues={selectedPrimaryPosition}
                                    confirmDirty={(options: DropdownOption[]): void =>
                                        this.setState({
                                            selectedPrimaryPosition: confirmDropdownHelper(options),
                                        })
                                    }
                                    style={{ width: '100%' }}
                                    isAllowedToClear
                                    disabled={
                                        selectedProfileOption === ProfileOption.Existing &&
                                        !!selectedPersonOption?.person.player
                                    }
                                />
                            </Form.Item>
                        </>
                    )}

                    {!initialValues?.teamId && !initialValues?.clubId && !player && !clubPlayer && (
                        <div
                            style={{
                                marginBottom:
                                    selectedPlayerClubs && selectedPlayerClubs.length > 0 ? 20 : 70,
                            }}
                        >
                            <div className="drawer-section-title">
                                <h3>Clubs</h3>
                            </div>
                            <Form.Item
                                name="clubs"
                                rules={[
                                    {
                                        validator: async () => {
                                            if (
                                                (!selectedPlayerClubs ||
                                                    selectedPlayerClubs.length < 1) &&
                                                (!selectedPlayerTeams ||
                                                    selectedPlayerTeams.length < 1)
                                            ) {
                                                return Promise.reject(
                                                    new Error('Club or Team field is required')
                                                );
                                            }
                                        },
                                    },
                                ]}
                                {...formItemLayout024}
                            >
                                <PersonClubs
                                    entityModule={ModuleName.Players}
                                    initialClubId={initialValues && initialValues.clubId}
                                    savePlayerChanges={this.updatePlayerClubs}
                                    selectedPlayerClubs={selectedPlayerClubs}
                                    removePlayerClubFromList={this.removePlayerClubFromList}
                                />
                            </Form.Item>
                        </div>
                    )}

                    {!initialValues?.clubId && !initialValues?.teamId && !player && !teamPlayer && (
                        <>
                            <div className="drawer-section-title">
                                <h3>Teams</h3>
                            </div>

                            <Form.Item
                                name="clubs"
                                rules={[
                                    {
                                        validator: async () => {
                                            if (
                                                (!selectedPlayerClubs ||
                                                    selectedPlayerClubs.length < 1) &&
                                                (!selectedPlayerTeams ||
                                                    selectedPlayerTeams.length < 1)
                                            ) {
                                                return Promise.reject(
                                                    new Error('Club or Team field is required')
                                                );
                                            }
                                        },
                                    },
                                ]}
                                {...formItemLayout024}
                            >
                                <PersonTeams
                                    entityModule={ModuleName.Players}
                                    initialTeamId={initialValues && initialValues.teamId}
                                    savePlayerChanges={this.updatePlayerTeams}
                                    selectedPlayerTeams={selectedPlayerTeams}
                                    removePlayerTeamFromList={this.removePlayerTeamFromList}
                                />
                            </Form.Item>
                        </>
                    )}

                    {((initialValues?.clubId && !player) || clubPlayer) && (
                        <>
                            {!clubPlayer && (
                                <div className="drawer-section-title">
                                    <h3>Club</h3>
                                </div>
                            )}

                            <Form.Item name="clubId" label="Club">
                                <AutocompleteDropdown
                                    placeholder="Choose club"
                                    getOptionsFrontend={(value: string): DropdownOption[] =>
                                        filterDropdownOptions(value, clubs)
                                    }
                                    initialValues={selectedClub}
                                    confirmDirty={(options: DropdownOption[]): void =>
                                        this.setState({
                                            selectedClub: confirmDropdownHelper(options),
                                        })
                                    }
                                    style={{ width: '100%' }}
                                    isAllowedToClear
                                    disabled={!!(initialValues && initialValues.clubId)}
                                />
                            </Form.Item>
                            <Form.Item
                                name="activeFromClub"
                                label="Active from"
                                initialValue={
                                    clubPlayer?.activeFrom
                                        ? moment(convertUTCTimeToLocal(clubPlayer.activeFrom))
                                        : moment(convertUTCTimeToLocal(new Date()))
                                }
                            >
                                <DatePicker
                                    format="DD.MM.YYYY."
                                    style={{ width: '100%' }}
                                    allowClear={false}
                                />
                            </Form.Item>
                            <Form.Item
                                name="activeToClub"
                                label="Active to"
                                initialValue={
                                    clubPlayer?.activeTo
                                        ? moment(convertUTCTimeToLocal(clubPlayer.activeTo))
                                        : undefined
                                }
                            >
                                <DatePicker format="DD.MM.YYYY." style={{ width: '100%' }} />
                            </Form.Item>
                        </>
                    )}

                    {initialValues?.teamId && !player && !clubPlayer && (
                        <>
                            {!teamPlayer && (
                                <div className="drawer-section-title">
                                    <h3>Team</h3>
                                </div>
                            )}

                            <Form.Item name="teamId" label="Team">
                                <AutocompleteDropdown
                                    placeholder="Choose team"
                                    getOptionsFrontend={(value: string): DropdownOption[] =>
                                        filterDropdownOptions(value, teams)
                                    }
                                    initialValues={selectedTeam}
                                    confirmDirty={(options: DropdownOption[]): void =>
                                        this.setState({
                                            selectedTeam: confirmDropdownHelper(options),
                                        })
                                    }
                                    style={{ width: '100%' }}
                                    isAllowedToClear
                                    disabled={!!(initialValues && initialValues.teamId)}
                                />
                            </Form.Item>
                            <Form.Item name="teamPositionId" label="Position">
                                <AutocompleteDropdown
                                    placeholder="Choose position"
                                    getOptionsFrontend={(value: string): DropdownOption[] =>
                                        filterDropdownOptions(value, primaryPositions)
                                    }
                                    initialValues={selectedTeamPosition}
                                    confirmDirty={(options: DropdownOption[]): void =>
                                        this.setState({
                                            selectedTeamPosition: confirmDropdownHelper(options),
                                        })
                                    }
                                    style={{ width: '100%' }}
                                    isAllowedToClear
                                />
                            </Form.Item>
                            <Form.Item
                                name="capNumber"
                                label="Cap number"
                                initialValue={teamPlayer?.capNumber}
                            >
                                <InputNumber min={1} max={99} />
                            </Form.Item>
                            <Form.Item
                                name="activeFromTeam"
                                label="Active from"
                                initialValue={
                                    teamPlayer?.activeFrom
                                        ? moment(convertUTCTimeToLocal(teamPlayer.activeFrom))
                                        : moment(convertUTCTimeToLocal(new Date()))
                                }
                            >
                                <DatePicker
                                    format="DD.MM.YYYY."
                                    style={{ width: '100%' }}
                                    allowClear={false}
                                />
                            </Form.Item>
                            <Form.Item
                                name="activeToTeam"
                                label="Active to"
                                initialValue={
                                    teamPlayer?.activeTo
                                        ? moment(convertUTCTimeToLocal(teamPlayer.activeTo))
                                        : undefined
                                }
                            >
                                <DatePicker format="DD.MM.YYYY." style={{ width: '100%' }} />
                            </Form.Item>
                        </>
                    )}
                </div>
            </Form>
        );
    }
}

export default PlayerForm;
