import {
    CreateMatchPlayerCommandOfMatchPlayer,
    MatchCoachVm,
    UpdateEventCommand,
} from '../../../../utils/api';
import MatchLogItem from './LogItem';

interface OwnProps {
    event: UpdateEventCommand;
    lineups: CreateMatchPlayerCommandOfMatchPlayer[];
    lineup?: CreateMatchPlayerCommandOfMatchPlayer;
    isHomeTeam: boolean;
    coaches: MatchCoachVm[];
    editEvent: (event: UpdateEventCommand) => void;
}

export type Props = OwnProps;

export default MatchLogItem;
