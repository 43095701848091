import { Button, Col, Radio, Row, Switch } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import React from 'react';
import { Props } from '.';
import CapSelector from '../../../../components/cap-selector/CapSelector';
import Icon from '../../../../components/icon/Icon';
import ShotLocation from '../../../../components/shot-location/ShotLocation';
import { getTeamId } from '../../../../helpers/EventHelper';
import { CapNumber } from '../../../../core/models/CapNumber';
import {
    CreateMatchPlayerCommandOfMatchPlayer,
    EventVm,
    ExclusionRequest,
    ExclusionTypeEnum,
    UpdateEventCommand,
} from '../../../../utils/api';

class Exclusion extends React.PureComponent<Props> {
    public handleFouledPlayerSelect = (cap: number): void => {
        const { event, updateEvent, lineups } = this.props;

        const eventVm = event as EventVm;

        const otherTeamLineups = lineups.filter(
            (l: CreateMatchPlayerCommandOfMatchPlayer): boolean => l.teamId !== getTeamId(event)
        );
        const lineup = otherTeamLineups.find(
            (l: CreateMatchPlayerCommandOfMatchPlayer): boolean => l.number === cap
        );
        const playerId = lineup?.id;

        const updatedEvent = new UpdateEventCommand({
            ...event,
            exclusion: event.exclusion
                ? new ExclusionRequest({
                    ...event.exclusion,
                    fouledPlayerId: playerId,
                    id: eventVm.exclusionId || 0,
                })
                : undefined,
        });

        updatedEvent.id = event.id;

        updateEvent(updatedEvent);
    };

    public handleFouledPlayerClear = (): void => {
        const { event, updateEvent } = this.props;

        const eventVm = event as EventVm;

        const updatedEvent = new UpdateEventCommand({
            ...event,
            exclusion: event.exclusion
                ? new ExclusionRequest({
                    ...event.exclusion,
                    fouledPlayerId: undefined,
                    isDoubleExclusion: false,
                    id: eventVm.exclusionId || 0,
                })
                : undefined,
        });

        updatedEvent.id = event.id;

        updateEvent(updatedEvent);
    };

    public handleSuspensionTypeSelect = (exclusionType: ExclusionTypeEnum): void => {
        const { event, updateEvent } = this.props;

        const eventVm = event as EventVm;

        const updatedEvent = new UpdateEventCommand({
            ...event,
            exclusion: event.exclusion
                ? new ExclusionRequest({
                    ...event.exclusion,
                    typeId: exclusionType,
                    isPenaltyExclusion:
                        exclusionType === ExclusionTypeEnum.Penalty
                            ? event.exclusion.isPenaltyExclusion
                            : false,
                    id: eventVm.exclusionId || 0,
                })
                : undefined,
        });

        updatedEvent.id = event.id;

        updateEvent(updatedEvent);
    };

    public toggleDoubleExclusion = (checked: boolean): void => {
        const { event, updateEvent } = this.props;

        const eventVm = event as EventVm;

        const updatedEvent = new UpdateEventCommand({
            ...event,
            exclusion: event.exclusion
                ? new ExclusionRequest({
                    ...event.exclusion,
                    isDoubleExclusion: checked,
                    id: eventVm.exclusionId || 0,
                })
                : undefined,
        });

        updatedEvent.id = event.id;

        updateEvent(updatedEvent);
    };

    public togglePenaltyExclusion = (checked: boolean): void => {
        const { event, updateEvent } = this.props;

        const eventVm = event as EventVm;

        const updatedEvent = new UpdateEventCommand({
            ...event,
            exclusion: event.exclusion
                ? new ExclusionRequest({
                    ...event.exclusion,
                    isPenaltyExclusion: checked,
                    id: eventVm.exclusionId || 0,
                })
                : undefined,
        });

        updatedEvent.id = event.id;

        updateEvent(updatedEvent);
    };

    public handleExclusionLocationSelect = (x: number, y: number): void => {
        const { event, updateEvent } = this.props;

        const eventVm = event as EventVm;

        const updatedEvent = new UpdateEventCommand({
            ...event,
            exclusion: event.exclusion
                ? new ExclusionRequest({
                    ...event.exclusion,
                    locationX: x,
                    locationY: y,
                    id: eventVm.exclusionId || 0,
                })
                : undefined,
        });

        updatedEvent.id = event.id;

        updateEvent(updatedEvent);
    };

    public render = (): React.ReactElement => {
        const { event, lineups, isHomeTeam } = this.props;
        const exclusionType = event.exclusion?.typeId;
        const isDoubleExclusion = event.exclusion?.isDoubleExclusion;
        const isPenaltyExclusion = event.exclusion?.isPenaltyExclusion;

        const fouledPlayerId = event.exclusion?.fouledPlayerId;
        const fouledPlayerLineup = lineups.find(
            (l: CreateMatchPlayerCommandOfMatchPlayer): boolean => l.id === fouledPlayerId
        );
        const otherTeamLineups = lineups.filter(
            (l: CreateMatchPlayerCommandOfMatchPlayer): boolean => l.teamId !== getTeamId(event)
        );

        const exclusionLocationX = event.exclusion?.locationX;
        const exclusionLocationY = event.exclusion?.locationY;

        const backgroundColor = isHomeTeam ? 'bg-white' : 'bg-blue';
        const fouledPlayerBackground = isHomeTeam ? 'bg-blue' : 'bg-white';

        return (
            <div className="event-create">
                <Row>
                    <Col span={24}>
                        <Radio.Group
                            className="exclusion-type-wrapper"
                            value={exclusionType}
                            buttonStyle="solid"
                            size="large"
                            onChange={(e: RadioChangeEvent): void =>
                                this.handleSuspensionTypeSelect(e.target.value)
                            }
                        >
                            <div className="exclusion-type-btn-wrapper">
                                <Radio.Button
                                    value={ExclusionTypeEnum.Exclusion}
                                    className={`btn-icon ${backgroundColor}`}
                                >
                                    <Icon name="icon-times" />
                                </Radio.Button>
                                <span className="exclusion-type-title">Exclusion</span>
                            </div>
                            <div className="exclusion-type-btn-wrapper">
                                <Radio.Button
                                    value={ExclusionTypeEnum.Penalty}
                                    className={`btn-icon ${backgroundColor}`}
                                >
                                    <span
                                        style={{
                                            color: '#000',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        5m
                                    </span>
                                </Radio.Button>
                                <span className="exclusion-type-title">Penalty</span>
                            </div>
                            <div className="exclusion-type-btn-wrapper">
                                <Radio.Button
                                    value={ExclusionTypeEnum.Suspension}
                                    className={`btn-icon ${backgroundColor}`}
                                >
                                    <Icon name="icon-ban" />
                                </Radio.Button>
                                <span className="exclusion-type-title">Suspension</span>
                            </div>
                            <div className="exclusion-type-btn-wrapper">
                                <Radio.Button
                                    value={ExclusionTypeEnum.Brutality}
                                    className={`btn-icon ${backgroundColor}`}
                                >
                                    <Icon name="icon-user-times" />
                                </Radio.Button>
                                <span className="exclusion-type-title">Brutality</span>
                            </div>
                            <div className="exclusion-type-btn-wrapper">
                                <div className="exclusion-type-switch">
                                    <Switch
                                        checked={isDoubleExclusion}
                                        onChange={(checked: boolean): void =>
                                            this.toggleDoubleExclusion(checked)
                                        }
                                        disabled={!fouledPlayerLineup}
                                        title={
                                            !fouledPlayerLineup
                                                ? 'Select fouled player to enable'
                                                : ''
                                        }
                                    />
                                </div>
                                <span className="exclusion-type-title">Double exclusion</span>
                            </div>
                            <div className="exclusion-type-btn-wrapper">
                                <div className="exclusion-type-switch">
                                    <Switch
                                        checked={isPenaltyExclusion}
                                        onChange={(checked: boolean): void =>
                                            this.togglePenaltyExclusion(checked)
                                        }
                                        disabled={exclusionType !== ExclusionTypeEnum.Penalty}
                                        title={
                                            exclusionType !== ExclusionTypeEnum.Penalty
                                                ? 'Select 5m to enable'
                                                : ''
                                        }
                                    />
                                </div>
                                <span className="exclusion-type-title">Penalty exclusion</span>
                            </div>
                        </Radio.Group>
                    </Col>
                </Row>
                <br />
                <Row className="match-form-lineup">
                    <div className="title">Fouled player:</div>
                    <CapSelector
                        capClass={fouledPlayerBackground}
                        onSelect={this.handleFouledPlayerSelect}
                        caps={otherTeamLineups.map(
                            (l: CreateMatchPlayerCommandOfMatchPlayer): CapNumber => ({ cap: l.number, capNumber: l.capNumber || l.number }),
                        )}
                        activeCap={fouledPlayerLineup ? fouledPlayerLineup.number : null}
                        activeCapNumber={fouledPlayerLineup ? fouledPlayerLineup.capNumber || fouledPlayerLineup.number : null}
                    />
                    {fouledPlayerLineup && (
                        <Button size="large" onClick={(): void => this.handleFouledPlayerClear()}>
                            Clear
                        </Button>
                    )}
                </Row>
                <br />
                <Row>
                    <ShotLocation
                        x={exclusionLocationX}
                        y={exclusionLocationY}
                        onSelect={this.handleExclusionLocationSelect}
                    />
                </Row>
            </div>
        );
    };
}

export default Exclusion;
