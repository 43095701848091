import { PlusOutlined, SyncOutlined } from '@ant-design/icons';
import { Button, Drawer, PageHeader, Tabs } from 'antd';
import React from 'react';
import PageHeaderTitle from '../../../components/page-header';
import { DrawerWidth } from '../../../config/dimensions';
import Routes from '../../../config/routes';
import ReplaceStrings from '../../../config/replaceStrings';
import { ActionOption, DrawerState, ModuleName } from '../../../core/models/enum';
import { isUserAllowed } from '../../../helpers/CheckPermissionHelper';
import { errorWithUserMessage, success } from '../../../helpers/NotificationHelper';
import { getNewRoute, getSubPageInitialProperties } from '../../../helpers/RoutingHelper';
import { transformCompetitionTeamsToTeams } from '../../../helpers/TransformHelper';
import {
    ApiException,
    CompetitionDetailVm,
    CompetitionsClient,
    CompetitionTeamsClient,
    CompetitionTeamVm,
    TeamVm,
} from '../../../utils/api';
import MatchForm from '../../matches/match-form';
import MatchTable from '../../matches/match-table';
import TeamTable from '../../teams/team-table';
import CompetitionTeamForm from '../competition-team';
import CompetitionDetails from './competition-details';
import { Props } from './index';
import { StandingsVm } from '../../../utils/api';
import StandingsTable from './standings-table';

const { TabPane } = Tabs;

interface State {
    competition?: CompetitionDetailVm;
    competitionStandings?: StandingsVm[];
    selectedCompetitionTeam?: CompetitionTeamVm;
    teams?: TeamVm[];
    activeTabKey: string;
    isEditMode: boolean;
    id?: string;
    drawerState: DrawerState;
    loading: boolean;
    isUserAllowedToCreateMatch: boolean;
    isUserAllowedToCreateTeam: boolean;
}

class CompetitionPage extends React.PureComponent<Props, State> {
    private teamsTableRef: any;
    private matchesTableRef: any;

    public constructor(props: Props) {
        super(props);

        const {
            location: { pathname },
        } = window;
        this.teamsTableRef = React.createRef();
        this.matchesTableRef = React.createRef();

        const initialProperties = getSubPageInitialProperties(
            pathname,
            'competitions',
            props.match,
            Routes.ROUTE_COMPETITIONS_READ,
            Routes.ROUTE_COMPETITIONS_EDIT
        );

        this.state = {
            activeTabKey: initialProperties.activeTabKey || Routes.ROUTE_COMPETITIONS_READ,
            id: initialProperties.initialEntityId,
            isEditMode: initialProperties.isInitialEditMode,
            drawerState:
                initialProperties.activeTabKey &&
                    initialProperties.activeTabKey !== Routes.ROUTE_COMPETITIONS_READ &&
                    initialProperties.isInitialEditMode
                    ? DrawerState.Edit
                    : DrawerState.Closed,
            loading: true,
            isUserAllowedToCreateMatch: false,
            isUserAllowedToCreateTeam: false,
        };
    }

    public componentDidMount = async (): Promise<void> => {
        this.getPermissions();
        this.getCompetitionDetails();
        await this.getStandings();
    };

    public componentDidUpdate = (prevProps: Props): void => {
        const {
            match: { path },
        } = this.props;

        if (path !== prevProps.match.path) {
            this.updateStateAfterRouteChanges();
        }
    };

    private getStandings = async (): Promise<void> => {
        const {
            match: {
                params: { competitionId },
            },
        } = this.props;

        const result = await new CompetitionsClient().getStandings(+competitionId)
        this.setState({
            competitionStandings: result
        })
    }

    private updateStandings = async () => {
        this.setState({
            loading: true
        })

        const {
            match: {
                params: { competitionId },
            },
        } = this.props;

        const result = await new CompetitionsClient().updateStandings(+competitionId);
        this.setState({
            competitionStandings: result
        }, () => {
            this.setState({
                loading: false
            })
        })
    }

    private getPermissions = (): void => {
        const { userProfile } = this.props;

        this.setState({
            isUserAllowedToCreateMatch: isUserAllowed(
                userProfile,
                ModuleName.Matches,
                ActionOption.Create
            ),
            isUserAllowedToCreateTeam: isUserAllowed(
                userProfile,
                ModuleName.CompetitionTeams,
                ActionOption.Create
            ),
        });
    };

    private updateStateAfterRouteChanges = (): void => {
        const { match } = this.props;
        const { competition, selectedCompetitionTeam } = this.state;
        const {
            location: { pathname },
        } = window;

        const initialProperties = getSubPageInitialProperties(
            pathname,
            'competitions',
            match,
            Routes.ROUTE_COMPETITIONS_READ,
            Routes.ROUTE_COMPETITIONS_EDIT
        );

        var newSelectedCompetitionTeam = match.params.teamId ? competition?.competitionTeams.find(
            (ct: CompetitionTeamVm): boolean => ct.teamId.toString() === match.params.teamId
        ) : selectedCompetitionTeam;

        this.setState({
            activeTabKey: initialProperties.activeTabKey || Routes.ROUTE_COMPETITIONS_READ,
            id: initialProperties.initialEntityId,
            isEditMode: initialProperties.isInitialEditMode,
            drawerState:
                initialProperties.activeTabKey &&
                    initialProperties.activeTabKey !== Routes.ROUTE_COMPETITIONS_READ &&
                    initialProperties.isInitialEditMode
                    ? DrawerState.Edit
                    : DrawerState.Closed,
            selectedCompetitionTeam: newSelectedCompetitionTeam
        });
    };

    private getCompetitionDetails = async (section?: string): Promise<void> => {
        const {
            match: {
                params: { competitionId, teamId },
            },
        } = this.props;

        if (competitionId) {
            const competitionClient = new CompetitionsClient();
            const competitionDetails = await competitionClient.getById(parseInt(competitionId, 10));

            if (teamId) {
                var selectedCompetitionTeam = competitionDetails.competitionTeams.find(
                    (tc: CompetitionTeamVm): boolean => tc.teamId.toString() === teamId
                );
            }

            this.setState({
                competition: competitionDetails,
                selectedCompetitionTeam
            });

            if (competitionDetails) {
                const teams = transformCompetitionTeamsToTeams(competitionDetails.competitionTeams);
                this.setState({
                    teams,
                });
            }
        }

        if (section === 'teams' && this.teamsTableRef && this.teamsTableRef.current) {
            this.teamsTableRef.current.getTeams();
        } else if (section === 'matches' && this.matchesTableRef && this.matchesTableRef.current) {
            this.matchesTableRef.current.getMatches();
        }

        this.setState({
            loading: false,
        });
    };

    private handleTabClick = (route: string): void => {
        const {
            history,
            match: {
                params: { competitionId },
            },
        } = this.props;

        this.setState({
            activeTabKey: route,
            isEditMode: false,
        });

        const newRoute = route.replace(ReplaceStrings.COMPETITION_ID, competitionId);

        history.push(newRoute);
    };

    private handleDrawerMode = (drawerState: DrawerState, section?: string): void => {
        const {
            history,
            match: {
                params: { competitionId },
            },
        } = this.props;

        this.setState({
            drawerState,
        });

        if (section === 'teams') {
            history.push(
                getNewRoute(
                    Routes.ROUTE_COMPETITION_TEAMS,
                    Routes.ROUTE_COMPETITION_TEAMS,
                    Routes.ROUTE_COMPETITION_TEAMS_CREATE,
                    drawerState,
                    ReplaceStrings.COMPETITION_ID,
                    competitionId
                )
            );
        } else if (section === 'matches') {
            history.push(
                getNewRoute(
                    Routes.ROUTE_COMPETITION_MATCHES,
                    Routes.ROUTE_COMPETITION_MATCHES,
                    Routes.ROUTE_COMPETITION_MATCHES_CREATE,
                    drawerState,
                    ReplaceStrings.COMPETITION_ID,
                    competitionId
                )
            );
        }
    };

    private handleCompTeamDelete = async (id?: number): Promise<void> => {
        const { competition } = this.state;
        if (id) {
            try {
                const competitionTeams = competition?.competitionTeams || [];
                const competitionTeam = competitionTeams.find(
                    (ct: CompetitionTeamVm): boolean => ct.teamId === id
                );

                if (competitionTeam) {
                    const client = new CompetitionTeamsClient();
                    const result = await client.delete(competitionTeam.id);

                    if (result) {
                        success('Competition team successfully deleted.');
                        await this.getCompetitionDetails();
                        if (this.teamsTableRef && this.teamsTableRef.current) {
                            this.teamsTableRef.current.getTeams();
                        }
                    }
                }
            } catch (error) {
                if (error instanceof ApiException) {
                    errorWithUserMessage(error.response);
                } else {
                    errorWithUserMessage('Error deleting data.');
                }
            }
        }
    };

    public render(): React.ReactElement {
        const { userProfile } = this.props;
        const {
            activeTabKey,
            competition,
            competitionStandings,
            teams,
            isEditMode,
            drawerState,
            loading,
            selectedCompetitionTeam,
            isUserAllowedToCreateMatch,
            isUserAllowedToCreateTeam,
        } = this.state;

        return (
            <div>
                <PageHeader
                    title={
                        <PageHeaderTitle
                            headerTitle={
                                competition
                                    ? competition.nameUniversal || competition.name || ''
                                    : ''
                            }
                            headerPhoto={
                                competition && competition.logo ? competition.logo : undefined
                            }
                            tableName="competition"
                            imgHeight={45}
                        />
                    }
                    style={{ padding: '16px 0px' }}
                />
                <div className="tab-padding">
                    <div
                        style={{
                            overflow: 'hidden',
                            width: '100%',
                            transition: 'width .3s cubic-bezier',
                        }}
                    >
                        <Tabs
                            style={{ width: '100%' }}
                            activeKey={activeTabKey}
                            onTabClick={(key: string): void => this.handleTabClick(key)}
                        >
                            <TabPane tab="Details" key={Routes.ROUTE_COMPETITIONS_READ}>
                                <div>
                                    {competition && (
                                        <CompetitionDetails
                                            userProfile={userProfile}
                                            isInitialEdit={isEditMode}
                                            competition={competition}
                                            refreshAfterSave={this.getCompetitionDetails}
                                        />
                                    )}
                                </div>
                            </TabPane>
                            <TabPane tab="Teams" key={Routes.ROUTE_COMPETITION_TEAMS}>
                                <div>
                                    <PageHeader
                                        title=""
                                        extra={
                                            isUserAllowedToCreateTeam
                                                ? [
                                                    <Button
                                                        type="primary"
                                                        style={{
                                                            zIndex: 10,
                                                            float: 'right',
                                                        }}
                                                        onClick={(): void =>
                                                            this.handleDrawerMode(
                                                                DrawerState.Edit,
                                                                'teams'
                                                            )
                                                        }
                                                    >
                                                        <PlusOutlined className="button-icon-display" />
                                                        Add team
                                                    </Button>,
                                                ]
                                                : []
                                        }
                                        style={{ padding: '16px 0px' }}
                                    />
                                    {competition && teams?.length ? (
                                        <TeamTable
                                            userProfile={userProfile}
                                            teamsFromProps={teams}
                                            onDelete={this.handleCompTeamDelete}
                                            wrappedComponentRef={this.teamsTableRef}
                                            moduleName={ModuleName.CompetitionTeams}
                                            shouldDisplayEditDelete
                                            competitionId={competition.id}
                                        />
                                    ) : (
                                        <div>No team competes in this competition.</div>
                                    )}
                                </div>
                            </TabPane>
                            <TabPane tab="Matches" key={Routes.ROUTE_COMPETITION_MATCHES}>
                                <div>
                                    <PageHeader
                                        title=""
                                        extra={
                                            isUserAllowedToCreateMatch
                                                ? [
                                                    <Button
                                                        type="primary"
                                                        style={{
                                                            zIndex: 10,
                                                            float: 'right',
                                                        }}
                                                        onClick={(): void =>
                                                            this.handleDrawerMode(
                                                                DrawerState.Edit,
                                                                'matches'
                                                            )
                                                        }
                                                        title={
                                                            !teams || teams.length < 2
                                                                ? 'Add more teams to the competition before creating a match'
                                                                : ''
                                                        }
                                                        disabled={!teams || teams.length < 2}
                                                    >
                                                        <PlusOutlined className="button-icon-display" />
                                                        Add match
                                                    </Button>,
                                                ]
                                                : []
                                        }
                                        style={{ padding: '16px 0px' }}
                                    />
                                    {competition &&
                                        competition.matches &&
                                        competition.matches.length > 0 ? (
                                        <MatchTable
                                            userProfile={userProfile}
                                            matchesFromProps={competition.matches}
                                            wrappedComponentRef={this.matchesTableRef}
                                        />
                                    ) : (
                                        <div>There are no matches in this competition.</div>
                                    )}
                                </div>
                            </TabPane>
                            <TabPane tab="Standings" key={Routes.ROUTE_COMPETITION_STANDINGS}>
                                <div>
                                    <PageHeader
                                        title=""
                                        extra={
                                            isUserAllowedToCreateMatch
                                                ? [
                                                    <Button
                                                        type="primary"
                                                        style={{
                                                            zIndex: 10,
                                                            float: 'right',
                                                        }}
                                                        onClick={this.updateStandings}
                                                    >
                                                        <SyncOutlined spin={loading} className="button-icon-display" />
                                                        Update Standings
                                                    </Button>,
                                                ]
                                                : []
                                        }
                                        style={{ padding: '16px 0px' }}
                                    />
                                    {competition &&
                                        competitionStandings &&
                                        competitionStandings.length > 0 ? (
                                        competitionStandings.map(cs => <StandingsTable userProfile={userProfile} standings={cs} isLoading={loading} />)
                                    ) : (
                                        <div>No standings could be loaded for this competition.</div>
                                    )}
                                </div>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
                <Drawer
                    title={
                        activeTabKey === Routes.ROUTE_COMPETITION_TEAMS
                            ? selectedCompetitionTeam
                                ? 'Edit team'
                                : 'Add team to competition'
                            : 'Add match'
                    }
                    visible={!!drawerState}
                    onClose={(): void =>
                        this.handleDrawerMode(
                            DrawerState.Closed,
                            activeTabKey === Routes.ROUTE_COMPETITION_TEAMS ? 'teams' : 'matches'
                        )
                    }
                    width={DrawerWidth}
                    destroyOnClose
                >
                    {activeTabKey === Routes.ROUTE_COMPETITION_TEAMS &&
                        competition &&
                        competition.id &&
                        !loading && (
                            <CompetitionTeamForm
                                competitionTeam={selectedCompetitionTeam}
                                handleClose={(): void => {
                                    this.handleDrawerMode(DrawerState.Closed, 'teams');
                                    this.setState({
                                        selectedCompetitionTeam: undefined,
                                    });
                                }}
                                refreshAfterSave={(): Promise<void> =>
                                    this.getCompetitionDetails('teams')
                                }
                                initialValues={{
                                    competitionId: competition.id,
                                    usedTeamIds:
                                        teams && teams.length > 0
                                            ? teams.map((t: TeamVm): number => t.id)
                                            : undefined,
                                    isInternational: competition.isInternational,
                                    federationId: competition.federationId,
                                }}
                            />
                        )}
                    {activeTabKey === Routes.ROUTE_COMPETITION_MATCHES &&
                        competition &&
                        competition.id &&
                        !loading && (
                            <MatchForm
                                handleClose={(): void =>
                                    this.handleDrawerMode(DrawerState.Closed, 'matches')
                                }
                                refreshAfterSave={(): Promise<void> =>
                                    this.getCompetitionDetails('matches')
                                }
                                initialValues={{
                                    competitionId: competition.id,
                                    teams: teams,
                                }}
                            />
                        )}
                </Drawer>
            </div>
        );
    }
}

export default CompetitionPage;
