import React from 'react';
import Icon from '../components/icon/Icon';
import { LINEUP_LIMIT } from '../config/settings';
import {
    CardTypeEnum,
    CoachRoleEnum,
    CreateEventCommand,
    CreateMatchPlayerCommandOfMatchPlayer,
    EventPeriodEnum,
    EventTypeEnum,
    EventVm,
    ExclusionTypeEnum,
    MatchCoachVm,
    MatchDetailVm,
    MatchStatusEnum,
    ShotRequest,
    ShotTypeEnum,
    TurnoverTypeEnum,
} from '../utils/api';

export const getTeamId = (event: EventVm | CreateEventCommand): number | undefined => {
    switch (event.typeId) {
        case EventTypeEnum.Card:
            return event.card?.teamId;
        case EventTypeEnum.Exclusion:
            return event.exclusion?.teamId;
        case EventTypeEnum.Shot:
            return event.shot?.teamId;
        case EventTypeEnum.Swim_Off:
            return event.swimoff?.winnerTeamId;
        case EventTypeEnum.Timeout:
            return event.timeout?.teamId;
        case EventTypeEnum.Challenge:
            return event.challenge?.teamId;
        case EventTypeEnum.Turnover:
            return event.turnover?.teamId;
        default:
            return undefined;
    }
};

export const getPlayerNumber = (event: EventVm): number | undefined => {
    switch (event.typeId) {
        case EventTypeEnum.Card:
            return event.card?.cardedPlayer?.number;
        case EventTypeEnum.Exclusion:
            return event.exclusion?.excludedPlayer?.number;
        case EventTypeEnum.Shot:
            return event.shot?.takenBy?.number;
        case EventTypeEnum.Swim_Off:
            return event.swimoff?.winnerSwimmer?.number;
        case EventTypeEnum.Turnover:
            return event.turnover?.lostPossesionPlayer?.number;
        case EventTypeEnum.Timeout:
        case EventTypeEnum.Challenge:
        default:
            return undefined;
    }
};

export const getPlayerNumberFromPlayerLineup = (
    event: CreateEventCommand | EventVm,
    playerLineup: CreateMatchPlayerCommandOfMatchPlayer[]
): number | undefined => {
    const teamId = getTeamId(event);
    let playerId: number | undefined;
    switch (event.typeId) {
        case EventTypeEnum.Card:
            playerId = event.card?.cardedPlayerId;
            break;
        case EventTypeEnum.Exclusion:
            playerId = event.exclusion?.excludedPlayerId;
            break;
        case EventTypeEnum.Shot:
            playerId = event.shot?.takenById;
            break;
        case EventTypeEnum.Swim_Off:
            playerId = event.swimoff?.winnerSwimmerId;
            break;
        case EventTypeEnum.Turnover:
            playerId = event.turnover?.lostPossesionPlayerId;
            break;
        case EventTypeEnum.Timeout:
        case EventTypeEnum.Challenge:
        default:
            playerId = undefined;
            break;
    }

    const result = playerLineup.find(
        (pl: CreateMatchPlayerCommandOfMatchPlayer): boolean =>
            pl.id === playerId && pl.teamId === teamId
    );
    return result?.number;
};

export const getEventTitle = (event: EventVm | CreateEventCommand): string => {
    switch (event.typeId) {
        case EventTypeEnum.Card: {
            switch (event.card?.typeId) {
                case CardTypeEnum.Red:
                    return 'Red Card';
                case CardTypeEnum.Yellow:
                    return 'Yellow Card';
                default:
                    return 'Card';
            }
        }
        case EventTypeEnum.Exclusion:
            return event.exclusion?.typeId
                ? ExclusionTypeEnum[event.exclusion?.typeId].replace('_', ' ')
                : EventTypeEnum[event.typeId].replace('_', ' ');
        case EventTypeEnum.Shot: {
            if (event.shot?.isGoal) {
                return 'Goal';
            } else {
                if (event.shot?.savedById) {
                    return 'Saved Shot';
                } else if (event.shot?.blockedById) {
                    return 'Blocked Shot';
                }
                return 'Missed Shot';
            }
        }
        case EventTypeEnum.Swim_Off:
            return 'Swim Off';
        case EventTypeEnum.Timeout:
            return 'Timeout';
        case EventTypeEnum.Challenge:
            return 'Challenge';
        case EventTypeEnum.Turnover: {
            return 'Turnover';
        }
        default:
            return EventTypeEnum[event.typeId].replace('_', ' ');
    }
};

export const getEventSubtitle = (
    event: EventVm | CreateEventCommand,
    lineups: CreateMatchPlayerCommandOfMatchPlayer[]
): string => {
    switch (event.typeId) {
        case EventTypeEnum.Shot: {
            const isFastBreak = event.shot?.isFastBreak;

            switch (event.shot?.typeId) {
                case ShotTypeEnum.Regular_Attack:
                    return isFastBreak ? 'Fast Break' : 'Regular Attack';
                case ShotTypeEnum.Power_Play:
                    return 'Power Play';
                case ShotTypeEnum.Penalty:
                    return 'Penalty';
                case ShotTypeEnum.Penalty_Shootout:
                    return 'Penalty Shootout';
                default:
                    return '';
            }
        }
        case EventTypeEnum.Turnover: {
            const stolenById = event.turnover?.wonPossesionPlayerId;
            const stolenByLineup = lineups.find(
                (l: CreateMatchPlayerCommandOfMatchPlayer): boolean => l.id === stolenById
            );

            switch (event.turnover?.typeId) {
                case TurnoverTypeEnum.Lost_Ball:
                    return stolenByLineup
                        ? `Stolen by Player ${stolenByLineup.capNumber || stolenByLineup.number}`
                        : 'Lost Ball';
                case TurnoverTypeEnum.Offensive_Foul:
                    return stolenByLineup
                        ? `Offensive Foul on Player ${stolenByLineup.capNumber || stolenByLineup.number}`
                        : 'Offensive Foul';
                case TurnoverTypeEnum.Ball_Under:
                    return stolenByLineup
                        ? `Submerged by Player ${stolenByLineup.capNumber || stolenByLineup.number}`
                        : 'Ball Under';
                case TurnoverTypeEnum.Clock_Expired:
                    return 'Clock Expired';
                default:
                    return '';
            }
        }
        case EventTypeEnum.Exclusion: {
            const fouledPlayerId = event.exclusion?.fouledPlayerId;
            const lineup = lineups.find(
                (l: CreateMatchPlayerCommandOfMatchPlayer): boolean => l.id === fouledPlayerId
            );
            if (!lineup) return '';
            return `On Player ${lineup.capNumber || lineup.number}`;
        }
        case EventTypeEnum.Swim_Off: {
            if (event.swimoff?.winnerSwimmerId) {
                const lostPlayerId =
                    event.swimoff?.winnerSwimmerId === event.swimoff?.homeTeamSwimmerId
                        ? event.swimoff?.awayTeamSwimmerId
                        : event.swimoff?.homeTeamSwimmerId;
                const lostPlayerLineup = lineups.find(
                    (l: CreateMatchPlayerCommandOfMatchPlayer): boolean => l.id === lostPlayerId
                );
                if (lostPlayerLineup) {
                    return `Won vs Player ${lostPlayerLineup.capNumber || lostPlayerLineup.number}`;
                }
                return '';
            }
            return 'No Winner';
        }
        case EventTypeEnum.Card:
        case EventTypeEnum.Timeout:
        case EventTypeEnum.Challenge:
        default:
            return '';
    }
};

export const getEventIcon = (event: EventVm | CreateEventCommand): React.ReactNode => {
    switch (event.typeId) {
        case EventTypeEnum.Shot:
            return <Icon name="icon-volleyball-ball" />;
        case EventTypeEnum.Card:
            return <Icon name="icon-edit-solid" />;
        case EventTypeEnum.Swim_Off:
            return <Icon name="icon-swimmer" />;
        case EventTypeEnum.Turnover:
            return <Icon name="icon-share" />;
        case EventTypeEnum.Timeout:
            return <Icon name="icon-whistle-solid" />;
        case EventTypeEnum.Challenge:
            return <Icon name="icon-whistle-solid" />;
        case EventTypeEnum.Exclusion: {
            switch (event.exclusion?.typeId) {
                case ExclusionTypeEnum.Exclusion:
                    return <Icon name="icon-times" />;
                case ExclusionTypeEnum.Suspension:
                    return <Icon name="icon-ban" />;
                case ExclusionTypeEnum.Penalty:
                    return <span className="fake-svg"> 5m </span>;
                case ExclusionTypeEnum.Brutality:
                    return <Icon name="icon-user-times" />;
                default:
                    return <Icon name="icon-times" />;
            }
        }
        default:
            return <span className="fake-svg"> </span>;
    }
};

export const getEventComment = (event: EventVm): string => {
    switch (event.typeId) {
        case EventTypeEnum.Challenge:
            return 'CH';
        case EventTypeEnum.Timeout:
            return 'TO';
        case EventTypeEnum.Swim_Off:
            return '#';
        case EventTypeEnum.Card:
            return event?.card?.typeId === CardTypeEnum.Red ? 'RC' : 'YC';
        case EventTypeEnum.Exclusion:
            switch (event.exclusion?.typeId) {
                case ExclusionTypeEnum.Exclusion:
                    return 'E';
                case ExclusionTypeEnum.Suspension:
                    return 'ES';
                case ExclusionTypeEnum.Brutality:
                    return 'EB';
                case ExclusionTypeEnum.Penalty:
                    return 'P';
                default:
                    return '';
            }
        case EventTypeEnum.Shot:
            if (event.shot?.isGoal) {
                switch (event.shot?.typeId) {
                    case ShotTypeEnum.Regular_Attack:
                        return 'G';
                    case ShotTypeEnum.Power_Play:
                        return 'EG';
                    case ShotTypeEnum.Penalty:
                        return 'PG';
                    case ShotTypeEnum.Penalty_Shootout:
                        return 'PSG';
                    default:
                        return '';
                }
            }
            return '';
        default:
            return '';
    }
};

export const compareCoaches = (c1: MatchCoachVm, c2: MatchCoachVm): number => {
    if (
        (c1.roleId === CoachRoleEnum.Head_Coach && c2.roleId === CoachRoleEnum.Head_Coach) ||
        (c1.roleId === CoachRoleEnum.Assistant_Coach && c2.roleId === CoachRoleEnum.Assistant_Coach)
    ) {
        return 0;
    }
    if (c1.roleId === CoachRoleEnum.Head_Coach && c2.roleId === CoachRoleEnum.Assistant_Coach) {
        return -1;
    }
    return 1;
};

export const formatTime = (min: number, sec: number): string =>
    `${min < 10 ? `0${min}` : min}:${sec < 10 ? `0${sec}` : sec}`;

export const filterPeriodEvents = (events: EventVm[], period: EventPeriodEnum): EventVm[] =>
    events.filter((event: EventVm): boolean => event.periodId === period);

export const calculateGoals = (events: EventVm[], period?: EventPeriodEnum): number => {
    const filteredEvents: EventVm[] = [];
    events.forEach((e: EventVm): void => {
        if ((!period || e.periodId === period) && e.shot?.isGoal) {
            filteredEvents.push(e);
        }
    });

    return filteredEvents.length;
};

export const getFouls = (events: EventVm[], foulOrdinal: number): string => {
    if (events.length > foulOrdinal) {
        const exclusionType = events[foulOrdinal].exclusion?.type;
        return exclusionType ? exclusionType.charAt(0) : '';
    }
    return '';
};

export const countExclusions = (events: EventVm[]): number => {
    const exclusions = events.filter((e: EventVm): boolean => e.typeId === EventTypeEnum.Exclusion);
    return exclusions.length;
};

export const compareEventsByTime = (e1: EventVm, e2: EventVm): number => {
    if (e1.periodId === e2.periodId) {
        if (e1.minute === e2.minute) {
            if (e1.seconds === e2.seconds) {
                if (e1.id > e2.id) {
                    return -1;
                }
                return 1;
            }
            if (e1.seconds > e2.seconds) {
                return 1;
            }
            return -1;
        }
        if (e1.minute > e2.minute) {
            return 1;
        }
        return -1;
    }
    if (e1.periodId > e2.periodId) {
        return 1;
    }
    return -1;
};

export const refreshMatchGoals = (match: MatchDetailVm, result: EventVm) => {
    return {
        ...match,
        homeTeamGoalsQ1: result.match.homeTeamGoalsQ1,
        homeTeamGoalsQ2: result.match.homeTeamGoalsQ2,
        homeTeamGoalsQ3: result.match.homeTeamGoalsQ3,
        homeTeamGoalsQ4: result.match.homeTeamGoalsQ4,
        homeTeamGoalsOT: result.match.homeTeamGoalsOT,
        awayTeamGoalsQ1: result.match.awayTeamGoalsQ1,
        awayTeamGoalsQ2: result.match.awayTeamGoalsQ2,
        awayTeamGoalsQ3: result.match.awayTeamGoalsQ3,
        awayTeamGoalsQ4: result.match.awayTeamGoalsQ4,
        awayTeamGoalsOT: result.match.awayTeamGoalsOT,
        homeTeamGoalsTotal: result.match.homeTeamGoalsTotal,
        awayTeamGoalsTotal: result.match.awayTeamGoalsTotal
    } as MatchDetailVm;
}

export const calculateGoalsAfterEventChange = (
    homeEvents: EventVm[],
    awayEvents: EventVm[],
    match: MatchDetailVm
): MatchDetailVm => {
    return {
        ...match,
        homeTeamGoalsQ1: calculateGoals(homeEvents, EventPeriodEnum.First_Period),
        homeTeamGoalsQ2: calculateGoals(homeEvents, EventPeriodEnum.Second_Period),
        homeTeamGoalsQ3: calculateGoals(homeEvents, EventPeriodEnum.Third_Period),
        homeTeamGoalsQ4: calculateGoals(homeEvents, EventPeriodEnum.Fourth_Period),
        homeTeamGoalsOT: calculateGoals(homeEvents, EventPeriodEnum.Overtime),
        homeTeamGoalsTotal: calculateGoals(homeEvents),
        awayTeamGoalsQ1: calculateGoals(awayEvents, EventPeriodEnum.First_Period),
        awayTeamGoalsQ2: calculateGoals(awayEvents, EventPeriodEnum.Second_Period),
        awayTeamGoalsQ3: calculateGoals(awayEvents, EventPeriodEnum.Third_Period),
        awayTeamGoalsQ4: calculateGoals(awayEvents, EventPeriodEnum.Fourth_Period),
        awayTeamGoalsOT: calculateGoals(awayEvents, EventPeriodEnum.Overtime),
        awayTeamGoalsTotal: calculateGoals(awayEvents),
    } as MatchDetailVm;
};

export const prepareMatchRequestForSwimoffEvent = (
    event: EventVm,
    match: MatchDetailVm
): MatchDetailVm => {
    return {
        ...match,
        status: getMatchStatusByEventPeriod(event.periodId),
        statusId: getMatchStatusIdByEventPeriod(event.periodId),
        homeTeamGoalsQ1:
            event.periodId === EventPeriodEnum.First_Period ? 0 : match.homeTeamGoalsQ1,
        homeTeamGoalsQ2:
            event.periodId === EventPeriodEnum.Second_Period ? 0 : match.homeTeamGoalsQ2,
        homeTeamGoalsQ3:
            event.periodId === EventPeriodEnum.Third_Period ? 0 : match.homeTeamGoalsQ3,
        homeTeamGoalsQ4:
            event.periodId === EventPeriodEnum.Fourth_Period ? 0 : match.homeTeamGoalsQ4,
        homeTeamGoalsOT: event.periodId === EventPeriodEnum.Overtime ? 0 : match.homeTeamGoalsOT,
        awayTeamGoalsQ1:
            event.periodId === EventPeriodEnum.First_Period ? 0 : match.awayTeamGoalsQ1,
        awayTeamGoalsQ2:
            event.periodId === EventPeriodEnum.First_Period ? 0 : match.awayTeamGoalsQ2,
        awayTeamGoalsQ3:
            event.periodId === EventPeriodEnum.First_Period ? 0 : match.awayTeamGoalsQ3,
        awayTeamGoalsQ4:
            event.periodId === EventPeriodEnum.First_Period ? 0 : match.awayTeamGoalsQ4,
        awayTeamGoalsOT:
            event.periodId === EventPeriodEnum.First_Period ? 0 : match.awayTeamGoalsOT,
    } as MatchDetailVm;
};

export const getMatchStatusIdByEventPeriod = (period: EventPeriodEnum): MatchStatusEnum => {
    switch (period) {
        case EventPeriodEnum.First_Period:
            return MatchStatusEnum.First_Period;
        case EventPeriodEnum.Second_Period:
            return MatchStatusEnum.Second_Period;
        case EventPeriodEnum.Third_Period:
            return MatchStatusEnum.Third_Period;
        case EventPeriodEnum.Fourth_Period:
            return MatchStatusEnum.Fourth_Period;
        case EventPeriodEnum.Overtime:
            return MatchStatusEnum.Overtime;
        default:
            return MatchStatusEnum.First_Period;
    }
};

export const getMatchStatusByEventPeriod = (period: EventPeriodEnum): string => {
    switch (period) {
        case EventPeriodEnum.First_Period:
            return '1st Period';
        case EventPeriodEnum.Second_Period:
            return '2nd Period';
        case EventPeriodEnum.Third_Period:
            return '3rd Period';
        case EventPeriodEnum.Fourth_Period:
            return '4th Period';
        case EventPeriodEnum.Overtime:
            return 'Overtime';
        default:
            return '1st Period';
    }
};

export const lineupNumberHasEvents = (
    events: EventVm[],
    capNumber: number,
    teamId: number
): boolean => {
    let isUsed = false;

    // eslint-disable-next-line array-callback-return
    events.map((event: EventVm): void => {
        switch (event.typeId) {
            case EventTypeEnum.Card:
                if (
                    event.card?.cardedPlayer?.number === capNumber &&
                    event.card?.cardedPlayer.teamId === teamId
                ) {
                    isUsed = true;
                }
                break;
            case EventTypeEnum.Exclusion:
                if (
                    event.exclusion?.excludedPlayer?.number === capNumber &&
                    event.exclusion?.excludedPlayer?.teamId === teamId
                ) {
                    isUsed = true;
                }
                if (
                    event.exclusion?.fouledPlayer?.number === capNumber &&
                    event.exclusion?.fouledPlayer?.teamId === teamId
                ) {
                    isUsed = true;
                }
                break;
            case EventTypeEnum.Shot:
                if (
                    event.shot?.assistedBy?.number === capNumber &&
                    event.shot?.assistedBy?.teamId === teamId
                ) {
                    isUsed = true;
                }
                if (
                    event.shot?.blockedBy?.number === capNumber &&
                    event.shot?.blockedBy?.teamId === teamId
                ) {
                    isUsed = true;
                }
                if (
                    event.shot?.savedBy?.number === capNumber &&
                    event.shot?.savedBy?.teamId === teamId
                ) {
                    isUsed = true;
                }
                if (
                    event.shot?.takenBy?.number === capNumber &&
                    event.shot?.takenBy?.teamId === teamId
                ) {
                    isUsed = true;
                }
                break;
            case EventTypeEnum.Swim_Off:
                if (
                    event.swimoff?.homeTeamSwimmer?.number === capNumber &&
                    event.swimoff?.homeTeamSwimmer?.teamId === teamId
                ) {
                    isUsed = true;
                }
                if (
                    event.swimoff?.awayTeamSwimmer?.number === capNumber &&
                    event.swimoff?.awayTeamSwimmer?.teamId === teamId
                ) {
                    isUsed = true;
                }
                // winner check is redundant
                if (
                    event.swimoff?.winnerSwimmer?.number === capNumber &&
                    event.swimoff?.winnerSwimmer?.teamId === teamId
                ) {
                    isUsed = true;
                }
                break;
            case EventTypeEnum.Turnover:
                if (
                    event.turnover?.lostPossesionPlayer?.number === capNumber &&
                    event.turnover?.lostPossesionPlayer?.teamId === teamId
                ) {
                    isUsed = true;
                }
                if (
                    event.turnover?.wonPossesionPlayer?.number === capNumber &&
                    event.turnover?.wonPossesionPlayer?.teamId === teamId
                ) {
                    isUsed = true;
                }
                break;
            case EventTypeEnum.Timeout:
            case EventTypeEnum.Challenge:
            default:
                break;
        }
    });

    return isUsed;
};

export const getListOfDeletedLineupNumberWithEvent = (
    events: EventVm[],
    teamId: number,
    lineups: CreateMatchPlayerCommandOfMatchPlayer[]
): number[] => {
    const deletedLineupNumbersWithEvents: number[] = [];

    for (let i = 0; i < LINEUP_LIMIT; i++) {
        if (lineupNumberHasEvents(events, i + 1, teamId)) {
            const findResult = lineups.find(
                (l: CreateMatchPlayerCommandOfMatchPlayer): boolean => l.number === i + 1
            );
            if (!findResult) {
                deletedLineupNumbersWithEvents.push(i + 1);
            }
        }
    }

    return deletedLineupNumbersWithEvents;
};

export const isShotOnTarget = (shot: ShotRequest): boolean => {
    if (shot.targetX === undefined || shot.targetY === undefined) return false;

    const crossbarLimit = 0.346;
    const leftPostLimit = 0.167;
    const rightPostLimit = 0.838;

    return (
        shot.targetY > crossbarLimit &&
        shot.targetX > leftPostLimit &&
        shot.targetX < rightPostLimit
    );
};
