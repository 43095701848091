import { MatchCoachVm, UpdateEventCommand } from '../../../../utils/api';
import Card from './Card';

interface OwnProps {
    isHomeTeam: boolean;
    event: UpdateEventCommand;
    coaches: MatchCoachVm[];
    updateEvent: (event: UpdateEventCommand) => void;
}

export type Props = OwnProps;

export default Card;
