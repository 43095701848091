import { Form, Input } from 'antd';
import React from 'react';
import AutocompleteDropdown from '../../../components/autocomplete-dropdown';
import DrawerButtons from '../../../components/drawer-buttons/index';
import { DropdownOption } from '../../../core/models/DropdownOption';
import { DrawerState } from '../../../core/models/enum';
import {
    confirmDropdownHelper,
    filterDropdownOptions,
} from '../../../helpers/AutocompleteDropdownHelper';
import {
    getClubsToDropdownOptions,
    getFederationsToDropdownOptions,
    getPersonsWithAdditionalInfoInNameToDropdownOptions,
    getUserTypesToDropdownOptions,
} from '../../../helpers/FetchAndTransformHelpers';
import { formItemLayout724 } from '../../../helpers/FormLayoutHelper';
import { errorWithUserMessage, success } from '../../../helpers/NotificationHelper';
import { customArrayValidationHelper } from '../../../helpers/ValidationHelper';
import { ApiException, RoleEnum, UsersClient } from '../../../utils/api';
import { Props } from './index';

interface State {
    isSaving: boolean;
    federations: DropdownOption[];
    persons: DropdownOption[];
    clubs: DropdownOption[];
    types: DropdownOption[];
    selectedFederation?: DropdownOption[];
    selectedPerson?: DropdownOption[];
    selectedClub?: DropdownOption[];
    selectedType?: DropdownOption[];
}

class UserForm extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            isSaving: false,
            federations: [],
            persons: [],
            clubs: [],
            types: [],
        };
    }

    public componentDidMount = (): void => {
        this.getFederations();
        this.getClubs();
        this.getPersons();
        this.getUserTypes();
    };

    private getFederations = async (): Promise<void> => {
        const { user, initialValues } = this.props;
        const preSelectedFederationId = initialValues && initialValues.federationId;
        const federationId = user && user.federationId;
        const results = await getFederationsToDropdownOptions(
            federationId || preSelectedFederationId
        );

        this.setState({
            federations: results.entities,
            selectedFederation: results.selectedEntity,
        });
    };

    private getClubs = async (): Promise<void> => {
        const { user, initialValues } = this.props;
        const preSelectedClubId = initialValues && initialValues.clubId;
        const clubId = user && user.clubId;
        const results = await getClubsToDropdownOptions(clubId || preSelectedClubId);

        this.setState({
            clubs: results.entities,
            selectedClub: results.selectedEntity,
        });
    };

    private getPersons = async (): Promise<void> => {
        const { user, initialValues } = this.props;
        const preSelectedPersonId = initialValues && initialValues.personId;
        const personId = user && user.personId;
        const results = await getPersonsWithAdditionalInfoInNameToDropdownOptions(
            personId || preSelectedPersonId
        );

        this.setState({
            persons: results.entities,
            selectedPerson: results.selectedEntity,
        });
    };

    private getUserTypes = async (): Promise<void> => {
        const { user } = this.props;
        const results = await getUserTypesToDropdownOptions(user?.typeId);

        this.setState({
            types: results.entities,
            selectedType: results.selectedEntity,
        });
    };

    private onSubmit = (values: any): void => {
        const { selectedPerson, selectedClub, selectedFederation, selectedType } = this.state;
        const { user } = this.props;

        this.setState({
            isSaving: true,
        });

        const request = {
            ...values,
            id: user ? user.id : undefined,
            clubId:
                selectedClub && selectedClub.length > 0
                    ? parseInt(selectedClub[0].id, 10)
                    : undefined,
            personId:
                selectedPerson && selectedPerson.length > 0
                    ? parseInt(selectedPerson[0].id, 10)
                    : undefined,
            federationId:
                selectedFederation && selectedFederation.length > 0
                    ? parseInt(selectedFederation[0].id, 10)
                    : undefined,
            typeId:
                selectedType && selectedType.length > 0
                    ? parseInt(selectedType[0].id, 10)
                    : undefined,
        };

        this.saveHelper(request);

        this.setState({
            isSaving: false,
        });
    };

    private saveHelper = async (request: any): Promise<void> => {
        try {
            let result;
            const client = new UsersClient();
            if (request.id) {
                result = await client.update(request.id, request);
            } else {
                result = await client.create(request);
            }

            if (result) {
                this.handleSuccessfullySaving();
            }
        } catch (error) {
            if (error instanceof ApiException) {
                errorWithUserMessage(error.response);
            } else {
                errorWithUserMessage('Error saving data.');
            }    
        }
    };

    private handleSuccessfullySaving = (): void => {
        const { user, handleClose, refreshAfterSave } = this.props;

        success('User successfully saved.');
        if (refreshAfterSave) {
            refreshAfterSave();
        }
        handleClose(
            user ? DrawerState.Read : DrawerState.Closed,
            user ? user.id.toString() : undefined
        );
    };

    public render(): React.ReactElement {
        const {
            isSaving,
            selectedClub,
            selectedPerson,
            selectedFederation,
            selectedType,
            clubs,
            persons,
            federations,
            types,
        } = this.state;
        const { handleClose, user, initialValues } = this.props;

        return (
            <Form onFinish={this.onSubmit} {...formItemLayout724}>
                <DrawerButtons
                    isSaving={isSaving}
                    onCancelAction={(): void =>
                        handleClose(
                            user ? DrawerState.Read : DrawerState.Closed,
                            user ? user.id.toString() : undefined
                        )
                    }
                />
                <div className="drawer-content">
                    <Form.Item
                        name="email"
                        label="Email"
                        initialValue={user ? user.email : undefined}
                        rules={[
                            {
                                required: true,
                                message: 'Email field is required!',
                            },
                            { type: 'email', message: 'Wrong e-mail format!' },
                            { max: 100, message: 'Too long input!' },
                        ]}
                    >
                        <Input disabled={!!user} />
                    </Form.Item>
                    <Form.Item name="typeId" label="Type">
                        <AutocompleteDropdown
                            placeholder="Choose type"
                            getOptionsFrontend={(value: string): DropdownOption[] =>
                                filterDropdownOptions(value, types)
                            }
                            initialValues={selectedType}
                            confirmDirty={(options: DropdownOption[]): void =>
                                this.setState({
                                    selectedType: confirmDropdownHelper(options),
                                    selectedClub: undefined,
                                    selectedFederation: undefined,
                                    selectedPerson: undefined,
                                })
                            }
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                    {selectedType &&
                        selectedType.length > 0 &&
                        parseInt(selectedType[0].id, 10) === RoleEnum.Federation && (
                            <Form.Item
                                name="federation"
                                label="Federation"
                                rules={[
                                    {
                                        required: true,
                                        validator: (_rule: any, value: any, callback: any): void =>
                                            customArrayValidationHelper(
                                                _rule,
                                                value,
                                                callback,
                                                'Federation field is required!',
                                                selectedFederation
                                            ),
                                    },
                                ]}
                            >
                                <AutocompleteDropdown
                                    placeholder="Choose federation"
                                    getOptionsFrontend={(value: string): DropdownOption[] =>
                                        filterDropdownOptions(value, federations)
                                    }
                                    initialValues={selectedFederation}
                                    confirmDirty={(options: DropdownOption[]): void =>
                                        this.setState({
                                            selectedFederation: confirmDropdownHelper(options),
                                        })
                                    }
                                    style={{ width: '100%' }}
                                    disabled={!!(initialValues && initialValues.federationId)}
                                    isAllowedToClear
                                />
                            </Form.Item>
                        )}
                    {selectedType &&
                        selectedType.length > 0 &&
                        parseInt(selectedType[0].id, 10) === RoleEnum.Club && (
                            <Form.Item
                                name="club"
                                label="Club"
                                rules={[
                                    {
                                        required: true,
                                        validator: (_rule: any, value: any, callback: any): void =>
                                            customArrayValidationHelper(
                                                _rule,
                                                value,
                                                callback,
                                                'Club field is required!',
                                                selectedClub
                                            ),
                                    },
                                ]}
                            >
                                <AutocompleteDropdown
                                    placeholder="Choose club"
                                    getOptionsFrontend={(value: string): DropdownOption[] =>
                                        filterDropdownOptions(value, clubs)
                                    }
                                    initialValues={selectedClub}
                                    confirmDirty={(options: DropdownOption[]): void =>
                                        this.setState({
                                            selectedClub: confirmDropdownHelper(options),
                                        })
                                    }
                                    style={{ width: '100%' }}
                                    disabled={!!(initialValues && initialValues.clubId)}
                                    isAllowedToClear
                                />
                            </Form.Item>
                        )}
                    {selectedType &&
                        selectedType.length > 0 &&
                        parseInt(selectedType[0].id, 10) === RoleEnum.Person && (
                            <Form.Item
                                name="person"
                                label="Person"
                                rules={[
                                    {
                                        required: true,
                                        validator: (_rule: any, value: any, callback: any): void =>
                                            customArrayValidationHelper(
                                                _rule,
                                                value,
                                                callback,
                                                'Person field is required!',
                                                selectedPerson
                                            ),
                                    },
                                ]}
                            >
                                <AutocompleteDropdown
                                    placeholder="Choose person"
                                    getOptionsFrontend={(value: string): DropdownOption[] =>
                                        filterDropdownOptions(value, persons)
                                    }
                                    initialValues={selectedPerson}
                                    confirmDirty={(options: DropdownOption[]): void =>
                                        this.setState({
                                            selectedPerson: confirmDropdownHelper(options),
                                        })
                                    }
                                    style={{ width: '100%' }}
                                    disabled={!!(initialValues && initialValues.personId)}
                                    isAllowedToClear
                                />
                            </Form.Item>
                        )}
                </div>
            </Form>
        );
    }
}

export default UserForm;
