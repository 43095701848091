import { ModuleName } from '../../core/models/enum';
import {
    CreateClubCoachCommandOfClubCoach,
    CreateClubPlayerCommandOfClubPlayer,
} from '../../utils/api';
import PersonClubs from './PersonClubs';

interface OwnProps {
    entityModule: ModuleName;
    initialClubId?: number;
    selectedCoachClubs?: CreateClubCoachCommandOfClubCoach[];
    selectedPlayerClubs?: CreateClubPlayerCommandOfClubPlayer[];
    saveCoachChanges?: (team: CreateClubCoachCommandOfClubCoach) => void;
    savePlayerChanges?: (team: CreateClubPlayerCommandOfClubPlayer) => void;
    removeCoachClubFromList?: (team: CreateClubCoachCommandOfClubCoach) => void;
    removePlayerClubFromList?: (team: CreateClubPlayerCommandOfClubPlayer) => void;
}

export type Props = OwnProps;

export default PersonClubs;
