import { Profile } from 'oidc-client';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RouteParams } from '../../../core/models/RouteHelper';
import { CityVm } from '../../../utils/api';
import CityTable from './CityTable';

interface OwnProps {
    userProfile: Profile;
    wrappedComponentRef?: any;
    citiesFromProps?: CityVm[];
}

export type Props = OwnProps & RouteComponentProps<RouteParams>;

export default withRouter(CityTable);
