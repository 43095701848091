import { Profile } from 'oidc-client';
import { ActionOption, ExtendedRoleEnum, ModuleName } from '../core/models/enum';

export interface SelectedUserRole {
    admin: boolean;
    federation: boolean;
    club: boolean;
    official: boolean;
    coach: boolean;
}

export const defaultSelectedUserRole: SelectedUserRole = {
    admin: false,
    federation: false,
    club: false,
    official: false,
    coach: false,
};

export const getUserRole = (userProfile: Profile): SelectedUserRole => {
    if (!userProfile) return defaultSelectedUserRole;

    const userRole = userProfile['role'];
    const userRoleEnums: ExtendedRoleEnum[] = [];

    if (typeof userRole === 'string') {
        userRoleEnums.push(
            ExtendedRoleEnum[userRole as unknown as number] as unknown as ExtendedRoleEnum
        );
    } else {
        for (const ur of userRole) {
            userRoleEnums.push(
                ExtendedRoleEnum[ur as unknown as number] as unknown as ExtendedRoleEnum
            );
        }
    }

    let isAdmin = false;
    let isFederation = false;
    let isClub = false;
    let isOfficial = false;
    let isCoach = false;

    userRoleEnums.map((u: ExtendedRoleEnum): null => {
        switch (u) {
            case ExtendedRoleEnum.Admin:
                isAdmin = true;
                break;
            case ExtendedRoleEnum.Federation:
                isFederation = true;
                break;
            case ExtendedRoleEnum.Club:
                isClub = true;
                break;
            case ExtendedRoleEnum.Official:
                isOfficial = true;
                break;
            case ExtendedRoleEnum.Coach:
                isCoach = true;
                break;
            default:
                break;
        }

        return null;
    });

    return {
        admin: isAdmin,
        federation: isFederation,
        club: isClub,
        official: isOfficial,
        coach: isCoach,
    };
};

export const isUserAdmin = (userProfile: Profile): boolean => {
    const userRole = userProfile['role'];
    const userRoleEnum = ExtendedRoleEnum[userRole] as unknown as ExtendedRoleEnum;

    return userRoleEnum === ExtendedRoleEnum.Admin;
};

export const isUserFederation = (userProfile: Profile): boolean => {
    const userRole = userProfile['role'];
    const userRoleEnum = ExtendedRoleEnum[userRole] as unknown as ExtendedRoleEnum;

    return userRoleEnum === ExtendedRoleEnum.Federation;
};

export const isUserOfficial = (userProfile: Profile): boolean => {
    const userRole = userProfile['role'];

    if (typeof userRole === 'string') {
        return false;
    } else {
        return userRole.some((role: string) => role === 'Official');
    }
};

export const isUserCoach = (userProfile: Profile): boolean => {
    const userRole = userProfile['role'];

    if (typeof userRole === 'string') {
        return false;
    } else {
        return userRole.some((role: string) => role === 'Coach');
    }
};

export const getAllowedEntities = (userProfile: Profile, module: ModuleName): number[] => {
    const accessModel = userProfile['access'];

    if (accessModel && accessModel['read']) {
        return accessModel['read'][module];
    }

    return [];
};

export const isUserAllowed = (
    userProfile: Profile,
    module: ModuleName,
    action: ActionOption,
    entityId?: number
): boolean => {
    const userRole = userProfile['role'];
    const userRoleEnums: ExtendedRoleEnum[] = [];
    const accessModel = userProfile['access'];

    //let allowedEntities: number[] = [];

    //if (accessModel && accessModel['read']) {
    //    switch (module) {
    //        case ModuleName.MatchLog:
    //            allowedEntities = accessModel['read']['matches'] || [];
    //            break;
    //        case ModuleName.CompetitionTeams:
    //            allowedEntities = accessModel['read']['teams'] || [];
    //            break;
    //        default:
    //            allowedEntities = accessModel['read'][module] || [];
    //            break;
    //    }
    //}

    if (typeof userRole === 'string') {
        userRoleEnums.push(
            ExtendedRoleEnum[userRole as unknown as number] as unknown as ExtendedRoleEnum
        );
    } else {
        for (const ur of userRole) {
            userRoleEnums.push(
                ExtendedRoleEnum[ur as unknown as number] as unknown as ExtendedRoleEnum
            );
        }
    }

    //const isAllowedEntity = allowedEntities.some((id: number): boolean => id === entityId);
    const isAllowedEntity = true;
    const canRead = !entityId || isAllowedEntity;
    const canWrite = isAllowedEntity;

    return userRoleEnums.some((userRoleEnum: ExtendedRoleEnum): boolean => {
        switch (module) {
            case ModuleName.Dashboard:
                return true;
            case ModuleName.Federations:
                return checkFederationPermissions(userRoleEnum, action, canRead, canWrite);
            case ModuleName.FederationRoles:
                return checkFederationRolesPermissions(userRoleEnum, action, canRead, canWrite);
            case ModuleName.Competitions:
                return checkCompetitionPermissions(userRoleEnum, action, canRead, canWrite);
            case ModuleName.CompetitionTeams:
                return checkCompetitionTeamPermissions(userRoleEnum, action, canRead, canWrite);
            case ModuleName.Clubs:
                return checkClubPermissions(userRoleEnum, action, canRead, canWrite);
            case ModuleName.Teams:
                return checkTeamPermissions(userRoleEnum, action, canRead, canWrite);
            case ModuleName.Matches:
                return checkMatchPermissions(userRoleEnum, action, canRead, canWrite);
            case ModuleName.MatchLog:
                return checkMatchLogPermissions(userRoleEnum, action, canRead, canWrite);
            case ModuleName.Persons:
                return checkPersonPermissions(userRoleEnum, action, canRead, canWrite);
            case ModuleName.Coaches:
                return checkCoachPermissions(userRoleEnum, action, canRead, canWrite);
            case ModuleName.Officials:
                return checkOfficialPermissions(userRoleEnum, action, canRead, canWrite);
            case ModuleName.Players:
                return checkPlayerPermissions(userRoleEnum, action, canRead, canWrite);
            case ModuleName.Users:
                return checkUserPermissions(userRoleEnum, action, canRead, canWrite);
            case ModuleName.Cities:
                return checkCityPermissions(userRoleEnum, action, canRead, canWrite);
            default:
                return false;
        }
    });
};

const checkClubPermissions = (
    userType: ExtendedRoleEnum,
    action: ActionOption,
    canRead: boolean,
    canWrite: boolean
): boolean => {
    switch (action) {
        case ActionOption.Read:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return canRead;
                case ExtendedRoleEnum.Club:
                    return canRead;
                case ExtendedRoleEnum.Coach:
                    return canRead;
                case ExtendedRoleEnum.Official:
                    return canRead;
                default:
                    return false;
            }
        case ActionOption.Create:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return true;
                case ExtendedRoleEnum.Club:
                    return false;
                case ExtendedRoleEnum.Coach:
                    return false;
                case ExtendedRoleEnum.Official:
                    return false;
                default:
                    return false;
            }
        case ActionOption.Update:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return canWrite;
                case ExtendedRoleEnum.Club:
                    return canWrite;
                case ExtendedRoleEnum.Coach:
                    return false;
                case ExtendedRoleEnum.Official:
                    return false;
                default:
                    return false;
            }
        case ActionOption.Delete:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return canWrite;
                case ExtendedRoleEnum.Club:
                    return false;
                case ExtendedRoleEnum.Coach:
                    return false;
                case ExtendedRoleEnum.Official:
                    return false;
                default:
                    return false;
            }
        default:
            return false;
    }
};

const checkCityPermissions = (
    userType: ExtendedRoleEnum,
    action: ActionOption,
    canRead: boolean,
    canWrite: boolean
): boolean => {
    switch (action) {
        case ActionOption.Read:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return true;
                case ExtendedRoleEnum.Club:
                    return true;
                case ExtendedRoleEnum.Coach:
                    return true;
                case ExtendedRoleEnum.Official:
                    return true;
                default:
                    return false;
            }
        case ActionOption.Create:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return false;
                case ExtendedRoleEnum.Club:
                    return false;
                case ExtendedRoleEnum.Coach:
                    return false;
                case ExtendedRoleEnum.Official:
                    return false;
                default:
                    return false;
            }
        case ActionOption.Update:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return false;
                case ExtendedRoleEnum.Club:
                    return false;
                case ExtendedRoleEnum.Coach:
                    return false;
                case ExtendedRoleEnum.Official:
                    return false;
                default:
                    return false;
            }
        case ActionOption.Delete:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return false;
                case ExtendedRoleEnum.Club:
                    return false;
                case ExtendedRoleEnum.Coach:
                    return false;
                case ExtendedRoleEnum.Official:
                    return false;
                default:
                    return false;
            }
        default:
            return false;
    }
};

const checkCoachPermissions = (
    userType: ExtendedRoleEnum,
    action: ActionOption,
    canRead: boolean,
    canWrite: boolean
): boolean => {
    switch (action) {
        case ActionOption.Read:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return canRead;
                case ExtendedRoleEnum.Club:
                    return canRead;
                case ExtendedRoleEnum.Coach:
                    return false;
                case ExtendedRoleEnum.Official:
                    return true;
                default:
                    return false;
            }
        case ActionOption.Create:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return true;
                case ExtendedRoleEnum.Club:
                    return true;
                case ExtendedRoleEnum.Coach:
                    return false;
                case ExtendedRoleEnum.Official:
                    return false;
                default:
                    return false;
            }
        case ActionOption.Update:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return canWrite;
                case ExtendedRoleEnum.Club:
                    return canWrite;
                case ExtendedRoleEnum.Coach:
                    return false;
                case ExtendedRoleEnum.Official:
                    return false;
                default:
                    return false;
            }
        case ActionOption.Delete:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return canWrite;
                case ExtendedRoleEnum.Club:
                    return canWrite;
                case ExtendedRoleEnum.Coach:
                    return false;
                case ExtendedRoleEnum.Official:
                    return false;
                default:
                    return false;
            }
        default:
            return false;
    }
};

const checkCompetitionPermissions = (
    userType: ExtendedRoleEnum,
    action: ActionOption,
    canRead: boolean,
    canWrite: boolean
): boolean => {
    switch (action) {
        case ActionOption.Read:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return canRead;
                case ExtendedRoleEnum.Club:
                    return canRead;
                case ExtendedRoleEnum.Coach:
                    return canRead;
                case ExtendedRoleEnum.Official:
                    return canRead;
                default:
                    return false;
            }
        case ActionOption.Create:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return true;
                case ExtendedRoleEnum.Club:
                    return false;
                case ExtendedRoleEnum.Coach:
                    return false;
                case ExtendedRoleEnum.Official:
                    return false;
                default:
                    return false;
            }
        case ActionOption.Update:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return canWrite;
                case ExtendedRoleEnum.Club:
                    return false;
                case ExtendedRoleEnum.Coach:
                    return false;
                case ExtendedRoleEnum.Official:
                    return false;
                default:
                    return false;
            }
        case ActionOption.Delete:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return canWrite;
                case ExtendedRoleEnum.Club:
                    return false;
                case ExtendedRoleEnum.Coach:
                    return false;
                case ExtendedRoleEnum.Official:
                    return false;
                default:
                    return false;
            }
        default:
            return false;
    }
};

const checkCompetitionTeamPermissions = (
    userType: ExtendedRoleEnum,
    action: ActionOption,
    canRead: boolean,
    canWrite: boolean
): boolean => {
    switch (action) {
        case ActionOption.Read:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return canRead;
                case ExtendedRoleEnum.Club:
                    return false;
                case ExtendedRoleEnum.Coach:
                    return false;
                case ExtendedRoleEnum.Official:
                    return false;
                default:
                    return false;
            }
        case ActionOption.Create:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return true;
                case ExtendedRoleEnum.Club:
                    return false;
                case ExtendedRoleEnum.Coach:
                    return false;
                case ExtendedRoleEnum.Official:
                    return false;
                default:
                    return false;
            }
        case ActionOption.Update:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return canWrite;
                case ExtendedRoleEnum.Club:
                    return false;
                case ExtendedRoleEnum.Coach:
                    return false;
                case ExtendedRoleEnum.Official:
                    return false;
                default:
                    return false;
            }
        case ActionOption.Delete:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return canWrite;
                case ExtendedRoleEnum.Club:
                    return false;
                case ExtendedRoleEnum.Coach:
                    return false;
                case ExtendedRoleEnum.Official:
                    return false;
                default:
                    return false;
            }
        default:
            return false;
    }
};

const checkFederationPermissions = (
    userType: ExtendedRoleEnum,
    action: ActionOption,
    canRead: boolean,
    canWrite: boolean
): boolean => {
    switch (action) {
        case ActionOption.Read:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return canRead;
                case ExtendedRoleEnum.Club:
                    return canRead;
                case ExtendedRoleEnum.Coach:
                    return canRead;
                case ExtendedRoleEnum.Official:
                    return canRead;
                default:
                    return false;
            }
        case ActionOption.Create:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return false;
                case ExtendedRoleEnum.Club:
                    return false;
                case ExtendedRoleEnum.Coach:
                    return false;
                case ExtendedRoleEnum.Official:
                    return false;
                default:
                    return false;
            }
        case ActionOption.Update:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return canWrite;
                case ExtendedRoleEnum.Club:
                    return false;
                case ExtendedRoleEnum.Coach:
                    return false;
                case ExtendedRoleEnum.Official:
                    return false;
                default:
                    return false;
            }
        case ActionOption.Delete:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return false;
                case ExtendedRoleEnum.Club:
                    return false;
                case ExtendedRoleEnum.Coach:
                    return false;
                case ExtendedRoleEnum.Official:
                    return false;
                default:
                    return false;
            }
        default:
            return false;
    }
};

const checkFederationRolesPermissions = (
    userType: ExtendedRoleEnum,
    action: ActionOption,
    canRead: boolean,
    canWrite: boolean
): boolean => {
    switch (action) {
        case ActionOption.Read:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return true;
                case ExtendedRoleEnum.Club:
                    return false;
                case ExtendedRoleEnum.Coach:
                    return false;
                case ExtendedRoleEnum.Official:
                    return false;
                default:
                    return false;
            }
        case ActionOption.Create:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return true;
                case ExtendedRoleEnum.Club:
                    return false;
                case ExtendedRoleEnum.Coach:
                    return false;
                case ExtendedRoleEnum.Official:
                    return false;
                default:
                    return false;
            }
        case ActionOption.Update:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return true;
                case ExtendedRoleEnum.Club:
                    return false;
                case ExtendedRoleEnum.Coach:
                    return false;
                case ExtendedRoleEnum.Official:
                    return false;
                default:
                    return false;
            }
        case ActionOption.Delete:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return true;
                case ExtendedRoleEnum.Club:
                    return false;
                case ExtendedRoleEnum.Coach:
                    return false;
                case ExtendedRoleEnum.Official:
                    return false;
                default:
                    return false;
            }
        default:
            return false;
    }
};

const checkMatchPermissions = (
    userType: ExtendedRoleEnum,
    action: ActionOption,
    canRead: boolean,
    canWrite: boolean
): boolean => {
    switch (action) {
        case ActionOption.Read:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return canRead;
                case ExtendedRoleEnum.Club:
                    return canRead;
                case ExtendedRoleEnum.Coach:
                    return canRead;
                case ExtendedRoleEnum.Official:
                    return canRead;
                default:
                    return false;
            }
        case ActionOption.Create:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return true;
                case ExtendedRoleEnum.Club:
                    return false;
                case ExtendedRoleEnum.Coach:
                    return false;
                case ExtendedRoleEnum.Official:
                    return false;
                default:
                    return false;
            }
        case ActionOption.Update:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return canWrite;
                case ExtendedRoleEnum.Club:
                    return false;
                case ExtendedRoleEnum.Coach:
                    return false;
                case ExtendedRoleEnum.Official:
                    return canWrite;
                default:
                    return false;
            }
        case ActionOption.Delete:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return canWrite;
                case ExtendedRoleEnum.Club:
                    return false;
                case ExtendedRoleEnum.Coach:
                    return false;
                case ExtendedRoleEnum.Official:
                    return false;
                default:
                    return false;
            }
        default:
            return false;
    }
};

const checkMatchLogPermissions = (
    userType: ExtendedRoleEnum,
    action: ActionOption,
    canRead: boolean,
    canWrite: boolean
): boolean => {
    switch (action) {
        case ActionOption.Read:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return canRead;
                case ExtendedRoleEnum.Club:
                    return canRead;
                case ExtendedRoleEnum.Coach:
                    return canRead;
                case ExtendedRoleEnum.Official:
                    return canRead;
                default:
                    return false;
            }
        case ActionOption.Create:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return true;
                case ExtendedRoleEnum.Club:
                    return false;
                case ExtendedRoleEnum.Coach:
                    return false;
                case ExtendedRoleEnum.Official:
                    return canWrite;

                default:
                    return false;
            }
        case ActionOption.Update:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return canWrite;
                case ExtendedRoleEnum.Club:
                    return false;
                case ExtendedRoleEnum.Coach:
                    return false;
                case ExtendedRoleEnum.Official:
                    return canWrite;

                default:
                    return false;
            }
        case ActionOption.Delete:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return canWrite;
                case ExtendedRoleEnum.Club:
                    return false;
                case ExtendedRoleEnum.Coach:
                    return false;
                case ExtendedRoleEnum.Official:
                    return canWrite;
                default:
                    return false;
            }
        default:
            return false;
    }
};

const checkOfficialPermissions = (
    userType: ExtendedRoleEnum,
    action: ActionOption,
    canRead: boolean,
    canWrite: boolean
): boolean => {
    switch (action) {
        case ActionOption.Read:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return canRead;
                case ExtendedRoleEnum.Club:
                    return false;
                case ExtendedRoleEnum.Coach:
                    return false;
                case ExtendedRoleEnum.Official:
                    return true;
                default:
                    return false;
            }
        case ActionOption.Create:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return true;
                case ExtendedRoleEnum.Club:
                    return false;
                case ExtendedRoleEnum.Coach:
                    return false;
                case ExtendedRoleEnum.Official:
                    return false;
                default:
                    return false;
            }
        case ActionOption.Update:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return canWrite;
                case ExtendedRoleEnum.Club:
                    return false;
                case ExtendedRoleEnum.Coach:
                    return false;
                case ExtendedRoleEnum.Official:
                    return canWrite;
                default:
                    return false;
            }
        case ActionOption.Delete:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return canWrite;
                case ExtendedRoleEnum.Club:
                    return false;
                case ExtendedRoleEnum.Coach:
                    return false;
                case ExtendedRoleEnum.Official:
                    return false;
                default:
                    return false;
            }
        default:
            return false;
    }
};

const checkPersonPermissions = (
    userType: ExtendedRoleEnum,
    action: ActionOption,
    canRead: boolean,
    canWrite: boolean
): boolean => {
    switch (action) {
        case ActionOption.Read:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return true;
                case ExtendedRoleEnum.Club:
                    return true;
                case ExtendedRoleEnum.Coach:
                    return true;
                case ExtendedRoleEnum.Official:
                    return true;
                default:
                    return true;
            }
        case ActionOption.Create:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return true;
                case ExtendedRoleEnum.Club:
                    return true;
                case ExtendedRoleEnum.Coach:
                    return true;
                case ExtendedRoleEnum.Official:
                    return false;
                default:
                    return false;
            }
        case ActionOption.Update:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return canWrite;
                case ExtendedRoleEnum.Club:
                    return canWrite;
                case ExtendedRoleEnum.Coach:
                    return canWrite;
                case ExtendedRoleEnum.Official:
                    return canWrite;
                default:
                    return false;
            }
        case ActionOption.Delete:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return false;
                case ExtendedRoleEnum.Club:
                    return false;
                case ExtendedRoleEnum.Coach:
                    return false;
                case ExtendedRoleEnum.Official:
                    return false;
                default:
                    return false;
            }
        default:
            return false;
    }
};

const checkPlayerPermissions = (
    userType: ExtendedRoleEnum,
    action: ActionOption,
    canRead: boolean,
    canWrite: boolean
): boolean => {
    switch (action) {
        case ActionOption.Read:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return canRead;
                case ExtendedRoleEnum.Club:
                    return canRead;
                case ExtendedRoleEnum.Coach:
                    return canRead;
                case ExtendedRoleEnum.Official:
                    return true;
                default:
                    return false;
            }
        case ActionOption.Create:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return true;
                case ExtendedRoleEnum.Club:
                    return true;
                case ExtendedRoleEnum.Coach:
                    return true;
                case ExtendedRoleEnum.Official:
                    return false;
                default:
                    return false;
            }
        case ActionOption.Update:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return canWrite;
                case ExtendedRoleEnum.Club:
                    return canWrite;
                case ExtendedRoleEnum.Coach:
                    return canWrite;
                case ExtendedRoleEnum.Official:
                    return false;
                default:
                    return false;
            }
        case ActionOption.Delete:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return canWrite;
                case ExtendedRoleEnum.Club:
                    return canWrite;
                case ExtendedRoleEnum.Coach:
                    return canWrite;
                case ExtendedRoleEnum.Official:
                    return false;
                default:
                    return false;
            }
        default:
            return false;
    }
};

const checkTeamPermissions = (
    userType: ExtendedRoleEnum,
    action: ActionOption,
    canRead: boolean,
    canWrite: boolean
): boolean => {
    switch (action) {
        case ActionOption.Read:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return canRead;
                case ExtendedRoleEnum.Club:
                    return canRead;
                case ExtendedRoleEnum.Coach:
                    return canRead;
                case ExtendedRoleEnum.Official:
                    return canRead;
                default:
                    return false;
            }
        case ActionOption.Create:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return true;
                case ExtendedRoleEnum.Club:
                    return true;
                case ExtendedRoleEnum.Coach:
                    return false;
                case ExtendedRoleEnum.Official:
                    return false;
                default:
                    return false;
            }
        case ActionOption.Update:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return canWrite;
                case ExtendedRoleEnum.Club:
                    return canWrite;
                case ExtendedRoleEnum.Coach:
                    return canWrite;
                case ExtendedRoleEnum.Official:
                    return false;
                default:
                    return false;
            }
        case ActionOption.Delete:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                    return canWrite;
                case ExtendedRoleEnum.Club:
                    return canWrite;
                case ExtendedRoleEnum.Coach:
                    return false;
                case ExtendedRoleEnum.Official:
                    return false;
                default:
                    return false;
            }
        default:
            return false;
    }
};

const checkUserPermissions = (
    userType: ExtendedRoleEnum,
    action: ActionOption,
    canRead: boolean,
    canWrite: boolean
): boolean => {
    switch (action) {
        case ActionOption.Read:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                case ExtendedRoleEnum.Club:
                case ExtendedRoleEnum.Coach:
                case ExtendedRoleEnum.Official:
                default:
                    return false;
            }
        case ActionOption.Create:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                case ExtendedRoleEnum.Club:
                case ExtendedRoleEnum.Coach:
                case ExtendedRoleEnum.Official:
                default:
                    return false;
            }
        case ActionOption.Update:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                case ExtendedRoleEnum.Club:
                case ExtendedRoleEnum.Coach:
                case ExtendedRoleEnum.Official:
                default:
                    return false;
            }
        case ActionOption.Delete:
            switch (userType) {
                case ExtendedRoleEnum.Admin:
                    return true;
                case ExtendedRoleEnum.Federation:
                case ExtendedRoleEnum.Club:
                case ExtendedRoleEnum.Coach:
                case ExtendedRoleEnum.Official:
                default:
                    return false;
            }
        default:
            return false;
    }
};
