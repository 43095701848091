import React from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import { LoginOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import authService from './AuthorizeService';
import { AuthorizationRoutes } from './ApiAuthorizationConstants';

interface Props { }

interface State {
    isAuthenticated: boolean;
    userName: string | null;
}

export class LoginMenu extends React.Component<Props, State> {
    _subscription: any;

    constructor(props: Props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            userName: null,
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([
            authService.isAuthenticated(),
            authService.getUserProfile(),
        ]);
        this.setState({
            isAuthenticated,
            userName: user?.name,
        });
    }

    render() {
        const { isAuthenticated } = this.state;

        return (
            <Menu
                theme="dark"
                mode="inline"
                defaultSelectedKeys={['1']}
                className={isAuthenticated ? 'bottom-menu' : ''}
            >
                {isAuthenticated ? (
                    <>
                        <Menu.Item key="profile" icon={<UserOutlined />}>
                            <Link to={AuthorizationRoutes.Profile}>
                                Account
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="logout" icon={<LogoutOutlined />}>
                            <Link
                                to={{
                                    pathname: `${AuthorizationRoutes.LogOut}`,
                                    state: {
                                        local: true,
                                        returnUrl: AuthorizationRoutes.DefaultLoginRedirectPath,
                                    },
                                }}
                            >
                                Logout
                            </Link>
                        </Menu.Item>
                    </>
                ) : (
                    <Menu.Item key="login" icon={<LoginOutlined />}>
                        <Link to={AuthorizationRoutes.Login}>Login</Link>
                    </Menu.Item>
                )}
            </Menu>
        );
    }
}
