import { ToolTwoTone } from '@ant-design/icons';
import React from 'react';
import { Link } from 'react-router-dom';
import { Props } from './index';
import './styles.css';

class NotFoundPage extends React.PureComponent<Props> {
    public render(): React.ReactElement {
        return (
            <div className="not-found-wrapper">
                <div className="error_pic">
                    <ToolTwoTone className="tool-icon" />
                </div>
                <div className="error_text">
                    <h2>Ooops... Error 404</h2>
                    <h4>We are sorry, but the page you are looking for does not exist.</h4>
                    <p>
                        <span className="check">
                            Please check entered address and try again or{' '}
                        </span>
                        <Link to="/">go to homepage</Link>
                    </p>
                </div>
            </div>
        );
    }
}

export default NotFoundPage;
