import React from 'react';
import { Layout as AntLayout } from 'antd';
import { LoginMenu } from './api-authorization/LoginMenu';
import Routes from '../config/routes';
import IconLogo from '../assets/images/icon-logo.png';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { getActiveRouteName } from '../helpers/RoutingHelper';
import { RouteParams } from '../core/models/RouteHelper';
import {
    defaultSelectedUserRole,
    SelectedUserRole,
    getUserRole,
} from '../helpers/CheckPermissionHelper';
import authService from './api-authorization/AuthorizeService';
import { Profile } from 'oidc-client';
import SidebarMenu from './SidebarMenu';

const { Content, Footer, Sider } = AntLayout;

interface OwnProps {
    isSidebarHidden: boolean;
}

type Props = RouteComponentProps<RouteParams> & OwnProps;

interface State {
    collapsed: boolean;
    currentModule: string;
    userRole: SelectedUserRole;
    userProfile?: Profile;
    useDarkTheme: boolean;
}
class Layout extends React.Component<Props, State> {
    _subscription: any;

    constructor(props: Props) {
        super(props);

        this.state = {
            collapsed: false,
            currentModule: getActiveRouteName(window.location.pathname),
            userRole: defaultSelectedUserRole,
            useDarkTheme: false
        };
    }

    componentDidMount = async (): Promise<void> => {
        this._subscription = authService.subscribe(() => this.authenticationChanged());
        this.populateAuthenticationState();
    };

    componentDidUpdate = (prevProps: Props): void => {
        const { location } = this.props;

        if (location.pathname !== prevProps.location.pathname) {
            this.setState({
                currentModule: getActiveRouteName(window.location.pathname),
            });
        }
    };

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateAuthenticationState() {
        var userProfile = await authService.getUserProfile();

        this.setState({ userRole: getUserRole(userProfile) });
    }

    async authenticationChanged() {
        this.setState({ userProfile: undefined });
        await this.populateAuthenticationState();
    }

    onCollapse = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    render() {
        const { collapsed, currentModule, userRole, useDarkTheme } = this.state;
        const { isSidebarHidden } = this.props;

        return (
            <AntLayout style={{ minHeight: '100vh' }} className={`${useDarkTheme ? 'dark-theme' : ''}`}>
                <Sider
                    collapsible
                    collapsed={collapsed}
                    onCollapse={this.onCollapse}
                    hidden={isSidebarHidden}
                >
                    <div className="logo">
                        <Link to={Routes.ROUTE_DASHBOARD}>
                            <img src={IconLogo} alt="icon-logo"></img>
                        </Link>
                    </div>
                    {userRole && <SidebarMenu currentModule={currentModule} userRole={userRole} />}
                    <LoginMenu />
                </Sider>
                <AntLayout className="site-layout">
                    <Content
                        className="site-layout-background"
                        style={{
                            margin: isSidebarHidden ? '0px' : '24px 16px',
                            padding: isSidebarHidden ? 0 : 24,
                            minHeight: 280,
                        }}
                    >
                        {this.props.children}
                    </Content>
                    {!isSidebarHidden && (
                        <Footer style={{ textAlign: 'center' }}>
                            Total Waterpolo Arena &copy;{new Date().getFullYear()}
                        </Footer>
                    )}
                </AntLayout>
            </AntLayout>
        );
    }
}

export default withRouter(Layout);
