import { Table } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import Routes from '../../../config/routes';
import ReplaceStrings from '../../../config/replaceStrings';
import { ActionOption, ModuleName, SortDirectionEnum } from '../../../core/models/enum';
import { Filter } from '../../../core/models/Filter';
import { isUserAllowed } from '../../../helpers/CheckPermissionHelper';
import {
    buildFilterRequest,
    DefaultFilter,
    getDateRangeFromOption,
    getRadioFilter,
    getSearchFilter,
    getSelectFilter,
    MatchStartDateOptions,
} from '../../../helpers/FilterHelper';
import { PaginationOptions } from '../../../helpers/PaginationHelper';
import { feTableFilterHelper } from '../../../helpers/TableFilterHelper';
import { capitalizeFirstLetter } from '../../../helpers/TransformHelper';
import {
    CompetitionsClient,
    MatchFilter,
    CompetitionVm,
    MatchesClient,
    MatchVm,
    OfficialVm,
    PaginatedListOfMatchVm,
    PaginationFilterOfMatchFilter,
} from '../../../utils/api';
import { Props } from './index';
import { DropdownOption } from '../../../core/models/DropdownOption';

interface State {
    matches: MatchVm[];
    paginationOptions: TablePaginationConfig;
    currentFilter: Filter;
    loading: boolean;
    competitions: DropdownOption[];
}

const DefaultMatchFilter: Filter = {
    ...DefaultFilter,
    orderByKey: 'startDate',
    orderByValue: SortDirectionEnum.DESC,
};

class MatchTable extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            matches: [],
            paginationOptions: PaginationOptions,
            currentFilter: DefaultMatchFilter,
            loading: true,
            competitions: [],
        };
    }

    public componentDidMount = (): void => {
        this.getMatches();
        this.getCompetitions();
    };

    private getCompetitions = async (): Promise<void> => {
        const competitions = await new CompetitionsClient().getAll();

        this.setState({
            competitions: competitions.map(
                (competition: CompetitionVm): DropdownOption => ({
                    id: competition.id.toString(),
                    name: competition.nameUniversal,
                })
            ),
        });
    };

    private getMatches = async (currentFilterParam?: Filter): Promise<void> => {
        const { currentFilter } = this.state;
        const { matchesFromProps } = this.props;

        this.setState({
            loading: true,
        });

        let matchesPagedList;
        const filter = currentFilterParam || currentFilter;

        if (matchesFromProps) {
            matchesPagedList = this.getMatchesFromProps(filter);
        } else {
            matchesPagedList = await this.getAllMatches(filter);
        }

        this.setState({
            paginationOptions: {
                ...PaginationOptions,
                current: matchesPagedList.currentPage,
                pageSize: matchesPagedList.pageSize,
                total: matchesPagedList.totalItems,
            },
            matches: matchesPagedList?.pageItems || [],
            loading: false,
        });
    };

    private getAllMatches = async (filter: Filter): Promise<PaginatedListOfMatchVm> => {
        const matchClient = new MatchesClient();

        const paginationFilter: PaginationFilterOfMatchFilter = new PaginationFilterOfMatchFilter({
            filter: new MatchFilter({
                homeTeamDisplayName: filter.filterRequest?.homeTeamDisplayName,
                awayTeamDisplayName: filter.filterRequest?.awayTeamDisplayName,
                competitionId: filter.filterRequest?.competitionId,
                venue: filter.filterRequest?.venue,
                round: filter.filterRequest?.round,
                status: filter.filterRequest?.status,
                startDate: filter.filterRequest?.startDate
                    ? getDateRangeFromOption((filter.filterRequest?.startDate as string[])[0])
                    : undefined,
            }),
            pageIndex: filter.pageIndex,
            pageSize: filter.pageSize,
            sortBy: capitalizeFirstLetter(filter.orderByKey),
            sortOrder: filter.orderByValue,
        });

        return await matchClient.getPaginated(paginationFilter);
    };

    private getMatchesFromProps = (filter: Filter): PaginatedListOfMatchVm => {
        const { matchesFromProps } = this.props;

        const filteredResult = feTableFilterHelper(matchesFromProps || [], filter);
        return new PaginatedListOfMatchVm({
            ...filteredResult,
            pageItems: filteredResult.pageItems as MatchVm[],
        });
    };

    private handleTableChange = (pagination: any, filters: any, sorter: any): void => {
        const filterRequest = buildFilterRequest(filters);

        const filter: Filter = {
            pageIndex: pagination.current,
            pageSize: pagination.pageSize,
            orderByKey:
                sorter?.columnKey && sorter?.order
                    ? sorter.columnKey
                    : DefaultMatchFilter.orderByKey,
            orderByValue: sorter?.order ? sorter.order : DefaultMatchFilter.orderByValue,
            filterRequest,
        };

        this.getMatches(filter);
        this.setState({
            currentFilter: filter,
        });
    };

    public render(): React.ReactElement {
        const { userProfile } = this.props;
        const { matches, loading, paginationOptions, competitions } = this.state;

        const columns = [
            {
                title: 'Home team',
                dataIndex: 'homeTeamDisplayName',
                key: 'homeTeamDisplayName',
                ...getSearchFilter(),
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: (): void => {},
            },
            {
                title: 'Score',
                render: (value: MatchVm): string =>
                    value &&
                    value.homeTeamGoalsTotal !== undefined &&
                    value.homeTeamGoalsTotal !== null &&
                    value.awayTeamGoalsTotal !== undefined &&
                    value.awayTeamGoalsTotal !== null
                        ? `${value.homeTeamGoalsTotal} - ${value.awayTeamGoalsTotal}`
                        : '',
            },
            {
                title: 'Away team',
                dataIndex: 'awayTeamDisplayName',
                key: 'awayTeamDisplayName',
                ...getSearchFilter(),
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: (): void => {},
            },
            {
                title: 'Date',
                dataIndex: 'startDate',
                key: 'startDate',
                ...getRadioFilter(MatchStartDateOptions),
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: (): void => {},
                render: (value: Date): string => (value ? moment(value).format('DD.MM.YYYY.') : ''),
                width: 110,
            },
            {
                title: 'Time',
                dataIndex: 'startDate',
                key: 'startDate',
                render: (value: Date): string => (value ? moment(value).format('HH:mm') : ''),
                width: 110,
            },
            {
                title: 'Competition',
                dataIndex: 'competitionDisplayName',
                key: 'competitionId',
                ...getSelectFilter(competitions),
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: (): void => {},
            },
            {
                title: 'Referees',
                dataIndex: 'referees',
                key: 'referees',
                render: (value?: OfficialVm[]): string => {
                    const strings: string[] =
                        value?.map((v: OfficialVm): string => `${v.name} ${v.surname}`) || [];

                    return strings.join(', ');
                },
            },
            {
                title: 'Delegates',
                dataIndex: 'delegates',
                key: 'delegates',
                render: (value?: OfficialVm[]): string => {
                    const strings: string[] =
                        value?.map((v: OfficialVm): string => `${v.name} ${v.surname}`) || [];

                    return strings.join(', ');
                },
            },
            {
                title: 'Venue',
                dataIndex: 'venue',
                key: 'venue',
                ...getSearchFilter(),
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: (): void => {},
            },
            {
                title: 'Round',
                dataIndex: 'round',
                key: 'round',
                ...getSearchFilter(),
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: (): void => {},
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (value?: string): string => (value ? value.replace('_', ' ') : ''),
                ...getSearchFilter(),
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: (): void => {},
            },
            {
                dataIndex: '',
                render: (value: MatchVm): React.ReactElement => {
                    const canRead = isUserAllowed(
                        userProfile,
                        ModuleName.Matches,
                        ActionOption.Read,
                        value.id
                    );

                    return (
                        <div style={{ display: 'inline-flex' }}>
                            {canRead && (
                                <Link
                                    to={Routes.ROUTE_MATCHES_READ.replace(
                                        ReplaceStrings.MATCH_ID,
                                        value.id.toString()
                                    )}
                                >
                                    Details
                                </Link>
                            )}
                            &nbsp;
                            {canRead && (
                                <Link
                                    to={Routes.ROUTE_MATCH_LOG.replace(
                                        ReplaceStrings.MATCH_ID,
                                        value.id.toString()
                                    )}
                                >
                                    Log
                                </Link>
                            )}
                        </div>
                    );
                },
                width: 86,
            },
        ];

        return (
            <Table
                columns={columns}
                loading={loading}
                dataSource={matches}
                rowKey={(record): string => (record && record.id ? record.id.toString() : '-1')}
                pagination={paginationOptions}
                onChange={this.handleTableChange}
                bordered
                size="small"
            />
        );
    }
}

export default MatchTable;
