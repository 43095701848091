import { Profile } from 'oidc-client';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { OfficialVmWithRoles } from '../../../core/models/OfficialVmWithRoles';
import { RouteParams } from '../../../core/models/RouteHelper';
import OfficialTable from './OfficialTable';

interface OwnProps {
    userProfile: Profile;
    onDelete?: (id?: number) => Promise<void>;
    officialsFromProps?: OfficialVmWithRoles[];
    shouldDisplayRolesColumn?: boolean;
    wrappedComponentRef?: any;
    federationId?: number;
    matchId?: number;
}

export type Props = OwnProps & RouteComponentProps<RouteParams>;

export default withRouter(OfficialTable);
