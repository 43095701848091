import { PlusOutlined } from '@ant-design/icons';
import { Button, Drawer, PageHeader, Tabs } from 'antd';
import React from 'react';
import PageHeaderTitle from '../../../components/page-header';
import { DrawerWidth } from '../../../config/dimensions';
import Routes from '../../../config/routes';
import ReplaceStrings from '../../../config/replaceStrings';
import { ActionOption, DrawerState, ModuleName } from '../../../core/models/enum';
import { OfficialVmWithRoles } from '../../../core/models/OfficialVmWithRoles';
import { isUserAllowed } from '../../../helpers/CheckPermissionHelper';
import { groupOfficialsByOfficialId } from '../../../helpers/CompareHelper';
import { errorWithUserMessage, success } from '../../../helpers/NotificationHelper';
import { getNewRoute, getSubPageInitialProperties } from '../../../helpers/RoutingHelper';
import {
    ApiException,
    ClubsClient,
    FederationDetailVm,
    FederationOfficialsClient,
    FederationOfficialVm,
    FederationsClient,
    OfficialVm,
} from '../../../utils/api';
import ClubForm from '../../clubs/club-form';
import ClubTable from '../../clubs/club-table';
import CompetitionForm from '../../competitions/competition-form';
import CompetitionsTable from '../../competitions/competitions-table';
import OfficialForm from '../../officials/official-form';
import OfficialTable from '../../officials/official-table';
import FederationDetails from './federation-details';
import { Props } from './index';

const { TabPane } = Tabs;

interface State {
    federation?: FederationDetailVm;
    activeTabKey: string;
    isEditMode: boolean;
    id?: string;
    drawerState: DrawerState;
    loading: boolean;
    officials?: OfficialVm[];
    selectedFederationOfficial?: FederationOfficialVm;
    isUserAllowedToCreateClub: boolean;
    isUserAllowedToCreateCompetition: boolean;
    isUserAllowedToCreateOfficial: boolean;
}

class FederationPage extends React.PureComponent<Props, State> {
    private clubTableRef: any;
    private competitionTableRef: any;
    private officialTableRef: any;

    public constructor(props: Props) {
        super(props);

        const {
            location: { pathname },
        } = window;
        this.clubTableRef = React.createRef();
        this.competitionTableRef = React.createRef();
        this.officialTableRef = React.createRef();

        const initialProperties = getSubPageInitialProperties(
            pathname,
            'federations',
            props.match,
            Routes.ROUTE_FEDERATIONS_READ,
            Routes.ROUTE_FEDERATIONS_EDIT
        );

        this.state = {
            activeTabKey: initialProperties.activeTabKey || Routes.ROUTE_FEDERATIONS_READ,
            id: initialProperties.initialEntityId,
            isEditMode: initialProperties.isInitialEditMode,
            drawerState:
                initialProperties.activeTabKey &&
                initialProperties.activeTabKey !== Routes.ROUTE_FEDERATIONS_READ &&
                initialProperties.isInitialEditMode
                    ? DrawerState.Edit
                    : DrawerState.Closed,
            loading: true,
            isUserAllowedToCreateClub: false,
            isUserAllowedToCreateCompetition: false,
            isUserAllowedToCreateOfficial: false,
        };
    }

    public componentDidMount = (): void => {
        this.getPermissions();
        this.getFederationDetails();
    };

    public componentDidUpdate = (prevProps: Props): void => {
        const {
            match: { path },
        } = this.props;

        if (path !== prevProps.match.path) {
            this.updateStateAfterRouteChanges();
        }
    };

    private getPermissions = (): void => {
        const { userProfile } = this.props;

        this.setState({
            isUserAllowedToCreateClub: isUserAllowed(
                userProfile,
                ModuleName.Clubs,
                ActionOption.Create
            ),
            isUserAllowedToCreateCompetition: isUserAllowed(
                userProfile,
                ModuleName.Competitions,
                ActionOption.Create
            ),
            isUserAllowedToCreateOfficial: isUserAllowed(
                userProfile,
                ModuleName.Officials,
                ActionOption.Create
            ),
        });
    };

    private updateStateAfterRouteChanges = (): void => {
        const { match } = this.props;
        const { federation, selectedFederationOfficial } = this.state;
        const {
            location: { pathname },
        } = window;

        const initialProperties = getSubPageInitialProperties(
            pathname,
            'federations',
            match,
            Routes.ROUTE_FEDERATIONS_READ,
            Routes.ROUTE_FEDERATIONS_EDIT
        );

        var newSelectedFederationOfficial = match.params.officialId
            ? federation?.officials.find(
                  (fo: FederationOfficialVm): boolean =>
                      fo.officialId.toString() === match.params.officialId
              )
            : selectedFederationOfficial;

        this.setState({
            activeTabKey: initialProperties.activeTabKey || Routes.ROUTE_FEDERATIONS_READ,
            id: initialProperties.initialEntityId,
            isEditMode: initialProperties.isInitialEditMode,
            drawerState:
                initialProperties.activeTabKey &&
                initialProperties.activeTabKey !== Routes.ROUTE_FEDERATIONS_READ &&
                initialProperties.isInitialEditMode
                    ? DrawerState.Edit
                    : DrawerState.Closed,
            selectedFederationOfficial: newSelectedFederationOfficial,
        });
    };

    private getFederationDetails = async (section?: string): Promise<void> => {
        const {
            match: {
                params: { federationId, officialId },
            },
        } = this.props;

        let selectedFederationOfficial: FederationOfficialVm | undefined;

        if (federationId) {
            const federationClient = new FederationsClient();
            const federationDetails = await federationClient.getById(parseInt(federationId, 10));
            if (officialId) {
                selectedFederationOfficial = federationDetails.officials.find(
                    (fo: FederationOfficialVm): boolean => fo.officialId.toString() === officialId
                );
            }

            this.setState({
                federation: federationDetails,
                selectedFederationOfficial,
            });
        }

        if (section === 'clubs' && this.clubTableRef && this.clubTableRef.current) {
            this.clubTableRef.current.getClubs();
        } else if (
            section === 'competitions' &&
            this.competitionTableRef &&
            this.competitionTableRef.current
        ) {
            this.competitionTableRef.current.getCompetitions();
        } else if (
            section === 'officials' &&
            this.officialTableRef &&
            this.officialTableRef.current
        ) {
            this.officialTableRef.current.getOfficials();
        }

        this.setState({
            loading: false,
        });
    };

    private handleTabClick = (route: string): void => {
        const {
            history,
            match: {
                params: { federationId },
            },
        } = this.props;

        this.setState({
            activeTabKey: route,
            isEditMode: false,
        });

        const newRoute = route.replace(ReplaceStrings.FEDERATION_ID, federationId);

        history.push(newRoute);
    };

    private handleDrawerMode = (drawerState: DrawerState, section?: string): void => {
        const {
            history,
            match: {
                params: { federationId },
            },
        } = this.props;

        this.setState({
            drawerState,
        });

        if (section === 'clubs') {
            history.push(
                getNewRoute(
                    Routes.ROUTE_FEDERATION_CLUBS,
                    Routes.ROUTE_FEDERATION_CLUBS,
                    Routes.ROUTE_FEDERATION_CLUBS_CREATE,
                    drawerState,
                    ReplaceStrings.FEDERATION_ID,
                    federationId
                )
            );
        } else if (section === 'competitions') {
            history.push(
                getNewRoute(
                    Routes.ROUTE_FEDERATION_COMPETITIONS,
                    Routes.ROUTE_FEDERATION_COMPETITIONS,
                    Routes.ROUTE_FEDERATION_COMPETITIONS_CREATE,
                    drawerState,
                    ReplaceStrings.FEDERATION_ID,
                    federationId
                )
            );
        } else if (section === 'officials') {
            history.push(
                getNewRoute(
                    Routes.ROUTE_FEDERATION_OFFICIALS,
                    Routes.ROUTE_FEDERATION_OFFICIALS,
                    Routes.ROUTE_FEDERATION_OFFICIALS_CREATE,
                    drawerState,
                    ReplaceStrings.FEDERATION_ID,
                    federationId
                )
            );
        }
    };

    private handleFederationOfficialDelete = async (id?: number): Promise<void> => {
        const { federation } = this.state;

        if (!id) return;

        try {
            const federationOfficials = federation?.officials || [];
            const federationOfficial = federationOfficials.find(
                (fo: FederationOfficialVm): boolean => fo.officialId === id
            );

            if (!federationOfficial?.id) return;

            const client = new FederationOfficialsClient();
            const result = await client.delete(federationOfficial.id);

            if (!result) return;
            success('Federation official successfully deleted.');

            await this.getFederationDetails();
            if (this.officialTableRef && this.officialTableRef.current) {
                this.officialTableRef.current.getOfficials();
            }
        } catch (error) {
            if (error instanceof ApiException) {
                errorWithUserMessage(error.response);
            } else {
                errorWithUserMessage('Error deleting data.');
            }
        }
    };

    private removeClub = async (id?: number): Promise<void> => {
        if (!id) return;

        try {
            const client = new ClubsClient();
            const result = await client.removeClubFromFederation(id);

            if (!result) return;
            success('Club successfully removed from federation.');

            await this.getFederationDetails();
            if (this.clubTableRef && this.clubTableRef.current) {
                this.clubTableRef.current.getClubs();
            }
        } catch (error) {
            if (error instanceof ApiException) {
                errorWithUserMessage(error.response);
            } else {
                errorWithUserMessage('Error deleting data.');
            }
        }
    };

    public render(): React.ReactElement {
        const { userProfile } = this.props;
        const {
            activeTabKey,
            federation,
            isEditMode,
            drawerState,
            loading,
            selectedFederationOfficial,
            isUserAllowedToCreateClub,
            isUserAllowedToCreateCompetition,
            isUserAllowedToCreateOfficial,
        } = this.state;

        return (
            <div>
                <PageHeader
                    title={
                        <PageHeaderTitle
                            headerTitle={
                                federation ? federation.nameUniversal || federation.name || '' : ''
                            }
                            headerPhoto={
                                federation && federation.logo ? federation.logo : undefined
                            }
                            tableName="federation"
                            imgHeight={45}
                        />
                    }
                    key="title"
                    style={{ padding: '16px 0px' }}
                />
                <div className="tab-padding">
                    <div
                        style={{
                            overflow: 'hidden',
                            width: '100%',
                            transition: 'width .3s cubic-bezier',
                        }}
                    >
                        <Tabs
                            style={{ width: '100%' }}
                            activeKey={activeTabKey}
                            onTabClick={(key: string): void => this.handleTabClick(key)}
                        >
                            <TabPane tab="Details" key={Routes.ROUTE_FEDERATIONS_READ}>
                                <div>
                                    {federation && (
                                        <FederationDetails
                                            userProfile={userProfile}
                                            isInitialEdit={isEditMode}
                                            federation={federation}
                                            refreshAfterSave={this.getFederationDetails}
                                        />
                                    )}
                                </div>
                            </TabPane>
                            <TabPane tab="Clubs" key={Routes.ROUTE_FEDERATION_CLUBS}>
                                <div>
                                    <PageHeader
                                        title=""
                                        extra={
                                            isUserAllowedToCreateClub
                                                ? [
                                                      <Button
                                                          key="1"
                                                          type="primary"
                                                          style={{
                                                              zIndex: 10,
                                                              float: 'right',
                                                          }}
                                                          onClick={(): void =>
                                                              this.handleDrawerMode(
                                                                  DrawerState.Edit,
                                                                  'clubs'
                                                              )
                                                          }
                                                      >
                                                          <PlusOutlined className="button-icon-display" />
                                                          Add club
                                                      </Button>,
                                                  ]
                                                : []
                                        }
                                        key="federation-clubs"
                                        style={{ padding: '16px 0px' }}
                                    />
                                    {federation &&
                                    federation.clubs &&
                                    federation.clubs.length > 0 ? (
                                        <ClubTable
                                            userProfile={userProfile}
                                            clubsFromProps={federation.clubs}
                                            wrappedComponentRef={this.clubTableRef}
                                            additionalActionTitle="Remove"
                                            additionalAction={this.removeClub}
                                        />
                                    ) : (
                                        <div>There are no clubs in this federation.</div>
                                    )}
                                </div>
                            </TabPane>
                            <TabPane tab="Competitions" key={Routes.ROUTE_FEDERATION_COMPETITIONS}>
                                <div>
                                    <PageHeader
                                        title=""
                                        extra={
                                            isUserAllowedToCreateCompetition
                                                ? [
                                                      <Button
                                                          type="primary"
                                                          style={{
                                                              zIndex: 10,
                                                              float: 'right',
                                                          }}
                                                          onClick={(): void =>
                                                              this.handleDrawerMode(
                                                                  DrawerState.Edit,
                                                                  'competitions'
                                                              )
                                                          }
                                                          key="2"
                                                      >
                                                          <PlusOutlined className="button-icon-display" />
                                                          Add competition
                                                      </Button>,
                                                  ]
                                                : []
                                        }
                                        key="federation-competitions"
                                        style={{ padding: '16px 0px' }}
                                    />
                                    {federation &&
                                    federation.competitions &&
                                    federation.competitions.length > 0 ? (
                                        <CompetitionsTable
                                            userProfile={userProfile}
                                            competitionsFromProps={federation.competitions}
                                            wrappedComponentRef={this.competitionTableRef}
                                        />
                                    ) : (
                                        <div>There are no competitions in this federation.</div>
                                    )}
                                </div>
                            </TabPane>
                            <TabPane tab="Officials" key={Routes.ROUTE_FEDERATION_OFFICIALS}>
                                <div>
                                    <PageHeader
                                        title=""
                                        extra={
                                            isUserAllowedToCreateOfficial
                                                ? [
                                                      <Button
                                                          key="3"
                                                          type="primary"
                                                          style={{
                                                              zIndex: 10,
                                                              float: 'right',
                                                          }}
                                                          onClick={(): void =>
                                                              this.handleDrawerMode(
                                                                  DrawerState.Edit,
                                                                  'officials'
                                                              )
                                                          }
                                                      >
                                                          <PlusOutlined className="button-icon-display" />
                                                          Add official
                                                      </Button>,
                                                  ]
                                                : []
                                        }
                                        key="federation-officials"
                                        style={{ padding: '16px 0px' }}
                                    />
                                    {federation &&
                                    federation.officials &&
                                    federation.officials.length > 0 ? (
                                        <OfficialTable
                                            userProfile={userProfile}
                                            officialsFromProps={
                                                groupOfficialsByOfficialId(
                                                    federation.officials
                                                ) as OfficialVmWithRoles[]
                                            }
                                            wrappedComponentRef={this.officialTableRef}
                                            shouldDisplayRolesColumn
                                            onDelete={this.handleFederationOfficialDelete}
                                            federationId={federation.id}
                                        />
                                    ) : (
                                        <div>There are no officials in this federation.</div>
                                    )}
                                </div>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
                <Drawer
                    title={
                        activeTabKey === Routes.ROUTE_FEDERATION_CLUBS
                            ? 'Add club'
                            : activeTabKey === Routes.ROUTE_FEDERATION_COMPETITIONS
                            ? 'Add competition'
                            : selectedFederationOfficial
                            ? 'Edit official'
                            : 'Add official'
                    }
                    visible={!!drawerState}
                    onClose={(): void =>
                        this.handleDrawerMode(
                            DrawerState.Closed,
                            activeTabKey === Routes.ROUTE_FEDERATION_CLUBS
                                ? 'clubs'
                                : activeTabKey === Routes.ROUTE_FEDERATION_COMPETITIONS
                                ? 'competitions'
                                : 'officials'
                        )
                    }
                    width={DrawerWidth}
                    destroyOnClose
                >
                    {activeTabKey === Routes.ROUTE_FEDERATION_CLUBS &&
                        federation &&
                        federation.id &&
                        !loading && (
                            <ClubForm
                                handleClose={(): void =>
                                    this.handleDrawerMode(DrawerState.Closed, 'clubs')
                                }
                                refreshAfterSave={(): Promise<void> =>
                                    this.getFederationDetails('clubs')
                                }
                                initialValues={{ federationId: federation.id }}
                            />
                        )}
                    {activeTabKey === Routes.ROUTE_FEDERATION_COMPETITIONS &&
                        federation &&
                        federation.id &&
                        !loading && (
                            <CompetitionForm
                                handleClose={(): void =>
                                    this.handleDrawerMode(DrawerState.Closed, 'competitions')
                                }
                                refreshAfterSave={(): Promise<void> =>
                                    this.getFederationDetails('competitions')
                                }
                                initialValues={{ federationId: federation.id }}
                            />
                        )}
                    {activeTabKey === Routes.ROUTE_FEDERATION_OFFICIALS &&
                        federation &&
                        federation.id &&
                        !loading && (
                            <OfficialForm
                                handleClose={(): void => {
                                    this.handleDrawerMode(DrawerState.Closed, 'officials');
                                    this.setState({
                                        selectedFederationOfficial: undefined,
                                    });
                                }}
                                refreshAfterSave={(): Promise<void> =>
                                    this.getFederationDetails('officials')
                                }
                                initialValues={{
                                    federationId: federation.id,
                                    officials:
                                        federation.officials && federation.officials.length > 0
                                            ? federation.officials
                                            : undefined,
                                }}
                                federationOfficial={selectedFederationOfficial}
                            />
                        )}
                </Drawer>
            </div>
        );
    }
}

export default FederationPage;
