import { PlusOutlined } from '@ant-design/icons';
import { Button, Drawer, PageHeader, Tabs } from 'antd';
import React from 'react';
import PageHeaderTitle from '../../../components/page-header';
import { DrawerWidth } from '../../../config/dimensions';
import Routes from '../../../config/routes';
import ReplaceStrings from '../../../config/replaceStrings';
import { ActionOption, DrawerState, ModuleName } from '../../../core/models/enum';
import { isUserAllowed } from '../../../helpers/CheckPermissionHelper';
import { errorWithUserMessage, success } from '../../../helpers/NotificationHelper';
import { getNewRoute, getSubPageInitialProperties } from '../../../helpers/RoutingHelper';
import {
    transformCoachTeamsToCoaches,
    transformHomeAwayMatchesInOne,
    transformPlayerTeamsToPlayers,
    transformTeamCompetitionsToCompetitions,
} from '../../../helpers/TransformHelper';
import {
    ApiException,
    CompetitionVm,
    MatchVm,
    TeamCoachesClient,
    TeamCoachVm,
    TeamDetailVm,
    TeamPlayersClient,
    TeamPlayerVm,
    TeamsClient,
} from '../../../utils/api';
import CoachForm from '../../coaches/coach-form';
import CoachTable from '../../coaches/coach-table';
import CompetitionTable from '../../competitions/competitions-table';
import MatchTable from '../../matches/match-table';
import PlayerForm from '../../players/player-form';
import PlayerTable from '../../players/player-table';
import TeamDetails from './team-details';
import { Props } from './index';

const { TabPane } = Tabs;

interface State {
    team?: TeamDetailVm;
    competitions?: CompetitionVm[];
    matches?: MatchVm[];
    activeTabKey: string;
    isEditMode: boolean;
    id?: string;
    loading: boolean;
    drawerState: DrawerState;
    selectedTeamPlayer?: TeamPlayerVm;
    selectedTeamCoach?: TeamCoachVm;
    isUserAllowedToCreateCoach: boolean;
    isUserAllowedToCreatePlayer: boolean;
}

class TeamPage extends React.PureComponent<Props, State> {
    private coachTableRef: any;
    private playerTableRef: any;

    public constructor(props: Props) {
        super(props);

        const {
            location: { pathname },
        } = window;
        this.coachTableRef = React.createRef();
        this.playerTableRef = React.createRef();

        const initialProperties = getSubPageInitialProperties(
            pathname,
            'teams',
            props.match,
            Routes.ROUTE_TEAMS_READ,
            Routes.ROUTE_TEAMS_EDIT
        );

        this.state = {
            activeTabKey: initialProperties.activeTabKey || Routes.ROUTE_TEAMS_READ,
            id: initialProperties.initialEntityId,
            isEditMode: initialProperties.isInitialEditMode,
            loading: true,
            drawerState:
                initialProperties.activeTabKey &&
                initialProperties.activeTabKey !== Routes.ROUTE_TEAMS_READ &&
                initialProperties.isInitialEditMode
                    ? DrawerState.Edit
                    : DrawerState.Closed,
            isUserAllowedToCreateCoach: false,
            isUserAllowedToCreatePlayer: false,
        };
    }

    public componentDidMount = (): void => {
        this.getPermissions();
        this.getTeamDetails();
    };

    public componentDidUpdate = (prevProps: Props): void => {
        const {
            match: { path },
        } = this.props;

        if (path !== prevProps.match.path) {
            this.updateStateAfterRouteChanges();
        }
    };

    private getPermissions = (): void => {
        const { userProfile } = this.props;

        this.setState({
            isUserAllowedToCreateCoach: isUserAllowed(
                userProfile,
                ModuleName.Coaches,
                ActionOption.Create
            ),
            isUserAllowedToCreatePlayer: isUserAllowed(
                userProfile,
                ModuleName.Players,
                ActionOption.Create
            ),
        });
    };

    private updateStateAfterRouteChanges = (): void => {
        const { match } = this.props;
        const { team, selectedTeamCoach, selectedTeamPlayer } = this.state;
        const {
            location: { pathname },
        } = window;

        const initialProperties = getSubPageInitialProperties(
            pathname,
            'teams',
            match,
            Routes.ROUTE_TEAMS_READ,
            Routes.ROUTE_TEAMS_EDIT
        );

        var newSelectedTeamCoach = match.params.coachId
            ? team?.coaches.find(
                  (tc: TeamCoachVm): boolean => tc.coachId.toString() === match.params.coachId
              )
            : selectedTeamCoach;

        var newSelectedTeamPlayer = match.params.playerId
            ? team?.players.find(
                  (tp: TeamPlayerVm): boolean => tp.playerId.toString() === match.params.playerId
              )
            : selectedTeamPlayer;

        this.setState({
            activeTabKey: initialProperties.activeTabKey || Routes.ROUTE_TEAMS_READ,
            id: initialProperties.initialEntityId,
            isEditMode: initialProperties.isInitialEditMode,
            drawerState:
                initialProperties.activeTabKey &&
                initialProperties.activeTabKey !== Routes.ROUTE_TEAMS_READ &&
                initialProperties.isInitialEditMode
                    ? DrawerState.Edit
                    : DrawerState.Closed,
            selectedTeamCoach: newSelectedTeamCoach,
            selectedTeamPlayer: newSelectedTeamPlayer,
        });
    };

    private getTeamDetails = async (section?: string): Promise<void> => {
        const {
            match: {
                params: { teamId, coachId, playerId },
            },
        } = this.props;

        if (teamId) {
            const teamClient = new TeamsClient();
            const teamDetails = await teamClient.getById(parseInt(teamId, 10));

            if (coachId) {
                var selectedTeamCoach = teamDetails.coaches.find(
                    (tc: TeamCoachVm): boolean => tc.coachId.toString() === coachId
                );
            }
            if (playerId) {
                var selectedTeamPlayer = teamDetails.players.find(
                    (tp: TeamPlayerVm): boolean => tp.playerId.toString() === playerId
                );
            }

            this.setState({
                team: teamDetails,
                selectedTeamCoach,
                selectedTeamPlayer,
            });

            if (teamDetails) {
                const competitions = transformTeamCompetitionsToCompetitions(
                    teamDetails.competitionTeams
                );
                const matches = transformHomeAwayMatchesInOne(
                    teamDetails.homeMatches,
                    teamDetails.awayMatches
                );
                this.setState({
                    competitions,
                    matches,
                });
            }
        }

        if (section === 'coaches' && this.coachTableRef && this.coachTableRef.current) {
            this.coachTableRef.current.getCoaches();
        } else if (section === 'players' && this.playerTableRef && this.playerTableRef.current) {
            this.playerTableRef.current.getPlayers();
        }

        this.setState({
            loading: false,
        });
    };

    private handleTabClick = (route: string): void => {
        const {
            history,
            match: {
                params: { teamId },
            },
        } = this.props;

        this.setState({
            activeTabKey: route,
            isEditMode: false,
        });

        const newRoute = route.replace(ReplaceStrings.TEAM_ID, teamId);

        history.push(newRoute);
    };

    private handleDrawerMode = (drawerState: DrawerState, section?: string): void => {
        const {
            history,
            match: {
                params: { teamId },
            },
        } = this.props;

        this.setState({
            drawerState,
        });

        if (section === 'coaches') {
            history.push(
                getNewRoute(
                    Routes.ROUTE_TEAM_COACHES,
                    Routes.ROUTE_TEAM_COACHES,
                    Routes.ROUTE_TEAM_COACHES_CREATE,
                    drawerState,
                    ReplaceStrings.TEAM_ID,
                    teamId
                )
            );
        } else if (section === 'players') {
            history.push(
                getNewRoute(
                    Routes.ROUTE_TEAM_PLAYERS,
                    Routes.ROUTE_TEAM_PLAYERS,
                    Routes.ROUTE_TEAM_PLAYERS_CREATE,
                    drawerState,
                    ReplaceStrings.TEAM_ID,
                    teamId
                )
            );
        }
    };

    private handleTeamPlayerDelete = async (id?: number): Promise<void> => {
        const { team } = this.state;

        if (!id) return;

        try {
            const teamPlayers = team?.players || [];
            const teamPlayer = teamPlayers.find((cp: TeamPlayerVm): boolean => cp.playerId === id);

            if (!teamPlayer?.id) return;

            const client = new TeamPlayersClient();
            const result = await client.delete(teamPlayer.id);

            if (!result) return;
            success('Team player successfully deleted.');

            await this.getTeamDetails();
            if (this.playerTableRef && this.playerTableRef.current) {
                this.playerTableRef.current.getPlayers();
            }
        } catch (error) {
            if (error instanceof ApiException) {
                errorWithUserMessage(error.response);
            } else {
                errorWithUserMessage('Error deleting data.');
            }
        }
    };

    private handleTeamCoachDelete = async (id?: number): Promise<void> => {
        const { team } = this.state;

        if (!id) return;

        try {
            const teamCoaches = team?.coaches || [];
            const teamCoach = teamCoaches.find((cc: TeamCoachVm): boolean => cc.coachId === id);

            if (!teamCoach?.id) return;
            const client = new TeamCoachesClient();
            const result = await client.delete(teamCoach.id);

            if (!result) return;
            success('Team coach successfully deleted.');

            await this.getTeamDetails();
            if (this.coachTableRef && this.coachTableRef.current) {
                this.coachTableRef.current.getCoaches();
            }
        } catch (error) {
            if (error instanceof ApiException) {
                errorWithUserMessage(error.response);
            } else {
                errorWithUserMessage('Error deleting data.');
            }
        }
    };

    public render(): React.ReactElement {
        const { userProfile } = this.props;
        const {
            activeTabKey,
            team,
            isEditMode,
            competitions,
            matches,
            drawerState,
            loading,
            selectedTeamCoach,
            selectedTeamPlayer,
            isUserAllowedToCreateCoach,
            isUserAllowedToCreatePlayer,
        } = this.state;

        return (
            <div>
                <PageHeader
                    title={
                        <PageHeaderTitle
                            headerTitle={team ? team.nameUniversal || team.name || '' : ''}
                            headerPhoto={team && team.logo ? team.logo : undefined}
                            tableName="team"
                            imgHeight={45}
                        />
                    }
                    style={{ padding: '16px 0px' }}
                />
                <div className="tab-padding">
                    <div
                        style={{
                            overflow: 'hidden',
                            width: '100%',
                            transition: 'width .3s cubic-bezier',
                        }}
                    >
                        <Tabs
                            style={{ width: '100%' }}
                            activeKey={activeTabKey}
                            onTabClick={(key: string): void => this.handleTabClick(key)}
                        >
                            <TabPane tab="Details" key={Routes.ROUTE_TEAMS_READ}>
                                <div>
                                    {team && (
                                        <TeamDetails
                                            userProfile={userProfile}
                                            isInitialEdit={isEditMode}
                                            team={team}
                                            refreshAfterSave={this.getTeamDetails}
                                        />
                                    )}
                                </div>
                            </TabPane>
                            <TabPane tab="Competitions" key={Routes.ROUTE_TEAM_COMPETITIONS}>
                                {competitions && competitions.length > 0 ? (
                                    <CompetitionTable
                                        userProfile={userProfile}
                                        competitionsFromProps={competitions}
                                    />
                                ) : (
                                    <div>The team does not compete in any competition.</div>
                                )}
                            </TabPane>
                            <TabPane tab="Matches" key={Routes.ROUTE_TEAM_MATCHES}>
                                {matches && matches.length > 0 ? (
                                    <MatchTable
                                        userProfile={userProfile}
                                        matchesFromProps={matches}
                                    />
                                ) : (
                                    <div>There are no matches for this team.</div>
                                )}
                            </TabPane>
                            <TabPane tab="Coaches" key={Routes.ROUTE_TEAM_COACHES}>
                                <div>
                                    <PageHeader
                                        title=""
                                        extra={
                                            isUserAllowedToCreateCoach
                                                ? [
                                                      <Button
                                                          key="1"
                                                          type="primary"
                                                          style={{
                                                              zIndex: 10,
                                                              float: 'right',
                                                          }}
                                                          onClick={(): void =>
                                                              this.handleDrawerMode(
                                                                  DrawerState.Edit,
                                                                  'coaches'
                                                              )
                                                          }
                                                      >
                                                          <PlusOutlined className="button-icon-display" />
                                                          Add coach
                                                      </Button>,
                                                  ]
                                                : []
                                        }
                                        style={{ padding: '16px 0px' }}
                                    />
                                    {team && team.coaches && team.coaches.length > 0 ? (
                                        <CoachTable
                                            userProfile={userProfile}
                                            onDelete={this.handleTeamCoachDelete}
                                            coachesFromProps={transformCoachTeamsToCoaches(
                                                team.coaches
                                            )}
                                            wrappedComponentRef={this.coachTableRef}
                                            teamId={team.id}
                                        />
                                    ) : (
                                        <div>There are no coaches in this team.</div>
                                    )}
                                </div>
                            </TabPane>
                            <TabPane tab="Players" key={Routes.ROUTE_TEAM_PLAYERS}>
                                <div>
                                    <PageHeader
                                        title=""
                                        extra={
                                            isUserAllowedToCreatePlayer
                                                ? [
                                                      <Button
                                                          key="1"
                                                          type="primary"
                                                          style={{
                                                              zIndex: 10,
                                                              float: 'right',
                                                          }}
                                                          onClick={(): void =>
                                                              this.handleDrawerMode(
                                                                  DrawerState.Edit,
                                                                  'players'
                                                              )
                                                          }
                                                      >
                                                          <PlusOutlined className="button-icon-display" />
                                                          Add player
                                                      </Button>,
                                                  ]
                                                : []
                                        }
                                        style={{ padding: '16px 0px' }}
                                    />
                                    {team && team.players && team.players.length > 0 ? (
                                        <PlayerTable
                                            userProfile={userProfile}
                                            onDelete={this.handleTeamPlayerDelete}
                                            playersFromProps={transformPlayerTeamsToPlayers(
                                                team.players
                                            )}
                                            wrappedComponentRef={this.playerTableRef}
                                            teamId={team.id}
                                        />
                                    ) : (
                                        <div>There are no players in this team.</div>
                                    )}
                                </div>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
                <Drawer
                    title={
                        activeTabKey === Routes.ROUTE_TEAM_COACHES
                            ? selectedTeamCoach
                                ? 'Edit coach'
                                : 'Add coach'
                            : selectedTeamPlayer
                            ? 'Edit player'
                            : 'Add player'
                    }
                    visible={!!drawerState}
                    onClose={(): void =>
                        this.handleDrawerMode(
                            DrawerState.Closed,
                            activeTabKey === Routes.ROUTE_TEAM_COACHES ? 'coaches' : 'players'
                        )
                    }
                    width={DrawerWidth}
                    destroyOnClose
                >
                    {activeTabKey === Routes.ROUTE_TEAM_COACHES && team && !loading && (
                        <CoachForm
                            handleClose={(): void => {
                                this.handleDrawerMode(DrawerState.Closed, 'coaches');
                                this.setState({
                                    selectedTeamCoach: undefined,
                                });
                            }}
                            refreshAfterSave={(): Promise<void> => this.getTeamDetails('coaches')}
                            initialValues={{
                                teamId: team && team.id ? team.id : undefined,
                                coaches:
                                    team && team.coaches && team.coaches.length > 0
                                        ? transformCoachTeamsToCoaches(team.coaches)
                                        : undefined,
                            }}
                            teamCoach={selectedTeamCoach}
                        />
                    )}
                    {activeTabKey === Routes.ROUTE_TEAM_PLAYERS && team && !loading && (
                        <PlayerForm
                            handleClose={(): void => {
                                this.handleDrawerMode(DrawerState.Closed, 'players');
                                this.setState({
                                    selectedTeamPlayer: undefined,
                                });
                            }}
                            refreshAfterSave={(): Promise<void> => this.getTeamDetails('players')}
                            initialValues={{
                                teamId: team && team.id ? team.id : undefined,
                                players:
                                    team && team.players && team.players.length > 0
                                        ? transformPlayerTeamsToPlayers(team.players)
                                        : undefined,
                            }}
                            teamPlayer={selectedTeamPlayer}
                        />
                    )}
                </Drawer>
            </div>
        );
    }
}

export default TeamPage;
