import { CreateMatchPlayerCommandOfMatchPlayer, UpdateEventCommand } from '../../../../utils/api';
import SwimOff from './SwimOff';

interface OwnProps {
    isHomeTeam: boolean;
    homeTeamId?: number;
    event: UpdateEventCommand;
    lineups: CreateMatchPlayerCommandOfMatchPlayer[];
    updateEvent: (event: UpdateEventCommand) => void;
}

export type Props = OwnProps;

export default SwimOff;
