import { CompareFn } from 'antd/lib/table/interface';
import { DropdownOption } from '../core/models/DropdownOption';
import { OfficialVmWithRoles } from '../core/models/OfficialVmWithRoles';
import { FederationOfficialRoleVm, FederationOfficialVm, MatchOfficialVm } from '../utils/api';
import { transformMatchRoleOfficialToOfficialWithRoles, transformRoleOfficialToOfficialWithRoles } from './TransformHelper';
import { get } from './UtilityHelper';

export const compare = (
    firstValue: string | number | undefined,
    secondValue: string | number | undefined
): number => {
    let comparison = 0;

    if (firstValue && secondValue) {
        if (firstValue > secondValue) {
            comparison = 1;
        } else if (firstValue < secondValue) {
            comparison = -1;
        }
        return comparison;
    } else if (!firstValue && secondValue) {
        return -1;
    } else if (firstValue && !secondValue) {
        return 1;
    } else {
        return 0;
    }
};

export const reverseCompare = (
    firstValue: string | number | undefined,
    secondValue: string | number | undefined
): number => {
    let comparison = 0;

    if (firstValue && secondValue) {
        if (firstValue < secondValue) {
            comparison = 1;
        } else if (firstValue > secondValue) {
            comparison = -1;
        }
        return comparison;
    } else if (!firstValue && secondValue) {
        return 1;
    } else if (firstValue && !secondValue) {
        return -1;
    } else {
        return 0;
    }
};

export const compareDates = (
    firstValue: Date | undefined,
    secondValue: Date | undefined
): number => {
    let comparison = 0;

    if (firstValue && secondValue) {
        if ((firstValue.getTime() - secondValue.getTime()) > 0) {
            comparison = 1;
        } else if ((secondValue.getTime() - firstValue.getTime()) > 0) {
            comparison = -1;
        }
        return comparison;
    } else if (!firstValue && secondValue) {
        return -1;
    } else if (firstValue && !secondValue) {
        return 1;
    } else {
        return 0;
    }
};

export const reverseCompareDates = (
    firstValue: Date | undefined,
    secondValue: Date | undefined
): number => {
    let comparison = 0;

    if (firstValue && secondValue) {
        if ((firstValue.getTime() - secondValue.getTime()) > 0) {
            comparison = -1;
        } else if ((secondValue.getTime() - firstValue.getTime()) > 0) {
            comparison = 1;
        }
        return comparison;
    } else if (!firstValue && secondValue) {
        return 1;
    } else if (firstValue && !secondValue) {
        return -1;
    } else {
        return 0;
    }
};

export const checkIfStringIsInArray = (
    value: string,
    array: DropdownOption[]
): DropdownOption[] | undefined => {
    return array.filter(
        (a: DropdownOption): boolean =>
            a.searchableName !== undefined && a.searchableName.toLowerCase() === value.toLowerCase()
    );
};

export const groupOfficialsByOfficialId = (
    inputOfficials: FederationOfficialVm[]
): OfficialVmWithRoles[] => {
    const newOfficials: OfficialVmWithRoles[] = [];

    inputOfficials.forEach((iO: FederationOfficialVm): void => {
        const index = newOfficials.findIndex(
            (nO: OfficialVmWithRoles): boolean => nO.id === iO.officialId
        );
        if (index > -1) {
            const selectedOfficial = newOfficials[index];
            const newRoles: string[] = iO.roles.map(
                (r: FederationOfficialRoleVm): string => r.role
            );
            newOfficials[index] = {
                ...newOfficials[index],
                roles: selectedOfficial.roles ? [...selectedOfficial.roles, ...newRoles] : newRoles,
            } as OfficialVmWithRoles;
        } else {
            newOfficials.push(transformRoleOfficialToOfficialWithRoles(iO));
        }
    });

    return newOfficials;
};

export const groupMatchOfficialsByOfficialId = (
    inputOfficials: MatchOfficialVm[]
): OfficialVmWithRoles[] => {
    const newOfficials: OfficialVmWithRoles[] = [];

    inputOfficials.forEach((iO: MatchOfficialVm): void => {
        const index = newOfficials.findIndex(
            (nO: OfficialVmWithRoles): boolean => nO.id === iO.officialId
        );
        if (index > -1) {
            const selectedOfficial = newOfficials[index];
            newOfficials[index] = {
                ...newOfficials[index],
                roles: selectedOfficial.roles ? [...selectedOfficial.roles, iO.role] : [iO.role],
            } as OfficialVmWithRoles;
        } else {
            newOfficials.push(transformMatchRoleOfficialToOfficialWithRoles(iO));
        }
    });

    return newOfficials;
};

/**
 * Returns a string compare function.
 *
 * @param path - The path of the property
 * @returns A string compare function for property specified by the path
 */
export const getDefaultComparer = (path: string): CompareFn<any> => {
    return (a: any, b: any) => compare(get(a, path)?.toLowerCase(), get(b, path)?.toLowerCase());
};

/**
 * Returns a number compare function.
 *
 * @param path - The path of the property
 * @returns A number compare function for property specified by the path
 */
export const getNumberComparer = (path: string): CompareFn<any> => {
    return (a: any, b: any) => compare(get(a, path), get(b, path));
};
