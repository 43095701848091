import { Profile } from 'oidc-client';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RouteParams } from '../../../core/models/RouteHelper';
import { MatchOfficialVm } from '../../../utils/api';
import OfficialMatchTable from './OfficialMatchTable';

interface OwnProps {
    userProfile: Profile;
    wrappedComponentRef?: any;
    matchesFromProps?: MatchOfficialVm[];
}

export type Props = OwnProps & RouteComponentProps<RouteParams>;

export default withRouter(OfficialMatchTable);
