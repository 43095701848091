import { DropdownOption } from '../core/models/DropdownOption';

export const customArrayValidationHelper = (
    _rule: any,
    value: any,
    callback: any,
    message: string,
    entityToValidate?: DropdownOption[]
): void => {
    if (!entityToValidate || entityToValidate.length === 0) {
        callback(message);
    } else {
        callback();
    }
};

export const customStringValidationHelper = (
    _rule: any,
    value: any,
    callback: any,
    message: string,
    entityToValidate?: string
): void => {
    if (!entityToValidate) {
        callback(message);
    } else {
        callback();
    }
};
