import { DatePicker, Form } from 'antd';
import moment from 'moment';
import React from 'react';
import AutocompleteDropdown from '../../../../components/autocomplete-dropdown';
import DrawerButtons from '../../../../components/drawer-buttons/index';
import { DropdownOption } from '../../../../core/models/DropdownOption';
import { DrawerState } from '../../../../core/models/enum';
import {
    confirmDropdownHelper,
    filterDropdownOptions,
} from '../../../../helpers/AutocompleteDropdownHelper';
import { convertUTCTimeToLocal } from '../../../../helpers/DateHelper';
import { getFederationsToDropdownOptions } from '../../../../helpers/FetchAndTransformHelpers';
import { formItemLayout724 } from '../../../../helpers/FormLayoutHelper';
import { success } from '../../../../helpers/NotificationHelper';
import { createHelper, updateHelper } from '../../../../helpers/SubmitHelper';
import { transformEnumToDropdownOptions } from '../../../../helpers/TransformHelper';
import { customArrayValidationHelper } from '../../../../helpers/ValidationHelper';
import {
    FederationOfficialRoleVm,
    FederationOfficialsClient,
    OfficialRoleEnum,
} from '../../../../utils/api';
import { Props } from './index';

interface State {
    isSaving: boolean;
    federations: DropdownOption[];
    roles: DropdownOption[];
    selectedFederation?: DropdownOption[];
    selectedRoles?: DropdownOption[];
}

class OfficialRoleForm extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            isSaving: false,
            federations: [],
            roles: [],
        };
    }

    public componentDidMount = (): void => {
        this.getFederations();
        this.getRoles();
    };

    private getFederations = async (): Promise<void> => {
        const { initialValues } = this.props;
        const results = await getFederationsToDropdownOptions();

        const filteredResults = results.entities.map(
            (e: DropdownOption): DropdownOption => {
                const isAlreadyInList =
                    initialValues && initialValues.federationIds
                        ? initialValues.federationIds.find(
                              (fI: number): boolean => fI.toString() === e.id
                          )
                        : undefined;
                return {
                    id: e.id,
                    name: e.name,
                    disabled: !!isAlreadyInList,
                };
            }
        );

        this.setState({
            federations: filteredResults,
        });
    };

    private getRoles = async (): Promise<void> => {
        const { officialRole } = this.props;

        const officialRoles = transformEnumToDropdownOptions(OfficialRoleEnum);
        const roles = officialRoles;

        const selectedRoles =
            officialRole && officialRole.roles
                ? officialRole.roles.map(
                      (r: FederationOfficialRoleVm): DropdownOption => ({
                          id: r.roleId.toString(),
                          name: r.role.replace('_', ' '),
                      })
                  )
                : undefined;

        this.setState({
            roles,
            selectedRoles,
        });
    };

    private confirmFederation = (options: DropdownOption[]): void => {
        this.setState(
            {
                selectedFederation: confirmDropdownHelper(options),
                selectedRoles: undefined,
            },
            (): Promise<void> => this.getRoles()
        );
    };

    private onSubmit = (values: any): void => {
        const { selectedFederation, selectedRoles } = this.state;
        const {
            match: {
                params: { officialId },
            },
            officialRole,
            initialValues,
        } = this.props;
        const federationOfficialClient = new FederationOfficialsClient();

        this.setState({
            isSaving: true,
        });

        const request = {
            id: officialRole ? officialRole.id : undefined,
            officialId: initialValues && initialValues ? initialValues.officialId : officialId,
            federationId:
                selectedFederation && selectedFederation.length > 0
                    ? parseInt(selectedFederation[0].id, 10)
                    : undefined,
            roles:
                selectedRoles && selectedRoles.length > 0
                    ? selectedRoles.map(
                          (sR: DropdownOption): OfficialRoleEnum => parseInt(sR.id, 10)
                      )
                    : [],
            activeFrom: values.activeFrom,
            activeTo: values.activeTo,
        };

        if (request.id) {
            updateHelper(federationOfficialClient, request, this.handleSuccessfullySaving);
        } else {
            createHelper(federationOfficialClient, request, this.handleSuccessfullySaving);
        }

        this.setState({
            isSaving: false,
        });
    };

    private handleSuccessfullySaving = (): void => {
        const {
            match: {
                params: { officialId },
            },
            handleClose,
            refreshAfterSave,
        } = this.props;

        success('Official successfully saved.');
        refreshAfterSave();
        handleClose(DrawerState.Closed, officialId);
    };

    public render(): React.ReactElement {
        const { isSaving, selectedFederation, selectedRoles, federations, roles } = this.state;
        const {
            match: {
                params: { officialId },
            },
            handleClose,
            officialRole,
        } = this.props;

        return (
            <Form onFinish={this.onSubmit} {...formItemLayout724}>
                <DrawerButtons
                    isSaving={isSaving}
                    onCancelAction={(): void => handleClose(DrawerState.Closed, officialId)}
                />
                <div className="drawer-content">
                    {!officialRole && (
                        <Form.Item
                            name="federationId"
                            label="Federation"
                            rules={[
                                {
                                    required: true,
                                    validator: (_rule: any, value: any, callback: any): void =>
                                        customArrayValidationHelper(
                                            _rule,
                                            value,
                                            callback,
                                            'Federation field is required!',
                                            selectedFederation
                                        ),
                                },
                            ]}
                        >
                            <AutocompleteDropdown
                                placeholder="Choose federation"
                                getOptionsFrontend={(value: string): DropdownOption[] =>
                                    filterDropdownOptions(value, federations)
                                }
                                initialValues={selectedFederation}
                                confirmDirty={(options: DropdownOption[]): void =>
                                    this.confirmFederation(options)
                                }
                                style={{ width: '100%' }}
                                isAllowedToClear
                            />
                        </Form.Item>
                    )}
                    <Form.Item
                        name="roleId"
                        label="Roles"
                        rules={[
                            {
                                required: true,
                                validator: (_rule: any, value: any, callback: any): void =>
                                    customArrayValidationHelper(
                                        _rule,
                                        value,
                                        callback,
                                        'Roles field is required!',
                                        selectedRoles
                                    ),
                            },
                        ]}
                    >
                        <AutocompleteDropdown
                            placeholder="Choose roles"
                            getOptionsFrontend={(value: string): DropdownOption[] =>
                                filterDropdownOptions(value, roles)
                            }
                            initialValues={selectedRoles}
                            mode="multiple"
                            confirmDirty={(options: DropdownOption[]): void =>
                                this.setState({
                                    selectedRoles: confirmDropdownHelper(options),
                                })
                            }
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                    <Form.Item
                        name="activeFrom"
                        label="Active from"
                        initialValue={
                            officialRole && officialRole.activeFrom
                                ? moment(convertUTCTimeToLocal(officialRole.activeFrom))
                                : moment(convertUTCTimeToLocal(new Date()))
                        }
                    >
                        <DatePicker
                            format="DD.MM.YYYY."
                            style={{ width: '100%' }}
                            allowClear={false}
                        />
                    </Form.Item>
                    <Form.Item
                        name="activeTo"
                        label="Active to"
                        initialValue={
                            officialRole && officialRole.activeTo
                                ? moment(convertUTCTimeToLocal(officialRole.activeTo))
                                : undefined
                        }
                    >
                        <DatePicker format="DD.MM.YYYY." style={{ width: '100%' }} />
                    </Form.Item>
                </div>
            </Form>
        );
    }
}

export default OfficialRoleForm;
