import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, DatePicker } from 'antd';
import moment from 'moment';
import React from 'react';
import './styles.css';

interface Props {
    selectedKeys: Date[];
    confirm: Function;
    setSelectedKeys: Function;
    clearFilters: Function;
}

class DatePickerFilter extends React.Component<Props> {
    private handleSearch = (confirm: Function): void => {
        confirm();
    };

    private handleReset = (clearFilters: Function): void => {
        clearFilters();
    };

    public render = (): React.ReactElement => {
        const { selectedKeys, setSelectedKeys, clearFilters, confirm } = this.props;

        return (
            <div className="search-filter">
                <DatePicker
                    placeholder="Choose date"
                    value={moment(selectedKeys[0])}
                    onChange={(e): void => setSelectedKeys(e ? [e.toDate()] : [])}
                    style={{ width: 188 }}
                    format="DD.MM.YYYY."
                />
                <Button
                    type="primary"
                    onClick={(): void => this.handleSearch(confirm)}
                    icon={
                        <SearchOutlined
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        />
                    }
                    size="small"
                    style={{ marginLeft: 5 }}
                />
                <Button
                    onClick={(): void => this.handleReset(clearFilters)}
                    size="small"
                    icon={
                        <CloseOutlined
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        />
                    }
                    style={{ marginLeft: 5 }}
                />
            </div>
        );
    };
}

export default DatePickerFilter;
