import { RouteComponentProps, withRouter } from 'react-router-dom';
import { CoachVm } from '../../../utils/api';
import { RouteParams } from '../../../core/models/RouteHelper';
import CoachTable from './CoachTable';
import { Profile } from 'oidc-client';

interface OwnProps {
    userProfile: Profile;
    coachesFromProps?: CoachVm[];
    wrappedComponentRef?: any;
    onDelete?: (id?: number) => Promise<void>;
    clubId?: number;
    teamId?: number;
}

export type Props = OwnProps & RouteComponentProps<RouteParams>;

export default withRouter(CoachTable);
