import { Col, Drawer, Form, Input, Row } from 'antd';
import moment from 'moment';
import React from 'react';
import DetailsButtons from '../../../../components/details-buttons';
import { DrawerWidth } from '../../../../config/dimensions';
import { DrawerState, ModuleName } from '../../../../core/models/enum';
import { convertUTCTimeToLocal } from '../../../../helpers/DateHelper';
import { formItemLayout724 } from '../../../../helpers/FormLayoutHelper';
import { getNewRoute } from '../../../../helpers/RoutingHelper';
import { handleDeleteHelper } from '../../../../helpers/SubmitHelper';
import { PersonsClient } from '../../../../utils/api';
import PersonForm from '../../person-form';
import { Props } from './index';

interface State {
    drawerState: DrawerState;
    loading: boolean;
}

class PersonDetails extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            drawerState: props.isInitialEdit ? DrawerState.Edit : DrawerState.Closed,
            loading: true,
        };
    }

    private handleDrawerMode = (drawerState: DrawerState): void => {
        const { history, routeHelper } = this.props;

        this.setState({
            drawerState,
        });

        history.push(
            getNewRoute(
                routeHelper.mainRoute,
                routeHelper.readRoute,
                routeHelper.editRoute,
                drawerState,
                routeHelper.replaceString,
                routeHelper.id
            )
        );
    };

    private openForm = (): void => {
        this.handleDrawerMode(DrawerState.Edit);
    };

    private handleDelete = (): void => {
        const { history, routeHelper } = this.props;

        history.push(routeHelper.mainRoute);
    };

    public render(): React.ReactElement {
        const { userProfile, person, refreshAfterSave } = this.props;
        const { drawerState } = this.state;

        return (
            <div>
                <DetailsButtons
                    userProfile={userProfile}
                    onDeleteAction={(): Promise<void> =>
                        handleDeleteHelper(
                            new PersonsClient(),
                            'Person successfully deleted.',
                            this.handleDelete,
                            person.id
                        )
                    }
                    onEditAction={this.openForm}
                    customMessage="Deleting a person also deletes any existing coach, official and person roles and also the users access to the application. Are you sure you want to delete this person?"
                    moduleName={ModuleName.Persons}
                    entityId={person.id}
                />
                <Row className="details-height">
                    <Col span={12}>
                        <Form {...formItemLayout724}>
                            <Form.Item label="Name">
                                <Input readOnly value={person.name} />
                            </Form.Item>
                            <Form.Item label="Surname">
                                <Input readOnly value={person.surname} />
                            </Form.Item>
                            <Form.Item label="Address">
                                <Input readOnly value={person.address} />
                            </Form.Item>
                            <Form.Item label="Nationality">
                                <Input readOnly value={person.nationality} />
                            </Form.Item>
                            <Form.Item label="Country">
                                <Input readOnly value={person.countryOfBirth} />
                            </Form.Item>
                            <Form.Item label="City">
                                <Input readOnly value={person.cityOfBirth} />
                            </Form.Item>
                            <Form.Item label="Birth date">
                                <Input
                                    readOnly
                                    value={
                                        person.birthDate
                                            ? moment(
                                                  convertUTCTimeToLocal(person.birthDate)
                                              ).format('DD.MM.YYYY.')
                                            : ''
                                    }
                                />
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col span={12}>
                        <Form {...formItemLayout724}>
                            <Form.Item label="Phone">
                                <Input readOnly value={person.phone} />
                            </Form.Item>
                            <Form.Item label="Email">
                                <Input readOnly value={person.email} />
                            </Form.Item>
                            <Form.Item label="Facebook">
                                <Input readOnly value={person.facebook} />
                            </Form.Item>
                            <Form.Item label="Twitter">
                                <Input readOnly value={person.twitter} />
                            </Form.Item>
                            <Form.Item label="Youtube">
                                <Input readOnly value={person.youtube} />
                            </Form.Item>
                            <Form.Item label="Instagram">
                                <Input readOnly value={person.instagram} />
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
                <Drawer
                    title="Edit profile"
                    visible={drawerState === DrawerState.Edit}
                    onClose={(): void => this.handleDrawerMode(DrawerState.Read)}
                    width={DrawerWidth}
                    destroyOnClose
                >
                    <PersonForm
                        userProfile={userProfile}
                        handleClose={this.handleDrawerMode}
                        refreshAfterSave={refreshAfterSave}
                        person={person}
                    />
                </Drawer>
            </div>
        );
    }
}

export default PersonDetails;
