import { UploadOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, Radio, Row, Upload } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import moment from 'moment';
import React from 'react';
import AutocompleteDropdown from '../../../components/autocomplete-dropdown';
import DrawerButtons from '../../../components/drawer-buttons/index';
import PersonClubs from '../../../components/person-clubs/index';
import PersonTeams from '../../../components/person-teams/index';
import { DropdownOption } from '../../../core/models/DropdownOption';
import { DrawerState, ModuleName, ProfileOption } from '../../../core/models/enum';
import { profileOptionButtons, ProfileOptionsModel } from '../../../core/models/FormProfileOptions';
import {
    confirmDropdownHelper,
    filterDropdownOptions,
    getCitiesHelper,
    getCountriesHelper,
} from '../../../helpers/AutocompleteDropdownHelper';
import {
    removeCoachClubFromListHelper,
    removeCoachTeamFromListHelper,
    updateCoachClubsHelper,
    updateCoachTeamsHelper,
} from '../../../helpers/CoachPlayerListHelper';
import { coachSubmitHelper } from '../../../helpers/CoachSubmitHelper';
import { checkIfStringIsInArray } from '../../../helpers/CompareHelper';
import { convertUTCTimeToLocal } from '../../../helpers/DateHelper';
import {
    getClubsToDropdownOptions,
    getCoachRoles,
    getTeamsToDropdownOptions,
} from '../../../helpers/FetchAndTransformHelpers';
import { onUploadFileChangeHelper } from '../../../helpers/FileHelper';
import { formItemLayout024, formItemLayout724 } from '../../../helpers/FormLayoutHelper';
import { success } from '../../../helpers/NotificationHelper';
import {
    transformPersonSuggestions,
    transformPersonToDropdownOption,
} from '../../../helpers/TransformHelper';
import {
    customArrayValidationHelper,
    customStringValidationHelper,
} from '../../../helpers/ValidationHelper';
import {
    CoachVm,
    CreateClubCoachCommandOfClubCoach,
    CreateTeamCoachCommandOfTeamCoach,
    PersonsClient,
    PersonVm,
} from '../../../utils/api';
import { Props } from './index';
import './styles.css';

interface State {
    isSaving: boolean;
    countries: DropdownOption[];
    cities: DropdownOption[];
    persons: DropdownOption[];
    clubs: DropdownOption[];
    teams: DropdownOption[];
    personVMs: PersonVm[];
    coachRoles: DropdownOption[];
    selectedCountry?: DropdownOption[];
    selectedCity?: DropdownOption[];
    selectedPerson?: DropdownOption[];
    selectedRole?: DropdownOption[];
    selectedClub?: DropdownOption[];
    selectedTeam?: DropdownOption[];
    selectedCoachTeams?: CreateTeamCoachCommandOfTeamCoach[];
    selectedCoachClubs?: CreateClubCoachCommandOfClubCoach[];
    selectedPersonVm?: PersonVm;
    selectedProfileOption?: string;
    file?: any;
    fileBase64?: string;
    name?: string;
    surname?: string;
    personSuggestions?: DropdownOption[];
}

class CoachForm extends React.PureComponent<Props, State> {
    private formRef: any;

    public constructor(props: Props) {
        super(props);

        this.formRef = React.createRef();

        this.state = {
            isSaving: false,
            countries: [],
            cities: [],
            persons: [],
            personVMs: [],
            coachRoles: [],
            clubs: [],
            teams: [],
        };
    }

    public componentDidMount = (): void => {
        this.getCountries();
        this.getCities();
        this.getPersons();
        this.getCoachRoles();
        this.getClubs();
        this.getTeams();
    };

    private getCountries = async (): Promise<void> => {
        const countryHelper = await getCountriesHelper();

        this.setState({
            countries: countryHelper.entities,
            selectedCountry: countryHelper.selectedEntity,
        });
    };

    private getCities = async (countryId?: number): Promise<void> => {
        const cityHelper = await getCitiesHelper(countryId);

        this.setState({
            cities: cityHelper.entities,
            selectedCity: cityHelper.selectedEntity,
        });
    };

    private getCoachRoles = async (): Promise<void> => {
        const { teamCoach } = this.props;
        const results = await getCoachRoles(teamCoach?.roleId);

        this.setState({
            coachRoles: results.entities,
            selectedRole: results.selectedEntity,
        });
    };

    private getClubs = async (): Promise<void> => {
        const { initialValues } = this.props;
        const results = await getClubsToDropdownOptions(initialValues && initialValues.clubId);

        this.setState({
            clubs: results.entities,
            selectedClub: results.selectedEntity,
        });
    };

    private getTeams = async (): Promise<void> => {
        const { initialValues } = this.props;
        const results = await getTeamsToDropdownOptions(initialValues && initialValues.teamId);

        this.setState({
            teams: results.entities,
            selectedTeam: results.selectedEntity,
        });
    };

    private getPersons = async (): Promise<void> => {
        const { initialValues } = this.props;
        const personClient = new PersonsClient();
        const personsVMs = await personClient.getAll();

        const persons: DropdownOption[] = [];
        const personVMs: PersonVm[] = [];

        personsVMs.forEach((p: PersonVm): void => {
            if (!(initialValues && (initialValues.clubId || initialValues.teamId)) && !p.coachId) {
                personVMs.push(p);
                persons.push(transformPersonToDropdownOption(p));
            } else if (
                initialValues &&
                (initialValues.clubId || initialValues.teamId) &&
                !p.coachId
            ) {
                personVMs.push(p);
                persons.push(transformPersonToDropdownOption(p));
            } else if (
                initialValues &&
                (initialValues.clubId || initialValues.teamId) &&
                !initialValues.coaches
            ) {
                personVMs.push(p);
                persons.push(transformPersonToDropdownOption(p));
            } else if (
                initialValues &&
                (initialValues.clubId || initialValues.teamId) &&
                initialValues.coaches &&
                p.coachId &&
                !initialValues.coaches.find((coach: CoachVm): boolean => coach.id === p.coachId)
            ) {
                personVMs.push(p);
                persons.push(transformPersonToDropdownOption(p));
            } else {
                personVMs.push(p);
                persons.push(transformPersonToDropdownOption(p, 'This person is already a coach!'));
            }
        });

        this.setState({
            persons,
            personVMs,
        });
    };

    private onSubmit = (values: any): void => {
        const {
            selectedCountry,
            selectedCity,
            selectedPerson,
            selectedProfileOption,
            fileBase64,
            selectedPersonVm,
            selectedRole,
            selectedCoachTeams,
            selectedCoachClubs,
            selectedClub,
            selectedTeam,
        } = this.state;
        const { initialValues, clubCoach, teamCoach } = this.props;

        this.setState({
            isSaving: true,
        });

        coachSubmitHelper(
            this.handleSuccessfullySaving,
            values,
            selectedCountry,
            selectedCity,
            selectedPerson,
            selectedProfileOption,
            fileBase64,
            selectedPersonVm,
            selectedRole,
            selectedCoachTeams,
            selectedCoachClubs,
            selectedClub,
            selectedTeam,
            initialValues,
            clubCoach,
            teamCoach
        );

        this.setState({
            isSaving: false,
        });
    };

    private confirmPerson = (options: DropdownOption[]): void => {
        const { personVMs } = this.state;
        const selectedPersonVm =
            options && options.length > 0
                ? personVMs.find((p: PersonVm): boolean => p.id.toString() === options[0].id)
                : undefined;

        this.setState({
            selectedPerson: confirmDropdownHelper(options),
            selectedPersonVm,
        });
    };

    private confirmExistingPerson = (option: DropdownOption): void => {
        const { personVMs } = this.state;
        const selectedPersonVm = personVMs.find(
            (p: PersonVm): boolean => p.id.toString() === option.id
        );

        this.setState({
            selectedPerson: [option],
            selectedPersonVm,
            selectedProfileOption: ProfileOption.Existing,
        });
    };

    private handleSuccessfullySaving = (): void => {
        const { handleClose, refreshAfterSave } = this.props;

        success('Coach successfully saved.');
        refreshAfterSave();
        handleClose(DrawerState.Closed);
    };

    private confirmCountry = (option: DropdownOption[]): void => {
        const selectedCountry = confirmDropdownHelper(option);
        this.setState({
            selectedCountry,
            selectedCity: undefined,
        });
        if (selectedCountry) {
            this.getCities(parseInt(selectedCountry[0].id, 10));
        }
    };

    private onUploadFileChange = async (file: File): Promise<void> => {
        const uploadHelper = await onUploadFileChangeHelper(file);

        if (uploadHelper) {
            this.setState({
                file: uploadHelper.file,
                fileBase64: uploadHelper.fileBase64,
            });
        }
    };

    private checkIfPersonExists = (
        event: React.FocusEvent<HTMLInputElement>,
        field: string
    ): void => {
        const { name, surname, persons } = this.state;
        const targetValue = event && event.target && event.target.value;

        if (field === 'name') {
            this.setState({
                personSuggestions: checkIfStringIsInArray(
                    `${targetValue || ''} ${surname || ''}`,
                    persons
                ),
                name: event.target.value,
            });
        } else {
            this.setState({
                personSuggestions: checkIfStringIsInArray(
                    `${name || ''} ${targetValue || ''}`,
                    persons
                ),
                surname: event.target.value,
            });
        }
    };

    private updateCoachTeams = (team: CreateTeamCoachCommandOfTeamCoach): void => {
        const { selectedCoachTeams } = this.state;
        this.setState({
            selectedCoachTeams: updateCoachTeamsHelper(team, selectedCoachTeams),
        });
    };

    private removeCoachTeamFromList = (team: CreateTeamCoachCommandOfTeamCoach): void => {
        const { selectedCoachTeams } = this.state;
        const newSelectedTeams = removeCoachTeamFromListHelper(team, selectedCoachTeams);
        if (newSelectedTeams) {
            this.setState({ selectedCoachTeams: newSelectedTeams });
        }
    };

    private updateCoachClubs = (club: CreateClubCoachCommandOfClubCoach): void => {
        const { selectedCoachClubs } = this.state;
        this.setState({
            selectedCoachClubs: updateCoachClubsHelper(club, selectedCoachClubs),
        });
    };

    private removeCoachClubFromList = (club: CreateClubCoachCommandOfClubCoach): void => {
        const { selectedCoachClubs } = this.state;
        const newSelectedClubs = removeCoachClubFromListHelper(club, selectedCoachClubs);
        if (newSelectedClubs) {
            this.setState({ selectedCoachClubs: newSelectedClubs });
        }
    };

    public render(): React.ReactElement {
        const {
            isSaving,
            selectedCountry,
            selectedCity,
            selectedProfileOption,
            selectedPerson,
            file,
            fileBase64,
            countries,
            cities,
            persons,
            clubs,
            teams,
            selectedClub,
            personSuggestions,
            selectedCoachTeams,
            selectedCoachClubs,
            selectedTeam,
            selectedRole,
            coachRoles,
        } = this.state;
        const { handleClose, initialValues, clubCoach, teamCoach } = this.props;

        return (
            <Form onFinish={this.onSubmit} {...formItemLayout724} ref={this.formRef}>
                <DrawerButtons
                    isSaving={isSaving}
                    onCancelAction={(): void => handleClose(DrawerState.Closed)}
                />
                <div className="drawer-content">
                    <div>
                        {!(clubCoach || teamCoach) && (
                            <div>
                                <div className="drawer-section-title">
                                    <h3>Profile</h3>
                                </div>
                                <Form.Item
                                    name="profileInformationOption"
                                    rules={[
                                        {
                                            required: true,
                                            validator: (
                                                _rule: any,
                                                value: any,
                                                callback: any
                                            ): void =>
                                                customStringValidationHelper(
                                                    _rule,
                                                    value,
                                                    callback,
                                                    'You have to choose one of these options!',
                                                    selectedProfileOption
                                                ),
                                        },
                                    ]}
                                    {...formItemLayout024}
                                >
                                    {profileOptionButtons.map(
                                        (pOB: ProfileOptionsModel): React.ReactElement => (
                                            <Radio.Button
                                                value={pOB.value}
                                                checked={selectedProfileOption === pOB.value}
                                                onChange={(e: RadioChangeEvent): void =>
                                                    this.setState({
                                                        selectedProfileOption: e.target.value,
                                                    })
                                                }
                                                style={{
                                                    width: 130,
                                                    textAlign: 'center',
                                                }}
                                            >
                                                {pOB.title}
                                            </Radio.Button>
                                        )
                                    )}
                                </Form.Item>
                                {selectedProfileOption === ProfileOption.Existing && (
                                    <div style={{ width: '100%', float: 'left' }}>
                                        <Form.Item
                                            name="personId"
                                            label="Profile"
                                            rules={[
                                                {
                                                    required: true,
                                                    validator: (
                                                        _rule: any,
                                                        value: any,
                                                        callback: any
                                                    ): void =>
                                                        customArrayValidationHelper(
                                                            _rule,
                                                            value,
                                                            callback,
                                                            'Profile field is required!',
                                                            selectedPerson
                                                        ),
                                                },
                                            ]}
                                        >
                                            <AutocompleteDropdown
                                                placeholder="Choose profile"
                                                getOptionsFrontend={(
                                                    value: string
                                                ): DropdownOption[] =>
                                                    filterDropdownOptions(value, persons)
                                                }
                                                initialValues={selectedPerson}
                                                confirmDirty={(options: DropdownOption[]): void =>
                                                    this.confirmPerson(options)
                                                }
                                                style={{ width: '100%' }}
                                            />
                                        </Form.Item>
                                    </div>
                                )}
                                {selectedProfileOption === ProfileOption.New && (
                                    <div style={{ width: '100%', float: 'left' }}>
                                        <Form.Item
                                            name="name"
                                            label="Name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Name field is required!',
                                                },
                                                {
                                                    max: 200,
                                                    message: 'Too long input!',
                                                },
                                            ]}
                                        >
                                            <Input
                                                onBlur={(
                                                    e: React.FocusEvent<HTMLInputElement>
                                                ): void => this.checkIfPersonExists(e, 'name')}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="surname"
                                            label="Surname"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Surname field is required!',
                                                },
                                                {
                                                    max: 200,
                                                    message: 'Too long input!',
                                                },
                                            ]}
                                        >
                                            <Input
                                                onBlur={(
                                                    e: React.FocusEvent<HTMLInputElement>
                                                ): void => this.checkIfPersonExists(e, 'surname')}
                                            />
                                        </Form.Item>
                                        {personSuggestions && personSuggestions.length > 0 && (
                                            <Form.Item className="person-suggestion" label=" ">
                                                <span>
                                                    Profiles with this name and surname already
                                                    exists. Click on the profile to bind the coach
                                                    role to it.
                                                </span>
                                                {transformPersonSuggestions(
                                                    personSuggestions,
                                                    this.confirmExistingPerson
                                                )}
                                            </Form.Item>
                                        )}
                                        <Form.Item
                                            name="address"
                                            label="Address"
                                            rules={[
                                                {
                                                    max: 200,
                                                    message: 'Too long input!',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            name="nationality"
                                            label="Nationality"
                                            rules={[
                                                {
                                                    max: 200,
                                                    message: 'Too long input!',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item name="countryOfBirthId" label="Country of birth">
                                            <AutocompleteDropdown
                                                placeholder="Choose country"
                                                getOptionsFrontend={(
                                                    value: string
                                                ): DropdownOption[] =>
                                                    filterDropdownOptions(value, countries)
                                                }
                                                initialValues={selectedCountry}
                                                confirmDirty={this.confirmCountry}
                                                style={{ width: '100%' }}
                                                isAllowedToClear
                                            />
                                        </Form.Item>
                                        <Form.Item name="cityOfBirthId" label="City of birth">
                                            <AutocompleteDropdown
                                                placeholder="Choose city"
                                                getOptionsFrontend={(
                                                    value: string
                                                ): DropdownOption[] =>
                                                    filterDropdownOptions(value, cities)
                                                }
                                                initialValues={selectedCity}
                                                confirmDirty={(options: DropdownOption[]): void =>
                                                    this.setState({
                                                        selectedCity:
                                                            confirmDropdownHelper(options),
                                                    })
                                                }
                                                style={{ width: '100%' }}
                                                isAllowedToClear
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="birthDate"
                                            label="Birth date"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Birth date field is required!',
                                                },
                                            ]}
                                        >
                                            <DatePicker
                                                format="DD.MM.YYYY."
                                                style={{ width: '100%' }}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="phone"
                                            label="Phone"
                                            rules={[
                                                {
                                                    // eslint-disable-next-line
                                                    pattern: /^[0-9(\)\+]+$/,
                                                    message:
                                                        'Only numbers and plus sign are allowed!',
                                                },
                                                {
                                                    max: 200,
                                                    message: 'Too long input!',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            name="email"
                                            label="Email"
                                            rules={[
                                                {
                                                    type: 'email',
                                                    message: 'Wrong e-mail format!',
                                                },
                                                {
                                                    max: 200,
                                                    message: 'Too long input!',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            name="facebook"
                                            label="Facebook"
                                            rules={[
                                                {
                                                    max: 200,
                                                    message: 'Too long input!',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            name="twitter"
                                            label="Twitter"
                                            rules={[
                                                {
                                                    max: 200,
                                                    message: 'Too long input!',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            name="youtube"
                                            label="Youtube"
                                            rules={[
                                                {
                                                    max: 200,
                                                    message: 'Too long input!',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            name="instagram"
                                            label="Instagram"
                                            rules={[
                                                {
                                                    max: 200,
                                                    message: 'Too long input!',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item name="photo" label="Photo">
                                            {fileBase64 && (
                                                <Row>
                                                    <Col span="24">
                                                        <img
                                                            height={55}
                                                            src={`data:image/jpeg;base64,${fileBase64}`}
                                                            alt="logo"
                                                        />
                                                    </Col>
                                                    <Col span="24">
                                                        <Button
                                                            type="primary"
                                                            danger
                                                            style={{
                                                                marginTop: 10,
                                                            }}
                                                            onClick={(): void =>
                                                                this.setState({
                                                                    file: undefined,
                                                                    fileBase64: undefined,
                                                                })
                                                            }
                                                        >
                                                            Remove logo
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            )}
                                            {!fileBase64 && (
                                                <Upload
                                                    accept="image/png"
                                                    style={{ zIndex: 10 }}
                                                    beforeUpload={(file) => {
                                                        this.onUploadFileChange(file);
                                                        return false;
                                                    }}
                                                    onRemove={(): void =>
                                                        this.setState({
                                                            file: undefined,
                                                            fileBase64: undefined,
                                                        })
                                                    }
                                                    fileList={file ? [{ ...file }] : []}
                                                >
                                                    <Button type="primary" disabled={!!file}>
                                                        <UploadOutlined className="button-icon-display" />
                                                        Upload image
                                                    </Button>
                                                </Upload>
                                            )}
                                        </Form.Item>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    {!(initialValues && initialValues.teamId) &&
                        !(initialValues && initialValues.clubId) && (
                            <div
                                style={{
                                    marginBottom:
                                        selectedCoachClubs && selectedCoachClubs.length > 0
                                            ? 20
                                            : 70,
                                }}
                            >
                                <div className="drawer-section-title">
                                    <h3>Clubs</h3>
                                </div>
                                <Form.Item
                                    name="clubs"
                                    rules={[
                                        {
                                            validator: async () => {
                                                if (
                                                    (!selectedCoachClubs ||
                                                        selectedCoachClubs.length < 1) &&
                                                    (!selectedCoachTeams ||
                                                        selectedCoachTeams.length < 1)
                                                ) {
                                                    return Promise.reject(
                                                        new Error('Club or Team field is required')
                                                    );
                                                }
                                            },
                                        },
                                    ]}
                                    {...formItemLayout024}
                                >
                                    <PersonClubs
                                        entityModule={ModuleName.Coaches}
                                        initialClubId={initialValues && initialValues.clubId}
                                        saveCoachChanges={this.updateCoachClubs}
                                        selectedCoachClubs={selectedCoachClubs}
                                        removeCoachClubFromList={this.removeCoachClubFromList}
                                    />
                                </Form.Item>
                            </div>
                        )}
                    {!(initialValues && initialValues.clubId) &&
                        !(initialValues && initialValues.teamId) && (
                            <div>
                                <div className="drawer-section-title">
                                    <h3>Teams</h3>
                                </div>
                                <Form.Item
                                    name="clubs"
                                    rules={[
                                        {
                                            validator: async () => {
                                                if (
                                                    (!selectedCoachClubs ||
                                                        selectedCoachClubs.length < 1) &&
                                                    (!selectedCoachTeams ||
                                                        selectedCoachTeams.length < 1)
                                                ) {
                                                    return Promise.reject(
                                                        new Error('Club or Team field is required')
                                                    );
                                                }
                                            },
                                        },
                                    ]}
                                    {...formItemLayout024}
                                >
                                    <PersonTeams
                                        entityModule={ModuleName.Coaches}
                                        initialTeamId={initialValues && initialValues.teamId}
                                        saveCoachChanges={this.updateCoachTeams}
                                        selectedCoachTeams={selectedCoachTeams}
                                        removeCoachTeamFromList={this.removeCoachTeamFromList}
                                    />
                                </Form.Item>
                            </div>
                        )}
                    {initialValues && initialValues.clubId && (
                        <div>
                            {!clubCoach && (
                                <div className="drawer-section-title">
                                    <h3>Club</h3>
                                </div>
                            )}
                            <Form.Item name="clubId" label="Club">
                                <AutocompleteDropdown
                                    placeholder="Choose club"
                                    getOptionsFrontend={(value: string): DropdownOption[] =>
                                        filterDropdownOptions(value, clubs)
                                    }
                                    initialValues={selectedClub}
                                    confirmDirty={(options: DropdownOption[]): void =>
                                        this.setState({
                                            selectedClub: confirmDropdownHelper(options),
                                        })
                                    }
                                    style={{ width: '100%' }}
                                    isAllowedToClear
                                    disabled={!!(initialValues && initialValues.clubId)}
                                />
                            </Form.Item>
                            <Form.Item
                                name="activeFromClub"
                                label="Active from"
                                initialValue={
                                    clubCoach?.activeFrom
                                        ? moment(convertUTCTimeToLocal(clubCoach.activeFrom))
                                        : moment(convertUTCTimeToLocal(new Date()))
                                }
                            >
                                <DatePicker
                                    format="DD.MM.YYYY."
                                    style={{ width: '100%' }}
                                    allowClear={false}
                                />
                            </Form.Item>
                            <Form.Item
                                name="activeToClub"
                                label="Active to"
                                initialValue={
                                    clubCoach?.activeTo
                                        ? moment(convertUTCTimeToLocal(clubCoach.activeTo))
                                        : undefined
                                }
                            >
                                <DatePicker format="DD.MM.YYYY." style={{ width: '100%' }} />
                            </Form.Item>
                        </div>
                    )}
                    {initialValues && initialValues.teamId && (
                        <div>
                            {!teamCoach && (
                                <div className="drawer-section-title">
                                    <h3>Team</h3>
                                </div>
                            )}
                            <Form.Item name="teamId" label="Team">
                                <AutocompleteDropdown
                                    placeholder="Choose team"
                                    getOptionsFrontend={(value: string): DropdownOption[] =>
                                        filterDropdownOptions(value, teams)
                                    }
                                    initialValues={selectedTeam}
                                    confirmDirty={(options: DropdownOption[]): void =>
                                        this.setState({
                                            selectedTeam: confirmDropdownHelper(options),
                                        })
                                    }
                                    style={{ width: '100%' }}
                                    isAllowedToClear
                                    disabled={!!(initialValues && initialValues.teamId)}
                                />
                            </Form.Item>
                            <Form.Item
                                name="teamRoleId"
                                label="Role"
                                rules={[
                                    {
                                        required: selectedTeam && selectedTeam.length > 0,
                                        validator: (_rule: any, value: any, callback: any): void =>
                                            customArrayValidationHelper(
                                                _rule,
                                                value,
                                                callback,
                                                'Role field is required!',
                                                selectedRole
                                            ),
                                    },
                                ]}
                            >
                                <AutocompleteDropdown
                                    placeholder="Choose role"
                                    getOptionsFrontend={(value: string): DropdownOption[] =>
                                        filterDropdownOptions(value, coachRoles)
                                    }
                                    initialValues={selectedRole}
                                    confirmDirty={(options: DropdownOption[]): void =>
                                        this.setState({
                                            selectedRole: confirmDropdownHelper(options),
                                        })
                                    }
                                    style={{ width: '100%' }}
                                    isAllowedToClear
                                />
                            </Form.Item>
                            <Form.Item
                                name="activeFromTeam"
                                label="Active from"
                                initialValue={
                                    teamCoach?.activeFrom
                                        ? moment(convertUTCTimeToLocal(teamCoach.activeFrom))
                                        : moment(convertUTCTimeToLocal(new Date()))
                                }
                            >
                                <DatePicker
                                    format="DD.MM.YYYY."
                                    style={{ width: '100%' }}
                                    allowClear={false}
                                />
                            </Form.Item>
                            <Form.Item
                                name="activeToTeam"
                                label="Active to"
                                initialValue={
                                    teamCoach?.activeTo
                                        ? moment(convertUTCTimeToLocal(teamCoach.activeTo))
                                        : undefined
                                }
                            >
                                <DatePicker format="DD.MM.YYYY." style={{ width: '100%' }} />
                            </Form.Item>
                        </div>
                    )}
                </div>
            </Form>
        );
    }
}

export default CoachForm;
