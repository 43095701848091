import { CheckOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import Routes from '../../../config/routes';
import ReplaceStrings from '../../../config/replaceStrings';
import { ModuleName, SortDirectionEnum } from '../../../core/models/enum';
import { Filter } from '../../../core/models/Filter';
import { getAllowedEntities, isUserAdmin } from '../../../helpers/CheckPermissionHelper';
import { convertUTCTimeToLocal } from '../../../helpers/DateHelper';
import {
    buildFilterRequest,
    DefaultFilter,
    getDatePickerFilter,
    getRadioFilter,
    getSearchFilter,
} from '../../../helpers/FilterHelper';
import { PaginationOptions } from '../../../helpers/PaginationHelper';
import { capitalizeFirstLetter } from '../../../helpers/TransformHelper';
import { FederationsClient, FederationVm } from '../../../utils/api';
import { Props } from './index';

interface State {
    federations: FederationVm[];
    paginationOptions: TablePaginationConfig;
    currentFilter: Filter;
    loading: boolean;
    allowedEntities: number[];
    isAdmin: boolean;
}

class FederationTable extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            federations: [],
            paginationOptions: PaginationOptions,
            loading: true,
            currentFilter: DefaultFilter,
            allowedEntities: [],
            isAdmin: false,
        };
    }

    public componentDidMount = (): void => {
        this.getPermissions();
        this.getFederations();
    };

    private getPermissions = (): void => {
        const { userProfile } = this.props;

        this.setState({
            allowedEntities: getAllowedEntities(userProfile, ModuleName.Federations),
            isAdmin: isUserAdmin(userProfile),
        });
    };

    private getFederations = async (currentFilterParam?: Filter): Promise<void> => {
        const { currentFilter } = this.state;

        this.setState({
            loading: true,
        });

        const filter = currentFilterParam || currentFilter;

        const federationClient = new FederationsClient();
        const federationPagedList = await federationClient.getPaginated(
            filter.filterRequest?.nameUniversal,
            filter.filterRequest?.shortName,
            filter.filterRequest?.activeFrom,
            filter.filterRequest?.activeTo,
            filter.filterRequest?.isPro,
            filter.filterRequest?.country,
            filter.pageIndex,
            filter.pageSize,
            capitalizeFirstLetter(filter.orderByKey),
            filter.orderByValue
        );

        this.setState({
            paginationOptions: {
                ...PaginationOptions,
                current: federationPagedList.currentPage,
                pageSize: federationPagedList.pageSize,
                total: federationPagedList.totalItems,
            },
            federations: federationPagedList?.pageItems || [],
            loading: false,
        });
    };

    private handleTableChange = (pagination: any, filters: any, sorter: any): void => {
        const filterRequest = buildFilterRequest(filters);

        const filter: Filter = {
            pageIndex: pagination.current,
            pageSize: pagination.pageSize,
            orderByKey: sorter?.columnKey && sorter?.order ? sorter.columnKey : undefined,
            orderByValue: sorter?.order ? sorter.order : undefined,
            filterRequest,
        };

        this.getFederations(filter);

        this.setState({
            currentFilter: filter,
        });
    };

    public render(): React.ReactElement {
        const { federations, loading, paginationOptions, allowedEntities, isAdmin } = this.state;

        const columns = [
            {
                title: 'Name',
                dataIndex: 'nameUniversal',
                key: 'nameUniversal',
                ...getSearchFilter(),
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: (): void => {},
            },
            {
                title: 'Country',
                dataIndex: 'country',
                key: 'country',
                ...getSearchFilter(),
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: (): void => {},
            },
            {
                title: 'Professional',
                dataIndex: 'isPro',
                key: 'isPro',
                width: 162,
                ...getRadioFilter([
                    { id: 'false', name: 'False' },
                    { id: 'true', name: 'True' },
                ]),
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: (): void => {},
                render: (value: boolean): React.ReactElement =>
                    value ? <CheckOutlined style={{ width: '100%' }} /> : <span />,
            },
            {
                title: 'Active from',
                dataIndex: 'activeFrom',
                key: 'activeFrom',
                ...getDatePickerFilter(),
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: (): void => {},
                render: (value: Date): string =>
                    value ? moment(convertUTCTimeToLocal(value)).format('DD.MM.YYYY.') : '',
                width: 158,
            },
            {
                title: 'Active to',
                dataIndex: 'activeTo',
                key: 'activeTo',
                ...getDatePickerFilter(),
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: (): void => {},
                render: (value?: Date): string =>
                    value ? moment(convertUTCTimeToLocal(value)).format('DD.MM.YYYY.') : '',
                width: 158,
            },
            {
                dataIndex: '',
                render: (value: FederationVm): React.ReactElement =>
                    value &&
                    value.id &&
                    (isAdmin ||
                        true || !!allowedEntities.find((ent: number): boolean => ent === value?.id)) ? (
                        <Link
                            to={Routes.ROUTE_FEDERATIONS_READ.replace(
                                ReplaceStrings.FEDERATION_ID,
                                value.id.toString()
                            )}
                        >
                            Details
                        </Link>
                    ) : (
                        <span />
                    ),
                width: 80,
            },
        ];

        return (
            <Table
                columns={columns}
                loading={loading}
                dataSource={federations}
                rowKey={(record): string => (record && record.id ? record.id.toString() : '-1')}
                pagination={paginationOptions}
                onChange={this.handleTableChange}
                bordered
                size="small"
            />
        );
    }
}

export default FederationTable;
