import { CheckOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import Routes from '../../../config/routes';
import ReplaceStrings from '../../../config/replaceStrings';
import { ModuleName, SortDirectionEnum } from '../../../core/models/enum';
import { Filter } from '../../../core/models/Filter';
import { getAllowedEntities, isUserAdmin } from '../../../helpers/CheckPermissionHelper';
import { convertUTCTimeToLocal } from '../../../helpers/DateHelper';
import {
    buildFilterRequest,
    DefaultFilter,
    getDatePickerFilter,
    getRadioFilter,
    getSearchFilter,
} from '../../../helpers/FilterHelper';
import { PaginationOptions } from '../../../helpers/PaginationHelper';
import { feTableFilterHelper } from '../../../helpers/TableFilterHelper';
import { capitalizeFirstLetter } from '../../../helpers/TransformHelper';
import { popupConfirmDeleteWithCallback } from '../../../helpers/NotificationHelper';
import { ClubsClient, ClubVm, PaginatedListOfClubVm } from '../../../utils/api';
import { Props } from './index';

interface State {
    clubs: ClubVm[];
    paginationOptions: TablePaginationConfig;
    currentFilter: Filter;
    loading: boolean;
    allowedEntities: number[];
    isAdmin: boolean;
}

class ClubTable extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            clubs: [],
            paginationOptions: PaginationOptions,
            currentFilter: DefaultFilter,
            loading: true,
            allowedEntities: [],
            isAdmin: false,
        };
    }

    public componentDidMount = (): void => {
        this.getPermissions();
        this.getClubs();
    };

    private getPermissions = (): void => {
        let { userProfile } = this.props;

        this.setState({
            allowedEntities: getAllowedEntities(userProfile, ModuleName.Clubs),
            isAdmin: isUserAdmin(userProfile),
        });
    };

    private getClubs = async (currentFilterParam?: Filter): Promise<void> => {
        const { currentFilter } = this.state;
        const { clubsFromProps } = this.props;

        this.setState({
            loading: true,
        });

        let clubPagedList;
        const filter = currentFilterParam || currentFilter;

        if (clubsFromProps) {
            clubPagedList = this.getClubsFromProps(filter);
        } else {
            clubPagedList = await this.getAllClubs(filter);
        }

        this.setState({
            paginationOptions: {
                ...PaginationOptions,
                current: clubPagedList.currentPage,
                pageSize: clubPagedList.pageSize,
                total: clubPagedList.totalItems,
            },
            clubs: clubPagedList?.pageItems || [],
            loading: false,
        });
    };

    private getAllClubs = async (filter: Filter): Promise<PaginatedListOfClubVm> => {
        const clubClient = new ClubsClient();

        return await clubClient.getPaginated(
            filter.filterRequest?.nameUniversal,
            filter.filterRequest?.shortName,
            filter.filterRequest?.activeFrom,
            filter.filterRequest?.activeTo,
            filter.filterRequest?.isPro,
            filter.filterRequest?.federation,
            filter.filterRequest?.country,
            filter.filterRequest?.city,
            filter.pageIndex,
            filter.pageSize,
            capitalizeFirstLetter(filter.orderByKey),
            filter.orderByValue
        );
    };

    private getClubsFromProps = (filter: Filter): PaginatedListOfClubVm => {
        const { clubsFromProps } = this.props;

        const filteredResult = feTableFilterHelper(clubsFromProps || [], filter);
        return new PaginatedListOfClubVm({
            ...filteredResult,
            pageItems: filteredResult.pageItems as ClubVm[],
        });
    };

    private handleTableChange = (pagination: any, filters: any, sorter: any): void => {
        const filterRequest = buildFilterRequest(filters);

        const filter: Filter = {
            pageIndex: pagination.current,
            pageSize: pagination.pageSize,
            orderByKey: sorter?.columnKey && sorter?.order ? sorter.columnKey : undefined,
            orderByValue: sorter?.order ? sorter.order : undefined,
            filterRequest,
        };

        this.getClubs(filter);
        this.setState({
            currentFilter: filter,
        });
    };

    public render(): React.ReactElement {
        const { clubs, loading, paginationOptions, allowedEntities, isAdmin } = this.state;
        const { additionalAction, additionalActionTitle } = this.props;

        const columns = [
            {
                title: 'Name',
                dataIndex: 'nameUniversal',
                key: 'nameUniversal',
                ...getSearchFilter(),
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: (): void => {},
            },
            {
                title: 'Federation',
                dataIndex: 'federation',
                key: 'federation',
                ...getSearchFilter(),
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: (): void => {},
            },
            {
                title: 'Country',
                dataIndex: 'country',
                key: 'country',
                ...getSearchFilter(),
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: (): void => {},
            },
            {
                title: 'City',
                dataIndex: 'city',
                key: 'city',
                ...getSearchFilter(),
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: (): void => {},
            },
            {
                title: 'Professional',
                dataIndex: 'isPro',
                key: 'isPro',
                ...getRadioFilter([
                    { id: 'false', name: 'False' },
                    { id: 'true', name: 'True' },
                ]),
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: (): void => {},
                render: (value: boolean): React.ReactElement =>
                    value ? <CheckOutlined style={{ width: '100%' }} /> : <span />,
                width: 110,
            },
            {
                title: 'Active from',
                dataIndex: 'activeFrom',
                key: 'activeFrom',
                ...getDatePickerFilter(),
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: (): void => {},
                render: (value: Date): string =>
                    value ? moment(convertUTCTimeToLocal(value)).format('DD.MM.YYYY.') : '',
                width: 110,
            },
            {
                title: 'Active to',
                dataIndex: 'activeTo',
                ...getDatePickerFilter(),
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: (): void => {},
                render: (value?: Date): string =>
                    value ? moment(convertUTCTimeToLocal(value)).format('DD.MM.YYYY.') : '',
                key: 'activeTo',
                width: 110,
            },
            {
                dataIndex: '',
                render: (value: ClubVm): React.ReactElement =>
                    value &&
                    value.id &&
                    (isAdmin ||
                        true || !!allowedEntities.find((ent: number): boolean => ent === value?.id)) ? (
                        <div>
                            <Link
                                to={Routes.ROUTE_CLUBS_READ.replace(
                                    ReplaceStrings.CLUB_ID,
                                    value.id.toString()
                                )}
                            >
                                Details
                            </Link>
                            {additionalAction && (
                                <>
                                    {` | `}
                                    <span
                                        className="additional-action-link"
                                        onClick={(): void =>
                                            popupConfirmDeleteWithCallback(
                                                additionalAction,
                                                value.id,
                                                'Are you sure you want to remove this club?',
                                                'Remove'
                                            )
                                        }
                                    >
                                        {additionalActionTitle || 'Remove'}
                                    </span>
                                </>
                            )}
                        </div>
                    ) : (
                        <span />
                    ),
                width: additionalAction ? 120 : 80,
            },
        ];

        return (
            <Table
                columns={columns}
                loading={loading}
                dataSource={clubs}
                rowKey={(record): string => (record && record.id ? record.id.toString() : '-1')}
                pagination={paginationOptions}
                onChange={this.handleTableChange}
                bordered
                size="small"
            />
        );
    }
}

export default ClubTable;
