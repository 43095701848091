import { UploadOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, Row, Upload } from 'antd';
import moment from 'moment';
import React from 'react';
import AutocompleteDropdown from '../../../components/autocomplete-dropdown';
import DrawerButtons from '../../../components/drawer-buttons/index';
import { DropdownOption } from '../../../core/models/DropdownOption';
import { DrawerState } from '../../../core/models/enum';
import {
    confirmDropdownHelper,
    filterDropdownOptions,
    getCitiesHelper,
    getCountriesHelper,
} from '../../../helpers/AutocompleteDropdownHelper';
import { convertUTCTimeToLocal } from '../../../helpers/DateHelper';
import { onUploadFileChangeHelper } from '../../../helpers/FileHelper';
import { formItemLayout724 } from '../../../helpers/FormLayoutHelper';
import { success } from '../../../helpers/NotificationHelper';
import { createHelper, updateHelper } from '../../../helpers/SubmitHelper';
import { CreatePersonCommandOfPerson, PersonsClient } from '../../../utils/api';
import { Props } from './index';

interface State {
    isSaving: boolean;
    countries: DropdownOption[];
    cities: DropdownOption[];
    selectedCountry?: DropdownOption[];
    selectedCity?: DropdownOption[];
    file?: any;
    fileBase64?: string;
}

class PersonForm extends React.PureComponent<Props, State> {
    private formRef: any;

    public constructor(props: Props) {
        super(props);

        const { person } = props;

        this.formRef = React.createRef();

        this.state = {
            isSaving: false,
            countries: [],
            cities: [],
            fileBase64: person ? person.photo : undefined,
        };
    }

    public componentDidMount = (): void => {
        const { person } = this.props;

        this.getCountries();
        if (!(person && person.countryOfBirthId)) {
            this.getCities();
        }
    };

    private getCountries = async (): Promise<void> => {
        const { person } = this.props;
        const countryId = person && person.countryOfBirthId;

        const countryHelper = await getCountriesHelper(countryId);

        if (countryId) {
            this.getCities(countryId);
        }

        this.setState({
            countries: countryHelper.entities,
            selectedCountry: countryHelper.selectedEntity,
        });
    };

    private getCities = async (countryId?: number): Promise<void> => {
        const { person } = this.props;
        const cityId = person && person.cityOfBirthId;

        const cityHelper = await getCitiesHelper(countryId, cityId);

        this.setState({
            cities: cityHelper.entities,
            selectedCity: cityHelper.selectedEntity,
        });
    };

    private onSubmit = (values: any): void => {
        const { selectedCountry, selectedCity, fileBase64 } = this.state;
        const { person } = this.props;
        const personClient = new PersonsClient();

        this.setState({
            isSaving: true,
        });

        const personDetails: CreatePersonCommandOfPerson = new CreatePersonCommandOfPerson({
            name: values.name,
            surname: values.surname,
            birthDate:
                values && values.birthDate
                    ? new Date(values.birthDate.toLocaleString())
                    : new Date(),
            nationality: values.nationality,
            address: values.address,
            email: values.email,
            phone: values.phone,
            facebook: values.facebook,
            youtube: values.youtube,
            twitter: values.twitter,
            instagram: values.instagram,
            photo: fileBase64 || undefined,
            countryOfBirthId:
                selectedCountry && selectedCountry.length > 0
                    ? parseInt(selectedCountry[0].id, 10)
                    : undefined,
            cityOfBirthId:
                selectedCity && selectedCity.length > 0
                    ? parseInt(selectedCity[0].id, 10)
                    : undefined,
            coachId: person?.coachId,
            coach: undefined,
            officialId: person?.officialId,
            official: undefined,
            playerId: person?.playerId,
            player: undefined,
        });

        if (person && person.id) {
            updateHelper(
                personClient,
                { ...personDetails, id: person.id },
                this.handleSuccessfullySaving
            );
        } else {
            createHelper(personClient, personDetails, this.handleSuccessfullySaving);
        }

        this.setState({
            isSaving: false,
        });
    };

    private handleSuccessfullySaving = (): void => {
        const { person, handleClose, refreshAfterSave } = this.props;

        success('Person successfully saved.');
        refreshAfterSave();
        handleClose(
            person ? DrawerState.Read : DrawerState.Closed,
            person ? person.id.toString() : undefined
        );
    };

    private confirmCountry = (option: DropdownOption[]): void => {
        const selectedCountry = confirmDropdownHelper(option);
        this.setState({
            selectedCountry,
            selectedCity: undefined,
        });
        if (selectedCountry) {
            this.getCities(parseInt(selectedCountry[0].id, 10));
        }
    };

    private onUploadFileChange = async (file: File): Promise<void> => {
        const uploadHelper = await onUploadFileChangeHelper(file);

        if (uploadHelper) {
            this.setState({
                file: uploadHelper.file,
                fileBase64: uploadHelper.fileBase64,
            });
        }
    };

    public render(): React.ReactElement {
        const {
            isSaving,
            selectedCountry,
            selectedCity,
            file,
            fileBase64,
            countries,
            cities,
        } = this.state;
        const { handleClose, person } = this.props;

        return (
            <Form onFinish={this.onSubmit} {...formItemLayout724} ref={this.formRef}>
                <DrawerButtons
                    isSaving={isSaving}
                    onCancelAction={(): void =>
                        handleClose(
                            person ? DrawerState.Read : DrawerState.Closed,
                            person ? person.id.toString() : undefined
                        )
                    }
                />
                <div className="drawer-content">
                    <Form.Item
                        name="name"
                        label="Name"
                        initialValue={person ? person.name : undefined}
                        rules={[
                            {
                                required: true,
                                message: 'Name field is required!',
                            },
                            { max: 200, message: 'Too long input!' },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="surname"
                        label="Surname"
                        initialValue={person ? person.surname : undefined}
                        rules={[
                            {
                                required: true,
                                message: 'Surname field is required!',
                            },
                            { max: 200, message: 'Too long input!' },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="address"
                        label="Address"
                        initialValue={person ? person.address : undefined}
                        rules={[{ max: 200, message: 'Too long input!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="nationality"
                        label="Nationality"
                        initialValue={person ? person.nationality : undefined}
                        rules={[{ max: 200, message: 'Too long input!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item name="countryOfBirthId" label="Country of birth">
                        <AutocompleteDropdown
                            placeholder="Choose country"
                            getOptionsFrontend={(value: string): DropdownOption[] =>
                                filterDropdownOptions(value, countries)
                            }
                            initialValues={selectedCountry}
                            confirmDirty={this.confirmCountry}
                            style={{ width: '100%' }}
                            isAllowedToClear
                        />
                    </Form.Item>
                    <Form.Item name="cityOfBirthId" label="City of birth">
                        <AutocompleteDropdown
                            placeholder="Choose city"
                            getOptionsFrontend={(value: string): DropdownOption[] =>
                                filterDropdownOptions(value, cities)
                            }
                            initialValues={selectedCity}
                            confirmDirty={(options: DropdownOption[]): void =>
                                this.setState({
                                    selectedCity: confirmDropdownHelper(options),
                                })
                            }
                            style={{ width: '100%' }}
                            isAllowedToClear
                        />
                    </Form.Item>
                    <Form.Item
                        name="birthDate"
                        label="Birth date"
                        rules={[
                            {
                                required: true,
                                message: 'Birth date field is required!',
                            },
                        ]}
                        initialValue={
                            person && person.birthDate
                                ? moment(convertUTCTimeToLocal(person.birthDate))
                                : undefined
                        }
                    >
                        <DatePicker format="DD.MM.YYYY." style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                        name="phone"
                        label="Phone"
                        initialValue={person ? person.phone : undefined}
                        rules={[
                            {
                                // eslint-disable-next-line
                                pattern: /^[0-9(\)\+]+$/,
                                message: 'Only numbers and plus sign are allowed!',
                            },
                            { max: 200, message: 'Too long input!' },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        label="Email"
                        initialValue={person ? person.email : undefined}
                        rules={[
                            { type: 'email', message: 'Wrong e-mail format!' },
                            { max: 200, message: 'Too long input!' },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="facebook"
                        label="Facebook"
                        initialValue={person ? person.facebook : undefined}
                        rules={[{ max: 200, message: 'Too long input!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="twitter"
                        label="Twitter"
                        initialValue={person ? person.twitter : undefined}
                        rules={[{ max: 200, message: 'Too long input!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="youtube"
                        label="Youtube"
                        initialValue={person ? person.youtube : undefined}
                        rules={[{ max: 200, message: 'Too long input!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="instagram"
                        label="Instagram"
                        initialValue={person ? person.instagram : undefined}
                        rules={[{ max: 200, message: 'Too long input!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="photo"
                        label="Photo"
                        initialValue={person ? person.photo : undefined}
                    >
                        {fileBase64 && (
                            <Row>
                                <Col span="24">
                                    <img
                                        height={55}
                                        src={`data:image/jpeg;base64,${fileBase64}`}
                                        alt="logo"
                                    />
                                </Col>
                                <Col span="24">
                                    <Button
                                        type="primary"
                                        danger
                                        style={{ marginTop: 10 }}
                                        onClick={(): void =>
                                            this.setState({
                                                file: undefined,
                                                fileBase64: undefined,
                                            })
                                        }
                                    >
                                        Remove logo
                                    </Button>
                                </Col>
                            </Row>
                        )}
                        {!fileBase64 && (
                            <Upload
                                accept="image/png"
                                style={{ zIndex: 10 }}
                                beforeUpload={(file) => {
                                    this.onUploadFileChange(file);
                                    return false;
                                }}
                                onRemove={(): void =>
                                    this.setState({
                                        file: undefined,
                                        fileBase64: undefined,
                                    })
                                }
                                fileList={file ? [{ ...file }] : []}
                            >
                                <Button type="primary" disabled={!!file}>
                                    <UploadOutlined className="button-icon-display" />
                                    Upload image
                                </Button>
                            </Upload>
                        )}
                    </Form.Item>
                </div>
            </Form>
        );
    }
}

export default PersonForm;
