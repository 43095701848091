import React, { Component } from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import {
    DashboardOutlined,
    HomeOutlined,
    SettingOutlined,
    TeamOutlined,
    UnorderedListOutlined,
} from '@ant-design/icons';
import Routes from '../config/routes';
import { ModuleName, ExtendedRoleEnum } from '../core/models/enum';
import { SelectedUserRole } from '../helpers/CheckPermissionHelper';

interface Props {
    currentModule: string;
    userRole: SelectedUserRole;
}

interface MenuItem {
    key: ModuleName;
    icon: any;
    link: string;
    text: string;
    allowedRoles?: ExtendedRoleEnum[];
    children?: MenuItem[];
}

const links: MenuItem[] = [
    {
        key: ModuleName.Dashboard,
        icon: <DashboardOutlined />,
        link: Routes.ROUTE_DASHBOARD,
        text: 'Dashboard',
    },
    {
        key: ModuleName.Federations,
        icon: <UnorderedListOutlined />,
        link: Routes.ROUTE_FEDERATIONS,
        text: 'Federations',
        allowedRoles: [
            ExtendedRoleEnum.Admin,
            ExtendedRoleEnum.Federation,
            ExtendedRoleEnum.Official,
        ],
    },
    {
        key: ModuleName.Clubs,
        icon: <UnorderedListOutlined />,
        link: Routes.ROUTE_CLUBS,
        text: 'Clubs',
        allowedRoles: [ExtendedRoleEnum.Admin, ExtendedRoleEnum.Federation, ExtendedRoleEnum.Coach],
    },
    {
        key: ModuleName.Teams,
        icon: <UnorderedListOutlined />,
        link: Routes.ROUTE_TEAMS,
        text: 'Teams',
        allowedRoles: [
            ExtendedRoleEnum.Admin,
            ExtendedRoleEnum.Federation,
            ExtendedRoleEnum.Club,
            ExtendedRoleEnum.Coach,
        ],
    },
    {
        key: ModuleName.Competitions,
        icon: <UnorderedListOutlined />,
        link: Routes.ROUTE_COMPETITIONS,
        text: 'Competitions',
        allowedRoles: [
            ExtendedRoleEnum.Admin,
            ExtendedRoleEnum.Federation,
            ExtendedRoleEnum.Official,
            ExtendedRoleEnum.Club,
        ],
    },
    {
        key: ModuleName.Matches,
        icon: <UnorderedListOutlined />,
        link: Routes.ROUTE_MATCHES,
        text: 'Matches',
        allowedRoles: [
            ExtendedRoleEnum.Admin,
            ExtendedRoleEnum.Federation,
            ExtendedRoleEnum.Official,
            ExtendedRoleEnum.Club,
        ],
    },
    {
        key: ModuleName.Coaches,
        icon: <TeamOutlined />,
        link: Routes.ROUTE_COACHES,
        text: 'Coaches',
        allowedRoles: [ExtendedRoleEnum.Admin, ExtendedRoleEnum.Federation, ExtendedRoleEnum.Club],
    },
    {
        key: ModuleName.Players,
        icon: <TeamOutlined />,
        link: Routes.ROUTE_PLAYERS,
        text: 'Players',
        allowedRoles: [ExtendedRoleEnum.Admin, ExtendedRoleEnum.Federation, ExtendedRoleEnum.Club],
    },
    {
        key: ModuleName.Officials,
        icon: <TeamOutlined />,
        link: Routes.ROUTE_OFFICIALS,
        text: 'Officials',
        allowedRoles: [
            ExtendedRoleEnum.Admin,
            ExtendedRoleEnum.Federation,
            ExtendedRoleEnum.Official,
        ],
    },
    {
        key: ModuleName.Administration,
        icon: <SettingOutlined />,
        link: '',
        text: 'Administration',
        allowedRoles: [ExtendedRoleEnum.Admin],
        children: [
            {
                key: ModuleName.Users,
                icon: <TeamOutlined />,
                link: Routes.ROUTE_USERS,
                text: 'Users',
                allowedRoles: [ExtendedRoleEnum.Admin],
            },
            {
                key: ModuleName.Cities,
                icon: <HomeOutlined />,
                link: Routes.ROUTE_CITIES,
                text: 'Cities',
                allowedRoles: [ExtendedRoleEnum.Admin],
            },
        ],
    },
];

export default class SidebarMenu extends Component<Props> {
    getUserAllowedLinks = (menuItems: MenuItem[]): MenuItem[] => {
        const { userRole } = this.props;

        return menuItems.filter((item: MenuItem): boolean => {
            if (!item.allowedRoles) return true;

            return item.allowedRoles.some((role: ExtendedRoleEnum): boolean => {
                switch (role) {
                    case ExtendedRoleEnum.Admin:
                        return userRole.admin;
                    case ExtendedRoleEnum.Federation:
                        return userRole.federation;
                    case ExtendedRoleEnum.Club:
                        return userRole.club;
                    case ExtendedRoleEnum.Coach:
                        return userRole.coach;
                    case ExtendedRoleEnum.Official:
                        return userRole.official;
                    default:
                        return false;
                }
            });
        });
    };

    render() {
        const { currentModule } = this.props;

        return (
            <Menu theme="dark" mode="inline" selectedKeys={[currentModule]}>
                {this.getUserAllowedLinks(links).map(
                    (item: any): React.ReactElement =>
                        item.children ? (
                            <Menu.SubMenu key={item.key} icon={item.icon} title={item.text}>
                                {this.getUserAllowedLinks(item.children).map(
                                    (item: any): React.ReactElement => (
                                        <Menu.Item key={item.key} icon={item.icon}>
                                            <Link to={item.link}>{item.text}</Link>
                                        </Menu.Item>
                                    )
                                )}
                            </Menu.SubMenu>
                        ) : (
                            <Menu.Item key={item.key} icon={item.icon}>
                                <Link to={item.link}>{item.text}</Link>
                            </Menu.Item>
                        )
                )}
            </Menu>
        );
    }
}
