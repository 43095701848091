import { Select } from 'antd';
import { LabeledValue } from 'antd/lib/select';
import React from 'react';
import { DropdownOption } from '../../../../core/models/DropdownOption';
import { calculateGoals, compareEventsByTime, getFouls } from '../../../../helpers/EventHelper';
import {
    CreateMatchPlayerCommandOfMatchPlayer,
    EventPeriodEnum,
    EventTypeEnum,
    EventVm,
} from '../../../../utils/api';
import { Props } from './index';

const { Option } = Select;

class MatchLineupRow extends React.PureComponent<Props> {
    private handleSelect = (value: number): void => {
        const { isHomeTeam, cap, handleSelectLineupPlayer } = this.props;
        handleSelectLineupPlayer(isHomeTeam, value, cap, 1);
    };

    private handleChange = (value: string | number | LabeledValue): void => {
        if (!value) {
            const { isHomeTeam, cap, handleClearLineupPlayer } = this.props;
            handleClearLineupPlayer(isHomeTeam, cap);
        }
    };

    private availablePlayersFilter = (
        player: DropdownOption,
        lineupRequest: CreateMatchPlayerCommandOfMatchPlayer | undefined
    ): boolean => {
        const { lineup } = this.props;
        const isInLineup = lineup.some(
            (l: CreateMatchPlayerCommandOfMatchPlayer): boolean =>
                l.playerId.toString() === player.id
        );
        const isSelectedPlayer = lineupRequest && lineupRequest.playerId.toString() === player.id;
        return isSelectedPlayer || !isInLineup;
    };

    public render = (): React.ReactElement => {
        const { capNumber, isHomeTeam, players, lineupRequest, events } = this.props;

        const q1Goals = calculateGoals(events, EventPeriodEnum.First_Period);
        const q2Goals = calculateGoals(events, EventPeriodEnum.Second_Period);
        const q3Goals = calculateGoals(events, EventPeriodEnum.Third_Period);
        const q4Goals = calculateGoals(events, EventPeriodEnum.Fourth_Period);
        const psoGoals = calculateGoals(events, EventPeriodEnum.Overtime);

        const playerGoals = (
            <div
                key={1}
                className="c-player-list__goal-scored u-flex u-flex-row u-justify-content-between u-align-items-start"
            >
                <div className="c-player-list__goal-scored-item">
                    <input type="text" placeholder="" value={q1Goals > 0 ? q1Goals : ''} readOnly />
                </div>
                <div className="c-player-list__goal-scored-item">
                    <input type="text" placeholder="" value={q2Goals > 0 ? q2Goals : ''} readOnly />
                </div>
                <div className="c-player-list__goal-scored-item">
                    <input type="text" placeholder="" value={q3Goals > 0 ? q3Goals : ''} readOnly />
                </div>
                <div className="c-player-list__goal-scored-item">
                    <input type="text" placeholder="" value={q4Goals > 0 ? q4Goals : ''} readOnly />
                </div>
                <div className="c-player-list__goal-scored-item">
                    <input
                        type="text"
                        placeholder=""
                        value={psoGoals > 0 ? psoGoals : ''}
                        readOnly
                    />
                </div>
            </div>
        );

        const exclusionEvents = events
            .filter((e: EventVm): boolean => e.typeId === EventTypeEnum.Exclusion)
            .sort(compareEventsByTime);

        const playerFouls = (
            <div
                key={2}
                className="c-player-list__major-fouls u-flex u-flex-row u-justify-content-between u-align-items-start"
            >
                <div className="c-player-list__major-fouls-item">
                    <input
                        type="text"
                        placeholder=""
                        value={getFouls(exclusionEvents, 0)}
                        readOnly
                    />
                </div>
                <div className="c-player-list__major-fouls-item">
                    <input
                        type="text"
                        placeholder=""
                        value={getFouls(exclusionEvents, 1)}
                        readOnly
                    />
                </div>
                <div className="c-player-list__major-fouls-item">
                    <input
                        type="text"
                        placeholder=""
                        value={getFouls(exclusionEvents, 2)}
                        readOnly
                    />
                </div>
            </div>
        );

        const playerName = (
            <div
                key={3}
                className="c-player-list__name u-flex u-flex-row u-justify-content-between u-align-items-start"
            >
                <div className="c-player-list__name-item">
                    <Select
                        showSearch
                        allowClear
                        style={{ width: '100%', height: 30 }}
                        placeholder="Select Player"
                        optionFilterProp="children"
                        onChange={this.handleChange}
                        onSelect={this.handleSelect}
                        value={lineupRequest ? lineupRequest.playerId : undefined}
                        filterOption={(input: string, option: any): boolean =>
                            option.props.children.toUpperCase().indexOf(input.toUpperCase()) !== -1
                        }
                    >
                        {players
                            .filter((p: DropdownOption): boolean =>
                                this.availablePlayersFilter(p, lineupRequest)
                            )
                            .map(
                                (p: DropdownOption): React.ReactElement => (
                                    <Option key={p.id} value={Number(p.id)}>
                                        {p.name}
                                    </Option>
                                )
                            )}
                    </Select>
                </div>
            </div>
        );

        const playerCap = (
            <div
                key={4}
                className="c-player-list__nbr u-flex u-flex-row u-justify-content-between u-align-items-start"
            >
                <div className="c-player-list__nbr-item">
                    <input type="text" placeholder="First Name Surname" value={capNumber} readOnly />
                </div>
            </div>
        );

        // setting order of fields
        const render = isHomeTeam
            ? [playerGoals, playerFouls, playerName, playerCap]
            : [playerCap, playerName, playerFouls, playerGoals];

        return (
            <div className="c-player-list__body-row u-flex u-flex-row u-justify-content-between">
                {render}
            </div>
        );
    };
}

export default MatchLineupRow;
