import { EventVm, MatchCoachVm, MatchPlayerVm, MatchVm } from '../../../utils/api';
import MatchEventLog from './MatchEventLog';

export interface Props {
    match: MatchVm;
    events: EventVm[];
    lineups: MatchPlayerVm[];
    coaches: MatchCoachVm[];
}

export default MatchEventLog;
