import { Form, Input } from 'antd';
import React from 'react';
import DrawerButtons from '../../../components/drawer-buttons';
import { DrawerState } from '../../../core/models/enum';
import { DropdownOption } from '../../../core/models/DropdownOption';
import { formItemLayout724 } from '../../../helpers/FormLayoutHelper';
import { errorWithUserMessage, success } from '../../../helpers/NotificationHelper';
import { ApiException, CitiesClient } from '../../../utils/api';
import AutocompleteDropdown from '../../../components/autocomplete-dropdown';
import { confirmDropdownHelper, filterDropdownOptions, getCountriesHelper } from '../../../helpers/AutocompleteDropdownHelper';
import { customArrayValidationHelper } from '../../../helpers/ValidationHelper';
import { Props } from './index';

interface State {
    isSaving: boolean;
    countries: DropdownOption[];
    selectedCountry?: DropdownOption[];
}

class CityForm extends React.Component<Props, State> {
    private formRef: any;

    public constructor(props: Props) {
        super(props);

        this.formRef = React.createRef();

        this.state = {
            isSaving: false,
            countries: [],
        };
    }

    public componentDidMount = () => {
        this.getCountries();
    };

    private getCountries = async (): Promise<void> => {
        const { city } = this.props;

        const countryHelper = await getCountriesHelper(city?.countryId);

        this.setState({
            countries: countryHelper.entities,
            selectedCountry: countryHelper.selectedEntity,
        });
    };

    private onSubmit = (values: any) => {
        const { city } = this.props;
        const { selectedCountry } = this.state;

        this.setState({
            isSaving: true,
        });

        const request = {
            ...values,
            id: city ? city.id : undefined,
            countryId: selectedCountry && selectedCountry.length > 0
                ? parseInt(selectedCountry[0].id, 10)
                : undefined,
        };

        this.saveHelper(request);

        this.setState({
            isSaving: false,
        });
    };

    private saveHelper = async (request: any): Promise<void> => {
        try {
            let result;
            const client = new CitiesClient();
            if (request.id) {
                result = await client.update(request.id, request);
            } else {
                result = await client.create(request);
            }

            if (result) {
                this.handleSuccessfullySaving();
            }
        } catch (error) {
            if (error instanceof ApiException) {
                errorWithUserMessage(error.response);
            } else {
                errorWithUserMessage("Error saving data.");
            }    
        }
    };

    private handleSuccessfullySaving = () => {
        const { city, handleClose, refreshAfterSave } = this.props;

        success("City successfully saved.");
        if (refreshAfterSave) {
            refreshAfterSave();
        }
        handleClose(
            city ? DrawerState.Read : DrawerState.Closed,
            city ? city.id.toString() : undefined
        );
    };
    public render() {
        const { city, handleClose } = this.props;
        const { isSaving, countries, selectedCountry } = this.state;

        if (!countries) return null;

        return (
            <Form onFinish={this.onSubmit} {...formItemLayout724} ref={this.formRef}>
                <DrawerButtons
                    isSaving={isSaving}
                    onCancelAction={(): void =>
                        handleClose(
                            city ? DrawerState.Read : DrawerState.Closed,
                            city ? city.id.toString() : undefined
                        )
                    }
                />
                <div className="drawer-content">
                    <Form.Item
                        name="name"
                        label="Name"
                        initialValue={city?.name}
                        rules={[
                            {
                                required: true,
                                message: "Required field",
                            },
                            { max: 200, message: "Maximum 200 characters" },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="nameUniversal"
                        label="Name universal"
                        initialValue={city?.nameUniversal}
                        rules={[
                            {
                                required: true,
                                message: "Required field",
                            },
                            { max: 200, message: "Maximum 200 characters" },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="countryId"
                        label="Country"
                        rules={[
                            {
                                required: true,
                                validator: (
                                    _rule: any,
                                    value: any,
                                    callback: any
                                ): void =>
                                    customArrayValidationHelper(
                                        _rule,
                                        value,
                                        callback,
                                        'Country is required!',
                                        selectedCountry
                                    ),
                            },
                        ]}
                    >
                        <AutocompleteDropdown
                            placeholder="Choose country"
                            getOptionsFrontend={(value: string): DropdownOption[] =>
                                filterDropdownOptions(value, countries)
                            }
                            initialValues={selectedCountry}
                            confirmDirty={(options: DropdownOption[]): void =>
                                this.setState({
                                    selectedCountry: confirmDropdownHelper(options),
                                })
                            }
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                </div>
            </Form>
        );
    }
}

export default CityForm;
