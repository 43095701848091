import {
    CreateEventCommand,
    CreateMatchPlayerCommandOfMatchPlayer,
    EventVm,
    MatchCoachVm,
    MatchDetailVm,
} from '../../../utils/api';
import MatchActionBar from './MatchActionBar';

interface OwnProps {
    isHomeTeam: boolean;
    event: EventVm | CreateEventCommand;
    match?: MatchDetailVm;
    lineups: CreateMatchPlayerCommandOfMatchPlayer[];
    coaches?: MatchCoachVm[];
    clearEvent: () => void;
    deleteEvent: (event: EventVm) => void;
}

export type Props = OwnProps;

export default MatchActionBar;
