import { Profile } from 'oidc-client';
import { DropdownOption } from '../core/models/DropdownOption';
import { ActionOption, ModuleName, ProfileOption } from '../core/models/enum';
import { CoachFormInitialValues } from '../core/models/InitialValues';
import {
    ClubPlayersClient,
    ClubPlayerVm,
    CreateClubPlayerCommandOfClubPlayer,
    CreateMatchPlayerCommandOfMatchPlayer,
    CreatePersonCommandOfPerson,
    CreatePlayerCommand,
    CreateTeamPlayerCommandOfTeamPlayer,
    MatchDetailVm,
    MatchPlayerVm,
    PersonsClient,
    PersonVm,
    PlayerDetailVm,
    PlayerPositionEnum,
    PlayersClient,
    TeamPlayersClient,
    TeamPlayerVm,
    TeamsClient,
    UpdateClubPlayerCommandOfClubPlayer,
    UpdatePlayerCommandOfPlayer,
    UpdateTeamPlayerCommandOfTeamPlayer,
} from '../utils/api';
import { isUserAllowed } from './CheckPermissionHelper';
import { createHelper, updateHelper } from './SubmitHelper';

export const playerSubmitHelper = (
    callbackFunction: () => void,
    values: any,
    selectedCountry?: DropdownOption[],
    selectedCity?: DropdownOption[],
    selectedProfileOption?: string,
    selectedPerson?: PersonVm,
    fileBase64?: string,
    selectedPrimaryPosition?: DropdownOption[],
    selectedPlayerTeams?: CreateTeamPlayerCommandOfTeamPlayer[],
    selectedPlayerClubs?: CreateClubPlayerCommandOfClubPlayer[],
    selectedClub?: DropdownOption[],
    selectedTeam?: DropdownOption[],
    selectedTeamPosition?: DropdownOption[],
    player?: PlayerDetailVm,
    initialValues?: CoachFormInitialValues,
    initialClubPlayer?: ClubPlayerVm,
    initialTeamPlayer?: TeamPlayerVm
): void => {
    const primaryPositionId =
        selectedPrimaryPosition && selectedPrimaryPosition.length > 0
            ? parseInt(selectedPrimaryPosition[0].id, 10)
            : undefined;
    const birthDate =
        values && values.birthDate ? new Date(values.birthDate.toLocaleString()) : new Date();
    const countryOfBirthId =
        selectedCountry && selectedCountry.length > 0
            ? parseInt(selectedCountry[0].id, 10)
            : undefined;
    const cityOfBirthId =
        selectedCity && selectedCity.length > 0 ? parseInt(selectedCity[0].id, 10) : undefined;

    // CLUB PLAYERS PAGE
    if (initialValues?.clubId) {
        if (initialClubPlayer) {
            updateHelper(
                new ClubPlayersClient(),
                {
                    ...new UpdateClubPlayerCommandOfClubPlayer({
                        id: 0,
                        clubId: initialValues.clubId,
                        playerId: initialClubPlayer.playerId,
                        activeFrom: values.activeFromClub,
                        activeTo: values.activeToClub,
                    }),
                    // constructor doesn't set id correctly so we set it manually
                    id: initialClubPlayer.id,
                },
                callbackFunction
            );
        } else if (selectedProfileOption === ProfileOption.Existing && selectedPerson?.playerId) {
            createHelper(
                new ClubPlayersClient(),
                new CreateClubPlayerCommandOfClubPlayer({
                    clubId: initialValues.clubId,
                    playerId: selectedPerson.playerId,
                    activeFrom: values.activeFromClub,
                    activeTo: values.activeToClub,
                }),
                callbackFunction
            );
        } else {
            const clubs =
                selectedClub && selectedClub?.length > 0
                    ? [
                          new CreateClubPlayerCommandOfClubPlayer({
                              playerId: 0,
                              clubId: parseInt(selectedClub[0].id, 10),
                              activeFrom: values.activeFromClub,
                              activeTo: values.activeToClub,
                          }),
                      ]
                    : undefined;

            if (selectedProfileOption === ProfileOption.Existing) {
                createHelper(
                    new PlayersClient(),
                    new CreatePlayerCommand({
                        personId: values.personId,
                        clubs,
                        teams: undefined,
                        weight: values.weight,
                        height: values.height,
                        dominantHand: values.dominantHand,
                        primaryPositionId,
                    }),
                    callbackFunction
                );
            } else {
                createHelper(
                    new PersonsClient(),
                    new CreatePersonCommandOfPerson({
                        player: new CreatePlayerCommand({
                            personId: values.personId,
                            clubs,
                            teams: undefined,
                            weight: values.weight,
                            height: values.height,
                            dominantHand: values.dominantHand,
                            primaryPositionId,
                        }),
                        name: values.name,
                        surname: values.surname,
                        birthDate,
                        nationality: values.nationality,
                        address: values.address,
                        email: values.email,
                        phone: values.phone,
                        facebook: values.facebook,
                        youtube: values.youtube,
                        twitter: values.twitter,
                        instagram: values.instagram,
                        photo: fileBase64 || undefined,
                        countryOfBirthId,
                        cityOfBirthId,
                        playerId: undefined,
                        coachId: undefined,
                        coach: undefined,
                        officialId: undefined,
                        official: undefined,
                    }),
                    callbackFunction
                );
            }
        }
    }
    // TEAM PLAYERS PAGE
    else if (initialValues?.teamId) {
        const positionId =
            selectedTeamPosition && selectedTeamPosition.length > 0
                ? parseInt(selectedTeamPosition[0].id, 10)
                : undefined;

        if (initialTeamPlayer) {
            updateHelper(
                new TeamPlayersClient(),
                {
                    ...new UpdateTeamPlayerCommandOfTeamPlayer({
                        id: 0,
                        teamId: initialValues.teamId,
                        playerId: initialTeamPlayer.playerId,
                        activeFrom: values.activeFromTeam,
                        activeTo: values.activeToTeam,
                        positionId,
                        capNumber: values.capNumber,
                    }),
                    // constructor doesn't set id correctly so we set it manually
                    id: initialTeamPlayer.id,
                },
                callbackFunction
            );
        } else if (selectedProfileOption === ProfileOption.Existing && selectedPerson?.playerId) {
            createHelper(
                new TeamPlayersClient(),
                new CreateTeamPlayerCommandOfTeamPlayer({
                    teamId: initialValues.teamId,
                    playerId: selectedPerson.playerId,
                    activeFrom: values.activeFromTeam,
                    activeTo: values.activeToTeam,
                    positionId,
                    capNumber: values.capNumber,
                }),
                callbackFunction
            );
        } else {
            if (selectedProfileOption === ProfileOption.Existing) {
                createHelper(
                    new PlayersClient(),
                    new CreatePlayerCommand({
                        personId: values.personId,
                        clubs: undefined,
                        teams:
                            selectedTeam && selectedTeam.length > 0
                                ? [
                                      new CreateTeamPlayerCommandOfTeamPlayer({
                                          playerId: 0,
                                          teamId: parseInt(selectedTeam[0].id, 10),
                                          activeFrom: values.activeFromTeam,
                                          activeTo: values.activeToTeam,
                                          positionId,
                                          capNumber: values.capNumber,
                                      }),
                                  ]
                                : undefined,
                        weight: values.weight,
                        height: values.height,
                        dominantHand: values.dominantHand,
                        primaryPositionId,
                    }),
                    callbackFunction
                );
            } else {
                createHelper(
                    new PersonsClient(),
                    new CreatePersonCommandOfPerson({
                        player: new CreatePlayerCommand({
                            personId: values.personId,
                            clubs: undefined,
                            teams:
                                selectedTeam && selectedTeam.length > 0
                                    ? [
                                          new CreateTeamPlayerCommandOfTeamPlayer({
                                              playerId: 0,
                                              teamId: parseInt(selectedTeam[0].id, 10),
                                              activeFrom: values.activeFromTeam,
                                              activeTo: values.activeToTeam,
                                              positionId,
                                              capNumber: values.capNumber,
                                          }),
                                      ]
                                    : undefined,
                            weight: values.weight,
                            height: values.height,
                            dominantHand: values.dominantHand,
                            primaryPositionId,
                        }),
                        name: values.name,
                        surname: values.surname,
                        birthDate,
                        nationality: values.nationality,
                        address: values.address,
                        email: values.email,
                        phone: values.phone,
                        facebook: values.facebook,
                        youtube: values.youtube,
                        twitter: values.twitter,
                        instagram: values.instagram,
                        photo: fileBase64 || undefined,
                        countryOfBirthId,
                        cityOfBirthId,
                        playerId: undefined,
                        coachId: undefined,
                        coach: undefined,
                        officialId: undefined,
                        official: undefined,
                    }),
                    callbackFunction
                );
            }
        }
    }
    // PLAYERS PAGE
    else {
        const clubs =
            selectedPlayerClubs && selectedPlayerClubs.length > 0 ? selectedPlayerClubs : undefined;
        const teams =
            selectedPlayerTeams && selectedPlayerTeams.length > 0 ? selectedPlayerTeams : undefined;

        if (selectedProfileOption === ProfileOption.Existing) {
            createHelper(
                new PlayersClient(),
                new CreatePlayerCommand({
                    personId: values.personId,
                    clubs,
                    teams,
                    weight: values.weight,
                    height: values.height,
                    dominantHand: values.dominantHand,
                    primaryPositionId,
                }),
                callbackFunction
            );
        } else if (player?.id) {
            updateHelper(
                new PlayersClient(),
                {
                    ...new UpdatePlayerCommandOfPlayer({
                        id: player.id,
                        weight: values.weight,
                        height: values.height,
                        dominantHand: values.dominantHand,
                        primaryPositionId,
                    }),
                    // constructor doesn't set id correctly so we set it manually
                    id: player.id,
                },
                callbackFunction
            );
        } else {
            createHelper(
                new PersonsClient(),
                new CreatePersonCommandOfPerson({
                    player: new CreatePlayerCommand({
                        personId: values.personId,
                        clubs,
                        teams,
                        weight: values.weight,
                        height: values.height,
                        dominantHand: values.dominantHand,
                        primaryPositionId,
                    }),
                    name: values.name,
                    surname: values.surname,
                    birthDate,
                    nationality: values.nationality,
                    address: values.address,
                    email: values.email,
                    phone: values.phone,
                    facebook: values.facebook,
                    youtube: values.youtube,
                    twitter: values.twitter,
                    instagram: values.instagram,
                    photo: fileBase64 || undefined,
                    countryOfBirthId,
                    cityOfBirthId,
                    playerId: undefined,
                    coachId: undefined,
                    coach: undefined,
                    officialId: undefined,
                    official: undefined,
                }),
                callbackFunction
            );
        }
    }
};

export const createMatchPlayerCommandFromDropdowns = async (
    userProfile: Profile,
    match: MatchDetailVm,
    homePlayerDropdowns: DropdownOption[][],
    awayPlayerDropdowns: DropdownOption[][]
): Promise<CreateMatchPlayerCommandOfMatchPlayer[]> => {
    const results: CreateMatchPlayerCommandOfMatchPlayer[] = [];
    const teamClient = new TeamsClient();
    const isAllowedHomeTeam = isUserAllowed(
        userProfile,
        ModuleName.Teams,
        ActionOption.Read,
        match.homeTeamId
    );
    const isAllowedAwayTeam = isUserAllowed(
        userProfile,
        ModuleName.Teams,
        ActionOption.Read,
        match.awayTeamId
    );
    let homePlayers: TeamPlayerVm[] = [];
    let awayPlayers: TeamPlayerVm[] = [];
    const matchPlayers = match.players;

    if (isAllowedHomeTeam) {
        const homeTeam = await teamClient.getById(match.homeTeamId);
        homePlayers = homeTeam.players;
    }
    if (isAllowedAwayTeam) {
        const awayTeam = await teamClient.getById(match.awayTeamId);
        awayPlayers = awayTeam.players;
    }

    homePlayerDropdowns.forEach((hpd: DropdownOption[], index: number): void => {
        if (hpd.length > 0) {
            const oldPlayer =
                matchPlayers && matchPlayers.length > 0
                    ? matchPlayers.find(
                          (p: MatchPlayerVm): boolean => p.playerId.toString() === hpd[0].id
                      )
                    : undefined;
            const oldTeamPlayer =
                !oldPlayer && homePlayers && homePlayers.length > 0
                    ? homePlayers.find(
                          (p: TeamPlayerVm): boolean => p.playerId.toString() === hpd[0].id
                      )
                    : undefined;
            results.push(
                new CreateMatchPlayerCommandOfMatchPlayer({
                    id: oldPlayer?.id ?? 0,
                    number: index + 1,
                    capNumber: oldTeamPlayer?.capNumber || oldPlayer?.capNumber || index + 1,
                    matchId: match.id,
                    playerId: parseInt(hpd[0].id, 10),
                    teamId: match.homeTeamId,
                    positionId: oldPlayer
                        ? oldPlayer.positionId
                        : oldTeamPlayer
                        ? oldTeamPlayer.positionId ||
                          oldTeamPlayer.player.primaryPositionId ||
                          PlayerPositionEnum.Driver
                        : PlayerPositionEnum.Driver,
                })
            );
        }
    });

    awayPlayerDropdowns.forEach((apd: DropdownOption[], index: number): void => {
        if (apd.length > 0) {
            const oldPlayer =
                matchPlayers && matchPlayers.length > 0
                    ? matchPlayers.find(
                          (p: MatchPlayerVm): boolean => p.playerId.toString() === apd[0].id
                      )
                    : undefined;
            const oldTeamPlayer =
                !oldPlayer && awayPlayers && awayPlayers.length > 0
                    ? awayPlayers.find(
                          (p: TeamPlayerVm): boolean => p.playerId.toString() === apd[0].id
                      )
                    : undefined;
            results.push(
                new CreateMatchPlayerCommandOfMatchPlayer({
                    id: oldPlayer?.id ?? 0,
                    number: index + 1,
                    capNumber: oldTeamPlayer?.capNumber || oldPlayer?.capNumber || index + 1,
                    matchId: match.id,
                    playerId: parseInt(apd[0].id, 10),
                    teamId: match.awayTeamId,
                    positionId: oldPlayer
                        ? oldPlayer.positionId
                        : oldTeamPlayer
                        ? oldTeamPlayer.positionId ||
                          oldTeamPlayer.player.primaryPositionId ||
                          PlayerPositionEnum.Driver
                        : PlayerPositionEnum.Driver,
                })
            );
        }
    });

    return results;
};
