import moment from 'moment';
import { ReactElement } from 'react';
import { DropdownOption } from '../core/models/DropdownOption';
import { OfficialVmWithRoles } from '../core/models/OfficialVmWithRoles';
import { PersonOption } from '../modules/players/player-form/PlayerForm';
import {
    ClubCoachVm,
    ClubPlayerVm,
    ClubVm,
    CoachVm,
    CompetitionTeamVm,
    CompetitionVm,
    FederationOfficialRoleVm,
    FederationOfficialVm,
    MatchCoachVm,
    MatchOfficialVm,
    MatchPlayerVm,
    MatchVm,
    PersonVm,
    PlayerVm,
    TeamCoachVm,
    TeamPlayerVm,
    TeamVm,
} from '../utils/api';
import { convertUTCTimeToLocal } from './DateHelper';

export const transformCompetitionTeamsToTeams = (
    competitionTeams?: CompetitionTeamVm[]
): TeamVm[] | undefined => {
    if (competitionTeams) {
        const teams: TeamVm[] = [];

        competitionTeams.forEach((cT: CompetitionTeamVm): void => {
            if (cT.team) {
                teams.push(cT.team);
            }
        });

        return teams.length > 0 ? teams : undefined;
    }
    return undefined;
};

export const transformTeamCompetitionsToCompetitions = (
    teamCompetitions?: CompetitionTeamVm[]
): CompetitionVm[] | undefined => {
    if (teamCompetitions) {
        const competitions: CompetitionVm[] = [];

        teamCompetitions.forEach((tC: CompetitionTeamVm): void => {
            if (tC.competition) {
                competitions.push(tC.competition);
            }
        });

        return competitions.length > 0 ? competitions : undefined;
    }
    return undefined;
};

export const transformHomeAwayMatchesInOne = (
    homeMatches?: MatchVm[],
    awayMatches?: MatchVm[]
): MatchVm[] | undefined => {
    let matches: MatchVm[] = homeMatches || [];

    if (awayMatches) {
        matches = [...matches, ...awayMatches];
    }

    return matches.length > 0 ? matches : undefined;
};

export const transformPersonOptionsToArrayString = (person: PersonVm): string[] => {
    const personOptions: string[] = [];
    if (person.officialId) {
        personOptions.push('Official');
    }
    if (person.coachId) {
        personOptions.push('Coach');
    }
    if (person.playerId) {
        personOptions.push('Player');
    }
    return personOptions;
};

export const checkIfPersonIsCoach = (person: PersonVm): boolean => (person.coachId ? true : false);

export const checkIfPersonIsPlayer = (person: PersonVm): boolean =>
    person.playerId ? true : false;

export const transformRoleOfficialToOfficialWithRoles = (
    roleOfficial: FederationOfficialVm
): OfficialVmWithRoles =>
    ({
        ...roleOfficial.official,
        roles: roleOfficial.roles
            ? roleOfficial.roles.map((r: FederationOfficialRoleVm): string => r.role)
            : [],
    } as OfficialVmWithRoles);

export const transformMatchRoleOfficialToOfficialWithRoles = (
    roleOfficial: MatchOfficialVm
): OfficialVmWithRoles =>
    ({
        ...roleOfficial.official,
        roles: roleOfficial.role ? [roleOfficial.role] : [],
    } as OfficialVmWithRoles);

export const transformCoachClubsToClubs = (coachClubs?: ClubCoachVm[]): ClubVm[] | undefined => {
    if (coachClubs) {
        const clubs: ClubVm[] = [];

        coachClubs.forEach((cC: ClubCoachVm): void => {
            if (cC.club) {
                clubs.push(cC.club);
            }
        });

        return clubs.length > 0 ? clubs : undefined;
    }
    return undefined;
};

export const transformCoachClubsToCoaches = (coachClubs?: ClubCoachVm[]): CoachVm[] | undefined => {
    if (coachClubs) {
        const coaches: CoachVm[] = [];

        coachClubs.forEach((cC: ClubCoachVm): void => {
            if (cC.coach) {
                coaches.push(cC.coach);
            }
        });

        return coaches.length > 0 ? coaches : undefined;
    }
    return undefined;
};

export const transformCoachTeamsToTeams = (coachTeams?: TeamCoachVm[]): TeamVm[] | undefined => {
    if (coachTeams) {
        const teams: TeamVm[] = [];

        coachTeams.forEach((cT: TeamCoachVm): void => {
            if (cT.team) {
                teams.push(cT.team);
            }
        });

        return teams.length > 0 ? teams : undefined;
    }
    return undefined;
};

export const transformCoachTeamsToCoaches = (coachTeams?: TeamCoachVm[]): CoachVm[] | undefined => {
    if (coachTeams) {
        const coaches: CoachVm[] = [];

        coachTeams.forEach((cT: TeamCoachVm): void => {
            if (cT.coach) {
                coaches.push(cT.coach);
            }
        });

        return coaches.length > 0 ? coaches : undefined;
    }
    return undefined;
};

export const transformToMatchVMArray = (coachMatches?: MatchCoachVm[]): MatchVm[] | undefined => {
    if (coachMatches) {
        const matches: MatchVm[] = [];

        coachMatches.forEach((cT: MatchCoachVm): void => {
            if (cT.match) {
                matches.push(cT.match);
            }
        });

        return matches.length > 0 ? matches : undefined;
    }
    return undefined;
};

export const transformPlayerClubsToClubs = (playerClubs?: ClubPlayerVm[]): ClubVm[] | undefined => {
    if (playerClubs) {
        const clubs: ClubVm[] = [];

        playerClubs.forEach((pC: ClubPlayerVm): void => {
            if (pC.club) {
                clubs.push(pC.club);
            }
        });

        return clubs.length > 0 ? clubs : undefined;
    }
    return undefined;
};

export const transformPlayerClubsToPlayers = (
    playerClubs?: ClubPlayerVm[]
): PlayerVm[] | undefined => {
    if (playerClubs) {
        const players: PlayerVm[] = [];

        playerClubs.forEach((pC: ClubPlayerVm): void => {
            if (pC.player) {
                players.push(pC.player);
            }
        });

        return players.length > 0 ? players : undefined;
    }
    return undefined;
};

export const transformPlayerTeamsToTeams = (playerTeams?: TeamPlayerVm[]): TeamVm[] | undefined => {
    if (playerTeams) {
        const teams: TeamVm[] = [];

        playerTeams.forEach((pT: TeamPlayerVm): void => {
            if (pT.team) {
                teams.push(pT.team);
            }
        });

        return teams.length > 0 ? teams : undefined;
    }
    return undefined;
};

export const transformPlayerTeamsToPlayers = (
    playerTeams?: TeamPlayerVm[]
): PlayerVm[] | undefined => {
    if (playerTeams) {
        const players: PlayerVm[] = [];

        playerTeams.forEach((pT: TeamPlayerVm): void => {
            if (pT.player) {
                players.push(pT.player);
            }
        });

        return players.length > 0 ? players : undefined;
    }
    return undefined;
};

export const transformPlayerMatchesToMatches = (
    playerMatches?: MatchPlayerVm[]
): MatchVm[] | undefined => {
    if (playerMatches) {
        const matches: MatchVm[] = [];

        playerMatches.forEach((pM: MatchPlayerVm): void => {
            if (pM.match) {
                matches.push(pM.match);
            }
        });

        return matches.length > 0 ? matches : undefined;
    }
    return undefined;
};

export const transformEnumToDropdownOptions = (value: any): DropdownOption[] => {
    const keys = Object.keys(value).filter((k) => typeof value[k as any] === 'number');

    return keys.map(
        (key: string): DropdownOption => ({
            id: value[key].toString(),
            name: key.replace('_', ' '),
        })
    );
};

export const transformMatchPlayerToDropdownOptions = (
    matchPlayer: MatchPlayerVm
): DropdownOption => ({
    id: matchPlayer.playerId.toString(),
    name: `${matchPlayer.player.name} ${matchPlayer.player.surname}`,
    capNumber: matchPlayer?.capNumber,
});

export const transformPersonSuggestions = (
    personSuggestions: DropdownOption[],
    callbackFunction: (personSuggestion: DropdownOption) => void
): ReactElement[] =>
    personSuggestions.map(
        (pS: DropdownOption): ReactElement => (
            <span style={{ display: 'block' }}>
                <span
                    onClick={(): void => callbackFunction(pS)}
                    style={{
                        color: '#1890ff',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                    }}
                >
                    {pS.searchableName || pS.name}
                </span>
                <span>{pS.searchableName ? pS.name.replace(pS.searchableName, '') : ''}</span>
            </span>
        )
    );

export const renderPersonSuggestions = (
    personSuggestions: PersonOption[],
    callbackFunction: (personSuggestion: PersonOption) => void
): ReactElement[] =>
    personSuggestions.map(
        (item: PersonOption): ReactElement => (
            <span style={{ display: 'block' }}>
                <span
                    onClick={(): void => callbackFunction(item)}
                    style={{
                        color: '#1890ff',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                    }}
                >
                    {item.label}
                </span>
            </span>
        )
    );

export const transformPersonToDropdownOption = (
    p: PersonVm,
    descriptionalMessage?: string
): DropdownOption => ({
    id: p && p.id ? p.id.toString() : '-1',
    name: `${p.name} ${p.surname} ${
        p.birthDate ? `(${moment(convertUTCTimeToLocal(p.birthDate)).format('DD.MM.YYYY.')})` : ''
    } - ${`${transformPersonOptionsToArrayString(p).join(', ')}`}`,
    searchableName: `${p.name} ${p.surname}`,
    disabled: !!descriptionalMessage,
    descriptionalMessage: descriptionalMessage,
});

export const personToPersonOption = (person: PersonVm): PersonOption => ({
    value: person.id,
    label: `${person.name} ${person.surname} ${
        person.birthDate
            ? `(${moment(convertUTCTimeToLocal(person.birthDate)).format('DD.MM.YYYY.')})`
            : ''
    } - ${`${transformPersonOptionsToArrayString(person).join(', ')}`}`,
    person: person,
});

export const transformTeamToDropdownOption = (t: TeamVm): DropdownOption => ({
    id: t && t.id ? t.id.toString() : '-1',
    name: `${t.nameUniversal || t.name || ''} (${t.category} - ${t.gender})`,
});

export const transformTeamPlayersToDropdownOptions = (
    teamPlayers: MatchPlayerVm[],
    homeTeamId: number
): { home: DropdownOption[]; away: DropdownOption[] } => {
    const homeTeam: DropdownOption[] = [];
    const awayTeam: DropdownOption[] = [];

    // eslint-disable-next-line array-callback-return
    teamPlayers.map((c: MatchPlayerVm): void => {
        if (c.teamId === homeTeamId) {
            homeTeam.push({
                id: c && c.playerId ? c.playerId.toString() : '-1',
                name: `${c.player.name} ${c.player.surname}`,
                capNumber: c.capNumber,
            });
        } else {
            awayTeam.push({
                id: c && c.playerId ? c.playerId.toString() : '-1',
                name: `${c.player.name} ${c.player.surname}`,
                capNumber: c.capNumber,
            });
        }
    });

    return {
        home: homeTeam,
        away: awayTeam,
    };
};

export const capitalizeFirstLetter = (stringToCapitalize?: string): string | undefined => {
    return stringToCapitalize
        ? stringToCapitalize.charAt(0).toUpperCase() + stringToCapitalize.slice(1)
        : undefined;
};
