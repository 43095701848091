import { RouteComponentProps, withRouter } from 'react-router-dom';
import { DrawerState } from '../../../core/models/enum';
import { PlayerFormInitialValues } from '../../../core/models/InitialValues';
import { ClubPlayerVm, PlayerDetailVm, TeamPlayerVm } from '../../../utils/api';
import { RouteParams } from '../../../core/models/RouteHelper';
import PlayerForm from './PlayerForm';

interface OwnProps {
    player?: PlayerDetailVm;
    clubPlayer?: ClubPlayerVm;
    teamPlayer?: TeamPlayerVm;
    initialValues?: PlayerFormInitialValues;
    handleClose: (drawerMode: DrawerState, id?: string) => void;
    refreshAfterSave: () => void;
}

export type Props = OwnProps & RouteComponentProps<RouteParams>;

export default withRouter(PlayerForm);
