import { RouteComponentProps, withRouter } from 'react-router-dom';
import { UserVm } from '../../../utils/api';
import { RouteParams } from '../../../core/models/RouteHelper';
import UserTable from './UserTable';
import { Profile } from 'oidc-client';

interface OwnProps {
    userProfile: Profile;
    wrappedComponentRef?: any;
    usersFromProps?: UserVm[];
}

export type Props = OwnProps & RouteComponentProps<RouteParams>;

export default withRouter(UserTable);
