import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ClubDetailVm } from '../../../../utils/api';
import { RouteParams } from '../../../../core/models/RouteHelper';
import ClubDetails from './ClubDetails';
import { Profile } from 'oidc-client';

interface OwnProps {
    userProfile: Profile;
    isInitialEdit: boolean;
    club: ClubDetailVm;
    refreshAfterSave?: () => Promise<void>;
    hideEditButtons?: boolean;
}

export type Props = OwnProps & RouteComponentProps<RouteParams>;

export default withRouter(ClubDetails);
