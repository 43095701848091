import { Button, Layout, Spin } from 'antd';
import React from 'react';
import Icon from '../../components/icon/Icon';
import { DropdownOption } from '../../core/models/DropdownOption';
import { DrawerState } from '../../core/models/enum';
import {
    getListOfDeletedLineupNumberWithEvent,
    lineupNumberHasEvents,
} from '../../helpers/EventHelper';
import { exportToPdf } from '../../helpers/ExportHelper';
import {
    getCoachesToDropdownOptions,
    getOfficialsToDropdownOptions,
    getTeamPlayersToDropdownOptions,
} from '../../helpers/FetchAndTransformHelpers';
import { errorWithUserMessage, popupError, success } from '../../helpers/NotificationHelper';
import {
    ApiException,
    CoachRoleEnum,
    CreateMatchCoachCommandOfMatchCoach,
    CreateMatchOfficialCommandOfMatchOfficial,
    CreateMatchPlayerCommandOfMatchPlayer,
    EventsClient,
    EventVm,
    MatchCoachVm,
    MatchDetailVm,
    MatchesClient,
    MatchOfficialVm,
    MatchPlayerVm,
    MatchStatusEnum,
    OfficialRoleEnum,
    PlayerPositionEnum,
    UpdateMatchLogCommand,
} from '../../utils/api';
import MatchConfirmLog from './match-confirm-log';
import MatchEventLog from './match-event-log';
import MatchLineup from './match-lineup';
import MatchOfficials from './match-officials';
import MatchScoreboard from './match-scoreboard';
import { Props } from './index';
import './styles.css';

const { Content } = Layout;

export interface State {
    homeTeamPlayers: DropdownOption[];
    awayTeamPlayers: DropdownOption[];
    selectedHomeLineup: CreateMatchPlayerCommandOfMatchPlayer[];
    selectedAwayLineup: CreateMatchPlayerCommandOfMatchPlayer[];
    selectedHomeHeadCoach?: CreateMatchCoachCommandOfMatchCoach;
    selectedHomeAssistCoaches: CreateMatchCoachCommandOfMatchCoach[];
    selectedAwayHeadCoach?: CreateMatchCoachCommandOfMatchCoach;
    selectedAwayAssistCoaches: CreateMatchCoachCommandOfMatchCoach[];
    selectedReferee1?: CreateMatchOfficialCommandOfMatchOfficial;
    selectedReferee2?: CreateMatchOfficialCommandOfMatchOfficial;
    selectedDirector?: CreateMatchOfficialCommandOfMatchOfficial;
    selectedDelegates: CreateMatchOfficialCommandOfMatchOfficial[];
    selectedGoalReferees: CreateMatchOfficialCommandOfMatchOfficial[];
    selectedSecretaries: CreateMatchOfficialCommandOfMatchOfficial[];
    selectedTimekeepers: CreateMatchOfficialCommandOfMatchOfficial[];
    selectedVars: CreateMatchOfficialCommandOfMatchOfficial[];
    coaches: DropdownOption[];
    selectedCoaches: number[];
    selectedOfficials: number[];
    officials: DropdownOption[];
    isLoading: boolean;
    match?: MatchDetailVm;
    events?: EventVm[];
}

class MatchLog extends React.PureComponent<Props, State> {
    private confirmLogRef: any;

    constructor(props: Props) {
        super(props);

        this.confirmLogRef = React.createRef();

        this.state = {
            homeTeamPlayers: [],
            awayTeamPlayers: [],
            selectedHomeLineup: [],
            selectedAwayLineup: [],
            selectedHomeAssistCoaches: [],
            selectedAwayAssistCoaches: [],
            selectedDelegates: [],
            selectedGoalReferees: [],
            selectedSecretaries: [],
            selectedTimekeepers: [],
            selectedVars: [],
            coaches: [],
            selectedCoaches: [],
            selectedOfficials: [],
            officials: [],
            isLoading: false,
        };
    }

    public componentDidMount = (): void => {
        this.getCoaches();
        this.getOfficials();
        this.getMatchDetails();
    };

    private getMatchDetails = async (): Promise<void> => {
        const {
            match: {
                params: { matchId },
            },
        } = this.props;

        this.setState({
            isLoading: true,
        });

        if (matchId) {
            const matchClient = new MatchesClient();
            const eventClient = new EventsClient();

            const match = await matchClient.getById(parseInt(matchId, 10));
            const events = await eventClient.getByMatchId(parseInt(matchId, 10));

            this.setState({
                match,
                events,
                homeTeamPlayers: (await getTeamPlayersToDropdownOptions(match.homeTeamId)).entities,
                awayTeamPlayers: (await getTeamPlayersToDropdownOptions(match.awayTeamId)).entities,
            });
        }

        this.initMatchLog();

        this.setState({
            isLoading: false,
        });
    };

    private getCoaches = async (): Promise<void> => {
        const results = await getCoachesToDropdownOptions();

        this.setState({
            coaches: results.entities,
        });
    };

    private getOfficials = async (): Promise<void> => {
        const results = await getOfficialsToDropdownOptions();

        this.setState({
            officials: results.entities,
        });
    };

    private getPlayerPosition = (cap: number, team: 'home' | 'away'): PlayerPositionEnum => {
        switch (cap) {
            case 1:
            case 13:
                return PlayerPositionEnum.Goalkeeper;
            default:
                return PlayerPositionEnum.Driver;
        }
    };

    private initMatchLog = (): void => {
        const { match } = this.state;

        if (!match) return;

        const homeLineup =
            match.players?.filter(
                (lineup: MatchPlayerVm): boolean => lineup.teamId === match.homeTeamId
            ) || [];
        const awayLineup =
            match.players?.filter(
                (lineup: MatchPlayerVm): boolean => lineup.teamId === match.awayTeamId
            ) || [];

        const homeHeadCoaches =
            match.coaches?.filter(
                (c: MatchCoachVm): boolean =>
                    c.teamId === match.homeTeamId && c.roleId === CoachRoleEnum.Head_Coach
            ) || [];
        const homeAssistantCoaches =
            match.coaches?.filter(
                (c: MatchCoachVm): boolean =>
                    c.teamId === match.homeTeamId && c.roleId === CoachRoleEnum.Assistant_Coach
            ) || [];
        const awayHeadCoaches =
            match.coaches?.filter(
                (c: MatchCoachVm): boolean =>
                    c.teamId === match.awayTeamId && c.roleId === CoachRoleEnum.Head_Coach
            ) || [];
        const awayAssistantCoaches =
            match.coaches?.filter(
                (c: MatchCoachVm): boolean =>
                    c.teamId === match.awayTeamId && c.roleId === CoachRoleEnum.Assistant_Coach
            ) || [];

        const selectedCoaches = match.coaches?.map((c: MatchCoachVm): number => c.coachId) || [];
        const newCoaches = this.mapToAvailableCoachOptions(selectedCoaches);

        const selectedOfficials =
            match.officials?.map((c: MatchOfficialVm): number => c.officialId) || [];
        const newOfficials = this.mapToAvailableOfficialOptions(selectedOfficials);

        const matchReferees =
            match.officials?.filter(
                (o: MatchOfficialVm): boolean => o.roleId === OfficialRoleEnum.Referee
            ) || [];
        const matchDelegates =
            match.officials?.filter(
                (o: MatchOfficialVm): boolean => o.roleId === OfficialRoleEnum.Delegate
            ) || [];
        const matchDirectors =
            match.officials?.filter(
                (o: MatchOfficialVm): boolean => o.roleId === OfficialRoleEnum.Director
            ) || [];
        const matchGoalReferees =
            match.officials?.filter(
                (o: MatchOfficialVm): boolean => o.roleId === OfficialRoleEnum.Goal_Referee
            ) || [];
        const matchSecretaries =
            match.officials?.filter(
                (o: MatchOfficialVm): boolean => o.roleId === OfficialRoleEnum.Secretary
            ) || [];
        const matchTimekeepers =
            match.officials?.filter(
                (o: MatchOfficialVm): boolean => o.roleId === OfficialRoleEnum.Timekeeper
            ) || [];
        const matchVars =
            match.officials?.filter(
                (o: MatchOfficialVm): boolean => o.roleId === OfficialRoleEnum.VAR
            ) || [];

        this.setState({
            selectedHomeLineup: homeLineup as CreateMatchPlayerCommandOfMatchPlayer[],
            selectedAwayLineup: awayLineup as CreateMatchPlayerCommandOfMatchPlayer[],
            selectedHomeHeadCoach: homeHeadCoaches[0] as CreateMatchCoachCommandOfMatchCoach,
            selectedHomeAssistCoaches:
                homeAssistantCoaches as CreateMatchCoachCommandOfMatchCoach[],
            selectedAwayHeadCoach: awayHeadCoaches[0] as CreateMatchCoachCommandOfMatchCoach,
            selectedAwayAssistCoaches:
                awayAssistantCoaches as CreateMatchCoachCommandOfMatchCoach[],
            selectedReferee1: matchReferees[0] as CreateMatchOfficialCommandOfMatchOfficial,
            selectedReferee2: matchReferees[1] as CreateMatchOfficialCommandOfMatchOfficial,
            selectedDirector: matchDirectors[0] as CreateMatchOfficialCommandOfMatchOfficial,
            selectedDelegates: matchDelegates as CreateMatchOfficialCommandOfMatchOfficial[],
            selectedGoalReferees: matchGoalReferees as CreateMatchOfficialCommandOfMatchOfficial[],
            selectedSecretaries: matchSecretaries as CreateMatchOfficialCommandOfMatchOfficial[],
            selectedTimekeepers: matchTimekeepers as CreateMatchOfficialCommandOfMatchOfficial[],
            selectedVars: matchVars as CreateMatchOfficialCommandOfMatchOfficial[],
            coaches: newCoaches,
            selectedCoaches,
            officials: newOfficials,
            selectedOfficials,
        });
    };

    public handleSelectLineupPlayer = (
        isHomeTeam: boolean,
        playerId: number,
        cap: number,
        capNumber: number
    ): void => {
        const { match, homeTeamPlayers, awayTeamPlayers } = this.state;

        if (!match) return;

        if (isHomeTeam) {
            const { selectedHomeLineup } = this.state;
            const positionId = this.getPlayerPosition(cap, 'home');
            const oldValue = selectedHomeLineup.find(
                (p: CreateMatchPlayerCommandOfMatchPlayer): boolean => p.playerId === playerId
            );
            const capNumber = homeTeamPlayers.find((hp: DropdownOption): boolean => hp.id === playerId.toString())?.capNumber;

            const newLineupRequest: CreateMatchPlayerCommandOfMatchPlayer =
                new CreateMatchPlayerCommandOfMatchPlayer({
                    id: oldValue?.id ?? 0,
                    matchId: match.id,
                    teamId: match.homeTeamId,
                    playerId: playerId,
                    positionId: positionId,
                    number: cap,
                    capNumber: capNumber || cap,
                });

            const index = selectedHomeLineup.findIndex(
                (l: CreateMatchPlayerCommandOfMatchPlayer): boolean =>
                    l.teamId === match.homeTeamId && l.number === cap
            );

            if (index !== -1) {
                // remove existing item from lineup and add newly selected to lineup
                this.setState({
                    selectedHomeLineup: [
                        ...selectedHomeLineup.slice(0, index),
                        ...selectedHomeLineup.slice(index + 1),
                        newLineupRequest,
                    ],
                });
            } else {
                // add newly selected item to lineup
                this.setState({
                    selectedHomeLineup: [...selectedHomeLineup, newLineupRequest],
                });
            }
        } else {
            const { selectedAwayLineup } = this.state;
            const positionId = this.getPlayerPosition(cap, 'away');

            const oldValue = selectedAwayLineup.find(
                (p: CreateMatchPlayerCommandOfMatchPlayer): boolean => p.playerId === playerId
            );

            const capNumber = awayTeamPlayers.find((hp: DropdownOption): boolean => hp.id === playerId.toString())?.capNumber;

            const newLineupRequest: CreateMatchPlayerCommandOfMatchPlayer =
                new CreateMatchPlayerCommandOfMatchPlayer({
                    id: oldValue?.id ?? 0,
                    matchId: match.id,
                    teamId: match.awayTeamId,
                    playerId: playerId,
                    positionId: positionId,
                    number: cap,
                    capNumber: capNumber || cap,
                });

            const index = selectedAwayLineup.findIndex(
                (l: CreateMatchPlayerCommandOfMatchPlayer): boolean =>
                    l.teamId === match.awayTeamId && l.number === cap
            );

            if (index !== -1) {
                // remove existing item from lineup and add newly selected to lineup
                this.setState({
                    selectedAwayLineup: [
                        ...selectedAwayLineup.slice(0, index),
                        ...selectedAwayLineup.slice(index + 1),
                        newLineupRequest,
                    ],
                });
            } else {
                // add newly selected item to lineup
                this.setState({
                    selectedAwayLineup: [...selectedAwayLineup, newLineupRequest],
                });
            }
        }
    };

    public handleClearLineupPlayer = (isHomeTeam: boolean, cap: number): void => {
        const { match, events } = this.state;

        if (!match) return;

        if (isHomeTeam) {
            const { selectedHomeLineup } = this.state;

            if (lineupNumberHasEvents(events || [], cap, match.homeTeamId)) {
                const errorMessage =
                    'This home team player cannot be deleted because he is referenced by an event!';
                popupError(errorMessage);
                return;
            }

            const index = selectedHomeLineup.findIndex(
                (l: CreateMatchPlayerCommandOfMatchPlayer): boolean =>
                    l.teamId === match.homeTeamId && l.number === cap
            );

            if (index !== -1) {
                this.setState({
                    selectedHomeLineup: [
                        ...selectedHomeLineup.slice(0, index),
                        ...selectedHomeLineup.slice(index + 1),
                    ],
                });
            }
        } else {
            const { selectedAwayLineup } = this.state;

            if (lineupNumberHasEvents(events || [], cap, match.awayTeamId)) {
                const errorMessage =
                    'This away team player cannot be deleted because he is referenced by an event!';
                popupError(errorMessage);
                return;
            }

            const index = selectedAwayLineup.findIndex(
                (l: CreateMatchPlayerCommandOfMatchPlayer): boolean =>
                    l.teamId === match.awayTeamId && l.number === cap
            );

            if (index !== -1) {
                this.setState({
                    selectedAwayLineup: [
                        ...selectedAwayLineup.slice(0, index),
                        ...selectedAwayLineup.slice(index + 1),
                    ],
                });
            }
        }
    };

    public handleSelectCoach = (
        isHomeTeam: boolean,
        coachRole: CoachRoleEnum,
        coachId: number
    ): void => {
        const { match, selectedCoaches } = this.state;

        if (!match) return;

        const oldValue = match.coaches?.find((c: MatchCoachVm): boolean => c.coachId === coachId);

        const newMatchCoach: CreateMatchCoachCommandOfMatchCoach =
            new CreateMatchCoachCommandOfMatchCoach({
                id: oldValue?.id ?? 0,
                matchId: match.id,
                teamId: isHomeTeam ? match.homeTeamId : match.awayTeamId,
                coachId: coachId,
                roleId: coachRole,
            });

        if (isHomeTeam) {
            if (coachRole === CoachRoleEnum.Head_Coach) {
                this.setState({
                    selectedHomeHeadCoach: newMatchCoach,
                });
            }
            if (coachRole === CoachRoleEnum.Assistant_Coach) {
                this.setState(
                    (prevState: State): State => ({
                        ...prevState,
                        selectedHomeAssistCoaches: [
                            ...prevState.selectedHomeAssistCoaches,
                            newMatchCoach,
                        ],
                    })
                );
            }
        } else {
            if (coachRole === CoachRoleEnum.Head_Coach) {
                this.setState({
                    selectedAwayHeadCoach: newMatchCoach,
                });
            }
            if (coachRole === CoachRoleEnum.Assistant_Coach) {
                this.setState(
                    (prevState: State): State => ({
                        ...prevState,
                        selectedAwayAssistCoaches: [
                            ...prevState.selectedAwayAssistCoaches,
                            newMatchCoach,
                        ],
                    })
                );
            }
        }

        const newSelectedCoaches = [...selectedCoaches, coachId];
        const newCoaches = this.mapToAvailableCoachOptions(newSelectedCoaches);
        this.setState({
            coaches: newCoaches,
            selectedCoaches: newSelectedCoaches,
        });
    };

    public handleClearCoach = (
        isHomeTeam: boolean,
        coachRole: CoachRoleEnum,
        coachIds?: number[]
    ): void => {
        const { match, selectedHomeAssistCoaches, selectedAwayAssistCoaches } = this.state;

        if (!match) return;

        if (isHomeTeam && coachRole === CoachRoleEnum.Head_Coach) {
            this.setState(
                (prevState: State): State => ({
                    ...prevState,
                    selectedHomeHeadCoach: undefined,
                    selectedCoaches: prevState.selectedCoaches.filter(
                        (c: number): boolean => c !== prevState.selectedHomeHeadCoach?.coachId
                    ),
                    coaches: this.mapToAvailableCoachOptions(
                        prevState.selectedCoaches.filter(
                            (c: number): boolean => c !== prevState.selectedHomeHeadCoach?.coachId
                        )
                    ),
                })
            );
        }
        if (isHomeTeam && coachRole === CoachRoleEnum.Assistant_Coach && coachIds) {
            const deletedAssistCoaches = selectedHomeAssistCoaches.filter(
                (c: CreateMatchCoachCommandOfMatchCoach): boolean => !coachIds.includes(c.coachId)
            );
            this.setState(
                (prevState: State): State => ({
                    ...prevState,
                    selectedHomeAssistCoaches: prevState.selectedHomeAssistCoaches.filter(
                        (c: CreateMatchCoachCommandOfMatchCoach): boolean =>
                            coachIds.includes(c.coachId)
                    ),
                    selectedCoaches: prevState.selectedCoaches.filter(
                        (c: number): boolean =>
                            !deletedAssistCoaches.find(
                                (d: CreateMatchCoachCommandOfMatchCoach): boolean => d.coachId === c
                            )
                    ),
                    coaches: this.mapToAvailableCoachOptions(
                        prevState.selectedCoaches.filter(
                            (c: number): boolean =>
                                !deletedAssistCoaches.find(
                                    (d: CreateMatchCoachCommandOfMatchCoach): boolean =>
                                        d.coachId === c
                                )
                        )
                    ),
                })
            );
        }
        if (!isHomeTeam && coachRole === CoachRoleEnum.Head_Coach) {
            this.setState(
                (prevState: State): State => ({
                    ...prevState,
                    selectedAwayHeadCoach: undefined,
                    selectedCoaches: prevState.selectedCoaches.filter(
                        (c: number): boolean => c !== prevState.selectedAwayHeadCoach?.coachId
                    ),
                    coaches: this.mapToAvailableCoachOptions(
                        prevState.selectedCoaches.filter(
                            (c: number): boolean => c !== prevState.selectedAwayHeadCoach?.coachId
                        )
                    ),
                })
            );
        }
        if (!isHomeTeam && coachRole === CoachRoleEnum.Assistant_Coach && coachIds) {
            const deletedAssistCoaches = selectedAwayAssistCoaches.filter(
                (c: CreateMatchCoachCommandOfMatchCoach): boolean => !coachIds.includes(c.coachId)
            );
            this.setState(
                (prevState: State): State => ({
                    ...prevState,
                    selectedAwayAssistCoaches: prevState.selectedAwayAssistCoaches.filter(
                        (c: CreateMatchCoachCommandOfMatchCoach): boolean =>
                            coachIds.includes(c.coachId)
                    ),
                    selectedCoaches: prevState.selectedCoaches.filter(
                        (c: number): boolean =>
                            !deletedAssistCoaches.find(
                                (d: CreateMatchCoachCommandOfMatchCoach): boolean => d.coachId === c
                            )
                    ),
                    coaches: this.mapToAvailableCoachOptions(
                        prevState.selectedCoaches.filter(
                            (c: number): boolean =>
                                !deletedAssistCoaches.find(
                                    (d: CreateMatchCoachCommandOfMatchCoach): boolean =>
                                        d.coachId === c
                                )
                        )
                    ),
                })
            );
        }
    };

    private mapToAvailableCoachOptions = (selectedCoaches: number[]): DropdownOption[] => {
        const { coaches } = this.state;
        return coaches.map((c: DropdownOption): DropdownOption => {
            const isDisabled = selectedCoaches.find(
                (sc: number): boolean => sc.toString() === c.id
            );
            return {
                ...c,
                disabled: !!isDisabled,
            };
        });
    };

    public handleSelectOfficial = (
        officialRole: OfficialRoleEnum,
        officialId: number,
        referee?: number
    ): void => {
        const { match, selectedOfficials } = this.state;

        if (!match) return;

        const oldValue = match.officials?.find(
            (o: MatchOfficialVm): boolean =>
                o.officialId === officialId && o.roleId === officialRole
        );

        const newMatchOfficial: CreateMatchOfficialCommandOfMatchOfficial =
            new CreateMatchOfficialCommandOfMatchOfficial({
                id: oldValue?.id ?? 0,
                matchId: match.id,
                officialId: officialId,
                roleId: officialRole,
            });

        if (officialRole === OfficialRoleEnum.Referee && referee === 1) {
            this.setState({
                selectedReferee1: newMatchOfficial,
            });
        }
        if (officialRole === OfficialRoleEnum.Referee && referee === 2) {
            this.setState({
                selectedReferee2: newMatchOfficial,
            });
        }
        if (officialRole === OfficialRoleEnum.Director) {
            this.setState({
                selectedDirector: newMatchOfficial,
            });
        }
        if (officialRole === OfficialRoleEnum.Delegate) {
            this.setState(
                (prevState: State): State => ({
                    ...prevState,
                    selectedDelegates: [...prevState.selectedDelegates, newMatchOfficial],
                })
            );
        }
        if (officialRole === OfficialRoleEnum.Goal_Referee) {
            this.setState(
                (prevState: State): State => ({
                    ...prevState,
                    selectedGoalReferees: [...prevState.selectedGoalReferees, newMatchOfficial],
                })
            );
        }
        if (officialRole === OfficialRoleEnum.Secretary) {
            this.setState(
                (prevState: State): State => ({
                    ...prevState,
                    selectedSecretaries: [...prevState.selectedSecretaries, newMatchOfficial],
                })
            );
        }
        if (officialRole === OfficialRoleEnum.Timekeeper) {
            this.setState(
                (prevState: State): State => ({
                    ...prevState,
                    selectedTimekeepers: [...prevState.selectedTimekeepers, newMatchOfficial],
                })
            );
        }
        if (officialRole === OfficialRoleEnum.VAR) {
            this.setState(
                (prevState: State): State => ({
                    ...prevState,
                    selectedVars: [...prevState.selectedVars, newMatchOfficial],
                })
            );
        }

        const newSelectedOfficials = [...selectedOfficials, officialId];
        const newOfficials = this.mapToAvailableOfficialOptions(newSelectedOfficials);
        this.setState({
            officials: newOfficials,
            selectedOfficials: newSelectedOfficials,
        });
    };

    public handleClearOfficial = (
        officialRole: OfficialRoleEnum,
        officialIds?: number[],
        referee?: number
    ): void => {
        const {
            match,
            selectedGoalReferees,
            selectedDelegates,
            selectedSecretaries,
            selectedTimekeepers,
            selectedVars,
        } = this.state;

        if (!match) return;

        if (officialRole === OfficialRoleEnum.Referee && referee === 1) {
            this.setState(
                (prevState: State): State => ({
                    ...prevState,
                    selectedReferee1: undefined,
                    selectedOfficials: prevState.selectedOfficials.filter(
                        (o: number): boolean => o !== prevState.selectedReferee1?.officialId
                    ),
                    officials: this.mapToAvailableOfficialOptions(
                        prevState.selectedOfficials.filter(
                            (o: number): boolean => o !== prevState.selectedReferee1?.officialId
                        )
                    ),
                })
            );
        }
        if (officialRole === OfficialRoleEnum.Referee && referee === 2) {
            this.setState(
                (prevState: State): State => ({
                    ...prevState,
                    selectedReferee2: undefined,
                    selectedOfficials: prevState.selectedOfficials.filter(
                        (o: number): boolean => o !== prevState.selectedReferee2?.officialId
                    ),
                    officials: this.mapToAvailableOfficialOptions(
                        prevState.selectedOfficials.filter(
                            (o: number): boolean => o !== prevState.selectedReferee2?.officialId
                        )
                    ),
                })
            );
        }
        if (officialRole === OfficialRoleEnum.Director) {
            this.setState(
                (prevState: State): State => ({
                    ...prevState,
                    selectedDirector: undefined,
                    selectedOfficials: prevState.selectedOfficials.filter(
                        (o: number): boolean => o !== prevState.selectedDirector?.officialId
                    ),
                    officials: this.mapToAvailableOfficialOptions(
                        prevState.selectedOfficials.filter(
                            (o: number): boolean => o !== prevState.selectedDirector?.officialId
                        )
                    ),
                })
            );
        }
        if (officialRole === OfficialRoleEnum.Delegate && officialIds) {
            const deletedDelagates = selectedDelegates.filter(
                (so: CreateMatchOfficialCommandOfMatchOfficial): boolean =>
                    !officialIds.includes(so.officialId)
            );
            this.setState(
                (prevState: State): State => ({
                    ...prevState,
                    selectedDelegates: prevState.selectedDelegates.filter(
                        (o: CreateMatchOfficialCommandOfMatchOfficial): boolean =>
                            officialIds.includes(o.officialId)
                    ),
                    selectedOfficials: prevState.selectedOfficials.filter(
                        (o: number): boolean =>
                            !deletedDelagates.find(
                                (d: CreateMatchOfficialCommandOfMatchOfficial): boolean =>
                                    d.officialId === o
                            )
                    ),
                    officials: this.mapToAvailableOfficialOptions(
                        prevState.selectedOfficials.filter(
                            (o: number): boolean =>
                                !deletedDelagates.find(
                                    (d: CreateMatchOfficialCommandOfMatchOfficial): boolean =>
                                        d.officialId === o
                                )
                        )
                    ),
                })
            );
        }
        if (officialRole === OfficialRoleEnum.Goal_Referee && officialIds) {
            const deletedGoalReferees = selectedGoalReferees.filter(
                (so: CreateMatchOfficialCommandOfMatchOfficial): boolean =>
                    !officialIds.includes(so.officialId)
            );
            this.setState(
                (prevState: State): State => ({
                    ...prevState,
                    selectedGoalReferees: prevState.selectedGoalReferees.filter(
                        (o: CreateMatchOfficialCommandOfMatchOfficial): boolean =>
                            officialIds.includes(o.officialId)
                    ),
                    selectedOfficials: prevState.selectedOfficials.filter(
                        (o: number): boolean =>
                            !deletedGoalReferees.find(
                                (d: CreateMatchOfficialCommandOfMatchOfficial): boolean =>
                                    d.officialId === o
                            )
                    ),
                    officials: this.mapToAvailableOfficialOptions(
                        prevState.selectedOfficials.filter(
                            (o: number): boolean =>
                                !deletedGoalReferees.find(
                                    (d: CreateMatchOfficialCommandOfMatchOfficial): boolean =>
                                        d.officialId === o
                                )
                        )
                    ),
                })
            );
        }
        if (officialRole === OfficialRoleEnum.Secretary && officialIds) {
            const deletedSecretaries = selectedSecretaries.filter(
                (so: CreateMatchOfficialCommandOfMatchOfficial): boolean =>
                    !officialIds.includes(so.officialId)
            );
            this.setState(
                (prevState: State): State => ({
                    ...prevState,
                    selectedSecretaries: prevState.selectedSecretaries.filter(
                        (o: CreateMatchOfficialCommandOfMatchOfficial): boolean =>
                            officialIds.includes(o.officialId)
                    ),
                    selectedOfficials: prevState.selectedOfficials.filter(
                        (o: number): boolean =>
                            !deletedSecretaries.find(
                                (d: CreateMatchOfficialCommandOfMatchOfficial): boolean =>
                                    d.officialId === o
                            )
                    ),
                    officials: this.mapToAvailableOfficialOptions(
                        prevState.selectedOfficials.filter(
                            (o: number): boolean =>
                                !deletedSecretaries.find(
                                    (d: CreateMatchOfficialCommandOfMatchOfficial): boolean =>
                                        d.officialId === o
                                )
                        )
                    ),
                })
            );
        }
        if (officialRole === OfficialRoleEnum.Timekeeper && officialIds) {
            const deletedTimeKeepers = selectedTimekeepers.filter(
                (so: CreateMatchOfficialCommandOfMatchOfficial): boolean =>
                    !officialIds.includes(so.officialId)
            );
            this.setState(
                (prevState: State): State => ({
                    ...prevState,
                    selectedTimekeepers: prevState.selectedTimekeepers.filter(
                        (o: CreateMatchOfficialCommandOfMatchOfficial): boolean =>
                            officialIds.includes(o.officialId)
                    ),
                    selectedOfficials: prevState.selectedOfficials.filter(
                        (o: number): boolean =>
                            !deletedTimeKeepers.find(
                                (d: CreateMatchOfficialCommandOfMatchOfficial): boolean =>
                                    d.officialId === o
                            )
                    ),
                    officials: this.mapToAvailableOfficialOptions(
                        prevState.selectedOfficials.filter(
                            (o: number): boolean =>
                                !deletedTimeKeepers.find(
                                    (d: CreateMatchOfficialCommandOfMatchOfficial): boolean =>
                                        d.officialId === o
                                )
                        )
                    ),
                })
            );
        }
        if (officialRole === OfficialRoleEnum.VAR && officialIds) {
            const deletedVars = selectedVars.filter(
                (so: CreateMatchOfficialCommandOfMatchOfficial): boolean =>
                    !officialIds.includes(so.officialId)
            );
            this.setState(
                (prevState: State): State => ({
                    ...prevState,
                    selectedVars: prevState.selectedVars.filter(
                        (o: CreateMatchOfficialCommandOfMatchOfficial): boolean =>
                            officialIds.includes(o.officialId)
                    ),
                    selectedOfficials: prevState.selectedOfficials.filter(
                        (o: number): boolean =>
                            !deletedVars.find(
                                (d: CreateMatchOfficialCommandOfMatchOfficial): boolean =>
                                    d.officialId === o
                            )
                    ),
                    officials: this.mapToAvailableOfficialOptions(
                        prevState.selectedOfficials.filter(
                            (o: number): boolean =>
                                !deletedVars.find(
                                    (d: CreateMatchOfficialCommandOfMatchOfficial): boolean =>
                                        d.officialId === o
                                )
                        )
                    ),
                })
            );
        }
    };

    private mapToAvailableOfficialOptions = (selectedOfficials: number[]): DropdownOption[] => {
        const { officials } = this.state;
        return officials.map((o: DropdownOption): DropdownOption => {
            const isDisabled = selectedOfficials.find(
                (so: number): boolean => so.toString() === o.id
            );
            return {
                ...o,
                disabled: !!isDisabled,
            };
        });
    };

    private updateMatchLog = async (): Promise<void> => {
        const {
            selectedHomeLineup,
            selectedAwayLineup,
            selectedHomeHeadCoach,
            selectedHomeAssistCoaches,
            selectedAwayHeadCoach,
            selectedAwayAssistCoaches,
            selectedReferee1,
            selectedReferee2,
            selectedDirector,
            selectedDelegates,
            selectedGoalReferees,
            selectedSecretaries,
            selectedTimekeepers,
            selectedVars,
            match,
            events,
        } = this.state;

        if (!match) return;

        this.setState({ isLoading: true })

        const deletedHomePlayersWithEvents = getListOfDeletedLineupNumberWithEvent(
            events || [],
            match.homeTeamId,
            selectedHomeLineup
        );
        const deletedAwayPlayersWithEvents = getListOfDeletedLineupNumberWithEvent(
            events || [],
            match.awayTeamId,
            selectedAwayLineup
        );

        if (deletedHomePlayersWithEvents.length > 0 || deletedAwayPlayersWithEvents.length > 0) {
            const errorMessage = `${deletedHomePlayersWithEvents.length > 0
                ? `The following home team players cannot be deleted because they are referenced by an event: ${deletedHomePlayersWithEvents.join(
                    ', '
                )}.\n `
                : ''
                }${deletedAwayPlayersWithEvents.length > 0
                    ? `The following away team players cannot be deleted because they are referenced by an event: ${deletedAwayPlayersWithEvents.join(
                        ', '
                    )}. `
                    : ''
                }`;
            popupError(errorMessage);
            return;
        }

        const matchCoaches: CreateMatchCoachCommandOfMatchCoach[] = [];
        const matchOfficials: CreateMatchOfficialCommandOfMatchOfficial[] = [];
        const matchPlayers: CreateMatchPlayerCommandOfMatchPlayer[] = [
            ...selectedHomeLineup,
            ...selectedAwayLineup,
        ];

        if (selectedHomeHeadCoach) {
            matchCoaches.push(selectedHomeHeadCoach);
        }
        if (selectedAwayHeadCoach) {
            matchCoaches.push(selectedAwayHeadCoach);
        }
        if (selectedHomeAssistCoaches) {
            matchCoaches.push(...selectedHomeAssistCoaches);
        }
        if (selectedAwayAssistCoaches) {
            matchCoaches.push(...selectedAwayAssistCoaches);
        }
        if (selectedReferee1) {
            matchOfficials.push(selectedReferee1);
        }
        if (selectedReferee2) {
            matchOfficials.push(selectedReferee2);
        }
        if (selectedDirector) {
            matchOfficials.push(selectedDirector);
        }
        if (selectedDelegates) {
            matchOfficials.push(...selectedDelegates);
        }
        if (selectedGoalReferees) {
            matchOfficials.push(...selectedGoalReferees);
        }
        if (selectedSecretaries) {
            matchOfficials.push(...selectedSecretaries);
        }
        if (selectedTimekeepers) {
            matchOfficials.push(...selectedTimekeepers);
        }
        if (selectedVars) {
            matchOfficials.push(...selectedVars);
        }

        const request: UpdateMatchLogCommand = new UpdateMatchLogCommand({
            matchId: match.id,
            matchCoaches,
            matchOfficials,
            matchPlayers,
        });

        try {
            const matchClient = new MatchesClient();
            const result = await matchClient.updateMatchLog(match.id, request);

            if (result) {
                await this.getMatchDetails();
                success('Match log successfully saved.');
            }
        } catch (error) {
            if (error instanceof ApiException) {
                errorWithUserMessage(error.response);
            } else {
                errorWithUserMessage('Error saving data.');
            }
        }

        this.setState({ isLoading: false })
    };

    private confirmLog = (): void => {
        if (this.confirmLogRef && this.confirmLogRef.current) {
            this.confirmLogRef.current.handleDrawerMode(DrawerState.Edit);
        }
    };

    private exportToPdf = async (): Promise<void> => {
        const {
            selectedHomeLineup,
            selectedAwayLineup,
            selectedHomeHeadCoach,
            selectedHomeAssistCoaches,
            selectedAwayHeadCoach,
            selectedAwayAssistCoaches,
            selectedReferee1,
            selectedReferee2,
            selectedDirector,
            selectedDelegates,
            selectedGoalReferees,
            selectedSecretaries,
            selectedTimekeepers,
            selectedVars,
            coaches,
            officials,
            match,
            events,
        } = this.state;

        this.setState({ isLoading: true });

        await exportToPdf(
            events || [],
            coaches,
            match?.players || [],
            selectedHomeAssistCoaches,
            selectedAwayAssistCoaches,
            selectedHomeLineup,
            selectedAwayLineup,
            selectedDelegates,
            selectedGoalReferees,
            selectedSecretaries,
            selectedTimekeepers,
            selectedVars,
            officials,
            selectedReferee1,
            selectedReferee2,
            selectedDirector,
            selectedHomeHeadCoach,
            selectedAwayHeadCoach,
            match
        );

        this.setState({ isLoading: false });
    };

    public render = (): React.ReactElement => {
        const {
            homeTeamPlayers,
            awayTeamPlayers,
            selectedHomeLineup,
            selectedAwayLineup,
            selectedHomeHeadCoach,
            selectedHomeAssistCoaches,
            selectedAwayHeadCoach,
            selectedAwayAssistCoaches,
            selectedReferee1,
            selectedReferee2,
            selectedDirector,
            selectedDelegates,
            selectedGoalReferees,
            selectedSecretaries,
            selectedTimekeepers,
            selectedVars,
            coaches,
            officials,
            isLoading,
            match,
            events,
        } = this.state;
        const { history } = this.props;

        return (
            <Content
                className="o-wrapper o-wrapper--game-log u-flex u-flex-column u-justify-content-start"
                style={{ overflowY: 'scroll' }}
            >
                <Spin tip="Loading..." spinning={isLoading} className="spinner">
                    <MatchScoreboard
                        match={match}
                        competition={match ? match.competition : undefined}
                        homeTeam={match ? match.homeTeam : undefined}
                        awayTeam={match ? match.awayTeam : undefined}
                    />
                    <div className="c-player-list u-flex u-justify-content-between">
                        <MatchLineup
                            events={events}
                            lineup={selectedHomeLineup}
                            players={homeTeamPlayers}
                            handleSelectLineupPlayer={this.handleSelectLineupPlayer}
                            handleClearLineupPlayer={this.handleClearLineupPlayer}
                            isHomeTeam
                        />
                        <MatchLineup
                            events={events}
                            lineup={selectedAwayLineup}
                            players={awayTeamPlayers}
                            handleSelectLineupPlayer={this.handleSelectLineupPlayer}
                            handleClearLineupPlayer={this.handleClearLineupPlayer}
                            isHomeTeam={false}
                        />
                    </div>
                    <MatchOfficials
                        homeHeadCoach={selectedHomeHeadCoach}
                        homeAssistantCoaches={selectedHomeAssistCoaches}
                        awayHeadCoach={selectedAwayHeadCoach}
                        awayAssistantCoaches={selectedAwayAssistCoaches}
                        referee1={selectedReferee1}
                        referee2={selectedReferee2}
                        director={selectedDirector}
                        delegates={selectedDelegates}
                        goalReferees={selectedGoalReferees}
                        secretaries={selectedSecretaries}
                        timekeepers={selectedTimekeepers}
                        vars={selectedVars}
                        coaches={coaches}
                        officials={officials}
                        handleSelectCoach={this.handleSelectCoach}
                        handleClearCoach={this.handleClearCoach}
                        handleSelectOfficial={this.handleSelectOfficial}
                        handleClearOfficial={this.handleClearOfficial}
                        events={events}
                        match={match}
                    />
                    {match && (
                        <MatchEventLog
                            match={match}
                            events={events || []}
                            lineups={match.players || []}
                            coaches={match.coaches || []}
                        />
                    )}
                    <div className="c-event c-event--confirm u-flex u-justify-content-center u-align-items-center">
                        <button
                            onClick={this.updateMatchLog}
                            type="button"
                            className="u-flex u-align-items-center u-justify-content-center"
                        >
                            <Icon name="icon-clipboard-check-regular" />
                            <span>&nbsp; Save Match Log</span>
                        </button>
                    </div>
                    <div className="c-extended-menu">
                        <button
                            type="button"
                            className="c-btn c-btn--side c-btn--right c-btn--extended-menu-btn c-extended-menu__btn u-flex u-align-items-center u-justify-content-center"
                        >
                            <Icon name="icon-chevron-left" />
                        </button>
                        <div className="c-extended-menu__list u-flex u-flex-row u-flex-nowrap">
                            <div
                                onClick={(): void => history.push(`/`)}
                                className="c-extended-menu__list u-flex u-flex-row u-flex-nowrap"
                                role="button"
                            >
                                <div className="c-extended-menu__item u-flex u-flex-column u-align-items-center u-justify-content-center">
                                    <Icon name="icon-swimmer" />
                                    <span>Back to App</span>
                                </div>
                            </div>
                            {match && (
                                <div
                                    className="c-extended-menu__item u-flex u-flex-column u-align-items-center u-justify-content-center"
                                    onClick={(): void => history.push(`/match-report/${match.id}`)}
                                    role="button"
                                >
                                    <Icon name="icon-clipboard-list" />
                                    <span>Match Report</span>
                                </div>
                            )}
                            {match && (
                                <div
                                    className="c-extended-menu__item u-flex u-flex-column u-align-items-center u-justify-content-center"
                                    onClick={this.confirmLog}
                                    role="button"
                                >
                                    <Button
                                        className="confirm-match-log-button"
                                        disabled={match.statusId !== MatchStatusEnum.Finished}
                                    >
                                        <Icon name="icon-check" />
                                        <span>Confirm Log</span>
                                    </Button>
                                </div>
                            )}
                            <div
                                className="c-extended-menu__item u-flex u-flex-column u-align-items-center u-justify-content-center"
                                onClick={
                                    match ? (): Promise<void> => this.exportToPdf() : undefined
                                }
                                role="button"
                            >
                                <Icon name="icon-file-pdf-solid" />
                                <span>Export to PDF</span>
                            </div>
                        </div>
                    </div>
                    <MatchConfirmLog
                        matchOfficials={match?.officials || []}
                        updateMatchDetailsAfterSave={this.getMatchDetails}
                        ref={this.confirmLogRef}
                    />
                </Spin>
            </Content>
        );
    };
}

export default MatchLog;
