import { CheckOutlined } from '@ant-design/icons';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import Routes from '../../../config/routes';
import ReplaceStrings from '../../../config/replaceStrings';
import { SortDirectionEnum } from '../../../core/models/enum';
import { convertUTCTimeToLocal } from '../../../helpers/DateHelper';
import {
    DefaultFilter,
    getDatePickerFilter,
    getRadioFilter,
    getSearchFilter,
} from '../../../helpers/FilterHelper';
import { CompetitionVm } from '../../../utils/api';

export const columns = [
    {
        title: 'Name',
        dataIndex: 'nameUniversal',
        key: 'nameUniversal',
        ...getSearchFilter(),
        sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
        sorter: (): void => {},
    },
    {
        title: 'Federation',
        dataIndex: 'federation',
        key: 'federation',
        ...getSearchFilter(),
        sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
        sorter: (): void => {},
    },
    {
        title: 'Category',
        dataIndex: 'category',
        key: 'category',
        ...getSearchFilter(),
        sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
        sorter: (): void => {},
    },
    {
        title: 'Competition Type',
        dataIndex: 'competitionType',
        key: 'competitionType',
        ...getSearchFilter(),
        sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
        sorter: (): void => {},
    },
    {
        title: 'Professional',
        dataIndex: 'isPro',
        key: 'isPro',
        ...getRadioFilter([
            { id: 'false', name: 'False' },
            { id: 'true', name: 'True' },
        ]),
        sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
        sorter: (): void => {},
        render: (value: boolean): React.ReactElement =>
            value ? <CheckOutlined style={{ width: '100%' }} /> : <span />,
        width: 110,
    },
    {
        title: 'Start date',
        dataIndex: 'startDate',
        key: 'startDate',
        ...getDatePickerFilter(),
        sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
        sorter: (): void => {},
        render: (value: Date): string => (value ? moment(value).format('DD.MM.YYYY.') : ''),
        width: 110,
    },
    {
        title: 'End date',
        dataIndex: 'endDate',
        key: 'endDate',
        ...getDatePickerFilter(),
        sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
        sorter: (): void => {},
        render: (value?: Date): string =>
            value ? moment(convertUTCTimeToLocal(value)).format('DD.MM.YYYY.') : '',
        width: 110,
    },
    {
        dataIndex: '',
        render: (value: CompetitionVm): React.ReactElement =>
            value && value.id ? (
                <Link
                    to={Routes.ROUTE_COMPETITIONS_READ.replace(
                        ReplaceStrings.COMPETITION_ID,
                        value.id.toString()
                    )}
                >
                    Details
                </Link>
            ) : (
                <span />
            ),
        width: 80,
    },
];

export const DefaultCompetitionFilter = {
    ...DefaultFilter,
    orderByKey: 'category',
    orderByValue: SortDirectionEnum.DESC,
};
