import React from 'react'
import { Props } from '.';
import { Table, TablePaginationConfig } from 'antd';
import { getSearchFilter } from '../../../../helpers/FilterHelper';
import { SortDirectionEnum } from '../../../../core/models/enum';
import { getDefaultComparer, getNumberComparer } from '../../../../helpers/CompareHelper';

interface State {
    isLoading: boolean;
}

class StandingsTable extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: false
        }
    }

    public render(): React.ReactNode {
        const { standings, isLoading } = this.props;

        const columns = [
            {
                title: "Rank",
                dataIndex: 'rank',
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                defaultSortOrder: 'ascend',
                sorter: getNumberComparer('rank')
            },
            {
                title: "Team",
                dataIndex: 'team',
                ...getSearchFilter(),
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: getDefaultComparer('team')
            },
            {
                title: "Played",
                dataIndex: 'played',
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: getNumberComparer('played')
            },
            {
                title: "Drawn",
                dataIndex: 'drawn',
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: getNumberComparer('drawn')
            },
            {
                title: "Won",
                dataIndex: 'won',
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: getNumberComparer('won')
            },
            {
                title: "Lost",
                dataIndex: 'lost',
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: getNumberComparer('lost')
            },
            {
                title: "GoalsScored",
                dataIndex: 'goalsScored',
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: getNumberComparer('goalsScored')
            },
            {
                title: "GoalsConceded",
                dataIndex: 'goalsConceded',
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: getNumberComparer('goalsConceded')
            },
            {
                title: "GoalsDiff",
                dataIndex: 'goalsDiff',
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: getNumberComparer('goalsDiff')
            },
            {
                title: "Points",
                dataIndex: 'points',
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: getNumberComparer('points')
            }
        ];

        return (
            <Table
                loading={isLoading}
                columns={columns}
                dataSource={standings.rankings}
                rowKey={(record): string => record.id?.toString()}
                pagination={false}
                bordered
                size="small"
            />
        );
    }

}

export default StandingsTable;