import { RouteComponentProps, withRouter } from 'react-router-dom';
import { CompetitionVm } from '../../../utils/api';
import { RouteParams } from '../../../core/models/RouteHelper';
import CompetitionTable from './CompetitionTable';
import { Profile } from 'oidc-client';

interface OwnProps {
    userProfile: Profile;
    wrappedComponentRef?: any;
    competitionsFromProps?: CompetitionVm[];
}

export type Props = OwnProps & RouteComponentProps<RouteParams>;

export default withRouter(CompetitionTable);
