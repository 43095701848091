import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ClubVm } from '../../../utils/api';
import { RouteParams } from '../../../core/models/RouteHelper';
import ClubTable from './ClubTable';
import { Profile } from 'oidc-client';

interface OwnProps {
    userProfile: Profile;
    wrappedComponentRef?: any;
    clubsFromProps?: ClubVm[];
    additionalActionTitle?: string;
    additionalAction?: (id?: number) => void;
}

export type Props = OwnProps & RouteComponentProps<RouteParams>;

export default withRouter(ClubTable);
