import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RouteParams } from '../../../../core/models/RouteHelper';
import { DrawerState } from '../../../../core/models/enum';
import { RoleOfficialFormInitialValues } from '../../../../core/models/InitialValues';
import { FederationOfficialVm } from '../../../../utils/api';
import OfficialRoleForm from './OfficialRoleForm';

interface OwnProps {
    officialRole?: FederationOfficialVm;
    initialValues?: RoleOfficialFormInitialValues;
    handleClose: (drawerMode: DrawerState, id?: string) => void;
    refreshAfterSave: () => void;
}

export type Props = OwnProps & RouteComponentProps<RouteParams>;

export default withRouter(OfficialRoleForm);
