import { RouteComponentProps, withRouter } from 'react-router-dom';
import { DrawerState } from '../../../core/models/enum';
import { MatchFormInitialValues } from '../../../core/models/InitialValues';
import { MatchDetailVm } from '../../../utils/api';
import { RouteParams } from '../../../core/models/RouteHelper';
import MatchForm from './MatchForm';

interface OwnProps {
    matchFromProps?: MatchDetailVm;
    initialValues?: MatchFormInitialValues;
    handleClose: (drawerMode: DrawerState, id?: string) => void;
    refreshAfterSave: () => void;
}

export type Props = OwnProps & RouteComponentProps<RouteParams>;

export default withRouter(MatchForm);
