import { Layout } from 'antd';
import React from 'react';
import Icon from '../icon/Icon';
import Pool from '../../assets/images/pool-location-1500x1744.png';

interface Props {
    x?: number;
    y?: number;
    onSelect: (x: number, y: number) => void;
}

class ShotLocation extends React.PureComponent<Props> {
    private onLocationSelect = (event: React.MouseEvent<HTMLImageElement, MouseEvent>): void => {
        const { onSelect } = this.props;
        const img = event.target as HTMLImageElement;
        const x = Math.round((event.nativeEvent.offsetX / img.width) * 1000) / 1000;
        const y = Math.round((event.nativeEvent.offsetY / img.height) * 1000) / 1000;

        onSelect(x, y);
    };

    public render = (): React.ReactElement => {
        const { x, y } = this.props;

        return (
            <Layout className="shot-location noselect">
                <div className="shot-location-goal" />
                <div
                    onClick={(event: React.MouseEvent<HTMLImageElement, MouseEvent>): void =>
                        this.onLocationSelect(event)
                    }
                >
                    <img src={Pool} alt="shot location" />
                    {x && y && (
                        <Icon
                            name="icon-map-marker-alt"
                            className="marker"
                            style={{ top: `${y * 100}%`, left: `${x * 100}%` }}
                        />
                    )}
                </div>
                <div className="shot-location-goal" />
            </Layout>
        );
    };
}

export default ShotLocation;
