import { MatchOfficialVm } from '../../../utils/api';
import MatchConfirmLog from './MatchConfirmLog';

interface OwnProps {
    matchOfficials: MatchOfficialVm[];
    updateMatchDetailsAfterSave: () => Promise<void>;
}

export type Props = OwnProps;

export default MatchConfirmLog;
