import React from 'react';
import { Props } from '.';
import Icon from '../../../components/icon/Icon';
import { ClockState } from '../../../core/models/enum';
import { EventPeriodEnum } from '../../../utils/api';
import { Spin } from 'antd';

export interface State {
    isLoading: boolean;
}

class MatchClock extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: false
        }
    }

    private onConfirmation = async (): Promise<void> => {
        const { saveEvent } = this.props;

        this.setState({ isLoading: true });
        await saveEvent();
        this.setState({ isLoading: false });
    }

    public render = (): React.ReactElement => {
        const {
            event,
            clockState,
            currentPeriod,
            currentTime,
            eventTime,
            eventPeriod,
            isEventValid,
            startClock,
            stopClock,
            setPeriod,
            modifyClock,
        } = this.props;

        const { isLoading } = this.state;

        const currMinutes = Math.floor(currentTime / 60);
        const currSeconds = currentTime - currMinutes * 60;
        const currTimeDisplay = `${currMinutes < 10 ? `0${currMinutes}` : currMinutes}:${currSeconds < 10 ? `0${currSeconds}` : currSeconds
            }`;

        const clockMinutes = Math.floor(eventTime / 60);
        const clockSeconds = eventTime - clockMinutes * 60;
        const clockMinutesDisplay = clockMinutes < 10 ? `0${clockMinutes}` : clockMinutes;
        const clockSecondsDisplay = clockSeconds < 10 ? `0${clockSeconds}` : clockSeconds;

        let className = '';

        switch (clockState) {
            case ClockState.Running:
                className = 'is-running';
                break;
            case ClockState.Paused:
            case ClockState.Stopped:
                className = isEventValid ? 'is-time-confirmation-active' : 'is-paused';
                break;
            default:
                break;
        }
        const stopwatchClickHandler =
            clockState === ClockState.Running ? (): void => stopClock() : (): void => startClock();
        const isEditingEvent = event != null;

        return (
            <div className="o-clock-screen noselect">
                <div className={`c-clock ${className}`}>
                    <div className="c-clock__quarters">
                        <span
                            onClick={(): void => setPeriod(EventPeriodEnum.First_Period)}
                            className={
                                eventPeriod === EventPeriodEnum.First_Period ? 'is-active' : ''
                            }
                            role="button"
                        >
                            1st Period
                        </span>
                        <span
                            onClick={(): void => setPeriod(EventPeriodEnum.Second_Period)}
                            className={
                                eventPeriod === EventPeriodEnum.Second_Period ? 'is-active' : ''
                            }
                            role="button"
                        >
                            2nd Period
                        </span>
                        <span
                            onClick={(): void => setPeriod(EventPeriodEnum.Third_Period)}
                            className={
                                eventPeriod === EventPeriodEnum.Third_Period ? 'is-active' : ''
                            }
                            role="button"
                        >
                            3rd Period
                        </span>
                        <span
                            onClick={(): void => setPeriod(EventPeriodEnum.Fourth_Period)}
                            className={
                                eventPeriod === EventPeriodEnum.Fourth_Period ? 'is-active' : ''
                            }
                            role="button"
                        >
                            4th Period
                        </span>
                        <span
                            onClick={(): void => setPeriod(EventPeriodEnum.Overtime)}
                            className={eventPeriod === EventPeriodEnum.Overtime ? 'is-active' : ''}
                            role="button"
                        >
                            Overtime
                        </span>
                        <span
                            style={{
                                fontSize: '20px',
                                cursor: 'normal',
                                color: clockState === ClockState.Stopped ? 'red' : 'greenyellow',
                            }}
                        >
                            {`${EventPeriodEnum[currentPeriod].replace(
                                '_',
                                ' '
                            )} - ${currTimeDisplay}`}
                        </span>
                    </div>
                    <div
                        className={`c-clock__clock ${clockState !== ClockState.Running ? 'enabled' : ''
                            }`}
                    >
                        <div
                            className="c-clock__pause"
                            onClick={isEditingEvent ? undefined : stopwatchClickHandler}
                            role="button"
                        >
                            <div className="c-clock__pause-inner">
                                <Icon name="icon-stopwatch" />
                            </div>
                        </div>
                        <div className="c-clock__minutes">
                            <div className="c-clock__minus-plus">
                                <span
                                    onClick={
                                        clockState !== ClockState.Running
                                            ? (): void => modifyClock(60)
                                            : undefined
                                    }
                                    className="c-clock__plus"
                                    role="button"
                                >
                                    <Icon name="icon-plus" />
                                </span>
                                <span
                                    onClick={
                                        clockState !== ClockState.Running
                                            ? (): void => modifyClock(-60)
                                            : undefined
                                    }
                                    className="c-clock__minus"
                                    role="button"
                                >
                                    <Icon name="icon-minus" />
                                </span>
                            </div>
                            <div className="c-clock__digits">{clockMinutesDisplay}</div>
                            <span className="c-clock__note">Minutes</span>
                        </div>
                        <div className="c-clock__colon">:</div>
                        <div className="c-clock__seconds">
                            <div className="c-clock__minus-plus">
                                <span
                                    onClick={
                                        clockState !== ClockState.Running
                                            ? (): void => modifyClock(10)
                                            : undefined
                                    }
                                    className="c-clock__plus"
                                    role="button"
                                >
                                    <Icon name="icon-plus" />
                                </span>
                                <span
                                    onClick={
                                        clockState !== ClockState.Running
                                            ? (): void => modifyClock(-10)
                                            : undefined
                                    }
                                    className="c-clock__minus"
                                    role="button"
                                >
                                    <Icon name="icon-minus" />
                                </span>
                            </div>
                            <div className="c-clock__digits">{clockSecondsDisplay}</div>
                            <div className="c-clock__minus-plus">
                                <span
                                    onClick={
                                        clockState !== ClockState.Running
                                            ? (): void => modifyClock(1)
                                            : undefined
                                    }
                                    className="c-clock__plus"
                                    role="button"
                                >
                                    <Icon name="icon-plus" />
                                </span>
                                <span
                                    onClick={
                                        clockState !== ClockState.Running
                                            ? (): void => modifyClock(-1)
                                            : undefined
                                    }
                                    className="c-clock__minus"
                                    role="button"
                                >
                                    <Icon name="icon-minus" />
                                </span>
                            </div>
                            <span className="c-clock__note">Seconds</span>
                        </div>
                        <div
                            className="c-clock__confirmation"
                            onClick={isEventValid && !isLoading ? async (): Promise<void> => await this.onConfirmation() : undefined}
                            role="button"
                        >
                            {isLoading ? <Spin className='clock-spinner' /> : <Icon name="icon-check" />}
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

export default MatchClock;
