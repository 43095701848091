import DrawerButtons from './DrawerButtons';

interface OwnProps {
    isSaving?: boolean;
    isFormDirty?: boolean;
    onSubmitAction?: () => void;
    onCancelAction: () => void;
}

export type Props = OwnProps;

export default DrawerButtons;
