import { Profile } from 'oidc-client';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RouteParams } from '../../../core/models/RouteHelper';
import { FederationOfficialVm } from '../../../utils/api';
import OfficialRoles from './OfficialRoles';

interface OwnProps {
    userProfile: Profile;
    isInitialEdit: boolean;
    officialId: number;
    officialRoles: FederationOfficialVm[];
    refreshAfterSave: () => void;
}

export type Props = OwnProps & RouteComponentProps<RouteParams>;

export default withRouter(OfficialRoles);
