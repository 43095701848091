import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import React from 'react';
import CardIcon from '../../../../components/card-icon/CardIcon';
import { CardRequest, CardTypeEnum, EventVm, MatchCoachVm, UpdateEventCommand } from '../../../../utils/api';
import { Props } from '.';

class Card extends React.PureComponent<Props> {
    public handleCardTypeSelect = (cardId: CardTypeEnum): void => {
        const { event, updateEvent } = this.props;

        const eventVm = event as EventVm;

        const card: CardRequest | undefined = event.card
            ? new CardRequest({
                ...event.card,
                typeId: cardId,
                id: eventVm.cardId || 0,
            })
            : undefined;

        const updatedEvent: UpdateEventCommand = new UpdateEventCommand({
            ...event,
            card,
        });

        updatedEvent.id = event.id;

        updateEvent(updatedEvent);
    };

    public render = (): React.ReactElement => {
        const { event, coaches } = this.props;
        const cardType = event.card?.typeId;
        const matchCoach = coaches.find(
            (c: MatchCoachVm): boolean => c.id === event.card?.cardedCoachId
        );

        return (
            <div className="event-create">
                <div className="title">Coach:</div>
                <div className="content">
                    {matchCoach ? `${matchCoach.coach.name} ${matchCoach.coach.surname}` : ''}
                </div>
                <br />
                <div className="title">Card:</div>
                <Radio.Group
                    className="card-wrapper"
                    value={cardType}
                    buttonStyle="solid"
                    size="large"
                    onChange={(e: RadioChangeEvent): void =>
                        this.handleCardTypeSelect(e.target.value)
                    }
                >
                    <div className="card-btn-wrapper" style={{ marginRight: 10 }}>
                        <Radio.Button value={CardTypeEnum.Yellow} className="icon-button">
                            <CardIcon type={CardTypeEnum.Yellow} />
                        </Radio.Button>
                    </div>
                    <div className="card-btn-wrapper">
                        <Radio.Button value={CardTypeEnum.Red} className="icon-button">
                            <CardIcon type={CardTypeEnum.Red} />
                        </Radio.Button>
                    </div>
                </Radio.Group>
            </div>
        );
    };
}

export default Card;
