import { PageHeader, Tabs } from 'antd';
import React from 'react';
import PageHeaderTitle from '../../../components/page-header';
import Routes from '../../../config/routes';
import ReplaceStrings from '../../../config/replaceStrings';
import { ModuleName } from '../../../core/models/enum';
import { getSubPageInitialProperties } from '../../../helpers/RoutingHelper';
import {
    transformCoachClubsToClubs,
    transformCoachTeamsToTeams,
    transformToMatchVMArray,
} from '../../../helpers/TransformHelper';
import { CoachDetailVm, CoachesClient } from '../../../utils/api';
import ClubTable from '../../clubs/club-table';
import MatchTable from '../../matches/match-table';
import PersonDetails from '../../persons/person-page/person-details';
import TeamTable from '../../teams/team-table';
import { Props } from './index';

const { TabPane } = Tabs;

interface State {
    coach?: CoachDetailVm;
    activeTabKey: string;
    isEditMode: boolean;
    id?: string;
    loading: boolean;
}

class CoachPage extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        const {
            location: { pathname },
        } = window;

        const initialProperties = getSubPageInitialProperties(
            pathname,
            'coaches',
            props.match,
            Routes.ROUTE_COACHES_READ,
            Routes.ROUTE_COACHES_EDIT
        );

        this.state = {
            activeTabKey: initialProperties.activeTabKey || Routes.ROUTE_COACH_PERSONAL_INFO,
            id: initialProperties.initialEntityId,
            isEditMode: initialProperties.isInitialEditMode,
            loading: true,
        };
    }

    public componentDidMount = (): void => {
        this.getCoachDetails();
    };

    public componentDidUpdate = (prevProps: Props): void => {
        const {
            match: { path },
        } = this.props;

        if (path !== prevProps.match.path) {
            this.updateStateAfterRouteChanges();
        }
    };

    private updateStateAfterRouteChanges = (): void => {
        const { match } = this.props;
        const {
            location: { pathname },
        } = window;

        const initialProperties = getSubPageInitialProperties(
            pathname,
            'coaches',
            match,
            Routes.ROUTE_COACHES_READ,
            Routes.ROUTE_COACHES_EDIT
        );

        this.setState({
            activeTabKey: initialProperties.activeTabKey || Routes.ROUTE_COACH_PERSONAL_INFO,
            id: initialProperties.initialEntityId,
            isEditMode: initialProperties.isInitialEditMode,
        });
    };

    private getCoachDetails = async (): Promise<void> => {
        const {
            match: {
                params: { coachId },
            },
        } = this.props;

        if (coachId) {
            const coachClient = new CoachesClient();
            const coachDetails = await coachClient.getById(parseInt(coachId, 10));

            this.setState({
                coach: coachDetails,
            });
        }

        this.setState({
            loading: false,
        });
    };

    private handleTabClick = (route: string): void => {
        const {
            history,
            match: {
                params: { coachId },
            },
        } = this.props;

        this.setState({
            activeTabKey: route,
            isEditMode: false,
        });

        const newRoute = route.replace(ReplaceStrings.COACH_ID, coachId);

        history.push(newRoute);
    };

    public render(): React.ReactElement {
        const { userProfile } = this.props;
        const { activeTabKey, coach, isEditMode } = this.state;

        return (
            <div>
                <PageHeader
                    title={
                        <PageHeaderTitle
                            headerTitle={
                                coach ? `${coach.person.name} ${coach.person.surname}` : ''
                            }
                            headerPhoto={
                                coach && coach.person!.photo ? coach.person!.photo : undefined
                            }
                            tableName="coach"
                            imgHeight={120}
                        />
                    }
                    style={{ padding: '16px 0px' }}
                />
                <div className="tab-padding">
                    <div
                        style={{
                            overflow: 'hidden',
                            width: '100%',
                            transition: 'width .3s cubic-bezier',
                        }}
                    >
                        <Tabs
                            style={{ width: '100%' }}
                            activeKey={activeTabKey}
                            onTabClick={(key: string): void => this.handleTabClick(key)}
                        >
                            <TabPane
                                tab="Personal information"
                                key={Routes.ROUTE_COACH_PERSONAL_INFO}
                            >
                                {coach && (
                                    <PersonDetails
                                        userProfile={userProfile}
                                        isInitialEdit={isEditMode}
                                        person={coach.person}
                                        refreshAfterSave={this.getCoachDetails}
                                        routeHelper={{
                                            mainRoute: Routes.ROUTE_COACHES,
                                            readRoute: Routes.ROUTE_COACH_PERSONAL_INFO,
                                            editRoute: Routes.ROUTE_COACH_PERSONAL_INFO_EDIT,
                                            replaceString: ReplaceStrings.COACH_ID,
                                            id: coach.id.toString(),
                                        }}
                                    />
                                )}
                            </TabPane>
                            <TabPane tab="Clubs" key={Routes.ROUTE_COACH_CLUBS}>
                                {coach && coach.clubs && coach.clubs.length > 0 ? (
                                    <ClubTable
                                        userProfile={userProfile}
                                        clubsFromProps={transformCoachClubsToClubs(coach.clubs)}
                                    />
                                ) : (
                                    <div>This coach does not coach any club.</div>
                                )}
                            </TabPane>
                            <TabPane tab="Teams" key={Routes.ROUTE_COACH_TEAMS}>
                                {coach && coach.teams && coach.teams.length > 0 ? (
                                    <TeamTable
                                        userProfile={userProfile}
                                        teamsFromProps={transformCoachTeamsToTeams(coach.teams)}
                                        moduleName={ModuleName.Coaches}
                                    />
                                ) : (
                                    <div>This coach does not coach any team.</div>
                                )}
                            </TabPane>
                            <TabPane tab="Matches" key={Routes.ROUTE_COACH_MATCHES}>
                                {coach && coach.matches && coach.matches.length > 0 ? (
                                    <MatchTable
                                        userProfile={userProfile}
                                        matchesFromProps={transformToMatchVMArray(coach.matches)}
                                    />
                                ) : (
                                    <div>This coach does not participate in any match.</div>
                                )}
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        );
    }
}

export default CoachPage;
