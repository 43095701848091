import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, DatePicker, Drawer, Form, Table } from 'antd';
import moment from 'moment';
import React from 'react';
import { DrawerWidth } from '../../config/dimensions';
import { DropdownOption } from '../../core/models/DropdownOption';
import { DrawerState } from '../../core/models/enum';
import {
    confirmDropdownHelper,
    filterDropdownOptions,
} from '../../helpers/AutocompleteDropdownHelper';
import { convertUTCTimeToLocal } from '../../helpers/DateHelper';
import { getFederationsToDropdownOptions } from '../../helpers/FetchAndTransformHelpers';
import { formItemLayout724 } from '../../helpers/FormLayoutHelper';
import { transformEnumToDropdownOptions } from '../../helpers/TransformHelper';
import { customArrayValidationHelper } from '../../helpers/ValidationHelper';
import {
    CreateFederationOfficialCommandOfFederationOfficial,
    OfficialRoleEnum,
} from '../../utils/api';
import AutocompleteDropdown from '../autocomplete-dropdown';
import DrawerButtons from '../drawer-buttons/index';
import { Props } from './index';

interface State {
    drawerState: DrawerState;
    federations: DropdownOption[];
    roles: DropdownOption[];
    selectedFederation?: DropdownOption[];
    selectedRoles?: DropdownOption[];
}

class FederationRoles extends React.PureComponent<Props, State> {
    private formRef: any;

    public constructor(props: Props) {
        super(props);

        this.formRef = React.createRef();

        this.state = {
            drawerState: DrawerState.Closed,
            federations: [],
            roles: [],
        };
    }

    public componentDidMount = (): void => {
        this.getFederations();
        this.getRoles();
    };

    private getFederations = async (): Promise<void> => {
        const { initialFederationRoleId } = this.props;
        const results = await getFederationsToDropdownOptions(initialFederationRoleId);

        this.setState({
            federations: results.entities,
            selectedFederation: results.selectedEntity,
        });
    };

    private getRoles = (): void => {
        const roles = transformEnumToDropdownOptions(OfficialRoleEnum);

        this.setState({
            roles,
        });
    };

    private onSave = (values: any): void => {
        const { selectedFederation, selectedRoles, federations } = this.state;
        const { saveChanges, initialFederationRoleId } = this.props;
       

        if (
            selectedFederation &&
            selectedFederation.length > 0 &&
            selectedRoles &&
            selectedRoles.length > 0
        ) {
            const federation = new CreateFederationOfficialCommandOfFederationOfficial({
                federationId: parseInt(selectedFederation[0].id, 10),
                activeFrom: values.activeFrom,
                activeTo: values.activeTo,
                roles: selectedRoles.map((sR: DropdownOption): number => parseInt(sR.id, 10)),
                officialId: 0,
            });

            var newFederations = [...federations];
            const selectedFederationIndex = federations.findIndex(x => x.id === selectedFederation[0].id);
            newFederations[selectedFederationIndex].disabled = true;

            saveChanges(federation);
            this.handleDrawerMode(DrawerState.Closed);
            this.setState({
                selectedRoles: undefined,
                selectedFederation: undefined,
                federations: newFederations
            });
            if (!initialFederationRoleId) {
                this.setState({
                    selectedFederation: undefined,
                });
            }
        }
    };

    private handleDrawerMode = (drawerState: DrawerState): void => {
        this.setState({
            drawerState,
        });
    };

    private enableFederation = (id: number): void => {
        const { federations } = this.state;
        var newFederations = [...federations];

        const federationIndex = federations.findIndex(x => x.id === id.toString());
        newFederations[federationIndex].disabled = false;        

        this.setState({
            federations: newFederations
        });
    };

    public render(): React.ReactElement {
        const { drawerState, federations, roles, selectedFederation, selectedRoles } = this.state;
        const {
            initialFederationRoleId,
            selectedFederationRoles,
            removeFederationRoleFromList,
        } = this.props;

        const columns = [
            {
                title: 'Federation',
                dataIndex: 'federationId',
                key: 'federationId',
                render: (value: number): string => {
                    const federation = federations.find(
                        (t: DropdownOption): boolean => t.id === value.toString()
                    );
                    return federation ? federation.name : '';
                },
            },
            {
                title: 'Roles',
                dataIndex: 'roles',
                key: 'roles',
                render: (values: OfficialRoleEnum[]): string => {
                    const strings: string[] = [];
                    values.forEach((v: OfficialRoleEnum): void => {
                        const result = roles.find(
                            (cR: DropdownOption): boolean => cR.id === v.toString()
                        );
                        if (result) {
                            strings.push(result.name);
                        }
                    });
                    return strings.join(', ');
                },
            },
            {
                title: 'Active from',
                dataIndex: 'activeFrom',
                key: 'activeFrom',
                render: (value: Date): string =>
                    value ? moment(convertUTCTimeToLocal(value)).format('DD.MM.YYYY.') : '',
                width: 110,
            },
            {
                title: 'Active to',
                dataIndex: 'activeTo',
                key: 'activeTo',
                render: (value?: Date): string =>
                    value ? moment(convertUTCTimeToLocal(value)).format('DD.MM.YYYY.') : '',
                width: 110,
            },
            {
                title: '',
                dataIndex: '',
                key: '',
                render: (
                    value: CreateFederationOfficialCommandOfFederationOfficial
                ): React.ReactElement => (
                        <DeleteOutlined
                            onClick={(): void => {
                                removeFederationRoleFromList(value);
                                this.enableFederation(value.federationId);
                            }}
                            className="text-danger-custom"                         
                        />
                ),
                width: 31,
            },
        ];

        return (
            <div>
                <Button
                    type="primary"
                    style={{
                        zIndex: 10,
                        float: 'right',
                        marginBottom: 15,
                    }}
                    disabled={
                        !!initialFederationRoleId &&
                        !!selectedFederationRoles &&
                        selectedFederationRoles.length > 0
                    }
                    onClick={(): void => this.handleDrawerMode(DrawerState.Create)}
                >
                    <PlusOutlined className="button-icon-display" />
                    Add federation
                </Button>
                {selectedFederationRoles && selectedFederationRoles.length > 0 && (
                    <Table
                        columns={columns}
                        dataSource={selectedFederationRoles}
                        bordered
                        pagination={false}
                        size="small"
                    />
                )}
                <Drawer
                    title="Add federation"
                    visible={!!drawerState}
                    width={DrawerWidth}
                    onClose={(): void => this.handleDrawerMode(DrawerState.Closed)}
                    destroyOnClose
                >
                    <Form onFinish={this.onSave} {...formItemLayout724} ref={this.formRef}>
                        <DrawerButtons
                            onCancelAction={(): void => this.handleDrawerMode(DrawerState.Closed)}
                        />
                        <div className="drawer-content">
                            <Form.Item
                                name="federationId"
                                label="Federation"
                                rules={[
                                    {
                                        required: true,
                                        validator: (_rule: any, value: any, callback: any): void =>
                                            customArrayValidationHelper(
                                                _rule,
                                                value,
                                                callback,
                                                'Federation field is required!',
                                                selectedFederation
                                            ),
                                    },
                                ]}
                            >
                                <AutocompleteDropdown
                                    placeholder="Choose federation"
                                    getOptionsFrontend={(value: string): DropdownOption[] =>
                                        filterDropdownOptions(value, federations)
                                    }
                                    initialValues={selectedFederation}
                                    confirmDirty={(options: DropdownOption[]): void =>
                                        this.setState({
                                            selectedFederation: confirmDropdownHelper(options),
                                        })
                                    }
                                    style={{ width: '100%' }}
                                    disabled={!!initialFederationRoleId}
                                    isAllowedToClear
                                />
                            </Form.Item>
                            <Form.Item
                                name="roleIds"
                                label="Roles"
                                rules={[
                                    {
                                        required: true,
                                        validator: (_rule: any, value: any, callback: any): void =>
                                            customArrayValidationHelper(
                                                _rule,
                                                value,
                                                callback,
                                                'Roles field is required!',
                                                selectedRoles
                                            ),
                                    },
                                ]}
                            >
                                <AutocompleteDropdown
                                    placeholder="Choose roles"
                                    getOptionsFrontend={(value: string): DropdownOption[] =>
                                        filterDropdownOptions(value, roles)
                                    }
                                    initialValues={selectedRoles}
                                    mode="multiple"
                                    confirmDirty={(options: DropdownOption[]): void =>
                                        this.setState({
                                            selectedRoles: confirmDropdownHelper(options),
                                        })
                                    }
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                            <Form.Item
                                name="activeFrom"
                                label="Active from"
                                initialValue={moment(convertUTCTimeToLocal(new Date()))}
                            >
                                <DatePicker
                                    format="DD.MM.YYYY."
                                    style={{ width: '100%' }}
                                    allowClear={false}
                                />
                            </Form.Item>
                            <Form.Item name="activeTo" label="Active to" initialValue={undefined}>
                                <DatePicker format="DD.MM.YYYY." style={{ width: '100%' }} />
                            </Form.Item>
                        </div>
                    </Form>
                </Drawer>
            </div>
        );
    }
}

export default FederationRoles;
