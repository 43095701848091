import { Profile } from 'oidc-client';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RouteHelper, RouteParams } from '../../../../core/models/RouteHelper';
import { PersonVm } from '../../../../utils/api';
import PersonDetails from './PersonDetails';

interface OwnProps {
    userProfile: Profile;
    isInitialEdit: boolean;
    person: PersonVm;
    refreshAfterSave: () => Promise<void>;
    routeHelper: RouteHelper;
}

export type Props = OwnProps & RouteComponentProps<RouteParams>;

export default withRouter(PersonDetails);
