import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import React from 'react';
import ReplaceStrings from '../../../config/replaceStrings';
import Routes from '../../../config/routes';
import { Filter } from '../../../core/models/Filter';
import { ActionOption, ModuleName, SortDirectionEnum } from '../../../core/models/enum';
import { isUserAllowed } from '../../../helpers/CheckPermissionHelper';
import {
    buildFilterRequest,
    DefaultFilter,
    getSearchFilter,
} from '../../../helpers/FilterHelper';
import {
    errorWithUserMessage,
    popupConfirmDeleteWithCallback,
    success,
} from '../../../helpers/NotificationHelper';
import { PaginationOptions } from '../../../helpers/PaginationHelper';
import { feTableFilterHelper } from '../../../helpers/TableFilterHelper';
import {
    ApiException,
    CitiesClient,
    CityVm,
} from '../../../utils/api';
import { Props } from './index';

interface State {
    allCities: CityVm[];
    cities: CityVm[];
    paginationOptions: TablePaginationConfig;
    currentFilter: Filter;
    loading: boolean;
    allowEdit: boolean;
    allowDelete: boolean;
}

class CityTable extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            allCities: [],
            cities: [],
            paginationOptions: PaginationOptions,
            currentFilter: DefaultFilter,
            loading: true,
            allowEdit: false,
            allowDelete: false,
        };
    }

    public componentDidMount = (): void => {
        this.getPermissions();
        this.getInitialAllCities();
    };

    private getPermissions = async (): Promise<void> => {
        const { userProfile } = this.props;

        this.setState({
            allowEdit: isUserAllowed(userProfile, ModuleName.Cities, ActionOption.Update),
            allowDelete: isUserAllowed(userProfile, ModuleName.Cities, ActionOption.Delete),
        });
    };

    private getInitialAllCities = async (): Promise<void> => {
        const { citiesFromProps } = this.props;

        this.setState({
            loading: true,
        });

        let allCities;

        if (citiesFromProps) {
            allCities = citiesFromProps;
        } else {
            allCities = await this.getCitiesFromBe();
        }

        this.setState(
            {
                allCities,
                loading: false,
            },
            (): void => this.getFilteredCity()
        );
    };

    private getCitiesFromBe = async (): Promise<CityVm[]> => {
        const client = new CitiesClient();
        return await client.getAll();
    };

    private getFilteredCity = (currentFilterParam?: Filter): void => {
        const { currentFilter, allCities } = this.state;

        const filter = currentFilterParam || currentFilter;
        const cityPagedList = feTableFilterHelper(allCities || [], filter);

        this.setState({
            paginationOptions: {
                ...PaginationOptions,
                current: cityPagedList.currentPage,
                pageSize: cityPagedList.pageSize,
                total: cityPagedList.totalItems,
            },
            cities: cityPagedList?.pageItems || [],
            loading: false,
        });
    };

    private handleDeleteHelper = async (id: number): Promise<void> => {
        try {
            const client = new CitiesClient();
            const result = await client.delete(id);
            if (result) {
                success("City successfully deleted");
                this.getInitialAllCities();
            }
        } catch (error) {
            if (error instanceof ApiException) {
                errorWithUserMessage(error.response);
            } else {
                errorWithUserMessage('Error deleting data.');
            }    
        }
    };

    private handleTableChange = (pagination: any, filters: any, sorter: any): void => {
        const filterRequest = buildFilterRequest(filters);

        const filter: Filter = {
            pageIndex: pagination.current,
            pageSize: pagination.pageSize,
            orderByKey: sorter?.field && sorter?.order ? sorter.field : undefined,
            orderByValue: sorter?.order ? sorter.order : undefined,
            filterRequest,
        };

        this.getFilteredCity(filter);
        this.setState({
            currentFilter: filter,
        });
    };

    public render(): React.ReactElement {
        const { cities, loading, paginationOptions, allowDelete, allowEdit } =
            this.state;
        const { history } = this.props;

        const columns = [
            {
                title: "Name",
                dataIndex: 'name',
                ...getSearchFilter(),
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: (): void => { },
            },
            {
                title: "Name universal",
                dataIndex: 'nameUniversal',
                ...getSearchFilter(),
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: (): void => { },
            },
            {
                title: "Country",
                dataIndex: 'country',
                ...getSearchFilter(),
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: (): void => { },
            },
            {
                key: 'actions',
                width: 80,
                align: 'left',
                className: 'no-print',
                render: (value: CityVm): React.ReactElement => (
                    <>
                        {allowEdit && (
                            <EditOutlined
                                onClick={(): void =>
                                    history.push(
                                        Routes.ROUTE_CITIES_EDIT.replace(
                                            ReplaceStrings.CITY_ID,
                                            value.id.toString()
                                        )
                                    )
                                }
                                style={{ color: '#1890ff' }}
                            />
                        )}
                        {allowDelete && (
                            <DeleteOutlined
                                onClick={(): void =>
                                    popupConfirmDeleteWithCallback(
                                        (): Promise<void> => this.handleDeleteHelper(value.id),
                                        undefined,
                                        "Are you sure you want to delete this city?",
                                        "Delete"
                                    )
                                }
                                className="text-danger-custom"
                                style={{ marginLeft: 10 }}
                            />
                        )}
                    </>
                ),
            },
        ];

        return (
            <Table
                columns={columns}
                loading={loading}
                dataSource={cities}
                rowKey={(record): string => record.id?.toString()}
                pagination={paginationOptions}
                onChange={this.handleTableChange}
                bordered
                size="small"
            />
        );
    }
}

export default CityTable;
