import { PageHeader, Tabs } from 'antd';
import React from 'react';
import PageHeaderTitle from '../../../components/page-header';
import Routes from '../../../config/routes';
import ReplaceStrings from '../../../config/replaceStrings';
import { ModuleName } from '../../../core/models/enum';
import { getSubPageInitialProperties } from '../../../helpers/RoutingHelper';
import {
    transformPlayerClubsToClubs,
    transformPlayerMatchesToMatches,
    transformPlayerTeamsToTeams,
} from '../../../helpers/TransformHelper';
import { PlayerDetailVm, PlayersClient } from '../../../utils/api';
import ClubTable from '../../clubs/club-table';
import MatchTable from '../../matches/match-table';
import PersonDetails from '../../persons/person-page/person-details';
import TeamTable from '../../teams/team-table';
import { Props } from './index';
import PlayerDetails from './player-details';

const { TabPane } = Tabs;

interface State {
    player?: PlayerDetailVm;
    activeTabKey: string;
    isEditMode: boolean;
    id?: string;
    loading: boolean;
}

class PlayerPage extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        const {
            location: { pathname },
        } = window;

        const initialProperties = getSubPageInitialProperties(
            pathname,
            'players',
            props.match,
            Routes.ROUTE_PLAYERS_READ,
            Routes.ROUTE_PLAYERS_EDIT
        );

        this.state = {
            activeTabKey: initialProperties.activeTabKey || Routes.ROUTE_PLAYERS_READ,
            id: initialProperties.initialEntityId,
            isEditMode: initialProperties.isInitialEditMode,
            loading: true,
        };
    }

    public componentDidMount = (): void => {
        this.getPlayerDetails();
    };

    public componentDidUpdate = (prevProps: Props): void => {
        const {
            match: { path },
        } = this.props;

        if (path !== prevProps.match.path) {
            this.updateStateAfterRouteChanges();
        }
    };

    private updateStateAfterRouteChanges = (): void => {
        const { match } = this.props;
        const {
            location: { pathname },
        } = window;

        const initialProperties = getSubPageInitialProperties(
            pathname,
            'players',
            match,
            Routes.ROUTE_PLAYERS_READ,
            Routes.ROUTE_PLAYERS_EDIT
        );

        this.setState({
            activeTabKey: initialProperties.activeTabKey || Routes.ROUTE_PLAYERS_READ,
            id: initialProperties.initialEntityId,
            isEditMode: initialProperties.isInitialEditMode,
        });
    };

    private getPlayerDetails = async (): Promise<void> => {
        const {
            match: {
                params: { playerId },
            },
        } = this.props;

        if (playerId) {
            const playerClient = new PlayersClient();
            const playerDetails = await playerClient.getById(parseInt(playerId, 10));

            this.setState({
                player: playerDetails,
            });
        }

        this.setState({
            loading: false,
        });
    };

    private handleTabClick = (route: string): void => {
        const {
            history,
            match: {
                params: { playerId },
            },
        } = this.props;

        this.setState({
            activeTabKey: route,
            isEditMode: false,
        });

        const newRoute = route.replace(ReplaceStrings.PLAYER_ID, playerId);

        history.push(newRoute);
    };

    public render(): React.ReactElement {
        const { userProfile } = this.props;
        const { activeTabKey, player, isEditMode } = this.state;

        return (
            <div>
                <PageHeader
                    title={
                        <PageHeaderTitle
                            headerTitle={
                                player ? `${player.person.name} ${player.person.surname}` : ''
                            }
                            headerPhoto={
                                player && player.person!.photo ? player.person!.photo : undefined
                            }
                            tableName="player"
                            imgHeight={120}
                        />
                    }
                    style={{ padding: '16px 0px' }}
                />
                <div className="tab-padding">
                    <div
                        style={{
                            overflow: 'hidden',
                            width: '100%',
                            transition: 'width .3s cubic-bezier',
                        }}
                    >
                        <Tabs
                            style={{ width: '100%' }}
                            activeKey={activeTabKey}
                            onTabClick={(key: string): void => this.handleTabClick(key)}
                        >
                            <TabPane tab="Player information" key={Routes.ROUTE_PLAYERS_READ}>
                                <div>
                                    {player && (
                                        <PlayerDetails
                                            userProfile={userProfile}
                                            isInitialEdit={isEditMode}
                                            player={player}
                                            refreshAfterSave={this.getPlayerDetails}
                                        />
                                    )}
                                </div>
                            </TabPane>
                            <TabPane
                                tab="Personal information"
                                key={Routes.ROUTE_PLAYER_PERSONAL_INFO}
                            >
                                {player && (
                                    <PersonDetails
                                        userProfile={userProfile}
                                        isInitialEdit={isEditMode}
                                        person={player.person}
                                        refreshAfterSave={this.getPlayerDetails}
                                        routeHelper={{
                                            mainRoute: Routes.ROUTE_PLAYERS,
                                            readRoute: Routes.ROUTE_PLAYER_PERSONAL_INFO,
                                            editRoute: Routes.ROUTE_PLAYER_PERSONAL_INFO_EDIT,
                                            replaceString: ReplaceStrings.PLAYER_ID,
                                            id: player.id.toString(),
                                        }}
                                    />
                                )}
                            </TabPane>
                            <TabPane tab="Clubs" key={Routes.ROUTE_PLAYER_CLUBS}>
                                {player && player.clubs && player.clubs.length > 0 ? (
                                    <ClubTable
                                        userProfile={userProfile}
                                        clubsFromProps={transformPlayerClubsToClubs(player.clubs)}
                                    />
                                ) : (
                                    <div>This player does not play in any club.</div>
                                )}
                            </TabPane>
                            <TabPane tab="Teams" key={Routes.ROUTE_PLAYER_TEAMS}>
                                {player && player.teams && player.teams.length > 0 ? (
                                    <TeamTable
                                        userProfile={userProfile}
                                        teamsFromProps={transformPlayerTeamsToTeams(player.teams)}
                                        moduleName={ModuleName.Players}
                                    />
                                ) : (
                                    <div>This player does not play in any team.</div>
                                )}
                            </TabPane>
                            <TabPane tab="Matches" key={Routes.ROUTE_PLAYER_MATCHES}>
                                {player && player.matches && player.matches.length > 0 ? (
                                    <MatchTable
                                        userProfile={userProfile}
                                        matchesFromProps={transformPlayerMatchesToMatches(
                                            player.matches
                                        )}
                                    />
                                ) : (
                                    <div>This player does not participate in any match.</div>
                                )}
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        );
    }
}

export default PlayerPage;
