import { Form } from 'antd';
import React from 'react';
import AutocompleteDropdown from '../../../components/autocomplete-dropdown';
import DrawerButtons from '../../../components/drawer-buttons/index';
import { DropdownOption } from '../../../core/models/DropdownOption';
import { DrawerState } from '../../../core/models/enum';
import {
    confirmDropdownHelper,
    filterDropdownOptions,
} from '../../../helpers/AutocompleteDropdownHelper';
import { formItemLayout724 } from '../../../helpers/FormLayoutHelper';
import { success } from '../../../helpers/NotificationHelper';
import { createHelper, updateHelper } from '../../../helpers/SubmitHelper';
import { transformEnumToDropdownOptions } from '../../../helpers/TransformHelper';
import { customArrayValidationHelper } from '../../../helpers/ValidationHelper';
import {
    CreateMatchOfficialCommandOfMatchOfficial,
    MatchOfficialsClient,
    MatchOfficialVm,
    OfficialRoleEnum,
    OfficialsClient,
    OfficialVm,
    UpdateMatchOfficialCommandOfMatchOfficial,
} from '../../../utils/api';
import { Props } from './index';

interface State {
    isSaving: boolean;
    officials: DropdownOption[];
    roles: DropdownOption[];
    selectedOfficial?: DropdownOption[];
    selectedRole?: DropdownOption[];
}

class MatchOfficialForm extends React.PureComponent<Props, State> {
    private formRef: any;

    public constructor(props: Props) {
        super(props);

        this.formRef = React.createRef();

        this.state = {
            isSaving: false,
            officials: [],
            roles: [],
        };
    }

    public componentDidMount = (): void => {
        this.getOfficialsAndRoles();
    };

    public componentDidUpdate = (prevProps: Props): void => {
        const { matchOfficial } = this.props;

        if (matchOfficial !== prevProps.matchOfficial) {
            this.setInitialValues();
        }
    };

    private getOfficialsAndRoles = async (): Promise<void> => {
        const { existingOfficials, matchOfficial } = this.props;
        const officialClient = new OfficialsClient();
        const officialVMs = await officialClient.getAll();

        const officials: DropdownOption[] =
            officialVMs?.map(
                (o: OfficialVm): DropdownOption => ({
                    id: o.id.toString(),
                    name: `${o.name} ${o.surname}`,
                    disabled: !!existingOfficials.find(
                        (e: MatchOfficialVm): boolean =>
                            e.officialId === o.id &&
                            (matchOfficial ? e.officialId !== matchOfficial.officialId : true)
                    ),
                })
            ) || [];
        const roles = transformEnumToDropdownOptions(OfficialRoleEnum);

        this.setState(
            {
                officials,
                roles,
            },
            (): void => this.setInitialValues()
        );
    };

    private setInitialValues = (): void => {
        const { matchOfficial } = this.props;
        const { officials, roles } = this.state;

        const selectedOfficial = officials.find((o: DropdownOption): boolean =>
            matchOfficial ? o.id === matchOfficial.officialId.toString() : false
        );
        const selectedRole = roles.filter((r: DropdownOption): boolean =>
            matchOfficial ? matchOfficial.roleId.toString() === r.id : false
        );

        this.setState({
            selectedOfficial: selectedOfficial ? [selectedOfficial] : undefined,
            selectedRole,
        });
    };

    private onSubmit = (): void => {
        const { selectedOfficial, selectedRole } = this.state;
        const { matchOfficial, matchId } = this.props;

        this.setState({
            isSaving: true,
        });

        if (
            selectedOfficial &&
            selectedOfficial?.length > 0 &&
            selectedRole &&
            selectedRole?.length > 0
        ) {
            const client = new MatchOfficialsClient();

            if (matchOfficial?.id) {
                const updatedMatchOfficial = new UpdateMatchOfficialCommandOfMatchOfficial({
                    roleId: parseInt(selectedRole[0].id, 10),
                    id: matchOfficial.id,
                    matchId,
                    officialId: parseInt(selectedOfficial[0].id, 10),
                });
                updatedMatchOfficial.id = matchOfficial.id;
                updateHelper(client, updatedMatchOfficial, this.handleSuccessfullySaving);
            } else {
                const newMatchOfficial = new CreateMatchOfficialCommandOfMatchOfficial({
                    roleId: parseInt(selectedRole[0].id, 10),
                    id: 0,
                    matchId,
                    officialId: parseInt(selectedOfficial[0].id, 10),
                });
                createHelper(client, newMatchOfficial, this.handleSuccessfullySaving);
            }
        }

        this.setState({
            isSaving: false,
        });
    };

    private handleSuccessfullySaving = (): void => {
        const { matchOfficial, handleClose, refreshAfterSave } = this.props;

        success('Official successfully saved.');
        refreshAfterSave();
        handleClose(
            matchOfficial ? DrawerState.Read : DrawerState.Closed,
            matchOfficial?.id?.toString()
        );
    };

    public render(): React.ReactElement {
        const { isSaving, selectedOfficial, officials, selectedRole, roles } = this.state;
        const { matchOfficial, handleClose } = this.props;

        return (
            <Form onFinish={this.onSubmit} {...formItemLayout724} ref={this.formRef}>
                <DrawerButtons
                    isSaving={isSaving}
                    onCancelAction={(): void =>
                        handleClose(
                            matchOfficial ? DrawerState.Read : DrawerState.Closed,
                            matchOfficial?.id?.toString()
                        )
                    }
                />
                <div className="drawer-content">
                    <div>
                        <div>
                            <div
                                style={{
                                    width: '100%',
                                    float: 'left',
                                }}
                            >
                                <Form.Item
                                    name="officialId"
                                    label="Official"
                                    rules={[
                                        {
                                            required: true,
                                            validator: (
                                                _rule: any,
                                                value: any,
                                                callback: any
                                            ): void =>
                                                customArrayValidationHelper(
                                                    _rule,
                                                    value,
                                                    callback,
                                                    'Official field is required!',
                                                    selectedOfficial
                                                ),
                                        },
                                    ]}
                                >
                                    <AutocompleteDropdown
                                        placeholder="Choose official"
                                        getOptionsFrontend={(value: string): DropdownOption[] =>
                                            filterDropdownOptions(value, officials)
                                        }
                                        initialValues={selectedOfficial}
                                        confirmDirty={(options: DropdownOption[]): void =>
                                            this.setState({
                                                selectedOfficial: confirmDropdownHelper(options),
                                                selectedRole: undefined,
                                            })
                                        }
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="roleId"
                                    label="Role"
                                    rules={[
                                        {
                                            required: true,
                                            validator: (
                                                _rule: any,
                                                value: any,
                                                callback: any
                                            ): void =>
                                                customArrayValidationHelper(
                                                    _rule,
                                                    value,
                                                    callback,
                                                    'Role field is required!',
                                                    selectedRole
                                                ),
                                        },
                                    ]}
                                >
                                    <AutocompleteDropdown
                                        placeholder="Choose role"
                                        getOptionsFrontend={(value: string): DropdownOption[] =>
                                            filterDropdownOptions(value, roles)
                                        }
                                        initialValues={selectedRole}
                                        confirmDirty={(options: DropdownOption[]): void =>
                                            this.setState({
                                                selectedRole: confirmDropdownHelper(options),
                                            })
                                        }
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        );
    }
}

export default MatchOfficialForm;
