import React from 'react';
import { Route } from 'react-router';
import { RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import Routes from './config/routes';
import { ModuleName } from './core/models/enum';
import { AuthorizationRoutes } from './components/api-authorization/ApiAuthorizationConstants';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import { Home } from './components/Home';
import Layout from './components/Layout';
import NotFoundPage from './components/non-existing-page';
import Clubs from './modules/clubs';
import ClubPage from './modules/clubs/club-page';
import Coaches from './modules/coaches';
import CoachPage from './modules/coaches/coach-page';
import Competitions from './modules/competitions';
import CompetitionPage from './modules/competitions/competition-page';
import Federations from './modules/federations';
import FederationPage from './modules/federations/federation-page';
import MatchLog from './modules/match-log';
import MatchReport from './modules/match-report';
import Matches from './modules/matches';
import MatchPage from './modules/matches/match-page';
import Officials from './modules/officials';
import OfficialPage from './modules/officials/official-page';
import Players from './modules/players';
import PlayerPage from './modules/players/player-page';
import Teams from './modules/teams';
import TeamPage from './modules/teams/team-page';
import Users from './modules/users';
import Cities from './modules/cities';
import TestSocket from './modules/sockets/socket';
import 'antd/dist/antd.css';
import './custom.css';

type Props = RouteComponentProps<{}>;

class App extends React.PureComponent<Props> {
    componentDidMount() {
        document.title = 'Total Waterpolo Arena';
    }

    render() {
        const { history } = this.props;

        return (
            <Layout
                isSidebarHidden={
                    history.location.pathname.includes('match-log') ||
                    history.location.pathname.includes('match-report')
                }
            >
                <Switch>
                    <AuthorizeRoute
                        exact
                        path={Routes.ROUTE_DASHBOARD}
                        component={Home}
                        moduleName={ModuleName.Dashboard}
                    />
                    <Route path={Routes.ROUTE_NOT_FOUND} component={NotFoundPage} />

                    <Route path={Routes.ROUTE_SOCKET} component={TestSocket} />

                    <Route
                        path={AuthorizationRoutes.ApiAuthorizationPrefix}
                        component={ApiAuthorizationRoutes}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_FEDERATION_OFFICIALS_EDIT}
                        component={FederationPage}
                        moduleName={ModuleName.Federations}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_FEDERATION_OFFICIALS}
                        component={FederationPage}
                        moduleName={ModuleName.Federations}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_FEDERATION_CLUBS_CREATE}
                        component={FederationPage}
                        moduleName={ModuleName.Federations}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_FEDERATION_COMPETITIONS_CREATE}
                        component={FederationPage}
                        moduleName={ModuleName.Federations}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_FEDERATION_CLUBS}
                        component={FederationPage}
                        moduleName={ModuleName.Federations}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_FEDERATION_COMPETITIONS}
                        component={FederationPage}
                        moduleName={ModuleName.Federations}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_FEDERATIONS_CREATE}
                        component={Federations}
                        moduleName={ModuleName.Federations}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_FEDERATIONS_EDIT}
                        component={FederationPage}
                        moduleName={ModuleName.Federations}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_FEDERATIONS_READ}
                        component={FederationPage}
                        moduleName={ModuleName.Federations}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_FEDERATIONS}
                        component={Federations}
                        moduleName={ModuleName.Federations}
                    />

                    <AuthorizeRoute
                        path={Routes.ROUTE_CLUB_PLAYERS_EDIT}
                        component={ClubPage}
                        moduleName={ModuleName.Clubs}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_CLUB_PLAYERS_CREATE}
                        component={ClubPage}
                        moduleName={ModuleName.Clubs}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_CLUB_PLAYERS}
                        component={ClubPage}
                        moduleName={ModuleName.Clubs}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_CLUB_COACHES_EDIT}
                        component={ClubPage}
                        moduleName={ModuleName.Clubs}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_CLUB_COACHES_CREATE}
                        component={ClubPage}
                        moduleName={ModuleName.Clubs}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_CLUB_COACHES}
                        component={ClubPage}
                        moduleName={ModuleName.Clubs}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_CLUB_TEAMS_CREATE}
                        component={ClubPage}
                        moduleName={ModuleName.Clubs}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_CLUB_TEAMS}
                        component={ClubPage}
                        moduleName={ModuleName.Clubs}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_CLUBS_CREATE}
                        component={Clubs}
                        moduleName={ModuleName.Clubs}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_CLUBS_EDIT}
                        component={ClubPage}
                        moduleName={ModuleName.Clubs}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_CLUBS_READ}
                        component={ClubPage}
                        moduleName={ModuleName.Clubs}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_CLUBS}
                        component={Clubs}
                        moduleName={ModuleName.Clubs}
                    />

                    <AuthorizeRoute
                        path={Routes.ROUTE_TEAM_PLAYERS_EDIT}
                        component={TeamPage}
                        moduleName={ModuleName.Teams}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_TEAM_PLAYERS_CREATE}
                        component={TeamPage}
                        moduleName={ModuleName.Teams}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_TEAM_PLAYERS}
                        component={TeamPage}
                        moduleName={ModuleName.Teams}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_TEAM_COACHES_EDIT}
                        component={TeamPage}
                        moduleName={ModuleName.Teams}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_TEAM_COACHES_CREATE}
                        component={TeamPage}
                        moduleName={ModuleName.Teams}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_TEAM_COACHES}
                        component={TeamPage}
                        moduleName={ModuleName.Teams}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_TEAM_MATCHES}
                        component={TeamPage}
                        moduleName={ModuleName.Teams}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_TEAM_COMPETITIONS}
                        component={TeamPage}
                        moduleName={ModuleName.Teams}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_TEAM_CLUB}
                        component={TeamPage}
                        moduleName={ModuleName.Teams}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_TEAMS_CREATE}
                        component={Teams}
                        moduleName={ModuleName.Teams}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_TEAMS_EDIT}
                        component={TeamPage}
                        moduleName={ModuleName.Teams}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_TEAMS_READ}
                        component={TeamPage}
                        moduleName={ModuleName.Teams}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_TEAMS}
                        component={Teams}
                        moduleName={ModuleName.Teams}
                    />

                    <AuthorizeRoute
                        path={Routes.ROUTE_COMPETITION_MATCHES_CREATE}
                        component={CompetitionPage}
                        moduleName={ModuleName.Competitions}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_COMPETITION_TEAMS_EDIT}
                        component={CompetitionPage}
                        moduleName={ModuleName.Competitions}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_COMPETITION_TEAMS_CREATE}
                        component={CompetitionPage}
                        moduleName={ModuleName.Competitions}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_COMPETITION_MATCHES}
                        component={CompetitionPage}
                        moduleName={ModuleName.Competitions}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_COMPETITION_TEAMS}
                        component={CompetitionPage}
                        moduleName={ModuleName.Competitions}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_COMPETITIONS_CREATE}
                        component={Competitions}
                        moduleName={ModuleName.Competitions}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_COMPETITIONS_EDIT}
                        component={CompetitionPage}
                        moduleName={ModuleName.Competitions}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_COMPETITIONS_READ}
                        component={CompetitionPage}
                        moduleName={ModuleName.Competitions}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_COMPETITIONS}
                        component={Competitions}
                        moduleName={ModuleName.Competitions}
                    />

                    <AuthorizeRoute
                        path={Routes.ROUTE_MATCH_OFFICIALS_EDIT}
                        component={MatchPage}
                        moduleName={ModuleName.Matches}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_MATCH_OFFICIALS_CREATE}
                        component={MatchPage}
                        moduleName={ModuleName.Matches}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_MATCH_OFFICIALS}
                        component={MatchPage}
                        moduleName={ModuleName.Matches}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_MATCH_LINEUPS_EDIT}
                        component={MatchPage}
                        moduleName={ModuleName.Matches}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_MATCH_LINEUPS}
                        component={MatchPage}
                        moduleName={ModuleName.Matches}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_MATCHES_CREATE}
                        component={Matches}
                        moduleName={ModuleName.Matches}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_MATCHES_EDIT}
                        component={MatchPage}
                        moduleName={ModuleName.Matches}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_MATCHES_READ}
                        component={MatchPage}
                        moduleName={ModuleName.Matches}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_MATCHES}
                        component={Matches}
                        moduleName={ModuleName.Matches}
                    />

                    <AuthorizeRoute
                        path={Routes.ROUTE_MATCH_LOG}
                        component={MatchLog}
                        moduleName={ModuleName.Matches}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_MATCH_REPORT}
                        component={MatchReport}
                        moduleName={ModuleName.Matches}
                    />

                    <AuthorizeRoute
                        path={Routes.ROUTE_OFFICIAL_PERSONAL_INFO_EDIT}
                        component={OfficialPage}
                        moduleName={ModuleName.Officials}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_OFFICIAL_PERSONAL_INFO}
                        component={OfficialPage}
                        moduleName={ModuleName.Officials}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_OFFICIAL_MATCHES}
                        component={OfficialPage}
                        moduleName={ModuleName.Officials}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_OFFICIAL_ROLES_CREATE}
                        component={OfficialPage}
                        moduleName={ModuleName.Officials}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_OFFICIAL_ROLES_EDIT}
                        component={OfficialPage}
                        moduleName={ModuleName.Officials}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_OFFICIAL_ROLES}
                        component={OfficialPage}
                        moduleName={ModuleName.Officials}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_OFFICIALS_CREATE}
                        component={Officials}
                        moduleName={ModuleName.Officials}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_OFFICIALS_EDIT}
                        component={OfficialPage}
                        moduleName={ModuleName.Officials}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_OFFICIALS_READ}
                        component={OfficialPage}
                        moduleName={ModuleName.Officials}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_OFFICIALS}
                        component={Officials}
                        moduleName={ModuleName.Officials}
                    />

                    <AuthorizeRoute
                        path={Routes.ROUTE_COACH_CLUBS_CREATE}
                        component={CoachPage}
                        moduleName={ModuleName.Coaches}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_COACH_CLUBS}
                        component={CoachPage}
                        moduleName={ModuleName.Coaches}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_COACH_MATCHES_CREATE}
                        component={CoachPage}
                        moduleName={ModuleName.Coaches}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_COACH_MATCHES}
                        component={CoachPage}
                        moduleName={ModuleName.Coaches}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_COACH_TEAMS_CREATE}
                        component={CoachPage}
                        moduleName={ModuleName.Coaches}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_COACH_TEAMS}
                        component={CoachPage}
                        moduleName={ModuleName.Coaches}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_COACH_PERSONAL_INFO_EDIT}
                        component={CoachPage}
                        moduleName={ModuleName.Coaches}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_COACH_PERSONAL_INFO}
                        component={CoachPage}
                        moduleName={ModuleName.Coaches}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_COACHES_CREATE}
                        component={Coaches}
                        moduleName={ModuleName.Coaches}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_COACHES_EDIT}
                        component={CoachPage}
                        moduleName={ModuleName.Coaches}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_COACHES_READ}
                        component={CoachPage}
                        moduleName={ModuleName.Coaches}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_COACHES}
                        component={Coaches}
                        moduleName={ModuleName.Coaches}
                    />

                    <AuthorizeRoute
                        path={Routes.ROUTE_PLAYER_CLUBS_CREATE}
                        component={PlayerPage}
                        moduleName={ModuleName.Players}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_PLAYER_CLUBS}
                        component={PlayerPage}
                        moduleName={ModuleName.Players}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_PLAYER_MATCHES_CREATE}
                        component={PlayerPage}
                        moduleName={ModuleName.Players}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_PLAYER_MATCHES}
                        component={PlayerPage}
                        moduleName={ModuleName.Players}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_PLAYER_TEAMS_CREATE}
                        component={PlayerPage}
                        moduleName={ModuleName.Players}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_PLAYER_TEAMS}
                        component={PlayerPage}
                        moduleName={ModuleName.Players}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_PLAYER_PERSONAL_INFO_EDIT}
                        component={PlayerPage}
                        moduleName={ModuleName.Players}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_PLAYER_PERSONAL_INFO}
                        component={PlayerPage}
                        moduleName={ModuleName.Players}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_PLAYERS_CREATE}
                        component={Players}
                        moduleName={ModuleName.Players}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_PLAYERS_EDIT}
                        component={PlayerPage}
                        moduleName={ModuleName.Players}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_PLAYERS_READ}
                        component={PlayerPage}
                        moduleName={ModuleName.Players}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_PLAYERS}
                        component={Players}
                        moduleName={ModuleName.Players}
                    />

                    <AuthorizeRoute
                        path={Routes.ROUTE_USERS_CREATE}
                        component={Users}
                        moduleName={ModuleName.Users}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_USERS_EDIT}
                        component={Users}
                        moduleName={ModuleName.Users}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_USERS_READ}
                        component={Users}
                        moduleName={ModuleName.Users}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_USERS}
                        component={Users}
                        moduleName={ModuleName.Users}
                    />

                    <AuthorizeRoute
                        path={Routes.ROUTE_CITIES_CREATE}
                        component={Cities}
                        moduleName={ModuleName.Cities}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_CITIES_EDIT}
                        component={Cities}
                        moduleName={ModuleName.Cities}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_CITIES_READ}
                        component={Cities}
                        moduleName={ModuleName.Cities}
                    />
                    <AuthorizeRoute
                        path={Routes.ROUTE_CITIES}
                        component={Cities}
                        moduleName={ModuleName.Cities}
                    />
                </Switch>
            </Layout>
        );
    }
}

export default withRouter(App);
