import React from 'react';
import { Props } from '.';
import { compareEventsByTime, getPlayerNumber, getTeamId } from '../../../helpers/EventHelper';
import {
    CreateEventCommand,
    CreateMatchPlayerCommandOfMatchPlayer,
    EventPeriodEnum,
    EventVm,
    UpdateEventCommand,
} from '../../../utils/api';
import MatchLogItem from './log-item';

class MatchEventLog extends React.PureComponent<Props> {
    public render = (): React.ReactElement => {
        const { events, match, lineups, coaches, editEvent } = this.props;
        const sortedEvents = events.sort(compareEventsByTime);

        const q1Events = sortedEvents.filter(
            (e: EventVm | CreateEventCommand): boolean =>
                e.periodId === EventPeriodEnum.First_Period
        );
        const q2Events = sortedEvents.filter(
            (e: EventVm): boolean => e.periodId === EventPeriodEnum.Second_Period
        );
        const q3Events = sortedEvents.filter(
            (e: EventVm): boolean => e.periodId === EventPeriodEnum.Third_Period
        );
        const q4Events = sortedEvents.filter(
            (e: EventVm): boolean => e.periodId === EventPeriodEnum.Fourth_Period
        );
        const psoEvents = sortedEvents.filter(
            (e: EventVm): boolean => e.periodId === EventPeriodEnum.Overtime
        );

        return (
            <div className="o-event-list">
                {match &&
                    psoEvents.map(
                        (e: EventVm): React.ReactNode => (
                            <MatchLogItem
                                key={e.id}
                                event={(e as unknown) as UpdateEventCommand}
                                isHomeTeam={getTeamId(e) === match.homeTeamId}
                                lineups={lineups}
                                lineup={lineups.find(
                                    (l: CreateMatchPlayerCommandOfMatchPlayer): boolean =>
                                        l.number === getPlayerNumber(e) && l.teamId === getTeamId(e)
                                )}
                                coaches={coaches}
                                editEvent={editEvent}
                            />
                        )
                    )}
                {match && psoEvents.length > 0 && (
                    <span className="event-log-quarter">Overtime started</span>
                )}
                {match &&
                    q4Events.map(
                        (e: EventVm): React.ReactNode => (
                            <MatchLogItem
                                key={e.id}
                                event={(e as unknown) as UpdateEventCommand}
                                isHomeTeam={getTeamId(e) === match.homeTeamId}
                                lineups={lineups}
                                lineup={lineups.find(
                                    (l: CreateMatchPlayerCommandOfMatchPlayer): boolean =>
                                        l.number === getPlayerNumber(e) && l.teamId === getTeamId(e)
                                )}
                                coaches={coaches}
                                editEvent={editEvent}
                            />
                        )
                    )}
                {match && q4Events.length > 0 && (
                    <span className="event-log-quarter">4th period started</span>
                )}
                {match &&
                    q3Events.map(
                        (e: EventVm): React.ReactNode => (
                            <MatchLogItem
                                key={e.id}
                                event={(e as unknown) as UpdateEventCommand}
                                isHomeTeam={getTeamId(e) === match.homeTeamId}
                                lineups={lineups}
                                lineup={lineups.find(
                                    (l: CreateMatchPlayerCommandOfMatchPlayer): boolean =>
                                        l.number === getPlayerNumber(e) && l.teamId === getTeamId(e)
                                )}
                                coaches={coaches}
                                editEvent={editEvent}
                            />
                        )
                    )}
                {match && q3Events.length > 0 && (
                    <span className="event-log-quarter">3rd period started</span>
                )}
                {match &&
                    q2Events.map(
                        (e: EventVm): React.ReactNode => (
                            <MatchLogItem
                                key={e.id}
                                event={(e as unknown) as UpdateEventCommand}
                                isHomeTeam={getTeamId(e) === match.homeTeamId}
                                lineups={lineups}
                                lineup={lineups.find(
                                    (l: CreateMatchPlayerCommandOfMatchPlayer): boolean =>
                                        l.number === getPlayerNumber(e) && l.teamId === getTeamId(e)
                                )}
                                coaches={coaches}
                                editEvent={editEvent}
                            />
                        )
                    )}
                {match && q2Events.length > 0 && (
                    <span className="event-log-quarter">2nd period started</span>
                )}
                {match &&
                    q1Events.map(
                        (e: EventVm): React.ReactNode => (
                            <MatchLogItem
                                key={e.id}
                                event={(e as unknown) as UpdateEventCommand}
                                isHomeTeam={getTeamId(e) === match.homeTeamId}
                                lineups={lineups}
                                lineup={lineups.find(
                                    (l: CreateMatchPlayerCommandOfMatchPlayer): boolean =>
                                        l.number === getPlayerNumber(e) && l.teamId === getTeamId(e)
                                )}
                                coaches={coaches}
                                editEvent={editEvent}
                            />
                        )
                    )}
                {match && q1Events.length > 0 && (
                    <span className="event-log-quarter">1st period started</span>
                )}
            </div>
        );
    };
}

export default MatchEventLog;
