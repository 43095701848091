import { PlusOutlined } from '@ant-design/icons';
import { Button, Drawer, PageHeader, Table } from 'antd';
import moment from 'moment';
import React from 'react';
import TableActionButtons from '../../../components/table-action-buttons';
import { DrawerWidth } from '../../../config/dimensions';
import Routes from '../../../config/routes';
import ReplaceStrings from '../../../config/replaceStrings';
import { ActionOption, DrawerState, ModuleName } from '../../../core/models/enum';
import {
    getAllowedEntities,
    isUserAdmin,
    isUserAllowed,
} from '../../../helpers/CheckPermissionHelper';
import { convertUTCTimeToLocal } from '../../../helpers/DateHelper';
import { PaginationOptions } from '../../../helpers/PaginationHelper';
import { getNewSubRoute } from '../../../helpers/RoutingHelper';
import { handleDeleteHelper } from '../../../helpers/SubmitHelper';
import {
    FederationOfficialRoleVm,
    FederationOfficialsClient,
    FederationOfficialVm,
} from '../../../utils/api';
import { Props } from './index';
import OfficialRoleForm from './official-role-form';

interface State {
    drawerState: DrawerState;
    selectedOfficialRole?: FederationOfficialVm;
    isUserAllowedToCreate: boolean;
    isUserAllowedToUpdate: boolean;
    isUserAllowedToDelete: boolean;
    allowedEntities: number[];
    isAdmin: boolean;
}

class OfficialRoles extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        const {
            match: {
                path,
                params: { officialRoleId },
            },
            officialRoles,
        } = props;

        const drawerState =
            path === Routes.ROUTE_OFFICIAL_ROLES_CREATE
                ? DrawerState.Create
                : path === Routes.ROUTE_OFFICIAL_ROLES_EDIT
                ? DrawerState.Edit
                : DrawerState.Closed;

        this.state = {
            drawerState,
            selectedOfficialRole:
                drawerState === DrawerState.Closed
                    ? undefined
                    : officialRoles.find(
                          (oR: FederationOfficialVm): boolean => oR.id.toString() === officialRoleId
                      ),
            isUserAllowedToCreate: false,
            isUserAllowedToUpdate: false,
            isUserAllowedToDelete: false,
            allowedEntities: [],
            isAdmin: false,
        };
    }

    public componentDidMount = (): void => {
        this.getPermissions();
    };

    private getPermissions = (): void => {
        const { userProfile } = this.props;

        this.setState({
            isUserAllowedToCreate: isUserAllowed(
                userProfile,
                ModuleName.FederationRoles,
                ActionOption.Create
            ),
            isUserAllowedToUpdate: isUserAllowed(
                userProfile,
                ModuleName.FederationRoles,
                ActionOption.Update
            ),
            isUserAllowedToDelete: isUserAllowed(
                userProfile,
                ModuleName.FederationRoles,
                ActionOption.Delete
            ),
            allowedEntities: getAllowedEntities(userProfile, ModuleName.Federations),
            isAdmin: isUserAdmin(userProfile),
        });
    };

    private handleDrawerMode = (drawerState: DrawerState, id?: string): void => {
        const {
            history,
            match: {
                params: { officialId },
            },
            officialRoles,
        } = this.props;

        this.setState({
            drawerState,
            selectedOfficialRole:
                drawerState === DrawerState.Closed
                    ? undefined
                    : officialRoles.find(
                          (oR: FederationOfficialVm): boolean => oR.id.toString() === id
                      ),
        });

        history.push(
            getNewSubRoute(
                Routes.ROUTE_OFFICIAL_ROLES,
                Routes.ROUTE_OFFICIAL_ROLES,
                Routes.ROUTE_OFFICIAL_ROLES_EDIT,
                Routes.ROUTE_OFFICIAL_ROLES_CREATE,
                drawerState,
                ReplaceStrings.OFFICIAL_ID,
                ReplaceStrings.OFFICIAL_ROLE_ID,
                officialId,
                id
            )
        );
    };

    private handleDelete = async (id?: number): Promise<any> => {
        if (id) {
            handleDeleteHelper(
                new FederationOfficialsClient(),
                'Federation official successfully deleted.',
                this.onSuccessfullyDeleted,
                id
            );
        }
    };

    private onSuccessfullyDeleted = (): void => {
        const { refreshAfterSave } = this.props;

        refreshAfterSave();
    };

    private onUpdate = async (id?: number): Promise<void> => {
        if (id) {
            this.handleDrawerMode(DrawerState.Edit, id.toString());
        }
    };

    public render(): React.ReactElement {
        const {
            drawerState,
            selectedOfficialRole,
            isUserAllowedToCreate,
            isUserAllowedToDelete,
            isUserAllowedToUpdate,
            allowedEntities,
            isAdmin,
        } = this.state;
        const { officialRoles, officialId, refreshAfterSave } = this.props;

        const columns = [
            {
                title: 'Federation',
                dataIndex: 'federation',
                key: 'federation',
            },
            {
                title: 'Role',
                dataIndex: 'roles',
                key: 'roles',
                render: (values?: FederationOfficialRoleVm[]): string => {
                    if (values && values.length > 0) {
                        const strings: string[] = [];
                        values.forEach((v: FederationOfficialRoleVm): void => {
                            strings.push(v.role.replace('_', ' '));
                        });
                        return strings.join(', ');
                    }
                    return '';
                },
            },
            {
                title: 'Active from',
                dataIndex: 'activeFrom',
                key: 'activeFrom',
                render: (value: Date): string =>
                    value ? moment(convertUTCTimeToLocal(value)).format('DD.MM.YYYY.') : '',
                width: 110,
            },
            {
                title: 'Active to',
                dataIndex: 'activeTo',
                key: 'activeTo',
                render: (value: Date): string =>
                    value ? moment(convertUTCTimeToLocal(value)).format('DD.MM.YYYY.') : '',
                width: 110,
            },
            {
                title: '',
                dataIndex: '',
                key: '',
                render: (value: FederationOfficialVm): React.ReactElement => (
                    <TableActionButtons
                        id={value?.id}
                        confirmMessage="Are you sure you want to remove this official?"
                        onDeleteAction={this.handleDelete}
                        onUpdateAction={this.onUpdate}
                        isUserAllowedToUpdate={
                            isUserAllowedToUpdate &&
                            (isAdmin ||
                                true || !!allowedEntities.find(
                                    (ent: number): boolean => ent === value?.federationId
                                ))
                        }
                        isUserAllowedToDelete={
                            isUserAllowedToDelete &&
                            (isAdmin ||
                                true || !!allowedEntities.find(
                                    (ent: number): boolean => ent === value?.federationId
                                ))
                        }
                    />
                ),
                width: 80,
            },
        ];

        return (
            <div>
                <PageHeader
                    title=""
                    extra={
                        isUserAllowedToCreate
                            ? [
                                  <Button
                                      key="1"
                                      type="primary"
                                      style={{
                                          zIndex: 10,
                                          float: 'right',
                                      }}
                                      onClick={(): void =>
                                          this.handleDrawerMode(DrawerState.Create)
                                      }
                                  >
                                      <PlusOutlined className="button-icon-display" />
                                      Add federation
                                  </Button>,
                              ]
                            : []
                    }
                    style={{ padding: '16px 0px' }}
                />
                <Table
                    columns={columns}
                    dataSource={officialRoles}
                    rowKey={(record): string => (record && record.id ? record.id.toString() : '-1')}
                    pagination={PaginationOptions}
                    bordered
                    size="small"
                />
                <Drawer
                    title={
                        drawerState === DrawerState.Create ? 'Add federation' : 'Edit federation'
                    }
                    visible={!!drawerState}
                    onClose={(): void => this.handleDrawerMode(DrawerState.Closed)}
                    width={DrawerWidth}
                    destroyOnClose
                >
                    <OfficialRoleForm
                        initialValues={{
                            officialId: officialId,
                            federationIds: officialRoles.map(
                                (oR: FederationOfficialVm): number => oR.federationId
                            ),
                        }}
                        handleClose={this.handleDrawerMode}
                        refreshAfterSave={refreshAfterSave}
                        officialRole={selectedOfficialRole}
                    />
                </Drawer>
            </div>
        );
    }
}

export default OfficialRoles;
