import moment from 'moment';
import momentHr from 'moment/locale/hr';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string | undefined;
const rootElement = document.getElementById('root');
moment.updateLocale('language', {
    ...momentHr,
    week: {
        ...momentHr.week,
        dow: 1,
    }
});

ReactDOM.render(
    <BrowserRouter basename={baseUrl}>
        <App />
    </BrowserRouter>,
    rootElement
);
