import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Select, Button, Space } from 'antd';
import React from 'react';
import { DropdownOption } from '../../core/models/DropdownOption';

interface Props {
    selectedKeys: string[];
    confirm: Function;
    setSelectedKeys: Function;
    clearFilters: Function;
    options: DropdownOption[];
    placeholder: React.ReactElement;
}
const { Option } = Select;

class SelectFilter extends React.Component<Props> {
    private handleReset = (): void => {
        const { setSelectedKeys, clearFilters } = this.props;

        setSelectedKeys([]);
        clearFilters();
    };

    private onChange = (vls: string[]): void => {
        const { setSelectedKeys } = this.props;

        if (vls.length > 0) {
            setSelectedKeys(vls);
        } else {
            setSelectedKeys([]);
        }
    };

    public render = (): React.ReactElement => {
        const { options, selectedKeys, confirm, placeholder } = this.props;

        return (
            <Space style={{ padding: 8 }}>
                <Select
                    showSearch
                    placeholder={placeholder}
                    style={{ width: 200 }}
                    mode="multiple"
                    value={selectedKeys}
                    onChange={this.onChange}
                >
                    {options.map(
                        (o: DropdownOption): React.ReactElement => (
                            <Option value={o.id}>{o.name}</Option>
                        )
                    )}
                </Select>
                <Button
                    type="primary"
                    onClick={(): void => confirm()}
                    icon={<SearchOutlined />}
                    size="small"
                />
                <Button
                    onClick={(): void => this.handleReset()}
                    icon={<CloseOutlined />}
                    size="small"
                />
            </Space>
        );
    };
}

export default SelectFilter;
