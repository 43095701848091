import { Button, Drawer, PageHeader, Spin, Tabs } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import React from 'react';
import PageHeaderTitle from '../../../components/page-header';
import Routes from '../../../config/routes';
import ReplaceStrings from '../../../config/replaceStrings';
import { DrawerWidth } from '../../../config/dimensions';
import { getNewRoute, getSubPageInitialProperties } from '../../../helpers/RoutingHelper';
import { groupMatchOfficialsByOfficialId } from '../../../helpers/CompareHelper';
import { errorWithUserMessage, success } from '../../../helpers/NotificationHelper';
import { isUserAllowed } from '../../../helpers/CheckPermissionHelper';
import { MatchDetailVm, MatchesClient, MatchOfficialsClient, MatchOfficialVm, OfficialVm } from '../../../utils/api';
import MatchLineups from '../match-lineups';
import MatchDetails from './match-details';
import OfficialTable from '../../officials/official-table';
import MatchOfficialForm from '../../officials/match-official-form';
import { ActionOption, DrawerState, ModuleName } from '../../../core/models/enum';
import { OfficialVmWithRoles } from '../../../core/models/OfficialVmWithRoles';
import { Props } from './index';

const { TabPane } = Tabs;

interface State {
    matchDetails?: MatchDetailVm;
    activeTabKey: string;
    isEditMode: boolean;
    id?: string;
    drawerState: DrawerState;
    loading: boolean;
    officials?: OfficialVm[];
    selectedMatchOfficial?: MatchOfficialVm;
    isUserAllowedToCreateOfficial: boolean;
}

class MatchPage extends React.PureComponent<Props, State> {
    private officialTableRef: any;

    public constructor(props: Props) {
        super(props);

        const {
            location: { pathname },
        } = window;
        this.officialTableRef = React.createRef();

        const initialProperties = getSubPageInitialProperties(
            pathname,
            'matches',
            props.match,
            Routes.ROUTE_MATCHES_READ,
            Routes.ROUTE_MATCHES_EDIT
        );

        this.state = {
            activeTabKey: initialProperties.activeTabKey || Routes.ROUTE_MATCHES_READ,
            id: initialProperties.initialEntityId,
            isEditMode: initialProperties.isInitialEditMode,
            drawerState:
                initialProperties.activeTabKey &&
                    initialProperties.activeTabKey !== Routes.ROUTE_MATCHES_READ &&
                    initialProperties.isInitialEditMode
                    ? DrawerState.Edit
                    : DrawerState.Closed,
            loading: true,
            isUserAllowedToCreateOfficial: false,
        };
    }

    public componentDidMount = (): void => {
        this.getPermissions();
        this.getMatchDetails();
    };

    public componentDidUpdate = (prevProps: Props): void => {
        const {
            match: { path },
        } = this.props;

        if (path !== prevProps.match.path) {
            this.updateStateAfterRouteChanges();
        }
    };

    private getPermissions = (): void => {
        const { userProfile } = this.props;

        this.setState({
            isUserAllowedToCreateOfficial: isUserAllowed(
                userProfile,
                ModuleName.Officials,
                ActionOption.Create
            ),
        });
    };

    private updateStateAfterRouteChanges = (): void => {
        const { match } = this.props;
        const {
            location: { pathname },
        } = window;

        const initialProperties = getSubPageInitialProperties(
            pathname,
            'matches',
            match,
            Routes.ROUTE_MATCHES_READ,
            Routes.ROUTE_MATCHES_EDIT
        );

        this.setNewSelectedMatchOfficial();

        this.setState({
            activeTabKey: initialProperties.activeTabKey || Routes.ROUTE_MATCHES_READ,
            id: initialProperties.initialEntityId,
            isEditMode: initialProperties.isInitialEditMode,
            drawerState:
                initialProperties.activeTabKey &&
                    initialProperties.activeTabKey !== Routes.ROUTE_FEDERATIONS_READ &&
                    initialProperties.isInitialEditMode
                    ? DrawerState.Edit
                    : DrawerState.Closed,
        });
    };

    private setNewSelectedMatchOfficial = (): void => {
        const { match } = this.props;
        const { matchDetails, selectedMatchOfficial } = this.state;

        const newSelectedMatchOfficial = match.params.officialId
            ? matchDetails?.officials?.find(
                (fo: MatchOfficialVm): boolean =>
                    fo.officialId.toString() === match.params.officialId
            )
            : selectedMatchOfficial;

        this.setState({
            selectedMatchOfficial: newSelectedMatchOfficial,
        });
    }

    private getMatchDetails = async (section?: string): Promise<void> => {
        const {
            match: {
                params: { matchId },
            },
        } = this.props;

        this.setState({
            loading: true,
        });

        if (matchId) {
            const matchClient = new MatchesClient();
            const matchDetails = await matchClient.getById(parseInt(matchId, 10));

            this.setState({
                matchDetails,
            });
        }

        if (
            section === 'officials' &&
            this.officialTableRef &&
            this.officialTableRef.current
        ) {
            this.officialTableRef.current.getOfficials();
        }

        this.setNewSelectedMatchOfficial();

        this.setState({
            loading: false,
        });
    };

    private handleDrawerMode = (drawerState: DrawerState, section?: string): void => {
        const {
            history,
            match: {
                params: { matchId },
            },
        } = this.props;

        this.setState({
            drawerState,
        });

        if (section === 'officials') {
            history.push(
                getNewRoute(
                    Routes.ROUTE_MATCH_OFFICIALS,
                    Routes.ROUTE_MATCH_OFFICIALS,
                    Routes.ROUTE_MATCH_OFFICIALS_CREATE,
                    drawerState,
                    ReplaceStrings.MATCH_ID,
                    matchId
                )
            );
        }
    };

    private handleMatchOfficialDelete = async (id?: number): Promise<void> => {
        const { matchDetails } = this.state;

        if (!id) return;

        try {
            const matchOfficials = matchDetails?.officials || [];
            const matchOfficial = matchOfficials.find(
                (mo: MatchOfficialVm): boolean => mo.officialId === id
            );

            if (!matchOfficial?.id) return;

            const client = new MatchOfficialsClient();
            const result = await client.delete(matchOfficial.id);

            if (!result) return;
            success('Match official successfully deleted.');

            await this.getMatchDetails();
            if (this.officialTableRef && this.officialTableRef.current) {
                this.officialTableRef.current.getOfficials();
            }
        } catch (error) {
            errorWithUserMessage('Error deleting data.');
        }
    };

    private handleTabClick = (route: string): void => {
        const {
            history,
            match: {
                params: { matchId },
            },
        } = this.props;

        this.setState({
            activeTabKey: route,
            isEditMode: false,
        });

        const newRoute = route.replace(ReplaceStrings.MATCH_ID, matchId);

        history.push(newRoute);
    };

    public render(): React.ReactElement {
        const { userProfile } = this.props;
        const { activeTabKey, matchDetails, isEditMode, loading, isUserAllowedToCreateOfficial, selectedMatchOfficial, drawerState } = this.state;

        return (
            <Spin spinning={loading}>
                <PageHeader
                    title={
                        <PageHeaderTitle
                            headerTitle={
                                matchDetails
                                    ? `${matchDetails.homeTeamDisplayName} - ${matchDetails.awayTeamDisplayName}`
                                    : ''
                            }
                            tableName="match"
                        />
                    }
                    style={{ padding: '16px 0px' }}
                />
                <div className="tab-padding">
                    <div
                        style={{
                            overflow: 'hidden',
                            width: '100%',
                            transition: 'width .3s cubic-bezier',
                        }}
                    >
                        <Tabs
                            style={{ width: '100%' }}
                            activeKey={activeTabKey}
                            onTabClick={(key: string): void => this.handleTabClick(key)}
                        >
                            <TabPane tab="Details" key={Routes.ROUTE_MATCHES_READ}>
                                <div>
                                    {matchDetails && (
                                        <MatchDetails
                                            userProfile={userProfile}
                                            isInitialEdit={isEditMode}
                                            matchFromProps={matchDetails}
                                            refreshAfterSave={this.getMatchDetails}
                                        />
                                    )}
                                </div>
                            </TabPane>
                            <TabPane tab="Lineups" key={Routes.ROUTE_MATCH_LINEUPS}>
                                <div>
                                    {matchDetails && (
                                        <MatchLineups
                                            userProfile={userProfile}
                                            isInitialEdit={isEditMode}
                                            matchFromProps={matchDetails}
                                            refreshAfterSave={this.getMatchDetails}
                                        />
                                    )}
                                </div>
                            </TabPane>
                            <TabPane tab="Officials" key={Routes.ROUTE_MATCH_OFFICIALS}>
                                <div>
                                    <PageHeader
                                        title=""
                                        extra={
                                            isUserAllowedToCreateOfficial
                                                ? [
                                                    <Button
                                                        key="1"
                                                        type="primary"
                                                        style={{
                                                            zIndex: 10,
                                                            float: 'right',
                                                        }}
                                                        onClick={(): void =>
                                                            this.handleDrawerMode(
                                                                DrawerState.Edit,
                                                                'officials'
                                                            )
                                                        }
                                                    >
                                                        <PlusOutlined className="button-icon-display" />
                                                        Add official
                                                    </Button>,
                                                ]
                                                : []
                                        }
                                        style={{ padding: '16px 0px' }}
                                    />
                                    {matchDetails &&
                                        matchDetails.officials &&
                                        matchDetails.officials.length > 0 ? (
                                        <OfficialTable
                                            userProfile={userProfile}
                                            officialsFromProps={
                                                groupMatchOfficialsByOfficialId(
                                                    matchDetails.officials
                                                ) as OfficialVmWithRoles[]
                                            }
                                            wrappedComponentRef={this.officialTableRef}
                                            shouldDisplayRolesColumn
                                            onDelete={this.handleMatchOfficialDelete}
                                            matchId={matchDetails.id}
                                        />
                                    ) : (
                                        <div>There are no officials in this federation.</div>
                                    )}
                                </div>
                            </TabPane>
                        </Tabs>
                    </div>
                    <Drawer
                        title={
                            selectedMatchOfficial
                                ? 'Edit official'
                                : 'Add official'
                        }
                        visible={!!(drawerState && matchDetails) && activeTabKey === Routes.ROUTE_MATCH_OFFICIALS}
                        onClose={(): void =>
                            this.handleDrawerMode(
                                DrawerState.Closed,
                                'officials'
                            )
                        }
                        width={DrawerWidth}
                        destroyOnClose
                    >
                        {activeTabKey === Routes.ROUTE_MATCH_OFFICIALS &&
                            matchDetails?.id && matchDetails?.federationId &&
                            !loading && (
                                <MatchOfficialForm
                                    handleClose={(): void => {
                                        this.handleDrawerMode(DrawerState.Closed, 'officials');
                                        this.setState({
                                            selectedMatchOfficial: undefined,
                                        });
                                    }}
                                    refreshAfterSave={(): Promise<void> =>
                                        this.getMatchDetails('officials')
                                    }
                                    existingOfficials={matchDetails?.officials || []}
                                    matchId={matchDetails.id}
                                    federationId={matchDetails.federationId}
                                    matchOfficial={selectedMatchOfficial}
                                />
                            )}
                    </Drawer>
                </div>
            </Spin>
        );
    }
}

export default MatchPage;
