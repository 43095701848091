import { RouteComponentProps, withRouter } from 'react-router-dom';
import { CompetitionDetailVm } from '../../../../utils/api';
import { RouteParams } from '../../../../core/models/RouteHelper';
import CompetitionDetails from './CompetitionDetails';
import { Profile } from 'oidc-client';

interface OwnProps {
    userProfile: Profile;
    isInitialEdit: boolean;
    competition: CompetitionDetailVm;
    refreshAfterSave: () => Promise<void>;
}

export type Props = OwnProps & RouteComponentProps<RouteParams>;

export default withRouter(CompetitionDetails);
