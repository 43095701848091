import { Col, Drawer, Form, Input, Row } from 'antd';
import React from 'react';
import DetailsButtons from '../../../../components/details-buttons';
import { DrawerWidth } from '../../../../config/dimensions';
import Routes from '../../../../config/routes';
import ReplaceStrings from '../../../../config/replaceStrings';
import { DrawerState, ModuleName } from '../../../../core/models/enum';
import { formItemLayout724 } from '../../../../helpers/FormLayoutHelper';
import { getNewRoute } from '../../../../helpers/RoutingHelper';
import { handleDeleteHelper } from '../../../../helpers/SubmitHelper';
import { PlayersClient } from '../../../../utils/api';
import PlayerForm from '../../player-form';
import { Props } from './index';

interface State {
    drawerState: DrawerState;
    loading: boolean;
}

class PlayerDetails extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            drawerState: props.isInitialEdit ? DrawerState.Edit : DrawerState.Closed,
            loading: true,
        };
    }

    private handleDrawerMode = (drawerState: DrawerState, id?: string): void => {
        const { history } = this.props;

        this.setState({
            drawerState,
        });

        history.push(
            getNewRoute(
                Routes.ROUTE_PLAYERS,
                Routes.ROUTE_PLAYERS_READ,
                Routes.ROUTE_PLAYERS_EDIT,
                drawerState,
                ReplaceStrings.PLAYER_ID,
                id
            )
        );
    };

    private openForm = (): void => {
        const { player } = this.props;
        this.handleDrawerMode(DrawerState.Edit, player.id.toString());
    };

    private handleDelete = (): void => {
        const { history } = this.props;

        history.push(Routes.ROUTE_PLAYERS);
    };

    public render(): React.ReactElement {
        const { drawerState } = this.state;
        const { userProfile, player, refreshAfterSave } = this.props;

        return (
            <div>
                <DetailsButtons
                    userProfile={userProfile}
                    onDeleteAction={(): Promise<void> =>
                        handleDeleteHelper(
                            new PlayersClient(),
                            'Player successfully deleted.',
                            this.handleDelete,
                            player.id
                        )
                    }
                    onEditAction={this.openForm}
                    customMessage="Are you sure you want to delete this player?"
                    moduleName={ModuleName.Players}
                    entityId={player.id}
                />
                <Row className="details-height">
                    <Col span={12}>
                        <Form {...formItemLayout724}>
                            <Form.Item label="Dominant hand">
                                <Input readOnly value={player.dominantHand} />
                            </Form.Item>
                        </Form>
                        <Form {...formItemLayout724}>
                            <Form.Item label="Weight (kg)">
                                <Input readOnly value={player.weight} />
                            </Form.Item>
                        </Form>
                        <Form {...formItemLayout724}>
                            <Form.Item label="Height (cm)">
                                <Input readOnly value={player.height} />
                            </Form.Item>
                        </Form>
                        <Form {...formItemLayout724}>
                            <Form.Item label="Primary position">
                                <Input
                                    readOnly
                                    value={
                                        player.primaryPosition
                                            ? player.primaryPosition.replace('_', ' ')
                                            : ''
                                    }
                                />
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
                <Drawer
                    title="Edit player"
                    visible={drawerState === DrawerState.Edit}
                    onClose={(): void =>
                        this.handleDrawerMode(DrawerState.Read, player.id.toString())
                    }
                    width={DrawerWidth}
                    destroyOnClose
                >
                    <PlayerForm
                        handleClose={this.handleDrawerMode}
                        refreshAfterSave={refreshAfterSave}
                        player={player}
                    />
                </Drawer>
            </div>
        );
    }
}

export default PlayerDetails;
