import { DatePicker, Form, Input, InputNumber } from 'antd';
import moment from 'moment';
import React from 'react';
import AutocompleteDropdown from '../../../components/autocomplete-dropdown';
import DrawerButtons from '../../../components/drawer-buttons/index';
import { DropdownOption } from '../../../core/models/DropdownOption';
import { DrawerState } from '../../../core/models/enum';
import {
    confirmDropdownHelper,
    filterDropdownOptions,
    getCitiesHelper,
    getCountriesHelper,
} from '../../../helpers/AutocompleteDropdownHelper';
import {
    getCompetitionsToDropdownOptions,
    getMatchSegmentsToDropdownOptions,
    getMatchTeamsToDropdownOptions,
} from '../../../helpers/FetchAndTransformHelpers';
import { formItemLayout724 } from '../../../helpers/FormLayoutHelper';
import { success } from '../../../helpers/NotificationHelper';
import { createHelper, updateHelper } from '../../../helpers/SubmitHelper';
import { customArrayValidationHelper } from '../../../helpers/ValidationHelper';
import { MatchesClient, MatchStatusEnum } from '../../../utils/api';
import { Props } from './index';

interface State {
    isSaving: boolean;
    countries: DropdownOption[];
    cities: DropdownOption[];
    teams: DropdownOption[];
    competitions: DropdownOption[];
    matchSegments: DropdownOption[];
    selectedCountry?: DropdownOption[];
    selectedCity?: DropdownOption[];
    selectedHomeTeam?: DropdownOption[];
    selectedAwayTeam?: DropdownOption[];
    selectedCompetition?: DropdownOption[];
    selectedMatchSegment?: DropdownOption[];
}

class MatchForm extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            isSaving: false,
            countries: [],
            cities: [],
            teams: [],
            competitions: [],
            matchSegments: [],
        };
    }

    public componentDidMount = (): void => {
        const { matchFromProps } = this.props;

        this.getCountries();
        if (!(matchFromProps && matchFromProps.countryId)) {
            this.getCities();
        }
        this.getTeams();
        this.getCompetitions();
        this.getMatchSegments();
    };

    private getCountries = async (): Promise<void> => {
        const { matchFromProps } = this.props;
        const countryId = matchFromProps && matchFromProps.countryId;

        const countryHelper = await getCountriesHelper(countryId);

        if (countryId) {
            this.getCities(countryId);
        }

        this.setState({
            countries: countryHelper.entities,
            selectedCountry: countryHelper.selectedEntity,
        });
    };

    private getCities = async (countryId?: number): Promise<void> => {
        const { matchFromProps } = this.props;
        const cityId = matchFromProps && matchFromProps.cityId;

        const cityHelper = await getCitiesHelper(countryId, cityId);

        this.setState({
            cities: cityHelper.entities,
            selectedCity: cityHelper.selectedEntity,
        });
    };

    private getTeams = async (): Promise<void> => {
        const { matchFromProps, initialValues } = this.props;

        const results = await getMatchTeamsToDropdownOptions(
            matchFromProps && matchFromProps.homeTeamId,
            matchFromProps && matchFromProps.awayTeamId,
            initialValues && initialValues.teams
        );

        this.setState({
            teams: results.entities,
            selectedHomeTeam: results.selectedEntity,
            selectedAwayTeam: results.selectedSecondEntity,
        });
    };

    private getCompetitions = async (): Promise<void> => {
        const { matchFromProps, initialValues } = this.props;
        const competitionId = matchFromProps && matchFromProps.competitionId;
        const preSelectedCompetitionId = initialValues && initialValues.competitionId;

        const results = await getCompetitionsToDropdownOptions(
            competitionId || preSelectedCompetitionId
        );

        this.setState({
            competitions: results.entities,
            selectedCompetition: results.selectedEntity,
        });
    };

    private getMatchSegments = async (): Promise<void> => {
        const { matchFromProps } = this.props;
        const results = await getMatchSegmentsToDropdownOptions(
            matchFromProps && matchFromProps.matchSegmentId
        );

        this.setState({
            matchSegments: results.entities,
            selectedMatchSegment: results.selectedEntity,
        });
    };

    private onSubmit = (values: any): void => {
        const {
            selectedCountry,
            selectedCity,
            selectedHomeTeam,
            selectedAwayTeam,
            selectedCompetition,
            selectedMatchSegment,
        } = this.state;
        const { matchFromProps } = this.props;
        const matchClient = new MatchesClient();

        this.setState({
            isSaving: true,
        });

        const request = {
            ...values,
            id: matchFromProps ? matchFromProps.id : undefined,
            homeTeamGoalsQ1: matchFromProps?.homeTeamGoalsQ1,
            homeTeamGoalsQ2: matchFromProps?.homeTeamGoalsQ2,
            homeTeamGoalsQ3: matchFromProps?.homeTeamGoalsQ3,
            homeTeamGoalsQ4: matchFromProps?.homeTeamGoalsQ4,
            homeTeamGoalsOT: matchFromProps?.homeTeamGoalsOT,
            awayTeamGoalsQ1: matchFromProps?.awayTeamGoalsQ1,
            awayTeamGoalsQ2: matchFromProps?.awayTeamGoalsQ2,
            awayTeamGoalsQ3: matchFromProps?.awayTeamGoalsQ3,
            awayTeamGoalsQ4: matchFromProps?.awayTeamGoalsQ4,
            awayTeamGoalsOT: matchFromProps?.awayTeamGoalsOT,
            statusId: matchFromProps ? matchFromProps.statusId : MatchStatusEnum.Not_Started,
            countryId:
                selectedCountry && selectedCountry.length > 0
                    ? parseInt(selectedCountry[0].id, 10)
                    : undefined,
            cityId:
                selectedCity && selectedCity.length > 0
                    ? parseInt(selectedCity[0].id, 10)
                    : undefined,
            homeTeamId:
                selectedHomeTeam && selectedHomeTeam.length > 0
                    ? parseInt(selectedHomeTeam[0].id, 10)
                    : undefined,
            awayTeamId:
                selectedAwayTeam && selectedAwayTeam.length > 0
                    ? parseInt(selectedAwayTeam[0].id, 10)
                    : undefined,
            competitionId:
                selectedCompetition && selectedCompetition.length > 0
                    ? parseInt(selectedCompetition[0].id, 10)
                    : undefined,
            matchSegmentId:
                selectedMatchSegment && selectedMatchSegment.length > 0
                    ? parseInt(selectedMatchSegment[0].id, 10)
                    : undefined,
        };

        if (request.id) {
            updateHelper(matchClient, request, this.handleSuccessfullySaving);
        } else {
            createHelper(matchClient, request, this.handleSuccessfullySaving);
        }

        this.setState({
            isSaving: false,
        });
    };

    private handleSuccessfullySaving = (): void => {
        const { matchFromProps, handleClose, refreshAfterSave } = this.props;

        success('Match successfully saved.');
        refreshAfterSave();
        handleClose(
            matchFromProps ? DrawerState.Read : DrawerState.Closed,
            matchFromProps ? matchFromProps.id.toString() : undefined
        );
    };

    private confirmCountry = (option: DropdownOption[]): void => {
        const selectedCountry = confirmDropdownHelper(option);
        this.setState({
            selectedCountry,
            selectedCity: undefined,
        });
        if (selectedCountry) {
            this.getCities(parseInt(selectedCountry[0].id, 10));
        }
    };

    public render(): React.ReactElement {
        const {
            isSaving,
            selectedCountry,
            selectedCity,
            selectedHomeTeam,
            selectedAwayTeam,
            selectedCompetition,
            selectedMatchSegment,
            competitions,
            teams,
            countries,
            cities,
            matchSegments,
        } = this.state;
        const { handleClose, matchFromProps, initialValues } = this.props;

        return (
            <Form onFinish={this.onSubmit} {...formItemLayout724}>
                <DrawerButtons
                    isSaving={isSaving}
                    onCancelAction={(): void =>
                        handleClose(
                            matchFromProps ? DrawerState.Read : DrawerState.Closed,
                            matchFromProps ? matchFromProps.id.toString() : undefined
                        )
                    }
                />
                <div className="drawer-content">
                    <Form.Item
                        name="homeTeamId"
                        label="Home team"
                        rules={[
                            {
                                required: true,
                                validator: (_rule: any, value: any, callback: any): void =>
                                    customArrayValidationHelper(
                                        _rule,
                                        value,
                                        callback,
                                        'Home team field is required!',
                                        selectedHomeTeam
                                    ),
                            },
                        ]}
                    >
                        <AutocompleteDropdown
                            placeholder="Choose home team"
                            getOptionsFrontend={(value: string): DropdownOption[] =>
                                filterDropdownOptions(
                                    value,
                                    selectedAwayTeam && selectedAwayTeam.length > 0
                                        ? teams.filter(
                                            (t): boolean => t.id !== selectedAwayTeam[0].id
                                        )
                                        : teams
                                )
                            }
                            initialValues={selectedHomeTeam}
                            confirmDirty={(options: DropdownOption[]): void =>
                                this.setState({
                                    selectedHomeTeam: confirmDropdownHelper(options),
                                })
                            }
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                    <Form.Item
                        name="awayTeamId"
                        label="Away team"
                        rules={[
                            {
                                required: true,
                                validator: (_rule: any, value: any, callback: any): void =>
                                    customArrayValidationHelper(
                                        _rule,
                                        value,
                                        callback,
                                        'Away team field is required!',
                                        selectedAwayTeam
                                    ),
                            },
                        ]}
                    >
                        <AutocompleteDropdown
                            placeholder="Choose away team"
                            getOptionsFrontend={(value: string): DropdownOption[] =>
                                filterDropdownOptions(
                                    value,
                                    selectedHomeTeam && selectedHomeTeam.length > 0
                                        ? teams.filter(
                                            (t): boolean => t.id !== selectedHomeTeam[0].id
                                        )
                                        : teams
                                )
                            }
                            initialValues={selectedAwayTeam}
                            confirmDirty={(options: DropdownOption[]): void =>
                                this.setState({
                                    selectedAwayTeam: confirmDropdownHelper(options),
                                })
                            }
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                    <Form.Item
                        name="competitionId"
                        label="Competition"
                        rules={[
                            {
                                required: true,
                                validator: (_rule: any, value: any, callback: any): void =>
                                    customArrayValidationHelper(
                                        _rule,
                                        value,
                                        callback,
                                        'Competition field is required!',
                                        selectedCompetition
                                    ),
                            },
                        ]}
                    >
                        <AutocompleteDropdown
                            placeholder="Choose competition"
                            getOptionsFrontend={(value: string): DropdownOption[] =>
                                filterDropdownOptions(value, competitions)
                            }
                            initialValues={selectedCompetition}
                            confirmDirty={(options: DropdownOption[]): void =>
                                this.setState({
                                    selectedCompetition: confirmDropdownHelper(options),
                                })
                            }
                            style={{ width: '100%' }}
                            disabled={!!(initialValues && initialValues.competitionId)}
                            isAllowedToClear
                        />
                    </Form.Item>
                    <Form.Item
                        name="description"
                        label="Description"
                        initialValue={matchFromProps ? matchFromProps.description : undefined}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="venue"
                        label="Venue"
                        initialValue={matchFromProps ? matchFromProps.venue : undefined}
                        rules={[{ max: 200, message: 'Too long input!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="round"
                        label="Round"
                        initialValue={matchFromProps ? matchFromProps.round : undefined}
                        rules={[{ max: 200, message: 'Too long input!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="number"
                        label="Game No."
                        initialValue={matchFromProps ? matchFromProps.number : undefined}
                        rules={[{ max: 200, message: 'Too long input!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item name="country" label="Country">
                        <AutocompleteDropdown
                            placeholder="Choose country"
                            getOptionsFrontend={(value: string): DropdownOption[] =>
                                filterDropdownOptions(value, countries)
                            }
                            initialValues={selectedCountry}
                            confirmDirty={this.confirmCountry}
                            style={{ width: '100%' }}
                            isAllowedToClear
                        />
                    </Form.Item>
                    <Form.Item name="city" label="City">
                        <AutocompleteDropdown
                            placeholder="Choose city"
                            getOptionsFrontend={(value: string): DropdownOption[] =>
                                filterDropdownOptions(value, cities)
                            }
                            initialValues={selectedCity}
                            confirmDirty={(options: DropdownOption[]): void =>
                                this.setState({
                                    selectedCity: confirmDropdownHelper(options),
                                })
                            }
                            style={{ width: '100%' }}
                            isAllowedToClear
                        />
                    </Form.Item>
                    <Form.Item
                        name="startDate"
                        label="Start"
                        initialValue={
                            matchFromProps?.startDate ? moment(matchFromProps.startDate) : null
                        }
                        rules={[
                            {
                                required: true,
                                message: 'Start is required!',
                            },
                        ]}
                    >
                        <DatePicker format="DD.MM.YYYY. HH:mm" style={{ width: '100%' }} showTime />
                    </Form.Item>
                    <Form.Item
                        name="endDate"
                        label="End"
                        initialValue={
                            matchFromProps?.endDate ? moment(matchFromProps.endDate) : null
                        }
                    >
                        <DatePicker format="DD.MM.YYYY. HH:mm" style={{ width: '100%' }} showTime />
                    </Form.Item>
                    <Form.Item name="matchSegmentId" label="Match segment">
                        <AutocompleteDropdown
                            placeholder="Choose match segment"
                            getOptionsFrontend={(value: string): DropdownOption[] =>
                                filterDropdownOptions(value, matchSegments)
                            }
                            initialValues={selectedMatchSegment}
                            confirmDirty={(options: DropdownOption[]): void =>
                                this.setState({
                                    selectedMatchSegment: confirmDropdownHelper(options),
                                })
                            }
                            style={{ width: '100%' }}
                            isAllowedToClear
                        />
                    </Form.Item>
                    <Form.Item
                        name="matchSegmentLength"
                        label="Segment length (min)"
                        initialValue={matchFromProps ? matchFromProps.matchSegmentLength : 8}
                        rules={[
                            {
                                required: true,
                                message: 'Match segment length field is required!',
                            },
                        ]}
                        className="input-number-wrapper"
                    >
                        <InputNumber />
                    </Form.Item>
                </div>
            </Form>
        );
    }
}

export default MatchForm;
