import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Drawer, Form, Input } from 'antd';
import React from 'react';
import AutocompleteDropdown from '../../../components/autocomplete-dropdown';
import DrawerButtons from '../../../components/drawer-buttons';
import { DrawerWidth } from '../../../config/dimensions';
import { DropdownOption } from '../../../core/models/DropdownOption';
import { DrawerState } from '../../../core/models/enum';
import {
    confirmDropdownHelper,
    filterDropdownOptions,
} from '../../../helpers/AutocompleteDropdownHelper';
import { formItemLayout724 } from '../../../helpers/FormLayoutHelper';
import { errorWithUserMessage, success } from '../../../helpers/NotificationHelper';
import { customArrayValidationHelper } from '../../../helpers/ValidationHelper';
import { ApiException, ConfirmMatchLogCommand, MatchesClient, MatchOfficialVm } from '../../../utils/api';
import { Props } from './index';

interface State {
    drawerState: DrawerState;
    selectedOfficial?: DropdownOption[];
}

class MatchConfirmLog extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            drawerState: DrawerState.Closed,
        };
    }

    private onSave = async (values: any): Promise<void> => {
        const { selectedOfficial } = this.state;
        const { updateMatchDetailsAfterSave } = this.props;

        if (!(selectedOfficial && selectedOfficial.length > 0)) return;

        try {
            const matchClient = new MatchesClient();

            const request: ConfirmMatchLogCommand = new ConfirmMatchLogCommand({
                matchOfficialId: parseInt(selectedOfficial[0].id, 10),
                pin: values.pin,
            });

            const result = await matchClient.confirmMatchLog(request.matchOfficialId, request);

            if (result) {
                success('Match log successfully confirmed!');
                updateMatchDetailsAfterSave();
                this.handleDrawerMode(DrawerState.Closed);
            }
        } catch (error) {
            if (error instanceof ApiException) {
                errorWithUserMessage(error.response);
            } else {
                errorWithUserMessage('Error saving data.');
            }    
        }
    };

    private handleDrawerMode = (drawerState: DrawerState): void => {
        const { selectedOfficial } = this.state;
        this.setState({
            drawerState,
            selectedOfficial: drawerState === DrawerState.Closed ? undefined : selectedOfficial,
        });
    };

    public render(): React.ReactElement {
        const { drawerState, selectedOfficial } = this.state;
        const { matchOfficials } = this.props;

        return (
            <div>
                <Drawer
                    title="Confirm match log"
                    visible={!!drawerState}
                    width={DrawerWidth}
                    onClose={(): void => this.handleDrawerMode(DrawerState.Closed)}
                    destroyOnClose
                >
                    <Form onFinish={this.onSave} {...formItemLayout724} autoComplete="off">
                        <DrawerButtons
                            onCancelAction={(): void => this.handleDrawerMode(DrawerState.Closed)}
                        />
                        <div className="drawer-content">
                            <Form.Item
                                name="matchOfficialId"
                                label="Match official"
                                rules={[
                                    {
                                        required: true,
                                        validator: (_rule: any, value: any, callback: any): void =>
                                            customArrayValidationHelper(
                                                _rule,
                                                value,
                                                callback,
                                                'Official field is required!',
                                                selectedOfficial
                                            ),
                                    },
                                ]}
                            >
                                <AutocompleteDropdown
                                    placeholder="Choose match official"
                                    getOptionsFrontend={(value: string): DropdownOption[] =>
                                        filterDropdownOptions(
                                            value,
                                            matchOfficials.map(
                                                (o: MatchOfficialVm): DropdownOption => ({
                                                    id: o.id?.toString() || '',
                                                    name: `${o.official.name} ${o.official.surname}`,
                                                    disabled: o.confirmed,
                                                })
                                            )
                                        )
                                    }
                                    initialValues={selectedOfficial}
                                    confirmDirty={(options: DropdownOption[]): void =>
                                        this.setState({
                                            selectedOfficial: confirmDropdownHelper(options),
                                        })
                                    }
                                    style={{ width: '100%' }}
                                    isAllowedToClear
                                />
                            </Form.Item>
                            <Form.Item
                                name="pin"
                                label="PIN"
                                rules={[
                                    {
                                        pattern: /^[0-9]+$/,
                                        message: 'Only numbers are allowed!',
                                    },
                                    { required: true, message: 'PIN is required!' },
                                    { min: 4, message: 'Too short PIN!' },
                                    { max: 4, message: 'Too long PIN!' },
                                ]}
                            >
                                <Input.Password
                                    placeholder="****"
                                    iconRender={(visible) =>
                                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                                    }
                                    autoComplete="new-password"
                                />
                            </Form.Item>
                        </div>
                    </Form>
                </Drawer>
            </div>
        );
    }
}

export default MatchConfirmLog;
