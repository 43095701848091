const strings = {
    FEDERATION_ID: ':federationId',
    CLUB_ID: ':clubId',
    COMPETITION_ID: ':competitionId',
    TEAM_ID: ':teamId',
    MATCH_ID: ':matchId',
    OFFICIAL_ID: ':officialId',
    OFFICIAL_ROLE_ID: ':officialRoleId',
    COACH_ID: ':coachId',
    PLAYER_ID: ':playerId',
    USER_ID: ':userId',
    CITY_ID: ':cityId'
};

export default strings;
