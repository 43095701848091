import { Layout, Spin } from 'antd';
import moment from 'moment';
import React from 'react';
import Icon from '../../components/icon/Icon';
import { ClockState } from '../../core/models/enum';
import {
    compareEventsByTime,
    filterPeriodEvents,
    getTeamId,
    refreshMatchGoals,
} from '../../helpers/EventHelper';
import {
    errorWithUserMessage,
    popupConfirm,
    popupConfirmWithCallback,
    success,
} from '../../helpers/NotificationHelper';
import { updateHelper } from '../../helpers/SubmitHelper';
import {
    ApiException,
    CoachesClient,
    CoachVm,
    CreateEventCommand,
    CreateMatchPlayerCommandOfMatchPlayer,
    EventPeriodEnum,
    EventsClient,
    EventTypeEnum,
    EventVm,
    ExclusionRequest,
    ExclusionTypeEnum,
    MatchCoachVm,
    MatchDetailVm,
    MatchesClient,
    MatchStatusEnum,
    OfficialsClient,
    OfficialVm,
    UpdateEventCommand,
} from '../../utils/api';
import { Props } from './index';
import MatchActionBar from './match-action-bar';
import MatchClock from './match-clock';
import Card from './match-event-form/card';
import CoachExclusion from './match-event-form/coach-exclusion';
import Exclusion from './match-event-form/exclusion';
import Shot from './match-event-form/shot';
import SwimOff from './match-event-form/swim-off';
import Turnover from './match-event-form/turnover';
import MatchEventLog from './match-event-log';
import MatchLineup from './match-lineup';

const { Content } = Layout;

const INITIAL_TIME = 8 * 60;
const CLOCK_STOPPING_EVENTS = [EventTypeEnum.Timeout, EventTypeEnum.Challenge];

export interface State {
    match?: MatchDetailVm;
    coaches: CoachVm[];
    officials: OfficialVm[];
    events: EventVm[];
    selectedHomeKeeper: number;
    selectedAwayKeeper: number;
    currentPeriod: EventPeriodEnum;
    clockState: ClockState;
    currentTime: number;
    eventPeriod: EventPeriodEnum;
    eventTime: number;
    event: EventVm | CreateEventCommand | UpdateEventCommand | null;
    isEventValid: boolean;
    isLoading: boolean;
}

class MatchReport extends React.PureComponent<Props, State> {
    timer!: NodeJS.Timer | null;

    constructor(props: Props) {
        super(props);

        this.state = {
            coaches: [],
            officials: [],
            events: [],
            selectedAwayKeeper: 1,
            selectedHomeKeeper: 1,
            currentPeriod: EventPeriodEnum.First_Period,
            clockState: ClockState.Stopped,
            currentTime: INITIAL_TIME,
            eventPeriod: EventPeriodEnum.First_Period,
            eventTime: INITIAL_TIME,
            event: null,
            isEventValid: false,
            isLoading: false,
        };

        this.timer = null;
    }

    public componentDidMount = () => {
        this.getMatch();
        this.getCoaches();
        this.getOfficials();
    };

    private getMatch = async () => {
        const {
            match: {
                params: { matchId },
            },
        } = this.props;

        if (!matchId) return;

        this.setState({
            isLoading: true,
        });

        const matchClient = new MatchesClient();
        const eventClient = new EventsClient();

        const match = await matchClient.getById(parseInt(matchId, 10));
        const events = await eventClient.getByMatchId(parseInt(matchId, 10));

        this.setState({
            match,
            events,
        });

        if (!events.length) {
            this.initSwimOff();
        } else {
            this.initClock();
        }

        this.setState({
            isLoading: false,
        });
    };

    private getCoaches = async () => {
        const coachClient = new CoachesClient();
        const coaches = await coachClient.getAll();

        this.setState({
            coaches,
        });
    };

    private getOfficials = async () => {
        const officialClient = new OfficialsClient();
        const officials = await officialClient.getAll();

        this.setState({
            officials,
        });
    };

    private initSwimOff = () => {
        const { match } = this.state;

        if (!match) return;

        popupConfirm('Please confirm selected active goalkeepers');

        this.initEvent(EventTypeEnum.Swim_Off);
    };

    private initClock = () => {
        const { events } = this.state;

        const sortedEvents = events.sort(compareEventsByTime);
        let newestEvent = {} as EventVm;

        const q1Events = filterPeriodEvents(sortedEvents, EventPeriodEnum.First_Period);
        const q2Events = filterPeriodEvents(sortedEvents, EventPeriodEnum.Second_Period);
        const q3Events = filterPeriodEvents(sortedEvents, EventPeriodEnum.Third_Period);
        const q4Events = filterPeriodEvents(sortedEvents, EventPeriodEnum.Fourth_Period);
        const psoEvents = filterPeriodEvents(sortedEvents, EventPeriodEnum.Overtime);

        if (psoEvents.length > 0) {
            [newestEvent] = psoEvents;
        } else if (q4Events.length > 0) {
            [newestEvent] = q4Events;
        } else if (q3Events.length > 0) {
            [newestEvent] = q3Events;
        } else if (q2Events.length > 0) {
            [newestEvent] = q2Events;
        } else if (q1Events.length > 0) {
            [newestEvent] = q1Events;
        }

        if (!this.timer || (this.timer && this.isClockStoppingEvent(newestEvent))) {
            this.setState({
                currentPeriod: newestEvent.periodId,
                currentTime: newestEvent.minute * 60 + newestEvent.seconds,
                eventPeriod: newestEvent.periodId,
                eventTime: newestEvent.minute * 60 + newestEvent.seconds,
            });
        }
    };

    private isClockStoppingEvent = (event: EventVm): boolean => {
        return (
            !!event.timeout ||
            !!event.challenge ||
            event.shot?.isGoal ||
            event.exclusion?.typeId === ExclusionTypeEnum.Brutality ||
            event.exclusion?.typeId === ExclusionTypeEnum.Penalty
        );
    };

    private startClock = () => {
        if (!this.timer) {
            this.timer = setInterval(() => this.tickClock(), 1000);
        }

        this.setState(
            (prevState: State): State => ({
                ...prevState,
                clockState: ClockState.Running,
                eventPeriod:
                    prevState.clockState === ClockState.Paused
                        ? prevState.currentPeriod
                        : prevState.eventPeriod,
                currentPeriod:
                    prevState.clockState === ClockState.Stopped
                        ? prevState.eventPeriod
                        : prevState.currentPeriod,
                eventTime:
                    prevState.clockState === ClockState.Paused
                        ? prevState.currentTime
                        : prevState.eventTime,
                currentTime:
                    prevState.clockState === ClockState.Stopped
                        ? prevState.eventTime
                        : prevState.currentTime,
            })
        );
    };

    private stopClock = () => {
        if (!this.timer) return;
        clearInterval(this.timer);
        this.timer = null;
        this.setState(
            (prevState: State): State => ({
                ...prevState,
                clockState: ClockState.Stopped,
            })
        );
    };

    private pauseClock = () => {
        const { clockState } = this.state;

        if (clockState === ClockState.Running) {
            this.setState(
                (prevState: State): State => ({
                    ...prevState,
                    clockState: ClockState.Paused,
                })
            );
        }
    };

    private resetClock = () => {
        this.stopClock();

        this.setState({
            currentTime: INITIAL_TIME,
            eventTime: INITIAL_TIME,
        });
    };

    private setPeriod = (period: EventPeriodEnum) => {
        const { events } = this.state;
        if (
            events.filter(
                (x: EventVm): boolean =>
                    x.typeId === EventTypeEnum.Swim_Off && x.periodId === period
            ).length === 0
        ) {
            this.initSwimOff();
        }
        this.setState((prevState: State): State => {
            if (prevState.clockState !== ClockState.Paused) {
                this.resetClock();
            }

            return {
                ...prevState,
                currentPeriod:
                    prevState.clockState === ClockState.Paused ? prevState.currentPeriod : period,
                eventPeriod: period,
            };
        });
    };

    private modifyClock = (amount: number) => {
        const { clockState } = this.state;
        if (clockState === ClockState.Running) return;

        this.setState((prevState: State): State => {
            const newTime = prevState.eventTime + amount;

            return {
                ...prevState,
                eventTime: newTime < 0 ? prevState.eventTime : newTime,
                currentTime:
                    prevState.clockState === ClockState.Paused || newTime < 0
                        ? prevState.currentTime
                        : newTime,
            };
        });
    };

    private tickClock = () => {
        this.setState((prevState: State): State => {
            const newTime = prevState.currentTime - 1;
            if (prevState.currentTime === 0) {
                this.stopClock();
            }

            return {
                ...prevState,
                currentTime: newTime < 0 ? prevState.currentTime : newTime,
                eventTime:
                    prevState.clockState === ClockState.Paused || newTime < 0
                        ? prevState.eventTime
                        : newTime,
            };
        });
    };

    private initEvent = (type: EventTypeEnum, eventTypeValues?: any) => {
        const { clockState, match, eventPeriod, eventTime } = this.state;

        if (!match) return;

        if (CLOCK_STOPPING_EVENTS.includes(type)) {
            this.stopClock();
        } else if (clockState === ClockState.Running) {
            this.pauseClock();
        }

        const minute = Math.floor(eventTime / 60);

        const newEvent: CreateEventCommand = new CreateEventCommand({
            matchId: match.id,
            typeId: type,
            periodId: eventPeriod,
            minute,
            seconds: eventTime - minute * 60,
            turnover: type === EventTypeEnum.Turnover ? eventTypeValues : undefined,
            shot: type === EventTypeEnum.Shot ? eventTypeValues : undefined,
            timeout: type === EventTypeEnum.Timeout ? eventTypeValues : undefined,
            challenge: type === EventTypeEnum.Challenge ? eventTypeValues : undefined,
            swimoff: type === EventTypeEnum.Swim_Off ? eventTypeValues : undefined,
            card: type === EventTypeEnum.Card ? eventTypeValues : undefined,
            exclusion: type === EventTypeEnum.Exclusion ? eventTypeValues : undefined,
        });

        this.setEventValidity(newEvent);

        this.setState({
            event: newEvent,
        });
    };

    public handleKeeperSelect = (cap: number, teamId: number) => {
        const { match } = this.state;

        if (teamId === match?.homeTeamId) {
            this.setState({
                selectedHomeKeeper: cap,
            });
        } else if (teamId === match?.awayTeamId) {
            this.setState({
                selectedAwayKeeper: cap,
            });
        }
    };

    private setEventValidity = (event: CreateEventCommand) => {
        let isEventValid = false;
        switch (event.typeId) {
            case EventTypeEnum.Turnover:
                isEventValid = !!event.turnover;
                break;
            case EventTypeEnum.Timeout:
                isEventValid = !!event.timeout;
                break;
            case EventTypeEnum.Challenge:
                isEventValid = !!event.challenge;
                break;
            case EventTypeEnum.Card: {
                isEventValid = !!event.card;
                break;
            }
            case EventTypeEnum.Swim_Off: {
                isEventValid = !!event.swimoff;
                break;
            }
            case EventTypeEnum.Exclusion: {
                isEventValid = !!event.exclusion;
                break;
            }
            case EventTypeEnum.Shot: {
                isEventValid = !!event.shot;
                break;
            }
            default:
                break;
        }

        this.setState({
            isEventValid,
        });
    };

    private clearEvent = () => {
        this.setState(
            (prevState: State): State => ({
                ...prevState,
                clockState:
                    prevState.clockState === ClockState.Paused
                        ? ClockState.Running
                        : prevState.clockState,
                eventPeriod: prevState.currentPeriod,
                eventTime: prevState.currentTime,
                event: null,
                isEventValid: false,
            })
        );
    };

    public editEvent = (event: UpdateEventCommand) => {
        this.pauseClock();

        this.setEventValidity(event);

        this.setState({
            event,
            eventPeriod: event.periodId,
            eventTime: event.minute * 60 + event.seconds,
        });
    };

    private updateEvent = (event: UpdateEventCommand) => {
        const { clockState } = this.state;

        this.setEventValidity(event);

        let shouldStopClock = false;

        if (clockState !== ClockState.Stopped) {
            if (event.typeId === EventTypeEnum.Shot) {
                shouldStopClock = event.shot?.isGoal as boolean;
            }

            if (event.typeId === EventTypeEnum.Exclusion) {
                shouldStopClock =
                    event.exclusion?.typeId === ExclusionTypeEnum.Brutality ||
                    event.exclusion?.typeId === ExclusionTypeEnum.Penalty;
            }
        }

        if (shouldStopClock) {
            this.stopClock();
        }

        this.setState({
            event,
        });
    };

    private saveEvent = async () => {
        const { event, isEventValid, match, eventTime, eventPeriod } = this.state;

        if (!match || !isEventValid || !event) return;

        const mappedEvent = new EventVm({ ...(event as EventVm) });
        const minute = Math.floor(eventTime / 60);
        const newEvent: UpdateEventCommand = new UpdateEventCommand({
            ...(event as UpdateEventCommand),
            periodId: eventPeriod,
            minute,
            seconds: eventTime - minute * 60,
            id: mappedEvent.id,
        });

        if (newEvent.exclusion?.isDoubleExclusion) {
            const fouledPlayer = newEvent.exclusion?.fouledPlayerId;
            const excludedPlayer = newEvent.exclusion?.excludedPlayerId;
            const teamId = newEvent.exclusion?.teamId;
            const newTeamId = teamId === match.homeTeamId ? match.awayTeamId : match.homeTeamId;

            const replacedEvent: CreateEventCommand = new CreateEventCommand({
                ...newEvent,
                exclusion: new ExclusionRequest({
                    ...(event.exclusion as ExclusionRequest),
                    fouledPlayerId: excludedPlayer,
                    excludedPlayerId: fouledPlayer,
                    teamId: newTeamId,
                }),
            });

            await this.submitEvent(replacedEvent);
        }

        await this.submitEvent(newEvent, mappedEvent.id);

        if (event.typeId === EventTypeEnum.Swim_Off) {
            this.startClock();
        }
    };

    private submitEvent = async (event: EventVm | CreateEventCommand, eventId?: number) => {
        try {
            const client = new EventsClient();

            const newEvent = {
                matchId: event.matchId,
                typeId: event.typeId,
                periodId: event.periodId,
                minute: event.minute,
                seconds: event.seconds,
                turnover: event.turnover,
                shot: event.shot,
                timeout: event.timeout,
                challenge: event.challenge,
                swimoff: event.swimoff,
                card: event.card,
                exclusion: event.exclusion,
            } as UpdateEventCommand;

            let result;

            if (eventId) {
                result = await client.update(eventId, {
                    ...newEvent,
                    id: eventId,
                } as UpdateEventCommand);
            } else {
                result = await client.create(newEvent);
            }

            if (result) {
                this.handleEventSaved(result);
            }
        } catch (error) {
            if (error instanceof ApiException) {
                errorWithUserMessage(error.response);
            } else {
                errorWithUserMessage('Error saving data.');
            }
        }
    };

    private deleteEvent = async (event: EventVm) => {
        try {
            const result = await new EventsClient().delete(event.id);

            success('Event successfully deleted.');

            this.handleEventDeleted(result);
        } catch (error) {
            if (error instanceof ApiException) {
                errorWithUserMessage(error.response);
            } else {
                errorWithUserMessage('Error deleting data.');
            }
        }
    };

    private handleEventDeleted = async (result: EventVm) => {
        const { events, match } = this.state;

        if (!match) return;

        const newEvents = [...events];
        const index = newEvents.findIndex((e: EventVm): boolean => e.id === result.id);
        newEvents.splice(index, 1);

        this.setState({
            events: newEvents,
        });

        if (events[index].typeId === EventTypeEnum.Shot) {
            const newMatch = refreshMatchGoals(match, result);

            this.initClock();

            this.setState({
                match: newMatch,
            });
        }

        this.clearEvent();
    };

    private handleEventSaved = async (result: EventVm) => {
        const { events, match, event } = this.state;

        if (!match) return;

        let newEvents = [...events];

        const mappedEvent = new EventVm({ ...(event as EventVm) });
        const isUpdate = !!mappedEvent.id;

        if (isUpdate) {
            const index = newEvents.findIndex((ne: EventVm): boolean => ne.id === result.id);
            newEvents = [...newEvents.slice(0, index), result, ...newEvents.slice(index + 1)];
        } else {
            newEvents.push(result as EventVm);
        }

        const newMatch = { ...match, ...result.match } as MatchDetailVm;

        this.setState({
            events: newEvents,
            match: newMatch,
        });

        this.clearEvent();
    };

    private updateMatchStatus = async (matchStatus: MatchStatusEnum) => {
        const { match } = this.state;

        if (!match) return;

        const matchClient = new MatchesClient();

        const newMatch = {
            ...match,
            statusId: matchStatus,
            status: MatchStatusEnum[matchStatus],
        } as MatchDetailVm;

        updateHelper(matchClient, newMatch, () => this.setState({ match: newMatch }));
    };

    private finishMatch = () => {
        const { match } = this.state;

        if (!match) {
            return;
        }

        popupConfirmWithCallback(
            this.updateMatchStatus,
            MatchStatusEnum.Finished,
            'Are you sure you want to finish the match?'
        );
    };

    private renderEvent = (): React.ReactElement | null => {
        const { match, selectedHomeKeeper, selectedAwayKeeper, events, event } = this.state;

        const lineups = match?.players as CreateMatchPlayerCommandOfMatchPlayer[];

        const eventLog = (
            <MatchEventLog
                match={match}
                events={events}
                lineups={lineups}
                coaches={match?.coaches || []}
                editEvent={this.editEvent}
            />
        );

        if (!match || !event) {
            return eventLog;
        }

        switch (event?.typeId) {
            case EventTypeEnum.Turnover:
                return (
                    <Turnover
                        isHomeTeam={getTeamId(event) === match?.homeTeamId}
                        event={event as UpdateEventCommand}
                        lineups={lineups}
                        updateEvent={this.updateEvent}
                    />
                );
            case EventTypeEnum.Card:
                return (
                    <Card
                        isHomeTeam={getTeamId(event) === match?.homeTeamId}
                        event={event as UpdateEventCommand}
                        coaches={match?.coaches || []}
                        updateEvent={this.updateEvent}
                    />
                );
            case EventTypeEnum.Swim_Off:
                return (
                    <SwimOff
                        isHomeTeam={getTeamId(event) === match?.homeTeamId}
                        event={event as UpdateEventCommand}
                        lineups={lineups}
                        updateEvent={this.updateEvent}
                        homeTeamId={match?.homeTeamId}
                    />
                );
            case EventTypeEnum.Exclusion:
                if (event?.exclusion?.excludedCoachId) {
                    return (
                        <CoachExclusion
                            isHomeTeam={getTeamId(event) === match?.homeTeamId}
                            event={event as UpdateEventCommand}
                            coaches={match?.coaches || []}
                            updateEvent={this.updateEvent}
                        />
                    );
                }
                return (
                    <Exclusion
                        isHomeTeam={getTeamId(event) === match?.homeTeamId}
                        event={event as UpdateEventCommand}
                        lineups={lineups}
                        updateEvent={this.updateEvent}
                    />
                );
            case EventTypeEnum.Shot:
                return (
                    <Shot
                        isHomeTeam={getTeamId(event) === match?.homeTeamId}
                        event={event as UpdateEventCommand}
                        lineups={lineups}
                        selectedHomeKeeper={selectedHomeKeeper}
                        selectedAwayKeeper={selectedAwayKeeper}
                        updateEvent={this.updateEvent}
                    />
                );
            case EventTypeEnum.Timeout:
            case EventTypeEnum.Challenge:
            default:
                return eventLog;
        }
    };

    public render = (): React.ReactElement => {
        const {
            selectedHomeKeeper,
            selectedAwayKeeper,
            isLoading,
            match,
            event,
            clockState,
            currentPeriod,
            currentTime,
            eventPeriod,
            eventTime,
            isEventValid,
            events,
        } = this.state;
        const { history } = this.props;

        const lineups = (match?.players as CreateMatchPlayerCommandOfMatchPlayer[]) || [];

        return (
            <Spin tip="Loading..." spinning={isLoading} className="spinner">
                <Content className="o-wrapper">
                    <div className="o-left-screen">
                        <div className="o-left-screen__inner">
                            <div className="o-team o-team--left">
                                <MatchLineup
                                    match={match}
                                    events={
                                        events.filter(
                                            (e: EventVm): boolean =>
                                                getTeamId(e) === match?.homeTeamId
                                        ) || []
                                    }
                                    isHomeTeam
                                    lineup={lineups.filter(
                                        (l: CreateMatchPlayerCommandOfMatchPlayer): boolean =>
                                            l.teamId === match?.homeTeamId
                                    )}
                                    coaches={
                                        match?.coaches?.filter(
                                            (c: MatchCoachVm): boolean =>
                                                c.teamId === match.homeTeamId
                                        ) || []
                                    }
                                    initEvent={this.initEvent}
                                    onPlayerSelect={this.handleKeeperSelect}
                                    selectedKeeper={selectedHomeKeeper}
                                />
                            </div>
                            <div className="o-center-buttons" />
                            <div className="o-team o-team--right">
                                <MatchLineup
                                    match={match}
                                    events={
                                        events.filter(
                                            (e: EventVm): boolean =>
                                                getTeamId(e) === match?.awayTeamId
                                        ) || []
                                    }
                                    isHomeTeam={false}
                                    lineup={lineups.filter(
                                        (l: CreateMatchPlayerCommandOfMatchPlayer): boolean =>
                                            l.teamId === match?.awayTeamId
                                    )}
                                    coaches={
                                        match?.coaches?.filter(
                                            (c: MatchCoachVm): boolean =>
                                                c.teamId === match.awayTeamId
                                        ) || []
                                    }
                                    initEvent={this.initEvent}
                                    onPlayerSelect={this.handleKeeperSelect}
                                    selectedKeeper={selectedAwayKeeper}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="o-right-screen">
                        <div className="o-right-screen__inner">
                            <div className="o-top">
                                <p>
                                    <span>Start date:</span>
                                    &nbsp;
                                    <b>
                                        {match
                                            ? moment(match.startDate).format('DD.MM.YYYY. HH:mm')
                                            : ''}
                                    </b>
                                    &nbsp;&nbsp;
                                    <span>Venue:</span>
                                    &nbsp;
                                    <b>
                                        {match
                                            ? `Venue: ${match.venue || ''}${match.venue && match.city ? ', ' : ''
                                            }${match.city || ''}`
                                            : ''}
                                    </b>
                                    &nbsp;&nbsp;
                                    <span>Status:</span>
                                    &nbsp;
                                    <b>{match?.status?.replace('_', ' ')}</b>
                                </p>
                            </div>
                            <header className="o-teams">
                                <div className="c-teams__team c-teams__team--home">
                                    <p>
                                        {match?.homeTeam
                                            ? match?.homeTeam.nameUniversal || match?.homeTeam.name
                                            : ''}
                                    </p>
                                    <span
                                        onClick={() =>
                                            this.initEvent(EventTypeEnum.Challenge, {
                                                teamId: match?.homeTeamId,
                                            })
                                        }
                                        className="c-teams__to"
                                        role="button"
                                    >
                                        CH
                                    </span>
                                    <span
                                        onClick={() =>
                                            this.initEvent(EventTypeEnum.Timeout, {
                                                teamId: match?.homeTeamId,
                                            })
                                        }
                                        className="c-teams__to"
                                        role="button"
                                    >
                                        TO
                                    </span>
                                </div>
                                {match && (
                                    <span className="c-teams__result">{`${match.homeTeamGoalsTotal !== null
                                            ? match.homeTeamGoalsTotal
                                            : 0
                                        } - ${match.awayTeamGoalsTotal !== null
                                            ? match.awayTeamGoalsTotal
                                            : 0
                                        }`}</span>
                                )}
                                <div className="c-teams__team c-teams__team--away">
                                    <p>
                                        {match?.awayTeam
                                            ? match?.awayTeam.nameUniversal || match?.awayTeam.name
                                            : ''}
                                    </p>
                                    <span
                                        onClick={() =>
                                            this.initEvent(EventTypeEnum.Challenge, {
                                                teamId: match?.awayTeamId,
                                            })
                                        }
                                        className="c-teams__to"
                                        role="button"
                                    >
                                        CH
                                    </span>
                                    <span
                                        onClick={() =>
                                            this.initEvent(EventTypeEnum.Timeout, {
                                                teamId: match?.awayTeamId,
                                            })
                                        }
                                        className="c-teams__to"
                                        role="button"
                                    >
                                        TO
                                    </span>
                                </div>
                            </header>
                            <MatchClock
                                event={event}
                                clockState={clockState}
                                currentPeriod={currentPeriod}
                                currentTime={currentTime}
                                eventPeriod={eventPeriod}
                                eventTime={eventTime}
                                isEventValid={isEventValid}
                                startClock={this.startClock}
                                pauseClock={this.pauseClock}
                                stopClock={this.stopClock}
                                resetClock={this.resetClock}
                                setPeriod={this.setPeriod}
                                modifyClock={this.modifyClock}
                                saveEvent={this.saveEvent}
                            />
                            {event && (
                                <MatchActionBar
                                    isHomeTeam={getTeamId(event) === match?.homeTeamId}
                                    match={match}
                                    event={event}
                                    lineups={lineups}
                                    coaches={match?.coaches}
                                    clearEvent={this.clearEvent}
                                    deleteEvent={this.deleteEvent}
                                />
                            )}
                            {this.renderEvent()}
                        </div>
                    </div>
                    <div className="c-extended-menu">
                        <button
                            type="button"
                            className="c-btn c-btn--side c-btn--right c-btn--extended-menu-btn c-extended-menu__btn u-flex u-align-items-center u-justify-content-center"
                        >
                            <Icon name="icon-chevron-left" />
                        </button>
                        <div className="c-extended-menu__list u-flex u-flex-row u-flex-nowrap">
                            <div
                                onClick={() => history.push(`/`)}
                                className="c-extended-menu__list u-flex u-flex-row u-flex-nowrap"
                                role="button"
                            >
                                <div className="c-extended-menu__item u-flex u-flex-column u-align-items-center u-justify-content-center">
                                    <Icon name="icon-swimmer" />
                                    <span>Back to App</span>
                                </div>
                            </div>
                            <div
                                onClick={() => history.push(`/match-log/${match?.id}`)}
                                className="c-extended-menu__list u-flex u-flex-row u-flex-nowrap"
                                role="button"
                            >
                                <div className="c-extended-menu__item u-flex u-flex-column u-align-items-center u-justify-content-center">
                                    <Icon name="icon-clipboard-list" />
                                    <span>Match Log</span>
                                </div>
                            </div>
                            {match?.statusId !== MatchStatusEnum.Finished && (
                                <div
                                    onClick={() => this.finishMatch()}
                                    className="c-extended-menu__list u-flex u-flex-row u-flex-nowrap"
                                    role="button"
                                >
                                    <div className="c-extended-menu__item u-flex u-flex-column u-align-items-center u-justify-content-center">
                                        <Icon name="icon-whistle-solid" />
                                        <span>Finish Match</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </Content>
            </Spin>
        );
    };
}

export default MatchReport;
