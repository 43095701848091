import React from 'react';
import { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ActionOption, ModuleName } from '../../core/models/enum';
import { isUserAllowed } from '../../helpers/CheckPermissionHelper';
import { AuthorizationRoutes, QueryParameterNames } from './ApiAuthorizationConstants';
import authService from './AuthorizeService';
import Routes from '../../config/routes';
import { Profile } from 'oidc-client';

interface Props {
    exact?: any;
    component: any;
    path: any;
    moduleName: ModuleName;
    location?: any
}

interface State {
    ready: boolean;
    userProfile?: Profile;
    authorized?: boolean;
}

export default class AuthorizeRoute extends Component<Props, State> {
    _subscription: any;

    constructor(props: Props) {
        super(props);

        this.state = {
            ready: false,
            userProfile: undefined,
            authorized: undefined,
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.authenticationChanged());
        this.populateAuthenticationState();
    }

    componentDidUpdate(prevProps: Props) {
        const { moduleName } = this.props;

        if (moduleName !== prevProps.moduleName) {
            this.initSession();
        }
    }

    initSession = (): void => {
        const { moduleName } = this.props;
        const { userProfile } = this.state;

        const {
            location: { pathname },
        } = window;
        const urlParts = pathname.split('/');

        if (urlParts.length === 0) return;

        let entityId: number | undefined = undefined;
        if (urlParts.length > 2) {
            entityId = parseInt(urlParts[2], 10);
        }

        const lastPart = urlParts.pop();

        let actionType: ActionOption = ActionOption.Read;

        switch (lastPart) {
            case 'edit':
                actionType = ActionOption.Update;
                break;
            case 'create':
                actionType = ActionOption.Create;
                break;
            default:
                break;
        }

        if (!userProfile) {
            return;
        }

        const authorized = isUserAllowed(
            userProfile,
            moduleName,
            actionType,
            entityId && !isNaN(entityId) ? entityId : undefined
        );

        this.setState({
            authorized,
        });
    };

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    render() {
        const { ready, userProfile, authorized } = this.state;
        var link = document.createElement('a');
        link.href = this.props.location.pathname;
        const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
        const redirectUrl = `${AuthorizationRoutes.Login}?${
            QueryParameterNames.ReturnUrl
        }=${encodeURI(returnUrl)}`;
        if (!ready) {
            return <div></div>;
        } else {
            const { component: Component, ...rest } = this.props;
            return (
                <Route
                    {...rest}
                    render={(props) => {
                        if (!userProfile) {
                            return <Redirect to={redirectUrl} />;
                        }
                        if (authorized === undefined) {
                            return <div />;
                        }
                        if (authorized === false) {
                            return <Redirect to={Routes.ROUTE_NOT_FOUND} />;
                        }
                        return <Component {...props} userProfile={userProfile} />;
                    }}
                />
            );
        }
    }

    async populateAuthenticationState() {
        const userProfile = await authService.getUserProfile();
        this.setState({ ready: true, userProfile }, () => this.initSession());
    }

    async authenticationChanged() {
        this.setState({ ready: false, userProfile: undefined });
        await this.populateAuthenticationState();
    }
}
