import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Radio, Row } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import React from 'react';
import { DropdownOption } from '../../core/models/DropdownOption';
import './styles.css';

interface Props {
    selectedKeys: string[];
    confirm: Function;
    setSelectedKeys: Function;
    options: DropdownOption[];
    clearFilters: Function;
}

const searchInputId = '';

const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
    width: '150px',
};

class RadioFilter extends React.Component<Props> {
    public componentDidMount(): void {
        this.focusInputField();
    }

    public componentDidUpdate(): void {
        this.focusInputField();
    }

    private focusInputField = (): void => {
        const input = document.getElementById(searchInputId);

        if (input) {
            input.focus();
        }
    };

    public render = (): React.ReactElement => {
        const { selectedKeys, setSelectedKeys, clearFilters, confirm, options } = this.props;

        return (
            <div style={{ width: 'auto', height: 'auto' }}>
                <Row style={{ margin: '0 10%' }}>
                    <Radio.Group
                        onChange={(e: RadioChangeEvent): void => setSelectedKeys([e.target.value])}
                        value={selectedKeys[0]}
                    >
                        {options.map(
                            (d: DropdownOption): React.ReactElement => (
                                <Radio style={radioStyle} value={d.id}>
                                    {d.name}
                                </Radio>
                            )
                        )}
                    </Radio.Group>
                </Row>
                <Row style={{ margin: '10px 10% 0 10%', paddingBottom: '10px' }}>
                    <Button
                        type="primary"
                        onClick={(): void => confirm()}
                        icon={
                            <SearchOutlined
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            />
                        }
                        size="small"
                        style={{ margin: '0 10px 0 0' }}
                    />
                    <Button
                        onClick={(): void => clearFilters()}
                        size="small"
                        icon={
                            <CloseOutlined
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            />
                        }
                    />
                </Row>
            </div>
        );
    };
}

export default RadioFilter;
