import { Checkbox, Col, Drawer, Form, Input, Row } from 'antd';
import moment from 'moment';
import React from 'react';
import DetailsButtons from '../../../../components/details-buttons';
import { DrawerWidth } from '../../../../config/dimensions';
import Routes from '../../../../config/routes';
import ReplaceStrings from '../../../../config/replaceStrings';
import { DrawerState, ModuleName } from '../../../../core/models/enum';
import { convertUTCTimeToLocal } from '../../../../helpers/DateHelper';
import { formItemLayout724 } from '../../../../helpers/FormLayoutHelper';
import { getNewRoute } from '../../../../helpers/RoutingHelper';
import { handleDeleteHelper } from '../../../../helpers/SubmitHelper';
import { CompetitionsClient } from '../../../../utils/api';
import CompetitionForm from '../../competition-form';
import { Props } from './index';

interface State {
    drawerState: DrawerState;
    loading: boolean;
}

class CompetitionDetails extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            drawerState: props.isInitialEdit ? DrawerState.Edit : DrawerState.Closed,
            loading: true,
        };
    }

    private handleDrawerMode = (drawerState: DrawerState, id?: string): void => {
        const { history } = this.props;

        this.setState({
            drawerState,
        });

        history.push(
            getNewRoute(
                Routes.ROUTE_COMPETITIONS,
                Routes.ROUTE_COMPETITIONS_READ,
                Routes.ROUTE_COMPETITIONS_EDIT,
                drawerState,
                ReplaceStrings.COMPETITION_ID,
                id
            )
        );
    };

    private openForm = (): void => {
        const { competition } = this.props;
        this.handleDrawerMode(DrawerState.Edit, competition.id.toString());
    };

    private handleDelete = (): void => {
        const { history } = this.props;

        history.push(Routes.ROUTE_COMPETITIONS);
    };

    public render(): React.ReactElement {
        const { drawerState } = this.state;
        const { userProfile, competition, refreshAfterSave } = this.props;

        return (
            <div>
                <DetailsButtons
                    userProfile={userProfile}
                    onDeleteAction={(): Promise<void> =>
                        handleDeleteHelper(
                            new CompetitionsClient(),
                            'Competition successfully deleted.',
                            this.handleDelete,
                            competition.id
                        )
                    }
                    onEditAction={this.openForm}
                    customMessage="Are you sure you want to delete this competition?"
                    moduleName={ModuleName.Competitions}
                    entityId={competition.id}
                />
                <Row className="details-height">
                    <Col span={12}>
                        <Form {...formItemLayout724}>
                            <Form.Item label="Name">
                                <Input readOnly value={competition.name} />
                            </Form.Item>
                            <Form.Item label="Universal name">
                                <Input readOnly value={competition.nameUniversal} />
                            </Form.Item>
                            <Form.Item label="Short name">
                                <Input readOnly value={competition.shortName} />
                            </Form.Item>
                            <Form.Item label="Description">
                                <Input readOnly value={competition.description} />
                            </Form.Item>
                            <Form.Item label="Federation">
                                <Input readOnly value={competition.federation} />
                            </Form.Item>
                            <Form.Item label="Category">
                                <Input readOnly value={competition.category} />
                            </Form.Item>
                            <Form.Item label="Competition Type">
                                <Input readOnly value={competition.competitionType} />
                            </Form.Item>
                            <Form.Item label="Country">
                                <Input readOnly value={competition.country} />
                            </Form.Item>
                            <Form.Item label="City">
                                <Input readOnly value={competition.city} />
                            </Form.Item>
                            <Form.Item label="Start date">
                                <Input
                                    readOnly
                                    value={
                                        competition.startDate
                                            ? moment(
                                                  convertUTCTimeToLocal(competition.startDate)
                                              ).format('DD.MM.YYYY.')
                                            : ''
                                    }
                                />
                            </Form.Item>
                            <Form.Item label="End date">
                                <Input
                                    readOnly
                                    value={
                                        competition.endDate
                                            ? moment(
                                                  convertUTCTimeToLocal(competition.endDate)
                                              ).format('DD.MM.YYYY.')
                                            : ''
                                    }
                                />
                            </Form.Item>
                            <Form.Item label="Season">
                                <Input readOnly value={competition.season} />
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col span={12}>
                        <Form {...formItemLayout724}>
                            <Form.Item label="Venue">
                                <Input readOnly value={competition.venue} />
                            </Form.Item>
                            <Form.Item label="Address">
                                <Input readOnly value={competition.address} />
                            </Form.Item>
                            <Form.Item label="Phone">
                                <Input readOnly value={competition.phone} />
                            </Form.Item>
                            <Form.Item label="Web">
                                <Input readOnly value={competition.web} />
                            </Form.Item>
                            <Form.Item label="Email">
                                <Input readOnly value={competition.email} />
                            </Form.Item>
                            <Form.Item label="Facebook ">
                                <Input readOnly value={competition.facebook} />
                            </Form.Item>
                            <Form.Item label="Twitter ">
                                <Input readOnly value={competition.twitter} />
                            </Form.Item>
                            <Form.Item label="Instagram">
                                <Input readOnly value={competition.instagram} />
                            </Form.Item>
                            <Form.Item label="Youtube ">
                                <Input readOnly value={competition.youtube} />
                            </Form.Item>
                            <Form.Item label="Professional">
                                <Checkbox checked={competition.isPro} disabled />
                            </Form.Item>
                            <Form.Item label="International">
                                <Checkbox checked={competition.isInternational} disabled />
                            </Form.Item>
                            <Form.Item label="PIN confirmation">
                                <Checkbox
                                    checked={competition.gameLogPinConfirmationRequired}
                                    disabled
                                />
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
                <Drawer
                    title="Edit competition"
                    visible={drawerState === DrawerState.Edit}
                    onClose={(): void =>
                        this.handleDrawerMode(DrawerState.Read, competition.id.toString())
                    }
                    width={DrawerWidth}
                    destroyOnClose
                >
                    <CompetitionForm
                        handleClose={this.handleDrawerMode}
                        refreshAfterSave={refreshAfterSave}
                        competition={competition}
                    />
                </Drawer>
            </div>
        );
    }
}

export default CompetitionDetails;
