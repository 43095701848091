import { Checkbox, Form, Input } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React from 'react';
import AutocompleteDropdown from '../../../components/autocomplete-dropdown';
import DrawerButtons from '../../../components/drawer-buttons/index';
import { DropdownOption } from '../../../core/models/DropdownOption';
import { DrawerState } from '../../../core/models/enum';
import {
    confirmDropdownHelper,
    filterDropdownOptions,
} from '../../../helpers/AutocompleteDropdownHelper';
import {
    getCompetitionsToDropdownOptions,
    getTeamsToDropdownOptions,
} from '../../../helpers/FetchAndTransformHelpers';
import { formItemLayout724 } from '../../../helpers/FormLayoutHelper';
import { success } from '../../../helpers/NotificationHelper';
import { createHelper, updateHelper } from '../../../helpers/SubmitHelper';
import { CompetitionTeamsClient } from '../../../utils/api';
import { Props } from './index';

interface State {
    isSaving: boolean;
    teams: DropdownOption[];
    competitions: DropdownOption[];
    selectedTeam?: DropdownOption[];
    selectedCompetition?: DropdownOption[];
    isSeed: boolean;
    isDisqualified: boolean;
}

class CompetitionTeamForm extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        const { competitionTeam } = props;

        this.state = {
            isSaving: false,
            teams: [],
            competitions: [],
            isSeed: competitionTeam ? competitionTeam.isSeed : false,
            isDisqualified: competitionTeam ? competitionTeam.isDisqualified : false,
        };
    }

    public componentDidMount = (): void => {
        this.getCompetitions();
        this.getTeams();
    };

    private getCompetitions = async (): Promise<void> => {
        const { competitionTeam, initialValues } = this.props;
        const competitionId = competitionTeam && competitionTeam.competitionId;
        const preSelectedCompetitionId = initialValues && initialValues.competitionId;

        const results = await getCompetitionsToDropdownOptions(
            competitionId || preSelectedCompetitionId
        );

        this.setState({
            competitions: results.entities,
            selectedCompetition: results.selectedEntity,
        });
    };

    private getTeams = async (): Promise<void> => {
        const { competitionTeam, initialValues } = this.props;

        const results = await getTeamsToDropdownOptions(
            competitionTeam && competitionTeam.teamId,
            initialValues && initialValues.usedTeamIds,
            initialValues && initialValues.federationId,
            initialValues && initialValues.isInternational
        );

        this.setState({
            teams: results.entities,
            selectedTeam: results.selectedEntity,
        });
    };

    private onSubmit = (values: any): void => {
        const { selectedCompetition, selectedTeam, isDisqualified, isSeed } = this.state;
        const { competitionTeam } = this.props;
        const competitionTeamClient = new CompetitionTeamsClient();

        this.setState({
            isSaving: true,
        });

        const request = {
            ...values,
            id: competitionTeam ? competitionTeam.id : undefined,
            competitionId:
                selectedCompetition && selectedCompetition.length > 0
                    ? parseInt(selectedCompetition[0].id, 10)
                    : undefined,
            teamId:
                selectedTeam && selectedTeam.length > 0
                    ? parseInt(selectedTeam[0].id, 10)
                    : undefined,
            isDisqualified,
            isSeed,
        };

        if (request.id) {
            updateHelper(competitionTeamClient, request, this.handleSuccessfullySaving);
        } else {
            createHelper(competitionTeamClient, request, this.handleSuccessfullySaving);
        }

        this.setState({
            isSaving: false,
        });
    };

    private handleSuccessfullySaving = (): void => {
        const { competitionTeam, handleClose, refreshAfterSave } = this.props;

        success('Competition team successfully saved.');
        refreshAfterSave();
        handleClose(
            competitionTeam ? DrawerState.Read : DrawerState.Closed,
            competitionTeam ? competitionTeam.id.toString() : undefined
        );
    };

    public render(): React.ReactElement {
        const {
            isSaving,
            selectedCompetition,
            selectedTeam,
            competitions,
            teams,
            isDisqualified,
            isSeed,
        } = this.state;
        const { handleClose, competitionTeam, initialValues } = this.props;

        return (
            <Form onFinish={this.onSubmit} {...formItemLayout724}>
                <DrawerButtons
                    isSaving={isSaving}
                    onCancelAction={(): void =>
                        handleClose(
                            competitionTeam ? DrawerState.Read : DrawerState.Closed,
                            competitionTeam ? competitionTeam.id.toString() : undefined
                        )
                    }
                />
                <div className="drawer-content">
                    <Form.Item name="competitionId" label="Competition">
                        <AutocompleteDropdown
                            placeholder="Choose competition"
                            getOptionsFrontend={(value: string): DropdownOption[] =>
                                filterDropdownOptions(value, competitions)
                            }
                            initialValues={selectedCompetition}
                            confirmDirty={(options: DropdownOption[]): void =>
                                this.setState({
                                    selectedCompetition: confirmDropdownHelper(options),
                                })
                            }
                            style={{ width: '100%' }}
                            disabled={!!(initialValues && initialValues.competitionId)}
                            isAllowedToClear
                        />
                    </Form.Item>
                    <Form.Item name="teamId" label="Team">
                        <AutocompleteDropdown
                            placeholder="Choose team"
                            getOptionsFrontend={(value: string): DropdownOption[] =>
                                filterDropdownOptions(value, teams)
                            }
                            initialValues={selectedTeam}
                            confirmDirty={(options: DropdownOption[]): void =>
                                this.setState({
                                    selectedTeam: confirmDropdownHelper(options),
                                })
                            }
                            style={{ width: '100%' }}
                            disabled={!!competitionTeam}
                            isAllowedToClear
                        />
                    </Form.Item>
                    <Form.Item
                        name="isDisqualified"
                        label="Disqualified"
                        initialValue={competitionTeam ? competitionTeam.isDisqualified : undefined}
                    >
                        <Checkbox
                            checked={isDisqualified}
                            onChange={(e: CheckboxChangeEvent): void =>
                                this.setState({
                                    isDisqualified: e.target.checked,
                                })
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        name="isSeed"
                        label="Seed"
                        initialValue={competitionTeam ? competitionTeam.isSeed : undefined}
                    >
                        <Checkbox
                            checked={isSeed}
                            onChange={(e: CheckboxChangeEvent): void =>
                                this.setState({ isSeed: e.target.checked })
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        name="description"
                        label="Description"
                        initialValue={competitionTeam ? competitionTeam.description : undefined}
                    >
                        <Input />
                    </Form.Item>
                </div>
            </Form>
        );
    }
}

export default CompetitionTeamForm;
