import { CheckOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import Routes from '../../../config/routes';
import ReplaceStrings from '../../../config/replaceStrings';
import { ActionOption, ModuleName, SortDirectionEnum } from '../../../core/models/enum';
import { Filter } from '../../../core/models/Filter';
import {
    getAllowedEntities,
    isUserAdmin,
    isUserAllowed,
} from '../../../helpers/CheckPermissionHelper';
import { convertUTCTimeToLocal } from '../../../helpers/DateHelper';
import {
    buildFilterRequest,
    DefaultFilter,
    getDatePickerFilter,
    getRadioFilter,
    getSearchFilter,
} from '../../../helpers/FilterHelper';
import { PaginationOptions } from '../../../helpers/PaginationHelper';
import { feTableFilterHelper } from '../../../helpers/TableFilterHelper';
import { capitalizeFirstLetter } from '../../../helpers/TransformHelper';
import { PaginatedListOfTeamVm, TeamsClient, TeamVm } from '../../../utils/api';
import { Props } from './index';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { popupConfirmDeleteWithCallback } from '../../../helpers/NotificationHelper';

interface State {
    teams: TeamVm[];
    paginationOptions: TablePaginationConfig;
    currentFilter: Filter;
    loading: boolean;
    isUserAllowedToUpdate: boolean;
    isUserAllowedToDelete: boolean;
    allowedEntities: number[];
    isAdmin: boolean;
}

class TeamTable extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            teams: [],
            paginationOptions: PaginationOptions,
            currentFilter: DefaultFilter,
            loading: true,
            isUserAllowedToUpdate: false,
            isUserAllowedToDelete: false,
            allowedEntities: [],
            isAdmin: false,
        };
    }

    public componentDidMount = (): void => {
        this.getPermissions();
        this.getTeams();
    };

    private getPermissions = (): void => {
        const { userProfile, moduleName } = this.props;

        this.setState({
            isUserAllowedToUpdate: isUserAllowed(
                userProfile,
                moduleName || ModuleName.Teams,
                ActionOption.Update
            ),
            isUserAllowedToDelete: isUserAllowed(
                userProfile,
                moduleName || ModuleName.Teams,
                ActionOption.Delete
            ),
            allowedEntities: getAllowedEntities(userProfile, ModuleName.Teams),
            isAdmin: isUserAdmin(userProfile),
        });
    };

    private getTeams = async (currentFilterParam?: Filter): Promise<void> => {
        const { currentFilter } = this.state;
        const { teamsFromProps } = this.props;

        this.setState({
            loading: true,
        });

        let teamsPagedList;
        const filter = currentFilterParam || currentFilter;

        if (teamsFromProps) {
            teamsPagedList = this.getTeamsFromProps(filter);
        } else {
            teamsPagedList = await this.getAllTeams(filter);
        }

        this.setState({
            paginationOptions: {
                ...PaginationOptions,
                current: teamsPagedList.currentPage,
                pageSize: teamsPagedList.pageSize,
                total: teamsPagedList.totalItems,
            },
            teams: teamsPagedList?.pageItems || [],
            loading: false,
        });
    };

    private getAllTeams = async (filter: Filter): Promise<PaginatedListOfTeamVm> => {
        const teamClient = new TeamsClient();

        return await teamClient.getPaginated(
            filter.filterRequest?.nameUniversal,
            filter.filterRequest?.shortName,
            filter.filterRequest?.activeFrom,
            filter.filterRequest?.activeTo,
            filter.filterRequest?.isPro,
            filter.filterRequest?.club,
            filter.filterRequest?.category,
            filter.filterRequest?.country,
            filter.filterRequest?.city,
            filter.pageIndex,
            filter.pageSize,
            capitalizeFirstLetter(filter.orderByKey),
            filter.orderByValue
        );
    };

    private getTeamsFromProps = (filter: Filter): PaginatedListOfTeamVm => {
        const { teamsFromProps } = this.props;

        const filteredResult = feTableFilterHelper(teamsFromProps || [], filter);
        return new PaginatedListOfTeamVm({
            ...filteredResult,
            pageItems: filteredResult.pageItems as TeamVm[],
        });
    };

    private handleTableChange = (pagination: any, filters: any, sorter: any): void => {
        const filterRequest = buildFilterRequest(filters);

        const filter: Filter = {
            pageIndex: pagination.current,
            pageSize: pagination.pageSize,
            orderByKey: sorter?.columnKey && sorter?.order ? sorter.columnKey : undefined,
            orderByValue: sorter?.order ? sorter.order : undefined,
            filterRequest,
        };

        this.getTeams(filter);
        this.setState({
            currentFilter: filter,
        });
    };

    private handleDeleteHelper = async (id?: number): Promise<any> => {
        const { onDelete } = this.props;

        if (onDelete) {
            onDelete(id);
        }
    };

    public render(): React.ReactElement {
        const {
            teams,
            loading,
            paginationOptions,
            isUserAllowedToDelete,
            isUserAllowedToUpdate,
            allowedEntities,
            isAdmin,
        } = this.state;
        const { teamsFromProps, shouldDisplayEditDelete, history, competitionId } = this.props;

        const columns = [
            {
                title: 'Name',
                dataIndex: 'nameUniversal',
                key: 'nameUniversal',
                ...getSearchFilter(),
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: (): void => {},
            },
            {
                title: 'Club',
                dataIndex: 'club',
                key: 'club',
                ...getSearchFilter(),
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: (): void => {},
            },
            {
                title: 'Category',
                dataIndex: 'category',
                key: 'category',
                ...getSearchFilter(),
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: (): void => {},
            },
            {
                title: 'Country',
                dataIndex: 'country',
                key: 'country',
                ...getSearchFilter(),
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: (): void => {},
            },
            {
                title: 'City',
                dataIndex: 'city',
                key: 'city',
                ...getSearchFilter(),
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: (): void => {},
            },
            {
                title: 'Professional',
                dataIndex: 'isPro',
                key: 'isPro',
                ...getRadioFilter([
                    { id: 'false', name: 'False' },
                    { id: 'true', name: 'True' },
                ]),
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: (): void => {},
                render: (value: boolean): React.ReactElement =>
                    value ? <CheckOutlined style={{ width: '100%' }} /> : <span />,
                width: 110,
            },
            {
                title: 'Active from',
                dataIndex: 'activeFrom',
                key: 'activeFrom',
                ...getDatePickerFilter(),
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: (): void => {},
                render: (value: Date): string =>
                    value ? moment(convertUTCTimeToLocal(value)).format('DD.MM.YYYY.') : '',
                width: 110,
            },
            {
                title: 'Active to',
                dataIndex: 'activeTo',
                key: 'activeTo',
                ...getDatePickerFilter(),
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: (): void => {},
                render: (value?: Date): string =>
                    value ? moment(convertUTCTimeToLocal(value)).format('DD.MM.YYYY.') : '',
                width: 110,
            },
        ];

        if (teamsFromProps && shouldDisplayEditDelete) {
            columns.push({
                title: '',
                dataIndex: '',
                key: '',
                render: (value: TeamVm): React.ReactElement =>
                    value && value.id ? (
                        <>
                            {isUserAllowedToUpdate &&
                                competitionId &&
                                (isAdmin ||
                                true || !!allowedEntities.find(
                                        (ent: number): boolean => ent === value?.id
                                    )) && (
                                    <EditOutlined
                                        onClick={(): void =>
                                            history.push(
                                                Routes.ROUTE_COMPETITION_TEAMS_EDIT.replace(
                                                    ReplaceStrings.TEAM_ID,
                                                    value.id.toString()
                                                ).replace(
                                                    ReplaceStrings.COMPETITION_ID,
                                                    competitionId.toString()
                                                )
                                            )
                                        }
                                        style={{ color: '#1890ff' }}
                                    />
                                )}
                            {isUserAllowedToDelete &&
                                (isAdmin ||
                                true || !!allowedEntities.find(
                                        (ent: number): boolean => ent === value?.id
                                    )) && (
                                    <DeleteOutlined
                                        onClick={(): void =>
                                            popupConfirmDeleteWithCallback(
                                                (): Promise<void> =>
                                                    this.handleDeleteHelper(value.id),
                                                undefined,
                                                'Are you sure you want to remove this team?',
                                                'Delete'
                                            )
                                        }
                                        className="text-danger-custom"
                                        style={{ marginLeft: 10 }}
                                    />
                                )}
                        </>
                    ) : (
                        <span />
                    ),
                width: 80,
            });
        } else {
            columns.push({
                dataIndex: '',
                render: (value: TeamVm): React.ReactElement =>
                    value &&
                    value.id &&
                    (isAdmin ||
                        true || !!allowedEntities.find((ent: number): boolean => ent === value?.id)) ? (
                        <Link
                            to={Routes.ROUTE_TEAMS_READ.replace(
                                ReplaceStrings.TEAM_ID,
                                value.id.toString()
                            )}
                        >
                            Details
                        </Link>
                    ) : (
                        <span />
                    ),
                width: 80,
            });
        }

        return (
            <Table
                columns={columns}
                loading={loading}
                dataSource={teams}
                rowKey={(record): string => (record && record.id ? record.id.toString() : '-1')}
                pagination={paginationOptions}
                onChange={this.handleTableChange}
                bordered
                size="small"
            />
        );
    }
}

export default TeamTable;
