import { SaveOutlined, SyncOutlined } from '@ant-design/icons';
import { Button, Row } from 'antd';
import React from 'react';
import { Props } from './index';

class DrawerButtons extends React.PureComponent<Props> {
    public constructor(props: Props) {
        super(props);
    }

    public render(): React.ReactElement {
        const { isSaving, isFormDirty, onCancelAction, onSubmitAction } = this.props;

        return (
            <Row style={{ height: 50, display: 'flow-root', paddingRight: 40 }}>
                <div style={{ float: 'right' }}>
                    <Button type="default" style={{ width: 100 }} onClick={onCancelAction}>
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        style={{
                            marginLeft: 10,
                            width: 100,
                        }}
                        htmlType="submit"
                        disabled={isFormDirty !== undefined && !isFormDirty}
                        onClick={onSubmitAction}
                    >
                        {isSaving ? (
                            <SyncOutlined spin className="button-icon-display" />
                        ) : (
                            <SaveOutlined className="button-icon-display" />
                        )}
                        {isSaving ? 'Saving' : 'Save'}
                    </Button>
                </div>
            </Row>
        );
    }
}

export default DrawerButtons;
