import { UploadOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, DatePicker, Form, Input, Row, Upload } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import moment from 'moment';
import React from 'react';
import AutocompleteDropdown from '../../../components/autocomplete-dropdown';
import DrawerButtons from '../../../components/drawer-buttons/index';
import { DropdownOption } from '../../../core/models/DropdownOption';
import { DrawerState } from '../../../core/models/enum';
import {
    confirmDropdownHelper,
    filterDropdownOptions,
    getCitiesHelper,
    getCountriesHelper,
} from '../../../helpers/AutocompleteDropdownHelper';
import { convertUTCTimeToLocal } from '../../../helpers/DateHelper';
import {
    getCategoriesToDropdownOptions,
    getCompetitionTypesToDropdownOptions,
    getFederationsToDropdownOptions,
} from '../../../helpers/FetchAndTransformHelpers';
import { onUploadFileChangeHelper } from '../../../helpers/FileHelper';
import { formItemLayout724 } from '../../../helpers/FormLayoutHelper';
import { success } from '../../../helpers/NotificationHelper';
import { createHelper, updateHelper } from '../../../helpers/SubmitHelper';
import { customArrayValidationHelper } from '../../../helpers/ValidationHelper';
import { CompetitionsClient } from '../../../utils/api';
import { Props } from './index';

interface State {
    isSaving: boolean;
    federations: DropdownOption[];
    categories: DropdownOption[];
    competitionTypes: DropdownOption[];
    countries: DropdownOption[];
    cities: DropdownOption[];
    selectedFederation?: DropdownOption[];
    selectedCategory?: DropdownOption[];
    selectedCompetitionType?: DropdownOption[];
    selectedCountry?: DropdownOption[];
    selectedCity?: DropdownOption[];
    isPro: boolean;
    isInternational: boolean;
    gameLogPinConfirmationRequired: boolean;
    file?: any;
    fileBase64?: string;
}

class CompetitionForm extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        const { competition } = props;

        this.state = {
            isSaving: false,
            federations: [],
            categories: [],
            competitionTypes: [],
            countries: [],
            cities: [],
            isPro: competition ? competition.isPro : false,
            isInternational: competition ? competition.isInternational : false,
            gameLogPinConfirmationRequired: competition
                ? competition.gameLogPinConfirmationRequired
                : false,
            fileBase64: competition ? competition.logo : undefined,
        };
    }

    public componentDidMount = (): void => {
        const { competition } = this.props;

        this.getFederations();
        this.getCategories();
        this.getCompetitionTypes();
        this.getCountries();

        if (!(competition && competition.countryId)) {
            this.getCities();
        }
    };

    private getFederations = async (): Promise<void> => {
        const { competition, initialValues } = this.props;
        const preSelectedFederationId = initialValues && initialValues.federationId;
        const federationId = competition && competition.federationId;
        const results = await getFederationsToDropdownOptions(
            federationId || preSelectedFederationId
        );

        this.setState({
            federations: results.entities,
            selectedFederation: results.selectedEntity,
        });
    };

    private getCategories = async (): Promise<void> => {
        const { competition } = this.props;
        const results = await getCategoriesToDropdownOptions(competition && competition.categoryId);

        this.setState({
            categories: results.entities,
            selectedCategory: results.selectedEntity,
        });
    };

    private getCompetitionTypes = async (): Promise<void> => {
        const { competition } = this.props;
        const results = await getCompetitionTypesToDropdownOptions(
            competition && competition.competitionTypeId
        );

        this.setState({
            competitionTypes: results.entities,
            selectedCompetitionType: results.selectedEntity,
        });
    };

    private getCountries = async (): Promise<void> => {
        const { competition } = this.props;
        const countryId = competition && competition.countryId;

        const countryHelper = await getCountriesHelper(countryId);

        if (countryId) {
            this.getCities(countryId);
        }

        this.setState({
            countries: countryHelper.entities,
            selectedCountry: countryHelper.selectedEntity,
        });
    };

    private getCities = async (countryId?: number): Promise<void> => {
        const { competition } = this.props;
        const cityId = competition && competition.cityId;

        const cityHelper = await getCitiesHelper(countryId, cityId);

        this.setState({
            cities: cityHelper.entities,
            selectedCity: cityHelper.selectedEntity,
        });
    };

    private onSubmit = (values: any): void => {
        const {
            selectedFederation,
            selectedCategory,
            selectedCompetitionType,
            selectedCountry,
            selectedCity,
            isPro,
            isInternational,
            gameLogPinConfirmationRequired,
            fileBase64,
        } = this.state;
        const { competition } = this.props;
        const competitionClient = new CompetitionsClient();

        this.setState({
            isSaving: true,
        });

        const request = {
            ...values,
            id: competition ? competition.id : undefined,
            startDate: values?.startDate ? moment(values.startDate.toLocaleString()) : undefined,
            endDate: values?.endDate ? moment(values.endDate.toLocaleString()) : undefined,
            federationId:
                selectedFederation && selectedFederation.length > 0
                    ? parseInt(selectedFederation[0].id, 10)
                    : undefined,
            categoryId:
                selectedCategory && selectedCategory.length > 0
                    ? parseInt(selectedCategory[0].id, 10)
                    : undefined,
            competitionTypeId:
                selectedCompetitionType && selectedCompetitionType.length > 0
                    ? parseInt(selectedCompetitionType[0].id, 10)
                    : undefined,
            countryId:
                selectedCountry && selectedCountry.length > 0
                    ? parseInt(selectedCountry[0].id, 10)
                    : undefined,
            cityId:
                selectedCity && selectedCity.length > 0
                    ? parseInt(selectedCity[0].id, 10)
                    : undefined,
            isPro,
            isInternational,
            gameLogPinConfirmationRequired,
            logo: fileBase64,
        };

        if (request.id) {
            updateHelper(competitionClient, request, this.handleSuccessfullySaving);
        } else {
            createHelper(competitionClient, request, this.handleSuccessfullySaving);
        }

        this.setState({
            isSaving: false,
        });
    };

    private handleSuccessfullySaving = (): void => {
        const { competition, handleClose, refreshAfterSave } = this.props;

        success('Competition successfully saved.');
        refreshAfterSave();
        handleClose(
            competition ? DrawerState.Read : DrawerState.Closed,
            competition ? competition.id.toString() : undefined
        );
    };

    private confirmCountry = (option: DropdownOption[]): void => {
        const selectedCountry = confirmDropdownHelper(option);
        this.setState({
            selectedCountry,
            selectedCity: undefined,
        });
        if (selectedCountry) {
            this.getCities(parseInt(selectedCountry[0].id, 10));
        }
    };

    private onIsProChange = (e: CheckboxChangeEvent): void => {
        this.setState({
            isPro: e.target.checked,
        });
    };

    private onIsInternationalChange = (e: CheckboxChangeEvent): void => {
        this.setState({
            isInternational: e.target.checked,
        });
    };

    private onGameLogPinConfirmationRequiredChange = (e: CheckboxChangeEvent): void => {
        this.setState({
            gameLogPinConfirmationRequired: e.target.checked,
        });
    };

    private onUploadFileChange = async (file: File): Promise<void> => {
        const uploadHelper = await onUploadFileChangeHelper(file);

        if (uploadHelper) {
            this.setState({
                file: uploadHelper.file,
                fileBase64: uploadHelper.fileBase64,
            });
        }
    };

    public render(): React.ReactElement {
        const {
            isSaving,
            selectedFederation,
            selectedCategory,
            selectedCompetitionType,
            selectedCountry,
            selectedCity,
            isPro,
            isInternational,
            gameLogPinConfirmationRequired,
            file,
            fileBase64,
            competitionTypes,
            cities,
            countries,
            categories,
            federations,
        } = this.state;
        const { handleClose, competition, initialValues } = this.props;

        return (
            <Form onFinish={this.onSubmit} {...formItemLayout724}>
                <DrawerButtons
                    isSaving={isSaving}
                    onCancelAction={(): void =>
                        handleClose(
                            competition ? DrawerState.Read : DrawerState.Closed,
                            competition ? competition.id.toString() : undefined
                        )
                    }
                />
                <div className="drawer-content">
                    <Form.Item
                        name="name"
                        label="Name"
                        initialValue={competition ? competition.name : undefined}
                        rules={[
                            {
                                required: true,
                                message: 'Name field is required!',
                            },
                            { max: 200, message: 'Too long input!' },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="nameUniversal"
                        label="Universal name"
                        initialValue={competition ? competition.nameUniversal : undefined}
                        rules={[
                            {
                                required: true,
                                message: 'Universal name field is required!',
                            },
                            { max: 200, message: 'Too long input!' },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="shortName"
                        label="Short name"
                        initialValue={competition ? competition.shortName : undefined}
                        rules={[{ max: 200, message: 'Too long input!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="description"
                        label="Description"
                        initialValue={competition ? competition.description : undefined}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="federation"
                        label="Federation"
                        rules={[
                            {
                                required: true,
                                validator: (_rule: any, value: any, callback: any): void =>
                                    customArrayValidationHelper(
                                        _rule,
                                        value,
                                        callback,
                                        'Federation field is required!',
                                        selectedFederation
                                    ),
                            },
                        ]}
                    >
                        <AutocompleteDropdown
                            placeholder="Choose federation"
                            getOptionsFrontend={(value: string): DropdownOption[] =>
                                filterDropdownOptions(value, federations)
                            }
                            initialValues={selectedFederation}
                            confirmDirty={(options: DropdownOption[]): void =>
                                this.setState({
                                    selectedFederation: confirmDropdownHelper(options),
                                })
                            }
                            style={{ width: '100%' }}
                            disabled={!!(initialValues && initialValues.federationId)}
                            isAllowedToClear
                        />
                    </Form.Item>
                    <Form.Item name="category" label="Category">
                        <AutocompleteDropdown
                            placeholder="Choose category"
                            getOptionsFrontend={(value: string): DropdownOption[] =>
                                filterDropdownOptions(value, categories)
                            }
                            initialValues={selectedCategory}
                            confirmDirty={(options: DropdownOption[]): void =>
                                this.setState({
                                    selectedCategory: confirmDropdownHelper(options),
                                })
                            }
                            style={{ width: '100%' }}
                            isAllowedToClear
                        />
                    </Form.Item>
                    <Form.Item name="competitionType" label="Competition type">
                        <AutocompleteDropdown
                            placeholder="Choose competition type"
                            getOptionsFrontend={(value: string): DropdownOption[] =>
                                filterDropdownOptions(value, competitionTypes)
                            }
                            initialValues={selectedCompetitionType}
                            confirmDirty={(options: DropdownOption[]): void =>
                                this.setState({
                                    selectedCompetitionType: confirmDropdownHelper(options),
                                })
                            }
                            style={{ width: '100%' }}
                            isAllowedToClear
                        />
                    </Form.Item>
                    <Form.Item name="country" label="Country">
                        <AutocompleteDropdown
                            placeholder="Choose country"
                            getOptionsFrontend={(value: string): DropdownOption[] =>
                                filterDropdownOptions(value, countries)
                            }
                            initialValues={selectedCountry}
                            confirmDirty={this.confirmCountry}
                            style={{ width: '100%' }}
                            isAllowedToClear
                        />
                    </Form.Item>
                    <Form.Item name="city" label="City">
                        <AutocompleteDropdown
                            placeholder="Choose city"
                            getOptionsFrontend={(value: string): DropdownOption[] =>
                                filterDropdownOptions(value, cities)
                            }
                            initialValues={selectedCity}
                            confirmDirty={(options: DropdownOption[]): void =>
                                this.setState({
                                    selectedCity: confirmDropdownHelper(options),
                                })
                            }
                            style={{ width: '100%' }}
                            isAllowedToClear
                        />
                    </Form.Item>
                    <Form.Item
                        name="startDate"
                        label="Start date"
                        initialValue={
                            competition && competition.startDate
                                ? moment(convertUTCTimeToLocal(competition.startDate))
                                : undefined
                        }
                    >
                        <DatePicker format="DD.MM.YYYY." style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                        name="endDate"
                        label="End date"
                        initialValue={
                            competition && competition.endDate
                                ? moment(convertUTCTimeToLocal(competition.endDate))
                                : undefined
                        }
                    >
                        <DatePicker format="DD.MM.YYYY." style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                        name="season"
                        label="Season"
                        initialValue={competition ? competition.season : undefined}
                        rules={[{ max: 200, message: 'Too long input!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="venue"
                        label="Venue"
                        initialValue={competition ? competition.venue : undefined}
                        rules={[{ max: 200, message: 'Too long input!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="address"
                        label="Address"
                        initialValue={competition ? competition.address : undefined}
                        rules={[{ max: 200, message: 'Too long input!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="phone"
                        label="Phone"
                        initialValue={competition ? competition.phone : undefined}
                        rules={[
                            {
                                // eslint-disable-next-line
                                pattern: /^[0-9(\)\+]+$/,
                                message: 'Only numbers and plus sign are allowed!',
                            },
                            { max: 100, message: 'Too long input!' },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="web"
                        label="Web"
                        initialValue={competition ? competition.web : undefined}
                        rules={[{ max: 100, message: 'Too long input!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        label="Email"
                        initialValue={competition ? competition.email : undefined}
                        rules={[
                            { type: 'email', message: 'Wrong e-mail format!' },
                            { max: 100, message: 'Too long input!' },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="facebook"
                        label="Facebook"
                        initialValue={competition ? competition.facebook : undefined}
                        rules={[{ max: 100, message: 'Too long input!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="twitter"
                        label="Twitter"
                        initialValue={competition ? competition.twitter : undefined}
                        rules={[{ max: 100, message: 'Too long input!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="instagram"
                        label="Instagram"
                        initialValue={competition ? competition.instagram : undefined}
                        rules={[{ max: 100, message: 'Too long input!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="youtube"
                        label="Youtube"
                        initialValue={competition ? competition.youtube : undefined}
                        rules={[{ max: 100, message: 'Too long input!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="isPro"
                        label="Professional"
                        initialValue={competition ? competition.isPro : undefined}
                    >
                        <Checkbox checked={isPro} onChange={this.onIsProChange} />
                    </Form.Item>
                    <Form.Item
                        name="isInternational"
                        label="International"
                        initialValue={competition ? competition.isInternational : undefined}
                    >
                        <Checkbox
                            checked={isInternational}
                            onChange={this.onIsInternationalChange}
                        />
                    </Form.Item>
                    <Form.Item
                        name="gameLogPinConfirmationRequired"
                        label="PIN confirmation"
                        initialValue={
                            competition ? competition.gameLogPinConfirmationRequired : undefined
                        }
                    >
                        <Checkbox
                            checked={gameLogPinConfirmationRequired}
                            onChange={this.onGameLogPinConfirmationRequiredChange}
                        />
                    </Form.Item>
                    <Form.Item
                        name="logo"
                        label="Logo"
                        initialValue={competition ? competition.logo : undefined}
                    >
                        {fileBase64 && (
                            <Row>
                                <Col span="24">
                                    <img
                                        height={55}
                                        src={`data:image/jpeg;base64,${fileBase64}`}
                                        alt="logo"
                                    />
                                </Col>
                                <Col span="24">
                                    <Button
                                        type="primary"
                                        danger
                                        style={{ marginTop: 10 }}
                                        onClick={(): void =>
                                            this.setState({
                                                file: undefined,
                                                fileBase64: undefined,
                                            })
                                        }
                                    >
                                        Remove logo
                                    </Button>
                                </Col>
                            </Row>
                        )}
                        {!fileBase64 && (
                            <Upload
                                accept="image/png"
                                style={{ zIndex: 10 }}
                                beforeUpload={(file) => {
                                    this.onUploadFileChange(file);
                                    return false;
                                }}
                                onRemove={(): void =>
                                    this.setState({
                                        file: undefined,
                                        fileBase64: undefined,
                                    })
                                }
                                fileList={file ? [{ ...file }] : []}
                            >
                                <Button type="primary" disabled={!!file}>
                                    <UploadOutlined className="button-icon-display" />
                                    Upload image
                                </Button>
                            </Upload>
                        )}
                    </Form.Item>
                </div>
            </Form>
        );
    }
}

export default CompetitionForm;
