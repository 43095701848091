import { RouteComponentProps, withRouter } from 'react-router-dom';
import { DrawerState } from '../../../core/models/enum';
import { MatchOfficialVm } from '../../../utils/api';
import { RouteParams } from '../../../core/models/RouteHelper';
import MatchOfficialForm from './MatchOfficialForm';

interface OwnProps {
    federationId: number;
    matchId: number;
    matchOfficial?: MatchOfficialVm;
    existingOfficials: MatchOfficialVm[]
    handleClose: (drawerMode: DrawerState, id?: string) => void;
    refreshAfterSave: () => void;
}

export type Props = OwnProps & RouteComponentProps<RouteParams>;

export default withRouter(MatchOfficialForm);
