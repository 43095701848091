import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Form, Input } from 'antd';
import React from 'react';
import DrawerButtons from '../../../components/drawer-buttons/index';
import { DrawerState } from '../../../core/models/enum';
import { formItemLayout724 } from '../../../helpers/FormLayoutHelper';
import { errorWithUserMessage, success } from '../../../helpers/NotificationHelper';
import { ApiException, OfficialsClient, UpdateOfficialPinCommand } from '../../../utils/api';
import { Props } from './index';

interface State {
    isSaving: boolean;
}

class OfficialUpdatePINForm extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            isSaving: false,
        };
    }

    private onSubmit = async (values: any): Promise<void> => {
        const {
            match: {
                params: { officialId },
            },
        } = this.props;

        this.setState({
            isSaving: true,
        });

        if (officialId) {
            try {
                const officialClient = new OfficialsClient();

                const request: UpdateOfficialPinCommand = new UpdateOfficialPinCommand({
                    id: parseInt(officialId, 10),
                    pin: values.pin,
                });

                const result = await officialClient.updatePin(request.id, request);

                if (result) {
                    this.handleSuccessfullySaving();
                }
            } catch (error) {
                if (error instanceof ApiException) {
                    errorWithUserMessage(error.response);
                } else {
                    errorWithUserMessage('Error saving data.');
                }    
            }
        }

        this.setState({
            isSaving: false,
        });
    };

    private handleSuccessfullySaving = (): void => {
        const { handleClose } = this.props;

        success('PIN successfully updated.');
        handleClose(DrawerState.Closed);
    };

    public render(): React.ReactElement {
        const { isSaving } = this.state;
        const { handleClose } = this.props;

        return (
            <Form onFinish={this.onSubmit} {...formItemLayout724}>
                <DrawerButtons
                    isSaving={isSaving}
                    onCancelAction={(): void => handleClose(DrawerState.Closed)}
                />
                <div className="drawer-content">
                    <Form.Item
                        name="pin"
                        label="New PIN"
                        rules={[
                            {
                                pattern: /^[0-9]+$/,
                                message: 'Only numbers are allowed!',
                            },
                            { min: 4, message: 'Too short PIN!' },
                            { max: 4, message: 'Too long PIN!' },
                        ]}
                    >
                        <Input.Password
                            placeholder="****"
                            iconRender={(visible) =>
                                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                            }
                        />
                    </Form.Item>
                </div>
            </Form>
        );
    }
}

export default OfficialUpdatePINForm;
