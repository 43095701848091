import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RouteParams } from '../../../core/models/RouteHelper';
import { DrawerState } from '../../../core/models/enum';
import { CityVm } from '../../../utils/api';
import CityForm from './CityForm';

interface OwnProps {
    city?: CityVm;
    handleClose: (drawerMode: DrawerState, id?: string) => void;
    refreshAfterSave?: () => void;
}

export type Props = OwnProps & RouteComponentProps<RouteParams>;

export default withRouter(CityForm);
