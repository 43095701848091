import { PlusOutlined } from '@ant-design/icons';
import { Button, Drawer, PageHeader } from 'antd';
import React from 'react';
import { DrawerWidth } from '../../config/dimensions';
import Routes from '../../config/routes';
import ReplaceStrings from '../../config/replaceStrings';
import { ActionOption, DrawerState, ModuleName } from '../../core/models/enum';
import { isUserAllowed } from '../../helpers/CheckPermissionHelper';
import { getNewRoute } from '../../helpers/RoutingHelper';
import CompetitionForm from './competition-form';
import CompetitionTable from './competitions-table';
import { Props } from './index';

interface State {
    drawerState: DrawerState;
    isUserAllowedToCreate: boolean;
}

class Competitions extends React.PureComponent<Props, State> {
    private tableRef: any;

    public constructor(props: Props) {
        super(props);

        const {
            location: { pathname },
        } = window;

        this.tableRef = React.createRef();

        this.state = {
            drawerState:
                pathname === Routes.ROUTE_COMPETITIONS_CREATE
                    ? DrawerState.Edit
                    : DrawerState.Closed,
            isUserAllowedToCreate: false,
        };
    }

    public componentDidMount = (): void => {
        this.getPermissions();
    };

    private getPermissions = (): void => {
        const { userProfile } = this.props;

        this.setState({
            isUserAllowedToCreate: isUserAllowed(
                userProfile,
                ModuleName.Competitions,
                ActionOption.Create
            ),
        });
    };

    private handleDrawerMode = (drawerState: DrawerState, id?: string): void => {
        const { history } = this.props;

        this.setState({
            drawerState,
        });

        history.push(
            getNewRoute(
                Routes.ROUTE_COMPETITIONS,
                Routes.ROUTE_COMPETITIONS_READ,
                Routes.ROUTE_COMPETITIONS_CREATE,
                drawerState,
                ReplaceStrings.COMPETITION_ID,
                id
            )
        );
    };

    private updateTable = (): void => {
        this.tableRef.current.getCompetitions();
    };

    public render(): React.ReactElement {
        const { userProfile } = this.props;
        const { drawerState, isUserAllowedToCreate } = this.state;

        return (
            <div>
                <PageHeader
                    title="Competitions"
                    extra={
                        isUserAllowedToCreate
                            ? [
                                  <Button
                                      key="1"
                                      type="primary"
                                      style={{
                                          zIndex: 10,
                                          float: 'right',
                                      }}
                                      onClick={(): void => this.handleDrawerMode(DrawerState.Edit)}
                                  >
                                      <PlusOutlined className="button-icon-display" />
                                      Add competition
                                  </Button>,
                              ]
                            : []
                    }
                    style={{ padding: '16px 0px' }}
                />
                <CompetitionTable userProfile={userProfile} wrappedComponentRef={this.tableRef} />
                <Drawer
                    title="Add federation"
                    visible={!!drawerState}
                    onClose={(): void => this.handleDrawerMode(DrawerState.Closed)}
                    width={DrawerWidth}
                    destroyOnClose
                >
                    <CompetitionForm
                        handleClose={this.handleDrawerMode}
                        refreshAfterSave={this.updateTable}
                    />
                </Drawer>
            </div>
        );
    }
}

export default Competitions;
