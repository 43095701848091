import { TablePaginationConfig } from 'antd/lib/table';
import { Filter } from '../core/models/Filter';
import { PaginationHelperModel } from '../core/models/PaginationHelper';
import { defaultPageIndex, defaultPageSize } from './FilterHelper';

export const PaginationOptions: TablePaginationConfig = {
    showSizeChanger: true,
    pageSizeOptions: ['5', '10', '25', '50'],
};

export const calculateNewPaginationSettings = (
    filter: Filter,
    length: number
): PaginationHelperModel => {
    const currentPage = filter.pageIndex || defaultPageIndex;
    const pageSize = filter.pageSize || defaultPageSize;

    const totalItems = length;
    const totalPages = Math.ceil(totalItems / pageSize);

    const startIndex = pageSize * (currentPage - 1);
    const endIndex = pageSize * currentPage;

    return {
        currentPage,
        pageSize,
        totalPages,
        totalItems,
        startIndex,
        endIndex,
    };
};
