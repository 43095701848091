import moment from 'moment';

export const checkIfDateIsPast = (date: Date): boolean => {
    const dateNow = new Date();
    dateNow.setHours(0, 0, 0, 0);

    if (date < dateNow) {
        return true;
    }

    return false;
};

export const convertUTCTimeToLocal = (date: Date): Date => {
    const utcOffset = moment(date).utcOffset();
    return moment(date).add(utcOffset, 'minutes').toDate();
};

export const checkIfDatesWithoutTimeAreSame = (firstDate: Date, secondDate?: Date): boolean => {
    if (!secondDate) return false;

    return moment(firstDate).isSame(secondDate, 'day');
};
