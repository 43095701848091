import { Col, Form, Row, Spin, Tag } from 'antd';
import React from 'react';
import AutocompleteDropdown from '../../../components/autocomplete-dropdown';
import DetailsButtons from '../../../components/details-buttons';
import DrawerButtons from '../../../components/drawer-buttons';
import Routes from '../../../config/routes';
import { LINEUP_LIMIT } from '../../../config/settings';
import ReplaceStrings from '../../../config/replaceStrings';
import { DropdownOption } from '../../../core/models/DropdownOption';
import { ActionOption, DrawerState, ModuleName } from '../../../core/models/enum';
import { filterDropdownOptions } from '../../../helpers/AutocompleteDropdownHelper';
import { isUserAllowed } from '../../../helpers/CheckPermissionHelper';
import { getTeamPlayersToDropdownOptions } from '../../../helpers/FetchAndTransformHelpers';
import { errorWithUserMessage, success } from '../../../helpers/NotificationHelper';
import { createMatchPlayerCommandFromDropdowns } from '../../../helpers/PlayerSubmitHelper';
import { getNewRoute } from '../../../helpers/RoutingHelper';
import {
    transformMatchPlayerToDropdownOptions,
    transformTeamPlayersToDropdownOptions,
} from '../../../helpers/TransformHelper';
import {
    ApiException,
    CreateOrUpdateMatchPlayersCommand,
    MatchesClient,
    MatchPlayerVm,
    MatchStatusEnum,
} from '../../../utils/api';
import { Props } from './index';
import './styles.css';

const restColumnSize = 1;
const numberColumnSize = 2;
const dropdownColumnSize = 21;

interface State {
    drawerState: DrawerState;
    isSaving: boolean;
    isLoading: boolean;
    isFormDirty: boolean;
    homePlayers: DropdownOption[];
    awayPlayers: DropdownOption[];
    homePlayerDropdowns: DropdownOption[][];
    awayPlayerDropdowns: DropdownOption[][];
    isUserAllowedOnHomeTeam: boolean;
    isUserAllowedOnAwayTeam: boolean;
}

class MatchLineups extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        const initialPlayerDropdowns: DropdownOption[][] = [];
        for (let i = 0; i < LINEUP_LIMIT; i += 1) {
            initialPlayerDropdowns.push([]);
        }

        this.state = {
            drawerState: props.isInitialEdit ? DrawerState.Edit : DrawerState.Closed,
            isFormDirty: false,
            isLoading: false,
            isSaving: false,
            homePlayers: [],
            awayPlayers: [],
            homePlayerDropdowns: initialPlayerDropdowns,
            awayPlayerDropdowns: initialPlayerDropdowns,
            isUserAllowedOnHomeTeam: false,
            isUserAllowedOnAwayTeam: false,
        };
    }

    public componentDidMount = (): void => {
        this.getPermissions();
    };

    private getPermissions = async (): Promise<void> => {
        const { userProfile, matchFromProps } = this.props;

        this.setState(
            {
                isUserAllowedOnHomeTeam: isUserAllowed(
                    userProfile,
                    ModuleName.Teams,
                    ActionOption.Read,
                    matchFromProps.homeTeamId
                ),
                isUserAllowedOnAwayTeam: isUserAllowed(
                    userProfile,
                    ModuleName.Teams,
                    ActionOption.Read,
                    matchFromProps.awayTeamId
                ),
            },
            (): Promise<void> => this.mapPlayers()
        );
    };

    private mapPlayers = async (): Promise<void> => {
        const { matchFromProps } = this.props;
        const { drawerState, isUserAllowedOnHomeTeam, isUserAllowedOnAwayTeam } = this.state;

        this.setState({
            isLoading: true,
        });

        let homePlayers: DropdownOption[] = [];
        let awayPlayers: DropdownOption[] = [];

        if (drawerState === DrawerState.Closed) {
            const players = transformTeamPlayersToDropdownOptions(
                matchFromProps.players || [],
                matchFromProps.homeTeamId
            );

            homePlayers = players.home;
            awayPlayers = players.away;
        } else {
            if (isUserAllowedOnHomeTeam) {
                const homePlayersWrapped = await getTeamPlayersToDropdownOptions(
                    matchFromProps.homeTeamId
                );
                homePlayers = homePlayersWrapped.entities;
            } else {
                const players = transformTeamPlayersToDropdownOptions(
                    matchFromProps.players || [],
                    matchFromProps.homeTeamId
                );
                homePlayers = players.home;
            }

            if (isUserAllowedOnAwayTeam) {
                const awayPlayersWrapped = await getTeamPlayersToDropdownOptions(
                    matchFromProps.awayTeamId
                );
                awayPlayers = awayPlayersWrapped.entities;
            } else {
                const players = transformTeamPlayersToDropdownOptions(
                    matchFromProps.players || [],
                    matchFromProps.homeTeamId
                );
                awayPlayers = players.away;
            }
        }

        this.setState(
            {
                homePlayers: homePlayers,
                awayPlayers: awayPlayers,
            },
            (): void => this.initializePlayerDropdowns()
        );

        this.setState({
            isLoading: false,
        });
    };

    private initializePlayerDropdowns = (): void => {
        const { matchFromProps } = this.props;
        const { homePlayers, awayPlayers } = this.state;

        let localHomePlayers = [...homePlayers];
        let localAwayPlayers = [...awayPlayers];

        let homePlayerDropdowns: DropdownOption[][] = [];
        let awayPlayerDropdowns: DropdownOption[][] = [];
        for (let i = 0; i < LINEUP_LIMIT; i += 1) {
            homePlayerDropdowns.push([]);
            awayPlayerDropdowns.push([]);
        }

        if (matchFromProps.players) {
            matchFromProps.players.forEach((matchPlayer: MatchPlayerVm): void => {
                const playerDropdownOption = transformMatchPlayerToDropdownOptions(matchPlayer);
                if (matchPlayer.number > 0 && matchPlayer.number <= LINEUP_LIMIT) {
                    if (matchPlayer.teamId === matchFromProps.homeTeamId) {
                        homePlayerDropdowns[matchPlayer.number - 1] = [playerDropdownOption];
                        localHomePlayers = this.findSelectedChangeDisabled(
                            localHomePlayers,
                            playerDropdownOption.id,
                            true
                        );
                    } else if (matchPlayer.teamId === matchFromProps.awayTeamId) {
                        awayPlayerDropdowns[matchPlayer.number - 1] = [playerDropdownOption];
                        localAwayPlayers = this.findSelectedChangeDisabled(
                            localAwayPlayers,
                            playerDropdownOption.id,
                            true
                        );
                    }
                }
            });
            this.setState({
                homePlayers: localHomePlayers,
                awayPlayers: localAwayPlayers,
                homePlayerDropdowns,
                awayPlayerDropdowns,
            });
        }
    };

    private clearAllDropdowns = (): void => {
        const initialPlayerDropdowns: DropdownOption[][] = [];
        for (let i = 0; i < LINEUP_LIMIT; i += 1) {
            initialPlayerDropdowns.push([]);
        }

        this.setState({
            homePlayers: [],
            awayPlayers: [],
            homePlayerDropdowns: initialPlayerDropdowns,
            awayPlayerDropdowns: initialPlayerDropdowns,
        });
    };

    private onSubmit = async (): Promise<void> => {
        const { userProfile, matchFromProps } = this.props;
        const { homePlayerDropdowns, awayPlayerDropdowns } = this.state;

        this.setState({
            isSaving: true,
        });

        const matchClient = new MatchesClient();

        const matchPlayers = await createMatchPlayerCommandFromDropdowns(
            userProfile,
            matchFromProps,
            homePlayerDropdowns,
            awayPlayerDropdowns
        );

        const request = new CreateOrUpdateMatchPlayersCommand({
            matchId: matchFromProps.id,
            matchPlayers,
        });

        try {
            const result = await matchClient.updatePlayers(matchFromProps.id, request);

            if (result) {
                this.handleSuccessfullySaving();
            }
        } catch (error) {
            if (error instanceof ApiException) {
                errorWithUserMessage(error.response);
            } else {
                errorWithUserMessage('Error saving data.');
            }
        }

        this.setState({
            isSaving: false,
        });
    };

    private handleSuccessfullySaving = (): void => {
        const { refreshAfterSave } = this.props;

        success('Lineup successfully saved.');
        if (refreshAfterSave) {
            refreshAfterSave().then((): void => this.refreshLineups());
        }
    };

    private refreshLineups = (): void => {
        const { matchFromProps } = this.props;

        this.clearAllDropdowns();
        this.handleDrawerMode(DrawerState.Closed, matchFromProps.id.toString());
    };

    private handleDrawerMode = (drawerState: DrawerState, id?: string): void => {
        const { history } = this.props;

        this.setState(
            {
                drawerState,
                isFormDirty: false,
            },
            (): Promise<void> => this.mapPlayers()
        );

        history.push(
            getNewRoute(
                Routes.ROUTE_MATCH_LINEUPS,
                Routes.ROUTE_MATCH_LINEUPS,
                Routes.ROUTE_MATCH_LINEUPS_EDIT,
                drawerState,
                ReplaceStrings.MATCH_ID,
                id
            )
        );
    };

    private openForm = (): void => {
        const { matchFromProps } = this.props;
        this.handleDrawerMode(DrawerState.Edit, matchFromProps.id.toString());
    };

    private setNewHomePlayers = (
        selectedNewValues: DropdownOption[],
        selectedOldValues?: DropdownOption[]
    ): void => {
        const { homePlayers, isFormDirty } = this.state;
        if (selectedNewValues && selectedNewValues.length > 0) {
            this.setState({
                homePlayers: this.findSelectedChangeDisabled(
                    homePlayers,
                    selectedNewValues[0].id,
                    true
                ),
            });
        } else if (selectedOldValues && selectedOldValues.length > 0) {
            this.setState({
                homePlayers: this.findSelectedChangeDisabled(
                    homePlayers,
                    selectedOldValues[0].id,
                    false
                ),
            });
        }
        if (!isFormDirty) {
            this.setState({
                isFormDirty: true,
            });
        }
    };

    private setNewAwayPlayers = (
        selectedNewValues: DropdownOption[],
        selectedOldValues?: DropdownOption[]
    ): void => {
        const { awayPlayers, isFormDirty } = this.state;
        if (selectedNewValues && selectedNewValues.length > 0) {
            this.setState({
                awayPlayers: this.findSelectedChangeDisabled(
                    awayPlayers,
                    selectedNewValues[0].id,
                    true
                ),
            });
        } else if (selectedOldValues && selectedOldValues.length > 0) {
            this.setState({
                awayPlayers: this.findSelectedChangeDisabled(
                    awayPlayers,
                    selectedOldValues[0].id,
                    false
                ),
            });
        }
        if (!isFormDirty) {
            this.setState({
                isFormDirty: true,
            });
        }
    };

    private findSelectedChangeDisabled = (
        players: DropdownOption[],
        selectedPlayerId: string,
        isDisabled: boolean
    ): DropdownOption[] =>
        players.map(
            (player: DropdownOption): DropdownOption => {
                if (player.id === selectedPlayerId) {
                    return {
                        ...player,
                        disabled: isDisabled,
                    };
                } else {
                    return player;
                }
            }
        );

    private resetAfterCancel = (drawerState: DrawerState): void => {
        const { matchFromProps } = this.props;
        this.handleDrawerMode(
            drawerState,
            matchFromProps ? matchFromProps.id.toString() : undefined
        );
        this.clearAllDropdowns();
    };

    private updateDropdownValue = (
        newValue: DropdownOption[],
        index: number,
        side: 'home' | 'away'
    ): void => {
        if (side === 'home') {
            const { homePlayerDropdowns } = this.state;
            this.setNewHomePlayers(newValue, homePlayerDropdowns[index]);
            const newDropdowns = [...homePlayerDropdowns];
            newDropdowns.splice(index, 1, newValue);
            this.setState({
                homePlayerDropdowns: newDropdowns,
            });
        } else {
            const { awayPlayerDropdowns } = this.state;
            this.setNewAwayPlayers(newValue, awayPlayerDropdowns[index]);
            const newDropdowns = [...awayPlayerDropdowns];
            newDropdowns.splice(index, 1, newValue);
            this.setState({
                awayPlayerDropdowns: newDropdowns,
            });
        }
    };

    public render(): React.ReactElement {
        const { userProfile, matchFromProps } = this.props;
        const {
            drawerState,
            isSaving,
            isLoading,
            homePlayers,
            awayPlayers,
            isFormDirty,
            homePlayerDropdowns,
            awayPlayerDropdowns,
            isUserAllowedOnAwayTeam,
            isUserAllowedOnHomeTeam,
        } = this.state;

        const homeDisabled = !drawerState || !isUserAllowedOnHomeTeam;
        const awayDisabled = !drawerState || !isUserAllowedOnAwayTeam;
        const actionsDisabled = matchFromProps.statusId !== MatchStatusEnum.Not_Started || !(isUserAllowedOnHomeTeam || isUserAllowedOnAwayTeam)

        return (
            <Spin spinning={isLoading}>
                {!!drawerState && (
                    <DrawerButtons
                        isSaving={isSaving}
                        isFormDirty={isFormDirty}
                        onSubmitAction={this.onSubmit}
                        onCancelAction={(): void => this.resetAfterCancel(DrawerState.Closed)}
                    />
                )}
                {!drawerState && (
                    <DetailsButtons
                        userProfile={userProfile}
                        onEditAction={this.openForm}
                        disabled={actionsDisabled}
                        moduleName={ModuleName.Matches}
                        entityId={matchFromProps.id}
                        editTooltip={actionsDisabled ? "Lineups can only be edited before the match starts" : undefined}
                    />
                )}
                <Row className="details-height">
                    <Col span={12}>
                        {homePlayerDropdowns.map(
                            (hPD: DropdownOption[], index: number): React.ReactElement => (
                                <Form key={`home-team-${index}`}>
                                    <Row>
                                        <Col span={dropdownColumnSize}>
                                            <Form.Item name="homeTeam">
                                                <AutocompleteDropdown
                                                    getOptionsFrontend={(
                                                        value: string
                                                    ): DropdownOption[] =>
                                                        filterDropdownOptions(value, homePlayers)
                                                    }
                                                    initialValues={hPD}
                                                    confirmDirty={(
                                                        options: DropdownOption[]
                                                    ): void => {
                                                        this.updateDropdownValue(
                                                            options,
                                                            index,
                                                            'home'
                                                        );
                                                    }}
                                                    isAllowedToClear
                                                    placeholder={
                                                        homeDisabled ? undefined : 'Choose player'
                                                    }
                                                    showArrow={!homeDisabled}
                                                    style={{ width: '100%' }}
                                                    disabled={homeDisabled}
                                                    className={homeDisabled ? "read-mode-view" : undefined}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={numberColumnSize}>
                                            <Tag className="number-tag number-tag-home">
                                                {hPD.length > 0 && hPD[0].capNumber ? hPD[0].capNumber : index + 1}
                                            </Tag>
                                        </Col>
                                    </Row>
                                </Form>
                            )
                        )}
                    </Col>
                    <Col span={12}>
                        {awayPlayerDropdowns.map(
                            (aPD: DropdownOption[], index: number): React.ReactElement => (
                                <Form key={`away-team-${index}`}>
                                    <Row>
                                        <Col span={restColumnSize} />
                                        <Col span={numberColumnSize}>
                                            <Tag className="number-tag number-tag-away">
                                                {aPD.length > 0 && aPD[0].capNumber ? aPD[0].capNumber : index + 1}
                                            </Tag>
                                        </Col>
                                        <Col span={dropdownColumnSize}>
                                            <Form.Item name="awayTeam">
                                                <AutocompleteDropdown
                                                    getOptionsFrontend={(
                                                        value: string
                                                    ): DropdownOption[] =>
                                                        filterDropdownOptions(value, awayPlayers)
                                                    }
                                                    initialValues={aPD}
                                                    confirmDirty={(
                                                        options: DropdownOption[]
                                                    ): void => {
                                                        this.updateDropdownValue(
                                                            options,
                                                            index,
                                                            'away'
                                                        );
                                                    }}
                                                    isAllowedToClear
                                                    placeholder={
                                                        awayDisabled ? undefined : 'Choose player'
                                                    }
                                                    showArrow={!awayDisabled}
                                                    style={{ width: '100%' }}
                                                    disabled={awayDisabled}
                                                    className={awayDisabled ? "read-mode-view" : undefined}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            )
                        )}
                    </Col>
                </Row>
            </Spin>
        );
    }
}

export default MatchLineups;
