import { RouteComponentProps, withRouter } from 'react-router-dom';
import { DrawerState } from '../../../core/models/enum';
import { ClubFormInitialValues } from '../../../core/models/InitialValues';
import { ClubDetailVm } from '../../../utils/api';
import { RouteParams } from '../../../core/models/RouteHelper';
import ClubForm from './ClubForm';

interface OwnProps {
    club?: ClubDetailVm;
    initialValues?: ClubFormInitialValues;
    handleClose: (drawerMode: DrawerState, id?: string) => void;
    refreshAfterSave?: () => void;
}

export type Props = OwnProps & RouteComponentProps<RouteParams>;

export default withRouter(ClubForm);
