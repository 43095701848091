import { PlusOutlined } from '@ant-design/icons';
import { Button, Drawer, PageHeader } from 'antd';
import React from 'react';
import { DrawerWidth } from '../../config/dimensions';
import Routes from '../../config/routes';
import ReplaceStrings from '../../config/replaceStrings';
import { ActionOption, DrawerState, ModuleName } from '../../core/models/enum';
import { isUserAllowed } from '../../helpers/CheckPermissionHelper';
import { getNewRoute } from '../../helpers/RoutingHelper';
import { Props } from './index';
import MatchForm from './match-form';
import MatchTable from './match-table';

interface State {
    drawerState: DrawerState;
    allowCreate: boolean;
}

class Matches extends React.PureComponent<Props, State> {
    private tableRef: any;

    public constructor(props: Props) {
        super(props);

        const {
            location: { pathname },
        } = window;

        this.tableRef = React.createRef();

        this.state = {
            drawerState:
                pathname === Routes.ROUTE_MATCHES_CREATE ? DrawerState.Edit : DrawerState.Closed,
            allowCreate: isUserAllowed(props.userProfile, ModuleName.Matches, ActionOption.Create),
        };
    }

    private handleDrawerMode = (drawerState: DrawerState, id?: string): void => {
        const { history } = this.props;

        this.setState({
            drawerState,
        });

        history.push(
            getNewRoute(
                Routes.ROUTE_MATCHES,
                Routes.ROUTE_MATCHES_READ,
                Routes.ROUTE_MATCHES_CREATE,
                drawerState,
                ReplaceStrings.MATCH_ID,
                id
            )
        );
    };

    private updateTable = (): void => {
        this.tableRef.current.getMatches();
    };

    public render(): React.ReactElement {
        const { userProfile } = this.props;
        const { drawerState, allowCreate } = this.state;

        return (
            <>
                <PageHeader
                    title="Matches"
                    extra={
                        allowCreate
                            ? [
                                  <Button
                                      key="1"
                                      type="primary"
                                      style={{
                                          zIndex: 10,
                                          float: 'right',
                                      }}
                                      onClick={(): void => this.handleDrawerMode(DrawerState.Edit)}
                                  >
                                      <PlusOutlined className="button-icon-display" />
                                      Add match
                                  </Button>,
                              ]
                            : []
                    }
                    style={{ padding: '16px 0px' }}
                />

                <MatchTable userProfile={userProfile} wrappedComponentRef={this.tableRef} />

                <Drawer
                    title="Add match"
                    visible={!!drawerState}
                    onClose={(): void => this.handleDrawerMode(DrawerState.Closed)}
                    width={DrawerWidth}
                    destroyOnClose
                >
                    <MatchForm
                        handleClose={this.handleDrawerMode}
                        refreshAfterSave={this.updateTable}
                    />
                </Drawer>
            </>
        );
    }
}

export default Matches;
