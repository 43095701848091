import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RouteParams } from '../../../../core/models/RouteHelper';
import StandingsTable from './StandingsTable';
import { Profile } from 'oidc-client';
import { StandingsVm } from '../../../../utils/api';

interface OwnProps {
    userProfile: Profile;
    standings: StandingsVm;
    isLoading: boolean;
}

export type Props = OwnProps & RouteComponentProps<RouteParams>;

export default withRouter(StandingsTable);