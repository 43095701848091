import { EventVm, MatchCoachVm, MatchPlayerVm, MatchVm } from '../../../../utils/api';
import LogItem from './LogItem';

export interface Props {
    event: EventVm;
    coaches: MatchCoachVm[];
    lineup?: MatchPlayerVm;
    teamId?: number;
    match: MatchVm;
}

export default LogItem;
