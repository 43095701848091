import { Table } from 'antd';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import Routes from '../../../config/routes';
import ReplaceStrings from '../../../config/replaceStrings';
import { ModuleName } from '../../../core/models/enum';
import {
    getAllowedEntities,
    isUserAdmin,
    isUserFederation,
    isUserOfficial,
} from '../../../helpers/CheckPermissionHelper';
import { convertUTCTimeToLocal } from '../../../helpers/DateHelper';
import { PaginationOptions } from '../../../helpers/PaginationHelper';
import { MatchOfficialVm, MatchVm } from '../../../utils/api';
import { Props } from './index';

interface State {
    matches: MatchOfficialVm[];
    loading: boolean;
    allowedEntities: number[];
    isAdmin: boolean;
    isFederation: boolean;
    isOfficial: boolean;
}

class OfficialMatchTable extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            matches: [],
            loading: true,
            allowedEntities: [],
            isAdmin: false,
            isFederation: false,
            isOfficial: false,
        };
    }

    public componentDidMount = (): void => {
        this.getPermissions();
        this.getMatches();
    };

    private getPermissions = (): void => {
        const { userProfile } = this.props;

        this.setState({
            allowedEntities: getAllowedEntities(userProfile, ModuleName.Matches),
            isAdmin: isUserAdmin(userProfile),
            isFederation: isUserFederation(userProfile),
            isOfficial: isUserOfficial(userProfile),
        });
    };

    private getMatches = async (): Promise<void> => {
        const { matchesFromProps } = this.props;

        this.setState({
            matches: matchesFromProps || [],
            loading: false,
        });
    };

    public render(): React.ReactElement {
        const { matches, loading, allowedEntities, isAdmin, isFederation, isOfficial } = this.state;

        const columns = [
            {
                title: 'Home team',
                dataIndex: 'match',
                key: 'match',
                render: (value?: MatchVm): string => (value ? value.homeTeamDisplayName || '' : ''),
            },
            {
                title: 'Away team',
                dataIndex: 'match',
                key: 'match',
                render: (value?: MatchVm): string => (value ? value.awayTeamDisplayName || '' : ''),
            },
            {
                title: 'Score',
                dataIndex: 'match',
                key: 'match',
                render: (value: MatchVm): string =>
                    value ? `${value.homeTeamGoalsTotal} - ${value.awayTeamGoalsTotal}` : '',
            },
            {
                title: 'Time',
                dataIndex: 'match',
                key: 'match',
                render: (value: MatchVm): string =>
                    value && value.startDate
                        ? moment(convertUTCTimeToLocal(value.startDate)).format('HH:mm')
                        : '',
                width: 110,
            },
            {
                title: 'Date',
                dataIndex: 'match',
                key: 'match',
                render: (value: MatchVm): string =>
                    value && value.startDate
                        ? moment(convertUTCTimeToLocal(value.startDate)).format('DD.MM.YYYY.')
                        : '',
                width: 110,
            },
            {
                title: 'Competition',
                dataIndex: 'match',
                key: 'match',
                render: (value: MatchVm): string =>
                    value && value.competitionDisplayName ? value.competitionDisplayName : '',
            },
            {
                title: 'Venue',
                dataIndex: 'match',
                key: 'match',
                render: (value: MatchVm): string => (value && value.venue ? value.venue : ''),
            },
            {
                title: 'Round',
                dataIndex: 'match',
                key: 'match',
                render: (value: MatchVm): string => (value && value.round ? value.round : ''),
            },
            {
                title: 'Status',
                dataIndex: 'match',
                key: 'match',
                render: (value: MatchVm): string =>
                    value && value.status ? value.status.replace('_', ' ') : '',
            },
            {
                title: 'Role',
                dataIndex: 'role',
                key: 'role',
                render: (value: string): string => (value ? value.replace('_', ' ') : ''),
            },
            {
                dataIndex: 'match',
                key: 'match',
                render: (value: MatchVm): React.ReactElement => {
                    const hasReadAccess = allowedEntities.some(
                        (ent: number): boolean => ent === value.id
                    );
                    const hasDetailsAccess = isAdmin || hasReadAccess;
                    const hasLogAccess =
                        isAdmin || (isFederation && hasReadAccess) || (isOfficial && hasReadAccess);

                    return (
                        <div style={{ display: 'inline-flex' }}>
                            {hasDetailsAccess && (
                                <Link
                                    to={Routes.ROUTE_MATCHES_READ.replace(
                                        ReplaceStrings.MATCH_ID,
                                        value.id.toString()
                                    )}
                                >
                                    Details
                                </Link>
                            )}
                            &nbsp;
                            {hasLogAccess && (
                                <Link
                                    to={Routes.ROUTE_MATCH_LOG.replace(
                                        ReplaceStrings.MATCH_ID,
                                        value.id.toString()
                                    )}
                                >
                                    Log
                                </Link>
                            )}
                        </div>
                    );
                },
                width: 80,
            },
        ];

        return (
            <Table
                columns={columns}
                loading={loading}
                dataSource={matches}
                rowKey={(record): string => (record && record.id ? record.id.toString() : '-1')}
                pagination={PaginationOptions}
                bordered
                size="small"
            />
        );
    }
}

export default OfficialMatchTable;
