import { DeleteOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import React from 'react';
import { Props } from '.';
import CardIcon from '../../../components/card-icon/CardIcon';
import Icon from '../../../components/icon/Icon';
import { getPlayerNumberFromPlayerLineup, getTeamId } from '../../../helpers/EventHelper';
import {
    CoachRoleEnum,
    CreateMatchPlayerCommandOfMatchPlayer,
    EventTypeEnum,
    EventVm,
    ExclusionTypeEnum,
    MatchCoachVm,
    ShotTypeEnum,
    TurnoverTypeEnum,
} from '../../../utils/api';

class MatchActionBar extends React.PureComponent<Props> {
    private renderActionButtons = (): React.ReactElement | null => {
        const { isHomeTeam, event, lineups, coaches, match } = this.props;

        const teamColor = isHomeTeam ? 'white' : 'blue';
        const otherTeamColor = isHomeTeam ? 'blue' : 'white';

        switch (event.typeId) {
            case EventTypeEnum.Timeout:
                return (
                    <span className={`c-action-bar__icons c-action-bar__to is-${teamColor}-team`}>
                        TO
                    </span>
                );
            case EventTypeEnum.Challenge:
                return (
                    <span className={`c-action-bar__icons c-action-bar__to is-${teamColor}-team`}>
                        CH
                    </span>
                );
            case EventTypeEnum.Turnover: {
                const turnoverType = event.turnover?.typeId;
                const stolenByLineup = lineups.find(
                    (l: CreateMatchPlayerCommandOfMatchPlayer): boolean =>
                        l.id === event.turnover?.wonPossesionPlayerId
                );
                const lostBallLineup = lineups.find(
                    (l: CreateMatchPlayerCommandOfMatchPlayer): boolean =>
                        l.id === event.turnover?.lostPossesionPlayerId
                );

                let turnoverTypeIcon;
                switch (turnoverType) {
                    case TurnoverTypeEnum.Lost_Ball:
                        turnoverTypeIcon = <Icon name="icon-user-times" />;
                        break;
                    case TurnoverTypeEnum.Offensive_Foul:
                        turnoverTypeIcon = <Icon name="icon-user-ninja" />;
                        break;
                    case TurnoverTypeEnum.Ball_Under:
                        turnoverTypeIcon = <Icon name="icon-volleyball-ball" />;
                        break;
                    case TurnoverTypeEnum.Clock_Expired:
                        turnoverTypeIcon = <Icon name="icon-stopwatch" />;
                        break;
                    default:
                        break;
                }

                return (
                    <span className="c-action-bar__icons">
                        <i className={`c-action-bar__icon c-action-bar__icon--${teamColor}`}>
                            <Icon name="icon-share" />
                        </i>
                        <i className={`c-action-bar__icon c-action-bar__icon--${teamColor}`}>
                            <span className="fake-svg">
                                {lostBallLineup ? lostBallLineup.capNumber || lostBallLineup.number : ''}
                            </span>
                        </i>
                        <i className={`c-action-bar__icon c-action-bar__icon--${teamColor}`}>
                            {turnoverTypeIcon}
                        </i>
                        <i className={`c-action-bar__icon c-action-bar__icon--${otherTeamColor}`}>
                            <Icon name="icon-hand-paper" />
                            <span className="fake-svg">
                                {stolenByLineup ? stolenByLineup.capNumber || stolenByLineup.number : ''}
                            </span>
                        </i>
                    </span>
                );
            }
            case EventTypeEnum.Card: {
                const cardedCoach = coaches?.find(
                    (c: MatchCoachVm): boolean => event.card?.cardedCoachId === c.id
                );
                const shortCardedCoach =
                    cardedCoach && cardedCoach.roleId === CoachRoleEnum.Head_Coach ? 'HC' : 'AC';

                return (
                    <span className="c-action-bar__icons">
                        <i className={`c-action-bar__icon c-action-bar__icon--${teamColor}`}>
                            <Icon name="icon-edit-solid" />
                        </i>
                        <i className={`c-action-bar__icon c-action-bar__icon--${teamColor}`}>
                            <span className="fake-svg">{shortCardedCoach}</span>
                        </i>
                        <i className={`c-action-bar__icon c-action-bar__icon--${teamColor}`}>
                            <span className="fake-svg">
                                {event.card?.typeId && <CardIcon type={event.card?.typeId} />}
                            </span>
                        </i>
                    </span>
                );
            }
            case EventTypeEnum.Swim_Off: {
                const playerNumber = getPlayerNumberFromPlayerLineup(event, lineups);
                const teamId = getTeamId(event);
                const homeTeamPlayerLineup = lineups.find(
                    (l: CreateMatchPlayerCommandOfMatchPlayer): boolean =>
                        l.number === playerNumber &&
                        l.teamId === match?.homeTeamId &&
                        match?.homeTeamId === teamId
                );
                const awayTeamPlayerLineup = lineups.find(
                    (l: CreateMatchPlayerCommandOfMatchPlayer): boolean =>
                        l.number === playerNumber &&
                        l.teamId === match?.awayTeamId &&
                        match?.awayTeamId === teamId
                );

                let winnerTeamColor = 'grey';
                if (event.swimoff?.winnerSwimmerId) {
                    const winnerId = event.swimoff?.winnerSwimmerId;
                    const winnerPlayerLineup = lineups.find(
                        (l: CreateMatchPlayerCommandOfMatchPlayer): boolean => l.id === winnerId
                    );
                    if (winnerPlayerLineup) {
                        if (
                            homeTeamPlayerLineup &&
                            homeTeamPlayerLineup.id === winnerPlayerLineup.id
                        ) {
                            winnerTeamColor = 'white';
                        }
                        if (
                            awayTeamPlayerLineup &&
                            awayTeamPlayerLineup.id === winnerPlayerLineup.id
                        ) {
                            winnerTeamColor = 'blue';
                        }
                    }
                }

                return (
                    <span className="c-action-bar__icons">
                        <i className="c-action-bar__icon c-action-bar__icon--white">
                            <span className="fake-svg">
                                {homeTeamPlayerLineup ? homeTeamPlayerLineup.capNumber || homeTeamPlayerLineup.number : ''}
                            </span>
                        </i>
                        <i className={`c-action-bar__icon c-action-bar__icon--${winnerTeamColor}`}>
                            <Icon name="icon-swimmer" />
                        </i>
                        <i className="c-action-bar__icon c-action-bar__icon--blue">
                            <span className="fake-svg">
                                {awayTeamPlayerLineup ? awayTeamPlayerLineup.capNumber || awayTeamPlayerLineup.number : ''}
                            </span>
                        </i>
                    </span>
                );
            }
            case EventTypeEnum.Exclusion: {
                const foulerPlayerLineup = lineups.find(
                    (l: CreateMatchPlayerCommandOfMatchPlayer): boolean =>
                        l.number === getPlayerNumberFromPlayerLineup(event, lineups)
                );
                const fouledPlayerId = event.exclusion?.fouledPlayerId;
                const fouledPlayerLineup = lineups.find(
                    (l: CreateMatchPlayerCommandOfMatchPlayer): boolean => l.id === fouledPlayerId
                );
                const cardedCoach = coaches?.find(
                    (c: MatchCoachVm): boolean => event.exclusion?.excludedCoachId === c.id
                );
                const shortCardedCoach =
                    cardedCoach && cardedCoach.roleId === CoachRoleEnum.Head_Coach ? 'HC' : 'AC';

                const exclusionLocationX = event.exclusion?.locationX;
                const exclusionLocationY = event.exclusion?.locationY;

                const isDoubleExclusion = event.exclusion?.isDoubleExclusion;
                const isPenaltyExclusion = event.exclusion?.isPenaltyExclusion;

                let exclusionTypeIcon;
                switch (event.exclusion?.typeId) {
                    case ExclusionTypeEnum.Exclusion:
                        exclusionTypeIcon = <Icon name="icon-times" />;
                        break;
                    case ExclusionTypeEnum.Penalty:
                        exclusionTypeIcon = <span className="fake-svg">5m</span>;
                        break;
                    case ExclusionTypeEnum.Suspension:
                        exclusionTypeIcon = <Icon name="icon-ban" />;
                        break;
                    case ExclusionTypeEnum.Brutality:
                        exclusionTypeIcon = <Icon name="icon-user-times" />;
                        break;
                    default:
                        break;
                }

                return (
                    <span className="c-action-bar__icons">
                        <i className={`c-action-bar__icon c-action-bar__icon--${teamColor}`}>
                            <Icon name="icon-times" />
                        </i>
                        <i className={`c-action-bar__icon c-action-bar__icon--${teamColor}`}>
                            <span className="fake-svg">
                                {foulerPlayerLineup
                                    ? foulerPlayerLineup.capNumber || foulerPlayerLineup.number
                                    : shortCardedCoach || ''}
                            </span>
                        </i>
                        <i className={`c-action-bar__icon c-action-bar__icon--${teamColor}`}>
                            {exclusionTypeIcon}
                        </i>

                        <i className={`c-action-bar__icon c-action-bar__icon--${otherTeamColor}`}>
                            <span className="fake-svg">
                                {fouledPlayerLineup ? fouledPlayerLineup.capNumber || fouledPlayerLineup.number : ''}
                            </span>
                        </i>
                        <i className={`c-action-bar__icon c-action-bar__icon--${teamColor}`}>
                            {isDoubleExclusion && <Icon name="icon-arrows-alt-h" />}
                        </i>
                        <i className={`c-action-bar__icon c-action-bar__icon--${teamColor}`}>
                            <span className="fake-svg">{isPenaltyExclusion ? 'PE' : ''}</span>
                        </i>
                        <i className={`c-action-bar__icon c-action-bar__icon--${teamColor}`}>
                            {exclusionLocationX && exclusionLocationY && (
                                <Icon name="icon-map-marker-alt" />
                            )}
                        </i>
                    </span>
                );
            }
            case EventTypeEnum.Shot: {
                const playerLineup = lineups.find(
                    (l: CreateMatchPlayerCommandOfMatchPlayer): boolean =>
                        l.number === getPlayerNumberFromPlayerLineup(event, lineups) &&
                        getTeamId(event) === l.teamId
                );

                const blockedByPlayerId = event.shot?.blockedById;
                const blockedByPlayerLineup = lineups.find(
                    (l: CreateMatchPlayerCommandOfMatchPlayer): boolean =>
                        l.id === blockedByPlayerId
                );

                const assistedById = event.shot?.assistedById;
                const assistedByPlayerLineup = lineups.find(
                    (l: CreateMatchPlayerCommandOfMatchPlayer): boolean => l.id === assistedById
                );

                const isFastBreak = event.shot?.isFastBreak;
                const isDirectFromFoul = event.shot?.isDirectFromFoul;

                const shotLocationX = event.shot?.locationX;
                const shotLocationY = event.shot?.locationY;
                const shotTargetX = event.shot?.targetX;
                const shotTargetY = event.shot?.targetY;

                let detailIcon = null;

                switch (event.shot?.typeId) {
                    case ShotTypeEnum.Regular_Attack:
                        detailIcon = <Icon name="icon-clock" />;
                        break;
                    case ShotTypeEnum.Power_Play:
                        detailIcon = <Icon name="icon-user-friends" />;
                        break;
                    case ShotTypeEnum.Penalty:
                    case ShotTypeEnum.Penalty_Shootout:
                        detailIcon = <span className="fake-svg">5m</span>;
                        break;
                    default:
                        break;
                }

                return (
                    <span className="c-action-bar__icons">
                        <i className={`c-action-bar__icon c-action-bar__icon--${teamColor}`}>
                            <span className="fake-svg">
                                {playerLineup ? playerLineup.capNumber || playerLineup.number : ''}
                            </span>
                        </i>
                        <i className={`c-action-bar__icon c-action-bar__icon--${teamColor}`}>
                            <Icon name="icon-volleyball-ball" />
                        </i>
                        <i className={`c-action-bar__icon c-action-bar__icon--${teamColor}`}>
                            {detailIcon}
                        </i>
                        <i className={`c-action-bar__icon c-action-bar__icon--${teamColor}`}>
                            {isFastBreak && <Icon name="icon-bolt" />}
                        </i>
                        <i className={`c-action-bar__icon c-action-bar__icon--${teamColor}`}>
                            {isDirectFromFoul && <span className="fake-svg">6m</span>}
                        </i>
                        <i className={`c-action-bar__icon c-action-bar__icon--${teamColor}`}>
                            {shotLocationX && shotLocationY && <Icon name="icon-map-marker-alt" />}
                        </i>
                        <i className={`c-action-bar__icon c-action-bar__icon--${teamColor}`}>
                            {shotTargetX && shotTargetY && <Icon name="icon-crosshair" />}
                        </i>
                        <i className={`c-action-bar__icon c-action-bar__icon--${otherTeamColor}`}>
                            <span className="fake-svg">
                                {blockedByPlayerLineup ? blockedByPlayerLineup.capNumber || blockedByPlayerLineup.number : ''}
                            </span>
                        </i>
                        <i className={`c-action-bar__icon c-action-bar__icon--${teamColor}`}>
                            <span className="fake-svg">
                                {assistedByPlayerLineup ? assistedByPlayerLineup.capNumber || assistedByPlayerLineup.number : ''}
                            </span>
                        </i>
                    </span>
                );
            }
            default:
                return null;
        }
    };

    public render = (): React.ReactElement => {
        const { event, clearEvent, deleteEvent } = this.props;
        const mappedEvent = new EventVm({ ...(event as EventVm) });
        const eventExists = !!mappedEvent.id;

        return (
            <div className="o-action-bar">
                <div className="c-action-bar">
                    <span
                        style={{
                            fontFamily: 'Josefin Sans',
                            fontSize: '20px',
                            color: 'white',
                        }}
                    >
                        {eventExists ? 'Edit event' : 'Create event'}
                    </span>
                    <div className="c-action-bar__main">{this.renderActionButtons()}</div>
                    {eventExists && (
                        <Popconfirm
                            title="Are you sure you want to delete this event?"
                            onConfirm={(): void => deleteEvent(mappedEvent)}
                            okText="Delete"
                            okButtonProps={{
                                danger: true,
                                className: 'modal-delete-button',
                            }}
                            cancelText="Cancel"
                            cancelButtonProps={{
                                className: 'modal-cancel-button',
                            }}
                        >
                            <i className="c-action-bar__delete" role="button" title="Delete">
                                <DeleteOutlined />
                            </i>
                        </Popconfirm>
                    )}
                    <i
                        onClick={(): void => clearEvent()}
                        className="c-action-bar__close"
                        role="button"
                        title="Close"
                    >
                        <Icon name="icon-times" />
                    </i>
                </div>
            </div>
        );
    };
}

export default MatchActionBar;
