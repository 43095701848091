import { DrawerState } from '../core/models/enum';

export const getNewRoute = (
    mainRoute: string,
    readRoute: string,
    editRoute: string,
    drawerState: DrawerState,
    stringToReplace?: string,
    id?: string
): string => {
    switch (drawerState) {
        case DrawerState.Closed:
            if (id && stringToReplace) {
                return mainRoute.replace(stringToReplace, id);
            }
            return mainRoute;
        case DrawerState.Read:
            if (id && stringToReplace) {
                return readRoute.replace(stringToReplace, id);
            }
            return mainRoute;
        case DrawerState.Edit:
            if (id && stringToReplace) {
                return editRoute.replace(stringToReplace, id ? id : '0');
            }
            return editRoute;
        default:
            return mainRoute;
    }
};

export const getNewSubRoute = (
    mainRoute: string,
    readRoute: string,
    editRoute: string,
    createRoute: string,
    drawerState: DrawerState,
    firstStringToReplace?: string,
    secondStringToReplace?: string,
    firstId?: string,
    secondId?: string
): string => {
    switch (drawerState) {
        case DrawerState.Closed:
            if (firstId && firstStringToReplace) {
                return mainRoute.replace(firstStringToReplace, firstId);
            }
            return mainRoute;
        case DrawerState.Read:
            if (firstId && secondId && firstStringToReplace && secondStringToReplace) {
                return readRoute
                    .replace(firstStringToReplace, firstId)
                    .replace(secondStringToReplace, secondId);
            }
            return mainRoute;
        case DrawerState.Edit:
            if (firstId && secondId && firstStringToReplace && secondStringToReplace) {
                return editRoute
                    .replace(firstStringToReplace, firstId)
                    .replace(secondStringToReplace, secondId);
            }
            return editRoute;
        case DrawerState.Create:
            if (firstId && firstStringToReplace) {
                return createRoute.replace(firstStringToReplace, firstId);
            }
            return createRoute;
        default:
            return mainRoute;
    }
};

export interface InitialRoutingProperties {
    initialEntityId?: string;
    isInitialEditMode: boolean;
    activeTabKey?: string;
}

export const getInitialProperties = (pathname: string): InitialRoutingProperties => {
    const urlParts = pathname.split('/');
    const lastPart = urlParts.pop();

    const isInitialEditMode = lastPart === 'edit';

    if (lastPart && !isInitialEditMode) {
        urlParts.push(lastPart);
    }

    const initialEntityId = urlParts.length > 2 ? urlParts[2] : undefined;

    return {
        initialEntityId,
        isInitialEditMode,
    };
};

export const getSubPageInitialProperties = (
    pathname: string,
    partPathname: string,
    match: any,
    readRoute: string,
    editRoute: string
): InitialRoutingProperties => {
    const urlParts = pathname.split('/');
    if (
        urlParts.length === 4 &&
        urlParts[1] === partPathname &&
        (urlParts[3] === 'edit' || urlParts[3] === 'create')
    ) {
        urlParts.splice(0, 1);
    } else if (urlParts.length > 3 && urlParts[1] === partPathname) {
        urlParts.splice(0, 3);
    } else {
        urlParts.splice(0, 1);
    }

    const lastPart = urlParts.pop();
    const isInitialEditMode = lastPart === 'edit' || lastPart === 'create';

    if (lastPart && !isInitialEditMode) {
        urlParts.push(lastPart);
    }
    const tabPart = urlParts.length > 0 ? `/${urlParts[0]}` : '';
    const fullTabRoute = tabPart !== `/${partPathname}` ? `${readRoute}${tabPart}` : match.path;

    const initialEntityId = urlParts.length > 1 ? urlParts[1] : undefined;

    const isEditRoute = fullTabRoute === editRoute;

    const activeTabKey = isEditRoute ? readRoute : fullTabRoute;

    return {
        initialEntityId,
        isInitialEditMode,
        activeTabKey,
    };
};

export const getActiveRouteName = (pathname: string): string => {
    const urlParts = pathname.split('/');
    if (urlParts && urlParts.length > 1) {
        return urlParts[1] === '' ? '/' : urlParts[1];
    } else if (urlParts && urlParts.length > 0) {
        return urlParts[0] === '' ? '/' : urlParts[0];
    }
    return '/';
};
