import { Table } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import React from 'react';
import { Link } from 'react-router-dom';
import Routes from '../../../config/routes';
import ReplaceStrings from '../../../config/replaceStrings';
import { ActionOption, ModuleName, SortDirectionEnum } from '../../../core/models/enum';
import { Filter } from '../../../core/models/Filter';
import { OfficialVmWithRoles } from '../../../core/models/OfficialVmWithRoles';
import {
    getAllowedEntities,
    isUserAdmin,
    isUserAllowed,
    isUserFederation,
} from '../../../helpers/CheckPermissionHelper';
import { buildFilterRequest, DefaultFilter, getSearchFilter } from '../../../helpers/FilterHelper';
import { PaginationOptions } from '../../../helpers/PaginationHelper';
import { feTableFilterHelper } from '../../../helpers/TableFilterHelper';
import { capitalizeFirstLetter } from '../../../helpers/TransformHelper';
import { OfficialsClient, OfficialVm, PaginatedListOfOfficialVm } from '../../../utils/api';
import { Props } from './index';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { popupConfirmDeleteWithCallback } from '../../../helpers/NotificationHelper';

interface State {
    officials: OfficialVmWithRoles[];
    paginationOptions: TablePaginationConfig;
    currentFilter: Filter;
    loading: boolean;
    allowedEntities: number[];
    isAdmin: boolean;
}

class OfficialTable extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            officials: [],
            paginationOptions: PaginationOptions,
            currentFilter: DefaultFilter,
            loading: true,
            allowedEntities: [],
            isAdmin: false,
        };
    }

    public componentDidMount = (): void => {
        this.getPermissions();
        this.getOfficials();
    };

    private getPermissions = (): void => {
        const { userProfile, officialsFromProps } = this.props;

        this.setState({
            allowedEntities: !officialsFromProps
                ? getAllowedEntities(userProfile, ModuleName.Officials)
                : isUserFederation(userProfile)
                    ? getAllowedEntities(userProfile, ModuleName.Officials)
                    : [],
            isAdmin: isUserAdmin(userProfile),
        });
    };

    private getOfficials = async (currentFilterParam?: Filter): Promise<void> => {
        const { currentFilter } = this.state;
        const { officialsFromProps } = this.props;

        this.setState({
            loading: true,
        });

        let officialsPagedList;
        const filter = currentFilterParam || currentFilter;

        if (officialsFromProps) {
            officialsPagedList = this.getOfficialsFromProps(filter);
        } else {
            officialsPagedList = await this.getAllOfficials(filter);
        }

        this.setState({
            paginationOptions: {
                ...PaginationOptions,
                current: officialsPagedList.currentPage,
                pageSize: officialsPagedList.pageSize,
                total: officialsPagedList.totalItems,
            },
            officials: officialsPagedList?.pageItems || [],
            loading: false,
        });
    };

    private getAllOfficials = async (filter: Filter): Promise<PaginatedListOfOfficialVm> => {
        const officialClient = new OfficialsClient();

        return await officialClient.getPaginated(
            filter.filterRequest?.name,
            filter.filterRequest?.surname,
            filter.filterRequest?.countryOfBirth,
            filter.filterRequest?.cityOfBirth,
            filter.pageIndex,
            filter.pageSize,
            capitalizeFirstLetter(filter.orderByKey),
            filter.orderByValue
        );
    };

    private getOfficialsFromProps = (filter: Filter): PaginatedListOfOfficialVm => {
        const { officialsFromProps } = this.props;

        const filteredResult = feTableFilterHelper(officialsFromProps || [], filter);
        return new PaginatedListOfOfficialVm({
            ...filteredResult,
            pageItems: filteredResult.pageItems as OfficialVm[],
        });
    };

    private handleTableChange = (pagination: any, filters: any, sorter: any): void => {
        const filterRequest = buildFilterRequest(filters);

        const filter: Filter = {
            pageIndex: pagination.current,
            pageSize: pagination.pageSize,
            orderByKey: sorter?.columnKey && sorter?.order ? sorter.columnKey : undefined,
            orderByValue: sorter?.order ? sorter.order : undefined,
            filterRequest,
        };

        this.getOfficials(filter);
        this.setState({
            currentFilter: filter,
        });
    };

    private handleDeleteHelper = async (id?: number): Promise<any> => {
        const { onDelete } = this.props;

        if (onDelete) {
            onDelete(id);
        }
    };

    public render(): React.ReactElement {
        const {
            officials,
            loading,
            paginationOptions,
            allowedEntities,
            isAdmin,
        } = this.state;
        const { shouldDisplayRolesColumn, officialsFromProps, history, federationId, matchId, userProfile } = this.props;

        const columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                ...getSearchFilter(),
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: (): void => { },
            },
            {
                title: 'Surname',
                dataIndex: 'surname',
                key: 'surname',
                ...getSearchFilter(),
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: (): void => { },
            },
            {
                title: 'Country',
                dataIndex: 'countryOfBirth',
                key: 'countryOfBirth',
                ...getSearchFilter(),
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: (): void => { },
            },
            {
                title: 'City',
                dataIndex: 'cityOfBirth',
                key: 'cityOfBirth',
                ...getSearchFilter(),
                sortDirections: [SortDirectionEnum.ASC, SortDirectionEnum.DESC],
                sorter: (): void => { },
            },
        ];

        if (shouldDisplayRolesColumn) {
            columns.splice(4, 0, {
                title: 'Roles',
                dataIndex: 'roles',
                key: 'roles',
                render: (value?: string[]): string =>
                    value ? value.join(', ').replace('_', ' ') : '',
            });
        }

        if (!officialsFromProps) {
            columns.push({
                dataIndex: '',
                render: (value: OfficialVmWithRoles): React.ReactElement =>
                    value &&
                        value.id &&
                        (isAdmin ||
                        true || !!allowedEntities.find((ent: number): boolean => ent === value?.id)) ? (
                        <Link
                            to={Routes.ROUTE_OFFICIALS_READ.replace(
                                ReplaceStrings.OFFICIAL_ID,
                                value.id.toString()
                            )}
                        >
                            Details
                        </Link>
                    ) : (
                        <span />
                    ),
                width: 80,
            });
        } else {
            columns.push({
                title: '',
                dataIndex: '',
                key: '',
                render: (value: OfficialVmWithRoles): React.ReactElement =>
                    value && value.id ? (
                        <>
                            {isUserAllowed(
                                userProfile,
                                ModuleName.Officials,
                                ActionOption.Update,
                                value.id,
                            ) && (
                                    (isAdmin ||
                                    true || !!allowedEntities.find(
                                            (ent: number): boolean => ent === value?.id
                                        )) && (federationId ? (
                                            <EditOutlined
                                                onClick={(): void =>
                                                    history.push(
                                                        Routes.ROUTE_FEDERATION_OFFICIALS_EDIT.replace(
                                                            ReplaceStrings.OFFICIAL_ID,
                                                            value.id.toString()
                                                        ).replace(
                                                            ReplaceStrings.FEDERATION_ID,
                                                            federationId.toString()
                                                        )
                                                    )
                                                }
                                                style={{ color: '#1890ff' }}
                                            />
                                        ) : (
                                            matchId ?
                                                <EditOutlined
                                                    onClick={(): void =>
                                                        history.push(
                                                            Routes.ROUTE_MATCH_OFFICIALS_EDIT.replace(
                                                                ReplaceStrings.OFFICIAL_ID,
                                                                value.id.toString()
                                                            ).replace(
                                                                ReplaceStrings.MATCH_ID,
                                                                matchId.toString()
                                                            )
                                                        )
                                                    }
                                                    style={{ color: '#1890ff' }}
                                                /> : <span />
                                        )))}
                            {isUserAllowed(
                                userProfile,
                                ModuleName.Officials,
                                ActionOption.Delete,
                                value.id,
                            ) &&
                                (isAdmin ||
                                true || !!allowedEntities.find(
                                        (ent: number): boolean => ent === value?.id
                                    )) && (
                                    <DeleteOutlined
                                        onClick={(): void =>
                                            popupConfirmDeleteWithCallback(
                                                (): Promise<void> =>
                                                    this.handleDeleteHelper(value.id),
                                                undefined,
                                                'Are you sure you want to remove this official?',
                                                'Delete'
                                            )
                                        }
                                        className="text-danger-custom"
                                        style={{ marginLeft: 10 }}
                                    />
                                )}
                        </>
                    ) : (
                        <span />
                    ),
                width: 80,
            });
        }

        return (
            <Table
                columns={columns}
                loading={loading}
                dataSource={officials}
                rowKey={(record): string => (record && record.id ? record.id.toString() : '-1')}
                pagination={paginationOptions}
                onChange={this.handleTableChange}
                bordered
                size="small"
            />
        );
    }
}

export default OfficialTable;
