import {
    CreateMatchPlayerCommandOfMatchPlayer,
    EventTypeEnum,
    EventVm,
    MatchCoachVm,
    MatchDetailVm,
} from '../../../utils/api';
import MatchLineup from './MatchLineup';

interface OwnProps {
    match?: MatchDetailVm;
    lineup: CreateMatchPlayerCommandOfMatchPlayer[];
    isHomeTeam: boolean;
    events: EventVm[];
    coaches: MatchCoachVm[];
    initEvent: (type: EventTypeEnum, eventTypeValues?: any) => void;
    selectedKeeper: number;
    onPlayerSelect: (cap: number, teamId: number) => void;
}

export type Props = OwnProps;

export default MatchLineup;
