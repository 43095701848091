import { PlusOutlined } from '@ant-design/icons';
import { Button, Drawer, PageHeader } from 'antd';
import React from 'react';
import { DrawerWidth } from '../../config/dimensions';
import ReplaceStrings from '../../config/replaceStrings';
import Routes from '../../config/routes';
import { ActionOption, DrawerState, ModuleName } from '../../core/models/enum';
import { isUserAllowed } from '../../helpers/CheckPermissionHelper';
import { getNewRoute } from '../../helpers/RoutingHelper';
import { CitiesClient, CityVm } from '../../utils/api';
import CityForm from './city-form';
import CityTable from './city-table';
import { Props } from './index';

interface State {
    allowCreate: boolean;
    selectedCity?: CityVm;
    drawerState: DrawerState;
}

class Cities extends React.Component<Props, State> {
    private tableRef: any;

    public constructor(props: Props) {
        super(props);

        this.tableRef = React.createRef();

        this.state = {
            drawerState: DrawerState.Closed,
            allowCreate: isUserAllowed(
                props.userProfile,
                ModuleName.Cities,
                ActionOption.Create
            ),
        };
    }

    public componentDidMount = () => {
        const {
            match: {
                params: { cityId },
                path,
            },
        } = this.props;

        this.handleRouteUpdate(path, cityId);
    };

    public componentDidUpdate = (prevProps: Props) => {
        const {
            match: {
                params: { cityId },
                path,
            },
        } = this.props;
        if (path !== prevProps.match.path) {
            this.handleRouteUpdate(path, cityId);
        }
    };

    private updateDrawerStateFromPathName = (pathname: string): void => {
        switch (pathname) {
            case Routes.ROUTE_CITIES_CREATE:
                this.setState({
                    drawerState: DrawerState.Create,
                });
                return;
            case Routes.ROUTE_CITIES_EDIT:
                this.setState({
                    drawerState: DrawerState.Edit,
                });
                return;
            case Routes.ROUTE_CITIES_READ:
                this.setState({
                    drawerState: DrawerState.Read,
                });
                return;
            case Routes.ROUTE_CITIES:
                this.setState({
                    drawerState: DrawerState.Closed,
                });
                return;
            default:
                this.setState({
                    drawerState: DrawerState.Closed,
                });
                return;
        }
    };

    private handleRouteUpdate = async (pathname: string, entityId?: string): Promise<void> => {
        let selectedCity;
        const client = new CitiesClient();

        if (entityId) {
            selectedCity = await client.getById(parseInt(entityId, 10));
        }

        this.setState(
            {
                selectedCity,
            },
            (): void => this.updateDrawerStateFromPathName(pathname)
        );
    };

    private handleDrawerMode = (drawerState: DrawerState, id?: string): void => {
        const { history } = this.props;

        this.setState({
            drawerState,
        });

        history.push(
            getNewRoute(
                Routes.ROUTE_CITIES,
                Routes.ROUTE_CITIES_READ,
                Routes.ROUTE_CITIES_CREATE,
                drawerState,
                ReplaceStrings.CITY_ID,
                id
            )
        );
    };

    private updateTable = (): void => {
        this.tableRef.current.getInitialAllCities();
    };

    public render(): React.ReactElement {
        const { userProfile } = this.props;
        const { drawerState, selectedCity, allowCreate } = this.state;

        return (
            <div>
                <PageHeader
                    title="Cities"
                    extra={
                        allowCreate
                            ? [
                                <Button
                                    key="1"
                                    type="primary"
                                    style={{
                                        zIndex: 10,
                                        float: 'right',
                                    }}
                                    onClick={(): void => this.handleDrawerMode(DrawerState.Edit)}
                                    className="no-print"
                                >
                                    <PlusOutlined />
                                    Add city
                                </Button>,
                            ]
                            : []
                    }
                    style={{ padding: '16px 0px' }}
                />

                <CityTable userProfile={userProfile} wrappedComponentRef={this.tableRef} />

                {drawerState !== DrawerState.Closed && (
                    <Drawer
                        title={
                            drawerState === DrawerState.Create
                                ? "Add city"
                                : "Edit city"
                        }
                        visible={!!drawerState}
                        onClose={(): void => this.handleDrawerMode(DrawerState.Closed)}
                        width={DrawerWidth}
                        destroyOnClose
                    >
                        <CityForm
                            handleClose={(): void => this.handleDrawerMode(DrawerState.Closed)}
                            refreshAfterSave={this.updateTable}
                            city={selectedCity}
                        />
                    </Drawer>
                )}
            </div>
        );
    }
}

export default Cities;
