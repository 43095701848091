import { Radio } from 'antd';
import React from 'react';
import { Props } from '.';
import { ExclusionTypeEnum, MatchCoachVm } from '../../../../utils/api';

class CoachExclusion extends React.PureComponent<Props> {
    public render = (): React.ReactElement => {
        const { event, coaches, isHomeTeam } = this.props;

        const matchCoach = coaches.find(
            (c: MatchCoachVm): boolean => c.id === event?.exclusion?.excludedCoachId
        );
        const backgroundColor = isHomeTeam ? 'bg-white' : 'bg-blue';

        return (
            <div className="event-create">
                <div className="title">Coach:</div>
                <div className="content">
                    {matchCoach ? `${matchCoach.coach.name} ${matchCoach.coach.surname}` : ''}
                </div>

                <br />
                <div className="title">Suspension type:</div>
                <Radio.Group
                    className="exclusion-type-wrapper"
                    value={ExclusionTypeEnum.Penalty}
                    buttonStyle="solid"
                    size="large"
                >
                    <div className="exclusion-type-btn-wrapper">
                        <Radio.Button
                            value={ExclusionTypeEnum.Penalty}
                            className={`btn-icon ${backgroundColor}`}
                        >
                            <span style={{ color: '#000', fontWeight: 'bold' }}>5m</span>
                        </Radio.Button>
                        <span className="exclusion-type-title">Penalty</span>
                    </div>
                </Radio.Group>

                <br />
            </div>
        );
    };
}

export default CoachExclusion;
