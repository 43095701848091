import { DropdownOption } from '../../../core/models/DropdownOption';
import {
    CoachRoleEnum,
    CreateMatchCoachCommandOfMatchCoach,
    CreateMatchOfficialCommandOfMatchOfficial,
    EventVm,
    MatchDetailVm,
    OfficialRoleEnum,
} from '../../../utils/api';
import MatchOfficials from './MatchOfficials';

export interface Props {
    homeHeadCoach: CreateMatchCoachCommandOfMatchCoach | undefined;
    homeAssistantCoaches: CreateMatchCoachCommandOfMatchCoach[];
    awayHeadCoach: CreateMatchCoachCommandOfMatchCoach | undefined;
    awayAssistantCoaches: CreateMatchCoachCommandOfMatchCoach[];
    referee1: CreateMatchOfficialCommandOfMatchOfficial | undefined;
    referee2: CreateMatchOfficialCommandOfMatchOfficial | undefined;
    director: CreateMatchOfficialCommandOfMatchOfficial | undefined;
    delegates: CreateMatchOfficialCommandOfMatchOfficial[];
    goalReferees: CreateMatchOfficialCommandOfMatchOfficial[];
    secretaries: CreateMatchOfficialCommandOfMatchOfficial[];
    timekeepers: CreateMatchOfficialCommandOfMatchOfficial[];
    vars: CreateMatchOfficialCommandOfMatchOfficial[];
    coaches: DropdownOption[];
    officials: DropdownOption[];
    match?: MatchDetailVm;
    events?: EventVm[];
    handleSelectCoach: (isHomeTeam: boolean, coachRole: CoachRoleEnum, coachId: number) => void;
    handleClearCoach: (isHomeTeam: boolean, coachRole: CoachRoleEnum, coachIds?: number[]) => void;
    handleSelectOfficial: (
        officialRole: OfficialRoleEnum,
        officialId: number,
        referee?: number
    ) => void;
    handleClearOfficial: (
        officialRole: OfficialRoleEnum,
        officialIds?: number[],
        referee?: number
    ) => void;
}

export default MatchOfficials;
