import { Button, Radio, Row } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import React from 'react';
import { Props } from '.';
import CapSelector from '../../../../components/cap-selector/CapSelector';
import Icon from '../../../../components/icon/Icon';
import { CapNumber } from '../../../../core/models/CapNumber';
import { getTeamId } from '../../../../helpers/EventHelper';
import {
    CreateMatchPlayerCommandOfMatchPlayer,
    EventVm,
    TurnoverRequest,
    TurnoverTypeEnum,
    UpdateEventCommand,
} from '../../../../utils/api';

class Turnover extends React.PureComponent<Props> {
    public handleStolenBySelect = (cap: number): void => {
        const { event, updateEvent, lineups } = this.props;

        const eventVm = event as EventVm;

        const lineup = lineups.find(
            (l: CreateMatchPlayerCommandOfMatchPlayer): boolean =>
                l.teamId !== getTeamId(event) && l.number === cap
        );

        const updatedEvent = new UpdateEventCommand({
            ...event,
            turnover: event.turnover
                ? new TurnoverRequest({
                    ...event.turnover,
                    wonPossesionPlayerId: lineup?.id,
                    id: eventVm.turnoverId || 0,
                })
                : undefined,
        });

        updatedEvent.id = event.id;

        updateEvent(updatedEvent);
    };

    public handleStolenByClear = (): void => {
        const { event, updateEvent } = this.props;

        const eventVm = event as EventVm;

        const updatedEvent = new UpdateEventCommand({
            ...event,
            turnover: event.turnover
                ? new TurnoverRequest({
                    ...event.turnover,
                    wonPossesionPlayerId: undefined,
                    id: eventVm.turnoverId || 0,
                })
                : undefined,
        });

        updatedEvent.id = event.id;

        updateEvent(updatedEvent);
    };

    public handleTurnoverTypeSelect = (turnoverType: TurnoverTypeEnum): void => {
        const { event, updateEvent } = this.props;

        const eventVm = event as EventVm;

        const updatedEvent = new UpdateEventCommand({
            ...event,
            turnover: event.turnover
                ? new TurnoverRequest({
                    ...event.turnover,
                    typeId: turnoverType,
                    wonPossesionPlayerId: undefined,
                    id: eventVm.turnoverId || 0,
                })
                : undefined,
        });

        updatedEvent.id = event.id;

        updateEvent(updatedEvent);
    };

    public render = (): React.ReactElement => {
        const { event, lineups, isHomeTeam } = this.props;

        const turnoverType = event.turnover?.typeId;
        const stolenById = event.turnover?.wonPossesionPlayerId;
        const stolenByLineup = lineups.find(
            (l: CreateMatchPlayerCommandOfMatchPlayer): boolean => l.id === stolenById
        );
        const otherTeamLineups = lineups.filter(
            (l: CreateMatchPlayerCommandOfMatchPlayer): boolean => l.teamId !== getTeamId(event)
        );
        const capClassLost = isHomeTeam ? 'bg-white' : 'bg-blue';
        const capClassStolen = isHomeTeam ? 'bg-blue' : 'bg-white';

        let title = "Stolen by"

        switch (turnoverType) {
            case TurnoverTypeEnum.Offensive_Foul:
                title = "Fouled Player";
                break;
            case TurnoverTypeEnum.Ball_Under:
                title = "Submerged by";
                break;
            default:
                title = "Stolen by";
        }

        return (
            <div className="event-create">
                <Row>
                    <Radio.Group
                        className="exclusion-type-wrapper"
                        value={turnoverType}
                        buttonStyle="solid"
                        size="large"
                        onChange={(e: RadioChangeEvent): void =>
                            this.handleTurnoverTypeSelect(e.target.value)
                        }
                    >
                        <div className="exclusion-type-btn-wrapper">
                            <Radio.Button
                                value={TurnoverTypeEnum.Lost_Ball}
                                className={`btn-icon ${capClassLost}`}
                            >
                                <Icon name="icon-user-times" />
                            </Radio.Button>
                            <span className="exclusion-type-title">Lost Ball</span>
                        </div>
                        <div className="exclusion-type-btn-wrapper">
                            <Radio.Button
                                value={TurnoverTypeEnum.Offensive_Foul}
                                className={`btn-icon ${capClassLost}`}
                            >
                                <Icon name="icon-user-ninja" />
                            </Radio.Button>
                            <span className="exclusion-type-title">Offensive Foul</span>
                        </div>
                        <div className="exclusion-type-btn-wrapper">
                            <Radio.Button
                                value={TurnoverTypeEnum.Ball_Under}
                                className={`btn-icon ${capClassLost}`}
                            >
                                <Icon name="icon-volleyball-ball" />
                            </Radio.Button>
                            <span className="exclusion-type-title">Ball Under</span>
                        </div>
                        <div className="exclusion-type-btn-wrapper">
                            <Radio.Button
                                value={TurnoverTypeEnum.Clock_Expired}
                                className={`btn-icon ${capClassLost}`}
                            >
                                <Icon name="icon-stopwatch" />
                            </Radio.Button>
                            <span className="exclusion-type-title">Clock Expired</span>
                        </div>
                    </Radio.Group>
                </Row>
                <br />
                <Row>
                    {(turnoverType === TurnoverTypeEnum.Lost_Ball || turnoverType === TurnoverTypeEnum.Ball_Under || turnoverType === TurnoverTypeEnum.Offensive_Foul) && (
                        <div className="match-form-lineup">
                            <div className="title">{title}</div>
                            <CapSelector
                                capClass={capClassStolen}
                                onSelect={this.handleStolenBySelect}
                                caps={otherTeamLineups.map(
                                    (l: CreateMatchPlayerCommandOfMatchPlayer): CapNumber => ({ cap: l.number, capNumber: l.capNumber || l.number }),
                                )}
                                activeCap={stolenByLineup ? stolenByLineup.number : null}
                                activeCapNumber={stolenByLineup ? stolenByLineup.capNumber || stolenByLineup.number : null}
                            />

                            {stolenByLineup && (
                                <Button
                                    size="large"
                                    onClick={(): void => this.handleStolenByClear()}
                                >
                                    Clear
                                </Button>
                            )}
                        </div>
                    )}
                </Row>
            </div>
        );
    };
}

export default Turnover;
