import { Filter } from '../core/models/Filter';
import { PaginatedListOfAnyVm } from '../core/models/PaginationHelper';
import { checkIfDatesWithoutTimeAreSame } from './DateHelper';
import { calculateNewPaginationSettings } from './PaginationHelper';
import {
    booleanCompareKeys,
    dateCompareKeys,
    regularCompareKeys,
    sortFeTableHelper,
} from './TableSortHelper';

export const feTableFilterHelper = (entities: any[], filter: Filter): PaginatedListOfAnyVm => {
    let allPageItems: any[] = [];
    const filterRequest = filter.filterRequest as any;

    entities.forEach((entity: any): void => {
        if (filterRequest) {
            const keys = Object.keys(filterRequest);
            let isValid = true;

            keys.forEach((k: string): void => {
                if (regularCompareKeys.find((rck: string): boolean => rck === k)) {
                    if (!entity[k]?.toLowerCase().includes(filterRequest[k].toLowerCase())) {
                        isValid = false;
                        return;
                    }
                } else if (dateCompareKeys.find((dck: string): boolean => dck === k)) {
                    if (!checkIfDatesWithoutTimeAreSame(filterRequest[k], entity[k])) {
                        isValid = false;
                        return;
                    }
                } else if (booleanCompareKeys.find((bck: string): boolean => bck === k)) {
                    if (!(entity[k]?.toString() === filterRequest[k]?.toString())) {
                        isValid = false;
                        return;
                    }
                }
            });

            if (isValid) {
                allPageItems.push(entity);
            }
        } else {
            allPageItems.push(entity);
        }
    });

    if (filter.orderByKey && filter.orderByValue) {
        allPageItems = sortFeTableHelper(filter.orderByKey, filter.orderByValue, allPageItems);
    }

    const paginationSettings = calculateNewPaginationSettings(filter, allPageItems.length);

    const pageItems = allPageItems.slice(
        paginationSettings.startIndex,
        paginationSettings.endIndex
    );

    return {
        pageItems: pageItems,
        currentPage: paginationSettings.currentPage,
        pageSize: paginationSettings.pageSize,
        totalPages: paginationSettings.totalPages,
        totalItems: paginationSettings.totalItems,
    };
};
