import { Checkbox, Col, Drawer, Form, Input, Row } from 'antd';
import moment from 'moment';
import React from 'react';
import DetailsButtons from '../../../../components/details-buttons';
import { DrawerWidth } from '../../../../config/dimensions';
import Routes from '../../../../config/routes';
import ReplaceStrings from '../../../../config/replaceStrings';
import { DrawerState, ModuleName } from '../../../../core/models/enum';
import { convertUTCTimeToLocal } from '../../../../helpers/DateHelper';
import { formItemLayout724 } from '../../../../helpers/FormLayoutHelper';
import { getNewRoute } from '../../../../helpers/RoutingHelper';
import { handleDeleteHelper } from '../../../../helpers/SubmitHelper';
import { ClubsClient } from '../../../../utils/api';
import ClubForm from '../../club-form';
import { Props } from './index';

interface State {
    drawerState: DrawerState;
    loading: boolean;
}

class ClubDetails extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            drawerState: props.isInitialEdit ? DrawerState.Edit : DrawerState.Closed,
            loading: true,
        };
    }

    private handleDrawerMode = (drawerState: DrawerState, id?: string): void => {
        const { history } = this.props;

        this.setState({
            drawerState,
        });

        history.push(
            getNewRoute(
                Routes.ROUTE_CLUBS,
                Routes.ROUTE_CLUBS_READ,
                Routes.ROUTE_CLUBS_EDIT,
                drawerState,
                ReplaceStrings.CLUB_ID,
                id
            )
        );
    };

    private openForm = (): void => {
        const { club } = this.props;
        this.handleDrawerMode(DrawerState.Edit, club.id.toString());
    };

    private handleDelete = (): void => {
        const { history } = this.props;

        history.push(Routes.ROUTE_CLUBS);
    };

    public render(): React.ReactElement {
        const { drawerState } = this.state;
        const { userProfile, club, refreshAfterSave, hideEditButtons } = this.props;

        return (
            <div>
                {!hideEditButtons && (
                    <DetailsButtons
                        userProfile={userProfile}
                        onDeleteAction={(): Promise<void> =>
                            handleDeleteHelper(
                                new ClubsClient(),
                                'Club successfully deleted.',
                                this.handleDelete,
                                club.id
                            )
                        }
                        onEditAction={this.openForm}
                        customMessage="Deleting a club also deletes all teams and users with a Club role within the club. Are you sure you want to delete this club?"
                        moduleName={ModuleName.Clubs}
                        entityId={club.id}
                    />
                )}
                <Row className="details-height">
                    <Col span={12}>
                        <Form {...formItemLayout724}>
                            <Form.Item label="Name">
                                <Input readOnly value={club.name} />
                            </Form.Item>
                            <Form.Item label="Universal name">
                                <Input readOnly value={club.nameUniversal} />
                            </Form.Item>
                            <Form.Item label="Short name">
                                <Input readOnly value={club.shortName} />
                            </Form.Item>
                            <Form.Item label="Description">
                                <Input readOnly value={club.description} />
                            </Form.Item>
                            <Form.Item label="Country">
                                <Input readOnly value={club.country} />
                            </Form.Item>
                            <Form.Item label="City">
                                <Input readOnly value={club.city} />
                            </Form.Item>
                            <Form.Item label="Federation">
                                <Input readOnly value={club.federation} />
                            </Form.Item>
                            <Form.Item label="Active from">
                                <Input
                                    readOnly
                                    value={
                                        club.activeFrom
                                            ? moment(convertUTCTimeToLocal(club.activeFrom)).format(
                                                  'DD.MM.YYYY.'
                                              )
                                            : ''
                                    }
                                />
                            </Form.Item>
                            <Form.Item label="Active to">
                                <Input
                                    readOnly
                                    value={
                                        club.activeTo
                                            ? moment(convertUTCTimeToLocal(club.activeTo)).format(
                                                  'DD.MM.YYYY.'
                                              )
                                            : ''
                                    }
                                />
                            </Form.Item>
                            <Form.Item label="President">
                                <Input readOnly value={club.president} />
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col span={12}>
                        <Form {...formItemLayout724}>
                            <Form.Item label="Venue">
                                <Input readOnly value={club.venue} />
                            </Form.Item>
                            <Form.Item label="Address">
                                <Input readOnly value={club.address} />
                            </Form.Item>
                            <Form.Item label="Phone">
                                <Input readOnly value={club.phone} />
                            </Form.Item>
                            <Form.Item label="Web">
                                <Input readOnly value={club.web} />
                            </Form.Item>
                            <Form.Item label="Email">
                                <Input readOnly value={club.email} />
                            </Form.Item>
                            <Form.Item label="Facebook ">
                                <Input readOnly value={club.facebook} />
                            </Form.Item>
                            <Form.Item label="Twitter ">
                                <Input readOnly value={club.twitter} />
                            </Form.Item>
                            <Form.Item label="Instagram">
                                <Input readOnly value={club.instagram} />
                            </Form.Item>
                            <Form.Item label="Youtube ">
                                <Input readOnly value={club.youtube} />
                            </Form.Item>
                            <Form.Item label="Professional">
                                <Checkbox checked={club.isPro} disabled />
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
                <Drawer
                    title="Edit club"
                    visible={drawerState === DrawerState.Edit}
                    onClose={(): void =>
                        this.handleDrawerMode(DrawerState.Read, club.id.toString())
                    }
                    width={DrawerWidth}
                    destroyOnClose
                >
                    <ClubForm
                        handleClose={this.handleDrawerMode}
                        refreshAfterSave={refreshAfterSave}
                        club={club}
                    />
                </Drawer>
            </div>
        );
    }
}

export default ClubDetails;
