import {
    CreateMatchPlayerCommandOfMatchPlayer,
    EventTypeEnum,
    EventVm,
    MatchDetailVm,
} from '../../../../utils/api';
import MatchLineupRow from './MatchLineupRow';

interface OwnProps {
    match?: MatchDetailVm;
    lineupPlayer?: CreateMatchPlayerCommandOfMatchPlayer;
    events: EventVm[];
    cap: number;
    capNumber: number;
    isHomeTeam: boolean;
    isSelectedKeeper: boolean;
    initEvent: (type: EventTypeEnum, eventTypeValues?: any) => void;
    selectPlayer: (cap: number, teamId: number) => void;
}

export type Props = OwnProps;

export default MatchLineupRow;
