import { EventTypeEnum, EventVm, MatchCoachVm, MatchDetailVm } from '../../../../utils/api';
import MatchCoachLineupRow from './MatchCoachLineupRow';

interface OwnProps {
    match?: MatchDetailVm;
    coach: MatchCoachVm;
    events: EventVm[];
    isHomeTeam: boolean;
    initEvent: (type: EventTypeEnum, eventTypeValues?: any) => void;
}

export type Props = OwnProps;

export default MatchCoachLineupRow;
