import React from 'react';
import { compareEventsByTime, getPlayerNumber, getTeamId } from '../../../helpers/EventHelper';
import { EventPeriodEnum, EventVm, MatchPlayerVm } from '../../../utils/api';
import { Props } from './index';
import LogItem from './log-item';

interface State {
    selectedQuarter: EventPeriodEnum;
}

class MatchEventLog extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            selectedQuarter: EventPeriodEnum.First_Period,
        };
    }

    private handleSelectQuarter = (quarter: EventPeriodEnum): void => {
        this.setState({
            selectedQuarter: quarter,
        });
    };

    private getTabClassName = (quarter: EventPeriodEnum): string => {
        const { selectedQuarter } = this.state;
        let result = 'c-gl-quarters__item ';

        if (quarter === selectedQuarter) {
            result += 'is-active ';
        }
        if (quarter === EventPeriodEnum.Overtime) {
            result += 'c-gl-quarters__item--pso ';
        }
        return result;
    };

    public render = (): React.ReactElement => {
        const { events, match, lineups, coaches } = this.props;
        const { selectedQuarter } = this.state;

        const quarterEvents = events.filter(
            (event: EventVm): boolean => event.periodId === selectedQuarter
        );

        return (
            <div>
                <div className="c-gl-quarters">
                    <div className="c-gl-quarters__list u-flex u-justify-content-between">
                        <div
                            className={this.getTabClassName(EventPeriodEnum.First_Period)}
                            onClick={(): void =>
                                this.handleSelectQuarter(EventPeriodEnum.First_Period)
                            }
                            role="button"
                            aria-hidden
                        >
                            <span>1st Period</span>
                        </div>
                        <div
                            className={this.getTabClassName(EventPeriodEnum.Second_Period)}
                            onClick={(): void =>
                                this.handleSelectQuarter(EventPeriodEnum.Second_Period)
                            }
                            role="button"
                            aria-hidden
                        >
                            <span>2nd Period</span>
                        </div>
                        <div
                            className={this.getTabClassName(EventPeriodEnum.Third_Period)}
                            onClick={(): void =>
                                this.handleSelectQuarter(EventPeriodEnum.Third_Period)
                            }
                            role="button"
                            aria-hidden
                        >
                            <span>3rd Period</span>
                        </div>
                        <div
                            className={this.getTabClassName(EventPeriodEnum.Fourth_Period)}
                            onClick={(): void =>
                                this.handleSelectQuarter(EventPeriodEnum.Fourth_Period)
                            }
                            role="button"
                            aria-hidden
                        >
                            <span>4th Period</span>
                        </div>
                        <div
                            className={this.getTabClassName(EventPeriodEnum.Overtime)}
                            onClick={(): void => this.handleSelectQuarter(EventPeriodEnum.Overtime)}
                            role="button"
                            aria-hidden
                        >
                            <span>Overtime</span>
                        </div>
                    </div>
                </div>

                <div className="c-gl-event-list u-flex u-justify-content-between">
                    <div className="c-event-list c-event-list--is-game-log">
                        {match &&
                            quarterEvents
                                .sort(compareEventsByTime)
                                .reverse()
                                .map(
                                    (e: EventVm): React.ReactNode => (
                                        <LogItem
                                            key={e.id}
                                            event={e}
                                            coaches={coaches}
                                            teamId={getTeamId(e)}
                                            match={match}
                                            lineup={
                                                lineups
                                                    ? lineups.find(
                                                          (l: MatchPlayerVm): boolean =>
                                                              l.number === getPlayerNumber(e)
                                                      )
                                                    : undefined
                                            }
                                        />
                                    )
                                )}
                    </div>
                </div>
            </div>
        );
    };
}

export default MatchEventLog;
