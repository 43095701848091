import React from 'react';
import './styles.css';

interface Props {
    headerPhoto?: string;
    headerTitle: string;
    tableName: string;
    imgHeight?: number;
}

class PageHeaderTitle extends React.PureComponent<Props> {
    public constructor(props: Props) {
        super(props);
    }

    public render(): React.ReactElement {
        const { headerPhoto, headerTitle, imgHeight } = this.props;

        return (
            <div className="page-header-wrapper">
                {headerPhoto && (
                    <img
                        height={imgHeight || 120}
                        src={`data:image/jpeg;base64,${headerPhoto}`}
                        style={{ marginRight: 10 }}
                        alt="logo"
                    />
                )}
                {headerTitle}
            </div>
        );
    }
}

export default PageHeaderTitle;
