import { Checkbox, Col, Drawer, Form, Input, Row } from 'antd';
import moment from 'moment';
import React from 'react';
import DetailsButtons from '../../../../components/details-buttons';
import { DrawerWidth } from '../../../../config/dimensions';
import Routes from '../../../../config/routes';
import ReplaceStrings from '../../../../config/replaceStrings';
import { DrawerState, ModuleName } from '../../../../core/models/enum';
import { convertUTCTimeToLocal } from '../../../../helpers/DateHelper';
import { formItemLayout724 } from '../../../../helpers/FormLayoutHelper';
import { getNewRoute } from '../../../../helpers/RoutingHelper';
import { handleDeleteHelper } from '../../../../helpers/SubmitHelper';
import { FederationCategoryVm, FederationsClient } from '../../../../utils/api';
import FederationForm from '../../federation-form';
import { Props } from './index';

interface State {
    drawerState: DrawerState;
    loading: boolean;
}

class FederationDetails extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            drawerState: props.isInitialEdit ? DrawerState.Edit : DrawerState.Closed,
            loading: true,
        };
    }

    private handleDrawerMode = (drawerState: DrawerState, id?: string): void => {
        const { history } = this.props;

        this.setState({
            drawerState,
        });

        history.push(
            getNewRoute(
                Routes.ROUTE_FEDERATIONS,
                Routes.ROUTE_FEDERATIONS_READ,
                Routes.ROUTE_FEDERATIONS_EDIT,
                drawerState,
                ReplaceStrings.FEDERATION_ID,
                id
            )
        );
    };

    private openForm = (): void => {
        const { federation } = this.props;
        this.handleDrawerMode(DrawerState.Edit, federation.id.toString());
    };

    private handleDelete = (): void => {
        const { history } = this.props;

        history.push(Routes.ROUTE_FEDERATIONS);
    };

    public render(): React.ReactElement {
        const { drawerState } = this.state;
        const { userProfile, federation, refreshAfterSave } = this.props;

        return (
            <div>
                <DetailsButtons
                    userProfile={userProfile}
                    onDeleteAction={(): Promise<void> =>
                        handleDeleteHelper(
                            new FederationsClient(),
                            'Federation successfully deleted.',
                            this.handleDelete,
                            federation.id
                        )
                    }
                    onEditAction={this.openForm}
                    customMessage="Deleting a federation also deletes all clubs, teams, competitions, matches and users with a Federation or Club role within the federation. Are you sure you want to delete this federation?"
                    moduleName={ModuleName.Federations}
                    entityId={federation.id}
                />
                <Row className="details-height">
                    <Col span={12}>
                        <Form {...formItemLayout724}>
                            <Form.Item label="Name">
                                <Input readOnly value={federation.name} />
                            </Form.Item>
                            <Form.Item label="Universal name">
                                <Input readOnly value={federation.nameUniversal} />
                            </Form.Item>
                            <Form.Item label="Short name">
                                <Input readOnly value={federation.shortName} />
                            </Form.Item>
                            <Form.Item label="Description">
                                <Input readOnly value={federation.description} />
                            </Form.Item>
                            <Form.Item label="President  ">
                                <Input readOnly value={federation.president} />
                            </Form.Item>
                            <Form.Item label="Secretary  ">
                                <Input readOnly value={federation.secretary} />
                            </Form.Item>
                            <Form.Item label="Country">
                                <Input readOnly value={federation.country} />
                            </Form.Item>
                            <Form.Item label="City">
                                <Input readOnly value={federation.city} />
                            </Form.Item>
                            <Form.Item label="Active from">
                                <Input
                                    readOnly
                                    value={
                                        federation.activeFrom
                                            ? moment(
                                                  convertUTCTimeToLocal(federation.activeFrom)
                                              ).format('DD.MM.YYYY.')
                                            : ''
                                    }
                                />
                            </Form.Item>
                            <Form.Item label="Active to">
                                <Input
                                    readOnly
                                    value={
                                        federation.activeTo
                                            ? moment(
                                                  convertUTCTimeToLocal(federation.activeTo)
                                              ).format('DD.MM.YYYY.')
                                            : ''
                                    }
                                />
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col span={12}>
                        <Form {...formItemLayout724}>
                            <Form.Item label="Categories">
                                <Input
                                    readOnly
                                    value={
                                        federation &&
                                        federation.categories &&
                                        federation.categories.length > 0
                                            ? federation.categories
                                                  .map(
                                                      (c: FederationCategoryVm): string =>
                                                          c.category
                                                  )
                                                  .join(', ')
                                            : ''
                                    }
                                />
                            </Form.Item>
                            <Form.Item label="Address">
                                <Input readOnly value={federation.address} />
                            </Form.Item>
                            <Form.Item label="Phone">
                                <Input readOnly value={federation.phone} />
                            </Form.Item>
                            <Form.Item label="Web">
                                <Input readOnly value={federation.web} />
                            </Form.Item>
                            <Form.Item label="Email">
                                <Input readOnly value={federation.email} />
                            </Form.Item>
                            <Form.Item label="Facebook ">
                                <Input readOnly value={federation.facebook} />
                            </Form.Item>
                            <Form.Item label="Twitter ">
                                <Input readOnly value={federation.twitter} />
                            </Form.Item>
                            <Form.Item label="Instagram">
                                <Input readOnly value={federation.instagram} />
                            </Form.Item>
                            <Form.Item label="Youtube ">
                                <Input readOnly value={federation.youtube} />
                            </Form.Item>
                            <Form.Item label="Professional">
                                <Checkbox checked={federation.isPro} disabled />
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
                <Drawer
                    title="Edit federation"
                    visible={drawerState === DrawerState.Edit}
                    onClose={(): void =>
                        this.handleDrawerMode(DrawerState.Read, federation.id.toString())
                    }
                    width={DrawerWidth}
                    destroyOnClose
                >
                    <FederationForm
                        handleClose={this.handleDrawerMode}
                        refreshAfterSave={refreshAfterSave}
                        federation={federation}
                    />
                </Drawer>
            </div>
        );
    }
}

export default FederationDetails;
