import { Button } from 'antd';
import React from 'react';
import CapSelector from '../../../../components/cap-selector/CapSelector';
import {
    CreateMatchPlayerCommandOfMatchPlayer,
    EventVm,
    SwimoffRequest,
    UpdateEventCommand,
} from '../../../../utils/api';
import { Props } from '.';
import { CapNumber } from '../../../../core/models/CapNumber';

class SwimOff extends React.PureComponent<Props> {
    public handleHomeTeamPlayerSelect = (cap: number): void => {
        const { event, updateEvent, lineups, homeTeamId } = this.props;

        const eventVm = event as EventVm;

        const lineup = lineups.find(
            (l: CreateMatchPlayerCommandOfMatchPlayer): boolean =>
                l.teamId === homeTeamId && l.number === cap
        );

        if (lineup) {
            const updatedEvent = new UpdateEventCommand({
                ...event,
                swimoff: event.swimoff
                    ? new SwimoffRequest({
                        ...event.swimoff,
                        homeTeamSwimmerId: lineup.id || 0,
                        id: eventVm.swimoffId || 0,
                    })
                    : new SwimoffRequest({
                        id: 0,
                        homeTeamSwimmerId: lineup.id || 0,
                        awayTeamSwimmerId: 0,
                        winnerSwimmerId: undefined,
                        winnerTeamId: undefined,
                    }),
            })

            updatedEvent.id = event.id;

            updateEvent(updatedEvent);
        }
    };

    public handleAwayTeamPlayerSelect = (cap: number): void => {
        const { event, updateEvent, lineups, homeTeamId } = this.props;

        const eventVm = event as EventVm;

        const lineup = lineups.find(
            (l: CreateMatchPlayerCommandOfMatchPlayer): boolean =>
                l.teamId !== homeTeamId && l.number === cap
        );

        if (lineup) {
            const updatedEvent = new UpdateEventCommand({
                ...event,
                swimoff: event.swimoff
                    ? new SwimoffRequest({
                        ...event.swimoff,
                        awayTeamSwimmerId: lineup.id || 0,
                        id: eventVm.swimoffId || 0,
                    })
                    : new SwimoffRequest({
                        id: 0,
                        homeTeamSwimmerId: 0,
                        awayTeamSwimmerId: lineup.id || 0,
                        winnerSwimmerId: undefined,
                        winnerTeamId: undefined,
                    }),
            });

            updatedEvent.id = event.id;

            updateEvent(updatedEvent);
        }
    };

    public handleWinnerSelect = (playerId: number): void => {
        const { event, updateEvent, lineups } = this.props;

        const eventVm = event as EventVm;

        const lineup = lineups.find(
            (l: CreateMatchPlayerCommandOfMatchPlayer): boolean => l.id === playerId
        );

        const updatedEvent = new UpdateEventCommand({
            ...event,
            swimoff: event.swimoff
                ? new SwimoffRequest({
                    ...event.swimoff,
                    winnerSwimmerId: playerId,
                    winnerTeamId: lineup?.teamId,
                    id: eventVm.swimoffId || 0,
                })
                : undefined,
        });

        updatedEvent.id = event.id;

        updateEvent(updatedEvent);
    };

    public handleWinnerClear = (): void => {
        const { event, updateEvent } = this.props;

        const eventVm = event as EventVm;

        const updatedEvent = new UpdateEventCommand({
            ...event,
            swimoff: event.swimoff
                ? new SwimoffRequest({
                    ...event.swimoff,
                    winnerSwimmerId: undefined,
                    winnerTeamId: undefined,
                    id: eventVm.swimoffId || 0,
                })
                : undefined,
        });

        updatedEvent.id = event.id;

        updateEvent(updatedEvent);
    };

    public render = (): React.ReactElement => {
        const { event, lineups, homeTeamId } = this.props;

        const homeTeamLineups = lineups.filter(
            (l: CreateMatchPlayerCommandOfMatchPlayer): boolean => l.teamId === homeTeamId
        );
        const awayTeamLineups = lineups.filter(
            (l: CreateMatchPlayerCommandOfMatchPlayer): boolean => l.teamId !== homeTeamId
        );

        const homeTeamPlayerLineup = lineups.find(
            (l: CreateMatchPlayerCommandOfMatchPlayer): boolean =>
                l.id === event.swimoff?.homeTeamSwimmerId
        );
        const awayTeamPlayerLineup = lineups.find(
            (l: CreateMatchPlayerCommandOfMatchPlayer): boolean =>
                l.id === event.swimoff?.awayTeamSwimmerId
        );

        const swimOffWinnerId = event.swimoff?.winnerSwimmerId;
        const swimOffWinnerLineup = lineups.find(
            (l: CreateMatchPlayerCommandOfMatchPlayer): boolean => l.id === swimOffWinnerId
        );

        const isHomeWinner =
            swimOffWinnerLineup &&
            homeTeamPlayerLineup &&
            swimOffWinnerLineup.id === homeTeamPlayerLineup.playerId;
        const isAwayWinner =
            swimOffWinnerLineup &&
            awayTeamPlayerLineup &&
            swimOffWinnerLineup.id === awayTeamPlayerLineup.playerId;

        return (
            <div className="event-create match-form-lineup">
                <div className="title">Home team player:</div>
                <CapSelector
                    capClass="bg-white"
                    onSelect={this.handleHomeTeamPlayerSelect}
                    caps={homeTeamLineups.map(
                        (l: CreateMatchPlayerCommandOfMatchPlayer): CapNumber => ({ cap: l.number, capNumber: l.capNumber || l.number }),
                    )}
                    activeCap={homeTeamPlayerLineup ? homeTeamPlayerLineup.number : null}
                    activeCapNumber={homeTeamPlayerLineup ? homeTeamPlayerLineup.capNumber || homeTeamPlayerLineup.number : null}
                />
                <br />
                <br />
                <div className="title">Away team player:</div>
                <CapSelector
                    capClass="bg-blue"
                    onSelect={this.handleAwayTeamPlayerSelect}
                    caps={awayTeamLineups.map(
                        (l: CreateMatchPlayerCommandOfMatchPlayer): CapNumber => ({ cap: l.number, capNumber: l.capNumber || l.number }),
                    )}
                    activeCap={awayTeamPlayerLineup ? awayTeamPlayerLineup.number : null}
                    activeCapNumber={awayTeamPlayerLineup ? awayTeamPlayerLineup.capNumber || awayTeamPlayerLineup.number : null}
                />
                <br />
                <br />
                {homeTeamPlayerLineup && awayTeamPlayerLineup && (
                    <div className="swimoff-winner-buttons">
                        <div className="title">Winner:</div>
                        <Button
                            type={isHomeWinner ? 'primary' : 'default'}
                            size="large"
                            onClick={(): void =>
                                this.handleWinnerSelect(homeTeamPlayerLineup.id || 0)
                            }
                        >
                            {homeTeamPlayerLineup ? homeTeamPlayerLineup.capNumber || homeTeamPlayerLineup.number : null}
                        </Button>
                        <Button
                            type={!swimOffWinnerId ? 'primary' : 'default'}
                            size="large"
                            onClick={(): void => this.handleWinnerClear()}
                        >
                            No Winner
                        </Button>
                        <Button
                            type={isAwayWinner ? 'primary' : 'default'}
                            size="large"
                            onClick={(): void =>
                                this.handleWinnerSelect(awayTeamPlayerLineup.id || 0)
                            }
                        >
                            {awayTeamPlayerLineup ? awayTeamPlayerLineup.capNumber || awayTeamPlayerLineup.number : null}
                        </Button>
                    </div>
                )}
            </div>
        );
    };
}

export default SwimOff;
