import { PlusOutlined } from '@ant-design/icons';
import { Button, Drawer, PageHeader } from 'antd';
import React from 'react';
import { DrawerWidth } from '../../config/dimensions';
import Routes from '../../config/routes';
import ReplaceStrings from '../../config/replaceStrings';
import { ActionOption, DrawerState, ModuleName } from '../../core/models/enum';
import { isUserAllowed } from '../../helpers/CheckPermissionHelper';
import { getNewRoute } from '../../helpers/RoutingHelper';
import { UserVm, UsersClient } from '../../utils/api';
import { Props } from './index';
import UserForm from './user-form';
import UserTable from './user-table';

interface State {
    drawerState: DrawerState;
    selectedUser?: UserVm;
    isUserAllowedToCreate: boolean;
}

class Users extends React.PureComponent<Props, State> {
    private tableRef: any;

    public constructor(props: Props) {
        super(props);

        this.tableRef = React.createRef();

        this.state = {
            drawerState: DrawerState.Closed,
            isUserAllowedToCreate: false,
        };
    }

    public componentDidMount = () => {
        const {
            match: {
                params: { userId },
                path,
            },
        } = this.props;

        this.getPermissions();
        this.handleRouteUpdate(path, userId);
    };

    public componentDidUpdate = (prevProps: Props) => {
        const {
            match: {
                params: { userId },
                path,
            },
        } = this.props;
        if (path !== prevProps.match.path) {
            this.handleRouteUpdate(path, userId);
        }
    };

    private getPermissions = (): void => {
        const { userProfile } = this.props;

        this.setState({
            isUserAllowedToCreate: isUserAllowed(
                userProfile,
                ModuleName.Users,
                ActionOption.Create
            ),
        });
    };

    private updateDrawerStateFromPathName = (pathname: string): void => {
        switch (pathname) {
            case Routes.ROUTE_USERS_CREATE:
                this.setState({
                    drawerState: DrawerState.Create,
                });
                return;
            case Routes.ROUTE_USERS_EDIT:
                this.setState({
                    drawerState: DrawerState.Edit,
                });
                return;
            case Routes.ROUTE_USERS_READ:
                this.setState({
                    drawerState: DrawerState.Read,
                });
                return;
            case Routes.ROUTE_USERS:
                this.setState({
                    drawerState: DrawerState.Closed,
                });
                return;
            default:
                this.setState({
                    drawerState: DrawerState.Closed,
                });
                return;
        }
    };

    private handleRouteUpdate = async (pathname: string, userId?: string): Promise<void> => {
        let selectedUser;
        const client = new UsersClient();

        if (userId) {
            selectedUser = await client.getById(userId);
        }

        this.setState(
            {
                selectedUser,
            },
            (): void => this.updateDrawerStateFromPathName(pathname)
        );
    };

    private handleDrawerMode = (drawerState: DrawerState, id?: string): void => {
        const { history } = this.props;

        this.setState({
            drawerState,
        });

        history.push(
            getNewRoute(
                Routes.ROUTE_USERS,
                Routes.ROUTE_USERS_READ,
                Routes.ROUTE_USERS_CREATE,
                drawerState,
                ReplaceStrings.USER_ID,
                id
            )
        );
    };

    private updateTable = (): void => {
        this.tableRef.current.getInitialAllUsers();
    };

    public render(): React.ReactElement {
        const { userProfile } = this.props;
        const { drawerState, selectedUser, isUserAllowedToCreate } = this.state;

        return (
            <div>
                <PageHeader
                    title="Users"
                    extra={
                        isUserAllowedToCreate
                            ? [
                                  <Button
                                      key="1"
                                      type="primary"
                                      style={{
                                          zIndex: 10,
                                          float: 'right',
                                      }}
                                      onClick={(): void => this.handleDrawerMode(DrawerState.Edit)}
                                  >
                                      <PlusOutlined className="button-icon-display" />
                                      Add user
                                  </Button>,
                              ]
                            : []
                    }
                    style={{ padding: '16px 0px' }}
                />
                <UserTable userProfile={userProfile} wrappedComponentRef={this.tableRef} />
                {drawerState !== DrawerState.Closed && (
                    <Drawer
                        title={drawerState === DrawerState.Create ? 'Add user' : 'Edit user'}
                        visible={!!drawerState}
                        onClose={(): void => this.handleDrawerMode(DrawerState.Closed)}
                        width={DrawerWidth}
                        destroyOnClose
                    >
                        <UserForm
                            handleClose={(): void => this.handleDrawerMode(DrawerState.Closed)}
                            refreshAfterSave={this.updateTable}
                            user={selectedUser}
                        />
                    </Drawer>
                )}
            </div>
        );
    }
}

export default Users;
