import { RouteComponentProps, withRouter } from 'react-router-dom';
import { DrawerState } from '../../../core/models/enum';
import { CoachFormInitialValues } from '../../../core/models/InitialValues';
import { ClubCoachVm, TeamCoachVm } from '../../../utils/api';
import { RouteParams } from '../../../core/models/RouteHelper';
import CoachForm from './CoachForm';

interface OwnProps {
    clubCoach?: ClubCoachVm;
    teamCoach?: TeamCoachVm;
    handleClose: (drawerMode: DrawerState, id?: string) => void;
    refreshAfterSave: () => void;
    initialValues?: CoachFormInitialValues;
}

export type Props = OwnProps & RouteComponentProps<RouteParams>;

export default withRouter(CoachForm);
