import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RouteParams } from '../../../core/models/RouteHelper';
import { DrawerState } from '../../../core/models/enum';
import OfficialUpdatePINForm from './OfficialUpdatePINForm';

interface OwnProps {
    handleClose: (drawerMode: DrawerState) => void;
}

export type Props = OwnProps & RouteComponentProps<RouteParams>;

export default withRouter(OfficialUpdatePINForm);
