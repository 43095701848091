import {
    CreateMatchPlayerCommandOfMatchPlayer,
    EventVm,
    MatchCoachVm,
    MatchDetailVm,
    UpdateEventCommand,
} from '../../../utils/api';
import MatchEventLog from './MatchEventLog';

interface OwnProps {
    events: EventVm[];
    match?: MatchDetailVm;
    lineups: CreateMatchPlayerCommandOfMatchPlayer[];
    coaches: MatchCoachVm[];
    editEvent: (event: UpdateEventCommand) => void;
}

export type Props = OwnProps;

export default MatchEventLog;
