import { DropdownOption } from '../core/models/DropdownOption';
import { ProfileOption } from '../core/models/enum';
import { CoachFormInitialValues } from '../core/models/InitialValues';
import {
    ClubCoachesClient,
    ClubCoachVm,
    CoachesClient,
    CoachRoleEnum,
    CreateClubCoachCommandOfClubCoach,
    CreateCoachCommand,
    CreatePersonCommandOfPerson,
    CreateTeamCoachCommandOfTeamCoach,
    PersonsClient,
    PersonVm,
    TeamCoachesClient,
    TeamCoachVm,
    UpdateClubCoachCommandOfClubCoach,
    UpdateTeamCoachCommandOfTeamCoach,
} from '../utils/api';
import { createHelper, updateHelper } from './SubmitHelper';

export const coachSubmitHelper = (
    callbackFunction: () => void,
    values: any,
    selectedCountry?: DropdownOption[],
    selectedCity?: DropdownOption[],
    selectedPerson?: DropdownOption[],
    selectedProfileOption?: string,
    fileBase64?: string,
    selectedPersonVm?: PersonVm,
    selectedRole?: DropdownOption[],
    selectedCoachTeams?: CreateTeamCoachCommandOfTeamCoach[],
    selectedCoachClubs?: CreateClubCoachCommandOfClubCoach[],
    selectedClub?: DropdownOption[],
    selectedTeam?: DropdownOption[],
    initialValues?: CoachFormInitialValues,
    initialClubCoach?: ClubCoachVm,
    initialTeamCoach?: TeamCoachVm
): void => {
    if (initialValues && initialValues.clubId) {
        if (initialClubCoach) {
            const request: UpdateClubCoachCommandOfClubCoach = new UpdateClubCoachCommandOfClubCoach(
                {
                    clubId: initialValues.clubId,
                    coachId: initialClubCoach.coachId,
                    activeFrom: values.activeFromClub,
                    activeTo: values.activeToClub,
                    id: initialClubCoach.id || 0,
                }
            );

            request.id = initialClubCoach.id || 0;

            if (request) {
                const client = new ClubCoachesClient();
                updateHelper(client, request, callbackFunction);
            }
        } else if (
            selectedProfileOption === ProfileOption.Existing &&
            selectedPersonVm &&
            selectedPersonVm.coachId
        ) {
            const request: CreateClubCoachCommandOfClubCoach = new CreateClubCoachCommandOfClubCoach(
                {
                    clubId: initialValues.clubId,
                    coachId: selectedPersonVm.coachId,
                    activeFrom: values.activeFromClub,
                    activeTo: values.activeToClub,
                }
            );

            if (request) {
                const client = new ClubCoachesClient();
                createHelper(client, request, callbackFunction);
            }
        } else {
            if (selectedProfileOption === ProfileOption.Existing) {
                const coachClient = new CoachesClient();

                const existingPerson: CreateCoachCommand = new CreateCoachCommand({
                    personId:
                        selectedPerson && selectedPerson.length > 0
                            ? parseInt(selectedPerson[0].id, 10)
                            : 0,
                    clubs:
                        selectedClub && selectedClub.length > 0
                            ? [
                                  new CreateClubCoachCommandOfClubCoach({
                                      coachId: 0,
                                      clubId: parseInt(selectedClub[0].id, 10),
                                      activeFrom: values.activeFromClub,
                                      activeTo: values.activeToClub,
                                  }),
                              ]
                            : undefined,
                    teams: undefined,
                });

                createHelper(coachClient, existingPerson, callbackFunction);
            } else {
                const personClient = new PersonsClient();

                const newPerson: CreatePersonCommandOfPerson = new CreatePersonCommandOfPerson({
                    coach: new CreateCoachCommand({
                        personId:
                            selectedPerson && selectedPerson.length > 0
                                ? parseInt(selectedPerson[0].id, 10)
                                : 0,
                        clubs:
                            selectedClub && selectedClub.length > 0
                                ? [
                                      new CreateClubCoachCommandOfClubCoach({
                                          coachId: 0,
                                          clubId: parseInt(selectedClub[0].id, 10),
                                          activeFrom: values.activeFromClub,
                                          activeTo: values.activeToClub,
                                      }),
                                  ]
                                : undefined,
                        teams: undefined,
                    }),
                    name: values.name,
                    surname: values.surname,
                    birthDate:
                        values && values.birthDate
                            ? new Date(values.birthDate.toLocaleString())
                            : new Date(),
                    nationality: values.nationality,
                    address: values.address,
                    email: values.email,
                    phone: values.phone,
                    facebook: values.facebook,
                    youtube: values.youtube,
                    twitter: values.twitter,
                    instagram: values.instagram,
                    photo: fileBase64 || undefined,
                    countryOfBirthId:
                        selectedCountry && selectedCountry.length > 0
                            ? parseInt(selectedCountry[0].id, 10)
                            : undefined,
                    cityOfBirthId:
                        selectedCity && selectedCity.length > 0
                            ? parseInt(selectedCity[0].id, 10)
                            : undefined,
                    playerId: undefined,
                    coachId: undefined,
                    officialId: undefined,
                    official: undefined,
                    player: undefined,
                });

                createHelper(personClient, newPerson, callbackFunction);
            }
        }
    } else if (initialValues && initialValues.teamId) {
        if (initialTeamCoach && selectedRole && selectedRole.length > 0) {
            const request: UpdateTeamCoachCommandOfTeamCoach = new UpdateTeamCoachCommandOfTeamCoach(
                {
                    teamId: initialValues.teamId,
                    coachId: initialTeamCoach.coachId,
                    activeFrom: values.activeFromTeam,
                    activeTo: values.activeToTeam,
                    roleId: parseInt(selectedRole[0].id, 10),
                    id: initialTeamCoach.id || 0,
                }
            );

            request.id = initialTeamCoach.id || 0;

            if (request) {
                const client = new TeamCoachesClient();
                updateHelper(client, request, callbackFunction);
            }
        } else if (
            selectedProfileOption === ProfileOption.Existing &&
            selectedPersonVm &&
            selectedPersonVm.coachId &&
            selectedRole &&
            selectedRole.length > 0
        ) {
            const request: CreateTeamCoachCommandOfTeamCoach = new CreateTeamCoachCommandOfTeamCoach(
                {
                    teamId: initialValues.teamId,
                    coachId: selectedPersonVm.coachId,
                    activeFrom: values.activeFromTeam,
                    activeTo: values.activeToTeam,
                    roleId: parseInt(selectedRole[0].id, 10),
                }
            );

            if (request) {
                const client = new TeamCoachesClient();
                createHelper(client, request, callbackFunction);
            }
        } else {
            if (selectedProfileOption === ProfileOption.Existing) {
                const coachClient = new CoachesClient();

                const existingPerson: CreateCoachCommand = new CreateCoachCommand({
                    personId:
                        selectedPerson && selectedPerson.length > 0
                            ? parseInt(selectedPerson[0].id, 10)
                            : 0,
                    clubs: undefined,
                    teams:
                        selectedTeam && selectedTeam.length > 0
                            ? [
                                  new CreateTeamCoachCommandOfTeamCoach({
                                      coachId: 0,
                                      teamId: parseInt(selectedTeam[0].id, 10),
                                      activeFrom: values.activeFromTeam,
                                      activeTo: values.activeToTeam,
                                      roleId:
                                          selectedRole && selectedRole.length > 0
                                              ? parseInt(selectedRole[0].id, 10)
                                              : CoachRoleEnum.Head_Coach,
                                  }),
                              ]
                            : undefined,
                });

                createHelper(coachClient, existingPerson, callbackFunction);
            } else {
                const personClient = new PersonsClient();

                const newPerson: CreatePersonCommandOfPerson = new CreatePersonCommandOfPerson({
                    coach: new CreateCoachCommand({
                        personId:
                            selectedPerson && selectedPerson.length > 0
                                ? parseInt(selectedPerson[0].id, 10)
                                : 0,
                        clubs: undefined,
                        teams:
                            selectedTeam && selectedTeam.length > 0
                                ? [
                                      new CreateTeamCoachCommandOfTeamCoach({
                                          coachId: 0,
                                          teamId: parseInt(selectedTeam[0].id, 10),
                                          activeFrom: values.activeFromTeam,
                                          activeTo: values.activeToTeam,
                                          roleId:
                                              selectedRole && selectedRole.length > 0
                                                  ? parseInt(selectedRole[0].id, 10)
                                                  : CoachRoleEnum.Head_Coach,
                                      }),
                                  ]
                                : undefined,
                    }),
                    name: values.name,
                    surname: values.surname,
                    birthDate:
                        values && values.birthDate
                            ? new Date(values.birthDate.toLocaleString())
                            : new Date(),
                    nationality: values.nationality,
                    address: values.address,
                    email: values.email,
                    phone: values.phone,
                    facebook: values.facebook,
                    youtube: values.youtube,
                    twitter: values.twitter,
                    instagram: values.instagram,
                    photo: fileBase64 || undefined,
                    countryOfBirthId:
                        selectedCountry && selectedCountry.length > 0
                            ? parseInt(selectedCountry[0].id, 10)
                            : undefined,
                    cityOfBirthId:
                        selectedCity && selectedCity.length > 0
                            ? parseInt(selectedCity[0].id, 10)
                            : undefined,
                    playerId: undefined,
                    coachId: undefined,
                    officialId: undefined,
                    official: undefined,
                    player: undefined,
                });

                createHelper(personClient, newPerson, callbackFunction);
            }
        }
    } else {
        if (selectedProfileOption === ProfileOption.Existing) {
            const coachClient = new CoachesClient();

            const existingPerson: CreateCoachCommand = new CreateCoachCommand({
                personId:
                    selectedPerson && selectedPerson.length > 0
                        ? parseInt(selectedPerson[0].id, 10)
                        : 0,
                clubs:
                    selectedCoachClubs && selectedCoachClubs.length > 0
                        ? selectedCoachClubs
                        : undefined,
                teams:
                    selectedCoachTeams && selectedCoachTeams.length > 0
                        ? selectedCoachTeams
                        : undefined,
            });

            createHelper(coachClient, existingPerson, callbackFunction);
        } else {
            const personClient = new PersonsClient();

            const newPerson: CreatePersonCommandOfPerson = new CreatePersonCommandOfPerson({
                coach: new CreateCoachCommand({
                    personId:
                        selectedPerson && selectedPerson.length > 0
                            ? parseInt(selectedPerson[0].id, 10)
                            : 0,
                    clubs:
                        selectedCoachClubs && selectedCoachClubs.length > 0
                            ? selectedCoachClubs
                            : undefined,
                    teams:
                        selectedCoachTeams && selectedCoachTeams.length > 0
                            ? selectedCoachTeams
                            : undefined,
                }),
                name: values.name,
                surname: values.surname,
                birthDate:
                    values && values.birthDate
                        ? new Date(values.birthDate.toLocaleString())
                        : new Date(),
                nationality: values.nationality,
                address: values.address,
                email: values.email,
                phone: values.phone,
                facebook: values.facebook,
                youtube: values.youtube,
                twitter: values.twitter,
                instagram: values.instagram,
                photo: fileBase64 || undefined,
                countryOfBirthId:
                    selectedCountry && selectedCountry.length > 0
                        ? parseInt(selectedCountry[0].id, 10)
                        : undefined,
                cityOfBirthId:
                    selectedCity && selectedCity.length > 0
                        ? parseInt(selectedCity[0].id, 10)
                        : undefined,
                playerId: undefined,
                coachId: undefined,
                officialId: undefined,
                official: undefined,
                player: undefined,
            });

            createHelper(personClient, newPerson, callbackFunction);
        }
    }
};
