import React from 'react';
import { Props } from '.';
import { LINEUP_LIMIT } from '../../../config/settings';
import { compareCoaches, getPlayerNumber } from '../../../helpers/EventHelper';
import { CreateMatchPlayerCommandOfMatchPlayer, EventVm, MatchCoachVm } from '../../../utils/api';
import MatchCoachLineupRow from './match-coach-lineup-row';
import MatchLineupRow from './match-lineup-row';

class MatchLineup extends React.PureComponent<Props> {
    private createLineup = (): React.ReactNode => {
        const {
            match,
            events,
            lineup,
            isHomeTeam,
            initEvent,
            selectedKeeper,
            onPlayerSelect,
        } = this.props;

        const lineupArray = [];

        for (let i = 1; i <= LINEUP_LIMIT; i += 1) {
            const lineupPlayer = lineup.find(
                (l: CreateMatchPlayerCommandOfMatchPlayer): boolean => l.number === i
            );
            const lineupPlayerEvents = lineupPlayer
                ? events.filter((e: EventVm): boolean => getPlayerNumber(e) === lineupPlayer.number)
                : [];
            lineupArray.push(
                <MatchLineupRow
                    match={match}
                    key={i}
                    isHomeTeam={isHomeTeam}
                    isSelectedKeeper={lineupPlayer ? selectedKeeper === lineupPlayer.number : false}
                    cap={lineupPlayer?.number || i}
                    capNumber={lineupPlayer?.capNumber || lineupPlayer?.number || i}
                    lineupPlayer={lineupPlayer}
                    events={lineupPlayerEvents}
                    initEvent={initEvent}
                    selectPlayer={onPlayerSelect}
                />
            );
        }

        return lineupArray;
    };

    private createCoaches = (): React.ReactNode => {
        const { match, coaches, events, isHomeTeam, initEvent } = this.props;

        return coaches
            .sort(compareCoaches)
            .map(
                (c: MatchCoachVm): React.ReactElement => (
                    <MatchCoachLineupRow
                        match={match}
                        events={events.filter(
                            (e: EventVm): boolean => e.card?.cardedCoachId === c.id
                        )}
                        isHomeTeam={isHomeTeam}
                        coach={c}
                        key={c.id}
                        initEvent={initEvent}
                    />
                )
            );
    };

    public render = (): React.ReactElement => {
        const { isHomeTeam } = this.props;
        return (
            <div style={{ textAlign: isHomeTeam ? 'left' : 'right' }}>
                {this.createLineup()}
                <br />
                {this.createCoaches()}
            </div>
        );
    };
}

export default MatchLineup;
