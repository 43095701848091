import { CreateFederationOfficialCommandOfFederationOfficial } from '../utils/api';

export const updateFederationRolesHelper = (
    federationRole: CreateFederationOfficialCommandOfFederationOfficial,
    federationRoles?: CreateFederationOfficialCommandOfFederationOfficial[]
): CreateFederationOfficialCommandOfFederationOfficial[] =>
    federationRoles ? [...federationRoles, federationRole] : [federationRole];

export const removeFederationRoleFromListHelper = (
    federationRole: CreateFederationOfficialCommandOfFederationOfficial,
    federationRoles?: CreateFederationOfficialCommandOfFederationOfficial[]
): CreateFederationOfficialCommandOfFederationOfficial[] | undefined => {
    const newFederationRoles = federationRoles ? [...federationRoles] : undefined;
    const federationRoleToDeleteIndex = newFederationRoles
        ? newFederationRoles.findIndex(
              (fR: CreateFederationOfficialCommandOfFederationOfficial): boolean =>
                  fR.roles === federationRole.roles &&
                  fR.federationId === federationRole.federationId &&
                  fR.officialId === federationRole.officialId &&
                  fR.activeFrom === federationRole.activeFrom &&
                  fR.activeTo === federationRole.activeTo
          )
        : -1;
    if (federationRoleToDeleteIndex > -1 && newFederationRoles) {
        newFederationRoles.splice(federationRoleToDeleteIndex, 1);
        return newFederationRoles;
    }
    return undefined;
};
