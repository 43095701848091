import { PlusOutlined } from '@ant-design/icons';
import { Button, Drawer, PageHeader, Tabs } from 'antd';
import React from 'react';
import PageHeaderTitle from '../../../components/page-header';
import { DrawerWidth } from '../../../config/dimensions';
import Routes from '../../../config/routes';
import ReplaceStrings from '../../../config/replaceStrings';
import { ActionOption, DrawerState, ModuleName } from '../../../core/models/enum';
import { isUserAllowed } from '../../../helpers/CheckPermissionHelper';
import { errorWithUserMessage, success } from '../../../helpers/NotificationHelper';
import { getNewRoute, getSubPageInitialProperties } from '../../../helpers/RoutingHelper';
import {
    transformCoachClubsToCoaches,
    transformPlayerClubsToPlayers,
} from '../../../helpers/TransformHelper';
import {
    ApiException,
    ClubCoachesClient,
    ClubCoachVm,
    ClubDetailVm,
    ClubPlayersClient,
    ClubPlayerVm,
    ClubsClient,
} from '../../../utils/api';
import CoachForm from '../../coaches/coach-form';
import CoachTable from '../../coaches/coach-table';
import PlayerForm from '../../players/player-form';
import PlayerTable from '../../players/player-table';
import TeamForm from '../../teams/team-form';
import TeamTable from '../../teams/team-table';
import ClubDetails from './club-details';
import { Props } from './index';

const { TabPane } = Tabs;

interface State {
    club?: ClubDetailVm;
    activeTabKey: string;
    isEditMode: boolean;
    id?: string;
    loading: boolean;
    drawerState: DrawerState;
    selectedClubPlayer?: ClubPlayerVm;
    selectedClubCoach?: ClubCoachVm;
    isUserAllowedToCreateCoach: boolean;
    isUserAllowedToCreatePlayer: boolean;
    isUserAllowedToCreateTeam: boolean;
}

class ClubPage extends React.PureComponent<Props, State> {
    private coachTableRef: any;
    private playerTableRef: any;
    private teamTableRef: any;

    public constructor(props: Props) {
        super(props);

        const {
            location: { pathname },
        } = window;
        this.coachTableRef = React.createRef();
        this.playerTableRef = React.createRef();
        this.teamTableRef = React.createRef();

        const initialProperties = getSubPageInitialProperties(
            pathname,
            'clubs',
            props.match,
            Routes.ROUTE_CLUBS_READ,
            Routes.ROUTE_CLUBS_EDIT
        );

        this.state = {
            activeTabKey: initialProperties.activeTabKey || Routes.ROUTE_CLUBS_READ,
            id: initialProperties.initialEntityId,
            isEditMode: initialProperties.isInitialEditMode,
            drawerState:
                initialProperties.activeTabKey &&
                initialProperties.activeTabKey !== Routes.ROUTE_CLUBS_READ &&
                initialProperties.isInitialEditMode
                    ? DrawerState.Edit
                    : DrawerState.Closed,
            loading: true,
            isUserAllowedToCreateCoach: false,
            isUserAllowedToCreatePlayer: false,
            isUserAllowedToCreateTeam: false,
        };
    }

    public componentDidMount = (): void => {
        this.getPermissions();
        this.getClubDetails();
    };

    public componentDidUpdate = (prevProps: Props): void => {
        const {
            match: { path },
        } = this.props;

        if (path !== prevProps.match.path) {
            this.updateStateAfterRouteChanges();
        }
    };

    private getPermissions = (): void => {
        const { userProfile } = this.props;

        this.setState({
            isUserAllowedToCreateCoach: isUserAllowed(
                userProfile,
                ModuleName.Coaches,
                ActionOption.Create
            ),
            isUserAllowedToCreatePlayer: isUserAllowed(
                userProfile,
                ModuleName.Players,
                ActionOption.Create
            ),
            isUserAllowedToCreateTeam: isUserAllowed(
                userProfile,
                ModuleName.Teams,
                ActionOption.Create
            ),
        });
    };

    private updateStateAfterRouteChanges = (): void => {
        const { match } = this.props;
        const { club, selectedClubCoach, selectedClubPlayer } = this.state;
        const {
            location: { pathname },
        } = window;

        const initialProperties = getSubPageInitialProperties(
            pathname,
            'clubs',
            match,
            Routes.ROUTE_CLUBS_READ,
            Routes.ROUTE_CLUBS_EDIT
        );

        var newSelectedClubCoach = match.params.coachId ? club?.coaches.find(
            (cc: ClubCoachVm): boolean => cc.coachId.toString() === match.params.coachId
        ) : selectedClubCoach;

        var newSelectedClubPlayer = match.params.playerId ? club?.players.find(
            (cp: ClubPlayerVm): boolean => cp.playerId.toString() === match.params.playerId
        ) : selectedClubPlayer;

        this.setState({
            activeTabKey: initialProperties.activeTabKey || Routes.ROUTE_CLUBS_READ,
            id: initialProperties.initialEntityId,
            isEditMode: initialProperties.isInitialEditMode,
            drawerState:
                initialProperties.activeTabKey &&
                initialProperties.activeTabKey !== Routes.ROUTE_CLUBS_READ &&
                initialProperties.isInitialEditMode
                    ? DrawerState.Edit
                    : DrawerState.Closed,
            selectedClubCoach: newSelectedClubCoach,
            selectedClubPlayer: newSelectedClubPlayer
        });
    };

    private getClubDetails = async (section?: string): Promise<void> => {
        const {
            match: {
                params: { clubId, coachId, playerId },
            },
        } = this.props;

        if (clubId) {
            const clubClient = new ClubsClient();
            const clubDetails = await clubClient.getById(+clubId);
            if (coachId) {
                var selectedClubCoach = clubDetails.coaches.find(
                    (cc: ClubCoachVm): boolean => cc.coachId.toString() === coachId
                );
            }
            if (playerId) {
                var selectedClubPlayer = clubDetails.players.find(
                    (cp: ClubPlayerVm): boolean => cp.playerId.toString() === playerId
                );
            }

            this.setState({
                club: clubDetails,
                selectedClubCoach,
                selectedClubPlayer

            });
        }

        if (section === 'coaches' && this.coachTableRef && this.coachTableRef.current) {
            this.coachTableRef.current.getCoaches();
        } else if (section === 'players' && this.playerTableRef && this.playerTableRef.current) {
            this.playerTableRef.current.getPlayers();
        } else if (section === 'teams' && this.teamTableRef && this.teamTableRef.current) {
            this.teamTableRef.current.getTeams();
        }

        this.setState({
            loading: false,
        });
    };

    private handleTabClick = (route: string): void => {
        const {
            history,
            match: {
                params: { clubId },
            },
        } = this.props;

        this.setState({
            activeTabKey: route,
            isEditMode: false,
        });

        const newRoute = route.replace(ReplaceStrings.CLUB_ID, clubId);

        history.push(newRoute);
    };

    private handleDrawerMode = (drawerState: DrawerState, section?: string): void => {
        const {
            history,
            match: {
                params: { clubId },
            },
        } = this.props;

        this.setState({
            drawerState,
        });

        if (section === 'coaches') {
            history.push(
                getNewRoute(
                    Routes.ROUTE_CLUB_COACHES,
                    Routes.ROUTE_CLUB_COACHES,
                    Routes.ROUTE_CLUB_COACHES_CREATE,
                    drawerState,
                    ReplaceStrings.CLUB_ID,
                    clubId
                )
            );
        } else if (section === 'players') {
            history.push(
                getNewRoute(
                    Routes.ROUTE_CLUB_PLAYERS,
                    Routes.ROUTE_CLUB_PLAYERS,
                    Routes.ROUTE_CLUB_PLAYERS_CREATE,
                    drawerState,
                    ReplaceStrings.CLUB_ID,
                    clubId
                )
            );
        } else if (section === 'teams') {
            history.push(
                getNewRoute(
                    Routes.ROUTE_CLUB_TEAMS,
                    Routes.ROUTE_CLUB_TEAMS,
                    Routes.ROUTE_CLUB_TEAMS_CREATE,
                    drawerState,
                    ReplaceStrings.CLUB_ID,
                    clubId
                )
            );
        }
    };

    private handleClubPlayerDelete = async (id?: number): Promise<void> => {
        const { club } = this.state;

        if (!id) return;

        try {
            const clubPlayers = club?.players || [];
            const clubPlayer = clubPlayers.find((cp: ClubPlayerVm): boolean => cp.playerId === id);

            if (!clubPlayer?.id) return;

            const client = new ClubPlayersClient();
            const result = await client.delete(clubPlayer.id);

            if (!result) return;
            success('Club player successfully deleted.');

            await this.getClubDetails();
            if (this.playerTableRef && this.playerTableRef.current) {
                this.playerTableRef.current.getPlayers();
            }
        } catch (error) {
            if (error instanceof ApiException) {
                errorWithUserMessage(error.response);
            } else {
                errorWithUserMessage('Error deleting data.');
            }    
        }
    };

    private handleClubCoachDelete = async (id?: number): Promise<void> => {
        const { club } = this.state;

        if (!id) return;

        try {
            const clubCoaches = club?.coaches || [];
            const clubCoach = clubCoaches.find((cc: ClubCoachVm): boolean => cc.coachId === id);

            if (!clubCoach?.id) return;
            const client = new ClubCoachesClient();
            const result = await client.delete(clubCoach.id);

            if (!result) return;
            success('Club coach successfully deleted.');

            await this.getClubDetails();
            if (this.coachTableRef && this.coachTableRef.current) {
                this.coachTableRef.current.getCoaches();
            }
        } catch (error) {
            if (error instanceof ApiException) {
                errorWithUserMessage(error.response);
            } else {
                errorWithUserMessage('Error deleting data.');
            }    
        }
    };

    public render(): React.ReactElement {
        let { userProfile } = this.props;
        const {
            activeTabKey,
            club,
            isEditMode,
            drawerState,
            selectedClubPlayer,
            selectedClubCoach,
            isUserAllowedToCreateCoach,
            isUserAllowedToCreatePlayer,
            isUserAllowedToCreateTeam,
        } = this.state;

        return (
            <div>
                <PageHeader
                    title={
                        <PageHeaderTitle
                            headerTitle={club ? club.nameUniversal || club.name || '' : ''}
                            headerPhoto={club && club.logo ? club.logo : undefined}
                            tableName="club"
                            imgHeight={45}
                        />
                    }
                    style={{ padding: '16px 0px' }}
                />
                <div className="tab-padding">
                    <div
                        style={{
                            overflow: 'hidden',
                            width: '100%',
                            transition: 'width .3s cubic-bezier',
                        }}
                    >
                        <Tabs
                            style={{ width: '100%' }}
                            activeKey={activeTabKey}
                            onTabClick={(key: string): void => this.handleTabClick(key)}
                        >
                            <TabPane tab="Details" key={Routes.ROUTE_CLUBS_READ}>
                                <div>
                                    {club && (
                                        <ClubDetails
                                            userProfile={userProfile}
                                            isInitialEdit={isEditMode}
                                            club={club}
                                            refreshAfterSave={this.getClubDetails}
                                        />
                                    )}
                                </div>
                            </TabPane>
                            <TabPane tab="Teams" key={Routes.ROUTE_CLUB_TEAMS}>
                                <div>
                                    <PageHeader
                                        title=""
                                        extra={
                                            isUserAllowedToCreateTeam
                                                ? [
                                                      <Button
                                                          type="primary"
                                                          style={{
                                                              zIndex: 10,
                                                              float: 'right',
                                                          }}
                                                          onClick={(): void =>
                                                              this.handleDrawerMode(
                                                                  DrawerState.Edit,
                                                                  'teams'
                                                              )
                                                          }
                                                      >
                                                          <PlusOutlined className="button-icon-display" />
                                                          Add team
                                                      </Button>,
                                                  ]
                                                : []
                                        }
                                        style={{ padding: '16px 0px' }}
                                    />
                                    {club && club.teams && club.teams.length > 0 ? (
                                        <TeamTable
                                            userProfile={userProfile}
                                            teamsFromProps={club.teams}
                                            moduleName={ModuleName.Clubs}
                                            wrappedComponentRef={this.teamTableRef}
                                        />
                                    ) : (
                                        <div>There are no teams in this club.</div>
                                    )}
                                </div>
                            </TabPane>
                            <TabPane tab="Coaches" key={Routes.ROUTE_CLUB_COACHES}>
                                <div>
                                    <PageHeader
                                        title=""
                                        extra={
                                            isUserAllowedToCreateCoach
                                                ? [
                                                      <Button
                                                          type="primary"
                                                          style={{
                                                              zIndex: 10,
                                                              float: 'right',
                                                          }}
                                                          onClick={(): void =>
                                                              this.handleDrawerMode(
                                                                  DrawerState.Edit,
                                                                  'coaches'
                                                              )
                                                          }
                                                      >
                                                          <PlusOutlined className="button-icon-display" />
                                                          Add coach
                                                      </Button>,
                                                  ]
                                                : []
                                        }
                                        style={{ padding: '16px 0px' }}
                                    />
                                    {club && club.coaches && club.coaches.length > 0 ? (
                                        <CoachTable
                                            userProfile={userProfile}
                                            onDelete={this.handleClubCoachDelete}
                                            coachesFromProps={transformCoachClubsToCoaches(
                                                club.coaches
                                            )}
                                            wrappedComponentRef={this.coachTableRef}
                                            clubId={club.id}
                                        />
                                    ) : (
                                        <div>There are no coaches in this club.</div>
                                    )}
                                </div>
                            </TabPane>
                            <TabPane tab="Players" key={Routes.ROUTE_CLUB_PLAYERS}>
                                <div>
                                    <PageHeader
                                        title=""
                                        extra={
                                            isUserAllowedToCreatePlayer
                                                ? [
                                                      <Button
                                                          type="primary"
                                                          style={{
                                                              zIndex: 10,
                                                              float: 'right',
                                                          }}
                                                          onClick={(): void =>
                                                              this.handleDrawerMode(
                                                                  DrawerState.Edit,
                                                                  'players'
                                                              )
                                                          }
                                                      >
                                                          <PlusOutlined className="button-icon-display" />
                                                          Add player
                                                      </Button>,
                                                  ]
                                                : []
                                        }
                                        style={{ padding: '16px 0px' }}
                                    />
                                    {club && club.players && club.players.length > 0 ? (
                                        <PlayerTable
                                            userProfile={userProfile}
                                            onDelete={this.handleClubPlayerDelete}
                                            playersFromProps={transformPlayerClubsToPlayers(
                                                club.players
                                            )}
                                            wrappedComponentRef={this.playerTableRef}
                                            clubId={club.id}
                                        />
                                    ) : (
                                        <div>There are no players in this club.</div>
                                    )}
                                </div>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
                <Drawer
                    title={
                        activeTabKey === Routes.ROUTE_CLUB_COACHES
                            ? selectedClubCoach
                                ? 'Edit coach'
                                : 'Add coach'
                            : activeTabKey === Routes.ROUTE_CLUB_PLAYERS
                            ? selectedClubPlayer
                                ? 'Edit player'
                                : 'Add player'
                            : 'Add team'
                    }
                    visible={!!drawerState}
                    onClose={(): void =>
                        this.handleDrawerMode(
                            DrawerState.Closed,
                            activeTabKey === Routes.ROUTE_CLUB_COACHES
                                ? 'coaches'
                                : activeTabKey === Routes.ROUTE_CLUB_PLAYERS
                                ? 'players'
                                : 'teams'
                        )
                    }
                    width={DrawerWidth}
                    destroyOnClose
                >
                    {activeTabKey === Routes.ROUTE_CLUB_COACHES && club && (
                        <CoachForm
                            handleClose={(): void => {
                                this.handleDrawerMode(DrawerState.Closed, 'coaches');
                                this.setState({
                                    selectedClubCoach: undefined,
                                });
                            }}
                            refreshAfterSave={(): Promise<void> => this.getClubDetails('coaches')}
                            initialValues={{
                                clubId: club && club.id ? club.id : undefined,
                                coaches:
                                    club && club.coaches && club.coaches.length > 0
                                        ? transformCoachClubsToCoaches(club.coaches)
                                        : undefined,
                            }}
                            clubCoach={selectedClubCoach}
                        />
                    )}
                    {activeTabKey === Routes.ROUTE_CLUB_PLAYERS && club && (
                        <PlayerForm
                            handleClose={(): void => {
                                this.handleDrawerMode(DrawerState.Closed, 'players');
                                this.setState({
                                    selectedClubPlayer: undefined,
                                });
                            }}
                            refreshAfterSave={(): Promise<void> => this.getClubDetails('players')}
                            initialValues={{
                                clubId: club && club.id ? club.id : undefined,
                                players:
                                    club && club.players && club.players.length > 0
                                        ? transformPlayerClubsToPlayers(club.players)
                                        : undefined,
                            }}
                            clubPlayer={selectedClubPlayer}
                        />
                    )}
                    {activeTabKey === Routes.ROUTE_CLUB_TEAMS && club && (
                        <TeamForm
                            handleClose={(): void =>
                                this.handleDrawerMode(DrawerState.Closed, 'teams')
                            }
                            refreshAfterSave={(): Promise<void> => this.getClubDetails('teams')}
                            initialValues={{
                                clubId: club && club.id ? club.id : undefined,
                            }}
                        />
                    )}
                </Drawer>
            </div>
        );
    }
}

export default ClubPage;
