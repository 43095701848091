import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ModuleName } from '../../../core/models/enum';
import { TeamVm } from '../../../utils/api';
import { RouteParams } from '../../../core/models/RouteHelper';
import TeamTable from './TeamTable';
import { Profile } from 'oidc-client';

interface OwnProps {
    userProfile: Profile;
    wrappedComponentRef?: any;
    moduleName?: ModuleName;
    teamsFromProps?: TeamVm[];
    shouldDisplayEditDelete?: boolean;
    onDelete?: (id?: number) => Promise<void>;
    competitionId?: number
}

export type Props = OwnProps & RouteComponentProps<RouteParams>;

export default withRouter(TeamTable);
