import React, { Component, Fragment } from 'react';
import { Route } from 'react-router';
import { Login } from './Login';
import { Logout } from './Logout';
import { AuthorizationRoutes, LoginActions, LogoutActions } from './ApiAuthorizationConstants';

export default class ApiAuthorizationRoutes extends Component {
    render() {
        return (
            <Fragment>
                <Route
                    path={AuthorizationRoutes.Login}
                    render={() => loginAction(LoginActions.Login)}
                />
                <Route
                    path={AuthorizationRoutes.LoginFailed}
                    render={() => loginAction(LoginActions.LoginFailed)}
                />
                <Route
                    path={AuthorizationRoutes.LoginCallback}
                    render={() => loginAction(LoginActions.LoginCallback)}
                />
                <Route
                    path={AuthorizationRoutes.Profile}
                    render={() => loginAction(LoginActions.Profile)}
                />
                {/* <Route
                    path={AuthorizationRoutes.Register}
                    render={() => loginAction(LoginActions.Register)}
                /> */}
                <Route
                    path={AuthorizationRoutes.LogOut}
                    render={() => logoutAction(LogoutActions.Logout)}
                />
                <Route
                    path={AuthorizationRoutes.LogOutCallback}
                    render={() => logoutAction(LogoutActions.LogoutCallback)}
                />
                <Route
                    path={AuthorizationRoutes.LoggedOut}
                    render={() => logoutAction(LogoutActions.LoggedOut)}
                />
            </Fragment>
        );
    }
}

function loginAction(name: string) {
    return <Login action={name}></Login>;
}

function logoutAction(name: string) {
    return <Logout action={name}></Logout>;
}
