import React from "react";
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { EventVm } from "../../utils/api";
import { Form, Input, Row } from "antd";

interface Props { }

interface State {
  connection?: HubConnection;
  event?: EventVm;
  matchId?: number
}

class Socket extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      connection: undefined,
      event: undefined,
      matchId: undefined,
    }
  }

  public componentDidMount(): void {
    const newConnection = new HubConnectionBuilder()
      .withUrl("https://localhost:44347/hubs/match")
      .withAutomaticReconnect()
      .build()

    newConnection.onreconnected(this.connectionSetup)

    this.setState({ connection: newConnection });
  }

  private connectionSetup = () => {
    const { connection, matchId } = this.state;

    if (!connection || !matchId) {
      console.log("Connection set up is called, but no connection or match id is present")
      return;
    }

    connection.invoke("JoinGroup", matchId.toString())

    connection.on("Event", (message: any) => {
      this.setState({
        event: message.event
      })
    })
  }

  public componentDidUpdate(): void {
    const { connection, matchId } = this.state;

    if (connection && matchId && connection.state === HubConnectionState.Disconnected) {
      connection.start()
        .then(this.connectionSetup)
        .catch((e: any) => console.log("Connection failed ::" + e))
    }
  }

  public onBlur = (event: any) => {
    this.setState({ matchId: event.target.value })
  }

  public render(): React.ReactElement {
    const { event, matchId } = this.state;

    return <>
      <Row>
        <Form.Item>
          <Input value={matchId} onBlur={this.onBlur} />
        </Form.Item>
      </Row>
      <p>Event ({matchId ?? '...'}): {JSON.stringify(event) ?? 'pending'}</p>
    </>
  }
}


export default Socket;