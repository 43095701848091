import { Col, Drawer, Form, Input, Row } from 'antd';
import React from 'react';
import DetailsButtons from '../../../../components/details-buttons';
import { DrawerWidth, DrawerWidthSmall } from '../../../../config/dimensions';
import Routes from '../../../../config/routes';
import ReplaceStrings from '../../../../config/replaceStrings';
import { DrawerState, ModuleName } from '../../../../core/models/enum';
import { formItemLayout724 } from '../../../../helpers/FormLayoutHelper';
import { getNewRoute } from '../../../../helpers/RoutingHelper';
import { handleDeleteHelper } from '../../../../helpers/SubmitHelper';
import { OfficialsClient } from '../../../../utils/api';
import OfficialForm from '../../official-form';
import OfficialUpdatePINForm from '../../official-update-pin';
import { Props } from './index';

interface State {
    drawerState: DrawerState;
    updatePINDrawerState: DrawerState;
    loading: boolean;
}

class OfficialDetails extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            drawerState: props.isInitialEdit ? DrawerState.Edit : DrawerState.Closed,
            updatePINDrawerState: DrawerState.Closed,
            loading: true,
        };
    }

    private handleDrawerMode = (drawerState: DrawerState, id?: string): void => {
        const { history } = this.props;

        this.setState({
            drawerState,
        });

        history.push(
            getNewRoute(
                Routes.ROUTE_OFFICIALS,
                Routes.ROUTE_OFFICIALS_READ,
                Routes.ROUTE_OFFICIALS_EDIT,
                drawerState,
                ReplaceStrings.OFFICIAL_ID,
                id
            )
        );
    };

    private openForm = (): void => {
        const { official } = this.props;
        this.handleDrawerMode(DrawerState.Edit, official.id.toString());
    };

    private handleDelete = (): void => {
        const { history } = this.props;

        history.push(Routes.ROUTE_OFFICIALS);
    };

    public render(): React.ReactElement {
        const { drawerState, updatePINDrawerState } = this.state;
        const { userProfile, official, refreshAfterSave } = this.props;

        return (
            <div>
                <DetailsButtons
                    userProfile={userProfile}
                    onDeleteAction={(): Promise<void> =>
                        handleDeleteHelper(
                            new OfficialsClient(),
                            'Official successfully deleted.',
                            this.handleDelete,
                            official.id
                        )
                    }
                    onUpdatePINAction={(): void =>
                        this.setState({
                            updatePINDrawerState: DrawerState.Edit,
                        })
                    }
                    onEditAction={this.openForm}
                    customMessage="Are you sure you want to delete this official?"
                    moduleName={ModuleName.Officials}
                    entityId={official.id}
                />
                <Row className="details-height">
                    <Col span={12}>
                        <Form {...formItemLayout724}>
                            <Form.Item label="Title">
                                <Input readOnly value={official.title} />
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
                <Drawer
                    title={drawerState === DrawerState.Edit ? 'Edit official' : 'Change PIN'}
                    visible={drawerState === DrawerState.Edit || !!updatePINDrawerState}
                    onClose={(): void =>
                        !!drawerState
                            ? this.handleDrawerMode(DrawerState.Read, official.id.toString())
                            : this.setState({
                                  updatePINDrawerState: DrawerState.Closed,
                              })
                    }
                    width={drawerState === DrawerState.Edit ? DrawerWidth : DrawerWidthSmall}
                    destroyOnClose
                >
                    {drawerState === DrawerState.Edit ? (
                        <OfficialForm
                            handleClose={this.handleDrawerMode}
                            refreshAfterSave={refreshAfterSave}
                            official={official}
                        />
                    ) : (
                        <OfficialUpdatePINForm
                            handleClose={(): void =>
                                this.setState({
                                    updatePINDrawerState: DrawerState.Closed,
                                })
                            }
                        />
                    )}
                </Drawer>
            </div>
        );
    }
}

export default OfficialDetails;
