import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import React from 'react';
import { popupConfirmDeleteWithCallback } from '../../helpers/NotificationHelper';
import { Props } from './index';

class TableActionButtons extends React.PureComponent<Props> {
    public constructor(props: Props) {
        super(props);
    }

    public render(): React.ReactElement {
        const {
            onDeleteAction,
            onUpdateAction,
            confirmMessage,
            id,
            isUserAllowedToDelete,
            isUserAllowedToUpdate,
        } = this.props;

        return (
            <>
                {onUpdateAction && isUserAllowedToUpdate && (
                    <EditOutlined
                        onClick={(): void => {
                            onUpdateAction(id);
                        }}
                        style={{ color: '#1890ff' }}
                    />
                )}
                {onDeleteAction && isUserAllowedToDelete && (
                    <DeleteOutlined
                        onClick={(): void =>
                            popupConfirmDeleteWithCallback(
                                (): Promise<void> => onDeleteAction(id),
                                undefined,
                                confirmMessage || 'Are you sure you want to remove this entity?',
                                'Remove'
                            )
                        }
                        className="text-danger-custom"
                        style={{ marginLeft: 10 }}
                    />
                )}
            </>
        );
    }
}

export default TableActionButtons;
