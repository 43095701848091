import { UploadOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, DatePicker, Form, Input, Row, Upload } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import moment from 'moment';
import React from 'react';
import AutocompleteDropdown from '../../../components/autocomplete-dropdown';
import DrawerButtons from '../../../components/drawer-buttons/index';
import { DropdownOption } from '../../../core/models/DropdownOption';
import { DrawerState } from '../../../core/models/enum';
import {
    confirmDropdownHelper,
    filterDropdownOptions,
    getCitiesHelper,
    getCountriesHelper,
} from '../../../helpers/AutocompleteDropdownHelper';
import { convertUTCTimeToLocal } from '../../../helpers/DateHelper';
import {
    getCategoriesToDropdownOptionsMultipleInitials,
    getFederationsToDropdownOptions,
} from '../../../helpers/FetchAndTransformHelpers';
import { onUploadFileChangeHelper } from '../../../helpers/FileHelper';
import { formItemLayout724 } from '../../../helpers/FormLayoutHelper';
import { success } from '../../../helpers/NotificationHelper';
import { createHelper, updateHelper } from '../../../helpers/SubmitHelper';
import { ClubsClient, FederationCategoryVm, FederationsClient } from '../../../utils/api';
import { Props } from './index';

interface State {
    isSaving: boolean;
    countries: DropdownOption[];
    cities: DropdownOption[];
    federations: DropdownOption[];
    categories: DropdownOption[];
    selectedCountry?: DropdownOption[];
    selectedCity?: DropdownOption[];
    selectedFederation?: DropdownOption[];
    selectedCategories?: DropdownOption[];
    isPro: boolean;
    file?: any;
    fileBase64?: string;
}

class ClubForm extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        const { club } = props;

        this.state = {
            isSaving: false,
            countries: [],
            cities: [],
            federations: [],
            categories: [],
            isPro: club ? club.isPro : false,
            fileBase64: club ? club.logo : undefined,
        };
    }

    public componentDidMount = (): void => {
        const { club } = this.props;

        this.getCountries();
        if (!(club && club.countryId)) {
            this.getCities();
        }
        this.getFederations();
        this.getCategories();
    };

    private getCountries = async (): Promise<void> => {
        const { club } = this.props;
        const countryId = club && club.countryId;

        const countryHelper = await getCountriesHelper(countryId);

        if (countryId) {
            this.getCities(countryId);
        }

        this.setState({
            countries: countryHelper.entities,
            selectedCountry: countryHelper.selectedEntity,
        });
    };

    private getCities = async (countryId?: number): Promise<void> => {
        const { club } = this.props;
        const cityId = club && club.cityId;

        const cityHelper = await getCitiesHelper(countryId, cityId);

        this.setState({
            cities: cityHelper.entities,
            selectedCity: cityHelper.selectedEntity,
        });
    };

    private getFederations = async (): Promise<void> => {
        const { club, initialValues } = this.props;
        const preSelectedFederationId = initialValues && initialValues.federationId;
        const federationId = club && club.federationId;
        const results = await getFederationsToDropdownOptions(
            federationId || preSelectedFederationId
        );

        this.setState({
            federations: results.entities,
            selectedFederation: results.selectedEntity,
        });
    };

    private getCategories = async (): Promise<void> => {
        const { initialValues } = this.props;
        const federationClient = new FederationsClient();
        const federation =
            initialValues && initialValues.federationId
                ? await federationClient.getById(initialValues.federationId)
                : undefined;

        const results = await getCategoriesToDropdownOptionsMultipleInitials(
            federation && federation.categories && federation.categories.length > 0
                ? federation.categories.map((c: FederationCategoryVm): number => c.categoryId)
                : undefined
        );

        this.setState({
            categories: results.entities,
            selectedCategories: results.selectedEntity,
        });
    };

    private onSubmit = (values: any): void => {
        const {
            selectedCountry,
            selectedCity,
            selectedFederation,
            selectedCategories,
            isPro,
            fileBase64,
        } = this.state;
        const { club } = this.props;
        const clubClient = new ClubsClient();

        this.setState({
            isSaving: true,
        });

        const request = {
            ...values,
            id: club ? club.id : undefined,
            activeFrom:
                values && values.activeFrom
                    ? moment(values.activeFrom.toLocaleString())
                    : undefined,
            activeTo:
                values && values.activeTo ? moment(values.activeTo.toLocaleString()) : undefined,
            countryId:
                selectedCountry && selectedCountry.length > 0
                    ? parseInt(selectedCountry[0].id, 10)
                    : undefined,
            cityId:
                selectedCity && selectedCity.length > 0
                    ? parseInt(selectedCity[0].id, 10)
                    : undefined,
            federationId:
                selectedFederation && selectedFederation.length > 0
                    ? parseInt(selectedFederation[0].id, 10)
                    : undefined,
            isPro,
            logo: fileBase64,
            teamCategories:
                selectedCategories && selectedCategories.length > 0
                    ? selectedCategories.map((sC: DropdownOption): number => parseInt(sC.id, 10))
                    : undefined,
        };

        if (request.id) {
            updateHelper(clubClient, request, this.handleSuccessfullySaving);
        } else {
            createHelper(clubClient, request, this.handleSuccessfullySaving);
        }

        this.setState({
            isSaving: false,
        });
    };

    private handleSuccessfullySaving = (): void => {
        const { club, handleClose, refreshAfterSave } = this.props;

        success('Club successfully saved.');
        if (refreshAfterSave) {
            refreshAfterSave();
        }
        handleClose(
            club ? DrawerState.Read : DrawerState.Closed,
            club ? club.id.toString() : undefined
        );
    };

    private confirmCountry = (option: DropdownOption[]): void => {
        const selectedCountry = confirmDropdownHelper(option);
        this.setState({
            selectedCountry,
            selectedCity: undefined,
        });
        if (selectedCountry) {
            this.getCities(parseInt(selectedCountry[0].id, 10));
        }
    };

    private onIsProChange = (e: CheckboxChangeEvent): void => {
        this.setState({
            isPro: e.target.checked,
        });
    };

    private onUploadFileChange = async (file: File): Promise<void> => {
        const uploadHelper = await onUploadFileChangeHelper(file);

        if (uploadHelper) {
            this.setState({
                file: uploadHelper.file,
                fileBase64: uploadHelper.fileBase64,
            });
        }
    };

    public render(): React.ReactElement {
        const {
            isSaving,
            selectedCountry,
            selectedCity,
            selectedFederation,
            selectedCategories,
            isPro,
            file,
            fileBase64,
            countries,
            cities,
            federations,
            categories,
        } = this.state;
        const { handleClose, club, initialValues } = this.props;

        return (
            <Form onFinish={this.onSubmit} {...formItemLayout724}>
                <DrawerButtons
                    isSaving={isSaving}
                    onCancelAction={(): void =>
                        handleClose(
                            club ? DrawerState.Read : DrawerState.Closed,
                            club ? club.id.toString() : undefined
                        )
                    }
                />
                <div className="drawer-content">
                    <Form.Item
                        name="name"
                        label="Name"
                        initialValue={club ? club.name : undefined}
                        rules={[
                            {
                                required: true,
                                message: 'Name field is required!',
                            },
                            { max: 200, message: 'Too long input!' },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="nameUniversal"
                        label="Universal name"
                        initialValue={club ? club.nameUniversal : undefined}
                        rules={[
                            {
                                required: true,
                                message: 'Universal name field is required!',
                            },
                            { max: 200, message: 'Too long input!' },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="shortName"
                        label="Short name"
                        initialValue={club ? club.shortName : undefined}
                        rules={[{ max: 200, message: 'Too long input!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="description"
                        label="Description"
                        initialValue={club ? club.description : undefined}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item name="country" label="Country">
                        <AutocompleteDropdown
                            placeholder="Choose country"
                            getOptionsFrontend={(value: string): DropdownOption[] =>
                                filterDropdownOptions(value, countries)
                            }
                            initialValues={selectedCountry}
                            confirmDirty={this.confirmCountry}
                            style={{ width: '100%' }}
                            isAllowedToClear
                        />
                    </Form.Item>
                    <Form.Item name="city" label="City">
                        <AutocompleteDropdown
                            placeholder="Choose city"
                            getOptionsFrontend={(value: string): DropdownOption[] =>
                                filterDropdownOptions(value, cities)
                            }
                            initialValues={selectedCity}
                            confirmDirty={(options: DropdownOption[]): void =>
                                this.setState({
                                    selectedCity: confirmDropdownHelper(options),
                                })
                            }
                            style={{ width: '100%' }}
                            isAllowedToClear
                        />
                    </Form.Item>
                    <Form.Item name="federation" label="Federation">
                        <AutocompleteDropdown
                            placeholder="Choose federation"
                            getOptionsFrontend={(value: string): DropdownOption[] =>
                                filterDropdownOptions(value, federations)
                            }
                            initialValues={selectedFederation}
                            confirmDirty={(options: DropdownOption[]): void =>
                                this.setState({
                                    selectedFederation: confirmDropdownHelper(options),
                                })
                            }
                            style={{ width: '100%' }}
                            disabled={!!(initialValues && initialValues.federationId)}
                            isAllowedToClear
                        />
                    </Form.Item>
                    {!club && (
                        <Form.Item name="categories" label="Categories">
                            <AutocompleteDropdown
                                placeholder="Choose categories"
                                getOptionsFrontend={(value: string): DropdownOption[] =>
                                    filterDropdownOptions(value, categories)
                                }
                                initialValues={selectedCategories}
                                mode="multiple"
                                confirmDirty={(options: DropdownOption[]): void =>
                                    this.setState({
                                        selectedCategories: confirmDropdownHelper(options),
                                    })
                                }
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    )}
                    <Form.Item
                        name="activeFrom"
                        label="Active from"
                        initialValue={
                            club && club.activeFrom
                                ? moment(convertUTCTimeToLocal(club.activeFrom))
                                : undefined
                        }
                    >
                        <DatePicker format="DD.MM.YYYY." style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                        name="activeTo"
                        label="Active to"
                        initialValue={
                            club && club.activeTo
                                ? moment(convertUTCTimeToLocal(club.activeTo))
                                : undefined
                        }
                    >
                        <DatePicker format="DD.MM.YYYY." style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                        name="president"
                        label="President"
                        initialValue={club ? club.president : undefined}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="venue"
                        label="Venue"
                        initialValue={club ? club.venue : undefined}
                        rules={[{ max: 200, message: 'Too long input!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="address"
                        label="Address"
                        initialValue={club ? club.address : undefined}
                        rules={[{ max: 200, message: 'Too long input!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="phone"
                        label="Phone"
                        initialValue={club ? club.phone : undefined}
                        rules={[
                            {
                                // eslint-disable-next-line
                                pattern: /^[0-9(\)\+]+$/,
                                message: 'Only numbers and plus sign are allowed!',
                            },
                            { max: 100, message: 'Too long input!' },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="web"
                        label="Web"
                        initialValue={club ? club.web : undefined}
                        rules={[{ max: 100, message: 'Too long input!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        label="Email"
                        initialValue={club ? club.email : undefined}
                        rules={[
                            { type: 'email', message: 'Wrong e-mail format!' },
                            { max: 100, message: 'Too long input!' },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="facebook"
                        label="Facebook"
                        initialValue={club ? club.facebook : undefined}
                        rules={[{ max: 100, message: 'Too long input!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="twitter"
                        label="Twitter"
                        initialValue={club ? club.twitter : undefined}
                        rules={[{ max: 100, message: 'Too long input!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="youtube"
                        label="Youtube"
                        initialValue={club ? club.youtube : undefined}
                        rules={[{ max: 100, message: 'Too long input!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="instagram"
                        label="Instagram"
                        initialValue={club ? club.instagram : undefined}
                        rules={[{ max: 100, message: 'Too long input!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="isPro"
                        label="Professional"
                        initialValue={club ? club.isPro : undefined}
                    >
                        <Checkbox checked={isPro} onChange={this.onIsProChange} />
                    </Form.Item>
                    <Form.Item name="logo" label="Logo" initialValue={club ? club.logo : undefined}>
                        {fileBase64 && (
                            <Row>
                                <Col span="24">
                                    <img
                                        height={55}
                                        src={`data:image/jpeg;base64,${fileBase64}`}
                                        alt="logo"
                                    />
                                </Col>
                                <Col span="24">
                                    <Button
                                        type="primary"
                                        danger
                                        style={{ marginTop: 10 }}
                                        onClick={(): void =>
                                            this.setState({
                                                file: undefined,
                                                fileBase64: undefined,
                                            })
                                        }
                                    >
                                        Remove logo
                                    </Button>
                                </Col>
                            </Row>
                        )}
                        {!fileBase64 && (
                            <Upload
                                accept="image/png"
                                style={{ zIndex: 10 }}
                                beforeUpload={(file) => {
                                    this.onUploadFileChange(file);
                                    return false;
                                }}
                                onRemove={(): void =>
                                    this.setState({
                                        file: undefined,
                                        fileBase64: undefined,
                                    })
                                }
                                fileList={file ? [{ ...file }] : []}
                            >
                                <Button type="primary" disabled={!!file}>
                                    <UploadOutlined className="button-icon-display" />
                                    Upload image
                                </Button>
                            </Upload>
                        )}
                    </Form.Item>
                </div>
            </Form>
        );
    }
}

export default ClubForm;
