import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import React from 'react';
import { CapNumber } from '../../core/models/CapNumber';

interface Props {
    onSelect: (cap: number) => void;
    caps: CapNumber[];
    activeCap: number | null;
    activeCapNumber: number | null;
    disabled?: boolean;
    capClass: string;
}

class CapSelector extends React.PureComponent<Props> {
    public render = (): React.ReactElement => {
        const { caps, onSelect, activeCap, disabled, capClass } = this.props;
        return (
            <Radio.Group
                className={`cap-selector-wrapper ${capClass}`}
                value={activeCap}
                onChange={(e: RadioChangeEvent): void => onSelect(e.target.value)}
                buttonStyle="solid"
                size="large"
                disabled={disabled}
            >
                {caps
                    .sort((a: CapNumber, b: CapNumber): number => a.capNumber - b.capNumber)
                    .map(
                        (cap: CapNumber): React.ReactNode => (
                            <Radio.Button key={cap.cap} value={cap.cap}>
                                {cap.capNumber}
                            </Radio.Button>
                        )
                    )}
            </Radio.Group>
        );
    };
}

export default CapSelector;
