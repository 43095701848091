import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, DatePicker, Drawer, Form, InputNumber, Table } from 'antd';
import moment from 'moment';
import React from 'react';
import { DrawerWidth } from '../../config/dimensions';
import { DropdownOption } from '../../core/models/DropdownOption';
import { DrawerState, ModuleName } from '../../core/models/enum';
import {
    confirmDropdownHelper,
    filterDropdownOptions,
} from '../../helpers/AutocompleteDropdownHelper';
import { convertUTCTimeToLocal } from '../../helpers/DateHelper';
import {
    getCoachRoles,
    getPlayerPositions,
    getTeamsToDropdownOptions,
} from '../../helpers/FetchAndTransformHelpers';
import { formItemLayout724 } from '../../helpers/FormLayoutHelper';
import { customArrayValidationHelper } from '../../helpers/ValidationHelper';
import {
    CreateTeamCoachCommandOfTeamCoach,
    CreateTeamPlayerCommandOfTeamPlayer,
} from '../../utils/api';
import AutocompleteDropdown from '../autocomplete-dropdown';
import DrawerButtons from '../drawer-buttons/index';
import { Props } from './index';

interface State {
    drawerState: DrawerState;
    teams: DropdownOption[];
    coachRoles: DropdownOption[];
    primaryPositions: DropdownOption[];
    selectedRole?: DropdownOption[];
    selectedTeam?: DropdownOption[];
    selectedTeamPosition?: DropdownOption[];
}

class PersonTeams extends React.PureComponent<Props, State> {
    private formRef: any;

    public constructor(props: Props) {
        super(props);

        this.formRef = React.createRef();

        this.state = {
            drawerState: DrawerState.Closed,
            coachRoles: [],
            teams: [],
            primaryPositions: [],
        };
    }

    public componentDidMount = (): void => {
        this.getCoachRoles();
        this.getTeams();
        this.getPrimaryPositions();
    };

    private getCoachRoles = async (): Promise<void> => {
        const results = await getCoachRoles();

        this.setState({
            coachRoles: results.entities,
        });
    };

    private getTeams = async (): Promise<void> => {
        const { initialTeamId } = this.props;
        const results = await getTeamsToDropdownOptions(initialTeamId);

        this.setState({
            teams: results.entities,
            selectedTeam: results.selectedEntity,
        });
    };

    private getPrimaryPositions = async (): Promise<void> => {
        const results = await getPlayerPositions();

        this.setState({
            primaryPositions: results.entities,
        });
    };

    private onSave = (values: any): void => {
        const { selectedTeam, selectedRole, selectedTeamPosition, teams } = this.state;
        const { saveCoachChanges, savePlayerChanges, initialTeamId } = this.props;

        if (
            selectedTeam &&
            selectedTeam.length > 0 &&
            selectedRole &&
            selectedRole.length > 0 &&
            saveCoachChanges
        ) {
            const team = new CreateTeamCoachCommandOfTeamCoach({
                teamId: parseInt(selectedTeam[0].id, 10),
                activeFrom: values.activeFromTeam,
                activeTo: values.activeToTeam,
                roleId: parseInt(selectedRole[0].id, 10),
                coachId: 0,
            });

            let newTeams = [...teams];
            const selectedTeamIndex = teams.findIndex((x) => x.id === selectedTeam[0].id);
            newTeams[selectedTeamIndex].disabled = true;

            saveCoachChanges(team);
            this.handleDrawerMode(DrawerState.Closed);
            this.setState({
                selectedRole: undefined,
                selectedTeamPosition: undefined,
                teams: newTeams,
            });
            if (!initialTeamId) {
                this.setState({
                    selectedTeam: undefined,
                });
            }
        } else if (
            selectedTeam &&
            selectedTeam.length > 0 &&
            selectedTeamPosition &&
            selectedTeamPosition.length > 0 &&
            savePlayerChanges
        ) {
            const team = new CreateTeamPlayerCommandOfTeamPlayer({
                teamId: parseInt(selectedTeam[0].id, 10),
                activeFrom: values.activeFromTeam,
                activeTo: values.activeToTeam,
                positionId: parseInt(selectedTeamPosition[0].id, 10),
                playerId: 0,
                capNumber: values.capNumber,
            });

            let newTeams = [...teams];
            const selectedTeamIndex = teams.findIndex((x) => x.id === selectedTeam[0].id);
            newTeams[selectedTeamIndex].disabled = true;

            savePlayerChanges(team);
            this.handleDrawerMode(DrawerState.Closed);
            this.setState({
                selectedRole: undefined,
                selectedTeamPosition: undefined,
                teams: newTeams,
            });
            if (!initialTeamId) {
                this.setState({
                    selectedTeam: undefined,
                });
            }
        }
    };

    private enableTeam = (id: number): void => {
        const { teams } = this.state;
        var newTeams = [...teams];

        const teamIndex = teams.findIndex((x) => x.id === id.toString());
        newTeams[teamIndex].disabled = false;

        this.setState({
            teams: newTeams,
        });
    };

    private handleDrawerMode = (drawerState: DrawerState): void => {
        this.setState({
            drawerState,
        });
    };

    public render(): React.ReactElement {
        const {
            drawerState,
            teams,
            coachRoles,
            primaryPositions,
            selectedTeam,
            selectedRole,
            selectedTeamPosition,
        } = this.state;
        const {
            entityModule,
            initialTeamId,
            selectedCoachTeams,
            selectedPlayerTeams,
            removeCoachTeamFromList,
            removePlayerTeamFromList,
        } = this.props;

        const columnsCoach = [
            {
                title: 'Team',
                dataIndex: 'teamId',
                key: 'teamId',
                render: (value: number): string => {
                    const team = teams.find(
                        (t: DropdownOption): boolean => t.id === value.toString()
                    );
                    return team ? team.name : '';
                },
            },
            {
                title: 'Role',
                dataIndex: 'roleId',
                key: 'roleId',
                render: (value: number): string => {
                    const role = coachRoles.find(
                        (cR: DropdownOption): boolean => cR.id === value.toString()
                    );
                    return role ? role.name : '';
                },
            },
            {
                title: 'Active from',
                dataIndex: 'activeFrom',
                key: 'activeFrom',
                render: (value: Date): string =>
                    value ? moment(convertUTCTimeToLocal(value)).format('DD.MM.YYYY.') : '',
                width: 110,
            },
            {
                title: 'Active to',
                dataIndex: 'activeTo',
                key: 'activeTo',
                render: (value?: Date): string =>
                    value ? moment(convertUTCTimeToLocal(value)).format('DD.MM.YYYY.') : '',
                width: 110,
            },
            {
                title: '',
                dataIndex: '',
                key: '',
                render: (value: CreateTeamCoachCommandOfTeamCoach): React.ReactElement => (
                    <DeleteOutlined
                        onClick={(): void => {
                            if (removeCoachTeamFromList) {
                                removeCoachTeamFromList(value);
                                this.enableTeam(value.teamId);
                            }
                        }}
                        className="text-danger-custom"
                    />
                ),
                width: 31,
            },
        ];

        const columnsPlayer = [
            {
                title: 'Team',
                dataIndex: 'teamId',
                key: 'teamId',
                render: (value: number): string => {
                    const team = teams.find(
                        (t: DropdownOption): boolean => t.id === value.toString()
                    );
                    return team ? team.name : '';
                },
            },
            {
                title: 'Position',
                dataIndex: 'positionId',
                key: 'positionId',
                render: (value: number): string => {
                    const position = primaryPositions.find(
                        (pP: DropdownOption): boolean => pP.id === value.toString()
                    );
                    return position ? position.name : '';
                },
            },
            {
                title: 'Cap number',
                dataIndex: 'capNumber',
                key: 'capNumber',
            },
            {
                title: 'Active from',
                dataIndex: 'activeFrom',
                key: 'activeFrom',
                render: (value: Date): string =>
                    value ? moment(convertUTCTimeToLocal(value)).format('DD.MM.YYYY.') : '',
                width: 110,
            },
            {
                title: 'Active to',
                dataIndex: 'activeTo',
                key: 'activeTo',
                render: (value?: Date): string =>
                    value ? moment(convertUTCTimeToLocal(value)).format('DD.MM.YYYY.') : '',
                width: 110,
            },
            {
                title: '',
                dataIndex: '',
                key: '',
                render: (value: CreateTeamPlayerCommandOfTeamPlayer): React.ReactElement => (
                    <DeleteOutlined
                        onClick={(): void => {
                            if (removePlayerTeamFromList) {
                                removePlayerTeamFromList(value);
                                this.enableTeam(value.teamId);
                            }
                        }}
                        className="text-danger-custom"
                    />
                ),
                width: 31,
            },
        ];

        return (
            <div>
                <Button
                    type="primary"
                    style={{
                        zIndex: 10,
                        float: 'right',
                        marginBottom: 15,
                    }}
                    disabled={
                        !!initialTeamId &&
                        ((!!selectedCoachTeams && selectedCoachTeams.length > 0) ||
                            (!!selectedPlayerTeams && selectedPlayerTeams.length > 0))
                    }
                    onClick={(): void => this.handleDrawerMode(DrawerState.Create)}
                >
                    <PlusOutlined className="button-icon-display" />
                    Add team
                </Button>
                {entityModule === ModuleName.Coaches &&
                    selectedCoachTeams &&
                    selectedCoachTeams.length > 0 && (
                        <Table
                            columns={columnsCoach}
                            dataSource={selectedCoachTeams}
                            bordered
                            pagination={false}
                            size="small"
                        />
                    )}
                {entityModule === ModuleName.Players &&
                    selectedPlayerTeams &&
                    selectedPlayerTeams.length > 0 && (
                        <Table
                            columns={columnsPlayer}
                            dataSource={selectedPlayerTeams}
                            bordered
                            pagination={false}
                            size="small"
                        />
                    )}
                <Drawer
                    title="Add team"
                    visible={!!drawerState}
                    width={DrawerWidth}
                    onClose={(): void => this.handleDrawerMode(DrawerState.Closed)}
                    destroyOnClose
                    className="person-teams-drawer"
                >
                    <Form onFinish={this.onSave} {...formItemLayout724} ref={this.formRef}>
                        <DrawerButtons
                            onCancelAction={(): void => this.handleDrawerMode(DrawerState.Closed)}
                        />
                        <div className="drawer-content">
                            <Form.Item
                                name="teamId"
                                label="Team"
                                rules={[
                                    {
                                        required: true,
                                        validator: (_rule: any, value: any, callback: any): void =>
                                            customArrayValidationHelper(
                                                _rule,
                                                value,
                                                callback,
                                                'Team field is required!',
                                                selectedTeam
                                            ),
                                    },
                                ]}
                            >
                                <AutocompleteDropdown
                                    placeholder="Choose team"
                                    getOptionsFrontend={(value: string): DropdownOption[] =>
                                        filterDropdownOptions(value, teams)
                                    }
                                    initialValues={selectedTeam}
                                    confirmDirty={(options: DropdownOption[]): void =>
                                        this.setState({
                                            selectedTeam: confirmDropdownHelper(options),
                                        })
                                    }
                                    style={{ width: '100%' }}
                                    isAllowedToClear
                                    disabled={!!initialTeamId}
                                />
                            </Form.Item>
                            {entityModule === ModuleName.Coaches && (
                                <Form.Item
                                    name="teamRoleId"
                                    label="Role"
                                    rules={[
                                        {
                                            required: true,
                                            validator: (
                                                _rule: any,
                                                value: any,
                                                callback: any
                                            ): void =>
                                                customArrayValidationHelper(
                                                    _rule,
                                                    value,
                                                    callback,
                                                    'Role field is required!',
                                                    selectedRole
                                                ),
                                        },
                                    ]}
                                >
                                    <AutocompleteDropdown
                                        placeholder="Choose role"
                                        getOptionsFrontend={(value: string): DropdownOption[] =>
                                            filterDropdownOptions(value, coachRoles)
                                        }
                                        initialValues={selectedRole}
                                        confirmDirty={(options: DropdownOption[]): void =>
                                            this.setState({
                                                selectedRole: confirmDropdownHelper(options),
                                            })
                                        }
                                        style={{ width: '100%' }}
                                        isAllowedToClear
                                    />
                                </Form.Item>
                            )}
                            {entityModule === ModuleName.Players && (
                                <>
                                    <Form.Item
                                        name="teamPositionId"
                                        label="Position"
                                        rules={[
                                            {
                                                required: true,
                                                validator: (
                                                    _rule: any,
                                                    value: any,
                                                    callback: any
                                                ): void =>
                                                    customArrayValidationHelper(
                                                        _rule,
                                                        value,
                                                        callback,
                                                        'Position field is required!',
                                                        selectedTeamPosition
                                                    ),
                                            },
                                        ]}
                                    >
                                        <AutocompleteDropdown
                                            placeholder="Choose position"
                                            getOptionsFrontend={(value: string): DropdownOption[] =>
                                                filterDropdownOptions(value, primaryPositions)
                                            }
                                            initialValues={selectedTeamPosition}
                                            confirmDirty={(options: DropdownOption[]): void =>
                                                this.setState({
                                                    selectedTeamPosition: confirmDropdownHelper(
                                                        options
                                                    ),
                                                })
                                            }
                                            style={{ width: '100%' }}
                                            isAllowedToClear
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="capNumber"
                                        label="Cap number"
                                        style={{ width: '100%' }}
                                    >
                                        <InputNumber min={1} max={99} />
                                    </Form.Item>
                                </>
                            )}
                            <Form.Item
                                name="activeFromTeam"
                                label="Active from"
                                initialValue={moment(convertUTCTimeToLocal(new Date()))}
                            >
                                <DatePicker
                                    format="DD.MM.YYYY."
                                    style={{ width: '100%' }}
                                    allowClear={false}
                                />
                            </Form.Item>
                            <Form.Item
                                name="activeToTeam"
                                label="Active to"
                                initialValue={undefined}
                            >
                                <DatePicker format="DD.MM.YYYY." style={{ width: '100%' }} />
                            </Form.Item>
                        </div>
                    </Form>
                </Drawer>
            </div>
        );
    }
}

export default PersonTeams;
