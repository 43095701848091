import { ClockState } from '../../../core/models/enum';
import { CreateEventCommand, EventPeriodEnum, EventVm } from '../../../utils/api';
import MatchClock from './MatchClock';

interface OwnProps {
    event: EventVm | CreateEventCommand | null;
    clockState: ClockState;
    currentPeriod: EventPeriodEnum;
    currentTime: number;
    eventPeriod: EventPeriodEnum;
    eventTime: number;
    isEventValid: boolean;
    startClock: () => void;
    pauseClock: () => void;
    stopClock: () => void;
    resetClock: () => void;
    setPeriod: (period: EventPeriodEnum) => void;
    modifyClock: (amount: number) => void;
    saveEvent: () => Promise<void>;
}

export type Props = OwnProps;

export default MatchClock;
